import styled from 'styled-components/macro';
import ItemsBox from 'src/components/ItemsBox';

const WrappedItemsBox = styled(ItemsBox)`
  background-color: ${props => props.theme.colors.error.default};
  color: white;
  opacity: 0.75;
  border-radius: 4px;
  width: 357px;
  margin: 0;
`;

export default WrappedItemsBox;
