import PropTypes from 'prop-types';

import { Plan, SubscriptionStatus } from './_components/SubscriptionService';

type ApplicationCheckParams = {
  subscriptionStatus: SubscriptionStatus;
  viaReferralLink: null | number;
};

// This array will be scanned in order to find the first applicable offer (if any).
// So offers must be sorted in the correct hierarchy (best offer first).
export default [
  {
    message: `🎉  A friend recommended you to use Picter! Subscribe now and get 50% off for the first 3 months.`,
    isApplicable: ({
      viaReferralLink,
      subscriptionStatus,
    }: ApplicationCheckParams) =>
      !!viaReferralLink && subscriptionStatus === SubscriptionStatus.InTrial,
    discounts: {
      [Plan.Tier1]: {
        amount: 0.5, // percentage
        footnoteSign: '*',
        footnote:
          'The discounted price is valid for the first 3 months after signup. After three months the regular price of € 9.90 per month applies.',
      },
      [Plan.Tier2]: {
        amount: 0.5, // percentage
        footnoteSign: '**',
        footnote:
          'The discounted price is valid for the first 3 months after signup. After three months the regular price of € 19.90 per month per user applies.',
      },
    },
  },
];

export const discountShape = PropTypes.shape({
  amount: PropTypes.number.isRequired,
  footnoteSign: PropTypes.string.isRequired,
  footnote: PropTypes.string.isRequired,
});
