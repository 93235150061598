import { defineMessages } from 'react-intl';

export default defineMessages({
  titleEditorQuotaModal: {
    id: 'ProjectPageEditorQuotaModal.titleEditorQuotaModal',
    defaultMessage: 'Maximum number of editors',
  },
  labelEditorQuotaModalCancel: {
    id: 'ProjectPageEditorQuotaModal.labelEditorQuotaModalCancel',
    defaultMessage: 'Cancel',
  },
  labelEditorQuotaModalLearnMore: {
    id: 'ProjectPageEditorQuotaModal.labelEditorQuotaModalLearnMore',
    defaultMessage: 'Learn more',
  },
  labelEditorQuotaModalUpgrade: {
    id: 'ProjectPageEditorQuotaModal.labelEditorQuotaModalUpgrade',
    defaultMessage: 'Upgrade',
  },
  messageEditorQuotaReached: {
    id: 'ProjectPageEditorQuotaModal.messageEditorQuotaReached',
    defaultMessage: `Sorry! You've reached more than 1 editor by project, the maximum number of editors per project in the Basic plan. Upgrade to Plus for unlimited uploads.`,
  },
});
