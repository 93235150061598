import React, { useCallback, useMemo, useContext, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import { toastError, toastInfo } from '@picter/prisma';
import { List } from 'immutable';
import { useIntl } from 'react-intl';

import { createComment } from 'src/actions/comments';
import { get, has } from 'src/utils/accessors';
import { librarySpaceTaskUrl, librarySpaceTasksUrl } from 'src/routes/urls';
import { ListContext } from 'src/components/DynamicSizeList';
import { returnInCase } from 'src/utils/in-case';
import { useAnnotationsData } from 'src/components/Annotations';
import { useCurrentUser } from 'src/hooks/use-resource';
import { useToriiActions } from 'src/modules/torii';
import useNavigate from 'src/hooks/use-navigate';
import usePermission from 'src/hooks/use-permission';
import { Comment, CommentAction, CommentTarget } from 'src/components/Comment';

import messages from '../_messages';
import { useRevalidateTasks } from '../_context';

function hasRelationship(object, relationship) {
  const relation = get(object, ['relationships', relationship]);
  return has(relation, 'id') || has(relation, 'data.id');
}

function TaskComment({ comment, index }) {
  /**
   * Subscriptions
   */
  const user = useCurrentUser();
  const intl = useIntl();
  const {
    create,
    destroy,
    localBulkDestroy,
    localCreate,
    localUpdateRelationship,
    update,
  } = useToriiActions();
  const { canDeleteComments, canManageAssignees } = usePermission();
  const { spaceId, taskId } = useParams();
  const { query } = useLocation();
  const { highlightedId, selectedId } = useAnnotationsData();
  const navigate = useNavigate();
  const { subscribeToItemSizeChange } = useContext(ListContext);
  const revalidateTasks = useRevalidateTasks();

  /**
   * Data
   */
  const commentId = get(comment, 'id');
  const userId = get(user, 'id');
  const markerId = get(comment, 'attributes.markerId');

  const [isOpened, setOpened] = useState();
  const isHighlighted = commentId === highlightedId;
  const isSelected = commentId === selectedId || commentId === taskId;

  const target = returnInCase({
    [hasRelationship(comment, 'project')]: () => CommentTarget.PROJECT,
    [hasRelationship(comment, 'collection')]: () => CommentTarget.COLLECTION,
    [hasRelationship(comment, 'image')]: () => CommentTarget.FILE,
    [hasRelationship(comment, 'parent')]: () => CommentTarget.COMMENT,
  });
  const isCommentReply = target === CommentTarget.COMMENT;

  const author = get(comment, 'relationships.author');
  const assignee = get(comment, 'relationships.assignee');
  const file = get(comment, 'relationships.image');
  const project = get(comment, 'relationships.project');
  const replies = get(comment, 'relationships.replies');
  const resolver = get(comment, 'relationships.resolvedBy');

  const authorEmail = get(author, 'attributes.email');
  const authorName = get(author, 'attributes.publicName');
  const authorData = useMemo(
    () => ({
      email: authorEmail,
      name: authorName,
    }),
    [authorEmail, authorName],
  );

  const assigneeData = useMemo(() => {
    /**
     * Torii returns an empty array for non existent values.
     * If it's the case return undefined.
     */
    if (List.isList(assignee) || assignee instanceof Array) return undefined;

    const assigneeEmail = get(assignee, 'attributes.email');
    const assigneeName = get(assignee, 'attributes.publicName');

    return {
      email: assigneeEmail,
      name: assigneeName,
    };
  }, [assignee]);

  const fileData = useMemo(() => {
    if (target === CommentTarget.FILE) {
      const fileId = get(file, 'id');
      const fileTitle = get(file, 'attributes.title');
      const fileSrc =
        get(file, 'attributes.files.small.location') ||
        get(file, 'attributes.files.thumbnailUrls.0');

      const fileType = (() => {
        switch (true) {
          case get(file, 'attributes.isVideo'):
            return 'video';
          case get(file, 'attributes.isImage'):
            return 'image';
          case get(file, 'attributes.isPdf'):
            return 'pdf';
          case get(file, 'attributes.isNonVisual'):
            return 'non-visual';
          default:
            return undefined;
        }
      })();

      return {
        alt: fileTitle,
        frameRate: get(file, 'attributes.frameRate'),
        id: fileId,
        type: fileType,
        previewSize: 'large',
        previewType: 'single',
        showPreview: true,
        src: fileSrc,
      };
    }

    if (target === CommentTarget.PROJECT) {
      const projectFile = get(project, 'relationships.coverImage');
      const fileId = get(projectFile, 'id');
      const fileTitle = get(projectFile, 'attributes.title');
      const fileSrc =
        get(projectFile, 'attributes.files.small.location') ||
        get(projectFile, 'attributes.files.thumbnailUrls.0');
      const fileType = (() => {
        switch (true) {
          case get(projectFile, 'attributes.isVideo'):
            return 'video';
          case get(projectFile, 'attributes.isImage'):
            return 'image';
          case get(projectFile, 'attributes.isPdf'):
            return 'pdf';
          case get(projectFile, 'attributes.isNonVisual'):
            return 'non-visual';
          default:
            return undefined;
        }
      })();

      return {
        alt: fileTitle,
        frameRate: get(projectFile, 'attributes.frameRate'),
        id: fileId,
        type: fileType,
        previewSize: 'large',
        previewType: 'stack',
        showPreview: true,
        src: fileSrc,
      };
    }
    return undefined;
  }, [file, target, project]);

  const projectId = get(project, 'id');
  const projectPublicUrlKey = get(project, 'attributes.publicUrlKey');
  const projectData = useMemo(
    () => ({
      id: projectId,
    }),
    [projectId],
  );

  const repliesData = useMemo(() => {
    if (isCommentReply || !replies) return undefined; // nested reply not supported

    return (Reflect.has(replies, 'toJS') ? replies.toJS() : replies)
      .sort((reply, otherReply) => {
        return (
          get(reply, 'attributes.createdAt') -
          get(otherReply, 'attributes.createdAt')
        );
      })
      .map(reply => {
        const replyAuthor = get(reply, 'relationships.author');
        const isAuthor = userId === get(replyAuthor, 'id');
        const actions = [];

        if (isAuthor) actions.push(CommentAction.EDIT);
        if (isAuthor || canDeleteComments) actions.push(CommentAction.DELETE);

        return {
          actions,
          content: get(reply, 'attributes.text'),
          edited: get(reply, 'attributes.editedAt') > 0,
          author: {
            email: get(replyAuthor, 'attributes.email'),
            name: get(replyAuthor, 'attributes.publicName'),
          },
          id: get(reply, 'id'),
          published: get(reply, 'attributes.createdAt'),
        };
      });
  }, [canDeleteComments, isCommentReply, replies, userId]);

  /**
   * Permissions
   */
  const availableActions = useMemo(() => {
    const isAuthor = userId === get(author, 'id');
    const actions = [];

    if (isAuthor) actions.push(CommentAction.EDIT);
    if (isAuthor || canDeleteComments) actions.push(CommentAction.DELETE);
    if (isAuthor || canManageAssignees) actions.push(CommentAction.ASSIGN);

    return actions;
  }, [author, canDeleteComments, canManageAssignees, userId]);

  /**
   * Handlers
   */
  const toggleAssignee = useCallback(
    async (id, userToAssign) => {
      if (userToAssign) {
        await localCreate('users', {
          id: userToAssign.id,
          type: 'users',
          attributes: {
            email: userToAssign.email,
            publicName: userToAssign.name,
          },
        });

        return update('wsComments', {
          id,
          relationships: {
            assignee: { type: 'users', id: userToAssign.id },
          },
        });
      }

      return update('wsComments', {
        id,
        relationships: {
          assignee: null,
        },
      });
    },
    [localCreate, update],
  );

  const deleteComment = useCallback(
    async (id, reps) => {
      try {
        await destroy('wsComments', {
          id,
        });
        revalidateTasks();

        // locally deleting comment's replies
        if (reps && reps.length > 0) localBulkDestroy('wsComments', reps);

        toastInfo(intl.formatMessage(messages.messageDeleteCommentSuccess));
      } catch (e) {
        toastError(e);
      }
    },
    [destroy, intl, localBulkDestroy, revalidateTasks],
  );

  const editComment = useCallback(
    (id, text) => {
      try {
        if (text === '') {
          return destroy('wsComments', {
            id,
          });
        }

        return update('wsComments', {
          id,
          attributes: {
            text,
          },
        });
      } catch (e) {
        return toastError(e);
      }
    },
    [update, destroy],
  );

  const toggleResolve = useCallback(
    async (id, resolved) => {
      await update('wsComments', {
        id,
        attributes: {
          resolved,
        },
        optimistic: true,
      });

      return localUpdateRelationship('wsComments', {
        id: commentId,
        relation: 'resolvedBy',
        data: {
          type: 'users',
          id: userId,
        },
      });
    },
    [commentId, localUpdateRelationship, update, userId],
  );

  const createCommentReply = useCallback(
    ({ text }) => {
      return createComment(
        {
          projectId,
          commentId,
          projectPublicUrlKey,
          text,
          user,
        },
        { create, localUpdateRelationship },
      );
    },
    [
      commentId,
      create,
      localUpdateRelationship,
      projectId,
      projectPublicUrlKey,
      user,
    ],
  );

  const navigateToTasksPage = useCallback(() => {
    navigate(
      librarySpaceTasksUrl({
        spaceId,
      }),
      { query },
    );
  }, [spaceId, navigate, query]);

  const navigateToTaskPage = useCallback(() => {
    navigate(
      librarySpaceTaskUrl({
        taskId: commentId,
        spaceId,
      }),
      { query, state: markerId ? { selectedMarkerId: commentId } : undefined },
    );
  }, [markerId, commentId, query, spaceId, navigate]);

  const toggleFocusComment = useCallback(() => {
    if (isSelected) {
      navigateToTasksPage();
    } else {
      navigateToTaskPage();
    }
  }, [isSelected, navigateToTaskPage, navigateToTasksPage]);

  const toggleReplies = useCallback(() => {
    if (!isOpened) {
      const unsubscribe = subscribeToItemSizeChange(index, listElement => {
        listElement.scrollToItem(index);
        unsubscribe();
      });
      setOpened(true);
      navigateToTaskPage();
    } else {
      setOpened(false);
    }
  }, [
    index,
    isOpened,
    navigateToTaskPage,
    subscribeToItemSizeChange,
    setOpened,
  ]);

  return (
    <Comment
      actions={availableActions}
      assignee={assigneeData}
      author={authorData}
      content={get(comment, 'attributes.text')}
      direction="left-to-right"
      edited={get(comment, 'attributes.editedAt') > 0}
      file={fileData}
      frame={get(comment, 'attributes.frame')}
      highlighted={isHighlighted}
      id={commentId}
      marker={markerId}
      opened={isOpened}
      project={projectData}
      published={get(comment, 'attributes.createdAt')}
      replies={repliesData}
      resolved={get(comment, 'attributes.resolved')}
      resolver={get(resolver, 'attributes.publicName')}
      selected={isSelected}
      onClick={toggleFocusComment}
      onClickFile={toggleFocusComment}
      onClickMarker={isSelected ? undefined : navigateToTaskPage}
      onClickReply={toggleReplies}
      onAssign={toggleAssignee}
      onDelete={deleteComment}
      onEdit={editComment}
      onReply={createCommentReply}
      onResolve={toggleResolve}
    />
  );
}

TaskComment.propTypes = {
  comment: PropTypes.shape({}),
  index: PropTypes.number.isRequired,
};

TaskComment.defaultProps = {
  comment: {},
};

export default memo(TaskComment);
