import { defineMessages } from 'react-intl';

const messages = defineMessages({
  markupDoneButton: {
    defaultMessage: 'Add comment',
    id: 'Annotations.markupDoneButton',
  },
  markupUndoTooltip: {
    defaultMessage: 'Undo',
    id: 'Annotations.markupUndoTooltip',
  },
  markupCancelTooltip: {
    defaultMessage: 'Cancel',
    id: 'Annotations.markupCancelTooltip',
  },
});

export default messages;
