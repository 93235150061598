import { useTooltipState as useTooltipStateReakit } from 'reakit/Tooltip';

/**
 * @typedef TooltipState
 * @type {Object}
 *
 * @property {boolean} animated
 * @property {boolean} animating
 * @property {boolean} modal
 * @property {boolean} visible
 * @property {string} baseId
 * @property {string} placement
 * @property {Function} hide
 * @property {Function} place
 * @property {Function} show
 * @property {Function} toggle
 */

/**
 * @function useTooltipState
 *
 * @param {Object} [options]
 * @param {(number|undefined)} [options.gutter]
 * @param {number} [options.hideDelay=0]
 * @param {number} [options.showDelay=0]
 * @param {boolean} [options.animated=false]
 * @param {boolean} [options.visible=false] - Whether it's visible or not
 * @param {string} [options.baseId]
 * @param {string} [options.placement]
 *
 * @returns {TooltipState}
 *
 * @see https://reakit.io/docs/tooltip/#usetooltipstate
 */
export function useTooltipState({
  hideDelay = 0,
  showDelay = 0,
  ...initialState
} = {}) {
  const tooltip = useTooltipStateReakit(initialState);
  let hideTimeout;
  let showTimeout;

  return {
    ...tooltip,
    hide: () => {
      hideTimeout = setTimeout(() => {
        tooltip.hide();
      }, hideDelay);

      clearTimeout(showTimeout);
    },
    show: () => {
      showTimeout = setTimeout(() => {
        tooltip.show();
      }, showDelay);

      clearTimeout(hideTimeout);
    },
    toggle: () => {
      const delay = tooltip.visible ? hideDelay : showDelay;

      setTimeout(() => {
        tooltip.toggle();
      }, delay);

      clearTimeout(hideTimeout);
      clearTimeout(showTimeout);
    },
  };
}
