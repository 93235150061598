import React from 'react';

const CommentsSvg = props => {
  // necessary because of conflicts if the mask is declared
  // with the same id two times in the same page
  const maskId = Math.random();

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id={maskId} fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 2C1.89543 2 1 2.89543 1 4V9.00578V10.1194V15.2331L4.11369 12.1194H12.2331C13.3377 12.1194 14.2331 11.224 14.2331 10.1194V4C14.2331 2.89543 13.3377 2 12.2331 2H3Z"
        />
      </mask>
      <path
        d="M1 15.2331H0C0 15.6376 0.243642 16.0022 0.617317 16.157C0.990991 16.3118 1.42111 16.2262 1.70711 15.9402L1 15.2331ZM4.11369 12.1194V11.1194C3.84847 11.1194 3.59412 11.2248 3.40658 11.4123L4.11369 12.1194ZM2 4C2 3.44772 2.44772 3 3 3V1C1.34315 1 0 2.34315 0 4H2ZM2 9.00578V4H0V9.00578H2ZM2 10.1194V9.00578H0V10.1194H2ZM2 15.2331V10.1194H0V15.2331H2ZM3.40658 11.4123L0.292893 14.526L1.70711 15.9402L4.8208 12.8266L3.40658 11.4123ZM12.2331 11.1194H4.11369V13.1194H12.2331V11.1194ZM13.2331 10.1194C13.2331 10.6717 12.7854 11.1194 12.2331 11.1194V13.1194C13.89 13.1194 15.2331 11.7763 15.2331 10.1194H13.2331ZM13.2331 4V10.1194H15.2331V4H13.2331ZM12.2331 3C12.7854 3 13.2331 3.44772 13.2331 4H15.2331C15.2331 2.34315 13.89 1 12.2331 1V3ZM3 3H12.2331V1H3V3Z"
        fill="currentColor"
        mask={`url(#${maskId})`}
      />
    </svg>
  );
};

export default CommentsSvg;
