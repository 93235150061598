import React, { useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { ContextualTextField } from 'src/modules/prisma';
import useUpdatableState from 'src/hooks/use-updatable-state';
import { refShape } from 'src/utils/app-prop-types';

const ProjectTitle = ({
  title,
  forwardedRef,
  maxWidth,
  minWidth,
  onChange,
  fontWeight,
  textAlign,
  textStyle,
  disabled,
}) => {
  const [value, setValue] = useUpdatableState(title);
  const handleChange = useCallback(e => setValue(e.target.value), [setValue]);

  return (
    <ContextualTextField
      maxLength="100"
      maxWidth={maxWidth}
      minWidth={minWidth}
      onBlur={onChange}
      onChange={handleChange}
      placeholder="Untitled project"
      ref={forwardedRef}
      textAlign={textAlign}
      textStyle={textStyle}
      fontWeight={fontWeight}
      value={value}
      disabled={disabled}
    />
  );
};

ProjectTitle.propTypes = {
  forwardedRef: refShape.isRequired,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  fontWeight: PropTypes.string,
  textAlign: PropTypes.string,
  textStyle: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
};

ProjectTitle.defaultProps = {
  maxWidth: undefined,
  minWidth: undefined,
  fontWeight: 'light',
  textAlign: 'left',
  textStyle: 'body.regular',
  title: undefined,
  disabled: false,
};

export default forwardRef((props, ref) => (
  <ProjectTitle forwardedRef={ref} {...props} />
));
