export const createAsyncTypes = type => ({
  REQUEST: `${type}_REQUEST`,
  SUCCESS: `${type}_SUCCESS`,
  ERROR: `${type}_ERROR`,
});

export const asyncTypesArray = types => [
  types.REQUEST,
  types.SUCCESS,
  types.ERROR,
];
