import React from 'react';

import withUploadStorage from 'src/hocs/with-upload-storage';

import GroupControlledUploadIndicator from './GroupControlledUploadIndicator';

const UploadIndicatorConnectedWithUploadStorage = ({ uploadStorage }) => {
  const groups = Object.values(uploadStorage.state.groups);
  const groupsWithFiles = groups.filter(group => group.totalCount);
  return groupsWithFiles.map(group => (
    <GroupControlledUploadIndicator
      group={group}
      id={group.id}
      cancelGroupUploads={group.cancelGroupUploads}
    />
  ));
};

export default withUploadStorage(UploadIndicatorConnectedWithUploadStorage);
