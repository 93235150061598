import { defineMessages } from 'react-intl';

export default defineMessages({
  /**
   * CreditsPage
   */
  titleCreditsPage: {
    id: 'CreditsPage.titleCreditsPage',
    defaultMessage: 'Earn credit',
  },
  titleInviteReferral: {
    id: 'CreditsPage.titleInviteReferral',
    defaultMessage: 'Invite someone to Picter and you’ll both get a gift',
  },
  messageAmountOfCreditPerSubscription: {
    id: 'CreditsPage.messageAmountOfCreditPerSubscription',
    defaultMessage:
      'For each person who creates a new workspace and starts a paid subscription upon your referral, <bold>you receive a {creditAmount} credit</bold> on your Picter account.',
  },
  messageAmountOfDiscountToSubscriber: {
    id: 'CreditsPage.messageAmountOfDiscountToSubscriber',
    defaultMessage:
      'The <bold>invited person gets a {discountPercentage}% discount</bold> for the first 3 months.',
  },
  messageCreditsOnlyForActiveSubscription: {
    id: 'CreditsPage.messageCreditsOnlyForActiveSubscription',
    defaultMessage:
      'You can only earn credits with an active subscription. <subscription>Manage subscription</subscription>',
  },

  /**
   * ReferralLink
   */
  titleReferralLink: {
    id: 'ReferralLink.titleReferralLink',
    defaultMessage: 'Your personal invitation link',
  },
  labelCopyLink: {
    id: 'ReferralLink.labelCopyLink',
    defaultMessage: 'Copy link',
  },
  labelCopyInvitation: {
    id: 'ReferralLink.labelCopyInvitation',
    defaultMessage: 'Copy invitation',
  },
  messageValidOnlyIfNewWorkspace: {
    id: 'ReferralLink.messageValidOnlyIfNewWorkspace',
    defaultMessage:
      'Please note that inviting people to your own Workspace doesn’t earn you credits. Referral gifts only apply if the invited person creates their own new workspace.',
  },
  messageInvitationMessage: {
    id: 'ReferralLink.messageInvitationMessage',
    defaultMessage: `
Do you know Picter? It’s a tool for individuals and teams to organize, \
share, review, approve and deliver their visual files. All in one place.

They offer a 7 day free trial. So you can test it without commitments:
{referralLink}`,
  },
  messageLinkCopied: {
    id: 'ReferralLink.messageLinkCopied',
    defaultMessage: 'Link copied to clipboard',
  },
  messageInvitationCopied: {
    id: 'ReferralLink.messageInvitationCopied',
    defaultMessage: 'Invitation copied to clipboard',
  },

  /**
   * ReferralsCreditBalance
   */
  titleReferralsCreditBalance: {
    id: 'ReferralsCreditBalance.titleReferralsCreditBalance',
    defaultMessage: 'Invited friends & credit balance',
  },
  labelSignedUpDate: {
    id: 'ReferralsCreditBalance.labelSignedUpDate',
    defaultMessage: 'Signed up {signUpDate}',
  },
  labelInTrial: {
    id: 'ReferralsCreditBalance.labelInTrial',
    defaultMessage: 'In Trial',
  },
  labelTrialExpired: {
    id: 'ReferralsCreditBalance.labelTrialExpired',
    defaultMessage: 'Trial expired',
  },
  labelSubscribed: {
    id: 'ReferralsCreditBalance.labelSubscribed',
    defaultMessage: 'Subscribed',
  },
  messageTotalCreditEarned: {
    id: 'ReferralsCreditBalance.messageTotalCreditEarned',
    defaultMessage: 'Total credit earned',
  },
});
