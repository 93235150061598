import { defineMessages } from 'react-intl';

export default defineMessages({
  labelEditComment: {
    id: 'Comment.labelEditComment',
    defaultMessage: 'Edit',
  },
  labelEditedComment: {
    id: 'Comment.labelEditedComment',
    defaultMessage: ' (edited)',
  },
  labelReply: {
    id: 'Comment.labelReply',
    defaultMessage: `{numberOfReplies, plural,
      =0 {Reply}
      =1 {# reply}
      other {# replies}}`,
  },
  labelCancel: {
    id: 'Comment.labelCancel',
    defaultMessage: 'Cancel',
  },
  labelResolved: {
    id: 'Comment.labelResolved',
    defaultMessage: 'Resolved by {resolvedBy}',
  },
  labelResolve: {
    id: 'Comment.labelResolve',
    defaultMessage: 'Resolve',
  },
  labelJumpToFrame: {
    id: 'Comment.labelJumpToFrame',
    defaultMessage: 'Jump to frame',
  },
  labelShowAnnotationMarker: {
    id: 'Comment.labelShowAnnotationMarker',
    defaultMessage: `{isSelected, select,
      false {Show}
      true {Hide}
    } annotation`,
  },
  labelFocusAnnotationMarker: {
    id: 'Comment.labelFocusAnnotationMarker',
    defaultMessage: `{isSelected, select,
      false {Focus}
      true {Unfocus}
    } annotation`,
  },
  labelMore: {
    id: 'Comment.labelMore',
    defaultMessage: 'More...',
  },

  // Input
  labelCancelCommentEdit: {
    id: 'Comment.labelCancelCommentEdit',
    defaultMessage: 'Cancel',
  },
  labelSaveCommentEdit: {
    id: 'Comment.labelSaveCommentEdit',
    defaultMessage: 'Save changes',
  },

  // CommentDeleteModal
  labelCancelDeleteComment: {
    id: 'CommentDeleteModal.labelCancelDeleteComment',
    defaultMessage: 'Cancel',
  },
  labelDeleteComment: {
    id: 'CommentDeleteModal.labelDeleteComment',
    defaultMessage: 'Delete',
  },
  messageModalDeleteComment: {
    id: 'CommentDeleteModal.messageModalDeleteComment',
    defaultMessage:
      'Are you sure you want to delete this comment? This is irreversible.',
  },
  titleDeleteComment: {
    id: 'CommentDeleteModal.titleDeleteComment',
    defaultMessage: 'Delete comment',
  },

  labelReplyInputPlaceholder: {
    id: 'Comment.labelReplyInputPlaceholder',
    defaultMessage: 'Reply',
  },
  labelCommentInputButton: {
    id: 'Comment.labelCommentInputButton',
    defaultMessage: 'Send',
  },

  messageDeletedUser: {
    id: 'Comment.messageDeletedUser',
    defaultMessage: 'Deleted user',
  },
});
