import { defineMessages } from 'react-intl';

export default defineMessages({
  titleTasks: {
    id: 'TasksPage.titleTasks',
    defaultMessage: 'Tasks',
  },

  titleNoTaskSelected: {
    id: 'TasksPage.titleNoTaskSelected',
    defaultMessage: 'No task selected',
  },
  messageNoTaskSelected: {
    id: 'TasksPage.messageNoTaskSelected',
    defaultMessage: 'Select a task to preview the related file here',
  },

  messageDeleteCommentSuccess: {
    id: 'TasksPage.messageDeleteCommentSuccess',
    defaultMessage: 'Comment deleted',
  },
});
