import { ACTIONS_TYPES } from './constants';

export const initialState = {
  // store for actually selected items
  selection: new Set(),
  // store for current shif-selection preview
  shiftPreview: new Set(),
  // store pressed state of shift button
  shiftPressed: false,
  // keep track wether shift-selection is for
  // select or deselect
  isShiftModeSelect: true,
  // Keep track of last item that received a
  // select or deselect. This is needed to define
  // the shift-preview set.
  lastInteractionId: null,
};

// Slice ids with normalized start and end parameters
function getNormalizedSlice(ids, startIndex, stopId) {
  const stopIndex = ids.indexOf(stopId || ids[0]);
  const from = Math.min(startIndex, stopIndex);
  const to = Math.max(startIndex, stopIndex) + 1;
  return ids.slice(from, to);
}

function addToSelection(state, action) {
  const newSelection = state.shiftPressed
    ? new Set([...state.selection, ...state.shiftPreview])
    : new Set([...state.selection, String(action.id)]);
  return {
    selection: newSelection,
    shiftPreview: new Set(),
    lastInteractionId: action.id,
  };
}

function removeFromSelection(state, action) {
  const newSelection = new Set(state.selection);
  newSelection.delete(String(action.id));
  if (state.shiftPressed) {
    state.shiftPreview.forEach(id => newSelection.delete(String(id)));
  }

  // When we close a selection, we set the new shift preview to be from the first
  // item to the last interacted.
  // Usefull when the user ends a selection but wants to select again without triggering
  // a hover event on a card.
  if (newSelection.size === 0) {
    const lastInteractionIndex = action.originalIds.indexOf(action.id);
    const nextShiftPreview = new Set(
      action.originalIds.slice(0, lastInteractionIndex + 1),
    );
    return {
      ...state,
      selection: newSelection,
      shiftPreview: nextShiftPreview,
      lastInteractionId: action.originalIds[0],
    };
  }

  return {
    ...state,
    selection: newSelection,
    shiftPreview: new Set(),
    lastInteractionId: action.id,
  };
}

export const createReducer = () =>
  function selectionReducer(state, action) {
    switch (action.type) {
      case ACTIONS_TYPES.SELECT:
        return addToSelection(state, action);
      case ACTIONS_TYPES.DESELECT:
        return removeFromSelection(state, action);
      case ACTIONS_TYPES.TOGGLE_SELECT: {
        const newValue =
          action.value || !state.selection.has(String(action.id));

        if (state.shiftPressed) {
          const hasShiftSelection = Array.from(state.shiftPreview).every(item =>
            state.selection.has(item),
          );
          return !hasShiftSelection
            ? addToSelection(state, action)
            : removeFromSelection(state, action);
        }
        return newValue === true
          ? addToSelection(state, action)
          : removeFromSelection(state, action);
      }
      case ACTIONS_TYPES.SELECT_ALL:
        return {
          ...state,
          selection: new Set(action.ids),
        };
      case ACTIONS_TYPES.SET_SHIFT_PREVIEW: {
        const shiftPreview = new Set(
          getNormalizedSlice(action.ids, action.index, state.lastInteractionId),
        );

        const hasShiftSelection = Array.from(shiftPreview).every(item =>
          state.selection.has(item),
        );

        return {
          ...state,
          shiftPreview,
          isShiftModeSelect: !hasShiftSelection,
        };
      }
      case ACTIONS_TYPES.TOGGLE_SHIFT:
        return {
          ...state,
          shiftPressed: action.value,
        };
      case ACTIONS_TYPES.CLEAR:
        return initialState;
      default:
        throw new Error(`${action.type} is not a defined action`);
    }
  };
