import React, { cloneElement, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@picter/prisma';
import isFunction from 'lodash/isFunction';

function ToggleWorkaround({ children, ...dropdownStateProps }) {
  return children(dropdownStateProps);
}

/**
 * Reasons behind this workaround:
 *
 * Our Dropdown component is a little bit old and only supports
 * `PropTypes.element` as the `icon` property. Which isn't flexible because in
 * case the element we wish to be used as toggle needs to be wrapped with a
 * `TrackerEvent` or `Tooltip` we have to keep passing the `onClick` property
 * through a bunch of components.
 *
 * This workaround wraps the original Dropdown and accepts a function as `icon`
 * property, making the above possible.
 *
 * TODO: Accept function as `icon` property when refactoring the Dropdown component.
 */
const DropdownWorkaroundForToggleProperty = forwardRef(
  ({ icon, ...props }, ref) => {
    return (
      <Dropdown
        ref={ref}
        icon={
          <ToggleWorkaround>
            {dropdownStateProps =>
              isFunction(icon)
                ? icon(dropdownStateProps)
                : cloneElement(icon, dropdownStateProps)
            }
          </ToggleWorkaround>
        }
        {...props}
      />
    );
  },
);

DropdownWorkaroundForToggleProperty.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.func, // render props e.g. {({ active, onClik }) => ()}
    PropTypes.element, // e.g.<Icon />
  ]),
};

DropdownWorkaroundForToggleProperty.defaultProps = {
  icon: Dropdown.defaultProps.icon,
};

export default DropdownWorkaroundForToggleProperty;
