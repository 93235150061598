import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Row from '../styles/Row';

const GridItem = memo(({ index, style, data }) => {
  const {
    numRows,
    paddingLeft,
    paddingRight,
    numColumns,
    itemCount,
    children,
    rowHeight,
    margin,
    gridHeader,
  } = data;
  const rowStyle = {
    ...style,
    paddingLeft,
    paddingRight,
  };
  // Return header row
  if (index === 0) {
    return <Row style={rowStyle}>{gridHeader}</Row>;
  }
  // Return padding rows (first and last).
  // last row = number of data rows + 2 (header and padding top)
  if (index === 1 || index === numRows + 2) {
    return <Row style={rowStyle} />;
  }

  // The list contains rows for header and top- and bottom-padding.
  // So the row-index has an offset of 2 compared to the data index
  // (one header and one top-padding row). We need to subtract this offset
  // to normalize the index for data access.
  const dataIndex = index - 2;
  const columnIndices = Array(
    Math.min(numColumns, itemCount - dataIndex * numColumns),
  )
    .fill(dataIndex * numColumns)
    .map((base, i) => base + i);

  // Adds ghost children to make auto resize work correctly when using margins
  const emptyIndexes = Array(numColumns - columnIndices.length).fill(null);
  const columnIndicesWithEmptySpace = [...columnIndices, ...emptyIndexes];

  const width = 100 / numColumns;

  return (
    <Row style={rowStyle}>
      {columnIndicesWithEmptySpace.map(columnIndex => {
        if (columnIndex === null) {
          return <div style={{ width: `${width}%`, margin }} />;
        }
        return children({
          index: columnIndex,
          width,
          height: rowHeight,
          margin,
        });
      })}
    </Row>
  );
});

GridItem.propTypes = {
  data: PropTypes.shape({
    gridHeader: PropTypes.node,
    margin: PropTypes.number,
    numRows: PropTypes.number,
    paddingLeft: PropTypes.number,
    paddingRight: PropTypes.number,
    numColumns: PropTypes.number,
    itemCount: PropTypes.number,
    children: PropTypes.node.isRequired,
    rowHeight: PropTypes.number,
  }).isRequired,
  index: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
};

export default GridItem;
