import { Map } from 'immutable';

import {
  LOGIN_SUCCESS,
  SIGNUP_SUCCESS,
  CHECK_USER_SUCCESS,
  LOGOUT_SUCCESS,
} from '../constants';

const initialState = Map({});

export default (state = initialState, action = { type: undefined }) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case SIGNUP_SUCCESS:
    case CHECK_USER_SUCCESS:
      return Map({ ...action.payload });

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};
