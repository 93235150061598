import { Map, List } from 'immutable';
import { IMAGE_FILE_INFO_TYPE } from 'src/constants';
import curry from 'lodash/curry';
import pick from 'lodash/pick';

import { get } from 'src/utils/accessors';

export const getProjectImageFileInfo = (
  image,
  size = 'original',
  infoType = IMAGE_FILE_INFO_TYPE.LOCATION,
) => get(image, `attributes.files.${size}.${infoType}`);

export const getProjectVideoFileUrl = image =>
  get(image, 'attributes.files.mp4Urls.0');

export const getProjectFileThumbnailUrl = (cover = Map()) =>
  get(cover, 'attributes.files.thumbnailUrls.0');

export const getProjectImageProject = (image, defaultValue) =>
  get(image, 'relationships.project', defaultValue);

export const getProjectImageProof = image => get(image, 'attributes.approved');

export const getProjectImageSpace = (image, defaultValue) =>
  get(image, 'relationships.project.relationships.space', defaultValue);

export const getProjectImageUploadState = image => {
  return get(image, 'attributes.uploadPending');
};

export const getProjectImageProcessState = image => {
  const isTiff = get(image, 'attributes.mimeType') === 'image/tiff';

  if (get(image, 'attributes.isImage') && !isTiff) {
    return false;
  }

  const processingFlag =
    get(image, 'attributes.newVersionPending') ||
    get(image, 'attributes.processPending');

  return processingFlag;
};

export function msToTime(duration) {
  const durationArr = [];

  let seconds = Math.floor((duration / 1000) % 60, 10);
  let minutes = Math.floor((duration / (1000 * 60)) % 60, 10);
  let hours = Math.floor(duration / (1000 * 60 * 60), 10);

  hours = `${hours}`.padStart(2, '0');
  if (hours > 0) durationArr.push(hours);
  minutes = `${minutes}`.padStart(2, '0');
  seconds = `${seconds}`.padStart(2, '0');
  durationArr.push(minutes, seconds);

  return durationArr.join(':');
}

export function getProjectVideoDuration(video) {
  const msDuration = get(video, 'attributes.duration');
  return msDuration ? msToTime(msDuration) : undefined;
}

export const isProjectImageDefined = image => image && !image.isEmpty();

/**
 * Filter related accessors
 */
export const IMAGE_FILTER_QUERY_KEY = 'filter[global]';
export const IMAGE_APPROVAL_FILTER_QUERY_KEY = 'filter[approvedBy]';

export const ImageProofFilter = Object.freeze({
  ALL: undefined,
  UNDECIDED: 'undecided',
  REJECTED: 'rejected',
  ANY: 'any',
});

export function pickImageProofFilter(query) {
  return pick(query, 'filter');
}

export const isImageProofFilter = curry((filters, image) => {
  const { global: globalFilter, approvedBy } = filters || {};
  const filter = globalFilter || approvedBy;

  if (!image) {
    return false;
  }

  switch (filter) {
    case ImageProofFilter.ALL:
      return true;
    case ImageProofFilter.ANY:
      return (
        !!get(image, 'relationships.approvals', List()).size &&
        get(image, 'attributes.rejected') !== true
      );
    case ImageProofFilter.REJECTED:
      return get(image, 'attributes.rejected') === true;
    case ImageProofFilter.UNDECIDED:
      return (
        !get(image, 'relationships.approvals', List()).size &&
        get(image, 'attributes.rejected') !== true
      );
    default: {
      if (!filter) {
        return true;
      }

      if (get(image, 'attributes.rejected') === true) {
        return false;
      }

      const parsedFilters = Array.isArray(filter) ? filter : [filter];
      return parsedFilters.every(parsedFilter => {
        return get(image, 'relationships.approvals', List()).some(
          approval => parsedFilter === get(approval, 'relationships.user.id'),
        );
      });
    }
  }
});
