import React from 'react';
import PropTypes from 'prop-types';
import { TrackerScope } from '@picter/tracker';

const Document = ({ children }) => (
  <TrackerScope name="Collection">{children}</TrackerScope>
);

Document.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Document;
