import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';

import ItemsBox from 'src/components/ItemsBox';
import TruncateBox from 'src/styles/TruncateBox';

import WrappedItemsBox from './styles/WrappedItemsBox';

function ErrorToast({ closeToast, errors, title }) {
  return (
    <WrappedItemsBox>
      <TruncateBox width="95%">
        <ItemsBox.Title>{title}</ItemsBox.Title>
      </TruncateBox>
      <ItemsBox.Icon color="white" onClick={closeToast} type="close" />
      <ItemsBox.List>
        {errors.map(error => (
          <ItemsBox.Item key={`${title}-${error}`} item={error} />
        ))}
      </ItemsBox.List>
    </WrappedItemsBox>
  );
}

ErrorToast.propTypes = {
  closeToast: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

ErrorToast.defaultProps = {
  errors: new Map(),
};

export default ErrorToast;
