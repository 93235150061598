import styled from 'styled-components/macro';

const AcceptTermsModalContent = styled.div`
  display: flex;
  flex-direction: column;

  button {
    margin-left: auto;
    padding: 0;
  }

  img {
    margin-bottom: ${props => props.theme.space[4]}px;
    width: 100%;
  }

  p {
    margin-bottom: ${props => props.theme.space[6]}px;
    white-space: pre;
  }
`;

export default AcceptTermsModalContent;
