import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';

import { Box } from 'src/modules/prisma';
import FormAutoSaveIndicator from './FormAutoSaveIndicator';
import FormErrorIndicator from './FormErrorIndicator';
import FormSavedIndicator from './FormSavedIndicator';
import FormSavingIndicator from './FormSavingIndicator';

const FormSection = styled(Box).attrs({ gmt: 3 })`
  background: ${themeGet('colors.white')};
  border: 1px solid ${themeGet('colors.cold.gray.200')};
  padding: ${themeGet('space.5')}px ${themeGet('space.6')}px;
  position: relative;

  > ${/* sc-selector */ FormAutoSaveIndicator},
    > ${/* sc-selector */ FormErrorIndicator},
    > ${/* sc-selector */ FormSavedIndicator},
    > ${FormSavingIndicator} {
    position: absolute;
    top: ${themeGet('space.5')}px;
    right: ${themeGet('space.6')}px;
  }
`;

export default FormSection;
