import { cloneElement, useCallback, useMemo, useState } from 'react';

export function useModalState() {
  const [visible, setVisible] = useState(false);
  const open = useCallback(() => setVisible(true), [setVisible]);
  const close = useCallback(() => setVisible(false), [setVisible]);

  return { close, open, visible };
}
/**
 * TODO: Rename `useModalElement` properties passed to `Modal`.
 *
 * Better descriptive names:
 * - OLD     -> NEW
 * - open    -> visible
 * - onClose -> close
 * - ...     -> open (export open function)
 */
export function useModalElement(Modal, state) {
  const { close, visible } = state;
  const elementOnClose = Modal.props.onClose;

  const onClose = useCallback(
    (...params) => {
      close(...params);
      if (elementOnClose) elementOnClose(...params);
    },
    [close, elementOnClose],
  );

  const modalElement = useMemo(
    () => cloneElement(Modal, { open: visible, onClose, close }),
    [Modal, visible, onClose, close],
  );

  return modalElement;
}

export default function useModal(Modal) {
  const modalState = useModalState();
  const modalElement = useModalElement(Modal, modalState);

  return [modalElement, modalState];
}
