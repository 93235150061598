import styled from 'styled-components/macro';

import ItemActions from './NavigationItemActions';
import Link from '../../Link';

const NavigationItemLink = styled(Link)`
  align-items: center;
  color: ${props => props.theme.colors.grey[600]};
  display: inline-flex;
  width: 100%;

  & ~ ${ItemActions} {
    display: none;

    :hover /* Prevents flicker when moving the mouse to the box */ {
      display: block;
    }
  }

  &:hover {
    color: ${props => props.theme.colors.black};
  }

  &:hover,
  &:focus {
    ~ ${ItemActions} {
      display: block;
    }
  }

  &.active {
    color: ${props => props.theme.colors.primary};
    box-shadow: inset 5px 0 0 -1px ${props => props.theme.colors.primary};
  }
`;

export default NavigationItemLink;
