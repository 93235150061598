import { defineMessages } from 'react-intl';

export default defineMessages({
  messageYouNeedPermission: {
    id: 'NoPermissionPage.messageYouNeedPermission',
    defaultMessage: 'You need permission to see this project',
  },
  messageWantIn: {
    id: 'NoPermissionPage.messageWantIn',
    defaultMessage:
      'Want in? Ask the owner for access, or <a>log in to an account with permission</a>.',
  },
});
