import styled from 'styled-components/macro';
import { Box } from 'src/modules/prisma';

const SidebarTop = styled(Box)``;

SidebarTop.defaultProps = {
  flex: '0 1 80px',
};

export default SidebarTop;
