import styled from 'styled-components/macro';

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (max-width: ${props => props.theme.breakpoints[1]}) {
    -webkit-overflow-scrolling: touch;
  }
`;

export default SidebarContent;
