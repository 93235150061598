import { List } from 'immutable';
import isFunction from 'lodash/isFunction';

import { get } from 'src/utils/accessors';

export function listToKeyedObject(
  list = List(),
  { key: keyResolver = f => f, value: valueResolver = f => f } = {},
) {
  return list.reduce((keyedObject, item) => {
    const key = isFunction(keyResolver) ? keyResolver(item) : item[keyResolver];
    const value = isFunction(valueResolver)
      ? valueResolver(item)
      : item[valueResolver];

    return {
      ...keyedObject,
      [key]: value,
    };
  }, {});
}

export function featuresListToFeaturesObject(featuresList) {
  return listToKeyedObject(featuresList, {
    key: feature => get(feature, 'attributes.name'),
    value: feature => get(feature, 'attributes.config'),
  });
}
