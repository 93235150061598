import { defineMessages } from 'react-intl';

export default defineMessages({
  titleNotificationsPage: {
    id: 'NotificationsPage.titleNotificationsPage',
    defaultMessage: 'Notifications',
  },
  titleNotifyMeAbout: {
    id: 'NotificationsPage.titleNotifyMeAbout',
    defaultMessage: 'Notify me about...',
  },
  labelMentionsAndAssignmentsInput: {
    id: 'NotificationsPage.labelMentionsAndAssignmentsInput',
    defaultMessage: 'Mentions & task assignments',
  },
  messageMentionsAndAssignmentsInput: {
    id: 'NotificationsPage.messageMentionsAndAssignmentsInput',
    defaultMessage:
      'Someone mentions me with @myname or assigns a comment to me, no matter if I’m following the project or not',
  },
  labelActivitiesInput: {
    id: 'NotificationsPage.labelActivitiesInput',
    defaultMessage: 'All activity – in projects that I’m following',
  },
  messageActivitiesInput: {
    id: 'NotificationsPage.messageActivitiesInput',
    defaultMessage:
      'Someone comments, replies, assigns or resolves a comment, approves, uploads or downloads files',
  },
  titleCommunication: {
    id: 'NotificationsPage.titleCommunication',
    defaultMessage: 'Picter communication',
  },
  titleNotificationChannel: {
    id: 'NotificationsPage.titleNotificationChannel',
    defaultMessage: 'Receive notifications through...',
  },
  labelNewsletterInput: {
    id: 'NotificationsPage.labelNewsletterInput',
    defaultMessage: 'Get feature updates and tips via email',
  },
  messageNewsletterInput: {
    id: 'NotificationsPage.messageNewsletterInput',
    defaultMessage:
      'Learn about new features and receive valuable tips and insights',
  },
  labelEmailChannelInput: {
    id: 'NotificationsPage.labelEmailChannelInput',
    defaultMessage: 'Email',
  },
  messageEmailChannelInput: {
    id: 'NotificationsPage.messageEmailChannelInput',
    defaultMessage: 'New activity bundled in one email',
  },
  labelSlackChannelInput: {
    id: 'NotificationsPage.labelSlackChannelInput',
    defaultMessage: 'Slack',
  },
  messageSlackChannelInput: {
    id: 'NotificationsPage.messageSlackChannelInput',
    defaultMessage: 'Manage your slack integration here',
  },
  messageOpenPicterSlack: {
    id: 'NotificationsPage.messageOpenPicterSlack',
    defaultMessage: 'Click here to open Picter in Slack',
  },
  messageSlackBindAccounts: {
    id: 'NotificationsPage.messageSlackBindAccounts',
    defaultMessage:
      'Click here to connect your Picter account with your Slack account',
  },
  messageSlackChannelAskManagerOrOwner: {
    id: 'NotificationsPage.messageSlackChannelAskManagerOrOwner',
    defaultMessage:
      'Ask an owner or manager to install the Slack integration for this workspace',
  },
  labelNotificationsInsidePicter: {
    id: 'NotificationsPage.labelNotificationsInsidePicter',
    defaultMessage: 'You’ll also receive notifications inside Picter.',
  },
});
