import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  stringify as stringifyQueryString,
  parse as parseQueryString,
} from 'qs';
import { MediaQuery, Spinner } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';
// @ts-ignore
import * as ElasticAppSearch from '@elastic/app-search-javascript';
// @ts-ignore
import Keyboardist from 'react-keyboardist';

import { Box, Body } from 'src/modules/prisma';
import useFormatMessage from 'src/hooks/use-format-message';

import Modal from './styles/Modal';
import SearchField from './components/SearchField';
import SearchResult from './components/SearchResult';
import FiltersComponent from './components/Filters';
import { loadSearchSuggestions, search, Client } from './handlers';
import { Result, Facet, Filters } from './types';
import useFilters from './use-filters';
import { extractResultProps } from './utils';

import messages from './messages';

function SearchModalContent({
  qTerm,
  qFilters,
  signedSearchKey,
  setQTerm,
  handleClose,
}: {
  qTerm: string | undefined;
  qFilters:
    | {
        [index: string]: string | string[];
      }
    | undefined;
  signedSearchKey: string;
  setQTerm: (q: string) => void;
  handleClose: () => void;
}) {
  const formatMessage = useFormatMessage();

  const [client, setClient] = useState<Client | null>(null);
  const [searchingState, setSearchingState] = useState(false);
  const [results, setResults] = useState<Result[] | null>(null);
  const [facets, setFacets] = useState<{ [key: string]: Facet[] } | undefined>(
    {},
  );
  const [filters, addFilter, removeFilter] = useFilters(qFilters);

  useEffect(() => {
    setClient(
      ElasticAppSearch.createClient({
        searchKey: signedSearchKey,
        endpointBase: process.env.REACT_APP_SEARCH_ENDPOINT_BASE,
        engineName: process.env.REACT_APP_SEARCH_ENGINE_NAME,
      }),
    );
  }, [signedSearchKey]);

  const onSearch = useCallback(
    async (q: string, searchFilters: Filters) => {
      if (client) {
        setSearchingState(true);
        const { results: searchResults, facets: searchFacets } = await search(
          client,
          q,
          searchFilters,
        );
        setResults(searchResults);
        setFacets(searchFacets);
        setSearchingState(false);
      }
    },
    [client],
  );

  useEffect(() => {
    if (qTerm) {
      onSearch(qTerm, filters);
    }
  }, [filters, qTerm, onSearch, facets]);

  const bindings = useMemo(
    () => ({
      Escape: handleClose,
    }),
    [handleClose],
  );

  const hasFacets = useMemo(() => {
    const facetsValues = Object.values(facets ?? {});
    const hasValue = facetsValues.some(facet =>
      facet.some(item => item.data.length > 0),
    );
    return hasValue;
  }, [facets]);
  const typeFilter = useMemo(
    () => filters.all.find(({ type }) => type !== undefined)?.type?.[0],
    [filters],
  );

  if (!client) return null;

  const shouldShowModalBody = (qTerm && results) || searchingState;

  const shouldShowModalContent =
    (results && results.length > 0) || hasFacets || searchingState;

  return (
    <>
      <Keyboardist bindings={bindings} />
      <MediaQuery breakpoint="phablet" type="greaterThan">
        {(matches: boolean) => (
          <>
            <SearchField
              initialValue={qTerm}
              onSearch={(q: string) => setQTerm(q)}
              loadSuggestions={(q: string) => loadSearchSuggestions(client, q)}
              onClickClose={handleClose}
            />
            {shouldShowModalBody && (
              <>
                {shouldShowModalContent ? (
                  <Box
                    display="flex"
                    flex={1}
                    flexDirection={matches ? 'row' : 'column'}
                    pl={matches ? 8 : 4}
                    overflow="hidden"
                  >
                    {hasFacets && (
                      <Box
                        width={matches ? [2 / 7] : 1}
                        mb={matches ? 0 : 2}
                        pt={matches ? 6 : 3}
                        pb={matches ? 6 : 0}
                      >
                        <FiltersComponent
                          facets={facets}
                          filters={filters}
                          addFilter={addFilter}
                          removeFilter={removeFilter}
                          isMobile={!matches}
                        />
                      </Box>
                    )}

                    <Box
                      overflow="auto"
                      style={{ width: '100%' }}
                      pt={matches ? 6 : 0}
                      pb={matches ? 6 : 3}
                      pr={matches ? 8 : 4}
                    >
                      {results?.length === 0 || searchingState ? (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          flex="1"
                          height="100%"
                        >
                          {searchingState ? (
                            <Spinner />
                          ) : (
                            <Body>
                              <FormattedMessage
                                {...messages.labelNoResultsInFacet}
                                values={{ qTerm, type: typeFilter }}
                              />
                            </Body>
                          )}
                        </Box>
                      ) : (
                        results?.map(result => {
                          const props = extractResultProps(
                            result,
                            formatMessage,
                          );
                          if (!props) return null;
                          return (
                            <SearchResult {...props} isMobile={!matches} />
                          );
                        })
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flex="1"
                  >
                    <Body>
                      <FormattedMessage
                        {...messages.labelNoResults}
                        values={{ qTerm }}
                      />
                    </Body>
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </MediaQuery>
    </>
  );
}

export default function SearchModal({
  signedSearchKey,
}: {
  signedSearchKey: string;
}) {
  const history = useHistory();
  const { pathname, query } = useLocation() as {
    pathname: string;
    query?: {
      q: string;
      qFilters: {
        [index: string]: string | string[];
      };
    };
  };
  const { q: qTerm, qFilters } = query ?? {};

  const setQTerm = useCallback(
    (q: string) => {
      return history.push(
        `${pathname}?${stringifyQueryString(
          {
            ...query,
            q,
          },
          { encodeValuesOnly: true, arrayFormat: 'comma' },
        )}`,
      );
    },
    [history, pathname, query],
  );

  const handleClose = useCallback(() => {
    const { q, qFilters: innerQFilters, ...queryObject } = query ?? {};

    history.push(
      `${pathname}?${stringifyQueryString(
        {
          ...queryObject,
        },
        { encodeValuesOnly: true, arrayFormat: 'comma' },
      )}`,
    );
  }, [history, pathname, query]);

  const handleOpenSearch = useCallback(() => {
    const { q } = parseQueryString(window.location.search, {
      ignoreQueryPrefix: true,
      comma: true,
    });
    if (q === undefined) {
      setQTerm('');
    }
  }, [setQTerm]);

  const bindings = useMemo(
    () => ({
      // Shortcut on Windows
      'Ctrl+KeyF': handleOpenSearch,
      // Shortcut on Mac
      'Meta+KeyF': handleOpenSearch,
    }),
    [handleOpenSearch],
  );

  if (!signedSearchKey) return null;

  return (
    <>
      <Keyboardist bindings={bindings} />
      <Modal
        open={qTerm !== undefined}
        showContent={qTerm}
        onClickClose={handleClose}
      >
        <SearchModalContent
          signedSearchKey={signedSearchKey}
          qTerm={qTerm}
          setQTerm={setQTerm}
          qFilters={qFilters}
          handleClose={handleClose}
        />
      </Modal>
    </>
  );
}
