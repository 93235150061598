import React, { forwardRef } from 'react';
import Gravatar from 'react-gravatar';
import md5 from 'md5';

import AccountCircleSvg from 'src/assets/AccountCircleSvg';
import NoAccountsSvg from 'src/assets/NoAccountsSvg';
import { Icon } from 'src/modules/prisma';
import { Sizes } from 'src/types';

import Container, { Props as ContainerProps } from '../styles/AvatarContainer';
import Identicon from './Identicon';
import { containerSizeVariants } from '../variants';
import { CONTAINER_SIZE_TO_ICON_SIZE_MAP } from '../constants';

export type Props = Omit<ContainerProps, 'size' | 'name'> &
  Partial<{
    highlighted: boolean;
    selected: boolean;
    size: keyof Sizes<number | string>;
    shape: string;
    gravatar: boolean;
    uniqueIdentifier: number | string;
    'data-testid': string;
    src: string;
    email: string;
    name: string;
    deleted?: boolean;
  }>;

const Avatar = forwardRef<HTMLElement, Props>(
  (
    {
      id,
      className,
      email,
      highlighted = false,
      name,
      onClick,
      selected = false,
      size = 'medium',
      shape = 'circle',
      gravatar = true,
      uniqueIdentifier,
      'data-testid': dataTestId,
      src,
      deleted = false,
    },
    ref,
  ) => {
    const sizeValue = containerSizeVariants({ size });
    if (!email && !uniqueIdentifier /* public project */) {
      return (
        <Icon
          id={id}
          className={className}
          type={deleted ? <NoAccountsSvg /> : <AccountCircleSvg />}
          size={CONTAINER_SIZE_TO_ICON_SIZE_MAP[size]}
          onClick={onClick}
          data-testid="account-circle-icon"
          ref={ref}
        />
      );
    }

    let identifier = `${uniqueIdentifier}`;

    if (email) {
      identifier = email;
    }

    const identifierHash = md5(identifier, { encoding: 'binary' });

    return (
      <Container
        id={id}
        className={className}
        onClick={onClick}
        ref={ref}
        size={sizeValue}
        minSize={sizeValue}
        shape={shape}
        data-testid={dataTestId}
      >
        {/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */}
        {!src && (
          <Identicon
            hash={identifierHash}
            highlighted={highlighted}
            interactive={!!onClick}
            letter={name?.charAt(0) || identifier.charAt(0)}
            selected={selected}
            size={size}
          />
        )}
        {src && (
          <img
            style={{
              height: sizeValue,
              width: sizeValue,
            }}
            alt={name || undefined}
            src={src}
          />
        )}
        {gravatar && !src && email ? (
          <>
            {/* eslint-enable @typescript-eslint/prefer-nullish-coalescing */}
            <Gravatar
              email={email}
              md5={identifierHash}
              default="blank"
              size={sizeValue}
            />
          </>
        ) : null}
      </Container>
    );
  },
);

Avatar.displayName = 'Avatar';

export default Avatar;
