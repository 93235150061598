import React, { useRef, useCallback, useEffect } from 'react';

import { Box } from 'src/modules/prisma';
import useTinyKeys from 'src/hooks/use-tiny-keys';

import { RangeProgressContainer, Progress, Range } from './RangeProgress';
import { TimelineMarker } from '../types';
import { AvatarTimelineMarker } from '../containers/AvatarMarker';

export type TimelineControlProps = {
  markers?: TimelineMarker[];
  loaded: number /* percentage */;
  played: number /* percentage */;
  seekStep?: number /* percentage */;
  onClickMarker: (marker: TimelineMarker) => void;
  onSeekStart: (startPosition: number) => void;
  onSeek: (newPosition: number) => void;
  onSeekEnd: (endPosition: number) => void;
};

export default function TimelineControl({
  markers,
  loaded,
  played,
  seekStep = 0.05,
  onClickMarker,
  onSeekStart,
  onSeek,
  onSeekEnd,
}: TimelineControlProps) {
  const currentPosition = useRef(played);
  const max = 1;

  const seekPrevious = useCallback(() => {
    const newPosition = currentPosition.current - seekStep;
    const endPosition = Math.max(newPosition, 0);

    onSeekStart(currentPosition.current);
    onSeek(endPosition);
    onSeekEnd(endPosition);
  }, [onSeekStart, onSeek, onSeekEnd, seekStep]);

  const seekNext = useCallback(() => {
    const newPosition = currentPosition.current + seekStep;
    const endPosition = Math.min(newPosition, max);

    onSeekStart(currentPosition.current);
    onSeek(endPosition);
    onSeekEnd(endPosition);
  }, [onSeekStart, onSeek, onSeekEnd, seekStep]);

  useEffect(() => {
    currentPosition.current = played;
  }, [played]);

  useTinyKeys(
    window.document,
    {
      ArrowLeft: event => {
        event.stopPropagation();

        seekPrevious();
      },
      ArrowRight: event => {
        event.stopPropagation();

        seekNext();
      },
    },
    [seekNext, seekPrevious],
  );

  return (
    // @ts-ignore
    <Box alignItems="center" display="inline-flex" px={2} py={1} width="100%">
      <RangeProgressContainer>
        <Progress
          backgroundColor="grey.300"
          color="grey.600"
          value={loaded}
          max={max}
        />
        <Progress
          backgroundColor="transparent"
          color="primary"
          value={played}
          max={max}
        />
        <Range
          color="primary"
          max={max}
          onChange={event => onSeek?.(parseFloat(event.target.value))}
          onKeyDown={event => {
            event.preventDefault();

            if (['ArrowLeft'].includes(event.key)) seekPrevious();
            else if (['ArrowRight'].includes(event.key)) seekNext();
          }}
          onMouseDown={event => {
            event.stopPropagation();
            onSeekStart?.(currentPosition.current);
          }}
          onMouseUp={event => {
            event.stopPropagation();
            onSeekEnd?.(currentPosition.current);
          }}
          step={0.001}
          value={played}
        />
        {markers?.map(marker => (
          <AvatarTimelineMarker
            id={marker.id}
            position={marker.position}
            onClick={() => onClickMarker(marker)}
          />
        ))}
      </RangeProgressContainer>
    </Box>
  );
}
