import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedRelativeTime } from 'react-intl';
import { Box, Flex } from '@rebass/grid';

import { Body, Panel, Subheading } from 'src/modules/prisma';

import messages from '../messages';

function CurrentPlanPanel({
  actionButton,
  numberOfSeats,
  planName,
  secondsLeftInTrial,
}) {
  return (
    <Panel
      backgroundColor="white"
      borderColor="grey.200"
      borderStyle="solid"
      borderWidth="1px"
      padding={5}
      tag="section"
    >
      <Subheading tag="h2">
        <FormattedMessage {...messages.titleYourCurrentPlan} />
      </Subheading>
      <Flex
        flexDirection={['column', null, 'row']}
        justifyContent="space-between"
        mt={5}
      >
        <Body>
          <FormattedMessage
            {...messages.labelPlanName}
            values={{
              planName,
            }}
          />{' '}
          {secondsLeftInTrial > 0 && (
            <FormattedMessage
              {...messages.labelPlanTrialDays}
              values={{
                relativeDate: (
                  <FormattedRelativeTime
                    numeric="auto"
                    unit="second"
                    updateIntervalInSeconds={60}
                    value={secondsLeftInTrial}
                  />
                ),
              }}
            />
          )}
          {numberOfSeats > 0 && (
            <>
              {' • '}
              <FormattedMessage
                {...messages.labelPlanSeats}
                values={{
                  numberOfSeats,
                }}
              />
            </>
          )}
        </Body>
        <Box mt={[3, null, 0]}>{actionButton}</Box>
      </Flex>
    </Panel>
  );
}

CurrentPlanPanel.propTypes = {
  actionButton: PropTypes.element,
  numberOfSeats: PropTypes.number,
  planName: PropTypes.string.isRequired,
  secondsLeftInTrial: PropTypes.number,
};

CurrentPlanPanel.defaultProps = {
  actionButton: undefined,
  numberOfSeats: 0,
  secondsLeftInTrial: undefined,
};

export default CurrentPlanPanel;
