import styled from 'styled-components/macro';

export default styled.div`
  font-size: 12px;
  color: white;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 50%;
  min-width: 14px;
  min-height: 14px;
  text-align: center;
  padding-right: 2px;
  padding-left: 2px;
  line-height: 14px;
`;
