import styled, { css } from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';

import {
  borderColor,
  BorderColorProps,
  boxShadow,
  BoxShadowProps,
  color,
  ColorProps,
  typography,
  TypographyProps,
} from 'src/modules/prisma';

import { identiconSizeVariants } from '../variants';

const highlightedStyle = css`
  ::after {
    ${color};
    content: '';
    height: 2px;
    width: 60%;
    margin: 0 20%;
    position: absolute;
    left: 0;
    bottom: -${themeGet('space.3')}px;
    border-radius: 4px;
  }
`;

const selectedStyle = css`
  ::before {
    content: '';
    border: 2px solid;
    ${borderColor};
    ${boxShadow};
    border-radius: 50%;
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
  }
`;

export type Props = BorderColorProps &
  BoxShadowProps &
  ColorProps &
  TypographyProps & {
    highlighted?: boolean;
    selected?: boolean;
  };

const IdenticonWrapper = styled.span<Props>`
  ${color};
  ${typography};
  align-items: center;
  display: flex;
  font-size: ${identiconSizeVariants}px;
  font-style: normal;
  height: 100%;
  justify-content: center;
  position: relative;
  text-transform: uppercase;
  user-select: none;
  width: 100%;
  ${props => props.highlighted && highlightedStyle}
  ${props => props.selected && selectedStyle}
`;

IdenticonWrapper.defaultProps = {
  color: 'white',
  fontFamily: 'sansSerif',
  highlighted: undefined,
  lineHeight: '1em',
  selected: undefined,
};

export default IdenticonWrapper;
