import { defineMessages } from 'react-intl';

export default defineMessages({
  pageHeader: {
    id: 'Step2Page.pageHeader',
    defaultMessage: 'Tell us about your team',
  },
  placeholderName: {
    id: 'Step2Page.placeholderName',
    defaultMessage: 'Business name',
  },
  placeholderSegmentationTeamSize: {
    id: 'Step2Page.placeholderSegmentationTeamSize',
    defaultMessage: 'How big is your team?',
  },
  placeholderPhone: {
    id: 'Step2Page.placeholderPhone',
    defaultMessage: 'Phone number',
  },
  placeholderWebsite: {
    id: 'Step2Page.placeholderWebsite',
    defaultMessage: 'Website',
  },
  labelNewsletter: {
    id: 'Step2Page.labelNewsletter',
    defaultMessage: 'Get feature updates and tips via email',
  },
  labelFinishButton: {
    id: 'Step2Page.labelFinishButton',
    defaultMessage: 'Finish',
  },
  labelNextButton: {
    id: 'Step2Page.labelNextButton',
    defaultMessage: 'Next',
  },
  requiredField: {
    id: 'Step2Page.requiredField',
    defaultMessage: 'Required field',
  },
});
