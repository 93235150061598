import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';
import { bodyTextStyle } from '@picter/prisma';

import SelectFieldMenu from './SelectFieldMenu';

export const SelectFieldLabel = styled.label`
  ${bodyTextStyle};
  color: ${themeGet('colors.gray.600')};
  margin-bottom: 4px;
  margin-left: 4px;

  ${SelectFieldMenu /* sc-sel */}:focus ~ & {
    color: ${themeGet('colors.primary')};
  }

  ${SelectFieldMenu /* sc-sel */}.error ~ & {
    color: ${themeGet('colors.danger')};
  }

  ${SelectFieldMenu /* sc-sel */}:disabled ~ & {
    color: ${themeGet('colors.gray.600')};
  }
`;

export default SelectFieldLabel;
