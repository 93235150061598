import React, { forwardRef, useEffect, useState } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import screenfull from 'screenfull';
import { createGlobalStyle } from 'styled-components';

const LightThumbnailStyles = createGlobalStyle`
  .react-player__preview {
    background-size: contain !important;
    background-repeat: no-repeat;
    position: relative;
    z-index: 50;
  }
`;

interface VideoProps extends ReactPlayerProps {
  src: string;
  seeking: boolean;
  fullscreen: boolean;
  startAt?: number;
  onFullscreen?: (event: Event) => void;
  onFullscreenError?: (event: Event) => void;
  onFullscreenOff?: (event: Event) => void;
}

const Video = forwardRef<ReactPlayer, VideoProps>(
  (
    {
      src,
      fullscreen,
      light,
      progressInterval = 100,
      startAt,
      onFullscreen,
      onFullscreenError,
      onFullscreenOff,
      onReady,
      ...props
    },
    ref,
  ) => {
    const [player, setPlayer] = useState<ReactPlayer>();

    /** Register fullscreen listeners if existent */
    useEffect(() => {
      if (screenfull.isEnabled && (onFullscreen || onFullscreenOff)) {
        const fullscreenChangeListener = (event: Event) => {
          const isInFullscreen =
            screenfull.isEnabled && screenfull.isFullscreen;

          if (isInFullscreen) onFullscreen?.(event);
          else onFullscreenOff?.(event);
        };

        const fullscreenErrorListener = (event: Event) => {
          onFullscreenError?.(event);
        };

        screenfull.on('change', fullscreenChangeListener);
        screenfull.on('error', fullscreenErrorListener);

        return () => {
          if (screenfull.isEnabled) {
            screenfull.off('change', fullscreenChangeListener);
            screenfull.off('error', fullscreenErrorListener);
          }
        };
      }

      return () => undefined;
    }, []);

    /** Request fullscreen in case property is true */
    useEffect(() => {
      if (screenfull.isEnabled && fullscreen && player) {
        const videoEl = player.getInternalPlayer() as HTMLVideoElement;
        screenfull.request(videoEl).catch(onFullscreenError);
      }

      return undefined;
    }, [fullscreen, player]);

    useEffect(() => {
      if (typeof startAt === 'number' && player) player.seekTo(startAt);
    }, [startAt, player]);

    return (
      <>
        {light && <LightThumbnailStyles />}
        <ReactPlayer
          {...props}
          ref={ref}
          light={light}
          url={src}
          progressInterval={progressInterval}
          onReady={newPlayer => {
            setPlayer(newPlayer);
            onReady?.(newPlayer);
          }}
          controls={false}
        />
      </>
    );
  },
);

Video.displayName = 'Video';

export default Video;
