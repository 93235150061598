import React from 'react';
import { toast, toastRaw, TOAST_POSITION } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';
import { formatForLocalAction } from 'src/modules/torii';
import { fromJS } from 'immutable';

import validateImageUpload from 'src/utils/validate-image-upload';
import { ImageProofFilter } from 'src/utils/accessors/project-image';
import uploadStorage from 'src/utils/upload-storage';
import messages from 'src/messages';
import injectPublicLinkKey, {
  replacePublicLinkKey,
} from 'src/utils/inject-public-link-key';
import UndoToast from 'src/components/UndoToast';
import { viewModeTypes } from 'src/routes/constants';
import ErrorToast from 'src/components/ErrorToast';
import { get } from 'src/utils/accessors';

import {
  addImagesToCollection,
  createCollectionForInboxLink,
} from './collection';

const delay = ms => new Promise(res => setTimeout(res, ms));

function partition(array, predicate) {
  return array.reduce(
    ([pass, fail], elem, index) => {
      return predicate(elem, index)
        ? [[...pass, elem], fail]
        : [pass, [...fail, elem]];
    },
    [[], []],
  );
}

export const uploadProjectImages = async (
  { projectId, projectPublicKey, inboxLinkKey },
  filesBlob,
  { onError, onExistingFileName, overrideFile } = {}, // options
  props = {},
) => {
  const {
    load,
    localCreate,
    bulkCreate,
    localBulkCreate,
    localDestroy,
    localUpdate,
    validate = validateImageUpload,
    location: {
      query: { filter },
    },
    match,
    collection,
    acceptedFileTypes,
  } = props;

  const groupId = projectPublicKey;
  const { params } = match;
  const { viewMode } = params;
  const isRequestFilesRoute = viewMode === viewModeTypes.REQUEST_FILES;
  const collectionId = get(collection, 'id');

  if (isRequestFilesRoute && !collectionId) {
    const createdCollection = await createCollectionForInboxLink(
      { projectId, projectPublicKey },
      {
        localCreate,
      },
    );
    const createdInboxLinkKey = get(
      createdCollection,
      'attributes.inbox-link-key',
    );

    return uploadProjectImages(
      { projectId, projectPublicKey, inboxLinkKey: createdInboxLinkKey },
      filesBlob,
      { onError, onExistingFileName, overrideFile },
      { ...props, collection: fromJS(createdCollection) },
    );
  }

  let proofingStatus = null;

  if (filter === ImageProofFilter.ANY || filter === ImageProofFilter.REJECTED) {
    proofingStatus = filter === ImageProofFilter.ANY;
  }

  const preparedData = filesBlob.map(fileBlob => {
    const fileTypeStartsAt = fileBlob.name.lastIndexOf('.');
    const fileNameWithoutType = fileBlob.name.substring(0, fileTypeStartsAt);
    const titleLength = Math.min(fileNameWithoutType.length, 100);
    const blobTitle = fileNameWithoutType.substring(0, titleLength);

    return {
      type: 'ws-project-images',
      attributes: {
        title: blobTitle,
        'file-name': fileBlob.name,
        approved: proofingStatus,
        'mime-type': fileBlob.type,
      },
      relationships: {
        project: { data: { id: projectId, type: 'ws-projects' } },
      },
    };
  });

  const API_URL = process.env.REACT_APP_API_URL;

  const response = await fetch(
    replacePublicLinkKey(
      `${API_URL}/app-workspace/ws-project-images?publicKey=${projectPublicKey}${
        overrideFile !== undefined ? `&override=${overrideFile}` : ''
      }`,
      match,
    ),
    {
      method: 'POST',
      body: JSON.stringify({ data: preparedData }),
      credentials: 'include',
    },
  );

  if (!response.ok) {
    const error = await response.json();
    const handled = onError(error); // image quota errors
    if (!handled) {
      switch (error.statusCode) {
        case 400 /* Bad request (e.g. unsupported file extension) */: {
          // parsing validation errors key (data.0.attributes.mime-type)
          const keyRegex = /^data\.(\d+)\.attributes\.mime-type$/;
          const unsupportedFilesIndices = Object.keys(error).map(errorKey => {
            const keyMatch = keyRegex.exec(errorKey);

            if (keyMatch) {
              return Number(keyMatch[1]);
            }

            return null;
          });

          const [supportedFiles, unsupportedFiles] = partition(
            filesBlob,
            (fileBlob, index) => !unsupportedFilesIndices.includes(index),
          );

          unsupportedFiles.forEach(file => {
            toastRaw(
              <ErrorToast
                title={file.name}
                errors={['This file type is not supported.']}
              />,
              {
                autoClose: false,
                closeOnClick: false,
                position: TOAST_POSITION.BOTTOM_RIGHT,
              },
            );
          });

          if (supportedFiles.length) {
            uploadProjectImages(
              { projectId, projectPublicKey, inboxLinkKey },
              supportedFiles,
              {
                onError,
                onExistingFileName,
              },
              props,
            );
          }

          return null;
        }

        case 409: {
          const confirm = shouldOverride => {
            uploadProjectImages(
              { projectId, projectPublicKey, inboxLinkKey },
              filesBlob,
              {
                onError,
                onExistingFileName,
                overrideFile: shouldOverride,
              },
              props,
            );
          };
          onExistingFileName(confirm);
          return null;
        }
        case 403:
          toast.error(
            <FormattedMessage
              {...messages.messageErrorMessage}
              values={{ message: error._error }}
            />,
          );
          return null;
        default:
          throw error;
      }
    }
  }

  return response.json().then(async ({ data: images } = {}) => {
    // We need to check if last promise returned images (it may return nothing in case we match one of the cases inside the switch)
    if (images) {
      if (collectionId) {
        // If user is inside a collection, get last collectionImage order to upload it
        const collectionImages = get(collection, 'relationships.images');
        const lastCollectionImage = collectionImages.get(-1);
        const lastCollectionImageOrder =
          lastCollectionImage && get(lastCollectionImage, 'attributes.order');
        const collectionImagesIds = collectionImages.map(image =>
          get(image, 'relationships.image.id'),
        );

        const imagesToAddToCollection = images
          .map(image => image.id)
          .filter(imageId => !collectionImagesIds.includes(imageId));

        if (imagesToAddToCollection.length > 0) {
          addImagesToCollection(
            {
              imagesIds: imagesToAddToCollection,
              collectionId,
              projectPublicKey,
              initialOrder: lastCollectionImageOrder,
              inboxLinkKey,
            },
            { bulkCreate, match },
          );
        }
      }

      const chunkImagesUploadConfig = images.map((image, index) => {
        const fileBlob = filesBlob[index];
        const fileId = image.id;
        const uploadEndpoint = get(
          image,
          'attributes.direct-bucket-upload-url',
        );
        const mimeType = get(image, 'attributes.mime-type');
        const newVersion = get(image, 'attributes.new-version-pending');

        return [
          fileId,
          {
            fileBlob,
            uploadEndpoint,
            mimeType,
            onSuccess: async () => {
              await localUpdate('wsProjectImages', {
                id: fileId,
                attributes: {
                  uploadPending: false,
                },
              });
            },
            onFail: async ({ file }) => {
              const action = newVersion ? load : localDestroy;
              const projectImageId = file.id;
              await action('wsProjectImages', {
                id: projectImageId,
              });
            },
            validate: file => validate(file, acceptedFileTypes),
            newVersion,
          },
        ];
      });

      await uploadStorage.queueFiles(groupId, chunkImagesUploadConfig);
      //  Awaiting small delay before calling bulkCreate to avoid multiple re-renders at the same time
      // Once the image creation and upload storage queue files trigger the re-render.
      await delay(250);
      await localBulkCreate(
        'wsProjectImages',
        formatForLocalAction({ data: images }),
        {
          worker: images.length > 30,
        },
      );
    }
  });
};

export const addApprovalProjectImages = (
  projectPublicKey,
  userId,
  imagesIds,
  { bulkCreate, match },
) => {
  const { querySerializers } = injectPublicLinkKey(
    {
      querySerializers: {
        publicKey: () => `publicKey=${projectPublicKey}`,
      },
    },
    match,
  );
  return bulkCreate(
    'wsApprovals',
    imagesIds.map(imageId => ({
      attributes: {},
      relationships: {
        image: { type: 'ws-project-images', id: imageId },
      },
    })),
    {
      querySerializers,
    },
  );
};

export const addApprovalProjectImage = (
  projectPublicKey,
  userId,
  imageId,
  { bulkCreate, match },
) => {
  return addApprovalProjectImages(projectPublicKey, userId, [imageId], {
    bulkCreate,
    match,
  });
};

export const removeApprovalProjectImages = (
  projectPublicKey,
  approvalsIds,
  { bulkDestroy, match },
) => {
  const { querySerializers } = injectPublicLinkKey(
    {
      querySerializers: {
        publicKey: () => `publicKey=${projectPublicKey}`,
      },
    },
    match,
  );
  return bulkDestroy(
    'wsApprovals',
    approvalsIds.map(id => ({ id })),
    {
      querySerializers,
    },
  );
};

export const removeApprovalProjectImage = (
  projectPublicKey,
  approvalId,
  { bulkDestroy, match },
) => {
  return removeApprovalProjectImages(projectPublicKey, [approvalId], {
    bulkDestroy,
    match,
  });
};

export const replaceImageVersion = async ({
  imageId,
  fileBlob,
  match,
  update,
  load,
  localUpdate,
  validate,
}) => {
  const projectPublicKey = match.params.projectId;
  const { querySerializers } = injectPublicLinkKey(
    {
      querySerializers: {
        publicKey: () => `publicKey=${projectPublicKey}`,
      },
    },
    match,
  );
  const { data: image } = await update(
    'wsProjectImages',
    {
      id: imageId,
      querySerializers,
      attributes: {
        mimeType: fileBlob.type,
        fileName: fileBlob.name,
      },
    },
    {
      postfix: 'versions',
    },
  );

  const uploadEndpoint = get(image, 'attributes.direct-bucket-upload-url');
  const mimeType = get(image, 'attributes.mime-type');

  await uploadStorage.queueFile(projectPublicKey, imageId, {
    fileBlob,
    mimeType,
    uploadEndpoint,
    onSuccess: async () => {
      await localUpdate('wsProjectImages', {
        id: imageId,
        attributes: {
          uploadPending: false,
        },
      });
    },
    onFail: () => {
      load('wsProjectImages', { id: get(image, 'id') });
    },
    validate,
    newVersion: true,
  });
};

export const removeImage = async ({
  image,
  projectPublicUrlKey,
  match,
  afterUndo,
  localDestroy,
  localCreate,
  destroy,
}) => {
  const { querySerializers, publicLink } = injectPublicLinkKey(
    {
      querySerializers: {
        publicKey: () => `publicKey=${projectPublicUrlKey}`,
      },
    },
    match,
  );

  const imageId = get(image, 'id');
  const groupId =
    match.params.viewMode === viewModeTypes.DEFAULT
      ? match.params.projectId
      : publicLink;

  uploadStorage.dequeueFile(groupId, imageId);

  localDestroy('wsProjectImages', {
    id: get(image, 'id'),
  });

  // Using this object to store cancenOnClose so we can use object referency to share
  // state between functions. Something similar to using this keyword. Needed because we
  // want to update the state in onUndo that is called after onClose creation
  // and we want the onClose to be affected by it.
  const toastParameters = { cancelOnClose: false };

  const onClose = () => {
    if (!toastParameters.cancelOnClose) {
      destroy('wsProjectImages', {
        id: imageId,
        querySerializers,
      });
    }
  };

  const onUndo = async () => {
    toastParameters.cancelOnClose = true;

    const jsImage = image.toJS();

    // Parses relationships to something we can send to torii on create action
    const relationships = Object.entries(jsImage.relationships).reduce(
      (acc, [nextKey, nextValue]) => {
        // Parses relationship that were parsed by torii selector back to json api format
        if (!nextValue.data) {
          const data = Array.isArray(nextValue)
            ? nextValue.map(item => ({ id: item.id, type: item.type }))
            : { id: nextValue.id, type: nextValue.type };

          acc[nextKey] = {
            data,
          };
        }
        return acc;
      },
      jsImage.relationships,
    );

    const savedImage = { ...jsImage, relationships };

    await localCreate(
      'wsProjectImages',
      formatForLocalAction({ data: [savedImage] })[0],
    );

    if (afterUndo) afterUndo();
  };

  toastRaw(
    <UndoToast imageTitle={get(image, 'attributes.title')} onUndo={onUndo} />,
    {
      autoClose: 3000,
      onClose,
    },
  );
};
