import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FullPageContainer,
  FullScreenContainer,
  HelpcenterIcon,
} from '@picter/prisma';

import { Box, Button } from 'src/modules/prisma';
import GeneralLayout from 'src/layouts/GeneralLayout';
import NewPicterLogoSvg from 'src/assets/NewPicterLogoSvg';

import EmptyStateBox from './EmptyStateBox';

import messages from '../messages';

type Props = {
  graphic: React.ReactNode;
  title: React.ReactNode;
  message?: React.ReactNode;
  action?: React.ReactNode;
  actionHint?: React.ReactNode;
  onClickHelp?: () => void;
  onClickBack?: () => void;
};

function EmptyStatePage({
  graphic,
  title,
  message,
  action,
  actionHint,
  onClickHelp,
  onClickBack,
}: Props) {
  return (
    <FullScreenContainer>
      <GeneralLayout>
        <GeneralLayout.Top>
          <GeneralLayout.Top.AlignLeft>
            <NewPicterLogoSvg />
            {onClickBack && (
              <Button
                onClick={onClickBack}
                color="primary"
                backgroundColor="transparent"
                textStyle="action.regular"
                alignItems="flex-end"
                py={1}
                height="30px"
              >
                <FormattedMessage {...messages.labelBackToHome} />
              </Button>
            )}
          </GeneralLayout.Top.AlignLeft>
        </GeneralLayout.Top>
        <GeneralLayout.Content>
          <FullPageContainer>
            <EmptyStateBox
              graphic={graphic}
              title={title}
              message={message}
              action={action}
              actionHint={actionHint}
            />
            {onClickHelp && (
              <Box position="absolute" bottom={7} left={7}>
                <HelpcenterIcon onClick={onClickHelp} />
              </Box>
            )}
          </FullPageContainer>
        </GeneralLayout.Content>
      </GeneralLayout>
    </FullScreenContainer>
  );
}

export default EmptyStatePage;
