import { defineMessages } from 'react-intl';

export default defineMessages({
  messageSomethingWentWrong: {
    id: 'ErrorPage.messageSomethingWentWrong',
    defaultMessage: 'Oops, something went wrong!',
  },
  messageTryReloading: {
    id: 'ErrorPage.messageTryReloading',
    defaultMessage: 'Try reloading the page',
  },
  labelReloadPage: {
    id: 'ErrorPage.labelReloadPage',
    defaultMessage: 'Reload page',
  },
});
