import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Comment from 'src/containers/ProjectComment';
import { get } from 'src/utils/accessors';

import { useDynamicSizeItemMeasurer } from 'src/components/DynamicSizeList';

const CommentRenderer = memo(({ data, index, style }) => {
  const { data: comment, extraStyle } = data[index];
  const itemMeasurerRef = useDynamicSizeItemMeasurer(index);

  return (
    <div key={get(comment, 'id')} style={{ ...style, ...extraStyle }}>
      <div ref={itemMeasurerRef}>
        <Comment comment={comment} index={index} />
      </div>
    </div>
  );
});

CommentRenderer.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({ id: PropTypes.string }),
      extraStyle: PropTypes.shape({}),
    }),
  ).isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.shape({
    position: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
  }).isRequired,
};

export default CommentRenderer;
