import React, {
  useCallback,
  useState,
  useMemo,
  useReducer,
  useEffect,
} from 'react';
import { Anchor, toastInfo, toast } from '@picter/prisma';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { DoneAll as DoneAllSvg } from '@styled-icons/material';
import copy from 'copy-to-clipboard';

import {
  historyShape,
  locationShape,
  matchShape,
} from 'src/utils/app-prop-types';
import { publicLibraryProjectUrl } from 'src/routes/urls';
import useFilteredUrls from 'src/hooks/use-filtered-urls';
import { useProject } from 'src/hooks/use-resource';
import useFormatMessage from 'src/hooks/use-format-message';
import { useToriiActions } from 'src/modules/torii';
import {
  publicLinkTypes,
  getProjectPublicLinkByType,
} from 'src/utils/accessors/project';
import { viewModeTypes } from 'src/routes/constants';
import { sortedProjectCollectionsSelector } from 'src/selectors/collection';
import { get } from 'src/utils/accessors';

import ShareableLinkModal from './ShareableLinkModal';
import ProjectShareContentChooser from './ProjectShareContentChooser';
import messages from '../messages';

const attributeSettings = ['downloadEnabled', 'commentsEnabled'];

function reducer(state, action) {
  return {
    ...state,
    [action.key]: {
      ...state[action.key],
      ...action,
    },
  };
}

function ShareableReviewLinkModal({
  history,
  match: {
    params: { collectionId, projectId: projectPublicUrlKey },
  },
  location: {
    query: { filter },
  },
}) {
  const [loadingLink, setLoadingLink] = useState(false);
  const formatMessage = useFormatMessage();
  const { create, update, localUpdateRelationship } = useToriiActions();
  const { filteredProjectUrl } = useFilteredUrls(filter);
  const project = useProject({
    id: projectPublicUrlKey,
    include: ['publicLinks.collections', 'collections'],
  });
  const projectId = get(project, 'id');
  const projectPublicLink = getProjectPublicLinkByType(
    project,
    publicLinkTypes.REVIEW,
  );
  const projectPublicLinkUrl =
    projectPublicLink && get(projectPublicLink, 'attributes.enabled')
      ? publicLibraryProjectUrl({
          projectId: get(projectPublicLink, 'attributes.key'),
          viewMode: viewModeTypes.REVIEW,
        })
      : null;
  const collections = sortedProjectCollectionsSelector({ project });
  const [settings, dispatch] = useReducer(reducer, {
    downloadEnabled: {
      name: 'Download',
      value: projectPublicLink
        ? get(projectPublicLink, 'attributes.downloadEnabled')
        : true,
      label: <FormattedMessage {...messages.labelDownloadSetting} />,
    },
    commentsEnabled: {
      name: 'Comments',
      value: projectPublicLink
        ? get(projectPublicLink, 'attributes.commentsEnabled')
        : true,
      label: <FormattedMessage {...messages.labelCommentsSetting} />,
    },
    content: {
      name: 'Content',
      value: projectPublicLink
        ? get(projectPublicLink, 'relationships.collections')
            .map(c => get(c, 'id'))
            .toJS()
        : [],
      label: <FormattedMessage {...messages.labelContentSetting} />,
      render: renderProps => (
        <ProjectShareContentChooser
          {...renderProps}
          options={collections.map(c => ({
            id: get(c, 'id'),
            name: get(c, 'attributes.name'),
          }))}
        />
      ),
    },
  });

  useEffect(() => {
    dispatch({
      key: 'downloadEnabled',
      value: projectPublicLink
        ? get(projectPublicLink, 'attributes.downloadEnabled')
        : true,
    });
    dispatch({
      key: 'commentsEnabled',
      value: projectPublicLink
        ? get(projectPublicLink, 'attributes.commentsEnabled')
        : true,
    });
    dispatch({
      key: 'content',
      value: projectPublicLink
        ? get(projectPublicLink, 'relationships.collections')
            .map(c => get(c, 'id'))
            .toJS()
        : [],
    });
  }, [projectPublicLink]);

  const handleClose = useCallback(
    () =>
      history.push(
        filteredProjectUrl({ collectionId, projectId: projectPublicUrlKey }),
      ),
    [collectionId, filteredProjectUrl, history, projectPublicUrlKey],
  );

  const updatePublicLinkAttribute = useCallback(
    (key, value) => {
      if (projectPublicLink) {
        if (get(projectPublicLink, `attributes.${key}`) !== value) {
          update('wsPublicLinks', {
            id: get(projectPublicLink, 'id'),
            attributes: {
              [key]: value,
            },
            relationships: {
              ...(settings.content.value.length > 0
                ? {
                    collections: settings.content.value.map(id => ({
                      id,
                      type: 'ws-collections',
                    })),
                  }
                : {}),
            },
          });
        }

        toastInfo(formatMessage(messages[`message${key}`], { enabled: value }));
      }
    },
    [update, formatMessage, projectPublicLink, settings],
  );

  const updatePublicLinkCollections = useCallback(
    (
      collectionIds,
      {
        localOnly = false,
        publicLinkId = projectPublicLink && get(projectPublicLink, 'id'),
      } = {},
    ) => {
      if (publicLinkId) {
        const collectionsRelationshipData = collectionIds.map(id => ({
          type: 'ws-collections',
          id,
        }));

        if (!localOnly) {
          update('wsPublicLinks', {
            id: publicLinkId,
            relationships: { collections: collectionsRelationshipData },
          });
        }

        localUpdateRelationship('wsPublicLinks', {
          id: publicLinkId,
          relation: 'collections',
          data: collectionsRelationshipData,
        });
      }
    },
    [update, localUpdateRelationship, projectPublicLink],
  );

  const handleUpdateSetting = useCallback(
    (key, value) => {
      if (key === 'content') {
        const { action, payload } = value;

        if (action === 'removeAll') {
          updatePublicLinkAttribute('accessToAllFiles', true);
          updatePublicLinkCollections([], { localOnly: true });
          dispatch({ key, value: [] });
        } else {
          const updatedCollectionIds =
            action === 'add'
              ? [...settings.content.value, payload]
              : settings.content.value.filter(id => id !== payload);

          updatePublicLinkCollections(updatedCollectionIds);
          dispatch({ key, value: updatedCollectionIds });
        }
      } else {
        updatePublicLinkAttribute(key, value);
        dispatch({ key, value });
      }
    },
    [
      dispatch,
      updatePublicLinkAttribute,
      updatePublicLinkCollections,
      settings,
    ],
  );

  const handleDisableLink = useCallback(() => {
    update('wsPublicLinks', {
      id: get(projectPublicLink, 'id'),
      attributes: {
        enabled: false,
      },
      relationships: {
        ...(settings.content.value.length > 0
          ? {
              collections: settings.content.value.map(id => ({
                id,
                type: 'ws-collections',
              })),
            }
          : {}),
      },
      optimistic: true,
    });
  }, [update, projectPublicLink, settings]);

  const copyLinkFromResponse = responseLink => {
    const link = publicLibraryProjectUrl({
      projectId: responseLink.attributes.key,
      viewMode: viewModeTypes.REVIEW,
    });
    // {message: null} Forces an error on the prompt fallback. This way we can
    // handle it with a try catch and avoid using the prompt fallback form the lib.
    try {
      copy(link, { message: null });
      toast.info(<FormattedMessage {...messages.messageShareUrlCopySuccess} />);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const handleEnableLink = useCallback(async () => {
    let responseLink = null;
    if (projectPublicLink) {
      update('wsPublicLinks', {
        id: get(projectPublicLink, 'id'),
        attributes: {
          enabled: true,
        },
        relationships: {
          ...(settings.content.value.length > 0
            ? {
                collections: settings.content.value.map(id => ({
                  id,
                  type: 'ws-collections',
                })),
              }
            : {}),
        },
        optimistic: true,
      });
      responseLink = projectPublicLink.toJS();
    } else {
      setLoadingLink(true);
      const result = await create('wsPublicLinks', {
        attributes: {
          type: publicLinkTypes.REVIEW,
          ...Object.entries(settings).reduce((acc, [key, { value }]) => {
            if (attributeSettings.includes(key)) {
              acc[key] = value;
            }
            return acc;
          }, {}),
        },
        relationships: {
          project: { id: projectId, type: 'ws-projects' },
          ...(settings.content.value.length > 0
            ? {
                collections: settings.content.value.map(id => ({
                  id,
                  type: 'ws-collections',
                })),
              }
            : {}),
        },
      });
      responseLink = result.data;

      if (settings.content.value.length > 0) {
        updatePublicLinkCollections(settings.content.value, {
          localOnly: true,
          publicLinkId: responseLink.id,
        });
      }

      setLoadingLink(false);
    }
    copyLinkFromResponse(responseLink);
  }, [
    create,
    update,
    projectId,
    settings,
    projectPublicLink,
    updatePublicLinkCollections,
  ]);

  const description = useMemo(
    () => ({
      icon: <DoneAllSvg />,
      message: (
        <>
          <FormattedHTMLMessage {...messages.messageReviewLinkDescription} />{' '}
          <Anchor
            href="http://learn.picter.com/en/articles/3296464"
            target="blank"
            textStyle="action.small"
          >
            <FormattedMessage {...messages.labelLearnMore} />
          </Anchor>
        </>
      ),
    }),
    [],
  );

  return (
    <ShareableLinkModal
      title={
        <FormattedHTMLMessage {...messages.titleShareableReviewLinkModal} />
      }
      loadingLink={loadingLink && !projectPublicLinkUrl}
      description={description}
      link={projectPublicLinkUrl}
      onClickClose={handleClose}
      enableMessage={
        <FormattedMessage {...messages.labelGetShareableReviewLink} />
      }
      onClickEnableLink={handleEnableLink}
      onClickDisableLink={handleDisableLink}
      onUpdateSetting={handleUpdateSetting}
      settings={settings}
      type="review"
    />
  );
}

ShareableReviewLinkModal.propTypes = {
  history: historyShape.isRequired,
  location: locationShape.isRequired,
  match: matchShape.isRequired,
};

export default ShareableReviewLinkModal;
