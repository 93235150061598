import {
  IntlRelativeTimeFormatOptions,
  Unit,
} from '@formatjs/intl-relativetimeformat';
import React from 'react';
import { FormattedRelativeTime } from 'react-intl';

/**
 * Supposed to match with the following kind of string:
 *
 * 15 minutos atrás / em 15 minutos
 * vor 5 Tagen / in 5 Tagen
 * 30 seconds ago / in 30 seconds
 */
const formattedTimeRegex = /(\d+)\s(\w+)/;

/**
 * Further formats relative time
 * 15 minutos atrás -> 15m
 * vor 5 Tagen -> 5T
 * 30 seconds ago -> 30s
 */
export default function FormattedRelativeTinyTime(
  props: Exclude<IntlRelativeTimeFormatOptions, 'style'> & {
    value: number;
    unit: Unit;
    format: string;
    updateIntervalInSeconds: number;
    children: (formattedDate: string) => React.ReactElement;
  },
) {
  return (
    <FormattedRelativeTime {...props} style="long">
      {formattedTime => {
        const match = formattedTimeRegex.exec(formattedTime);

        if (match === null) return formattedTime; // now

        const [, time, unit] = match;
        return `${time}${unit[0]}`;
      }}
    </FormattedRelativeTime>
  );
}
