import styled from 'styled-components/macro';

const itemHeight = 76;
const maxVisible = 4;

export default styled.div`
  height: ${props => {
    if (!props.length) return 100;
    return props.length <= maxVisible
      ? props.length * itemHeight
      : itemHeight * maxVisible;
  }}px;
  overflow: hidden;

  p {
    line-height: inherit;
  }
`;
