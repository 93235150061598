import { defineMessages } from 'react-intl';

export default defineMessages({
  labelMembersPage: {
    id: 'SpaceMembersPage.labelMembersPage',
    defaultMessage: 'Members',
  },
  titleRoleInThisSpace: {
    id: 'SpaceMembersPage.titleRoleInThisSpace',
    defaultMessage: 'Choose role',
  },
  labelRemoveFromThisSpace: {
    id: 'SpaceMembersPage.labelRemoveFromThisSpace',
    defaultMessage: 'Remove from workspace',
  },
  messageManagerRole: {
    id: 'SpaceMembersPage.messageManagerRole',
    defaultMessage: 'Can manage members',
  },
  messageMemberRole: {
    id: 'SpaceMembersPage.messageMemberRole',
    defaultMessage: 'Has full access on all projects',
  },
  messageViewerRole: {
    id: 'SpaceMembersPage.messageViewerRole',
    defaultMessage: 'Can view and comment all projects',
  },
  titleRemoveMemberModal: {
    id: 'SpaceMembersPage.titleRemoveMemberModal',
    defaultMessage: 'Remove member',
  },
  messageRemoveMemberModal: {
    id: 'SpaceMembersPage.messageRemoveMemberModal',
    defaultMessage:
      'Removing a user from this team will remove their access to all projects in this space.',
  },
  labelRemoveMemberCancel: {
    id: 'SpaceMembersPage.labelRemoveMemberCancel',
    defaultMessage: 'Cancel',
  },
  labelRemoveMemberConfirm: {
    id: 'SpaceMembersPage.labelRemoveMemberConfirm',
    defaultMessage: 'Remove',
  },
  messageErrorMessage: {
    id: 'ProjectShareModal.messageErrorMessage',
    defaultMessage: 'Error: {message}',
  },
  labelAddMember: {
    id: 'SpaceMembersPage.labelAddMember',
    defaultMessage: 'Add member',
  },
  messageInvitationSent: {
    id: 'SpaceMembersPage.messageInvitationSent',
    defaultMessage: '{email} has been invited',
  },
});
