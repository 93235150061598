import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Heading, Action, A } from '@picter/prisma';
import NotificationsHeaderWrapper from '../styles/NotificationsHeader';
import messages from '../messages';

const NotificationsHeader = ({ markAllAsRead, unread }) => {
  return (
    <NotificationsHeaderWrapper>
      <Heading textSize="small">
        <FormattedMessage {...messages.notificationsTitle} />
      </Heading>
      {unread > 0 && (
        <A onClick={markAllAsRead} color="muted">
          <Action color="secondary" textSize="small">
            <FormattedMessage {...messages.markAllAsReadLabel} />
          </Action>
        </A>
      )}
    </NotificationsHeaderWrapper>
  );
};

NotificationsHeader.propTypes = {
  unread: PropTypes.number.isRequired,
  markAllAsRead: PropTypes.func.isRequired,
};

export default NotificationsHeader;
