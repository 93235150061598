import { defineMessages } from 'react-intl';

export default defineMessages({
  placeholderCommentInput: {
    id: 'ProjectCommentsContextSidebar.placeholderCommentInput',
    defaultMessage: 'Say something',
  },
  labelCommentInputButton: {
    id: 'ProjectCommentsContextSidebar.labelCommentInputButton',
    defaultMessage: 'Send',
  },
});
