import React from 'react';

import Avatar from 'src/components/Avatar';

import DropdownItemWrapper from '../styles/DropdownItemWrapper';

const MentionComponent = (entry, _, __, ___, hovering) => {
  return (
    <DropdownItemWrapper hovering={hovering}>
      <Avatar email={entry.email} name={entry.display} size="small" />
      <div>{entry.display}</div>
    </DropdownItemWrapper>
  );
};

export default MentionComponent;
