import { Unit } from '@formatjs/intl-relativetimeformat';

/**
 * Returns number of seconds relative to current date time.
 *
 * Positive number means date is in the future.
 * Negative number means date is in the past.
 */
export function getDateDeltaFromNowInSeconds(date: number | string) {
  if (typeof date === 'number') return (date - Date.now()) / 1000;
  return (Date.parse(date) - Date.now()) / 1000;
}

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const MONTH = DAY * 30;
const YEAR = DAY * 364;

export type TimeUnit = {
  unit: Unit;
  valueMs: number;
  threshold?: number;
};

const defaultTimeUnits: TimeUnit[] = [
  { unit: 'second', valueMs: SECOND, threshold: 45 },
  { unit: 'minute', valueMs: MINUTE, threshold: 50 },
  { unit: 'hour', valueMs: HOUR, threshold: 22 },
  { unit: 'day', valueMs: DAY, threshold: 8 },
  { unit: 'month', valueMs: MONTH, threshold: 12 },
  { unit: 'year', valueMs: YEAR },
];

export function getDeltaTimeAndUnit(
  to: Date,
  from = new Date(),
  timeUnits: TimeUnit[] = defaultTimeUnits,
): { value: number; unit: Unit } {
  const diff = to.valueOf() - from.valueOf();

  for (let i = 0; i < timeUnits.length; i += 1) {
    const timeUnit = timeUnits[i];
    const timeInUnit = diff / timeUnit.valueMs;
    if (
      Math.abs(timeInUnit) < Math.max(1, timeUnit.threshold || 0) ||
      i === timeUnits.length - 1
    ) {
      return { value: Math.round(timeInUnit), unit: timeUnit.unit };
    }
  }
  return { value: Math.round(diff) / DAY, unit: 'day' };
}
