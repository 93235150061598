import { createContext, useContext } from 'react';

/**
 * Workaround to revalidate useSWR tasks cache when deleting tasks.
 *
 * Comments were being deleted in our redux store but we have to synchronize
 * with SWR cache.
 */
const RevalidateTasksContext = createContext(() => {});
export const RevalidateTasksProvider = RevalidateTasksContext.Provider;
export function useRevalidateTasks() {
  return useContext(RevalidateTasksContext);
}
