import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Container from '../styles/GeneralLayoutContainer';
import Content from '../styles/GeneralLayoutContent';
import { LayoutControlsProvider, LayoutInfoProvider } from '../utils';
import Top from './GeneralLayoutTop';
import Bottom from './GeneralLayoutBottom';

const modes = {
  COMFORTABLE: 'comfortable',
  COMPACT: 'compact',
};

const sizes = {
  TOP_SECTION: {
    [modes.COMFORTABLE]: 88,
    [modes.COMPACT]: 64,
  },
};

function GeneralLayout({ id, className, children }) {
  const [mode, setMode] = useState(modes.COMFORTABLE);
  const info = useMemo(
    () => ({
      modes,
      mode,
      sizes,
    }),
    [mode],
  );

  const controls = useMemo(
    () => ({
      enableCompactMode: () => setMode(modes.COMPACT),
      enableComfortableMode: () => setMode(modes.COMFORTABLE),
    }),
    [setMode],
  );

  return (
    <LayoutControlsProvider value={controls}>
      <LayoutInfoProvider value={info}>
        <Container id={id} className={className}>
          {children}
        </Container>
      </LayoutInfoProvider>
    </LayoutControlsProvider>
  );
}

GeneralLayout.Content = Content;
GeneralLayout.Content.displayName = 'GeneralLayout.Content';

GeneralLayout.Top = Top;
GeneralLayout.Top.displayName = 'GeneralLayout.Top';

GeneralLayout.Bottom = Bottom;
GeneralLayout.Bottom.displayName = 'GeneralLayout.Bottom';

GeneralLayout.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  /**
   * CHILDREN_OPTIONS:
   * GeneralLayout.Top
   * GeneralLayout.Content
   */
  children: PropTypes.node.isRequired,
};

GeneralLayout.defaultProps = {
  id: null,
  className: null,
};

export default GeneralLayout;
