import React from 'react';
import { MediaQuery } from '@picter/prisma';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';

import AuthenticationBanner from 'src/components/AuthenticationBanner';
import { Box } from 'src/modules/prisma';

// eslint-disable-next-line react/prop-types
function LayoutBanner({ children }) {
  return (
    <Box alignItems="center" width={1 / 2}>
      {children}
    </Box>
  );
}

LayoutBanner.defaultProps = {
  children: <AuthenticationBanner />,
};

// eslint-disable-next-line react/prop-types
function LayoutContent({ children }) {
  return <Box width={[1, 1 / 2]}>{children}</Box>;
}

function Layout({ children }) {
  const keyedChildren = groupBy(children, 'type');

  return (
    <Box display="flex" height="100%">
      {keyedChildren[LayoutContent]}
      <MediaQuery
        breakpoint="mobile"
        type="greaterThan"
        render={() => keyedChildren[LayoutBanner]}
      />
    </Box>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

Layout.Banner = LayoutBanner;
Layout.Content = LayoutContent;

export default Layout;
