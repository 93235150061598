import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import DynamicSizeList from 'src/components/DynamicSizeList';
import { component } from 'src/utils/app-prop-types';

const ReverseDynamicSizeList = forwardRef(
  (
    {
      children,
      height,
      itemData,
      onItemsRendered,
      onScroll,
      scrollToIndex,
      ...props
    },
    ref,
  ) => {
    // refs
    const elementRef = useRef(); // list component
    const innerElementRef = useRef(); // big div (content)

    // state
    const [lastVisibleIndex, setLastVisibleIndex] = useState(null);
    const [numberOfItems, setNumberOfItems] = useState(itemData.length);
    const [scrolledByUser, setScrolled] = useState(false);

    const { current: element } = elementRef;
    const { current: innerElement } = innerElementRef;

    const handleItemsRendered = useCallback(
      visibleIndices => {
        const { visibleStopIndex } = visibleIndices;

        setLastVisibleIndex(visibleStopIndex);

        // pass through props
        if (onItemsRendered) {
          onItemsRendered(visibleIndices);
        }
      },
      [onItemsRendered],
    );

    const handleScroll = useCallback(
      scrollState => {
        const { scrollOffset, scrollUpdateWasRequested } = scrollState;
        if (!scrollUpdateWasRequested && scrollOffset > 0 && !scrolledByUser) {
          // scrollUpdateWasRequested false means user itself scrolled and
          // not scrollToItem() or scrollTo()
          setScrolled(true);
        }

        // pass through props
        if (onScroll) {
          onScroll(scrollState);
        }
      },
      [onScroll, scrolledByUser],
    );

    useEffect(() => {
      if (element && innerElement) {
        const { scrollHeight } = innerElement;
        const hasScroll = scrollHeight > height;

        // if it was not scrolled yet the element is mount so scroll to the bottom
        if (!scrolledByUser && hasScroll) {
          element.scrollTo(scrollHeight);
        }
        if (!scrolledByUser && scrollToIndex >= 0) {
          setScrolled(true);
          element.scrollToItem(scrollToIndex, 'start');
        }
      }
      // execute only when scrollHeight changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [element, innerElement && innerElement.scrollHeight]);

    // maintain scroll at the bottom when elements are added to the end
    useEffect(() => {
      const { length: itemDataLength } = itemData;

      if (element && innerElement) {
        const { scrollHeight } = innerElement;

        const numberOfNewItems = itemDataLength - numberOfItems; // important because we can have multiple items added at once
        const lastItemIndex = itemDataLength - 1; // new item that was just added
        const previousLastItemIndex = lastItemIndex - numberOfNewItems; // previous last item
        const wasScrollAtBottom = previousLastItemIndex === lastVisibleIndex;
        if (numberOfNewItems > 0) {
          const hasScroll = scrollHeight > height;

          if (wasScrollAtBottom && hasScroll) {
            element.scrollTo(scrollHeight);
          }
        }

        // resets list style on item deletion
        if (numberOfNewItems < 0) {
          element.resetAfterIndex(0);
        }
      }

      setNumberOfItems(itemDataLength);
      // no need to execute when lastVisibleIndex or element changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemData.length]);

    // maintain scroll at the bottom when height changes (e.g. comment input resized)
    useEffect(() => {
      if (element) {
        const lastItemIndex = itemData.length - 1; // last item index
        const isScrollAtBottom = lastItemIndex === lastVisibleIndex;

        if (isScrollAtBottom) {
          element.scrollToItem(itemData.length - 1);
        }
      }
      // no need to execute when length, lastVisibleIndex or element changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [height]);

    useImperativeHandle(ref, () => elementRef.current);

    return (
      <DynamicSizeList
        height={height}
        innerRef={innerElementRef}
        itemData={itemData}
        onItemsRendered={handleItemsRendered}
        onScroll={handleScroll}
        ref={elementRef}
        {...props}
      >
        {children}
      </DynamicSizeList>
    );
  },
);

ReverseDynamicSizeList.displayName = 'ReverseDynamicSizeList';

ReverseDynamicSizeList.defaultProps = {
  height: undefined,
  width: undefined,
  itemData: undefined,
  itemCount: 0,
  onItemsRendered: undefined,
  onScroll: undefined,
  scrollToIndex: undefined,
};

ReverseDynamicSizeList.propTypes = {
  children: component.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  itemData: PropTypes.arrayOf(PropTypes.object),
  itemCount: PropTypes.number,
  onItemsRendered: PropTypes.func,
  onScroll: PropTypes.func,
  scrollToIndex: PropTypes.number,
};

export default ReverseDynamicSizeList;
