import styled from 'styled-components/macro';

import { Box } from 'src/modules/prisma';

const Panel = styled(Box).attrs({
  gmt: 1,
})`
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border: solid 1px ${props => props.theme.colors.cold.gray['200']};
  display: flex;
  padding: ${props => props.theme.space[4]}px;
  padding-right: ${props => props.theme.space[2]}px;
  width: 100%;
`;

export default Panel;
