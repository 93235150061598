import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import isNumber from 'lodash/isNumber';

import { formattedMessage } from 'src/utils/app-prop-types';
import { Box, Text } from 'src/modules/prisma';
import { discountShape } from 'src/pages/index/settings/{spaceId}/subscription/_special-offer-config';

import messages from '../messages';
import FeaturesPanel from '../styles/PlanInfoFeaturesPanel';
import GridItem from '../styles/GridItem';

function PlanInfo({ action, discount, extra, features, index, label, price }) {
  const currentPrice =
    discount && isNumber(price) ? price - price * discount.amount : price;

  return (
    <>
      <GridItem gridArea={`header-${index}`} p={5}>
        <Text
          fontFamily="sansSerif"
          tag="h3"
          textStyle="heading.regular"
          fontWeight="strong"
        >
          {label}
        </Text>
        <section>
          {isNumber(price) ? (
            <>
              {discount && (
                <Text
                  fontFamily="sansSerif"
                  tag="div"
                  color="primary"
                  textDecoration="line-through"
                  textStyle={[
                    'heading.large',
                    null,
                    null,
                    'heading.small',
                    'heading.large',
                  ]}
                  fontWeight="light"
                >
                  € {price.toFixed(2)}
                </Text>
              )}
              <FormattedMessage
                {...messages.labelPlanPrice}
                values={{
                  date: msg => (
                    <Text fontFamily="sansSerif" textStyle="action.small">
                      {msg}
                      {/* eslint-disable-next-line react/prop-types */}
                      {discount && discount.footnoteSign}
                    </Text>
                  ),
                  price: msg => (
                    <Text
                      fontFamily="sansSerif"
                      tag="span"
                      textStyle={[
                        'heading.xlarge',
                        null,
                        null,
                        'heading.large',
                        'heading.xlarge',
                      ]}
                      fontWeight="light"
                    >
                      {msg}
                    </Text>
                  ),
                  // fix number to two decimal places if it's decimal
                  planPrice:
                    currentPrice % 1 === 0
                      ? currentPrice
                      : currentPrice.toFixed(2),
                }}
              />
            </>
          ) : (
            <Text
              fontFamily="sansSerif"
              tag="span"
              textStyle="heading.xlarge"
              fontWeight="light"
            >
              {price}
            </Text>
          )}
        </section>
        {extra && (
          <Text
            color="grey.600"
            fontFamily="sansSerif"
            tag="p"
            textStyle="body.regular"
            lineHeight="1.5em"
          >
            {extra}
          </Text>
        )}
      </GridItem>
      <GridItem gridArea={`main-${index}`}>
        <FeaturesPanel tag="section" mb={0} p={5} mt={0}>
          <ul>
            {/* eslint-disable react/no-array-index-key */}
            {features.map((feature, fIndex) => (
              <Text
                key={fIndex}
                fontFamily="sansSerif"
                tag="li"
                textStyle="body.regular"
                whiteSpace="pre-line"
              >
                {feature}
              </Text>
            ))}
            {/* eslint-enable react/no-array-index-key */}
          </ul>
          {action && (
            <>
              <br />
              <br />
              <Box mt="auto">{action}</Box>
            </>
          )}
        </FeaturesPanel>
      </GridItem>
      {discount && (
        <GridItem gridArea={`footer-${index}`} p={5}>
          <Text
            color="grey.600"
            fontFamily="sansSerif"
            tag="p"
            textStyle="body.regular"
            lineHeight="1.5em"
          >
            {discount.footnoteSign} {discount.footnote}
          </Text>
        </GridItem>
      )}
    </>
  );
}

PlanInfo.propTypes = {
  action: PropTypes.element,
  discount: discountShape,
  extra: PropTypes.oneOfType([formattedMessage, PropTypes.string]),
  features: PropTypes.arrayOf(formattedMessage),
  index: PropTypes.number.isRequired,
  label: formattedMessage.isRequired,
  price: PropTypes.oneOfType([formattedMessage, PropTypes.number]),
};

PlanInfo.defaultProps = {
  action: undefined,
  discount: undefined,
  extra: undefined,
  features: [],
  price: undefined,
};

export default PlanInfo;
