import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  Anchor,
  ButtonElement as Button,
  toastInfo,
  Spinner,
} from '@picter/prisma';
import { TrackerEvent } from '@picter/tracker';
import { FormattedMessage } from 'react-intl';
import { Link as LinkSvg } from '@styled-icons/material';
import { Flex } from '@rebass/grid';
import copy from 'copy-to-clipboard';

import { Icon } from 'src/modules/prisma';

import ShareableItemBox from './ShareableItemBox';
import SpinnerWrapper from '../styles/SpinnerWrapper';
import messages from '../messages';

function ShareableLinkBox({
  enableMessage,
  link,
  loadingLink,
  onClickEnable,
  onClickDisable,
  type,
}) {
  const copyLinkToClipboard = useCallback(
    event => {
      event.preventDefault();

      copy(link);
      toastInfo(<FormattedMessage {...messages.messageShareUrlCopySuccess} />);
    },
    [link],
  );

  let middleComponent = null;

  if (loadingLink) {
    middleComponent = (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  } else if (link) {
    middleComponent = (
      <Flex flexDirection="column">
        <TrackerEvent name={`Copy ${type} link`} trackClick>
          <Anchor href={link} lineHeight="1.15" onClick={copyLinkToClipboard}>
            {link}
          </Anchor>
        </TrackerEvent>
        <Text textStyle="action.small" color="grey.600">
          <FormattedMessage {...messages.messageShareableLinkAction} />
        </Text>
      </Flex>
    );
  } else {
    middleComponent = (
      <TrackerEvent name={`Get ${type} link`} trackClick>
        <Button
          color="primary"
          onClick={onClickEnable}
          px={0}
          variant="flat"
          data-intercom-target="enable-sharing-link"
        >
          {enableMessage}
        </Button>
      </TrackerEvent>
    );
  }

  return (
    <ShareableItemBox
      left={
        <Icon
          backgroundColor="white"
          borderColor="grey.200"
          borderStyle="solid"
          borderWidth="1px"
          boxSize="xxlarge"
          color="grey.600"
          size="medium"
          type={<LinkSvg />}
        />
      }
      middle={middleComponent}
      right={
        link ? (
          <TrackerEvent name={`Disable ${type} link`} trackClick>
            <Button color="primary" onClick={onClickDisable} variant="flat">
              <FormattedMessage {...messages.labelDisable} />
            </Button>
          </TrackerEvent>
        ) : null
      }
    />
  );
}

ShareableLinkBox.propTypes = {
  link: PropTypes.string,
  loadingLink: PropTypes.bool,
  enableMessage: PropTypes.node,
  onClickDisable: PropTypes.func.isRequired,
  onClickEnable: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

ShareableLinkBox.defaultProps = {
  link: null,
  loadingLink: false,
  enableMessage: <FormattedMessage {...messages.labelGetShareableLink} />,
};

export default ShareableLinkBox;
