import { useContext, useEffect } from 'react';

import { SelectableComponentsContext } from './SelectableComponentsProvider';

export default function SelectableComponentsFeeder({ ids }) {
  const { setIds } = useContext(SelectableComponentsContext);
  useEffect(() => {
    setIds(ids);
  }, [setIds, ids]);

  return null;
}
