import React from 'react';
import { FormattedMessage } from 'react-intl';

import DownloadSvg from 'src/assets/DownloadSvg';

import ActionsDropdownItem from './ActionsDropdownItem';
import messages from '../messages';

function ActionsDropdownDownload(props) {
  return (
    <ActionsDropdownItem
      action="download"
      icon={<DownloadSvg />}
      label={<FormattedMessage {...messages.labelDownload} />}
      {...props}
    />
  );
}

export default ActionsDropdownDownload;
