import React, { useContext, useCallback } from 'react';
import { Dropdown } from '@picter/prisma';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { FeatureName, useFeature } from 'src/containers/FeatureFlags';
import { locationShape, matchShape } from 'src/utils/app-prop-types';
import useFilteredUrls from 'src/hooks/use-filtered-urls';
import usePermission from 'src/hooks/use-permission';

import messages from '../messages';

const DropdownItemLink = withRouter(({ history, to, ...props }) => {
  const { closeMenu } = useContext(Dropdown.Context);
  const handleClick = useCallback(() => {
    history.push(to);
    closeMenu();
  }, [closeMenu, history, to]);
  return <Dropdown.Item {...props} onClick={handleClick} />;
});

function ShareDropdownMenu({
  location: {
    query: { filter },
  },
  match: {
    params: { collectionId, projectId },
  },
}) {
  const {
    filteredProjectShareUrl,
    filteredProjectShareReviewUrl,
    filteredProjectSharePresentationUrl,
    filteredProjectShareRequestFilesUrl,
  } = useFilteredUrls(filter);
  const { canShareProject, canManagePublicLinks } = usePermission();
  const isGuestInviteEnabled = useFeature(FeatureName.GUEST_MEMBER_INVITATION);

  return (
    <Dropdown.Menu horizontalOffset="-125%" verticalOffset="10px">
      {canShareProject && isGuestInviteEnabled && (
        <DropdownItemLink
          icon="group_add"
          label={<FormattedMessage {...messages.labelInvite} />}
          to={filteredProjectShareUrl({ collectionId, projectId })}
          data-intercom-target="menu-item-guests"
        />
      )}
      {canShareProject && canManagePublicLinks && isGuestInviteEnabled && (
        <Dropdown.Separator />
      )}
      {canManagePublicLinks && (
        <>
          <DropdownItemLink
            icon="done_all"
            label={<FormattedMessage {...messages.labelReview} />}
            to={filteredProjectShareReviewUrl({ collectionId, projectId })}
            data-intercom-target="menu-item-review-link"
          />
          <DropdownItemLink
            icon="desktop_windows"
            label={<FormattedMessage {...messages.labelPresentation} />}
            to={filteredProjectSharePresentationUrl({
              collectionId,
              projectId,
            })}
            data-intercom-target="menu-item-presentation-link"
          />
          <DropdownItemLink
            icon="move_to_inbox"
            label={<FormattedMessage {...messages.labelRequestFiles} />}
            to={filteredProjectShareRequestFilesUrl({
              collectionId,
              projectId,
            })}
            data-intercom-target="menu-item-inbox-link"
          />
        </>
      )}
    </Dropdown.Menu>
  );
}

ShareDropdownMenu.propTypes = {
  location: locationShape.isRequired,
  match: matchShape.isRequired,
};

export default withRouter(ShareDropdownMenu);
