import { VideoPlayerState, VideoPlayerAction } from './types';

export function videoPlayerReducer(
  state: VideoPlayerState,
  action: VideoPlayerAction,
): VideoPlayerState {
  switch (action.type) {
    case 'set_duration':
      return {
        ...state,
        duration: action.payload,
      };
    case 'set_fullscreen':
      return {
        ...state,
        fullscreen: action.payload,
      };
    case 'set_light':
      return {
        ...state,
        light: action.payload,
      };
    case 'set_playing':
      return {
        ...state,
        playing: action.payload,
      };
    case 'set_progress':
      return {
        ...state,
        ...action.payload,
      };
    case 'set_seeking':
      return {
        ...state,
        seeking: action.payload,
      };
    case 'set_volume':
      return {
        ...state,
        volume: action.payload,
      };
    case 'toggle_mute':
      return {
        ...state,
        muted: !state.muted,
      };
    case 'toggle_play':
      return {
        ...state,
        playing: !state.playing,
      };
    case 'toggle_seek':
      return {
        ...state,
        seeking: !state.seeking,
      };
    default:
      return state;
  }
}
