import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { toastError } from '@picter/prisma';
import { Check as CheckSvg, Close as CloseSvg } from '@styled-icons/material';

import { Icon } from 'src/modules/prisma';

import FormAutoSaveIndicator from './styles/FormAutoSaveIndicator';
import FormContent from './styles/FormContent';
import FormErrorIndicator from './styles/FormErrorIndicator';
import FormFieldset from './styles/FormFieldset';
import FormLegend from './styles/FormLegend';
import FormSavedIndicator from './styles/FormSavedIndicator';
import FormSavingIndicator from './styles/FormSavingIndicator';
import SpinnerSvg from '../SpinnerSvg';
import FormSection from './styles/FormSection';

import messages from './messages';

const ErrorIndicator = () => (
  <FormErrorIndicator>
    <Icon color="inherit" size="small" type={<CloseSvg />} />
    <FormattedMessage {...messages.messageError} />
  </FormErrorIndicator>
);
const SavedIndicator = () => (
  <FormSavedIndicator>
    <Icon color="inherit" size="small" type={<CheckSvg />} />
    <FormattedMessage {...messages.messageSaved} />
  </FormSavedIndicator>
);
const SavingIndicator = () => (
  <FormSavingIndicator>
    <SpinnerSvg />
    <FormattedMessage {...messages.messageSaving} />
  </FormSavingIndicator>
);
const AutoSaveIndicator = ({
  autoSaveError,
  autoSaveRequest,
  autoSaveSuccess,
  error,
}) => {
  useEffect(() => {
    if (autoSaveError) {
      toastError(`Error happened while auto saving. ${error._error}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSaveError]);

  return (
    <FormAutoSaveIndicator>
      {autoSaveRequest ? (
        <FormSection.SavingIndicator />
      ) : (
        <>{autoSaveSuccess && <FormSection.SavedIndicator />}</>
      )}
    </FormAutoSaveIndicator>
  );
};

AutoSaveIndicator.propTypes = {
  autoSaveError: PropTypes.bool.isRequired,
  autoSaveRequest: PropTypes.bool.isRequired,
  autoSaveSuccess: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Error),
};

AutoSaveIndicator.defaultProps = {
  error: null,
};

FormSection.Content = FormContent;
FormSection.ErrorIndicator = ErrorIndicator;
FormSection.Fieldset = FormFieldset;
FormSection.Legend = FormLegend;
FormSection.SavedIndicator = SavedIndicator;
FormSection.SavingIndicator = SavingIndicator;
FormSection.AutoSaveIndicator = AutoSaveIndicator;

export default FormSection;
