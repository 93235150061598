import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { FormattedMessage } from 'react-intl';
import { Group as GroupSvg } from '@styled-icons/material';

import IconGroup from 'src/components/IconGroup';
import { getEnabledPublicLinks } from 'src/utils/accessors/project';
import { Icon, Tooltip } from 'src/modules/prisma';
import { historyShape, matchShape } from 'src/utils/app-prop-types';
import { projectShareUrl } from 'src/routes/urls';
import { get } from 'src/utils/accessors';

import TrackSharingIndicatorClick from './TrackSharingIndicatorClick';
import messages from '../messages';
import {
  publicLinkMessageMap,
  publicLinkIconMap,
  publicLinkModalUrlMap,
} from '../constants';

function ProjectShareState({
  project,
  invitations,
  shouldOpenShareModal,
  shouldOpenPublicLinkModal,
  history,
  match: { params },
  projectUsers,
  iconOutline,

  showInvitations,
  showPublicLinks,
  showUsers,
}) {
  const publicLinks = getEnabledPublicLinks(project);
  const usersCount = showUsers ? projectUsers.size : 0;
  const invitationsCount = showInvitations ? invitations.size : 0;
  const directShareCount = usersCount + invitationsCount;

  const openPublicLinkModal = useCallback(
    publicLinkType => {
      history.push(publicLinkModalUrlMap[publicLinkType](params));
    },
    [history, params],
  );

  const openInvitationModal = useCallback(() => {
    history.push(projectShareUrl(params));
  }, [history, params]);

  return (
    <TrackSharingIndicatorClick
      trackClick={!!(shouldOpenShareModal || shouldOpenPublicLinkModal)}
    >
      <IconGroup outline={iconOutline} overlapSize={1}>
        {directShareCount > 0 && (
          <Tooltip>
            <Tooltip.Reference>
              <span>
                <Icon
                  onClick={shouldOpenShareModal ? openInvitationModal : null}
                  boxSize="xlarge"
                  size="small"
                  borderColor="grey.200"
                  borderStyle="solid"
                  borderWidth="1px"
                  backgroundColor="white"
                  color="grey.600"
                  type={<GroupSvg />}
                />
              </span>
            </Tooltip.Reference>
            <Tooltip.Message>
              <FormattedMessage {...messages.messageInvitedGuests} />
            </Tooltip.Message>
          </Tooltip>
        )}
        {showPublicLinks &&
          publicLinks.map(publicLink => {
            const publicLinkType = get(publicLink, 'attributes.type');
            return (
              <Tooltip>
                <Tooltip.Reference>
                  <span>
                    <Icon
                      onClick={
                        shouldOpenPublicLinkModal
                          ? () => openPublicLinkModal(publicLinkType)
                          : null
                      }
                      boxSize="xlarge"
                      size="small"
                      borderColor="grey.200"
                      borderStyle="solid"
                      borderWidth="1px"
                      backgroundColor="white"
                      color="grey.600"
                      type={publicLinkIconMap[publicLinkType]}
                    />
                  </span>
                </Tooltip.Reference>
                <Tooltip.Message>
                  <FormattedMessage {...publicLinkMessageMap[publicLinkType]} />
                </Tooltip.Message>
              </Tooltip>
            );
          })}
      </IconGroup>
    </TrackSharingIndicatorClick>
  );
}

ProjectShareState.propTypes = {
  iconOutline: PropTypes.string,
  invitations: PropTypes.instanceOf(List),
  history: historyShape.isRequired,
  match: matchShape.isRequired,
  project: PropTypes.instanceOf(Map),
  shouldOpenShareModal: PropTypes.bool,
  shouldOpenPublicLinkModal: PropTypes.bool,
  projectUsers: PropTypes.instanceOf(List),

  showInvitations: PropTypes.bool,
  showPublicLinks: PropTypes.bool,
  showUsers: PropTypes.bool,
};

ProjectShareState.defaultProps = {
  iconOutline: 'grey.100',
  invitations: null,
  project: null,
  shouldOpenShareModal: null,
  shouldOpenPublicLinkModal: null,
  projectUsers: null,

  showInvitations: false,
  showPublicLinks: false,
  showUsers: false,
};

export default withRouter(ProjectShareState);
