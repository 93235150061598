import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FieldBadge, FieldWrapper, HintMessage } from '@picter/prisma';

import SelectFieldContainer from '../styles/SelectFieldContainer';
import SelectFieldLabel from '../styles/SelectFieldLabel';
import SelectFieldMenu from '../styles/SelectFieldMenu';

const PLACEHOLDER_VALUE = '';

class SelectField extends Component {
  constructor(props) {
    super(props);

    const options = this.getOptions();
    const { ariaLabel, label, value: originalValue } = this.props;
    const initialValue = originalValue
      ? options.find(option => option.value === originalValue)
      : options[0];
    this.state = initialValue || options[0];

    if (!label && !ariaLabel) {
      throw new Error(
        'Please specify `label` or `ariaLabel` properties on `SelectField`.',
      );
    }
  }

  getOptions = () => {
    const { placeholder, options = [], hideDefaultPlaceholder } = this.props;
    const optionLabel = placeholder || 'Please select';
    const defaultOptions = [];

    if (!hideDefaultPlaceholder) {
      defaultOptions.push({
        label: `${optionLabel}`,
        value: PLACEHOLDER_VALUE,
      });
    }

    return [...defaultOptions, ...options];
  };

  handleChange = event => {
    const { onChange } = this.props;
    const options = this.getOptions();
    const { value } = event.target;

    this.setState(options.find(option => option.value === value));

    if (onChange) {
      onChange(event, value === PLACEHOLDER_VALUE ? null : value);
    }
  };

  handleBlur = event => {
    const { onBlur } = this.props;
    const { value } = event.target;

    if (onBlur) {
      onBlur(event, value === PLACEHOLDER_VALUE ? null : value);
    }
  };

  render() {
    const {
      id,
      className,
      ariaLabel,
      disabled,
      displayErrorMessage,
      error,
      hint,
      icon,
      label,
      name,
      onBlur,
      onFocus,
      placeholder,
      required,
      showBadge,
      width,
    } = this.props;
    const { value } = this.state;
    const options = this.getOptions();
    const empty = value === PLACEHOLDER_VALUE;

    return (
      <SelectFieldContainer id={id} className={className} width={width}>
        <FieldWrapper
          iconLeft={icon}
          iconRight="arrow_drop_down"
          withLabel={!!label}
        >
          <SelectFieldMenu
            id={`${name}-select-field`}
            aria-label={ariaLabel || label}
            name={name}
            className={classNames({ empty, error, icon: !!icon })}
            disabled={disabled}
            onBlur={onBlur}
            onChange={this.handleChange}
            onFocus={onFocus}
            value={value}
            required={required}
          >
            {options &&
              options.map(option => (
                <option
                  key={`${option.value}-${option.label}`}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
          </SelectFieldMenu>
          {label && (
            <SelectFieldLabel
              htmlFor={`${name}-select-field`}
              floated={label && placeholder}
            >
              {label}
              {showBadge && !required && <FieldBadge>Optional</FieldBadge>}
            </SelectFieldLabel>
          )}
        </FieldWrapper>
        {hint && <HintMessage size="small">{hint}</HintMessage>}
        {error && displayErrorMessage && (
          <HintMessage size="small" kind="error">
            {error}
          </HintMessage>
        )}
      </SelectFieldContainer>
    );
  }
}

SelectField.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
  displayErrorMessage: PropTypes.bool,
  error: PropTypes.node,
  hint: PropTypes.node,
  icon: PropTypes.string,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showBadge: PropTypes.bool,
  value: PropTypes.node,
  width: PropTypes.string,
  hideDefaultPlaceholder: PropTypes.bool,
};

SelectField.defaultProps = {
  id: null,
  className: null,
  ariaLabel: null,
  disabled: false,
  displayErrorMessage: false,
  error: null,
  hint: null,
  icon: null,
  label: null,
  onBlur: null,
  onChange: null,
  onFocus: null,
  options: null,
  placeholder: null,
  required: false,
  showBadge: true,
  value: null,
  width: null,
  hideDefaultPlaceholder: false,
};

export default SelectField;
