import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useRouter from 'src/hooks/use-router';
import { replacePublicLinkKey } from 'src/utils/inject-public-link-key';

export const MentionableUsersContext = createContext();

const API_URL = process.env.REACT_APP_API_URL;

const MentionableUsersProvider = props => {
  const [mentionableUsers, setMentionableUsers] = useState([]);
  const { match } = useRouter();
  const { projectId, projectPublicUrlKey } = props;
  useEffect(() => {
    fetch(
      replacePublicLinkKey(
        `${API_URL}/app-workspace/ws-projects/${projectId}/mentionable-users?publicKey=${projectPublicUrlKey}`,
        match,
      ),
      {
        method: 'GET',
        credentials: 'include',
      },
    )
      .then(res => {
        if (res.status !== 200) {
          return [];
        }
        return res.json();
      })
      .then(res => {
        setMentionableUsers(
          res.map(user => ({
            id: user.id,
            display: user.publicName,
            email: user.email,
          })),
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return (
    <MentionableUsersContext.Provider value={mentionableUsers} {...props} />
  );
};

MentionableUsersProvider.propTypes = {
  projectId: PropTypes.string.isRequired,
  projectPublicUrlKey: PropTypes.string.isRequired,
};

export default MentionableUsersProvider;
