import isFunction from 'lodash/isFunction';
import { viewModeTypes } from './constants';

/**
 * @typedef {Object} PathStructure
 *
 * @property {string} name - Name of the page/path described.
 * @property {string} path - A string that can contain one or multiple slash
 *                           prepended words that form a part of an URL.
 *                           Cannot be null.
 * @property {string} scope - Scope in which the page is in. Used in the final
 *                            event name tracked.
 * @property {boolean} track - Boolean value that tells the tracking library to
 *                             track or not. Based on name.
 *
 * @desc PathStructure objects are created to be used with `Route` and `PageViewTracker`
 * components.
 */

/**
 * @function extend
 *
 * @param {PathStructure} parent
 *
 * @param {Object} data - The new PATH data.
 * @param {string} data.name - Name of the new PATH. If present the PATH will be tracked.
 * @param {string} data.path - Slash prepended path that will form an URL.
 * @param {string} data.scope - Word that will be included in the page view event.
 *
 * @param {Object} opts
 * @param {boolean} [opts.extendPath=true] - Set as false to prevent extending parent's path.
 * @param {boolean} [opts.extendScope=true] - Set as false to prevent extending parent's scope.
 *
 * @returns {PathStructure} Final PATH with extended path and scope.
 *
 * @desc Creates a new PATH by extending from another one.
 */
const extend = (
  parent,
  { name, path, scope } = {},
  { extendPath = true, extendScope = true } = {},
) => {
  const extendedPath = extendPath ? [parent.path, path].join('') : path;
  const extendedScope = [];

  if (extendScope && parent.scope) {
    extendedScope.push(parent.scope);
  }

  if (scope) {
    extendedScope.push(scope);
  }

  const getScope = params => {
    const parsedScope = extendedScope.map(itemScope =>
      isFunction(itemScope) ? itemScope(params) : itemScope,
    );

    return parsedScope.length !== 0 ? parsedScope.join(' / ') : null;
  };

  return {
    name,
    path: extendedPath,
    scope: getScope,
    track: !!name, // only paths that declare a name prop will be tracked
  };
};

// PATH: To use with `Route` component

/*
  ========================================
  BASE PATHS
  ========================================
*/
const APP = {
  path: '',
  scope: 'App',
  track: false,
};
// App / Viewed Home
export const HOME = extend(APP, {
  name: 'Home',
  path: '/',
});

// App / Viewed Referral
export const REFERRAL_LINK = extend(APP, {
  name: 'Referral Link',
  path: '/referral/:urlKey',
});

/*
  ========================================
  AUTHENTICATION PATHS
  ========================================
*/
const AUTH = extend(
  APP,
  {
    path: '',
    scope: 'Auth',
  },
  { extendScope: false },
);
export const LOGIN = extend(AUTH, {
  path: '/login',
  name: 'Login',
  scope: 'Login',
});
export const SIGNUP = extend(AUTH, {
  path: '/signup',
  name: 'Sign up',
  scope: 'Sign up',
});

export const ACTIVATE_APP_SUMO_LICENSE = extend(AUTH, {
  name: 'Activate AppSumo license',
  scope: 'Activate AppSumo license',
  path: '/activate-appsumo-license',
});

/*
  ========================================
  LIBRARY AND PROJECT PATHS
  ========================================
*/
const LIBRARY = extend(APP, {
  path: '',
  scope: 'Library',
});
// Projects /
export const LIBRARY_PROJECTS = extend(
  LIBRARY,
  {
    path: `/:viewMode(${Object.values(viewModeTypes).join('|')})`,
    scope: ({ viewMode }) => {
      switch (viewMode) {
        case viewModeTypes.PRESENT:
          return 'Presentation';
        case viewModeTypes.REVIEW:
          return 'Review';
        case viewModeTypes.REQUEST_FILES:
          return 'Request files';
        default:
          return 'Projects';
      }
    },
  },
  { extendScope: false },
);
export const LIBRARY_SPACES = extend(
  LIBRARY,
  {
    path: '/spaces',
    scope: 'Spaces',
  },
  { extendScope: false },
);
export const LIBRARY_SPACES_NEW = extend(LIBRARY_SPACES, {
  path: '/new',
  name: 'New',
  scope: 'New',
});
export const LIBRARY_SPACES_NEW_1 = extend(LIBRARY_SPACES_NEW, {
  path: '/1',
  name: 'Step 1',
  scope: 'Step 1',
});
export const LIBRARY_SPACES_NEW_2 = extend(LIBRARY_SPACES_NEW, {
  path: '/2',
  name: 'Step 2',
  scope: 'Step 2',
});
export const LIBRARY_SPACES_NEW_3 = extend(LIBRARY_SPACES_NEW, {
  path: '/3',
  name: 'Step 3',
  scope: 'Step 3',
});
export const LIBRARY_SPACE = extend(LIBRARY_SPACES, {
  path: '/:spaceId',
  name: 'Space',
  scope: 'Space',
});
export const LIBRARY_SPACE_FOLDERS = extend(LIBRARY_SPACE, {
  path: '/folders',
  name: 'Folders',
  scope: 'Folders',
});
export const LIBRARY_SPACE_FOLDER = extend(LIBRARY_SPACE_FOLDERS, {
  path: '/:folderId',
  name: 'Folder',
  scope: 'Folder',
});
export const LIBRARY_SPACE_TRASH = extend(LIBRARY_SPACE, {
  path: '/trash',
  name: 'Trash',
  scope: 'Trash',
});
export const LIBRARY_SPACE_TASKS = extend(LIBRARY_SPACE, {
  path: '/tasks',
  name: 'Tasks',
  scope: 'Tasks',
});
export const LIBRARY_SPACE_TASK = extend(LIBRARY_SPACE_TASKS, {
  path: '/:taskId',
  name: 'Task',
  scope: 'Task',
});
export const LIBRARY_SETTINGS = extend(
  LIBRARY,
  {
    path: '/settings',
    name: 'Settings',
    scope: 'Settings',
  },
  { extendScope: false },
);
export const LIBRARY_SETTINGS_PERSONAL = extend(LIBRARY_SETTINGS, {
  path: '/personal',
  name: 'Personal',
  scope: 'Personal',
});
export const LIBRARY_SETTINGS_PERSONAL_PROFILE = extend(
  LIBRARY_SETTINGS_PERSONAL,
  {
    path: '/profile',
    name: 'Profile',
    scope: 'Profile',
  },
);
export const LIBRARY_SETTINGS_PERSONAL_MANAGE_ACCOUNT = extend(
  LIBRARY_SETTINGS_PERSONAL,
  {
    path: '/manage-account',
    name: 'Manage account',
    scope: 'Manage account',
  },
);
export const LIBRARY_SETTINGS_SPACE = extend(LIBRARY_SETTINGS, {
  path: '/:spaceId',
  name: 'Space',
  scope: 'Space',
});
export const LIBRARY_SETTINGS_SPACE_GENERAL = extend(LIBRARY_SETTINGS_SPACE, {
  path: '/general',
  name: 'General',
  scope: 'General',
});
export const LIBRARY_SETTINGS_SPACE_MEMBERS = extend(LIBRARY_SETTINGS_SPACE, {
  path: '/members',
  name: 'Members',
  scope: 'Members',
});
export const LIBRARY_SETTINGS_SPACE_NOTIFICATIONS = extend(
  LIBRARY_SETTINGS_SPACE,
  {
    path: '/notifications',
    name: 'Notifications',
    scope: 'Notifications',
  },
);
export const LIBRARY_SETTINGS_SPACE_SUBSCRIPTION = extend(
  LIBRARY_SETTINGS_SPACE,
  {
    path: '/subscription',
    name: 'Subscription',
    scope: 'Subscription',
  },
);
export const LIBRARY_SETTINGS_SPACE_SUBSCRIPTION_CANCEL = extend(
  LIBRARY_SETTINGS_SPACE_SUBSCRIPTION,
  {
    path: '/cancel/',
    scope: 'Cancel subscription',
  },
);
export const LIBRARY_SETTINGS_SPACE_CREDITS = extend(LIBRARY_SETTINGS_SPACE, {
  path: '/credits',
  name: 'Credits',
  scope: 'Credits',
});
export const LIBRARY_SETTINGS_SPACE_INTEGRATIONS = extend(
  LIBRARY_SETTINGS_SPACE,
  {
    path: '/integrations',
    name: 'Integrations',
    scope: 'Integrations',
  },
);
export const LIBRARY_PROJECTS_EMPTY = extend(LIBRARY_PROJECTS, {
  path: '/empty',
  name: 'Empty state',
});
export const LIBRARY_IMAGES = extend(LIBRARY, {
  path: '/i',
  name: 'Image browser',
});

// Projects / Project / Viewed Project
export const LIBRARY_PROJECT = extend(LIBRARY_PROJECTS, {
  path: '/:projectId',
  name: 'Project',
  scope: 'Project',
});
// Projects / Project / Info / Viewed Inbox Success
export const LIBRARY_PROJECT_INBOX_SUCCESS = extend(LIBRARY_PROJECT, {
  path: '/inbox-success',
  name: 'Inbox link success',
  scope: 'Inbox link success',
});
// Projects / Project / Info / Viewed Info
export const LIBRARY_PROJECT_INFO = extend(LIBRARY_PROJECT, {
  path: '/info',
  name: 'Info',
  scope: 'Info',
});
// Projects / Project / Info / Comments / Viewed Comments
export const LIBRARY_PROJECT_INFO_COMMENTS = extend(LIBRARY_PROJECT_INFO, {
  path: '/comments',
  name: 'Comments',
  scope: 'Comments',
});
// Projects / Project / Info / Comments / Viewed Comments
export const LIBRARY_PROJECT_INFO_COMMENT = extend(
  LIBRARY_PROJECT_INFO_COMMENTS,
  {
    path: '/:commentId',
    name: 'Comment',
    scope: 'Comment',
  },
);
// Projects / Project / Comments / Viewed Comments
export const LIBRARY_PROJECT_COMMENTS = extend(LIBRARY_PROJECT, {
  path: '/comments',
  name: 'Comments',
  scope: 'Comments',
});
// Projects / Project / Comments / Comment / Viewed Comment
export const LIBRARY_PROJECT_COMMENT = extend(LIBRARY_PROJECT_COMMENTS, {
  path: '/:commentId',
  name: 'Comment',
  scope: 'Comment',
});
// Projects / Project / Share / Viewed Share (not tracked)
export const LIBRARY_PROJECT_SHARE = extend(LIBRARY_PROJECT, {
  path: '/share',
  scope: 'Share',
});
// Projects / Project / Share / Invite / Viewed Invite (not tracked)
export const LIBRARY_PROJECT_SHARE_INVITE = extend(LIBRARY_PROJECT_SHARE, {
  path: '/invite',
  scope: 'Invite',
});
// Projects / Project / Share / Review / Viewed Review (not tracked)
export const LIBRARY_PROJECT_SHARE_REVIEW = extend(LIBRARY_PROJECT_SHARE, {
  path: '/review',
  scope: 'Review',
});
// Projects / Project / Share / Presentation / Viewed Presentation (not tracked)
export const LIBRARY_PROJECT_SHARE_PRESENTATION = extend(
  LIBRARY_PROJECT_SHARE,
  {
    path: '/presentation',
    scope: 'Presentation',
  },
);
// Projects / Project / Share / Request files / Viewed Request files (not tracked)
export const LIBRARY_PROJECT_SHARE_REQUEST_FILES = extend(
  LIBRARY_PROJECT_SHARE,
  {
    path: '/request-files',
    scope: 'Request files',
  },
);

// Projects images
export const LIBRARY_PROJECT_IMAGES = extend(
  LIBRARY_PROJECT,
  {
    path: '/i',
    scope: ({ viewMode }) => {
      switch (viewMode) {
        case viewModeTypes.PRESENT:
          return 'Presentation images';
        case viewModeTypes.REVIEW:
          return 'Review images';
        case viewModeTypes.REQUEST_FILES:
          return 'Request files images';
        default:
          return 'Projects images';
      }
    },
  },
  { extendScope: false },
);
// Projects images / Viewed Image
export const LIBRARY_PROJECT_IMAGE = extend(LIBRARY_PROJECT_IMAGES, {
  path: '/:imageId',
  name: 'Image',
});
// Projects images / Viewed Info
export const LIBRARY_PROJECT_IMAGE_INFO = extend(LIBRARY_PROJECT_IMAGE, {
  path: '/info',
  name: 'Info',
  scope: 'Info',
});

export const LIBRARY_PROJECT_IMAGE_COMMENTS = extend(LIBRARY_PROJECT_IMAGE, {
  path: '/comments',
  name: 'Comments',
  scope: 'Comments',
});
export const LIBRARY_PROJECT_IMAGE_COMMENT = extend(
  LIBRARY_PROJECT_IMAGE_COMMENTS,
  {
    path: '/:commentId',
    name: 'Comment',
    scope: 'Comment',
  },
);

// Collections / Collection / Viewed Collection
export const LIBRARY_PROJECT_COLLECTIONS = extend(LIBRARY_PROJECT, {
  path: '/c',
});
// Projects / Project / Collection / Viewed Collection
export const LIBRARY_PROJECT_COLLECTION = extend(LIBRARY_PROJECT_COLLECTIONS, {
  path: '/:collectionId',
  name: 'Collection',
  scope: 'Collection',
});

export const LIBRARY_PROJECT_COLLECTION_COMMENTS = extend(
  LIBRARY_PROJECT_COLLECTION,
  {
    path: '/comments',
    name: 'Comments',
    scope: 'Comments',
  },
);
export const LIBRARY_PROJECT_COLLECTION_COMMENT = extend(
  LIBRARY_PROJECT_COLLECTION_COMMENTS,
  {
    path: '/:commentId',
    name: 'Comment',
    scope: 'Comment',
  },
);

// Projects / Project / Collection / Share / Viewed Share (not tracked)
export const LIBRARY_PROJECT_COLLECTION_SHARE = extend(
  LIBRARY_PROJECT_COLLECTION,
  {
    path: '/share',
    scope: 'Share',
  },
);
// Projects / Project / Share / Invite / Viewed Invite (not tracked)
export const LIBRARY_PROJECT_COLLECTION_SHARE_INVITE = extend(
  LIBRARY_PROJECT_COLLECTION_SHARE,
  {
    path: '/invite',
    scope: 'Invite',
  },
);
// Projects / Project / Share / Review / Viewed Review (not tracked)
export const LIBRARY_PROJECT_COLLECTION_SHARE_REVIEW = extend(
  LIBRARY_PROJECT_COLLECTION_SHARE,
  {
    path: '/review',
    scope: 'Review',
  },
);
// Projects / Project / Share / Presentation / Viewed Presentation (not tracked)
export const LIBRARY_PROJECT_COLLECTION_SHARE_PRESENTATION = extend(
  LIBRARY_PROJECT_COLLECTION_SHARE,
  {
    path: '/presentation',
    scope: 'Presentation',
  },
);
// Projects / Project / Share / Request files / Viewed Request files (not tracked)
export const LIBRARY_PROJECT_COLLECTION_SHARE_REQUEST_FILES = extend(
  LIBRARY_PROJECT_COLLECTION_SHARE,
  {
    path: '/request-files',
    scope: 'Request files',
  },
);

// Collection images
export const LIBRARY_PROJECT_COLLECTION_IMAGES = extend(
  LIBRARY_PROJECT_COLLECTION,
  {
    path: '/i',
    scope: 'Collection images',
  },
  { extendScope: false },
);
// Collections images / Viewed Image
export const LIBRARY_PROJECT_COLLECTION_IMAGE = extend(
  LIBRARY_PROJECT_COLLECTION_IMAGES,
  {
    path: '/:imageId',
    name: 'Image',
  },
);
// Collections images / Viewed Info
export const LIBRARY_PROJECT_COLLECTION_IMAGE_INFO = extend(
  LIBRARY_PROJECT_COLLECTION_IMAGE,
  {
    path: '/info',
    name: 'Info',
  },
);

export const LIBRARY_PROJECT_COLLECTION_IMAGE_COMMENTS = extend(
  LIBRARY_PROJECT_COLLECTION_IMAGE,
  {
    path: '/comments',
    name: 'Comments',
    scope: 'Comments',
  },
);
export const LIBRARY_PROJECT_COLLECTION_IMAGE_COMMENT = extend(
  LIBRARY_PROJECT_COLLECTION_IMAGE_COMMENTS,
  {
    path: '/:commentId',
    name: 'Comment',
    scope: 'Comment',
  },
);

// Invitations
export const INVITATIONS = extend(APP, {
  path: '/invitations',
  name: 'Invitations',
});

// Invitations / Viewed Invitation
export const INVITATION = extend(INVITATIONS, {
  path: '/:invitationId',
  name: 'Invitation',
});

// Onboard
export const ONBOARD = extend(APP, {
  path: '/onboard-from-contests',
  name: 'Onboard',
});
// Password Recovery
export const PASSWORD_RECOVERY = extend(APP, {
  path: '/password-recovery',
  name: 'Password recovery',
});

// Profile setup
export const PROFILE_SETUP = extend(APP, {
  path: '/setup',
  name: 'Profile setup',
});

// Onboard
export const RESET_PASSWORD = extend(APP, {
  path: '/reset-password',
  name: 'Reset password',
});
