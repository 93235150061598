import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Flex, Box } from '@rebass/grid';
import { Icon, Dropdown } from '@picter/prisma';
import { FormattedRelativeTime, FormattedMessage } from 'react-intl';

import { Body } from 'src/modules/prisma';
import Avatar from 'src/components/Avatar';
import { getDateDeltaFromNowInSeconds } from 'src/utils/date';
import { historyShape } from 'src/utils/app-prop-types';
import { NotificationsContext } from 'src/components/NotificationsProvider';
import NotificationWrapper from '../styles/NotificationWrapper';

import messages from '../messages';

const Notification = ({
  id,
  actor,
  published,
  read,
  children,
  targetLink,
  history,
  noAvatar,
}) => {
  const { markAsRead } = useContext(NotificationsContext);
  const { closeMenu } = useContext(Dropdown.Context);

  const onClickHandler = useCallback(async () => {
    if (!read) {
      await markAsRead(id);
    }
    history.push(targetLink);
    closeMenu();
  }, [read, history, targetLink, closeMenu, markAsRead, id]);

  const deltaDatePublishedDateNow = useMemo(
    () => getDateDeltaFromNowInSeconds(published),
    [published],
  );

  return (
    <NotificationWrapper onClick={onClickHandler} px={5} pt={2} pb={3}>
      <Flex>
        {!noAvatar && (
          <Box mr={4}>
            <Avatar
              email={actor && actor.email}
              name={
                actor ? (
                  actor.name
                ) : (
                  <FormattedMessage {...messages.unauthenticatedUser} />
                )
              }
              size="large"
              deleted={!actor}
            />
          </Box>
        )}
        <Box style={{ flexGrow: 1 }}>
          <Box mb={1}>
            <Body lineHeight="inherit" wordBreak="break-word">
              {children}
            </Body>
          </Box>
          <Body color="secondary" textSize="small">
            <FormattedRelativeTime
              numeric="auto"
              unit="second"
              updateIntervalInSeconds={10}
              value={deltaDatePublishedDateNow}
            />
          </Body>
        </Box>
        <div
          style={{ minWidth: 42, display: 'flex', justifyContent: 'center' }}
        >
          {!read ? (
            <Box mt={1} ml={2}>
              <Icon type="fiber_manual_record" size="tiny" color="#FB5C5C" />
            </Box>
          ) : null}
        </div>
      </Flex>
    </NotificationWrapper>
  );
};

Notification.propTypes = {
  id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  actor: PropTypes.object,
  published: PropTypes.string.isRequired,
  read: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  targetLink: PropTypes.string.isRequired,
  history: historyShape.isRequired,
  noAvatar: PropTypes.bool,
};

Notification.defaultProps = {
  actor: null,
  noAvatar: false,
};

export default withRouter(Notification);
