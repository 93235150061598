import { REL_HAS_MANY, REL_BELONGS_TO } from '../constants';

export const hasMany = (type, { inverse } = {}) => ({
  relation: REL_HAS_MANY,
  type,
  inverse,
});

export const belongsTo = (type, { inverse } = {}) => ({
  relation: REL_BELONGS_TO,
  type,
  inverse,
});

// Get relation definition
export const getRelation = (schemas, type, relationName) =>
  schemas[type][relationName];

// Get relation definition of inverse relation
export const getInverseRelation = (schemas, type, relationName, resource) => {
  const relation = getRelation(schemas, type, relationName);
  if (!relation || !relation.inverse) {
    return undefined;
  }

  const inverseRelationName =
    typeof relation.inverse === 'function'
      ? relation.inverse(resource)
      : relation.inverse;

  return {
    ...schemas[relation.type][inverseRelationName],
    name: inverseRelationName,
  };
};

// Filter all relationships of a resource, that
// have an inverse-relationship defined in its schema.
export const getInversableRelationships = (schema, resource) =>
  resource.relationships && schema
    ? Object.entries(resource.relationships).filter(
        ([key]) => schema[key] && schema[key].inverse,
      )
    : undefined;
