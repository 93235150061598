import {
  createSelector,
  createSelectorCreator,
  defaultMemoize,
} from 'reselect';
import camelCase from 'lodash/camelCase';
import isEqual from 'lodash/isEqual';

import select from './select';

export const pureSelect = (state, ...rest) => state.getIn(['api', ...rest]);

const hashFn = (prev, next) => isEqual(prev, next);

const paramsSelectorCreator = createSelectorCreator(defaultMemoize, hashFn);

const stateMemoizationChecker = (
  { state: oldState },
  { state, memoForEntities, type },
) => {
  const entities =
    memoForEntities.length > 0 ? memoForEntities : [camelCase(type)];
  return entities.reduce((prev, selection) => {
    const parsedSelection = Array.isArray(selection) ? selection : [selection];
    return (
      prev &&
      pureSelect(state, ...parsedSelection) ===
        pureSelect(oldState, ...parsedSelection)
    );
  }, true);
};

const stateSelectorCreator = createSelectorCreator(
  defaultMemoize,
  stateMemoizationChecker,
);

const makeStateSelector = () =>
  stateSelectorCreator(
    params => params,
    ({ state }) => state,
  );

const makeParamsSelector = () =>
  paramsSelectorCreator(
    (_, __, params) => params,
    params => params,
  );

export const makeMemoSelect = (memoForEntities = []) => {
  const paramsSelector = makeParamsSelector();
  const stateSelector = makeStateSelector();

  return createSelector(
    (state, type) => stateSelector({ state, memoForEntities, type }),
    (_, type) => type,
    paramsSelector,
    (state, type, params) => select(state, type, params),
  );
};
