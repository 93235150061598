import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import Text from './Text';

const Heading = styled(Text).attrs(props => ({
  textStyle: `heading.${props.textSize}`,
}))``;

Heading.propTypes = {
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  textSize: PropTypes.oneOf(['xxlarge', 'xlarge', 'large', 'regular', 'small']),
};

Heading.defaultProps = {
  blacklist: [...Text.defaultProps.blacklist, 'textSize'],
  tag: 'h1',
  color: 'black',
  textSize: 'regular',
};

Heading.displayName = 'Heading';

export default Heading;
