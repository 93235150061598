import React from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { Button } from '@picter/prisma';

import messages from '../messages';

const StopButton = styled(Button).attrs({
  children: <FormattedMessage {...messages.labelStop} />,
  flat: true,
})`
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  position: absolute;
  right: 5px;
  bottom: 0;
`;

export default StopButton;
