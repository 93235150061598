import React from 'react';
import PropTypes from 'prop-types';

import { SubscriptionServiceProvider } from './SubscriptionServiceProvider';
import useChargebee from '../hooks/use-chargebee';
import useService from '../hooks/use-service';

function SubscriptionService({ children, endpoint, site }) {
  const chargebee = useChargebee({ site });
  const service = useService({ chargebee, endpoint });

  return (
    <SubscriptionServiceProvider value={service}>
      {children}
    </SubscriptionServiceProvider>
  );
}

SubscriptionService.propTypes = {
  children: PropTypes.node.isRequired,
  endpoint: PropTypes.string.isRequired,
  site: PropTypes.string.isRequired,
};

export default SubscriptionService;
