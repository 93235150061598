import styled from 'styled-components/macro';

import { Dropdown as PrismaDropdown } from 'src/modules/prisma';

const Dropdown = styled(PrismaDropdown)`
  div[class*='dropdown-menu'] {
    left: 0 !important;
    right: unset !important;
  }
`;

export default Dropdown;
