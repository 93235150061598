import moment from 'moment-timezone/moment-timezone';
import momentData from '@picter/timezones/data/moment-timezones.json';

moment.tz.add(momentData.zones);

export function requestHttp({ url, method, payload, onSuccess, onFailure }) {
  let statusCode;

  return fetch(url, {
    method,
    body: payload ? JSON.stringify(payload) : undefined,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(response => {
      statusCode = response.status;
      // try to parse json, if it fails, just
      // return null (otherwise 204s with no content
      // will fail)
      return response.json().catch(() => null);
    })
    .then(json => {
      if (statusCode >= 400) {
        return Promise.reject(json);
      }
      return Promise.resolve(json);
    })
    .then(onSuccess, onFailure);
}

export function logout({ endpointHost, ...rest }) {
  return requestHttp({
    url: `${endpointHost}/authenticate`,
    method: 'DELETE',
    ...rest,
  });
}

export function checkAuth({ endpointHost, ...rest }) {
  return requestHttp({
    url: `${endpointHost}/user`,
    method: 'GET',
    ...rest,
  });
}

export function signup({ endpointHost, payload, ...rest }) {
  return requestHttp({
    url: `${endpointHost}/users`,
    method: 'POST',
    payload: {
      timezone: moment.tz.guess(),
      ...payload,
    },
    ...rest,
  });
}

export function login({ endpointHost, payload, ...rest }) {
  return requestHttp({
    url: `${endpointHost}/authenticate`,
    method: 'POST',
    payload,
    ...rest,
  });
}
