import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { H1, P } from '@picter/prisma';
import Dropzone from 'react-dropzone';
import isFunction from 'lodash/isFunction';
import { Box, Flex } from '@rebass/grid';
import { branch, renderComponent } from 'recompose';

import { AbsoluteBox } from 'src/styles/PositionBox';

import CurvedRightArrowSVG from './CurvedRightArrowSVG';
import ReviewAndDownloadSVG from './ReviewAndDownloadSVG';
import ShareFlowStepContainer from '../styles/ShareFlowStepContainer';
import ShareViaLinkSVG from './ShareViaLinkSVG';
import UploadAreaContext from './UploadAreaContext';
import UploadAreaWrapper from '../styles/UploadArea';
import UploadButton from '../styles/UploadButton';
import UploadCloudSVG from './UploadCloudSVG';
import UploadImagesSVG from './UploadImagesSVG';
import UploadParagraph from '../styles/UploadParagraph';
import UploadOverlay from '../styles/UploadOverlay';
import { UPLOAD_TYPE } from '../constants';
import messages from '../messages';

class UploadArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accept: props.accept,
      active: false,
    };
    this.dropzoneRef = createRef();
    this.preventActiveState = props.disableDrop;
  }

  componentDidMount() {
    const { disableDrop } = this.props;
    if (!disableDrop) {
      this.dragStartListener = window.document.addEventListener(
        'dragstart',
        this.disableActiveState,
      );
      this.dragEndListener = window.document.addEventListener(
        'dragend',
        this.enableActiveState,
      );
    }
  }

  componentWillUnmount() {
    const { disableDrop } = this.props;
    if (!disableDrop) {
      window.document.removeEventListener('dragstart', this.disableActiveState);
      window.document.removeEventListener('dragend', this.enableActiveState);
    }
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    const { active } = this.state;
    const { disableDrop } = this.props;
    const uploadType = active
      ? UPLOAD_TYPE.DRAG_AND_DROP
      : UPLOAD_TYPE.FILE_CHOOSER;

    if (!disableDrop || uploadType === UPLOAD_TYPE.FILE_CHOOSER) {
      const { onUpload } = this.props;
      let files = [...acceptedFiles, ...rejectedFiles];

      if (typeof DataTransferItem !== 'undefined') {
        files = files.filter(
          f => !(f instanceof DataTransferItem), // filter out objects dragged inside the app
        );
      }

      this.setState({
        active: false,
      });

      if (onUpload && files.length > 0) {
        onUpload(files, {
          uploadType,
        });
      }
    }
  };

  onDragEnter = () => {
    if (this.preventActiveState) return;

    this.setState({
      active: true,
    });
  };

  onDragLeave = () => {
    if (this.preventActiveState) return;

    this.setState({
      active: false,
    });
  };

  disableActiveState = () => {
    this.preventActiveState = true;
  };

  enableActiveState = () => {
    this.preventActiveState = false;
  };

  openFileDialog = () => {
    if (this.dropzoneRef.current) {
      this.dropzoneRef.current.open();
    }
  };

  renderGraphics = () => (
    <>
      <Box mb={7} width={1}>
        <ShareFlowStepContainer>
          <Flex flex="0 1 48px" mb={5}>
            <P color="muted" noMargin>
              <FormattedMessage {...messages.messageUploadImages} />
            </P>
          </Flex>
          <UploadImagesSVG />
          <AbsoluteBox
            height="80px"
            left="50%"
            top="105%"
            width={[0, 1 / 3, null, 1 / 2, 0.55]}
          >
            <CurvedRightArrowSVG />
          </AbsoluteBox>
        </ShareFlowStepContainer>
        <ShareFlowStepContainer>
          <Flex flex="0 1 48px" mb={5}>
            <P color="muted" noMargin>
              <FormattedMessage {...messages.messageShareProject} />
            </P>
          </Flex>
          <ShareViaLinkSVG />
        </ShareFlowStepContainer>
        <ShareFlowStepContainer>
          <Flex flex="0 1 48px" mb={5}>
            <P color="muted" noMargin>
              <FormattedMessage {...messages.messageReviewAndDownload} />
            </P>
          </Flex>
          <ReviewAndDownloadSVG />
        </ShareFlowStepContainer>
      </Box>
    </>
  );

  renderUploadBox = () => {
    const { id, className, withGraphics } = this.props;

    return (
      <UploadAreaWrapper id={id} className={className}>
        {withGraphics && this.renderGraphics()}
        <Flex flexDirection="column" alignItems="center" width={1}>
          <UploadButton onClick={this.openFileDialog}>
            <FormattedMessage {...messages.labelAddImages} />
          </UploadButton>
          <UploadParagraph>
            <FormattedMessage {...messages.messageDragDropToUpload} />
          </UploadParagraph>
        </Flex>
      </UploadAreaWrapper>
    );
  };

  controls = {
    openFileDialog: this.openFileDialog,
    renderUploadBox: this.renderUploadBox,
  };

  render() {
    const { accept, active } = this.state;
    let { children } = this.props;
    const { disableDrop, multiple } = this.props;
    children = isFunction(children) ? children(this.controls) : children;

    return (
      <Dropzone
        data-testid="upload-area-dropzone"
        accept={accept}
        onDrop={this.onDrop}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        ref={this.dropzoneRef}
        style={{ position: 'relative', height: children ? '100%' : 'initial' }}
        inputProps={{ style: { width: disableDrop ? 0 : 'auto' } }}
        disableClick
        multiple={multiple}
      >
        <UploadAreaContext.Provider value={this.controls}>
          {children || this.renderUploadBox()}
          {active && (
            <UploadOverlay>
              <UploadCloudSVG />
              <H1>
                <FormattedMessage {...messages.messageDropFiles} />
              </H1>
            </UploadOverlay>
          )}
        </UploadAreaContext.Provider>
      </Dropzone>
    );
  }
}

UploadArea.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onUpload: PropTypes.func,
  withGraphics: PropTypes.bool,
  disableDrop: PropTypes.bool,
  multiple: PropTypes.bool,
};

UploadArea.defaultProps = {
  id: null,
  className: null,
  children: null,
  accept: undefined,
  onUpload: null,
  withGraphics: false,
  disableDrop: false,
  multiple: true,
};

const ChildrenOnly = ({ children: propsChildren }) => {
  const children = isFunction(propsChildren)
    ? propsChildren({
        openFileDialog: () => null,
        renderUploadBox: () => null,
      })
    : propsChildren;

  return children;
};

export default branch(
  ({ disabled }) => disabled,
  renderComponent(ChildrenOnly),
)(UploadArea);
