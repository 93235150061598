import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import { ArrowDropDown as ArrowDropDownSvg } from '@styled-icons/material';

import { Body, Box, Dropdown, Icon } from 'src/modules/prisma';
import { formattedMessage } from 'src/utils/app-prop-types';
import { librarySpaceUrl, librarySpaceFolderUrl } from 'src/routes/urls';
import {
  SpaceSortBy,
  getSpaceSortLocalStorageKey,
  getFolderSortLocalStorageKey,
  SPACE_SORT_QUERY_KEY,
} from 'src/utils/accessors/space';

import messages from '../_messages';

function SpaceSortChooser() {
  const { query } = useLocation();
  const { folderId, spaceId } = useParams();
  const isFolderPage = !!folderId;
  const localStorageSpaceKey = getSpaceSortLocalStorageKey(spaceId);
  const localStorageFolderKey = getFolderSortLocalStorageKey(folderId);
  const chosenSort =
    query[SPACE_SORT_QUERY_KEY] ||
    (isFolderPage && window.localStorage.getItem(localStorageFolderKey)) ||
    window.localStorage.getItem(localStorageSpaceKey) ||
    SpaceSortBy.UPDATED_AT.DESC();

  useEffect(() => {
    if (query[SPACE_SORT_QUERY_KEY]) {
      window.localStorage.setItem(
        isFolderPage ? localStorageFolderKey : localStorageSpaceKey,
        query[SPACE_SORT_QUERY_KEY],
      );
    }
  }, [query[SPACE_SORT_QUERY_KEY]]); // eslint-disable-line react-hooks/exhaustive-deps

  const ChooserLabel = {
    [SpaceSortBy.CREATED_AT.DESC()]: (
      <FormattedMessage {...messages.labelCreatedAtSort} />
    ),
    [SpaceSortBy.UPDATED_AT.DESC()]: (
      <FormattedMessage {...messages.labelUpdatedAtSort} />
    ),
    [SpaceSortBy.NAME.ASC()]: <FormattedMessage {...messages.labelTitleSort} />,
  }[chosenSort];

  const buildUrl = isFolderPage ? librarySpaceFolderUrl : librarySpaceUrl;

  return (
    <Dropdown
      icon={<SpaceSortChooserToggle>{ChooserLabel}</SpaceSortChooserToggle>}
    >
      <Dropdown.Menu>
        <Dropdown.Header>
          <FormattedMessage {...messages.titleSpaceSortChooser} />
        </Dropdown.Header>
        <Dropdown.Link
          to={buildUrl(
            { folderId, spaceId },
            {
              ...query,
              [SPACE_SORT_QUERY_KEY]: SpaceSortBy.NAME.ASC(),
            },
          )}
          label={<FormattedMessage {...messages.labelTitleSort} />}
          size="normal"
          active={chosenSort === SpaceSortBy.NAME.ASC()}
          exact
        />
        <Dropdown.Link
          to={buildUrl(
            { folderId, spaceId },
            {
              ...query,
              [SPACE_SORT_QUERY_KEY]: SpaceSortBy.CREATED_AT.DESC(),
            },
          )}
          label={<FormattedMessage {...messages.labelCreatedAtSort} />}
          size="normal"
          active={chosenSort === SpaceSortBy.CREATED_AT.DESC()}
          exact
        />
        <Dropdown.Link
          to={buildUrl(
            { folderId, spaceId },
            {
              ...query,
              [SPACE_SORT_QUERY_KEY]: SpaceSortBy.UPDATED_AT.DESC(),
            },
          )}
          label={<FormattedMessage {...messages.labelUpdatedAtSort} />}
          size="normal"
          active={chosenSort === SpaceSortBy.UPDATED_AT.DESC()}
          exact
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}

function SpaceSortChooserToggle({ active, children, onClick }) {
  return (
    <Box alignItems="center" display="flex" onClick={onClick} role="button">
      <Body
        color="grey.600"
        fontWeight="light"
        style={{ cursor: 'pointer' }}
        textSize="regular"
      >
        {children}
      </Body>
      <Box ml={1}>
        <Icon
          active={active || undefined}
          color="grey.600"
          size="medium"
          type={<ArrowDropDownSvg />}
          interactive
        />
      </Box>
    </Box>
  );
}

SpaceSortChooserToggle.propTypes = {
  active: PropTypes.bool,
  children: formattedMessage.isRequired,
  onClick: PropTypes.func,
};

SpaceSortChooserToggle.defaultProps = {
  active: undefined,
  onClick: undefined,
};

export default SpaceSortChooser;
