import { createSelectorCreator, defaultMemoize } from 'reselect';
import { is } from 'immutable';

// const createHashCodeImmutableSelector = createSelectorCreator(
//   defaultMemoize,
//   // Hash code doesn't work
//   // https://github.com/immutable-js/immutable-js/issues/1547
//   (object, otherObject) => {
//     if (object && otherObject && object.hashCode && otherObject.hashCode) {
//       return object.hashCode() === otherObject.hashCode();
//     }

//     return object === otherObject;
//   },
// );

// Needed to compare immutable objects since our select utility always brings
// a different object reference, so equality check (===) doesn't work
const createImmutableSelector = createSelectorCreator(defaultMemoize, is);

export default createImmutableSelector;
