import styled from 'styled-components/macro';

const CommentSection = styled.section`
  align-items: flex-start;
  display: flex;
  position: relative;
  flex-direction: row;
`;

export default CommentSection;
