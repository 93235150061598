import { defineMessages } from 'react-intl';

export default defineMessages({
  labelOwner: {
    id: 'UserRoleBox.labelOwner',
    defaultMessage: 'Owner',
  },
  labelManager: {
    id: 'UserRoleBox.labelManager',
    defaultMessage: 'Manager',
  },
  labelMember: {
    id: 'UserRoleBox.labelMember',
    defaultMessage: 'Member',
  },
  labelEditor: {
    id: 'UserRoleBox.labelEditor',
    defaultMessage: 'Editor',
  },
  labelViewer: {
    id: 'UserRoleBox.labelViewer',
    defaultMessage: 'Viewer',
  },
  labelDisableLinkAccess: {
    id: 'UserRoleBox.labelDisableLinkAccess',
    defaultMessage: 'Disable link access',
  },
  messageFullAccessRole: {
    id: 'UserRoleBox.messageFullAccessRole',
    defaultMessage: 'Has full access',
  },
  messageMemberRole: {
    id: 'UserRoleBox.messageMemberRole',
    defaultMessage: 'Has full access to this project',
  },
  messageViewerRole: {
    id: 'UserRoleBox.messageViewerRole',
    defaultMessage: 'Can view and comment',
  },
  labelLearnMoreAboutRoles: {
    id: 'UserRoleBox.labelLearnMoreAboutRoles',
    defaultMessage: 'Learn more about roles',
  },
  messagePendingInvitation: {
    id: 'UserRoleBox.messagePendingInvitation',
    defaultMessage: 'Pending invitation',
  },
});
