import styled from 'styled-components/macro';
import { H4 } from '@picter/prisma';

const BoxTitle = styled(H4).attrs({
  color: 'inherit',
  noMargin: true,
})`
  font-weight: normal;
  margin-bottom: ${props => props.theme.space[2]}px;
`;

export default BoxTitle;
