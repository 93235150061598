import { defineMessages } from 'react-intl';

export default defineMessages({
  messageReviewLinkTooltip: {
    id: 'ProjectShareState.messageReviewLinkTooltip',
    defaultMessage: 'Accessible by review link',
  },
  messagePresentLinkTooltip: {
    id: 'ProjectShareState.messagePresentLinkTooltip',
    defaultMessage: 'Accessible by presentation link',
  },
  messageRequestFilesLinkTooltip: {
    id: 'ProjectShareState.messageLinkTooltip',
    defaultMessage: 'Accessible by inbox link',
  },
  messageInvitedGuests: {
    id: 'ProjectShareState.messageInvitedGuests',
    defaultMessage: 'Guests',
  },
});
