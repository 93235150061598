import React from 'react';

const InfoSvg = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="8" cy="8" r="6.5" stroke="currentColor" />
    <line x1="8" y1="12" x2="8" y2="7" stroke="currentColor" />
    <circle cx="8" cy="5.25" r="0.75" fill="currentColor" />
  </svg>
);

export default InfoSvg;
