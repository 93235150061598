import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Icon, P } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';

import Avatar from 'src/components/Avatar';
import { get } from 'src/utils/accessors';

import RoleItem from './components/RoleItem';
import RolesDropdown from './components/RolesDropdown';
import messages from './messages';

const UserRoleBox = ({
  user,
  invitation,
  scope,
  title,
  labelRemove,
  onClickOwner,
  onClickManager,
  messageManager,
  onClickMember,
  labelMember,
  messageMember,
  onClickEditor,
  onClickViewer,
  labelViewer,
  messageViewer,
  onClickRemove,
  disabled,
}) => {
  const name = get(user, 'attributes.publicName');
  const email =
    get(user, 'attributes.email') || get(invitation, 'attributes.email');

  return (
    <RoleItem
      left={
        !user.isEmpty() ? (
          <Avatar email={email} name={name} size="large" />
        ) : (
          <Icon
            type="mail_outline"
            glyphSize="small"
            size="xlarge"
            color="muted"
            borderColor={p => p.theme.colors.gray[100]}
          />
        )
      }
      middle={
        !user.isEmpty() ? (
          <>
            <P noMargin>{name}</P>
            <P textStyle="captionSmall" color="muted" noMargin>
              {email}
            </P>
          </>
        ) : (
          <>
            <P noMargin>
              <FormattedMessage {...messages.messagePendingInvitation} />
            </P>
            <P textStyle="captionSmall" color="muted" noMargin>
              {email}
            </P>
          </>
        )
      }
      right={
        <RolesDropdown
          disabled={disabled}
          currentScope={scope}
          title={title}
          labelRemove={labelRemove}
          onClickOwner={onClickOwner}
          onClickManager={onClickManager}
          messageManager={messageManager}
          onClickMember={onClickMember}
          labelMember={labelMember}
          messageMember={messageMember}
          onClickEditor={onClickEditor}
          onClickViewer={onClickViewer}
          labelViewer={labelViewer}
          messageViewer={messageViewer}
          onClickRemove={onClickRemove}
        />
      }
    />
  );
};

UserRoleBox.propTypes = {
  user: PropTypes.instanceOf(Map),
  invitation: PropTypes.instanceOf(Map),
  scope: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  labelRemove: PropTypes.node.isRequired,
  onClickOwner: PropTypes.func,
  onClickManager: PropTypes.func,
  messageManager: PropTypes.node,
  onClickMember: PropTypes.func,
  labelMember: PropTypes.objectOf({
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
  }),
  messageMember: PropTypes.node,
  onClickEditor: PropTypes.func,
  onClickViewer: PropTypes.func,
  labelViewer: PropTypes.objectOf({
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
  }),
  messageViewer: PropTypes.node,
  onClickRemove: PropTypes.func,
  disabled: PropTypes.bool,
};

UserRoleBox.defaultProps = {
  user: Map(),
  invitation: Map(),
  onClickOwner: null,
  onClickManager: null,
  messageManager: null,
  onClickMember: null,
  messageMember: null,
  labelMember: null,
  labelViewer: null,
  messageViewer: null,
  onClickEditor: null,
  onClickViewer: null,
  onClickRemove: null,
  disabled: false,
};

export default UserRoleBox;

export { default as RolesDropdown } from './components/RolesDropdown';
export { default as RoleItem } from './components/RoleItem';
