import styled from 'styled-components/macro';

import {
  color,
  gap,
  space,
  layout, // height, width, display..
  flexbox,
  position,
} from './mixins';
import Element from './Element';

const Box = styled(Element).attrs(props => ({
  className: gap.getClass(props),
}))`
  ${color};
  ${flexbox};
  ${layout};
  ${position};
  ${space};

  .${gap.getClass} + .${gap.getClass}& {
    ${gap};
  }
`;

Box.defaultProps = {
  blacklist: [
    ...Element.defaultProps.blacklist,
    ...color.propNames,
    ...flexbox.propNames,
    ...gap.propNames,
    ...layout.propNames,
    ...position.propNames,
    ...space.propNames,
  ],
  gapClass: gap.class,
  tag: 'div',
};

Box.displayName = 'Box';

export default Box;
