import { defineMessages } from 'react-intl';

export default defineMessages({
  labelAddImages: {
    defaultMessage: 'Add files',
    id: 'ProjectPageActionsDropdownMobile.labelAddImages',
  },
  labelComments: {
    defaultMessage: 'Comments',
    id: 'ProjectPageActionsDropdownMobile.labelComments',
  },
  labelDeleteCollection: {
    defaultMessage: 'Delete collection',
    id: 'ProjectPageActionsDropdownMobile.labelDeleteCollection',
  },
  labelDeleteProject: {
    defaultMessage: 'Delete project',
    id: 'ProjectPageActionsDropdownMobile.labelDeleteProject',
  },
  labelDuplicateProject: {
    defaultMessage: 'Duplicate project',
    id: 'ProjectPageActionsDropdownMobile.labelDuplicateProject',
  },
  labelRenameCollection: {
    defaultMessage: 'Rename collection',
    id: 'ProjectPageActionsDropdownMobile.labelRenameCollection',
  },
  labelShare: {
    defaultMessage: 'Share',
    id: 'ProjectPageActionsDropdownMobile.labelShare',
  },
  messageModalDelete: {
    defaultMessage: 'This will delete the project including all its files.',
    id: 'ProjectPageActionsDropdownMobile.messageModalDelete',
  },
  labelFollowProject: {
    id: 'ProjectPageActionsDropdownMobile.labelFollowProject',
    defaultMessage: 'Follow',
  },
  labelUnfollowProject: {
    id: 'ProjectPageActionsDropdownMobile.labelUnfollowProject',
    defaultMessage: 'Unfollow',
  },
});
