import styled from 'styled-components/macro';

const SidebarPanel = styled.aside`
  background: ${props => props.theme.colors.white};
  backface-visibility: hidden;
  box-shadow: ${props => props.theme.shadows.soft};
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 200;
`;

export default SidebarPanel;
