import { defineMessages } from 'react-intl';

export default defineMessages({
  titleDownload: {
    id: 'ProjectDownloadModal.titleDownload',
    defaultMessage: 'Download',
  },
  labelDownloadFiles: {
    id: 'ProjectDownloadModal.labelDownloadFiles',
    defaultMessage: 'Download files',
  },
  labelAdvanced: {
    id: 'ProjectDownloadModal.labelAdvanced',
    defaultMessage: 'Advanced',
  },
  labelHideAdvanced: {
    id: 'ProjectDownloadModal.labelHideAdvanced',
    defaultMessage: 'Hide advanced',
  },
  messageImagesCount: {
    id: 'ProjectDownloadModal.messageImagesCount',
    defaultMessage: `{count, plural,
      one {# file}
      other {# files}
    }`,
  },
  labelAnyFilter: {
    id: 'ProjectDownloadModal.labelAnyFilter',
    defaultMessage: 'Approved',
  },
  labelRejectedFilter: {
    id: 'ProjectDownloadModal.labelRejectedFilter',
    defaultMessage: 'Rejected',
  },
  labelUndecidedFilter: {
    id: 'ProjectDownloadModal.labelUndecidedFilter',
    defaultMessage: 'Undecided',
  },
  labelApprovedByFilter: {
    id: 'ProjectDownloadModal.labelApprovedByFilter',
    defaultMessage: 'Approved by',
  },
  labelFileNamePattern: {
    id: 'ProjectDownloadModal.labelFileNamePattern',
    defaultMessage: 'Rename',
  },
  labelFileNamePatternOptionOriginalFilename: {
    id: 'ProjectDownloadModal.labelFileNamePatternOptionOriginalFilename',
    defaultMessage: 'Keep original filename',
  },
  labelFileNamePatternOptionTitle: {
    id: 'ProjectDownloadModal.labelFileNamePatternOptionTitle',
    defaultMessage: 'title.jpg',
  },
  labelFileNamePatternOptionOrderAndTitle: {
    id: 'ProjectDownloadModal.labelFileNamePatternOptionOrderAndTitle',
    defaultMessage: '01 - title.jpg',
  },
});
