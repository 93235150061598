export const MAX_IMAGE_FILE_SIZE = 5000000000; // 5GB

export const PROJECT_RELATION_FILTER = {
  OWNER: 'owner',
  EDITOR: 'editor',
  VIEWER: 'viewer',
  MEMBER: 'member',
};

export const IMAGE_FILE_INFO_TYPE = {
  BUCKET: 'bucket',
  ETAG: 'etag',
  KEY: 'key',
  LOCATION: 'location',
  SIZE: 'size',
  VERSIONID: 'versionid',
};

export const ACCEPTED_IMAGE_FILE_TYPES = [
  'image/jpeg',
  'image/tiff',
  'image/png',
  'image/gif',
];

export const ACCEPTED_DOCUMENT_FILE_TYPES = ['application/pdf'];

export const DOCUMENT_EXTENSION_TYPE_MAP = {
  pdf: 'application/pdf',
};

export const VIDEO_EXTENSION_TYPE_MAP = {
  mpg: 'video/mpeg',
  mp4: 'video/mp4',
  m4v: 'video/x-m4v',
  mov: 'video/quicktime',
  m2ts: 'video/MP2T',
};

export const ACCEPTED_VIDEO_FILE_TYPES = [
  'video/quicktime',
  'video/mpeg',
  'video/mp4',
  'video/x-m4v',
  'video/MP2T',
];

export const ACCEPTED_FILE_TYPES = [
  ...ACCEPTED_IMAGE_FILE_TYPES,
  ...ACCEPTED_VIDEO_FILE_TYPES,
  ...ACCEPTED_DOCUMENT_FILE_TYPES,
];
