import { useMemo } from 'react';
import { List } from 'immutable';
import { useParams } from 'react-router-dom';

import uploadStorage from 'src/utils/upload-storage';
import useProjectImages from 'src/hooks/use-project-images';
import { get } from 'src/utils/accessors';

const useGridImages = ({ project, filter }) => {
  const { projectId: publicKey, collectionId } = useParams();
  const uploadGroup = uploadStorage.state.groups[publicKey];

  const collectionWithImages = get(
    project,
    'relationships.collections',
    List(),
  ).find(col => get(col, 'id') && get(col, 'id') === collectionId);

  const images = useProjectImages({
    project,
    collection: collectionWithImages,
    filter,
  });

  const filteredImages = useMemo(() => {
    // Filters images that have no file and no upload pending. It means images which
    // uploads were canceled or something similar.
    return images.filter(image => {
      const parsedImage = collectionId
        ? get(image, 'relationships.image')
        : image;

      const upload = get(uploadGroup, `files.${get(image, 'id')}`);
      return (
        get(parsedImage, 'id') || (upload && upload.preview && !upload.errors)
      );
    });
  }, [collectionId, images, uploadGroup]);

  return filteredImages;
};

export default useGridImages;
