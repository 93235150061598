import Immutable from 'immutable';
import { REL_HAS_MANY } from '../../constants';
import {
  getInversableRelationships,
  getRelation,
  getInverseRelation,
} from '../../schema';

export default function createDataReducer(state, action, schemas) {
  const { meta, payload } = action;
  const { meta: payloadMeta, ...resources } = payload;
  // extract type resource from payload
  const typeResourcesEntries = Object.entries(resources[meta.type]);

  return state.withMutations(mutableState => {
    typeResourcesEntries.forEach(([id, resource]) => {
      // get inversable relationships of the resource
      const inversableRelationships = getInversableRelationships(
        schemas && schemas[meta.type] ? schemas[meta.type] : undefined,
        resource,
      );

      mutableState.mergeDeep({
        ...resources,
        meta: {
          [action.endpoint]: {
            meta:
              payloadMeta &&
              payloadMeta[action.endpoint] &&
              payloadMeta[action.endpoint].meta,
            loading: false,
          },
        },
      });

      if (inversableRelationships) {
        // create payload for inversable relationships
        const resourceIdentifier = Immutable.fromJS({
          id,
          type: resource.type,
        });
        // iterate over all inversable relationships and update them
        inversableRelationships.forEach(([relationName, { data }]) => {
          const relation = getRelation(schemas, meta.type, relationName);
          const inverseRelation = getInverseRelation(
            schemas,
            meta.type,
            relationName,
            resource,
          );

          // if there's no related data in the resource defined,
          // we can't update any inverse relationships
          if (!data) {
            return;
          }

          const relatedItems = Array.isArray(data) ? data : [data];
          relatedItems.forEach(relatedItem => {
            const dataPath = [
              relation.type,
              relatedItem.id,
              'relationships',
              inverseRelation.name,
              'data',
            ];
            // if inverse relation is a list, we possibly need to update it
            if (inverseRelation.relation === REL_HAS_MANY) {
              if (mutableState.getIn(dataPath)) {
                mutableState.updateIn(dataPath, list => {
                  const resourceIndex = list.findIndex(
                    item => item.get('id') === resourceIdentifier.get('id'),
                  );
                  if (resourceIndex !== -1) {
                    return list.set(resourceIndex, resourceIdentifier);
                  }
                  return list.push(resourceIdentifier);
                });
              } else {
                mutableState.setIn(
                  dataPath,
                  Immutable.List([resourceIdentifier]),
                );
              }
            } else {
              mutableState.setIn(dataPath, resourceIdentifier);
            }
          });
        });
      }
    });
  });
}
