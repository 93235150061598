import React from 'react';

export default function ToggleButtonSvg() {
  return (
    <svg
      width="192"
      height="94"
      viewBox="0 0 192 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <rect
          x="1"
          y="-1"
          width="188.095"
          height="91.6701"
          rx="45.835"
          transform="matrix(1 8.74228e-08 8.74228e-08 -1 8.74228e-08 91.6699)"
          stroke="#5C62CA"
          strokeWidth="2"
          strokeDasharray="13.78 13.78"
        />
        <path
          opacity="0.3"
          d="M78.844 9.64258L49.9656 9.64258C29.2178 9.64258 12.3984 26.2942 12.3984 46.8351C12.3984 67.376 29.2178 84.0276 49.9655 84.0276H78.844V9.64258Z"
          fill="#FB5C5C"
        />
        <path
          d="M78.8447 84.0273L140.132 84.0273C160.88 84.0273 177.699 67.3757 177.699 46.8348C177.699 26.2939 160.88 9.64229 140.132 9.64228L78.8447 9.64227L78.8447 84.0273Z"
          fill="white"
        />
        <circle
          r="37.1925"
          transform="matrix(1 8.71524e-08 8.76939e-08 -1 78.5168 46.7596)"
          fill="#FB5C5C"
        />
        <circle
          cx="78.8425"
          cy="46.8357"
          r="15.53"
          stroke="white"
          strokeWidth="2"
        />
        <rect
          x="74.1927"
          y="28.0677"
          width="9.23188"
          height="20.6625"
          fill="white"
          stroke="#FB5C5C"
          strokeWidth="7.23188"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="191.473" height="93.6701" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
