import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Close as CloseSvg, Remove as RemoveSvg } from '@styled-icons/material';
import classNames from 'classnames';

import ContentIcon from '../styles/ContentIcon';
import ContentIconGroup from '../styles/ContentIconGroup';

const RejectedIcon = forwardRef(
  ({ active, boxSize, className, id, onClick, size, ...props }, ref) => {
    return (
      <ContentIconGroup
        className={className}
        id={id}
        onClick={onClick}
        role="button"
        ref={ref}
        {...props}
      >
        <ContentIcon
          className={classNames('content-icon content-icon--active', {
            'content-icon--visible': active,
          })}
          backgroundColor="danger"
          backgroundOpacity="0.05"
          borderColor="danger"
          color="danger"
          content={RemoveSvg}
          type={<CloseSvg />}
          boxSize={boxSize}
          size={size}
        />
        <ContentIcon
          className="content-icon content-icon--hover"
          backgroundColor="danger"
          backgroundOpacity="0.05"
          borderColor="danger"
          color="black"
          content={RemoveSvg}
          type={<CloseSvg />}
          boxSize={boxSize}
          size={size}
        />
        <ContentIcon
          className="content-icon content-icon--default"
          backgroundColor="danger"
          backgroundOpacity="0.05"
          borderColor="danger"
          color="danger"
          type={<CloseSvg />}
          boxSize={boxSize}
          size={size}
        />
      </ContentIconGroup>
    );
  },
);

RejectedIcon.defaultProps = {
  active: false,
  boxSize: undefined,
  className: undefined,
  id: undefined,
  onClick: undefined,
  size: undefined,
};

RejectedIcon.propTypes = {
  active: PropTypes.bool,
  boxSize: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
};

export default RejectedIcon;
