import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';
import { Flex, Box } from '@rebass/grid';
import { withRouter } from 'react-router-dom';
import { Map } from 'immutable';
import { TrackerEvent } from '@picter/tracker';

import { historyShape } from 'src/utils/app-prop-types';
import ModalActions from 'src/styles/ModalActions';

import messages from '../messages';
import useHandlers from '../hooks/use-handlers';

const CreateCollectionModalForm = ({
  closeConfirmCreateModal,
  history,
  imagesIds,
  project,
}) => {
  const [collectionName, setCollectionName] = useState('');
  const { createNewCollection } = useHandlers({
    history,
    imagesIds,
    project,
  });

  return (
    <TrackerEvent name="Create" trackSubmit>
      <form
        onSubmit={e => {
          e.preventDefault();
          closeConfirmCreateModal();
          createNewCollection(collectionName);
        }}
      >
        <ModalActions>
          <Flex flexDirection="column" flex={1}>
            <Box>
              <TextField
                name="name"
                value={collectionName}
                onChange={e => setCollectionName(e.target.value)}
                maxLength={50}
                autoFocus
                required
              />
            </Box>
            <Flex justifyContent="flex-end">
              <Box>
                <TrackerEvent name="Cancel" trackClick>
                  <Button
                    colorScheme="grayscale"
                    flat
                    onClick={closeConfirmCreateModal}
                  >
                    <FormattedMessage {...messages.labelModalCancel} />
                  </Button>
                </TrackerEvent>
                <Button
                  colorScheme="primary"
                  flat
                  type="submit"
                  disabled={collectionName === ''}
                >
                  <FormattedMessage {...messages.labelModalCreate} />
                </Button>
              </Box>
            </Flex>
          </Flex>
        </ModalActions>
      </form>
    </TrackerEvent>
  );
};

CreateCollectionModalForm.propTypes = {
  closeConfirmCreateModal: PropTypes.func.isRequired,
  history: historyShape.isRequired,
  imagesIds: PropTypes.arrayOf(PropTypes.string),
  onCreate: PropTypes.func,
  project: PropTypes.instanceOf(Map),
};

CreateCollectionModalForm.defaultProps = {
  imagesIds: [],
  onCreate: null,
  project: Map(),
};

export default withRouter(CreateCollectionModalForm);
