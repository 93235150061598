import { Map } from 'immutable';

import {
  LOGIN_SUCCESS,
  SIGNUP_SUCCESS,
  CHECK_USER_SUCCESS,
  LOGOUT_SUCCESS,
  LOGIN_ERROR,
  SIGNUP_ERROR,
  CHECK_USER_ERROR,
} from '../constants';

const initialState = Map({
  authenticated: false,
});

export default (state = initialState, action = { type: undefined }) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case SIGNUP_SUCCESS:
    case CHECK_USER_SUCCESS:
      return state.set('authenticated', true);

    case LOGIN_ERROR:
    case SIGNUP_ERROR:
    case CHECK_USER_ERROR:
    case LOGOUT_SUCCESS:
      return state.set('authenticated', false);

    default:
      return state;
  }
};
