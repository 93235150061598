import React from 'react';
import PropTypes from 'prop-types';
import { ButtonElement as Button, Body, Modal } from '@picter/prisma';
import { Flex } from '@rebass/grid';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { TrackerEvent, TrackerScope } from '@picter/tracker';

import { Box } from 'src/modules/prisma';

import messages from '../messages';

function DeleteCollectionModal({ onClose, onClickDelete, open }) {
  return (
    <TrackerScope name="Delete collection modal">
      <Modal
        title={<FormattedMessage {...messages.titleDeleteCollectionModal} />}
        onClickClose={onClose}
        open={open}
      >
        <TrackerEvent name="Delete collection modal" trackView>
          <Body>
            <FormattedHTMLMessage {...messages.messageDeleteCollectionModal} />
          </Body>
          <Box mt={7} width={1}>
            <Flex flexDirection="row-reverse">
              <Box>
                <TrackerEvent name="Delete" trackClick>
                  <Button
                    color="danger"
                    onClick={onClickDelete}
                    px={0}
                    py={0}
                    textStyle="action.large"
                    variant="flat"
                  >
                    <FormattedMessage {...messages.labelDelete} />
                  </Button>
                </TrackerEvent>
              </Box>
              <Box gmr={6}>
                <TrackerEvent name="Cancel" trackClick>
                  <Button
                    color="grey.600"
                    onClick={onClose}
                    px={0}
                    py={0}
                    textStyle="action.large"
                    variant="flat"
                  >
                    <FormattedMessage {...messages.labelCancel} />
                  </Button>
                </TrackerEvent>
              </Box>
            </Flex>
          </Box>
        </TrackerEvent>
      </Modal>
    </TrackerScope>
  );
}

DeleteCollectionModal.propTypes = {
  onClose: PropTypes.func,
  onClickDelete: PropTypes.func,
  open: PropTypes.bool,
};

DeleteCollectionModal.defaultProps = {
  onClose: null,
  onClickDelete: null,
  open: false,
};

export default DeleteCollectionModal;
