import React, { useEffect, useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  ButtonElement as Button,
  Modal,
  Body,
} from '@picter/prisma';
import { Map } from 'immutable';
import { FormattedMessage, useIntl } from 'react-intl';
import { Flex, Box } from '@rebass/grid';

import { matchShape } from 'src/utils/app-prop-types';
import { useToriiActions } from 'src/modules/torii';
import { useModalState } from 'src/hooks/use-modal';
import { useCurrentUser } from 'src/hooks/use-resource';
import { get } from 'src/utils/accessors';
import { createCollectionForInboxLink } from 'src/actions/collection';

import messages from '../messages';

const UploadModal = ({ project, collection, match }) => {
  const actions = useToriiActions();
  const intl = useIntl();
  const [collectionName, setCollectionName] = useState('');
  const currentUser = useCurrentUser();
  const { open, close, visible } = useModalState();

  const spaceName = get(project, 'relationships.space.attributes.name');

  const userName = get(currentUser, 'attributes.firstName');

  const onChangeCollectionName = useCallback(e => {
    setCollectionName(e.target.value);
  }, []);

  const onSubmit = useCallback(async () => {
    await createCollectionForInboxLink(
      {
        projectId: get(project, 'id'),
        projectPublicKey: match.params.projectId,
        collectionName,
      },
      { localCreate: actions.localCreate },
    );
    return close();
  }, [
    actions.localCreate,
    close,
    collectionName,
    match.params.projectId,
    project,
  ]);

  useEffect(() => {
    if (collection.isEmpty()) {
      const date = new Date();
      const formatedDateTime = intl.formatDate(date, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
      });
      setCollectionName(`Inbox ${formatedDateTime}`);
      open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      open={visible}
      title={
        userName ? (
          <FormattedMessage
            {...messages.titleAuthenticatedUserRegisterModal}
            values={{ name: userName }}
          />
        ) : (
          <FormattedMessage
            {...messages.titleUnAuthenticatedUserRegisterModal}
          />
        )
      }
    >
      <Body>
        <FormattedMessage
          {...messages.messageLiteUserRegisterModal}
          values={{ owner: spaceName }}
        />
      </Body>
      <Box mt={4} mb={4}>
        <form onSubmit={onSubmit}>
          <FormattedMessage {...messages.placeholderCollectionNameForm}>
            {formattedMessage => (
              <TextField
                autoFocus
                name="collection-name"
                onChange={onChangeCollectionName}
                label={formattedMessage}
                value={collectionName}
                required
              />
            )}
          </FormattedMessage>
        </form>
      </Box>
      <Flex justifyContent="flex-end">
        <Button
          color="primary"
          disabled={!collectionName}
          textStyle="action.large"
          px={0}
          py={0}
          type="submit"
          variant="flat"
          onClick={onSubmit}
        >
          <FormattedMessage {...messages.labelCollectionNameFormModalConfirm} />
        </Button>
      </Flex>
    </Modal>
  );
};

UploadModal.propTypes = {
  project: PropTypes.instanceOf(Map).isRequired,
  collection: PropTypes.instanceOf(Map),
  match: matchShape.isRequired,
};

UploadModal.defaultProps = {
  collection: Map(),
};

export default memo(UploadModal);
