import React, {
  forwardRef,
  useMemo,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react';

import {
  useVideo,
  Video,
  VideoControls,
  VideoPlayerState,
  TimelineMarker,
} from 'src/components/VideoPlayer';
import {
  useAnnotations,
  ANNOTATION_IN_PROGRESS_ID,
} from 'src/components/Annotations';
import { transformFrameIntoSeconds } from 'src/utils/framerate';
import ReactPlayer from 'react-player';

type VideoPlayerRef = {
  actions: Record<string, unknown>;
  state: VideoPlayerState;
};

type VideoPlayerProps = {
  src: string;
  frameRate: number;
  thumbnail: string;
  height: number;
  width: number;
};

const VideoPlayer = forwardRef<VideoPlayerRef, VideoPlayerProps>(
  ({ src, frameRate, thumbnail, height, width }, ref) => {
    const {
      actions,
      state,
      state: { duration, playing },
    } = useVideo({
      light: false,
      playing: false,
    });
    const [
      { deselect, select },
      { annotations, selectedId },
    ] = useAnnotations();
    const videoPlayerRef = useRef<ReactPlayer>(null);
    const timelineMarkers = useMemo(() => {
      const markers: TimelineMarker[] = [];
      annotations.forEach(annotation => {
        const annotationId = annotation.id;
        if (annotationId !== ANNOTATION_IN_PROGRESS_ID) {
          const seconds = transformFrameIntoSeconds(
            annotation.meta?.frame ?? 0,
            frameRate,
          );

          markers.push({
            id: annotation.id,
            position: seconds / duration,
          });
        }
      });
      return markers;
    }, [annotations, frameRate, duration]);

    const selectedMarker = useMemo(() => {
      if (selectedId && selectedId !== ANNOTATION_IN_PROGRESS_ID) {
        if (playing) actions.setPlaying(false);

        if (duration /* video is loaded */) {
          return timelineMarkers.find(marker => marker.id === selectedId);
        }
      }
      return undefined;
    }, [selectedId, duration]);

    const secondsPerFrame = 1 / frameRate;
    const secondsPerFramePercentage = secondsPerFrame / duration;

    useImperativeHandle(
      ref,
      () => ({
        actions,
        state,
      }),
      [actions, state],
    );

    return (
      <>
        <Video
          ref={videoPlayerRef}
          src={src}
          height={height}
          width={width}
          {...state}
          startAt={selectedMarker?.position}
          onDuration={actions.setDuration}
          onProgress={(progress: { played: number; loaded: number }) => {
            if (!state.seeking) actions.setProgress(progress);
          }}
          onEnded={() => actions.setPlaying(false)}
          onPlay={() => {
            if (!state.playing) actions.setPlaying(true);
            deselect();
          }}
          onPause={() => actions.setPlaying(false)}
          onFullscreenError={() => actions.setFullscreen(false)}
          onFullscreenOff={() => actions.setFullscreen(false)}
          onReady={() => actions.setLight(false)}
        />
        {!state.light && (
          <VideoControls
            {...state}
            markers={timelineMarkers}
            seekStep={secondsPerFramePercentage}
            onChangeVolume={(_, newVolume) => actions.setVolume(newVolume)}
            onClickMarker={marker => {
              select(marker.id);
              actions.setPlaying(false);
            }}
            onClickPlayPause={actions.togglePlay}
            onClickFullscreen={() => actions.setFullscreen(true)}
            onToggleVolume={actions.toggleMute}
            onSeekStart={() => {
              deselect();
              actions.setSeeking(true);
            }}
            onSeek={newPosition => actions.setProgress({ played: newPosition })}
            onSeekEnd={endPosition => {
              actions.setSeeking(false);
              videoPlayerRef.current?.seekTo(endPosition);
            }}
            onEscape={event => {
              event.preventDefault();
              event.stopImmediatePropagation();

              actions.setLight(thumbnail);
            }}
          />
        )}
      </>
    );
  },
);

export default VideoPlayer;
