import { useMemo, useCallback } from 'react';
import { Map, List } from 'immutable';
import isFunction from 'lodash/isFunction';
import { makeMemoSelect } from '../../selectors';

import useToriiSelector from '../use-torii-selector';
import useToriiLoader from '../use-torii-loader';

import { mapRelationshipsToEntitiesTypes } from './utils';

export const resourceShouldLoad = (data, config = {}) => {
  const parsedRequest = config.request || config.request === undefined;
  return isFunction(parsedRequest) ? parsedRequest(data) : parsedRequest;
};

export const resourceShouldRevalidate = (data, config = {}) => {
  const parsedRevalidate = config.revalidate || config.revalidate === undefined;
  return isFunction(parsedRevalidate)
    ? parsedRevalidate(data)
    : parsedRevalidate;
};

export const useResourceSelector = ({
  resourceType,
  id,
  include,
  includeOnSelector,
  schemas,
}) => {
  const selectorIncludes = [...include, ...includeOnSelector];
  const selectorIncludesMemoString = [...include, ...includeOnSelector].join(
    '-',
  );

  const memoSelect = useMemo(() => {
    return makeMemoSelect(
      mapRelationshipsToEntitiesTypes({
        resourceType,
        id,
        relationships: selectorIncludes,
        schemas,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceType, id, selectorIncludesMemoString]);

  const selector = useCallback(
    state => {
      const result = memoSelect(state, resourceType, {
        id,
        include: selectorIncludes,
      });
      return Map.isMap(result) || List.isList(result) ? result : Map();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resourceType, id, memoSelect, selectorIncludesMemoString],
  );

  return useToriiSelector(selector);
};

export const useResourceLoader = (
  { resourceType, id, include, includeOnRequest, config },
  { shouldLoad = true, revalidate, revalidateTimeout, autoRevalidate } = {},
) => {
  const loaderIncludes = [...include, ...includeOnRequest];
  const loaderIncludesMemoString = [...include, ...includeOnRequest].join('-');
  const configString = JSON.stringify(Object.values(config || {}));

  const loader = useMemo(() => {
    return [
      [
        resourceType,
        {
          id,
          include: loaderIncludes.length ? loaderIncludes : undefined,
          ...config,
        },
      ],
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceType, id, configString, loaderIncludesMemoString]);
  useToriiLoader(loader, {
    shouldLoad,
    revalidate,
    revalidateTimeout,
    autoRevalidate,
  });
};

export default function useResource(
  resourceType,
  { id, include = [], includeOnRequest = [], includeOnSelector = [] },
  config = {},
  schemas,
) {
  if (!resourceType) throw new Error('useResource: Resource must be defined.');

  const data = useResourceSelector({
    resourceType,
    id,
    include,
    includeOnSelector,
    schemas,
  });

  const shouldLoad = resourceShouldLoad(data, config);
  const shouldRevalidate = resourceShouldRevalidate(data, config);

  useResourceLoader(
    { resourceType, id, include, includeOnRequest, config },
    {
      shouldLoad,
      revalidate: shouldRevalidate,
      revalidateTimeout: config.revalidateTimeout,
      autoRevalidate: config.autoRevalidate,
    },
  );

  return data;
}
