import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import paths from 'src/routes/paths';
import lazyWithPreload from 'src/utils/lazy-with-preload';

import CollectionRoutes from './c/{collectionId}/_routes';
import Document from './_document';
import ProjectPage from '.';

const { LIBRARY_PROJECT_IMAGE_PATH, LIBRARY_PROJECT_COLLECTION_PATH } = paths;

const ImagePage = lazyWithPreload(() => import('./i/{imageId}'));

function ProjectRoutes() {
  useEffect(() => {
    ImagePage.preload();
  }, []);

  return (
    <Document>
      <Switch>
        <Route
          path={LIBRARY_PROJECT_COLLECTION_PATH}
          component={CollectionRoutes}
        />
        <Route path={LIBRARY_PROJECT_IMAGE_PATH} component={ImagePage} />
        <Route component={ProjectPage} />
      </Switch>
    </Document>
  );
}

export default ProjectRoutes;
