import React from 'react';

export default function NewPicterLogoSvg() {
  return (
    <svg
      width="98"
      height="24"
      viewBox="0 0 98 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 15.989 1.94635 19.5234 4.94118 21.7053V12C4.94118 8.10152 8.10152 4.94118 12 4.94118C15.8985 4.94118 19.0588 8.10152 19.0588 12C19.0588 15.8985 15.8985 19.0588 12 19.0588C10.8575 19.0588 9.7783 18.7874 8.82353 18.3054V23.5751C9.83527 23.8521 10.9004 24 12 24Z"
        fill="black"
      />
      <path
        d="M37.858 15.2116C41.8154 15.2116 44.4333 13.0807 44.4333 9.12329C44.4333 5.13546 41.8154 3.00457 37.858 3.00457H31V21.6956H34.8661V15.2116H37.858ZM40.5977 9.12329C40.5977 10.7367 39.6236 11.8935 37.6144 11.8935H34.8661V6.32268H37.6144C39.6236 6.32268 40.5977 7.44901 40.5977 9.12329Z"
        fill="black"
      />
      <path
        d="M50.1946 8.08828H46.4198V21.6956H50.1946V8.08828ZM50.5294 4.16134C50.5294 2.91324 49.4944 2 48.3072 2C47.0895 2 46.0545 2.91324 46.0545 4.16134C46.0545 5.43988 47.0895 6.32268 48.3072 6.32268C49.4944 6.32268 50.5294 5.43988 50.5294 4.16134Z"
        fill="black"
      />
      <path
        d="M52.1942 14.9072C52.1942 19.1081 54.873 22 58.7391 22C62.1485 22 64.3099 20.0213 64.7056 17.0989H60.9917C60.7787 18.1339 59.9567 18.7732 58.7391 18.7732C57.0039 18.7732 55.9385 17.1903 55.9385 14.9072C55.9385 12.5936 57.0039 11.0107 58.7391 11.0107C59.9567 11.0107 60.7787 11.6499 60.9917 12.6849H64.7056C64.3099 9.793 62.1485 7.78387 58.7391 7.78387C54.873 7.78387 52.1942 10.6758 52.1942 14.9072Z"
        fill="black"
      />
      <path
        d="M66.4958 17.586C66.4958 20.5997 68.231 21.9696 71.0012 21.9696C72.0057 21.9696 72.919 21.8174 73.4365 21.5434V18.1948C73.0103 18.4688 72.371 18.6514 71.7926 18.6514C70.8185 18.6514 70.2401 18.1644 70.2401 17.0989V11.1324H73.2234V8.08828H70.2401V4.40487H66.4958C66.4958 8.79858 66.4958 13.1923 66.4958 17.586Z"
        fill="black"
      />
      <path
        d="M83.5014 17.7496C83.1066 18.5342 82.302 18.9559 81.1452 18.9559C79.7283 18.9559 78.5639 18.0495 78.254 16.2154C78.254 16.2154 85.8852 16.1879 87.2595 16.1879C87.5744 11.013 85.5854 7.78387 81.0843 7.78387C77.2792 7.78387 74.5394 10.7976 74.5394 14.9072C74.5394 19.1081 77.1878 22 81.1452 22C84.5547 22 86.4949 20.1484 87.0796 17.7496H83.5014ZM81.1148 10.8584C82.6318 10.8584 83.6113 11.8676 83.6113 13.8216H78.234C78.4639 11.7876 79.6536 10.8584 81.1148 10.8584Z"
        fill="black"
      />
      <path
        d="M97.4935 8.0274C97.2804 7.90563 96.7021 7.78387 96.0628 7.78387C94.7234 7.78387 93.5753 8.5942 93.0186 9.7936V8.08828H89.2439V21.6956H93.0186V15.1809C93.0186 11.7926 94.4349 11.3455 95.6062 11.3455C96.3063 11.3455 96.976 11.4977 97.4935 11.8021V8.0274Z"
        fill="black"
      />
    </svg>
  );
}
