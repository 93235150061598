import React from 'react';
import getDisplayName from 'src/utils/get-display-name';
import { SidebarControlConsumer } from '../utils/sidebar-control';

const withSidebarControl = WrappedComponent => {
  const WithSidebarControl = props => (
    <SidebarControlConsumer>
      {sidebarControl => (
        <WrappedComponent {...props} sidebarControl={sidebarControl} />
      )}
    </SidebarControlConsumer>
  );

  WithSidebarControl.displayName = `WithSidebarControl(${getDisplayName(
    WrappedComponent,
  )})`;

  return WithSidebarControl;
};

export default withSidebarControl;
