import { useState } from 'react';

export default function usePromise<K extends unknown[], T>(
  fn: (...args: K) => Promise<T>,
) {
  const [data, setData] = useState<T>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState();

  const trigger = (...args: K) => {
    setLoading(true);

    return fn(...args)
      .then(result => {
        setData(result);
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    data,
    isLoading,
    error,
    trigger,
  };
}
