import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import messages from './messages';

export default function useSegmentationJobTitleOptions() {
  const intl = useIntl();

  return useMemo(
    () => [
      {
        label: intl.formatMessage(messages.labelAgent),
        value: 'Agent',
      },
      {
        label: intl.formatMessage(messages.labelArtDirector),
        value: 'Art Director',
      },
      {
        label: intl.formatMessage(messages.labelContentCreator),
        value: 'Content Creator',
      },
      {
        label: intl.formatMessage(messages.labelContentManager),
        value: 'Content Manager',
      },
      {
        label: intl.formatMessage(messages.labelCopywriter),
        value: 'Copywriter',
      },
      {
        label: intl.formatMessage(messages.labelCreativeDirector),
        value: 'Creative Director',
      },
      {
        label: intl.formatMessage(messages.labelCurator),
        value: 'Curator',
      },
      {
        label: intl.formatMessage(messages.labelDesigner),
        value: 'Designer',
      },
      {
        label: intl.formatMessage(messages.labelImageEditor),
        value: 'Image Editor',
      },
      {
        label: intl.formatMessage(messages.labelIntern),
        value: 'Intern',
      },
      {
        label: intl.formatMessage(messages.labelManagingDirector),
        value: 'Managing Director',
      },
      {
        label: intl.formatMessage(messages.labelMarketingManager),
        value: 'Marketing Manager',
      },
      {
        label: intl.formatMessage(messages.labelPhotographer),
        value: 'Photographer',
      },
      {
        label: intl.formatMessage(messages.labelProducer),
        value: 'Producer',
      },
      {
        label: intl.formatMessage(messages.labelProfessor),
        value: 'Professor',
      },
      {
        label: intl.formatMessage(messages.labelProjectManager),
        value: 'Project Manager',
      },
      {
        label: intl.formatMessage(messages.labelRetoucher),
        value: 'Retoucher',
      },
      {
        label: intl.formatMessage(messages.labelSocialMediaManager),
        value: 'Social Media Manager',
      },
      {
        label: intl.formatMessage(messages.labelStudent),
        value: 'Student',
      },
      {
        label: intl.formatMessage(messages.labelOther),
        value: 'Other',
      },
    ],
    [intl],
  );
}
