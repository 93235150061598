import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import paths from 'src/routes/paths';
import { librarySettingsSpaceUrl } from 'src/routes/urls';
import NotFoundPage from 'src/components/NotFoundPage';
import { useCurrentUser } from 'src/hooks/use-resource';
import { get } from 'src/utils/accessors';

import PersonalSettingsRoutes from './personal/_routes';
import SpaceSettingsRoutes from './{spaceId}/_routes';
import Layout from './_layout';

const {
  LIBRARY_SETTINGS_PATH,
  LIBRARY_SETTINGS_SPACE_PATH,
  LIBRARY_SETTINGS_PERSONAL_PATH,
} = paths;

function SettingsRoutes() {
  const user = useCurrentUser(
    {
      include: ['spaces'],
    },
    {
      request: userBeforeRequest =>
        !(
          userBeforeRequest &&
          get(userBeforeRequest, 'relationships.spaces.0.id')
        ),
    },
  );
  return (
    <Layout>
      <Switch>
        <Route
          path={LIBRARY_SETTINGS_PATH}
          exact
          render={() => {
            const firstSpaceId = get(user, 'relationships.spaces.0.id');

            return (
              <Redirect
                to={librarySettingsSpaceUrl({
                  spaceId: firstSpaceId,
                })}
              />
            );
          }}
        />
        <Route
          path={LIBRARY_SETTINGS_PERSONAL_PATH}
          component={PersonalSettingsRoutes}
        />
        <Route
          path={LIBRARY_SETTINGS_SPACE_PATH}
          component={SpaceSettingsRoutes}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Layout>
  );
}

export default SettingsRoutes;
