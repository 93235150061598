import { defineMessages } from 'react-intl';

export default defineMessages({
  labelAddImages: {
    id: 'EmptyImageGrid.labelAddImages',
    defaultMessage: 'Add files',
  },
  messageEmptyCollection: {
    id: 'EmptyImageGrid.messageEmptyCollection',
    defaultMessage:
      'Add files from the project to this collection or upload new files.',
  },
  messageEmptyFilter: {
    id: 'EmptyImageGrid.messageEmptyFilter',
    defaultMessage:
      'Try <a>resetting the filter</a> or searching another collection within the project.',
  },
  messageEmptyPresentTitle: {
    id: 'EmptyImageGrid.messageEmptyPresentTitle',
    defaultMessage: 'No files in this presentation',
  },
  messageEmptyPresentMessage: {
    id: 'EmptyImageGrid.messageEmptyPresentMessage',
    defaultMessage: 'Try asking the project owner for more information',
  },
  messageEmptyReviewTitle: {
    id: 'EmptyImageGrid.messageEmptyReviewTitle',
    defaultMessage: 'No files for review',
  },
  messageEmptyReviewMessage: {
    id: 'EmptyImageGrid.messageEmptyReviewMessage',
    defaultMessage: 'Try asking the project owner for more information',
  },
  messageEmptyRequestFilesTitle: {
    id: 'EmptyImageGrid.messageEmptyRequestFilesTitle',
    defaultMessage: 'Upload your files',
  },
  messageEmptyRequestFilesMessage: {
    id: 'EmptyImageGrid.messageEmptyRequestFilesMessage',
    defaultMessage: 'Uploaded files will be delivered to {owner}',
  },
  titleEmptyFilter: {
    id: 'EmptyImageGrid.titleEmptyFilter',
    defaultMessage: `The applied filters don't match any files`,
  },
  titleEmptyCollection: {
    id: 'EmptyImageGrid.titleEmptyCollection',
    defaultMessage: 'No files in this collection',
  },
  titleEmptyProject: {
    id: 'EmptyImageGrid.titleEmptyProject',
    defaultMessage: `This project's looking a little bare`,
  },
  messageEmptyProject: {
    id: 'EmptyImageGrid.messageEmptyProject',
    defaultMessage:
      'Why not add some images, videos or documents to liven things up?',
  },
});
