import React, { useEffect } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Card } from '@picter/prisma';
import { Box, Flex } from '@rebass/grid';
import { Link } from 'react-router-dom';
import { FolderOpen as FolderOpenSvg } from '@styled-icons/material';
import { ellipsis } from 'polished';

import { Body, ContextualTextField, Icon } from 'src/modules/prisma';
import MessageTooltip from 'src/components/MessageTooltip';
import { librarySpaceFolderUrl } from 'src/routes/urls';
import { formattedMessage } from 'src/utils/app-prop-types';
import { get } from 'src/utils/accessors';

import FolderCardActionsDropdown from './components/FolderCardActionsDropdown';
import useHandlers from './hooks/use-handlers';

const FolderCardGrid = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: ${props => props.theme.space[4]}px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  position: relative;
  width: 100%;
  padding-bottom: ${props => props.theme.space[4]}px;

  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;

function FolderCardWrapper({
  name,
  icon,
  count,
  clickable,
  clickableProps,
  extra,
}) {
  return (
    <Card>
      <Card.Content px={0}>
        <Flex alignItems="center" justifyContent="space-between">
          <Box m={4} as={clickable} {...clickableProps}>
            <Icon
              type={icon}
              size="medium"
              boxSize="xxlarge"
              color="grey.600"
              borderStyle="solid"
              borderWidth="1px"
              borderColor="grey.200"
            />
          </Box>
          <Box
            as={clickable}
            // this calc here is needed because the ellipsis needs a parent
            // with the width set, and if it's set to 100% here it ignores
            // the icon and the context menu element sizes
            // 128px = 32px of icon size + 16px for each side margin
            // for both the icon and the context menu
            width="calc(100% - 128px)"
            style={{ display: 'block' }}
            {...clickableProps}
          >
            {name}
            {count && (
              <Body textSize="small" color="grey.600" fontWeight="light">
                {count}
              </Body>
            )}
          </Box>
          <Box m={4}>{extra}</Box>
        </Flex>
      </Card.Content>
    </Card>
  );
}

FolderCardWrapper.propTypes = {
  name: PropTypes.element.isRequired,
  icon: PropTypes.element,
  count: formattedMessage,
  clickable: PropTypes.elementType,
  clickableProps: PropTypes.shape({}),
  extra: PropTypes.node,
};

FolderCardWrapper.defaultProps = {
  icon: <FolderOpenSvg />,
  count: undefined,
  clickable: undefined,
  clickableProps: {},
  extra: undefined,
};

const TruncatedBody = styled(Body)`
  ${ellipsis()}
`;

function FolderCard({ folder, isEditing, render }) {
  const {
    name,
    count,
    setName,
    namePlaceholder,
    handleRenameFolder,
    handleRemoveFolder,
    handleRenameClick,
    isEditingName,
    setIsEditingName,
  } = useHandlers({ folder });

  useEffect(() => {
    if (isEditing) {
      setIsEditingName(true);
    }
  }, [isEditing, setIsEditingName]);

  const folderUrl = librarySpaceFolderUrl({
    folderId: get(folder, 'id'),
    spaceId: get(folder, 'relationships.space.data.id'),
  });

  if (render) {
    return render({
      name,
      count,
      setName,
      namePlaceholder,
      handleRenameFolder,
      handleRemoveFolder,
      handleRenameClick,
      isEditingName,
      setIsEditingName,
      folderUrl,
    });
  }

  return (
    <FolderCardWrapper
      name={
        isEditingName ? (
          <ContextualTextField
            maxLength="100"
            minWidth="100%"
            onBlur={handleRenameFolder}
            onChange={e => setName(e.target.value)}
            placeholder={namePlaceholder}
            textStyle="body.regular"
            value={name || undefined}
            autoFocus
          />
        ) : (
          <MessageTooltip message={name || namePlaceholder} showDelay={500}>
            <TruncatedBody>{name || namePlaceholder}</TruncatedBody>
          </MessageTooltip>
        )
      }
      count={count}
      clickable={Link}
      clickableProps={{
        to: folderUrl,
      }}
      extra={
        <FolderCardActionsDropdown
          onClickRename={handleRenameClick}
          onClickRemove={handleRemoveFolder}
        />
      }
    />
  );
}

FolderCard.propTypes = {
  folder: PropTypes.instanceOf(Map).isRequired,
  isEditing: PropTypes.bool,
  render: PropTypes.func,
};

FolderCard.defaultProps = {
  isEditing: false,
  render: undefined,
};

FolderCard.Grid = FolderCardGrid;
FolderCard.Wrapper = FolderCardWrapper;
FolderCard.ActionsDropdown = FolderCardActionsDropdown;

export default FolderCard;
