import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { useParams } from 'react-router-dom';

import { get } from 'src/utils/accessors';
import { useAnnotations } from 'src/components/Annotations';
import { useCommentUrlBuilder } from 'src/hooks/use-url-builder';
import useNavigate from 'src/hooks/use-navigate';
import { useSubscribeToLocationState } from 'src/hooks/use-subscribe-to-location-state';

import CommentRenderer from './CommentRenderer';
import ReverseDynamicSizeList from './ReverseDynamicSizeList';

const DEFAULT_ACTIVITY_SIZE = 97;

function CommentsRenderer({ comments: commentsList }) {
  const listRef = useRef();
  const { commentId } = useParams();
  const [{ deselect, select }, { selectedId }] = useAnnotations();
  const getDefaultSize = useCallback(() => DEFAULT_ACTIVITY_SIZE, []);
  const comments = useMemo(() => commentsList.toJS(), [commentsList]);
  const navigate = useNavigate();
  const commentUrl = useCommentUrlBuilder();

  /**
   * Used to select markers when going to or back from a comment thread
   * or redirect from the project page.
   */
  useSubscribeToLocationState(
    selectedMarkerId => {
      if (selectedMarkerId === 'deselect') deselect();
      else if (selectedMarkerId) select(selectedMarkerId);
    },
    ['selectedMarkerId'],
  );

  /**
   * used to scroll to the correct comment in the sidebar when
   * the user selects a marker
   */
  const commentsIndexById = useMemo(
    () =>
      comments
        ? comments.reduce((acc, comment, index) => {
            const id = get(comment, 'id');
            acc[id] = index;
            return acc;
          }, {})
        : {},
    [comments],
  );

  useEffect(() => {
    const { current: list } = listRef;
    const commentIndex = commentsIndexById[selectedId];

    if (list && typeof selectedId === 'string') {
      if (typeof commentIndex !== 'undefined') {
        list.scrollToItem(commentIndex);
      } else {
        // commentIndex not existent means it's not rendered
        navigate(commentUrl({ commentId: selectedId }), {
          state: { selectedMarkerId: selectedId },
        });
      }
    }

    // Missing dependencies: [`commentsIndexById`]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  const scrollToIndex = commentsIndexById[commentId];

  return (
    <AutoSizer>
      {({ height, width }) => (
        <ReverseDynamicSizeList
          ref={listRef}
          height={height}
          width={width}
          itemCount={comments.length}
          itemData={comments}
          getDefaultSize={getDefaultSize}
          scrollToIndex={scrollToIndex}
        >
          {CommentRenderer}
        </ReverseDynamicSizeList>
      )}
    </AutoSizer>
  );
}

CommentsRenderer.propTypes = {
  comments: PropTypes.instanceOf(List).isRequired,
};

export default CommentsRenderer;
