import styled from 'styled-components/macro';
import { bodyTextStyle } from '@picter/prisma';

const BoxText = styled.span.attrs({
  color: 'inherit',
})`
  ${bodyTextStyle};
`;

export default BoxText;
