import React from 'react';
import { Body, Heading } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';
import ToggleButtonSvg from 'src/assets/ToggleButtonSvg';

import BlockDashboardLayout from './BlockDashboardLayout';
import messages from '../_messages';

export default function SubscriptionExpiredPage() {
  return (
    <BlockDashboardLayout
      content={
        <>
          <ToggleButtonSvg />
          <Heading color="danger" m={0} mt={7}>
            <FormattedMessage {...messages.titleSubscriptionExpired} />
          </Heading>
          <Body color="danger" mt={2}>
            <FormattedMessage {...messages.messageSubscriptionExpired} />
          </Body>
        </>
      }
    />
  );
}
