import { defineMessages } from 'react-intl';

export default defineMessages({
  labelInvite: {
    id: 'ShareDropdown.labelInvite',
    defaultMessage: 'Invite guests',
  },
  labelReview: {
    id: 'ShareDropdown.labelReview',
    defaultMessage: 'Review link',
  },
  labelPresentation: {
    id: 'ShareDropdown.labelPresentation',
    defaultMessage: 'Presentation link',
  },
  labelRequestFiles: {
    id: 'ShareDropdown.labelRequestFiles',
    defaultMessage: 'Inbox link',
  },

  labelShare: {
    id: 'ProjectShare.labelShareButton',
    defaultMessage: 'Share',
  },
  titleShareableLinkModal: {
    id: 'ShareableLinkModal.titleShareableLinkModal',
    defaultMessage: 'Shareable link',
  },
  titleShareableReviewLinkModal: {
    id: 'ShareableLinkModal.titleShareableReviewLinkModal',
    defaultMessage: 'Share review link',
  },
  titleShareablePresentLinkModal: {
    id: 'ShareableLinkModal.titleShareablePresentLinkModal',
    defaultMessage: 'Share presentation link',
  },
  titleShareableRequestFilesLinkModal: {
    id: 'ShareableLinkModal.titleShareableRequestFilesLinkModal',
    defaultMessage: 'Share inbox link',
  },
  titleLink: {
    id: 'ShareableLinkModal.titleLink',
    defaultMessage: 'Link',
  },
  titleSettings: {
    id: 'ShareableLinkModal.titleSettings',
    defaultMessage: 'Settings',
  },
  labelCommentsSetting: {
    id: 'ShareableLinkModal.labelCommentsSetting',
    defaultMessage: 'Show comments',
  },
  labelDownloadSetting: {
    id: 'ShareableLinkModal.labelDownloadSetting',
    defaultMessage: 'Enable download',
  },
  labelLearnMore: {
    id: 'ShareableLinkModal.labelLearnMore',
    defaultMessage: 'Learn more',
  },
  labelSeeExample: {
    id: 'ShareableLinkModal.labelSeeExample',
    defaultMessage: 'See example',
  },
  labelDisable: {
    id: 'ShareableLinkBox.labelDisable',
    defaultMessage: 'Disable',
  },
  labelGetShareableLink: {
    id: 'ShareableLinkBox.labelGetShareableLink',
    defaultMessage: 'Get shareable link',
  },
  labelGetShareableReviewLink: {
    id: 'ShareableLinkBox.labelGetShareableReviewLink',
    defaultMessage: 'Get review link',
  },
  labelGetShareableRequestFilesLink: {
    id: 'ShareableLinkBox.labelGetShareableRequestFilesLink',
    defaultMessage: 'Get inbox link',
  },
  labelGetShareablePresentLink: {
    id: 'ShareableLinkBox.labelGetShareablePresentLink',
    defaultMessage: 'Get presentation link',
  },
  messageShareableLinkAction: {
    id: 'ProjectPage.messageProjectShareUrlEnabled',
    defaultMessage: 'Accessible by anyone with this link',
  },
  messageShareUrlCopySuccess: {
    id: 'ShareableLinkBox.messageShareUrlCopySuccess',
    defaultMessage: 'Link copied to clipboard',
  },

  messageReviewLinkDescription: {
    id: 'ShareableReviewLinkModal.messageReviewLinkDescription',
    defaultMessage:
      '<b>Review links</b> are a quick and easy way to get feedback on a project. Reviewers can approve and reject files with a single click (or tap on mobile).',
  },
  messagePresentLinkDescription: {
    id: 'ShareableReviewLinkModal.messagePresentLinkDescription',
    defaultMessage:
      '<b>Presentation links</b> display just files.</br>So fresh. So clean.',
  },
  messageRequestFilesLinkDescription: {
    id: 'ShareableReviewLinkModal.messageRequestFilesLinkDescription',
    defaultMessage:
      '<b>Inbox links</b> create a space for your collaborators to upload files with a simple drag and drop.',
  },
  messagecommentsEnabled: {
    id: 'ShareableReviewLinkModal.messagecommentsEnabled',
    defaultMessage: `{enabled, select,
        true {Enabled comments.}
        false {Disabled comments.}
      }`,
  },
  messagedownloadEnabled: {
    id: 'ShareableReviewLinkModal.messagedownloadEnabled',
    defaultMessage: `{enabled, select,
        true {Enabled download.}
        false {Disabled download.}
      }`,
  },
  messageaccessToAllFiles: {
    id: 'ShareableReviewLinkModal.messageaccessToAllFiles',
    defaultMessage: `{enabled, select,
        true {Enabled display all files.}
        false {Disabled display all files.}
      }`,
  },
  labelContentSetting: {
    id: 'ShareableLinkModal.labelContentSetting',
    defaultMessage: 'Content to be displayed',
  },
  labelAllFiles: {
    id: 'ShareableLinkModal.labelAllFiles',
    defaultMessage: 'All files',
  },
  labelNumberOfCollections: {
    id: 'ShareableLinkModal.labelNumberOfCollections',
    defaultMessage: '{count, plural, one {# collection} other {# collections}}',
  },
});
