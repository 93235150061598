import { defineMessages } from 'react-intl';

export default defineMessages({
  titleLiteUserRegisterModal: {
    id: 'LiteUserRegisterModal.titleLiteUserRegisterModal',
    defaultMessage: 'Want to interact?',
  },
  messageLiteUserRegisterModal: {
    id: 'LiteUserRegisterModal.messageLiteUserRegisterModal',
    defaultMessage:
      'Please leave your information so that {owner} will know who you are. You only need to do this once.',
  },

  labelLiteUserRegisterModalConfirm: {
    id: 'LiteUserRegisterForm.labelLiteUserRegisterModalConfirm',
    defaultMessage: 'Continue',
  },
  labelLiteUserRegisterModalLogin: {
    id: 'LiteUserRegisterForm.labelLiteUserRegisterModalLogin',
    defaultMessage: 'Use Picter? Log In',
  },
  placeholderLiteUserRegisterModalName: {
    id: 'LiteUserRegisterForm.placeholderLiteUserRegisterModalName',
    defaultMessage: 'Your name (optional)',
  },
  placeholderLiteUserRegisterModalEmail: {
    id: 'LiteUserRegisterForm.placeholderLiteUserRegisterModalEmail',
    defaultMessage: 'Your email',
  },
  messageLiteUserEmailError: {
    id: 'LiteUserRegisterForm.messageLiteUserEmailError',
    defaultMessage: 'Please enter a valid email address',
  },
});
