import { useCallback, useState, useMemo } from 'react';
import { toastError, toastInfo } from '@picter/prisma';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { uploadProjectImages } from 'src/actions/project-image';
import {
  libraryProjectUrl,
  librarySpaceUrl,
  librarySpaceFolderUrl,
  homeUrl,
} from 'src/routes/urls';
import { UPLOAD_TYPE } from 'src/components/UploadArea';
import { viewModeTypes } from 'src/routes/constants';
import { useErrorHandler } from 'src/components/ErrorHandler';
import { useToriiActions } from 'src/modules/torii';
import useAcceptedFileTypes from 'src/hooks/use-accepted-file-types';
import useFilteredUrls from 'src/hooks/use-filtered-urls';
import { get } from 'src/utils/accessors';

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  messageProjectTitleChanged: {
    id: 'ProjectRoute.messageProjectTitleChanged',
    defaultMessage: 'Project title changed successfully.',
  },
  messageProjectDuplicateSuccess: {
    id: 'ProjectPage.labelDuplicateProjectSuccess',
    defaultMessage: 'Project duplicated successfully.',
  },
  messageProjectRestoreSuccess: {
    id: 'ProjectPage.labelRestoreProjectSuccess',
    defaultMessage: 'Project restored successfully.',
  },
  messageProjectMovedToTrash: {
    id: 'ProjectPage.messageProjectMovedToTrash',
    defaultMessage: 'Project moved to trash.',
  },
  messageProjectDeletedSuccess: {
    id: 'ProjectPage.messageProjectDeletedSuccess',
    defaultMessage: 'Project deleted successfully.',
  },
});

export function useRedirectUrl({ viewMode, spaceId, folderId }) {
  return useMemo(() => {
    const isDefaultMode = viewMode === viewModeTypes.DEFAULT;
    let backUrl = homeUrl();

    if (isDefaultMode) {
      if (spaceId) {
        backUrl = librarySpaceUrl({ spaceId });
      }

      if (folderId) {
        backUrl = librarySpaceFolderUrl({ spaceId, folderId });
      }
    }

    return backUrl;
  }, [viewMode, spaceId, folderId]);
}

export function useEditorQuotaErrorModal() {
  const [
    editorQuotaErrorModalVisibility,
    setEditorQuotaErrorModalVisibility,
  ] = useState(false);

  const closeEditorQuotaErrorModal = useCallback(
    () => setEditorQuotaErrorModalVisibility(false),
    [setEditorQuotaErrorModalVisibility],
  );

  const openEditorQuotaErrorModal = useCallback(
    () => setEditorQuotaErrorModalVisibility(true),
    [setEditorQuotaErrorModalVisibility],
  );

  return {
    editorQuotaErrorModalVisibility,
    setEditorQuotaErrorModalVisibility,
    closeEditorQuotaErrorModal,
    openEditorQuotaErrorModal,
  };
}

export function useOnChangeProjectTitle({
  formatMessage,
  project,
  tracker,
  update,
}) {
  return useCallback(
    async event => {
      const { value: newTitle } = event.target;
      const oldTitle = get(project, 'attributes.title');
      if (newTitle !== oldTitle) {
        await update('wsProjects', {
          id: get(project, 'id'),
          attributes: {
            title: newTitle,
          },
          querySerializers: {
            publicKey: () =>
              `publicKey=${get(project, 'attributes.publicUrlKey')}`,
          },
        });
        tracker.track('Changed Project title');
        toastInfo(formatMessage(messages.messageProjectTitleChanged));
      }
    },
    [formatMessage, project, tracker, update],
  );
}

export function useOnClickDeleteProject({
  destroy,
  projectId,
  redirectUrl,
  formatMessage,
}) {
  const history = useHistory();

  return useCallback(async () => {
    await destroy('wsProjects', {
      id: projectId,
    });

    history.push(redirectUrl);
    toastInfo(formatMessage(messages.messageProjectDeletedSuccess));
  }, [destroy, history, projectId, redirectUrl, formatMessage]);
}

export function useOnClickInfoButton() {
  const { params } = useRouteMatch();
  const { query } = useLocation();
  const history = useHistory();
  const { collectionId, projectId: publicLinkKey } = params;
  const { filter } = query;
  const { filteredProjectInfoUrl } = useFilteredUrls(filter);

  return useCallback(() => {
    history.push(
      filteredProjectInfoUrl(
        {
          projectId: publicLinkKey,
          collectionId,
        },
        query,
      ),
    );
  }, [history, filteredProjectInfoUrl, publicLinkKey, collectionId, query]);
}

export function useRenameProject({ titleElementRef }) {
  return useCallback(() => {
    const { current: titleElement } = titleElementRef;
    if (titleElement) {
      titleElement.focus();
    }
  }, [titleElementRef]);
}

export function useOnFileUpload({
  tracker,
  projectId,
  collection,
  projectPublicKey,
  setConfirmReplaceImages,
}) {
  const { handleError } = useErrorHandler();
  const match = useRouteMatch();
  const location = useLocation();
  const actions = useToriiActions();
  const acceptedFileTypes = useAcceptedFileTypes();

  return useCallback(
    (files, { uploadType }) => {
      // track upload event before sharing
      const eventName =
        UPLOAD_TYPE.FILE_CHOOSER === uploadType
          ? 'Cicked Add images to upload'
          : 'Dragged Images to upload';

      tracker.track(eventName, {
        numberOfFiles: files.length,
      });

      return uploadProjectImages(
        {
          projectId,
          projectPublicKey,
        },
        files,
        {
          onError: handleError,
          onExistingFileName: confirm => setConfirmReplaceImages(() => confirm),
        },
        {
          match,
          location,
          acceptedFileTypes,
          collection,
          ...actions,
        },
      );
    },
    [
      tracker,
      projectId,
      projectPublicKey,
      handleError,
      match,
      location,
      acceptedFileTypes,
      collection,
      actions,
      setConfirmReplaceImages,
    ],
  );
}

export function useOnClickComments() {
  const { params } = useRouteMatch();
  const { query } = useLocation();
  const history = useHistory();
  const { collectionId, projectId: publicLinkKey } = params;
  const { filter } = query;
  const { filteredProjectCommentsUrl } = useFilteredUrls(filter);
  return useCallback(() => {
    history.push(
      filteredProjectCommentsUrl(
        {
          projectId: publicLinkKey,
          collectionId,
        },
        query,
      ),
    );
  }, [history, publicLinkKey, collectionId, query, filteredProjectCommentsUrl]);
}

export function useRenameCollection(editNameField) {
  return useCallback(() => editNameField(), [editNameField]);
}

export function useDeleteCollection({ destroy, collectionId, project }) {
  const history = useHistory();

  return useCallback(async () => {
    await destroy('wsCollections', {
      id: collectionId,
      querySerializers: {
        publicKey: () => `publicKey=${get(project, 'attributes.publicUrlKey')}`,
      },
    });
    history.push(
      libraryProjectUrl({
        projectId: get(project, 'attributes.publicUrlKey'),
      }),
    );
  }, [destroy, collectionId, history, project]);
}

export function useCopyProject({
  create,
  handleError,
  project,
  formatMessage,
  spaceId,
  folderId,
}) {
  const history = useHistory();

  return useCallback(async () => {
    try {
      const relationships = {};

      if (spaceId) {
        relationships.space = {
          id: spaceId,
          type: 'ws-spaces',
        };
      }

      if (folderId) {
        relationships.folder = {
          id: folderId,
          type: 'ws-folders',
        };
      }

      const { data } = await create('wsProjects', {
        attributes: {
          copyFromProject: get(project, 'id'),
        },
        relationships,
      });

      history.push(
        libraryProjectUrl({
          projectId: data.attributes['public-url-key'],
        }),
      );

      toastInfo(formatMessage(messages.messageProjectDuplicateSuccess));
    } catch (e) {
      const handled = handleError(e);
      if (handled) return;

      toastError(e);
    }
  }, [create, handleError, project, history, formatMessage, spaceId, folderId]);
}

export function useCloseShareProjectModal() {
  const { params } = useRouteMatch();
  const { query } = useLocation();
  const history = useHistory();
  const { collectionId, projectId: publicLinkKey } = params;
  const { filter } = query;
  const { filteredProjectUrl } = useFilteredUrls(filter);
  return useCallback(() => {
    return history.push(
      filteredProjectUrl({
        collectionId,
        projectId: publicLinkKey,
      }),
    );
  }, [history, filteredProjectUrl, collectionId, publicLinkKey]);
}

export function useOpenShareProjectModal() {
  const { params } = useRouteMatch();
  const { query } = useLocation();
  const history = useHistory();
  const { collectionId, projectId: publicLinkKey } = params;
  const { filter } = query;
  const { filteredProjectShareUrl } = useFilteredUrls(filter);
  return useCallback(
    () =>
      history.push(
        filteredProjectShareUrl({
          collectionId,
          projectId: publicLinkKey,
        }),
      ),
    [history, filteredProjectShareUrl, collectionId, publicLinkKey],
  );
}

export function useRestoreProject({ project, update, formatMessage }) {
  return useCallback(async () => {
    await update('wsProjects', {
      id: get(project, 'id'),
      attributes: {
        deletedAt: null,
      },
    });

    toastInfo(formatMessage(messages.messageProjectRestoreSuccess));
  }, [project, update, formatMessage]);
}

export function useMoveProjectToTrash({
  project,
  update,
  formatMessage,
  redirectUrl,
}) {
  const history = useHistory();
  return useCallback(async () => {
    await update('wsProjects', {
      id: get(project, 'id'),
      attributes: {
        deletedAt: new Date().valueOf(),
      },
    });

    history.push(redirectUrl);
    toastInfo(formatMessage(messages.messageProjectMovedToTrash));
  }, [update, project, formatMessage, history, redirectUrl]);
}
