import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';
import { variant } from 'styled-system';

const ProofStatusFlag = styled.div`
  ${variant({
    variants: {
      default: {
        backgroundColor: 'transparent',
        opacity: 0,
      },
      approved: {
        backgroundColor: 'success',
      },
      rejected: {
        backgroundColor: 'danger',
      },
    },
  })}
  border-radius: 50%;
  border: 1px solid white;
  bottom: ${themeGet('space.2')}px;
  height: ${themeGet('space.2')}px;
  margin: 0;
  padding: 0;
  pointer-events: none;
  position: absolute;
  right: ${themeGet('space.2')}px;
  width: ${themeGet('space.2')}px;
`;

ProofStatusFlag.defaultProps = {
  variant: 'default',
};

export default ProofStatusFlag;
