import styled, { css } from 'styled-components/macro';
import { Card } from '@picter/prisma';
import { transparentize } from 'polished';
import ImageCardExtraContainer from './ImageCardExtraContainer';
import ImageCardImage from './ImageCardImage';
import ImageCardImagePreview from './ImageCardImagePreview';

const boxShadow = props => {
  if (!props.isOver) return 'none';
  if (props.hoverSide === 'right') {
    return `4px 0px 0px 0px ${transparentize(0.6, props.theme.colors.primary)}`;
  }
  return `-4px 0px 0px 0px ${transparentize(0.6, props.theme.colors.primary)}`;
};

const rejectedStyles = ({ rejected }) =>
  rejected &&
  css`
    background-color: rgba(255, 255, 255, 0.3);
    ${ImageCardImage}, ${ImageCardImagePreview} {
      opacity: 0.5;
    }

    ${ImageCardExtraContainer} .image-card-editable-title {
      opacity: 0.5;
    }
  `;

const ImageCardCard = styled(Card)`
  box-shadow: ${boxShadow};
  ${props => props.canDrag && 'cursor: grab'};
  ${props => props.mute && 'opacity: 0.4;'};
  ${props => props.hide && 'display: none;'};
  ${props => props.isDragging && 'opacity: 0'};
  ${rejectedStyles};
  ${props =>
    props.canDrag &&
    `&:active {
    cursor: grabbing;
  }`}
`;

export default ImageCardCard;
