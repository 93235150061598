import { API_LOCAL_UPDATE_RELATIONSHIP } from '../../constants';

const localUpdateRelationship = (model, { id, relation, data } = {}) => {
  if (!id) {
    throw new Error(
      'You have to define a resource id to attach the relationship to.',
    );
  }

  if (!relation) {
    throw new Error('Relation is required to attach a relationship.');
  }

  if (data === undefined) {
    throw new Error('The parameter data cannot be undefined.');
  }

  return dispatch =>
    dispatch({
      type: API_LOCAL_UPDATE_RELATIONSHIP,
      payload: { data },
      meta: {
        model,
        relation,
        id,
        data,
      },
    });
};

export default localUpdateRelationship;
