import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useDisclosureState } from 'reakit/Disclosure';
import { CustomPropTypes } from '@picter/prisma';

import { AccordionItemProvider } from '../providers';

const AccordionItem = forwardRef(({ children, ...props }, ref) => {
  const state = useDisclosureState(props);

  useImperativeHandle(ref, () => state);

  return (
    <AccordionItemProvider value={state}>{children}</AccordionItemProvider>
  );
});

AccordionItem.propTypes = {
  children: CustomPropTypes.oneOrMoreOfType([PropTypes.element]).isRequired,
};

export default AccordionItem;
