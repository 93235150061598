import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';

import HintMessage from 'src/modules/prisma/components/HintMessage/styles/HintText';

const SelectFieldContainer = styled.div`
  display: inline-block;
  margin: 0 0 ${themeGet('space.4')}px;
  position: relative;
  text-align: left;
  width: ${props => (props.width ? props.width : '100%')};

  ${HintMessage /* sc-sel */} {
    margin-top: ${themeGet('space.1')}px;
    margin-left: 4px;
  }
`;

export default SelectFieldContainer;
