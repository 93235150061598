import React from 'react';
import PropTypes from 'prop-types';
import { KeyboardArrowDown as KeyboardArrowDownSvg } from '@styled-icons/material';

import { Box, Body, Icon } from 'src/modules/prisma';
import { formattedMessage } from 'src/utils/app-prop-types';

function CommentsFilterChooserToggle({ active, children, onClick }) {
  return (
    <Box alignItems="center" display="flex" onClick={onClick}>
      <Body fontWeight="regular" style={{ cursor: 'pointer' }} textSize="large">
        {children}
      </Body>
      <Box display="inline-block" ml={1} />
      <Icon
        active={active || undefined}
        type={<KeyboardArrowDownSvg />}
        backgroundColor="white"
        color="black"
        size="medium"
        interactive
      />
    </Box>
  );
}

CommentsFilterChooserToggle.propTypes = {
  active: PropTypes.bool,
  children: formattedMessage.isRequired,
  onClick: PropTypes.func,
};
CommentsFilterChooserToggle.defaultProps = {
  active: undefined,
  onClick: undefined,
};

export default CommentsFilterChooserToggle;
