import React, { createElement, useCallback } from 'react';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import isFunction from 'lodash/isFunction';
import { $TSFixMe } from 'src/types';

export type RestrictedRouteProps = RouteProps & {
  fallbackComponent?:
    | React.ComponentType<RouteComponentProps<$TSFixMe>>
    | React.ComponentType<$TSFixMe>;
  fallbackRender?: (props: RouteComponentProps<$TSFixMe>) => React.ReactNode;
  rule: (props: RouteComponentProps<$TSFixMe>) => boolean | boolean;
};

export default function RestrictedRoute({
  component,
  fallbackComponent,
  fallbackRender,
  render,
  rule,
  ...props
}: RestrictedRouteProps) {
  const routeRender = useCallback(
    routeProps => {
      const isAuthorized = isFunction(rule) ? rule(routeProps) : rule;
      let element = null;

      if (!isAuthorized) {
        if (fallbackComponent) {
          element = createElement(fallbackComponent, routeProps);
        }

        if (fallbackRender) {
          element = fallbackRender(routeProps);
        }

        return element;
      }

      if (component) {
        // @ts-ignore
        element = createElement(component, routeProps);
      }

      if (render) {
        element = render(routeProps);
      }

      return element;
    },
    [component, fallbackComponent, fallbackRender, render, rule],
  );

  return <Route {...props} render={routeRender} />;
}
