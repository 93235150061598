import React, { createElement } from 'react';
import { ArrowForward as ArrowForwardSvg } from '@styled-icons/material';

import { Box, Button, Icon, Heading, Panel } from 'src/modules/prisma';

export function CardAction({
  children,
  disabled = false,
  onClick,
}: {
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <Button
      alignItems="center"
      backgroundColor="transparent"
      color="primary"
      disabled={disabled}
      fontWeight="regular"
      onClick={onClick}
      px={0}
      py={0}
    >
      {children}
      <Box display="inline" ml={1}>
        <Icon color="primary" type={<ArrowForwardSvg />} />
      </Box>
    </Button>
  );
}

export default function Card({
  action,
  color,
  content,
  iconColor,
  iconType,
  title,
}: {
  action?: React.ReactNode;
  color: string;
  content: React.ReactNode;
  iconColor: string;
  iconType: React.FunctionComponent;
  title: React.ReactNode;
}) {
  return (
    <Panel
      borderRadius="medium"
      display="flex"
      flexDirection="column"
      style={{ overflow: 'hidden' }}
      tag="article"
    >
      <Box
        backgroundColor={color}
        display="grid"
        alignContent="center"
        justifyContent="center"
        flex="0 0 100px"
      >
        <Icon color={iconColor} type={createElement(iconType)} size="xxlarge" />
      </Box>
      <Box
        backgroundColor="white"
        display="flex"
        flex="1 0"
        flexDirection="column"
        p={5}
      >
        <Heading tag="h2" textStyle="regular" fontWeight="strong">
          {title}
        </Heading>
        <Box mt={4}>{content}</Box>
        {action && (
          <Box mt="auto" pt={5}>
            {action}
          </Box>
        )}
      </Box>
    </Panel>
  );
}
