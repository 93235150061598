import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedRelativeTime, FormattedMessage } from 'react-intl';

import { Box } from 'src/modules/prisma';
import { get } from 'src/utils/accessors';
import globalMessages from 'src/messages';
import { ChooserItem, chooserPropsShape } from 'src/components/EntityChooser';
import useFormatMessage from 'src/hooks/use-format-message';
import {
  getProjectImageFileInfo,
  getProjectFileThumbnailUrl,
} from 'src/utils/accessors/project-image';
import { getDateDeltaFromNowInSeconds } from 'src/utils/date';
import ProjectCardCoverIllustrationSvg from 'src/assets/ProjectCardCoverIllustrationSvg';

import messages from '../messages';

export default function ItemComponent({ item, chooserProps }) {
  const projectId = get(item, 'id');

  const projectTitle = get(item, 'attributes.title') || (
    <FormattedMessage {...globalMessages.labelUntitledProject} />
  );

  const cover = get(item, 'relationships.coverImage');
  const count = get(item, 'attributes.imageCount');
  const updatedAt = get(item, 'attributes.updatedAt');
  const deltaUpdatedAt = useMemo(
    () => getDateDeltaFromNowInSeconds(updatedAt),
    [updatedAt],
  );

  const formatMessage = useFormatMessage();

  const untitledProjectTitle = formatMessage(
    globalMessages.labelUntitledProject,
  );

  return (
    <ChooserItem
      id={projectId}
      cover={
        <Box
          display="flex"
          style={{
            height: 48,
            width: 48,
          }}
          alignItems="center"
          justifyContent="center"
          m={2}
        >
          {!cover || cover.isEmpty() ? (
            <Box
              backgroundColor="grey.300"
              display="flex"
              alignItems="center"
              justifyContent="center"
              width={1}
              p={2}
              style={{ height: '100%' }}
            >
              <ProjectCardCoverIllustrationSvg />
            </Box>
          ) : (
            <img
              style={{
                maxHeight: '100%',
                maxWidth: '100%',
              }}
              alt={`${projectTitle || untitledProjectTitle}'s cover`}
              src={
                getProjectImageFileInfo(cover, 'medium') ||
                getProjectFileThumbnailUrl(cover)
              }
            />
          )}
        </Box>
      }
      name={projectTitle}
      subheader={
        <>
          <FormattedMessage {...messages.labelFilesCount} values={{ count }} />
          {' • '}
          <FormattedRelativeTime
            numeric="auto"
            unit="second"
            updateIntervalInSeconds={10}
            value={deltaUpdatedAt}
          />
        </>
      }
      {...chooserProps}
    />
  );
}

ItemComponent.propTypes = {
  icon: PropTypes.element.isRequired,
  item: PropTypes.instanceOf(Map).isRequired,
  chooserProps: chooserPropsShape.isRequired,
};
