import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import EmptyStateBox from 'src/components/EmptyStateBox';
import EmptyPresentIllustrationSvg from 'src/assets/EmptyPresentIllustrationSvg';

import messages from '../messages';

const EmptyPresentBox = () => (
  <EmptyStateBox
    graphic={<EmptyPresentIllustrationSvg />}
    title={<FormattedHTMLMessage {...messages.messageEmptyPresentTitle} />}
    message={<FormattedHTMLMessage {...messages.messageEmptyPresentMessage} />}
    filled
  />
);

export default EmptyPresentBox;
