import styled from 'styled-components/macro';

import { grid } from './mixins';
import Box from './Box';

const Grid = styled(Box)`
  ${grid};
`;

Grid.defaultProps = {
  blacklist: [...Box.defaultProps.blacklist, ...grid.propNames],
};

Grid.displayName = 'Grid';

export default Grid;
