import React, { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const cache = {};

const Img = forwardRef(({ loader, src, alt, onLoad, ...imgProps }, ref) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (cache[src]) {
      return;
    }

    setIsLoading(true);

    const img = new Image();
    const handleLoad = () => {
      cache[src] = true;
      return setIsLoading(false);
    };

    img.src = src;

    if (img.decode) {
      img.decode().then(handleLoad);
    } else {
      img.onload = handleLoad;
    }
  }, [src, onLoad, ref]);

  if (isLoading) {
    return loader;
  }

  return (
    <img
      src={src}
      alt={alt}
      ref={ref}
      onLoad={() => ref && ref.current && onLoad && onLoad(ref.current)}
      {...imgProps}
    />
  );
});

Img.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  onLoad: PropTypes.function,
  loader: PropTypes.node,
};

Img.defaultProps = {
  loader: null,
  onLoad: null,
};

export default Img;
