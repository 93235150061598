import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { TextField, ButtonElement as Button } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';
import { Box, Flex } from '@rebass/grid';
import { List } from 'immutable';

import { matchShape } from 'src/utils/app-prop-types';
import { useSpace, useCurrentUser } from 'src/hooks/use-resource';
import FormSection from 'src/components/FormSection';
import { useToriiActions } from 'src/modules/torii';
import useFormikWithAutoSave from 'src/hooks/use-formik-with-auto-save';
import { get } from 'src/utils/accessors';
import { Body } from 'src/modules/prisma';
import UploadArea from 'src/components/UploadArea';
import uploadStorage from 'src/utils/upload-storage';
import SpaceAvatar from 'src/components/SpaceAvatar';

import Layout from './_layout';
import messages from './_messages';

const API_URL = process.env.REACT_APP_API_URL;

const SpacelabelGeneralPagePage = ({ match: { params } }) => {
  const { update } = useToriiActions();

  const space = useSpace({
    id: params.spaceId,
    include: ['users'],
  });

  const user = useCurrentUser();

  const spaceUser = get(space, 'relationships.users', List()).find(
    sUser => get(sUser, 'id').split(':')[1] === get(user, 'id'),
  );

  const handleIconUpload = useCallback(
    async ([fileBlob]) => {
      const res = await fetch(
        `${API_URL}/app-workspace/ws-spaces/${params.spaceId}/signed-upload-url`,
        {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify({
            'mime-type': fileBlob.type,
          }),
        },
      );
      const data = await res.json();
      const uploadEndpoint = data['direct-bucket-upload-url'];

      await uploadStorage.queueFile('space-logo', params.spaceId, {
        fileBlob,
        mimeType: fileBlob.type,
        uploadEndpoint,
        onSuccess: async () => {
          const fileNameUrl = new URL(uploadEndpoint);
          const fileName = `${fileNameUrl.origin}${fileNameUrl.pathname}`;
          await update('wsSpaces', {
            id: params.spaceId,
            attributes: {
              logoFileName: fileName,
            },
          });
        },
      });
    },
    [params.spaceId, update],
  );

  const handleSubmit = useCallback(
    async ({ name }) => {
      const { data: updatedSpace } = await update('wsSpaces', {
        id: params.spaceId,
        attributes: {
          name,
        },
      });
      return { name: updatedSpace.attributes.name };
    },
    [params.spaceId, update],
  );

  const { formikState, autoSaveState } = useFormikWithAutoSave({
    initialValues: { name: get(space, 'attributes.name') },
    validate: values => {
      const errors = {};

      if (!values.name) {
        errors.name = <FormattedMessage {...messages.requiredField} />;
      }

      return errors;
    },
    onSubmit: handleSubmit,
  });

  const { handleChange, handleBlur, values, errors } = formikState;

  const isOwner = get(spaceUser, 'attributes.scope') === 'owner';

  const spaceLogo = get(space, 'attributes.logo.small');
  const spaceName = get(space, 'attributes.name');

  return (
    <Layout>
      <FormSection>
        <FormSection.AutoSaveIndicator {...autoSaveState} />
        <FormSection.Fieldset>
          <FormSection.Content>
            {isOwner && (
              <UploadArea
                accept={['image/jpeg', 'image/png']}
                multiple={false}
                onUpload={handleIconUpload}
                disableDrop
              >
                {({ openFileDialog }) => (
                  <Flex mb={3} alignItems="center">
                    <Box mr={4}>
                      <SpaceAvatar
                        name={spaceName}
                        spaceId={params.spaceId}
                        size="xlarge"
                        src={spaceLogo}
                      />
                    </Box>
                    <Box width={[1, 2 / 3]}>
                      <Button
                        color="primary"
                        px={0}
                        py={0}
                        textStyle="action.regular"
                        variant="flat"
                        onClick={openFileDialog}
                      >
                        <FormattedMessage {...messages.labelBusinessIcon} />
                      </Button>
                      <Box mt={1}>
                        <Body color="grey.400" textSize="small">
                          <FormattedMessage {...messages.messageBusinessIcon} />
                        </Body>
                      </Box>
                    </Box>
                  </Flex>
                )}
              </UploadArea>
            )}
            <Box width={[1, 1 / 3]}>
              <TextField
                disabled={!isOwner}
                required
                label={<FormattedMessage {...messages.labelBusinessName} />}
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.name}
                value={values.name}
              />
            </Box>
          </FormSection.Content>
        </FormSection.Fieldset>
      </FormSection>
    </Layout>
  );
};

SpacelabelGeneralPagePage.propTypes = {
  match: matchShape.isRequired,
};

export default withRouter(SpacelabelGeneralPagePage);
