import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import qs from 'qs';
import {
  ViewList as ViewListSvg,
  ViewModule as ViewModuleSvg,
} from '@styled-icons/material';

import { Box, Icon } from 'src/modules/prisma';
import {
  SPACE_VIEW_QUERY_KEY,
  SPACE_VIEW_SESSIONSTORAGE_KEY,
  SpaceViewTypes,
} from 'src/utils/accessors/space';

export default function SpaceViewChooser() {
  const { query, pathname } = useLocation();
  const currentView =
    query[SPACE_VIEW_QUERY_KEY] ||
    sessionStorage.getItem(SPACE_VIEW_SESSIONSTORAGE_KEY);

  const iconParams = useMemo(
    () => type => {
      const isActive =
        (currentView && currentView === type) ||
        (!currentView && type === SpaceViewTypes.GRID);

      return {
        size: 'medium',
        color: isActive ? 'grey.600' : 'grey.300',
      };
    },
    [currentView],
  );

  const linkParams = useMemo(
    () => type => ({
      to: {
        pathname,
        search: qs.stringify({
          ...query,
          [SPACE_VIEW_QUERY_KEY]: type,
        }),
      },
      onClick: () => {
        sessionStorage.setItem(SPACE_VIEW_SESSIONSTORAGE_KEY, type);
      },
    }),
    [pathname, query],
  );

  return (
    <Box display="flex">
      <Link {...linkParams(SpaceViewTypes.LIST)}>
        <Icon type={<ViewListSvg />} {...iconParams(SpaceViewTypes.LIST)} />
      </Link>
      <Link {...linkParams(SpaceViewTypes.GRID)}>
        <Icon type={<ViewModuleSvg />} {...iconParams(SpaceViewTypes.GRID)} />
      </Link>
    </Box>
  );
}
