/* eslint-disable max-classes-per-file */
export type Coordinate = [number, number];

export enum MarkupType {
  Path = 'path',
  Point = 'point',
}

/* Names based on https://getcolorname.com/ */
export enum MarkupColor {
  Emerald = '#3ddd78',
  EasternBlue = '#1ca7a4',
  Cyan = '#00e2ff',
  DodgerBlue = '#3697ff',
  Indigo = '#5c62ca',
  FuchsiaPink = '#be52cc',
  CarnationPink = '#ff9cc8',
  Carnation = '#fb5c5c',
  FlushOrange = '#ff8300',
  LightiningYellow = '#ffc51f',
  Stack = '#8c8c8c',
  Alto = '#dddddd',
}

export interface Markup {
  color: MarkupColor;
  type: MarkupType;
}

function getWebDimensions(
  dimensions: { width: number; height: number },
  targetSize = 1500000,
) {
  const { height, width } = dimensions;

  if (height * width < targetSize) return dimensions;

  const ratio = width / height;
  const newWidth = Math.floor(Math.sqrt(targetSize * ratio));
  const newHeight = Math.floor(newWidth / ratio);

  return { width: newWidth, height: newHeight };
}

export class PathMarkup implements Markup {
  public type = MarkupType.Path;

  constructor(
    public path: Coordinate[],
    public color: MarkupColor = MarkupColor.Carnation,
    public normalized: boolean = false,
  ) {}

  private getScaledPathAsPoint(
    targetWidth: number,
    targetHeight: number,
  ): Coordinate[] {
    const [firstCoordinate] = this.path;

    if (this.normalized) {
      const normalizedFirstCoordinate: Coordinate = [
        firstCoordinate[0] * targetWidth,
        firstCoordinate[1] * targetHeight,
      ];
      return [
        normalizedFirstCoordinate,
        [normalizedFirstCoordinate[0] + 5, normalizedFirstCoordinate[1]],
      ];
    }

    return [firstCoordinate, [firstCoordinate[0] + 5, firstCoordinate[1]]];
  }

  getScaledPath(targetWidth: number, targetHeight: number): Coordinate[] {
    if (this.path.length <= 1)
      return this.getScaledPathAsPoint(targetWidth, targetHeight);
    if (this.normalized) {
      return this.path.map(coordinate => [
        coordinate[0] * targetWidth,
        coordinate[1] * targetHeight,
      ]);
    }

    const webDimensions = getWebDimensions({
      height: targetHeight,
      width: targetWidth,
    });

    return this.path.map(coordinate => {
      const percentageCoordinates = [
        coordinate[0] / webDimensions.width,
        coordinate[1] / webDimensions.height,
      ];
      return [
        percentageCoordinates[0] * targetWidth,
        percentageCoordinates[1] * targetHeight,
      ];
    });

    return this.path;
  }
}

export class PointMarkup implements Markup {
  public type = MarkupType.Point;
  constructor(
    public x: number,
    public y: number,
    public color: MarkupColor = MarkupColor.Carnation,
  ) {}

  getScaledPosition(targetWidth: number, targetHeight: number) {
    return [this.x * targetWidth, this.y * targetHeight];
  }
}
/* eslint-enable max-classes-per-file */
