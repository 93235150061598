import styled, { css } from 'styled-components/macro';
import { transition, variants } from '@picter/prisma';
import { transitions } from 'polished';

import ContentContainer from './ContentContainer';
import { CONTEXT_SIDEBAR_SIZE } from '../constants';
import MainSidebarPanel from './MainSidebarPanel';

const contextModeVariants = variants('contextMode', {
  resize: css`
    @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
      width: calc(100% - ${CONTEXT_SIDEBAR_SIZE}px);
    }

    @media screen and (min-width: ${props => props.theme.breakpoints[4]}) {
      width: calc(100% - ${CONTEXT_SIDEBAR_SIZE + 60}px);
    }
  `,
  shift: css`
    @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
      transform: translateX(-${CONTEXT_SIDEBAR_SIZE}px);
    }

    @media screen and (min-width: ${props => props.theme.breakpoints[4]}) {
      transform: translateX(-${CONTEXT_SIDEBAR_SIZE + 60}px);
    }
  `,
});

type Props = {
  contextMode: 'resize' | 'shift';
};

const LayoutContainer = styled.div<Props>`
  background: ${props => props.theme.colors.gray[100]};
  display: flex;
  height: 100%;
  justify-content: flex-end;
  position: relative;
  ${transitions(
    transition({ property: 'transform' }),
    transition({ property: 'width' }),
  )};
  width: 100%;
  will-change: flex-basis, transform, width;

  ${MainSidebarPanel} {
    transform: translateX(0);
    transition: ${transition({
      duration: '200ms',
      property: 'transform',
      ease: 'ease-in',
    })};
  }

  &.main-sidebar-opened {
    ${MainSidebarPanel} {
      transform: translateX(100%);
      transition: ${transition({
        duration: '200ms',
        property: 'transform',
        ease: 'ease-out',
      })};
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    ${MainSidebarPanel} ~ ${ContentContainer} {
      flex-basis: calc(100% - 20px);
    }

    &.main-sidebar-opened {
      ${MainSidebarPanel} {
        transform: unset;
      }

      ${MainSidebarPanel} ~ ${ContentContainer} {
        flex-basis: 0;
      }
    }

    &.context-sidebar-opened {
      ${contextModeVariants};
    }
  }
`;

export default LayoutContainer;
