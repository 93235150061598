import React from 'react';

import { Box, Spacer } from 'src/modules/prisma';
import useTinyKeys from 'src/hooks/use-tiny-keys';

import DurationControl, { DurationControlProps } from './DurationControl';
import FullscreenControl, { FullscreenControlProps } from './FullscreenControl';
import PlayPauseControl, { PlayPauseControlProps } from './PlayPauseControl';
import TimelineControl, { TimelineControlProps } from './TimelineControl';
import VolumeControl, { VolumeControlProps } from './VolumeControl';

export type VideoControlsProps = DurationControlProps &
  Pick<FullscreenControlProps, 'fullscreen'> &
  Pick<PlayPauseControlProps, 'playing'> &
  Pick<
    TimelineControlProps,
    | 'markers'
    | 'loaded'
    | 'played'
    | 'seekStep'
    | 'onClickMarker'
    | 'onSeek'
    | 'onSeekEnd'
    | 'onSeekStart'
  > &
  Pick<VolumeControlProps, 'volume' | 'muted'> & {
    onChangeVolume: VolumeControlProps['onChange'];
    onClickFullscreen: FullscreenControlProps['onClick'];
    onClickPlayPause: PlayPauseControlProps['onClick'];
    onEscape: (event: KeyboardEvent) => void;
    onToggleVolume: VolumeControlProps['onToggle'];
  };

export default function VideoControls({
  markers,
  seekStep,
  duration,
  fullscreen,
  loaded,
  muted,
  played,
  playing,
  volume,

  onChangeVolume,
  onClickFullscreen,
  onClickMarker,
  onClickPlayPause,
  onEscape,
  onSeek,
  onSeekEnd,
  onSeekStart,
  onToggleVolume,
}: VideoControlsProps) {
  useTinyKeys(
    window,
    {
      Escape: event => {
        onEscape(event);
      },
    },
    [onEscape],
  );

  return (
    <Box
      position="fixed"
      display="flex"
      flexDirection="row"
      bottom={0}
      px={4}
      py={5}
      left={0}
      right={0}
      m="auto"
      maxWidth={1024}
      zIndex={250}
    >
      <PlayPauseControl playing={playing} onClick={onClickPlayPause} />
      <Spacer sx={2} />
      <VolumeControl
        muted={muted}
        volume={volume}
        onChange={onChangeVolume}
        onToggle={onToggleVolume}
      />
      <Spacer sx={2} />
      <TimelineControl
        markers={markers}
        loaded={loaded}
        played={played}
        seekStep={seekStep}
        onClickMarker={onClickMarker}
        onSeekStart={onSeekStart}
        onSeek={onSeek}
        onSeekEnd={onSeekEnd}
      />
      <Spacer sx={2} />
      <DurationControl duration={duration} played={played} />
      <Spacer sx={2} />
      <FullscreenControl fullscreen={fullscreen} onClick={onClickFullscreen} />
    </Box>
  );
}
