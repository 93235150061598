import React from 'react';
import PropTypes from 'prop-types';
import { TrackerScope } from '@picter/tracker';
import { useParams } from 'react-router-dom';

import { SpaceFlagsProvider } from 'src/containers/FeatureFlags';
import { useSpace } from 'src/hooks/use-resource';
import withSuspenseLoader from 'src/hocs/with-suspense-loader';
import NotFoundPage from 'src/components/NotFoundPage';

const SpaceFlagsProviderContainer = withSuspenseLoader({
  ErrorComponent: NotFoundPage,
  LoadingComponent: null,
})(({ children }) => {
  const { spaceId } = useParams();
  const space = useSpace({
    id: spaceId,
    include: ['plan.features', 'extraFeatures'],
  });

  return <SpaceFlagsProvider space={space}>{children}</SpaceFlagsProvider>;
});

function Document({ children }) {
  return (
    <SpaceFlagsProviderContainer>
      <TrackerScope name="Settings / Space">{children}</TrackerScope>
    </SpaceFlagsProviderContainer>
  );
}

Document.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Document;
