import { createSelector } from 'reselect';
import { List } from 'immutable';
import { select } from 'src/modules/torii';

import createImmutableSelector from 'src/utils/create-immutable-selector';
import { isImageProofFilter } from 'src/utils/accessors/project-image';
import { get } from 'src/utils/accessors';

import { filterSelector } from '.';

export const imageSelectorById = createSelector(
  (state, { id }) =>
    select(state, 'wsCollectionImages', {
      id,
    }),
  image => image,
);

const collectionImagesSelector = createImmutableSelector(
  state => get(state.collection, 'relationships.images') || List(),
  images => images.filter(image => !!get(image, 'relationships.image')),
);

export const collectionImageSelectorById = createSelector(
  collectionImagesSelector,
  (_, { id }) => id,
  (collectionImages, id) =>
    collectionImages.find(collectionImage => get(collectionImage, 'id') === id),
);

export const collectionImageMaxOrderSelector = createSelector(
  collectionImagesSelector,
  images => images.maxBy(image => get(image, 'attributes.order')),
);

const sortImages = images =>
  images.sortBy(image => get(image, 'attributes.order'));

export const sortedCollectionImagesSelector = createSelector(
  collectionImagesSelector,
  sortImages,
);

export const makeFilteredCollectionImageSelector = () =>
  createSelector(
    sortedCollectionImagesSelector,
    filterSelector,
    (images, filter) =>
      images.filter(collectionImage => {
        const image = get(collectionImage, 'relationships.image');
        return !image.isEmpty() && isImageProofFilter(filter, image);
      }),
  );
