import { defineMessages } from 'react-intl';

export default defineMessages({
  labelDoneWithUploads: {
    id: 'RequestFilesPage.labelDoneWithUploads',
    defaultMessage: 'Done with my uploads',
  },
  labelUploadMore: {
    id: 'RequestFilesPage.labelUploadMore',
    defaultMessage: 'Upload more files',
  },
  titleFilesSent: {
    id: 'RequestFilesPage.titleFilesSent',
    defaultMessage: 'Files sent!',
  },
  messageFilesSent: {
    id: 'RequestFilesPage.messageFilesSent',
    defaultMessage: 'Job done! Feels good, right?',
  },
  titleAuthenticatedUserRegisterModal: {
    id: 'RequestFilesPage.titleAuthenticatedUserRegisterModal',
    defaultMessage: 'Hi {name}!',
  },
  titleUnAuthenticatedUserRegisterModal: {
    id: 'RequestFilesPage.titleUnAuthenticatedUserRegisterModal',
    defaultMessage: 'Hello there!',
  },
  messageLiteUserRegisterModal: {
    id: 'RequestFilesPage.messageLiteUserRegisterModal',
    defaultMessage: `{owner} has invited you to upload files. 
    Can you give the upload a name so they can 
    keep track of what you send?`,
  },
  placeholderCollectionNameForm: {
    id: 'RequestFilesPage.placeholderCollectionNameForm',
    defaultMessage: 'Upload name',
  },
  labelCollectionNameFormModalConfirm: {
    id: 'RequestFilesPage.labelCollectionNameFormModalConfirm',
    defaultMessage: 'Continue',
  },
});
