import Accordion from './styles/Accordion';
import AccordionItem from './components/AccordionItem';
import AccordionItemContent from './components/AccordionItemContent';
import AccordionItemDisclosure from './components/AccordionItemDisclosure';

Accordion.Item = AccordionItem;
Accordion.Item.Content = AccordionItemContent;
Accordion.Item.Disclosure = AccordionItemDisclosure;

export default Accordion;
