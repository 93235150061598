import { createAsyncTypes } from './utils/action-types-helpers';

export const API_ATTACH_RELATIONSHIP_TYPES = createAsyncTypes(
  'API_ATTACH_RELATIONSHIP',
);
export const API_CREATE_DATA_TYPES = createAsyncTypes('API_CREATE_DATA');
export const API_DESTROY_DATA_TYPES = createAsyncTypes('API_DESTROY_DATA');
export const API_LOAD_DATA_TYPES = createAsyncTypes('API_LOAD_DATA');
export const API_REMOVE_RELATIONSHIP_TYPES = createAsyncTypes(
  'API_REMOVE_RELATIONSHIP',
);
export const API_UPDATE_DATA_TYPES = createAsyncTypes('API_UPDATE_DATA');
export const API_UPDATE_RELATIONSHIP_TYPES = createAsyncTypes(
  'API_UPDATE_RELATIONSHIP',
);

export const API_RESET_STORE = 'API_RESET_STORE';
export const REL_HAS_MANY = 'has-many';
export const REL_BELONGS_TO = 'belongs-to';

export const API_LOCAL_CREATE = 'API_LOCAL_CREATE';
export const API_LOCAL_UPDATE = 'API_LOCAL_UPDATE';
export const API_LOCAL_DESTROY = 'API_LOCAL_DESTROY';
export const API_LOCAL_ATTACH_RELATIONSHIP = 'API_LOCAL_ATTACH_RELATIONSHIP';
export const API_LOCAL_REMOVE_RELATIONSHIP = 'API_LOCAL_REMOVE_RELATIONSHIP';
export const API_LOCAL_UPDATE_RELATIONSHIP = 'API_LOCAL_UPDATE_RELATIONSHIP';
export const API_STATE_UPDATE = 'API_STATE_UPDATE';
