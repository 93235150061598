import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import paths from 'src/routes/paths';
import lazyWithPreload from 'src/utils/lazy-with-preload';
import { libraryProjectsUrl } from 'src/routes/urls';
import { viewModeTypes } from 'src/routes/constants';
import RouteAuthenticated from 'src/utils/cerberus/components/RouteAuthenticated';

import Document from './_document';
import ProjectsPage from '.';

const { LIBRARY_PROJECT_PATH } = paths;

const ProjectRoutes = lazyWithPreload(() => import('./{projectId}/_routes'));

export default function ProjectsRoutes() {
  useEffect(() => {
    ProjectRoutes.preload();
  }, []);

  return (
    <Document>
      <Switch>
        <Route path={LIBRARY_PROJECT_PATH} component={ProjectRoutes} />
        <RouteAuthenticated
          path={libraryProjectsUrl({ viewMode: viewModeTypes.DEFAULT })}
          component={ProjectsPage}
        />
        <Redirect
          to={{
            pathname: libraryProjectsUrl({ viewMode: viewModeTypes.DEFAULT }),
          }}
        />
      </Switch>
    </Document>
  );
}
