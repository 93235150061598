import React from 'react';
import PropTypes from 'prop-types';
import { ButtonElement as Button, Body, Modal } from '@picter/prisma';
import { Flex } from '@rebass/grid';
import { FormattedMessage } from 'react-intl';
import { TrackerEvent, TrackerScope } from '@picter/tracker';
import { Link } from 'react-router-dom';

import { formattedMessage } from 'src/utils/app-prop-types';

import messages from '../messages';

function UpgradeModal({ message, name, onClose, open, title, upgradeLink }) {
  return (
    <TrackerScope name={name}>
      <Modal title={title} onClickClose={onClose} open={open}>
        <TrackerEvent name={name} trackView>
          <Body>{message}</Body>
          <Flex mt={7}>
            <Button
              color="grey.600"
              onClick={onClose}
              px={0}
              py={0}
              textStyle="action.large"
              variant="flat"
            >
              <FormattedMessage {...messages.labelCancel} />
            </Button>
            <Flex ml="auto">
              {upgradeLink && (
                <TrackerEvent name="Upgrade plan" trackClick>
                  <Link to={upgradeLink}>
                    <Button
                      color="primary"
                      px={0}
                      py={0}
                      ml={6}
                      textStyle="action.large"
                      variant="flat"
                    >
                      <FormattedMessage {...messages.labelUpgrade} />
                    </Button>
                  </Link>
                </TrackerEvent>
              )}
            </Flex>
          </Flex>
        </TrackerEvent>
      </Modal>
    </TrackerScope>
  );
}

UpgradeModal.propTypes = {
  message: formattedMessage,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: formattedMessage.isRequired,
  upgradeLink: PropTypes.string,
};

UpgradeModal.defaultProps = {
  message: undefined,
  onClose: null,
  open: false,
  upgradeLink: undefined,
};

export default UpgradeModal;
