import { fromJS, Map, List } from 'immutable';
import debounce from 'lodash/debounce';
import union from 'lodash/union';

export const DEBOUNCE_REQUEST_TIMEOUT = 30;

export const loadAll = ({ loaders, resolvers, load }, cleanUpFn) => {
  cleanUpFn();
  const { single, collection } = loaders.toJS();

  // flattening result with concat
  const singleRequestsEntries = [].concat(
    ...Object.keys(single).map(entityKey =>
      Object.values(single[entityKey]).map(entityValue => [
        entityKey,
        entityValue,
      ]),
    ),
  );

  const requests = [...singleRequestsEntries, ...Object.entries(collection)];

  return Promise.all(
    requests.map(([entity, params]) => {
      const requestResolvers = params.id
        ? resolvers.getIn(['single', entity, params.id])
        : resolvers.getIn([entity]);

      // eslint-disable-next-line react/destructuring-assignment
      return load(entity, params, { ignoreCache: params.revalidate })
        .then(res =>
          requestResolvers.get('resolvers').forEach(resolver => resolver(res)),
        )
        .catch(err =>
          requestResolvers.get('rejecters').forEach(rejecter => rejecter(err)),
        );
    }),
  );
};

export const debouncedLoadAll = debounce(loadAll, DEBOUNCE_REQUEST_TIMEOUT, {
  leading: false,
});

export const mergeResolver = ({
  entity,
  id,
  resolvers,
  resolver,
  rejecter,
}) => {
  let updatedResolvers = resolvers;

  if (id) {
    updatedResolvers = updatedResolvers.updateIn(
      ['single', entity, id, 'resolvers'],
      List(),
      val => val.push(resolver),
    );
    updatedResolvers = updatedResolvers.updateIn(
      ['single', entity, id, 'rejecters'],
      List(),
      val => val.push(rejecter),
    );
  } else {
    updatedResolvers = updatedResolvers.updateIn(
      [entity, 'resolvers'],
      List(),
      val => val.push(resolver),
    );
    updatedResolvers = updatedResolvers.updateIn(
      [entity, 'rejecters'],
      List(),
      val => val.push(rejecter),
    );
  }

  return updatedResolvers;
};

export const mergeLoader = ({ entity, loader, loaders }) => {
  let updatedLoaders = loaders;
  const { id, include, ...config } = loader;

  if (id) {
    updatedLoaders = updatedLoaders.setIn(
      ['single', entity, id, 'id'],
      loader.id,
    );

    updatedLoaders = updatedLoaders.updateIn(
      ['single', entity, id, 'include'],
      List(),
      val => fromJS(union(val.toJS(), include)),
    );

    Object.entries(config).forEach(([key, value]) => {
      updatedLoaders = updatedLoaders.updateIn(
        ['single', entity, id, key],
        currentValue => {
          if (currentValue === undefined) {
            return fromJS(value);
          }

          if (Map.isMap(currentValue) || List.isList(currentValue)) {
            return currentValue.mergeDeep(fromJS(value));
          }

          return value;
        },
      );
    });
  } else {
    if (!updatedLoaders.getIn(['collection', entity])) {
      updatedLoaders = updatedLoaders.setIn(
        ['collection', entity, 'include'],
        fromJS(include),
      );
    } else {
      updatedLoaders = updatedLoaders.updateIn(
        ['collection', entity, 'include'],
        List(),
        val => fromJS(union(val.toJS(), include)),
      );
    }

    Object.entries(config).forEach(([key, value]) => {
      updatedLoaders = updatedLoaders.updateIn(
        ['collection', entity, key],
        currentValue => {
          return currentValue === undefined || !currentValue.mergeDeep
            ? fromJS(value)
            : currentValue.mergeDeep(fromJS(value));
        },
      );
    });
  }

  return updatedLoaders;
};

const createDebouncedLoader = load => {
  let loaders = fromJS({ single: {}, collection: {} });
  let resolvers = Map();

  return (entity, loader) => {
    return new Promise((resolve, reject) => {
      loaders = mergeLoader({ entity, loader, loaders });
      resolvers = mergeResolver({
        entity,
        resolvers,
        id: loader.id,
        resolver: resolve,
        rejecter: reject,
      });

      // Sends a copy of the values before clearing because
      // you want the next loaders and resolvers to start
      // being stored in the exactly moment you call debouncedLoadAll.
      // Cant wait for promise because otherwise loaders
      // and resolvers called during promise resolution would be lost
      debouncedLoadAll({ loaders, resolvers, load }, () => {
        loaders = fromJS({ single: {}, collection: {} });
        resolvers = Map();
      });
    });
  };
};

export default createDebouncedLoader;
