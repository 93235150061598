import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedRelativeTime } from 'react-intl';
import { Box, Flex } from '@rebass/grid';
import { Timer as TimerSvg } from '@styled-icons/material';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';

import {
  Text,
  Anchor,
  Body,
  Icon,
  Panel,
  allowlistProps,
} from 'src/modules/prisma';
import { getDateDeltaFromNowInSeconds } from 'src/utils/date';
import { getSpacePlan } from 'src/utils/accessors/space';
import { librarySettingsSpaceSubscriptionUrl } from 'src/routes/urls';
import { get } from 'src/utils/accessors';

import messages from './messages';

function SpaceTrialPanel({ space }) {
  const spaceId = get(space, 'id');
  const spaceName = get(space, 'attributes.name');
  const spaceEndOfTrial = get(space, 'attributes.trialEndsAt');
  const planName = get(getSpacePlan(space, Map()), 'attributes.name');

  const deltaEndOfTrialInMs = useMemo(
    () => getDateDeltaFromNowInSeconds(spaceEndOfTrial),
    [spaceEndOfTrial],
  );
  const isOnTrial = deltaEndOfTrialInMs > 0;

  return (
    isOnTrial && (
      <Panel
        backgroundColor="white"
        borderColor="grey.200"
        borderRadius="medium"
        borderStyle="solid"
        borderWidth="1px"
        px={4}
        py={3}
      >
        <Text color="info" textStyle="action.regular">
          <FormattedMessage
            {...messages.messageCurrentlyInTrial}
            values={{
              spaceName,
              planName,
              learnmorelink: msg => (
                <Anchor
                  color="info"
                  fontWeight="regular"
                  tag={allowlistProps(Link, ['children', 'className', 'to'])}
                  textDecoration="underline"
                  textStyle="action.regular"
                  to={librarySettingsSpaceSubscriptionUrl({ spaceId })}
                >
                  {msg}
                </Anchor>
              ),
            }}
          />
        </Text>
        <Flex alignItems="center" mt={2}>
          <Box mr={2}>
            <Icon
              color="info"
              display="flex"
              size="small"
              type={<TimerSvg />}
            />
          </Box>
          <Body color="info">
            <FormattedMessage
              {...messages.messageEndsTrial}
              values={{
                date: (
                  <FormattedRelativeTime
                    numeric="auto"
                    unit="second"
                    updateIntervalInSeconds={60}
                    value={deltaEndOfTrialInMs}
                  />
                ),
              }}
            />
          </Body>
        </Flex>
      </Panel>
    )
  );
}

SpaceTrialPanel.propTypes = {
  space: PropTypes.instanceOf(Map).isRequired,
};

export default SpaceTrialPanel;
