import { Sizes } from 'src/types';

export const CONTAINER_SIZES_MAP: Sizes<number> = {
  xxxsmall: 12,
  xxsmall: 16,
  xsmall: 20,
  small: 24,
  medium: 28,
  large: 32,
  xlarge: 48,
};

export const CONTAINER_SIZE_TO_ICON_SIZE_MAP: Sizes<string> = {
  xxxsmall: 'xsmall',
  xxsmall: 'small',
  xsmall: 'medium',
  small: 'large',
  medium: 'xlarge',
  large: 'xxlarge',
  xlarge: 'xxxlarge',
};

export const CONTAINER_SHAPES_MAP = {
  circle: () => ({
    borderRadius: '50%',
  }),
  'rounded-square': () => ({
    borderRadius: '4px',
  }),
};

export const IDENTICON_FONT_SIZES_MAP: Sizes<number> = {
  xxxsmall: 10,
  xxsmall: 11,
  xsmall: 12,
  small: 13.75,
  medium: 16.5,
  large: 20,
  xlarge: 20,
};

export const FALLBACK_USER_ICON_SIZES_MAP: Sizes<{
  glyphSize: string;
  size: string;
}> = {
  xxxsmall: {
    glyphSize: 'small',
    size: 'small',
  },
  xxsmall: {
    glyphSize: 'small',
    size: 'small',
  },
  xsmall: {
    glyphSize: 'small',
    size: 'small',
  },
  small: {
    glyphSize: 'xlarge',
    size: 'medium',
  },
  medium: {
    glyphSize: 'huge',
    size: 'large',
  },
  large: {
    glyphSize: 'xhuge',
    size: 'xlarge',
  },
  xlarge: {
    glyphSize: 'xhuge',
    size: 'xlarge',
  },
};

export const IDENTICON_COLORS = [
  '#3ddd78', // shamrock
  '#1ca7a4', // eastern-blue
  '#00e2ff', // cyan
  '#3697ff', // dodger-blue
  '#5c62ca', // indigo
  '#be52cc', // amethyst
  '#ff9cc8', // carnation-pink
  '#fb5c5c', // carnation
  '#ff8300', // flush-orange
  '#ffc51f', // lightining-yellow
];
