import React, { useRef } from 'react';
import { ButtonElement as Button } from '@picter/prisma';
import PropTypes from 'prop-types';

import { Box, Icon, Tooltip } from 'src/modules/prisma';
import { formattedMessage } from 'src/utils/app-prop-types';

import Counter from './styles/Counter';

function IconButton({ counter, icon, label, onClick, ...props }) {
  const buttonRef = useRef();

  return (
    <Tooltip>
      <Tooltip.Reference>
        {(tooltipState, { ref, ...htmlProps }) => (
          <Button
            color="primary"
            textStyle="action.regular"
            px={2}
            variant="flat"
            onClick={(...args) => {
              onClick(...args);
              buttonRef.current.blur();
            }}
            ref={node => {
              buttonRef.current = node;
              ref(node);
            }}
            {...props}
            {...htmlProps}
          >
            <Box alignItems="center" display="flex">
              <Icon boxSize="large" size="medium" type={icon} />
              {counter > 0 && (
                <Counter ml={1} textStyle="body.xsmall">
                  {counter}
                </Counter>
              )}
            </Box>
          </Button>
        )}
      </Tooltip.Reference>
      <Tooltip.Message>{label}</Tooltip.Message>
    </Tooltip>
  );
}

IconButton.propTypes = {
  counter: PropTypes.number,
  icon: PropTypes.element.isRequired,
  label: formattedMessage.isRequired,
  onClick: PropTypes.func,
};

IconButton.defaultProps = {
  counter: 0,
  onClick: f => f,
};

export default IconButton;
