import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ButtonElement as Button, Modal, TextField } from '@picter/prisma';
import { Box, Flex } from '@rebass/grid';
import { Formik, Form, Field } from 'formik';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

import { RolesDropdown } from 'src/components/UserRoleBox';
import useFormatMessage from 'src/hooks/use-format-message';

import messages from './messages';

function InviteMemberModal({ open, onClose, onSend, allowedRoles, disabled }) {
  const formatMessage = useFormatMessage();
  const formikConfig = useMemo(
    () => ({
      initialValues: {
        email: '',
        scope: 'member',
      },
      validationSchema: Yup.object().shape({
        email: Yup.string()
          .email()
          .required(formatMessage(messages.messageEmailRequired)),
        scope: Yup.string().required(),
      }),
      onSubmit: async (values, formik) => {
        await onSend(values);
        formik.setSubmitting(false);
        formik.resetForm();
        onClose();
      },
    }),
    [onSend, onClose, formatMessage],
  );

  const setScope = useCallback(
    (form, scope) => {
      if (allowedRoles.includes(scope)) {
        return () => form.setFieldValue('scope', scope);
      }

      return undefined;
    },
    [allowedRoles],
  );

  return (
    <Modal
      open={open}
      onClickClose={onClose}
      title={formatMessage(messages.labelInviteMember)}
    >
      <Formik {...formikConfig}>
        <Flex as={Form}>
          <Box width={1}>
            <Field name="email">
              {({ field, meta }) => (
                <FormattedMessage {...messages.labelEmail}>
                  {message => (
                    <TextField
                      {...field}
                      autoFocus
                      placeholder={message}
                      error={meta.touched && meta.error}
                    />
                  )}
                </FormattedMessage>
              )}
            </Field>
          </Box>
          <Flex px={1} style={{ height: '42px' }} alignItems="center">
            <Field name="scope">
              {({ field, form }) => (
                <RolesDropdown
                  title={
                    <FormattedMessage {...messages.labelRoleInThisSpace} />
                  }
                  currentScope={field.value}
                  onClickOwner={setScope(form, 'owner')}
                  onClickManager={setScope(form, 'manager')}
                  onClickMember={setScope(form, 'member')}
                  onClickViewer={setScope(form, 'viewer')}
                  messageManager={
                    <FormattedMessage {...messages.messageManagerRole} />
                  }
                  messageMember={
                    <FormattedMessage {...messages.messageMemberRole} />
                  }
                  messageViewer={
                    <FormattedMessage {...messages.messageViewerRole} />
                  }
                  buttonBackground
                />
              )}
            </Field>
          </Flex>
          <Box style={{ whiteSpace: 'nowrap' }}>
            <Button
              height={42}
              type="submit"
              color="white"
              data-intercom-target="send-invitation-button"
              disabled={disabled}
            >
              <FormattedMessage {...messages.labelSend} />
            </Button>
          </Box>
        </Flex>
      </Formik>
    </Modal>
  );
}

InviteMemberModal.propTypes = {
  open: PropTypes.bool,
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
  onSend: PropTypes.func.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
};

InviteMemberModal.defaultProps = {
  open: false,
  disabled: false,
  onClose: f => f,
  allowedRoles: ['owner', 'manager', 'member', 'viewer'],
};

export default InviteMemberModal;
