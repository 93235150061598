import { createElement } from 'react';
import PropTypes from 'prop-types';

import Stacked from '../styles/IconGroupStacked';

const Group = {
  stacked: Stacked,
};

const IconGroup = ({ mode, ...props }) => createElement(Group[mode], props);

IconGroup.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  mode: PropTypes.oneOf(['merged', 'stacked']),
  /**
   * Only for mode="stacked"
   */
  outline: PropTypes.string,
  overlapSize: PropTypes.number,
};

IconGroup.defaultProps = {
  id: null,
  className: null,
  mode: 'stacked',
  outline: 'transparent',
  overlapSize: 0,
};

IconGroup.displayName = 'IconGroup';

export default IconGroup;
