import React from 'react';
import { DetailLayout, GeneralLayout, MediaQuery, H2 } from '@picter/prisma';
import picterLogoBlack from '@picter/prisma/lib/assets/picter-logo-black.svg';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Box } from '@rebass/grid';

import MenuIcon from 'src/components/MenuIcon';
import useDocumentTitle from 'src/hooks/use-document-title';
import ProjectsSection from 'src/containers/ProjectsSection';
import NewWorkspaceButton from 'src/components/NewWorkspaceButton';
import { useCurrentUser } from 'src/hooks/use-resource';
import { get } from 'src/utils/accessors';

import Layout from '../_layout';
import MainNavigation from '../_components/MainNavigation';
import messages from './messages';

const ProjectsPage = () => {
  useDocumentTitle('Projects');

  const user = useCurrentUser(
    { include: ['productFlags', 'spaces'] },
    { request: false },
  );

  const productFlags = get(user, 'relationships.productFlags');
  const isSpaceUser = get(productFlags, 'attributes.isSpaceUser');

  const pageTitle = messages.labelSharedwithMe;

  return (
    <Layout>
      <Layout.Navigation>
        <MainNavigation />
        {!isSpaceUser && (
          <Box mx={5} mt="auto" mb={6}>
            <NewWorkspaceButton showTooltip />
          </Box>
        )}
      </Layout.Navigation>
      <Layout.Content>
        <DetailLayout>
          <MediaQuery
            query={{ maxWidth: 0 }}
            render={() => (
              <DetailLayout.Top>
                <DetailLayout.Top.AlignLeft>
                  <MenuIcon />
                </DetailLayout.Top.AlignLeft>
                <DetailLayout.Top.AlignCenter>
                  <img src={picterLogoBlack} alt="Picter's logo" />
                </DetailLayout.Top.AlignCenter>
              </DetailLayout.Top>
            )}
          />
          <DetailLayout.Content>
            <GeneralLayout limited>
              <GeneralLayout.Top>
                <GeneralLayout.Top.AlignLeft>
                  <H2 noMargin>
                    <FormattedMessage {...pageTitle} />
                  </H2>
                </GeneralLayout.Top.AlignLeft>
              </GeneralLayout.Top>
              <GeneralLayout.Content>
                <ProjectsSection />
              </GeneralLayout.Content>
            </GeneralLayout>
          </DetailLayout.Content>
        </DetailLayout>
      </Layout.Content>
    </Layout>
  );
};

export default withRouter(ProjectsPage);
