import React, { useEffect, useMemo, useReducer } from 'react';

import { createContextWithAccessors } from '../utils';
import { AnnotationId, Annotation, AnnotationMeta } from '../models/Annotation';
import annotationsReducer, { AnnotationsState } from '../reducer';
import { Markup } from '../models/Markup';

// TODO: Find a better solution to sending default values to the context
export const [
  AnnotationsControlsContext,
  useAnnotationsControls,
] = createContextWithAccessors<{
  create: (markups: Markup[]) => void;
  meta: (annotationId: AnnotationId, metadata: AnnotationMeta) => void;
  remove: (annotationId: AnnotationId) => void;
  select: (annotationId: AnnotationId) => void;
  deselect: () => void;
  highlight: (annotationId: AnnotationId) => void;
  drawing: (isDrawing: boolean) => void;
}>({
  create: () => undefined,
  meta: () => undefined,
  remove: () => undefined,
  select: () => undefined,
  deselect: () => undefined,
  highlight: () => undefined,
  drawing: () => undefined,
});

const defaultState = {
  annotations: new Map(),
  isDrawing: false,
  selectedId: null,
  highlightedId: null,
};

export const [
  AnnotationsDataContext,
  useAnnotationsData,
] = createContextWithAccessors<AnnotationsState>(defaultState);

export function useAnnotations() {
  return [useAnnotationsControls(), useAnnotationsData()] as const;
}

export default function AnnotationsProvider({
  annotations,
  children,
  id,
}: React.PropsWithChildren<{
  annotations: Annotation[];
  id: string;
}>) {
  const [state, dispatch] = useReducer(annotationsReducer, defaultState);

  useEffect(() => {
    dispatch({
      type: 'ANNOTATIONS_RESET',
      payload: defaultState,
    });
  }, [id]);

  useEffect(() => {
    const annotationsMap = annotations.reduce((map, annotation) => {
      map.set(annotation.id, annotation);
      return map;
    }, new Map());

    dispatch({
      type: 'ANNOTATIONS_MERGE',
      payload: { annotations: annotationsMap },
    });
  }, [annotations]);

  const controls = useMemo(
    () => ({
      create: (markups: Markup[]) =>
        dispatch({ type: 'ANNOTATIONS_CREATE', payload: { markups } }),
      meta: (annotationId: AnnotationId, metadata: AnnotationMeta) =>
        dispatch({
          type: 'ANNOTATIONS_META',
          payload: { id: annotationId, data: metadata },
        }),
      deselect: () => dispatch({ type: 'ANNOTATIONS_DESELECT' }),
      remove: (annotationId: AnnotationId) =>
        dispatch({ type: 'ANNOTATIONS_REMOVE', payload: { id: annotationId } }),
      select: (annotationId: AnnotationId) =>
        dispatch({ type: 'ANNOTATIONS_SELECT', payload: { id: annotationId } }),
      highlight: (annotationId: AnnotationId) =>
        dispatch({
          type: 'ANNOTATIONS_HIGHLIGHT',
          payload: { id: annotationId },
        }),
      drawing: (isDrawing: boolean) =>
        dispatch({
          type: 'ANNOTATIONS_DRAWING',
          payload: isDrawing,
        }),
    }),
    [],
  );

  return (
    <AnnotationsControlsContext.Provider value={controls}>
      <AnnotationsDataContext.Provider value={state}>
        {children}
      </AnnotationsDataContext.Provider>
    </AnnotationsControlsContext.Provider>
  );
}
