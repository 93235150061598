import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { Link, useLocation } from 'react-router-dom';
import { useFormik, FormikProps, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { A, Spinner } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';

import {
  Body,
  Box,
  Button,
  Heading,
  HintMessage,
  TextField,
} from 'src/modules/prisma';
import PicterLogoSVG from 'src/assets/PicterLogoSVG';
import useFormatMessage from 'src/hooks/use-format-message';
import useScript from 'src/hooks/use-script';

import messages from './messages';

export const TERMS_URL = 'https://www.picter.com/terms-of-use';
export const PRIVACY_POLICY_URL = 'https://www.picter.com/privacy-policy';

const Form = styled(Box).attrs({
  width: 1,
  as: 'form',
})`
  max-width: 360px;
`;

const Anchor = styled(A)`
  font-size: inherit;
`;

type SignupFormProps = {
  onSubmit: (
    formValues: FormValues & { recaptchaToken?: string },
    formikHelpers: FormikHelpers<FormValues>,
  ) => void;
  loginUrl:
    | {
        pathname: string;
        search: string;
        state: string;
      }
    | string;
};

type LocationSate = {
  email?: string;
  logo: ReactNode;
  title: ReactNode;
};

type FormValues = {
  email: string;
  newPassword: string;
};

function SignupForm({ onSubmit: onSubmitHandler, loginUrl }: SignupFormProps) {
  const formatMessage = useFormatMessage();
  const { state: locationState }: { state?: LocationSate } = useLocation();
  const reCaptchaScriptStatus = useScript(
    `https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`,
    { removeOnUnmount: true },
  );

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    dirty,
    isValid,
    isSubmitting,
  }: FormikProps<FormValues> = useFormik({
    onSubmit(formValues, formikHelpers) {
      window.grecaptcha?.enterprise.ready(async () => {
        const recaptchaToken = await window.grecaptcha.enterprise.execute(
          process.env.REACT_APP_RECAPTCHA_SITE_KEY as string,
          { action: 'signup' },
        );
        onSubmitHandler({ ...formValues, recaptchaToken }, formikHelpers);
      });
    },
    initialValues: {
      email: locationState?.email ?? '',
      newPassword: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(formatMessage(messages.messageErrorEmailInvalid))
        .required(formatMessage(messages.messageErrorEmailInvalid)),
      newPassword: Yup.string()
        .min(
          8,
          formatMessage(messages.messageErrorPasswordTooShort, {
            min: 8,
          }),
        )
        .required(formatMessage(messages.messageErrorPasswordEmpty)),
    }),
  });

  const buildFieldProps = (name: keyof FormValues) => ({
    name,
    value: values[name],
    error: touched[name] && errors[name],
    onChange: handleChange,
    onBlur: handleBlur,
    disabled: isSubmitting,
  });

  return (
    <>
      {locationState?.logo !== false && (
        <Box mb={3}>{locationState?.logo ?? <PicterLogoSVG />}</Box>
      )}

      {locationState?.title !== false && (
        <Box mb={7}>
          <Heading
            textSize="large"
            textAlign="center"
            whiteSpace="pre-line"
            fontWeight="light"
          >
            {locationState?.title ?? <FormattedMessage {...messages.title} />}
          </Heading>
        </Box>
      )}

      <Form onSubmit={handleSubmit}>
        <TextField
          type="email"
          placeholder={formatMessage(messages.placeholderEmail)}
          {...buildFieldProps('email')}
          autoFocus
        />
        <TextField
          type="password"
          hint={
            <Body textSize="xsmall" color="grey.600">
              {formatMessage(messages.labelPasswordLimit)}
            </Body>
          }
          placeholder={formatMessage(messages.placeholderNewPassword)}
          {...buildFieldProps('newPassword')}
          noMargin
        />
        {
          // @ts-ignore This is a hack to allow adding a global error to the form. Unfortunately
          // formik does not support this out of the box, so we define the error on a "global" key
          // in formik's errors object.
          errors.global && (
            <HintMessage kind="error" size="small">
              {
                // @ts-ignore
                errors.global
              }
            </HintMessage>
          )
        }
        <Box mt={5} style={{ height: '40px ' }}>
          {!isSubmitting ? (
            <Button
              alignItems="center"
              justifyContent="center"
              fontWeight="regular"
              color="white"
              width={1}
              height={40}
              type="submit"
              data-testid="signup"
              disabled={!dirty || !isValid || reCaptchaScriptStatus !== 'ready'}
            >
              <FormattedMessage {...messages.labelSignup} />
            </Button>
          ) : (
            <Spinner />
          )}
        </Box>
        <Box mt={4}>
          <Body
            textAlign="center"
            color="grey.600"
            textSize="small"
            fontWeight="light"
          >
            <FormattedMessage
              {...messages.messageTerms}
              values={{
                terms: (
                  <Anchor href={TERMS_URL} target="_blank">
                    {formatMessage(messages.labelTermsOfUse)}
                  </Anchor>
                ),
                privacy: (
                  <Anchor href={PRIVACY_POLICY_URL} target="_blank">
                    {formatMessage(messages.labelPrivacyPolicy)}
                  </Anchor>
                ),
              }}
            />
            <br />
            <FormattedMessage
              {...messages.messageRecaptcha}
              values={{
                terms: (
                  <Anchor
                    href="https://policies.google.com/terms"
                    target="_blank"
                  >
                    {formatMessage(messages.labelTermsOfService)}
                  </Anchor>
                ),
                policy: (
                  <Anchor
                    href="https://policies.google.com/privacy"
                    target="_blank"
                  >
                    {formatMessage(messages.labelPrivacyPolicy)}
                  </Anchor>
                ),
              }}
            />
          </Body>
        </Box>
      </Form>

      <Box mt={8}>
        <Body textAlign="center" color="grey.600">
          <FormattedMessage {...messages.messageAlreadyHaveAccount} />
          <Link to={loginUrl}>
            <A>
              <FormattedMessage {...messages.labelLoginLink} />
            </A>
          </Link>
        </Body>
      </Box>
    </>
  );
}

export default SignupForm;
