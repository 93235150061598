import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import { useLocation, useHistory } from 'react-router-dom';
import { Flex } from '@rebass/grid';
import { FormattedMessage } from 'react-intl';
import { branch, compose, withProps } from 'recompose';

import Navigation from 'src/components/Navigation';
import TruncateBox from 'src/styles/TruncateBox';
import { get } from 'src/utils/accessors';
import { makeCollectionImagesSelector } from 'src/selectors/project-image';
import usePermission from 'src/hooks/use-permission';
import { returnInCase } from 'src/utils/in-case';
import { projectUrl, projectCommentsUrl } from 'src/routes/urls';
import branchMediaQuery from 'src/hocs/branch-media-query';
import { withBulkSelection } from 'src/modules/SelectableComponents';
import withFlexibleDiv from 'src/hocs/with-flexible-div';

import DeleteCollectionIcon from './DeleteCollectionIcon';
import RenameCollectionIcon from './RenameCollectionIcon';
import CollectionNavigationItemWrapper from '../styles/CollectionNavigationItemWrapper';
import messages from '../messages';

const collectionImagesSelector = makeCollectionImagesSelector();

function CollectionNavigationItem({
  collection,
  projectPublicKey,
  projectId,
  index,
  connectDropTarget,
  canManageCollections,
  isOver,
}) {
  const history = useHistory();
  const { pathname, query } = useLocation();

  const buildUrl = useMemo(
    () =>
      returnInCase({
        [pathname.includes('/comments')]: () => projectCommentsUrl,
        default: () => projectUrl,
      }),
    [pathname],
  );

  return connectDropTarget(
    <div>
      <CollectionNavigationItemWrapper isOver={isOver}>
        <Navigation.Item
          state={history.location.state}
          actions={
            canManageCollections
              ? [
                  <RenameCollectionIcon
                    collectionId={get(collection, 'id')}
                    projectPublicUrlKey={projectPublicKey}
                  />,
                  <DeleteCollectionIcon
                    collectionId={get(collection, 'id')}
                    projectPublicUrlKey={projectPublicKey}
                  />,
                ]
              : null
          }
          icon="collections"
          key={get(collection, 'id')}
          label={
            <Flex justifyContent="space-between">
              <TruncateBox width="90%">
                <span>
                  {get(collection, 'attributes.name') || (
                    <FormattedMessage {...messages.labelUntitledCollection} />
                  )}
                </span>
              </TruncateBox>
              <span>{collectionImagesSelector({ collection }).size}</span>
            </Flex>
          }
          textStyle="body"
          to={buildUrl(
            {
              collectionId: get(collection, 'id'),
              projectId,
            },
            {
              ...query,
              filter: undefined,
            },
          )}
          data-intercom-target={`collection-${index}`}
        />
      </CollectionNavigationItemWrapper>
    </div>,
  );
}

CollectionNavigationItem.propTypes = {
  collection: PropTypes.instanceOf(Map).isRequired,
  projectPublicKey: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  connectDropTarget: PropTypes.func,
  canManageCollections: PropTypes.bool.isRequired,
};

CollectionNavigationItem.defaultProps = {
  connectDropTarget: component => component,
};

const dropTargetSpecs = {
  drop(props, monitor) {
    const dragIds = props.selectionSize
      ? Array.from(props.getSelection())
      : [monitor.getItem().id];

    props.addImagesToCollectionHandler(props.collection, dragIds);
  },
};

export default compose(
  withProps(() => {
    const { canSelect, canManageCollections } = usePermission();
    return { canSelect, canManageCollections };
  }),
  branchMediaQuery(
    { minWidth: 0 },
    branch(
      /**
       * In case the user has select permission render selectable functionality
       * otherwise just a div that receives margin and width (because of image grid)
       */
      ({ canSelect, canManageCollections }) =>
        canSelect && canManageCollections,
      withBulkSelection,
    ),
    withFlexibleDiv,
  ),
  branch(
    ({ canSelect, canManageCollections }) => canSelect && canManageCollections,
    DropTarget('image', dropTargetSpecs, (connectDnD, monitor) => ({
      connectDropTarget: connectDnD.dropTarget(),
      isOver: monitor.isOver(),
    })),
  ),
)(CollectionNavigationItem);
