import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';
import { textStyle, typography } from 'src/modules/prisma';

const FormLegend = styled.legend.attrs({
  fontFamily: 'sansSerif',
  textStyle: 'subheading.regular',
})`
  ${textStyle};
  ${typography};
  color: ${themeGet('colors.black')};
  float: left;
  margin-bottom: ${themeGet('space.6')}px;
  padding: 0;
  width: 100%;
`;

export default FormLegend;
