import React from 'react';
import PropTypes from 'prop-types';
import {
  NotificationsActive as NotificationsActiveSvg,
  NotificationsOff as NotificationsOffSvg,
} from '@styled-icons/material';

import { Icon } from 'src/modules/prisma';

const FollowingBell = ({ following }) => {
  if (following === null) {
    return <div style={{ width: 20 }} />;
  }

  return following ? (
    <Icon
      color="primary"
      display="flex"
      size="medium"
      type={<NotificationsActiveSvg />}
    />
  ) : (
    <Icon
      color="muted"
      display="flex"
      size="medium"
      type={<NotificationsOffSvg />}
    />
  );
};

FollowingBell.propTypes = {
  following: PropTypes.bool,
};

FollowingBell.defaultProps = {
  following: null,
};

export default FollowingBell;
