import styled from 'styled-components/macro';

import { color, borderRadius, position } from 'src/modules/prisma';

const ProgressBarContainer = styled.div`
  display: flex;
  height: ${props => props.size}px;
  overflow: hidden;
  width: 100%;
  ${borderRadius};
  ${color};
  ${position};
`;

ProgressBarContainer.defaultProps = {
  color: 'transparent',
  size: 2,
};

export default ProgressBarContainer;
