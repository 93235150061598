import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import EmptyStateBox from 'src/components/EmptyStateBox';
import EmtpyProjectIllustrationSvg from 'src/assets/EmtpyProjectIllustrationSvg';

import AddImageButton from './AddImageButton';
import messages from '../messages';

const EmptyProjectBox = ({ showAddImage }) => {
  return (
    <EmptyStateBox
      action={showAddImage ? <AddImageButton /> : undefined}
      graphic={<EmtpyProjectIllustrationSvg />}
      message={<FormattedMessage {...messages.messageEmptyProject} />}
      title={<FormattedMessage {...messages.titleEmptyProject} />}
      dashed
    />
  );
};

EmptyProjectBox.propTypes = {
  showAddImage: PropTypes.bool,
};

EmptyProjectBox.defaultProps = {
  showAddImage: false,
};

export default EmptyProjectBox;
