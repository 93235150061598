import {
  color as ssColor,
  compose,
  get,
  grid as ssGrid,
  space,
  system,
  layout as ssLayout,
  typography as ssTypography,
  variant,
} from 'styled-system';
import { css } from 'styled-components/macro';
import { transparentize } from 'polished';

/**
 * `gap` adds margins to siblings of other gap boxes. If there are no siblings
 * it adds nothing.
 *
 * It works similar to the space helper of styled-system:
 * https://github.com/jxnblk/styled-system/blob/master/docs/api.md#space
 * To avoid overlapping the library properties Box uses the same properties
 * but with a "g" at the beginning.
 *
 * Ex:
 * - with siblings:
 * <Box />
 * <Box gmt="24px" /> // has a margin-top of 24px
 *
 * - without siblings:
 * <Box gmt="24px" /> // has no margin
 *
 */
function gap(props) {
  const REG = /^[g][m][trblxy]?$/;
  const initialProps = { theme: props.theme };
  const newProps = Object.entries(props).reduce((p, [key, value]) => {
    if (REG.test(key)) {
      p[key.replace('g', '')] = value; // eslint-disable-line no-param-reassign
    }
    return p;
  }, initialProps);

  return space(newProps);
}
gap.class = 'gap-space';
gap.getClass = props => props.gapClass;
gap.propNames = [
  'gapClass',
  'gm',
  'gmt',
  'gmr',
  'gmb',
  'gml',
  'gmx',
  'gmy',
  'gp',
  'gpt',
  'gpr',
  'gpb',
  'gpl',
  'gpx',
  'gpy',
];

function truncate({ truncateAt }) {
  return (
    truncateAt &&
    css`
      ${ssLayout({ maxWidth: truncateAt })};
      display: inline-block;
      overflow: hidden;
      overflow-wrap: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
    `
  );
}
truncate.propNames = ['truncateAt'];

/**
 * COLOR
 */
const config = {
  backgroundColor: {
    property: 'backgroundColor',
    scale: 'colors',
    transform: (value, scale, props) => {
      const { bgOpacity, backgroundOpacity } = props;
      if (bgOpacity || backgroundOpacity) {
        return transparentize(
          1 - (bgOpacity || backgroundOpacity),
          get(scale, value, value),
        );
      }

      return get(scale, value, value);
    },
  },
};
config.bgColor = config.backgroundColor;
const backgroundColor = system(config);
backgroundColor.propNames = [
  ...backgroundColor.propNames,
  'backgroundOpacity',
  'bgOpacity',
];
const color = compose(ssColor, backgroundColor);
color.propNames = [...color.propNames, ...backgroundColor.propNames];

/**
 * GRID
 */
const align = system({
  alignContent: true,
  alignItems: true,
  alignSelf: true,
  justifyContent: true,
  justifyItems: true,
  justifySelf: true,
  placeContent: true,
  placeItems: true,
  placeSelf: true,
});
const grid = compose(ssGrid, align);

/**
 * LAYOUT
 */
const minSize = system({
  minSize: {
    properties: ['minWidth', 'minHeight'],
    scale: 'sizes',
  },
});
const layout = compose(ssLayout, minSize);

/**
 * TYPOGRAPHY
 */
const textDecoration = system({
  textDecoration: true,
});
const textTransform = system({
  textTransform: true,
});
const textOverflow = system({
  textOverflow: true,
});
const overflowWrap = system({
  overflowWrap: true,
});
const whiteSpace = system({
  whiteSpace: true,
});
const wordBreak = system({
  wordBreak: true,
});
const typography = compose(ssTypography, textDecoration, textTransform);

/**
 * CONTENT
 */
const content = compose(overflowWrap, textOverflow, whiteSpace, wordBreak);

/**
 * COMPOSED
 */
const common = compose(color, space);

/**
 * VARIANT
 */
const textStyle = variant({
  prop: 'textStyle',
  variants: {
    heading: {
      xxlarge: {
        fontSize: 8, // 72px
        lineHeight: 6, // 1.7em
        letterSpacing: 0, // -0.015em
      },
      xlarge: {
        fontSize: 7, // 52px
        lineHeight: 1, // 1.1em
        letterSpacing: 1, // -0.01em
      },
      large: {
        fontSize: 6, // 36px
        lineHeight: 2, // 1.2em
        letterSpacing: 2, // 0em
      },
      regular: {
        fontSize: 5, // 24px
        lineHeight: 3, // 1.3em
        letterSpacing: 3, // 0.015em
      },
      small: {
        fontSize: 2, // 16px
        lineHeight: 4, // 1.4em
        letterSpacing: 6, // 0.03em
        fontWeight: 1, // regular
      },
    },
    subheading: {
      regular: {
        fontSize: 1, // 14px
        lineHeight: 6, // 1.7em
        letterSpacing: 9, // 0.055em
        fontWeight: 1, // regular
        textTransform: 1, // uppercase
      },
    },
    body: {
      large: {
        fontSize: 3, // 18px
        lineHeight: 4, // 1.4em
        letterSpacing: 5, // 0.025em
      },
      regular: {
        fontSize: 2, // 16px
        lineHeight: 6, // 1.7em
        letterSpacing: 6, // 0.03em
        fontWeight: 0, // light (default?)
      },
      small: {
        fontSize: 1, // 14px
        lineHeight: 4, // 1.4em
        letterSpacing: 7, // 0.035em
      },
      xsmall: {
        fontSize: 0, // 12px
        lineHeight: 3, // 1.3em
        letterSpacing: 8, // 0.045em
      },
    },
    action: {
      large: {
        fontSize: 4, // 20px
        lineHeight: 0, // 1em
        letterSpacing: 4, // 0.02em
        fontWeight: 1, // regular
      },
      regular: {
        fontSize: 2, // 16px
        lineHeight: 0, // 1em
        letterSpacing: 6, // 0.03em
        fontWeight: 1, // regular
      },
      small: {
        fontSize: 1, // 14px
        lineHeight: 0, // 1em
        letterSpacing: 7, // 0.035em
        fontWeight: 1, // regular
      },
    },
  },
});

export * from 'styled-system';

// customize mixins and export
export {
  align,
  common,
  color,
  content,
  gap,
  grid,
  typography,
  backgroundColor,
  layout,
  minSize,
  textStyle,
  textDecoration,
  textTransform,
  textOverflow,
  truncate,
  overflowWrap,
  whiteSpace,
  wordBreak,
};
