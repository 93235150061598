import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';

const FileWrapper = styled.div`
  height: inherit;
  border: 0;
  background-color: ${themeGet('colors.grey.100')};
  padding: 15px;
  line-height: 0;
  position: relative;
  overflow: hidden;

  ::after {
    content: '';
    position: absolute;
    border-width: 0 37px 37px 0;
    border-style: solid;
    border-color: ${themeGet('colors.grey.100')} ${themeGet('colors.white')};
    box-shadow: -2px 2px 15px ${themeGet('colors.grey.600')};
    top: 0;
    right: 0;
  }
`;

export default FileWrapper;
