import { List } from 'immutable';

const isMergeable = a =>
  a &&
  typeof a === 'object' &&
  typeof a.mergeWith === 'function' &&
  !List.isList(a);

const mergeDeepOverwriteLists = (a, b) => {
  // If b is null, it would overwrite a, even if a is mergeable
  if (b === null) return b;

  if (isMergeable(a) && !List.isList(a)) {
    return a.mergeWith(mergeDeepOverwriteLists, b);
  }

  return b;
};

export default mergeDeepOverwriteLists;
