import React, { useEffect } from 'react';
import { Switch, Redirect, Route, RouteComponentProps } from 'react-router-dom';

import {
  isSpacePartnerLicensed,
  isSpaceOwner,
  isSpaceManager,
} from 'src/utils/accessors/space';
import paths from 'src/routes/paths';
import { RestrictedRoute } from 'src/components/RestrictedRoute';
import {
  librarySettingsSpaceGeneralUrl,
  librarySettingsSpaceMembersUrl,
} from 'src/routes/urls';
import NotFoundPage from 'src/components/NotFoundPage';
import { useCurrentUser, useSpace } from 'src/hooks/use-resource';
import { Feature, FeatureName } from 'src/containers/FeatureFlags';
import { useRealtimeJSONApi } from 'src/modules/realtime';
import { $TSFixMe } from 'src/types';
import { UserResource, SpaceResource } from 'src/types/resources';

import Document from './_document';
import SpaceGeneralPage from './general';
import SpaceMembersPage from './members';
import CreditsPage from './credits';
import IntegrationsPage from './integrations';
import SpaceSubscriptionRoutes from './subscription/_routes';
import NotificationsPage from './notifications';

const {
  LIBRARY_SETTINGS_SPACE_PATH,
  LIBRARY_SETTINGS_SPACE_GENERAL_PATH,
  LIBRARY_SETTINGS_SPACE_CREDITS_PATH,
  LIBRARY_SETTINGS_SPACE_INTEGRATIONS_PATH,
  LIBRARY_SETTINGS_SPACE_MEMBERS_PATH,
  LIBRARY_SETTINGS_SPACE_SUBSCRIPTION_PATH,
  LIBRARY_SETTINGS_SPACE_NOTIFICATIONS_PATH,
} = paths as $TSFixMe;

export default function SettingsSpaceRoutes({
  match: {
    params: { spaceId },
  },
}: RouteComponentProps<{ spaceId: string }>) {
  const user: UserResource = useCurrentUser();
  const space: SpaceResource = useSpace({
    id: spaceId,
    include: ['users.user', 'partnerLicense'],
  });

  const { subscribe: subscribeToRealtime } = useRealtimeJSONApi();

  useEffect(() => {
    const subscription = subscribeToRealtime(
      `private-resource=ws-spaces;id=${spaceId}`,
    );

    return subscription;
  }, [subscribeToRealtime, spaceId]);

  return (
    <Document>
      <Switch>
        <Route
          path={LIBRARY_SETTINGS_SPACE_PATH}
          exact
          render={({ match: { params } }) => (
            <Feature name={FeatureName.TEAM_MEMBER_INVITATION}>
              {(isEnabled: boolean) =>
                isEnabled ? (
                  <Redirect
                    to={librarySettingsSpaceMembersUrl({
                      spaceId: params.spaceId,
                    })}
                  />
                ) : (
                  <Redirect
                    to={librarySettingsSpaceGeneralUrl({
                      spaceId: params.spaceId,
                    })}
                  />
                )
              }
            </Feature>
          )}
        />
        <Route
          path={LIBRARY_SETTINGS_SPACE_MEMBERS_PATH}
          render={routeProps => (
            <Feature name={FeatureName.TEAM_MEMBER_INVITATION}>
              {(isEnabled: boolean) =>
                isEnabled ? (
                  <SpaceMembersPage {...routeProps} />
                ) : (
                  <NotFoundPage />
                )
              }
            </Feature>
          )}
        />
        <Route
          path={LIBRARY_SETTINGS_SPACE_GENERAL_PATH}
          component={SpaceGeneralPage}
        />
        <Route
          path={LIBRARY_SETTINGS_SPACE_NOTIFICATIONS_PATH}
          component={NotificationsPage}
        />
        <RestrictedRoute
          component={CreditsPage}
          fallbackComponent={NotFoundPage}
          path={LIBRARY_SETTINGS_SPACE_CREDITS_PATH}
          rule={() =>
            isSpaceOwner(space, user) && !isSpacePartnerLicensed(space)
          }
        />
        <RestrictedRoute
          component={IntegrationsPage}
          fallbackComponent={NotFoundPage}
          path={LIBRARY_SETTINGS_SPACE_INTEGRATIONS_PATH}
          rule={() => isSpaceOwner(space, user) || isSpaceManager(space, user)}
        />
        <RestrictedRoute
          component={SpaceSubscriptionRoutes}
          fallbackComponent={NotFoundPage}
          path={LIBRARY_SETTINGS_SPACE_SUBSCRIPTION_PATH}
          rule={() => isSpaceOwner(space, user)}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Document>
  );
}
