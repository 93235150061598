import { useReducer, useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { stringify as stringifyQueryString } from 'qs';
import { Filters } from './types';

const initialValue: Filters = {
  all: [],
};

function reducer(
  state: Filters,
  action: {
    type: string;
    data: { name: string; value: string };
  },
) {
  const { type, data } = action;
  switch (type) {
    case 'add-filter': {
      const filter = { [data.name]: [data.value] };
      return {
        all: [
          // remove existing filter
          ...state.all.filter(innerFilter => !innerFilter[data.name]),
          // add new filter
          filter,
        ],
      };
    }
    case 'remove-filter': {
      const existingFilter = state.all.find(filter => filter[data.name]);
      if (!existingFilter || !existingFilter[data.name].includes(data.value)) {
        return state;
      }

      return {
        all: [
          // remove existing filter
          ...state.all.filter(filter => !filter[data.name]),
          // remove filter value
          ...(existingFilter[data.name].length === 1
            ? []
            : [
                {
                  [data.name]: existingFilter[data.name].filter(
                    value => value !== data.value,
                  ),
                },
              ]),
        ],
      };
    }
    default:
      return state;
  }
}

export default function useFilters(
  qFilters:
    | {
        [index: string]: string | string[];
      }
    | undefined = { type: 'project' },
): [
  Filters,
  (name: string, value: string) => void,
  (name: string, value: string) => void,
] {
  const parsedInitialValue = useMemo(() => {
    return qFilters
      ? {
          all: Object.entries(qFilters).map(([key, value]) => {
            return { [key]: Array.isArray(value) ? value : [value] };
          }),
        }
      : initialValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [filters, dispatch] = useReducer(reducer, parsedInitialValue);
  const { pathname, query } = useLocation() as {
    pathname: string;
    query?: { q: string; qFilters: string };
  };
  const history = useHistory();

  useEffect(() => {
    const parsedFilters = filters.all.reduce((acc, filter) => {
      Object.entries(filter).forEach(([filterKey, filterValue]) => {
        acc[filterKey] = filterValue;
      });
      return acc;
    }, {});

    return history.push(
      `${pathname}?${stringifyQueryString(
        {
          ...query,
          qFilters: parsedFilters,
        },
        { encodeValuesOnly: true, arrayFormat: 'comma' },
      )}`,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const addFilter = useCallback(
    (name: string, value: string) => {
      dispatch({
        type: 'add-filter',
        data: {
          name,
          value,
        },
      });
    },
    [dispatch],
  );
  const removeFilter = useCallback(
    (name: string, value: string) => {
      dispatch({
        type: 'remove-filter',
        data: {
          name,
          value,
        },
      });
    },
    [dispatch],
  );

  return [filters, addFilter, removeFilter];
}
