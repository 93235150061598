import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon, TruncateText, CustomPropTypes, warning } from '@picter/prisma';

import { Body, Box } from 'src/modules/prisma';

import DropdownItemButton from '../styles/DropdownItemButton';
import DropdownItemLink from '../styles/DropdownItemLink';

const DropdownItem = ({
  active,
  disabled,
  footer,
  hint,
  large,
  icon,
  label,
  link,
  size,
  shortcut,
  ...props
}) => {
  if (large) {
    warning(
      'Dropdownlarge',
      'Dropdown` property `large` has been deprecated. ' +
        'Use `size="large"` instead.',
    );
  }

  const DropdownItemComponent = link ? DropdownItemLink : DropdownItemButton;
  let itemIcon;

  switch (typeof icon) {
    case 'string':
      itemIcon = <Icon type={icon} color="inherit" />;
      break;
    case 'number':
      itemIcon = <Icon type={icon} color="inherit" size="small" />;
      break;
    default:
      itemIcon = icon;
      break;
  }

  return (
    <DropdownItemComponent
      {...props}
      className={classNames({ active, disabled })}
      active={active}
      disabled={disabled}
      footer={footer}
      size={large ? 'large' : size}
    >
      {itemIcon}
      <Box display="flex" justifyContent="space-between">
        <Box>
          <TruncateText>{label}</TruncateText>
          {hint && <TruncateText is="small">{hint}</TruncateText>}
        </Box>
        {shortcut && (
          <Box
            ml={4}
            py={0}
            px={1}
            border="1px solid"
            borderRadius="3px"
            borderColor="grey.300"
          >
            <Body textSize="xsmall" whiteSpace="nowrap" color="muted">
              {shortcut}
            </Body>
          </Box>
        )}
      </Box>
    </DropdownItemComponent>
  );
};

DropdownItem.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  footer: PropTypes.bool,
  hint: PropTypes.node,
  icon: PropTypes.oneOfType([
    CustomPropTypes.elementOf(Icon),
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  large: PropTypes.bool,
  link: PropTypes.bool,
  size: PropTypes.oneOf(['normal', 'large', 'auto']),
  shortcut: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

DropdownItem.defaultProps = {
  active: false,
  disabled: false,
  footer: false,
  hint: null,
  icon: null,
  large: false,
  link: false,
  size: 'normal',
  shortcut: null,
};

export default DropdownItem;
