import React from 'react';
import PropTypes from 'prop-types';

import ImagePreviewPlaceholder from 'src/components/PreviewPlaceholder';
import Image from '../styles/ImageCardImage';

const ImageCardImage = ({ alt, src, type, isNonVisual }) => {
  if (!isNonVisual) {
    return <Image alt={alt} src={src} />;
  }
  // Uses placeholder for non visual files
  return <ImagePreviewPlaceholder type={type} />;
};

ImageCardImage.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isNonVisual: PropTypes.bool.isRequired,
};

export default ImageCardImage;
