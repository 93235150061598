import { defineMessages } from 'react-intl';

export default defineMessages({
  labelApprovedThis: {
    id: 'FileProofingIcon.labelApprovedThis',
    defaultMessage: 'Approved this',
  },
  labelCollections: {
    id: 'FileProofingIcon.labelCollections',
    defaultMessage: 'Collections',
  },
  labelComments: {
    id: 'FileProofingIcon.labelComments',
    defaultMessage: 'Comments',
  },
  labelApprove: {
    id: 'FileProofingIcon.labelApprove',
    defaultMessage: 'Approve',
  },
  labelRejectedBy: {
    id: 'FileProofingIcon.labelRejectedBy',
    defaultMessage: 'Rejected by {user}',
  },
});
