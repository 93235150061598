import React from 'react';
import { FormattedMessage, FormattedNumberParts } from 'react-intl';
import { Redeem as RedeemSvg } from '@styled-icons/material';
import { GeneralLayout, Heading } from '@picter/prisma';
import { Link, useParams } from 'react-router-dom';

import {
  Anchor,
  Body,
  Box,
  Grid,
  Icon,
  Panel,
  Text,
  allowlistProps,
} from 'src/modules/prisma';
import { useSpace } from 'src/hooks/use-resource';
import { librarySettingsSpaceSubscriptionUrl } from 'src/routes/urls';
import { tGet } from 'src/utils/accessors';

import Layout from './_layout';
import messages from './_messages';
import ReferralLink, { useReferralLink } from './_components/ReferralLink';
import ReferralsCreditBalance from './_components/ReferralsCreditBalance';
import { SubscriptionStatus } from '../subscription/_components/SubscriptionService';

export default function CreditsPage() {
  const { spaceId } = useParams<{ spaceId: string }>();
  const space = useSpace({ id: spaceId });
  const subscriptionStatus = tGet(space, 'attributes', 'subscriptionStatus');
  const referralLink = useReferralLink({ spaceId });
  const isSubscriptionActive = [
    SubscriptionStatus.Active,
    SubscriptionStatus.NonRenewing,
    SubscriptionStatus.Paused,
  ].includes(subscriptionStatus);

  return (
    <Layout>
      <Layout.Top>
        <GeneralLayout.Top.AlignLeft>
          <Heading tag="h1" fontWeight="light" textSize="regular">
            <FormattedMessage {...messages.titleCreditsPage} />
          </Heading>
        </GeneralLayout.Top.AlignLeft>
      </Layout.Top>
      <Layout.Content>
        <section>
          <Heading tag="h2" fontWeight="light" textSize="large">
            <FormattedMessage {...messages.titleInviteReferral} />
          </Heading>
          <Box mt={5}>
            <Box
              p={0}
              m={0}
              mt={5}
              tag="ul"
              width={[1, 1, 1, 3 / 4, 3 / 4, 1 / 2]}
            >
              <Grid
                tag="li"
                display="grid"
                gridTemplateColumns="20px 1fr"
                gridGap={3}
                gmt={5}
              >
                <Icon color="grey.600" type={<RedeemSvg />} />
                <Box mt={-1}>
                  <Body fontWeight="light" textSize="large">
                    <FormattedMessage
                      {...messages.messageAmountOfCreditPerSubscription}
                      values={{
                        creditAmount: (
                          <FormattedNumberParts
                            key={spaceId}
                            style="currency"
                            currency="EUR"
                            value={
                              tGet(referralLink, 'attributes', 'reward') ?? 0
                            }
                          >
                            {parts => (
                              <>
                                {parts[1].value}
                                {parts[0].value}
                              </>
                            )}
                          </FormattedNumberParts>
                        ),
                        bold: (...chunks: unknown[]) => (
                          <Text fontWeight="strong">{chunks}</Text>
                        ),
                      }}
                    />
                  </Body>
                </Box>
              </Grid>
              <Grid
                tag="li"
                display="grid"
                gridTemplateColumns="20px 1fr"
                gridGap={3}
                gmt={5}
              >
                <Icon color="grey.600" type={<RedeemSvg />} />
                <Box mt={-1}>
                  <Body fontWeight="light" textSize="large">
                    <FormattedMessage
                      {...messages.messageAmountOfDiscountToSubscriber}
                      values={{
                        discountPercentage: 50,
                        bold: (...chunks: unknown[]) => (
                          <Text fontWeight="strong">{chunks}</Text>
                        ),
                      }}
                    />
                  </Body>
                </Box>
              </Grid>
            </Box>
          </Box>
          <Box mt={5}>
            {isSubscriptionActive ? (
              <ReferralLink
                urlKey={tGet(referralLink, 'attributes', 'urlKey')}
              />
            ) : (
              <Panel
                backgroundColor="primary"
                backgroundOpacity={0.04}
                py={4}
                px={5}
              >
                <Body color="info">
                  <FormattedMessage
                    {...messages.messageCreditsOnlyForActiveSubscription}
                    values={{
                      subscription: (...chunks: unknown[]) => (
                        <Anchor
                          color="info"
                          fontWeight="regular"
                          tag={allowlistProps(Link, [
                            'children',
                            'className',
                            'to',
                          ])}
                          textDecoration="underline"
                          textStyle="action.regular"
                          to={librarySettingsSpaceSubscriptionUrl({ spaceId })}
                        >
                          {chunks}
                        </Anchor>
                      ),
                    }}
                  />
                </Body>
              </Panel>
            )}
          </Box>
        </section>
        <Box mt={8} />
        <ReferralsCreditBalance
          referrals={tGet(referralLink, 'relationships', 'referrals')}
          rewardForReferral={tGet(referralLink, 'attributes', 'reward')}
          successfulReferrals={tGet(
            referralLink,
            'attributes',
            'successfulReferrals',
          )}
        />
      </Layout.Content>
    </Layout>
  );
}
