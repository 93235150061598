import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { get } from 'src/utils/accessors';

import { featuresListToFeaturesObject } from '../utils';
import FlagsProvider from './FlagsProvider';

const planFeaturesPath = 'relationships.plan.relationships.features';
const extraFeaturePath = 'relationships.extraFeatures';

function SpaceFlagsProvider({ children, space }) {
  const features = useMemo(
    () =>
      space
        ? {
            ...featuresListToFeaturesObject(get(space, planFeaturesPath)),
            ...featuresListToFeaturesObject(get(space, extraFeaturePath)),
          }
        : {},
    [space],
  );

  return <FlagsProvider features={features}>{children}</FlagsProvider>;
}

SpaceFlagsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  space: PropTypes.instanceOf(Map),
};

SpaceFlagsProvider.defaultProps = {
  space: undefined,
};

export default SpaceFlagsProvider;
