import React, { memo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Delete as DeleteSvg,
  Edit as EditSvg,
  MoreVert as MoreVertSvg,
} from '@styled-icons/material';

import {
  Body,
  Box,
  Heading,
  Icon,
  Spacer,
  Text,
  Tooltip,
} from 'src/modules/prisma';
import ActionsDropdown from 'src/components/ActionsDropdown';
import Avatar from 'src/components/Avatar';
import { formattedMessage } from 'src/utils/app-prop-types';
import TruncateBox from 'src/styles/TruncateBox';
import useModal from 'src/hooks/use-modal';
import useFormatMessage from 'src/hooks/use-format-message';

import { CommentAction } from '../constants';
import CommentContainer from '../styles/CommentContainer';
import CommentDeleteModal from './CommentDeleteModal';
import CommentEditForm from './CommentEditForm';
import CommentDropdown from '../styles/CommentDropdown';
import CommentHeader from '../styles/CommentHeader';
import CommentSection from '../styles/CommentSection';
import MarkupRenderer from './MarkupRenderer';
import messages from '../messages';
import CommentPublicationDate from './CommentPublicationDate';

function CommentReply({
  actions,
  author: { email: authorEmail, name: authorName },
  className,
  content,
  edited,
  id,
  published,

  onDelete,
  onEdit,
  ...props
}) {
  const formatMessage = useFormatMessage();
  const [editing, setEditing] = useState(false);
  const moreTooltipRef = useRef();
  const [
    commentDeleteModal,
    { open: openCommentDeleteModal, close: closeCommentDeleteModal },
  ] = useModal(
    <CommentDeleteModal
      onConfirm={() => {
        onDelete(id);
        closeCommentDeleteModal();
      }}
    />,
  );
  const messageDeletedUser = formatMessage(messages.messageDeletedUser);

  return (
    <>
      <CommentContainer {...props} className="comment-reply">
        <CommentHeader>
          <Avatar
            email={authorEmail}
            name={authorName || authorName || messageDeletedUser}
            size="small"
            deleted={!authorEmail && !authorName}
          />
          <Spacer sx="2" />
          <TruncateBox maxWidth="50%">
            <Heading
              tag="h1"
              textSize="small"
              title={authorName || authorEmail || messageDeletedUser}
            >
              {authorName || authorEmail || messageDeletedUser}
            </Heading>
          </TruncateBox>
          <Spacer sx="2" />
          <CommentPublicationDate date={published} />
          {actions.length > 0 && (
            <>
              <Spacer sx="auto" />
              <CommentDropdown>
                <ActionsDropdown
                  page="project"
                  toggle={({ onClick }) => {
                    return (
                      <Tooltip ref={moreTooltipRef}>
                        <Tooltip.Reference>
                          <Icon
                            color="grey.600"
                            size="medium"
                            type={<MoreVertSvg />}
                            onClick={event => {
                              moreTooltipRef.current.hide();
                              onClick(event);
                            }}
                          />
                        </Tooltip.Reference>
                        <Tooltip.Message>
                          <FormattedMessage {...messages.labelMore} />
                        </Tooltip.Message>
                      </Tooltip>
                    );
                  }}
                >
                  <ActionsDropdown.Group entity="comment">
                    {actions.includes(CommentAction.EDIT) && (
                      <ActionsDropdown.Item
                        action="edit"
                        icon={<EditSvg />}
                        label={
                          <FormattedMessage {...messages.labelEditComment} />
                        }
                        onClick={() => setEditing(true)}
                      />
                    )}
                    {actions.includes(CommentAction.DELETE) && (
                      <ActionsDropdown.Item
                        action="delete"
                        icon={<DeleteSvg />}
                        label={
                          <FormattedMessage {...messages.labelDeleteComment} />
                        }
                        onClick={openCommentDeleteModal}
                      />
                    )}
                  </ActionsDropdown.Group>
                </ActionsDropdown>
              </CommentDropdown>
            </>
          )}
        </CommentHeader>

        <CommentSection>
          {editing ? (
            <Box flex="1">
              <CommentEditForm
                onCancel={() => setEditing(false)}
                onSubmit={async text => {
                  if (text.trim() === '') {
                    openCommentDeleteModal();
                  } else {
                    await onEdit(id, text);
                  }
                  setEditing(false);
                }}
                value={content}
                autoFocus
              />
            </Box>
          ) : (
            <Box ml={6}>
              <Text
                fontFamily="sansSerif"
                lineHeight={4}
                overflowWrap="break-word"
                tag="p"
                textStyle="body.regular"
                wordBreak="break-word"
                whiteSpace="pre-wrap"
              >
                <MarkupRenderer source={content} />
                {edited && (
                  <Body
                    data-testid="edited-comment"
                    color="grey.600"
                    tag="span"
                    textSize="small"
                  >
                    <FormattedMessage {...messages.labelEditedComment} />
                  </Body>
                )}
              </Text>
            </Box>
          )}
        </CommentSection>
      </CommentContainer>
      {commentDeleteModal}
    </>
  );
}

CommentReply.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(CommentAction))),
  author: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
  }),
  className: PropTypes.string,
  content: formattedMessage.isRequired,
  edited: PropTypes.bool,
  id: PropTypes.string,
  published: PropTypes.number,

  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

CommentReply.defaultProps = {
  actions: [],
  author: {},
  className: undefined,
  edited: false,
  id: undefined,
  published: undefined,

  onDelete: f => f,
  onEdit: f => f,
};

export default memo(CommentReply);
