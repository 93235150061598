/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

import paths from 'src/routes/paths';
import { viewModeTypes } from 'src/routes/constants';

const { LIBRARY_PROJECT_PATH } = paths;

const { REACT_APP_API_URL } = process.env;
const WORKSPACE_ENDPOINT = `${REACT_APP_API_URL}/app-workspace/`;

export function useTrackPublicLinkView() {
  const {
    params: { projectId, viewMode },
  } = useRouteMatch(LIBRARY_PROJECT_PATH);
  const publicLinksViewModes = [
    viewModeTypes.PRESENT,
    viewModeTypes.REQUEST_FILES,
    viewModeTypes.REVIEW,
  ];

  useEffect(() => {
    if (publicLinksViewModes.includes(viewMode)) {
      fetch(
        new URL(
          `ws-public-links/${projectId}/viewed`,
          WORKSPACE_ENDPOINT,
        ).toString(),
        {
          credentials: 'include',
          method: 'POST',
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
/* eslint-enable import/prefer-default-export */
