import { List } from 'immutable';
import pick from 'lodash/pick';
import camelCase from 'lodash/camelCase';
import { get, tGet } from 'src/utils/accessors';
import {
  DELIMITER as SORT_BY_DELIMITER,
  builder as sortByBuilder,
} from 'src/modules/sorter';

export const getSpacePlan = (space, defaultValue) =>
  get(space, 'relationships.plan', defaultValue);

export const hasSpaceScope = (space, user, scope, defaultValue = false) => {
  const spaceCurrentUser = get(space, 'relationships.users', List()).find(
    item => get(item, 'relationships.user.id') === get(user, 'id'),
  );

  return spaceCurrentUser
    ? get(spaceCurrentUser, 'attributes.scope') === scope
    : defaultValue;
};

export const isSpaceOwner = (space, user, defaultValue = false) =>
  hasSpaceScope(space, user, 'owner', defaultValue);

export const isSpaceManager = (space, user, defaultValue = false) =>
  hasSpaceScope(space, user, 'manager', defaultValue);

export function isSpacePartnerLicensed(space) {
  const partnerLicense = tGet(space, 'relationships', 'partnerLicense');
  return Boolean(tGet(partnerLicense, 'id'));
}

/**
 * SORT ACCESSORS
 */
export const SPACE_SORT_QUERY_KEY = 'sort_by';
export const getSpaceSortLocalStorageKey = id => `picter-sort-space-${id}`;
export const getFolderSortLocalStorageKey = id => `picter-sort-folder-${id}`;

export const SpaceSortBy = sortByBuilder({
  CREATED_AT: 'created_at',
  UPDATED_AT: 'updated_at',
  NAME: 'name',
});

export function parseSpaceSortBy(sortBy) {
  const [snakeCaseAttr, operator] = sortBy.split(SORT_BY_DELIMITER);
  return [camelCase(snakeCaseAttr), operator];
}

export function getSpaceSortBy({ query, spaceId, folderId }) {
  return (
    get(query, SPACE_SORT_QUERY_KEY) ||
    (folderId &&
      window.localStorage.getItem(getFolderSortLocalStorageKey(folderId))) ||
    window.localStorage.getItem(getSpaceSortLocalStorageKey(spaceId)) ||
    SpaceSortBy.UPDATED_AT.DESC()
  );
}

export function pickSpaceSortBy(query) {
  return pick(query, SPACE_SORT_QUERY_KEY);
}

/**
 * VIEW ACCESSORS
 */
export const SPACE_VIEW_QUERY_KEY = 'view';
export const SPACE_VIEW_SESSIONSTORAGE_KEY = 'space_view_type';

export const SpaceViewTypes = {
  GRID: 'grid',
  LIST: 'list',
};
