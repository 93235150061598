import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Route, useParams } from 'react-router-dom';

import paths from 'src/routes/paths';
import { $TSFixMe } from 'src/types';
import { SpaceResource } from 'src/types/resources';
import { useSpace } from 'src/hooks/use-resource';
import withSuspenseLoader from 'src/hocs/with-suspense-loader';
import { tGet } from 'src/utils/accessors';
import { librarySettingsSpaceSubscriptionUrl } from 'src/routes/urls';

import UpgradeModal from './UpgradeModal';

const { LIBRARY_SETTINGS_SPACE_MEMBERS_PATH } = paths as $TSFixMe;

type AnyProps = {
  open: boolean;
  [key: string]: unknown;
};

function TeamLimitModal(props: AnyProps) {
  const { spaceId } = useParams<{ spaceId: string }>();
  const space: SpaceResource = useSpace({
    id: spaceId,
    include: ['plan.features'],
  });
  const plan = tGet(space, 'relationships', 'plan');
  const features = tGet(plan, 'relationships', 'features');
  const teamFeature = features.find(
    feature => tGet(feature, 'attributes', 'name') === 'team',
  );
  const teamFeatureConfig =
    teamFeature && tGet(teamFeature, 'attributes', 'config');
  const maxSeats =
    teamFeatureConfig && (tGet(teamFeatureConfig, 'maxSeats') as number);
  const upgradeLink = librarySettingsSpaceSubscriptionUrl({ spaceId });

  return (
    <UpgradeModal
      {...props}
      name="Team limit reached modal"
      title={
        <FormattedMessage
          id="TeamLimitModal.titleTeamLimit"
          defaultMessage="Maximum team members"
        />
      }
      message={
        <FormattedHTMLMessage
          id="TeamLimitModal.messaTeamLimitReached"
          defaultMessage={`Sorry! You've reached <b>{max} team members on your account</b>. Upgrade to get more seats.`}
          values={{ max: maxSeats }}
        />
      }
      upgradeLink={upgradeLink}
    />
  );
}

function TeamLimitModalWrapper({ open, ...props }: AnyProps) {
  if (!open) {
    return null;
  }

  return (
    <Route
      path={LIBRARY_SETTINGS_SPACE_MEMBERS_PATH}
      render={() => <TeamLimitModal {...props} open={open} />}
    />
  );
}

export default withSuspenseLoader({
  ErrorComponent: null,
  LoadingComponent: null,
})(TeamLimitModalWrapper);
