import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Portal } from '@picter/prisma';

import { withBulkSelection } from 'src/modules/SelectableComponents';
import ImageSelectionTopbar from 'src/components/ImageSelectionTopbar';
import { useProjectImage } from 'src/hooks/use-resource';
import usePermission from 'src/hooks/use-permission';

const SelectionTopbar = ({
  selectionSize,
  selectAll,
  clear,
  projectId,
  projectPublicKey,
  getSelection,
  onClickDownloadProject,
}) => {
  const selectedItemsIds = useMemo(
    () =>
      getSelection().map(id => {
        const [, imageIdIfCollection] = id.split(':');
        if (imageIdIfCollection) {
          return imageIdIfCollection;
        }
        return id;
      }),
    [getSelection],
  );

  const images = useProjectImage(
    {
      id: selectedItemsIds,
      include: ['approvals.user'],
    },
    {
      publicKey: projectPublicKey,
      querySerializers: {
        publicKey: p => `publicKey=${p}`,
      },
      request: false,
    },
  );

  const { canSelect } = usePermission();

  // When the id doest exsit, selection returns null on the id index
  const parsedImages = useMemo(() => images.filter(image => image), [images]);

  return selectionSize && canSelect ? (
    <Portal>
      <ImageSelectionTopbar
        selectedItems={parsedImages}
        selectAll={selectAll}
        deselectAll={clear}
        closeSelection={clear}
        projectId={projectId}
        projectPublicKey={projectPublicKey}
        onClickDownloadProject={onClickDownloadProject}
      />
    </Portal>
  ) : null;
};

SelectionTopbar.propTypes = {
  clear: PropTypes.func.isRequired,
  selectAll: PropTypes.func.isRequired,
  selectionSize: PropTypes.number.isRequired,
  projectId: PropTypes.string.isRequired,
  projectPublicKey: PropTypes.string.isRequired,
  getSelection: PropTypes.func.isRequired,
  onClickDownloadProject: PropTypes.func,
};

SelectionTopbar.defaultProps = {
  onClickDownloadProject: undefined,
};

export default withBulkSelection(SelectionTopbar);
