import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
// import { SelectField } from '@picter/prisma';

import { Checkbox, Text, Box } from 'src/modules/prisma';

const NotificationItemInput = ({
  name,
  label,
  message,
  value,
  handleChange,
  defaultValue,
}) => {
  const onChangeCheckbox = useCallback(
    e => {
      if (defaultValue) {
        handleChange(name, e.target.checked ? defaultValue : null);
      } else {
        handleChange(name, e.target.checked);
      }
    },
    [name, defaultValue, handleChange],
  );
  return (
    <Box
      display="flex"
      flexDirection={['column', null, 'row']}
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box mr={4}>
            <Checkbox
              checked={!!value}
              onChange={onChangeCheckbox}
              size="large"
              id={name}
            />
          </Box>
          <Box display="flex" flexDirection="column" tag="label" htmlFor={name}>
            <Text style={{ lineHeight: '1.4em' }}>{label}</Text>
            <Text color="grey.600" style={{ lineHeight: '1.4em' }}>
              {message}
            </Text>
          </Box>
        </Box>
      </Box>
      {/* <Box width={[1, 1 / 3]} mt={[4, 0]}>
      <SelectField ariaLabel="mentions" />
</Box> */}
    </Box>
  );
};

NotificationItemInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  message: PropTypes.node.isRequired,
  value: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
};

NotificationItemInput.defaultProps = {
  defaultValue: null,
};

export default NotificationItemInput;
