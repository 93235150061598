import React from 'react';
import PropTypes from 'prop-types';
import { TrackerEvent } from '@picter/tracker';

function TrackSharingIndicatorClick({ children, trackClick }) {
  return (
    <TrackerEvent name="Sharing indicator" trackClick={trackClick}>
      {children}
    </TrackerEvent>
  );
}

TrackSharingIndicatorClick.defaultProps = {
  trackClick: true,
};

TrackSharingIndicatorClick.propTypes = {
  children: PropTypes.element.isRequired,
  trackClick: PropTypes.bool,
};

export default TrackSharingIndicatorClick;
