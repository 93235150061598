import styled from 'styled-components/macro';

import { Box } from 'src/modules/prisma';

const ShareFlowStepContainer = styled(Box).attrs({
  alignItems: 'center',
  display: 'inline-flex',
  flexDirection: 'column',
  px: [0, 5, null, null, 6, 7],
  width: 1 / 3,
})`
  max-height: 290px;
  position: relative;
  text-align: center;
`;

export default ShareFlowStepContainer;
