import { defineMessages } from 'react-intl';

export default defineMessages({
  messageError: {
    id: 'FormSection.messageError',
    defaultMessage: 'Error',
  },
  messageSaved: {
    id: 'FormSection.messageSaved',
    defaultMessage: 'Saved',
  },
  messageSaving: {
    id: 'FormSection.messageSaving',
    defaultMessage: 'Saving...',
  },
});
