import { createSelector } from 'reselect';
import { List, Map } from 'immutable';

import { get } from 'src/utils/accessors';

export const permissionRank = [
  'owner',
  'manager',
  'editor',
  'member',
  'viewer',
];

const projectSelector = ({ project }) => project || Map();
const userSelector = ({ user }) => user;
const scopeSelector = (_, scope) => scope;

const userScopeSelector = createSelector(
  projectSelector,
  userSelector,
  (project, currentUser) => {
    if (!currentUser || currentUser.isEmpty()) {
      return { spaceScope: undefined, projectScope: undefined };
    }

    const isSameUser = user =>
      get(user, 'relationships.user.id') === get(currentUser, 'id');

    const getUserFrom = path =>
      get(project, path, List()).find(isSameUser, undefined, Map());

    const getScopeFrom = path => get(getUserFrom(path), 'attributes.scope');

    // if user is attached to the project'
    const projectScope = getScopeFrom('relationships.users');
    // if user is attached to the space
    const spaceScope = getScopeFrom('relationships.space.relationships.users');

    return {
      spaceScope,
      projectScope,
    };
  },
);

export const makePermissionSelector = () =>
  createSelector(
    projectSelector,
    scopeSelector,
    userScopeSelector,
    (project, scope, userScope) => {
      const publicScope = get(project, 'attributes.publicUrlScope');

      // Order scopes by priority
      const orderedScopeArr = [userScope.projectScope, publicScope]
        .filter(i => i)
        .sort((a, b) => permissionRank.indexOf(a) - permissionRank.indexOf(b));

      // Gets higher value
      const projectScope = orderedScopeArr[0];

      const hasProjectScope =
        projectScope &&
        permissionRank.indexOf(projectScope) <= permissionRank.indexOf(scope);

      const hasSpaceScope =
        userScope.spaceScope &&
        permissionRank.indexOf(userScope.spaceScope) <=
          permissionRank.indexOf(scope);

      return {
        project: hasProjectScope,
        space: hasSpaceScope,
        merged: hasProjectScope || hasSpaceScope,
      };
    },
  );
