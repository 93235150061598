import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Check as CheckSvg, Remove as RemoveSvg } from '@styled-icons/material';
import classNames from 'classnames';
import { List } from 'immutable';

import ContentIcon from '../styles/ContentIcon';
import ContentIconGroup from '../styles/ContentIconGroup';

const ApprovedByUserIcon = forwardRef(
  (
    {
      active,
      boxSize,
      className,
      id,
      numberOfApprovals,
      onClick,
      size,
      ...props
    },
    ref,
  ) => {
    return (
      <ContentIconGroup
        className={className}
        id={id}
        onClick={onClick}
        role="button"
        ref={ref}
        {...props}
      >
        <ContentIcon
          backgroundColor="success"
          backgroundOpacity="0.05"
          borderColor="success"
          boxSize={boxSize}
          className={classNames('content-icon content-icon--active', {
            'content-icon--visible': active,
          })}
          color="grey.600"
          content={RemoveSvg}
          size={size}
          type={<CheckSvg />}
        />
        <ContentIcon
          backgroundColor="success"
          backgroundOpacity="0.05"
          borderColor="success"
          boxSize={boxSize}
          className="content-icon content-icon--hover"
          color="black"
          content={RemoveSvg}
          size={size}
          type={<CheckSvg />}
        />
        <ContentIcon
          backgroundColor="success"
          backgroundOpacity="0.05"
          borderColor="success"
          boxSize={boxSize}
          className="content-icon content-icon--default"
          color="success"
          content={numberOfApprovals}
          size={size}
          type={<CheckSvg />}
        />
      </ContentIconGroup>
    );
  },
);

ApprovedByUserIcon.defaultProps = {
  active: false,
  approvals: List(),
  boxSize: undefined,
  className: undefined,
  id: undefined,
  numberOfApprovals: 0,
  onClick: undefined,
  size: undefined,
};

ApprovedByUserIcon.propTypes = {
  active: PropTypes.bool,
  approvals: PropTypes.instanceOf(List),
  boxSize: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  numberOfApprovals: PropTypes.number,
  onClick: PropTypes.func,
  size: PropTypes.string,
};

export default ApprovedByUserIcon;
