import React from 'react';
import ContentLoader from 'react-content-loader';
import themeGet from '@styled-system/theme-get';
import { FormattedMessage } from 'react-intl';

import { Accordion, Box, Heading, Text } from 'src/modules/prisma';
import useTheme from 'src/hooks/use-theme';
import ProgressBar from 'src/components/ProgressBar';

import messages from '../messages';

function OnboardingChecklistPlaceholder() {
  const theme = useTheme();

  return (
    <Accordion>
      <Accordion.Item>
        <Accordion.Item.Disclosure>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            pl={4}
            pr={3}
            pt={2}
            pb={3}
          >
            <Heading color="black" textAlign="left" textSize="small">
              <FormattedMessage {...messages.messageWelcomeToPicter} />{' '}
              <Text
                color="grey.600"
                fontFamily="sansSerif"
                textStyle="heading.small"
                fontWeight="light"
              >
                <FormattedMessage {...messages.messageStepsPrepared} />
              </Text>
            </Heading>
            <ContentLoader
              speed={2}
              viewBox="0 0 100 20"
              primaryColor={themeGet('colors.grey.100')({ theme })}
              secondaryColor={themeGet('colors.grey.300')({ theme })}
              preserveAspectRatio="none"
              style={{ width: '30%', height: '20px' }}
            >
              <rect x="0" y="0" rx="0" ry="0" width="100" height="20" />
            </ContentLoader>
          </Box>
          <ProgressBar />
        </Accordion.Item.Disclosure>
      </Accordion.Item>
    </Accordion>
  );
}

export default OnboardingChecklistPlaceholder;
