import React, { useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';

import useModal from 'src/hooks/use-modal';
import ProjectDownloadModal from 'src/components/ProjectDownloadModal';
import { getReviewDoneSessionStorageKey } from 'src/utils/accessors/project';
import { createComment } from 'src/actions/comments';
import { useCurrentUser } from 'src/hooks/use-resource';
import { useToriiActions } from 'src/modules/torii';
import { get } from 'src/utils/accessors';

import { useOnClickInfoButton } from '../../../_shared/hooks/handlers';

const useHandlers = props => {
  const { project, images } = props;

  const [projectDownloadModal, projectDownloadModalState] = useModal(
    <ProjectDownloadModal project={project} images={images} />,
  );

  const onClickInfoButton = useOnClickInfoButton();

  return {
    onClickInfoButton,
    projectDownloadModalState,
    projectDownloadModal,
  };
};

export function useCreateReviewDoneComment({ project }) {
  const {
    params: { projectId, viewMode },
  } = useRouteMatch();
  const user = useCurrentUser();
  const reviewDoneSessionStorageKey = getReviewDoneSessionStorageKey(projectId);
  const { create, localUpdateRelationship } = useToriiActions();

  return useCallback(
    async text => {
      sessionStorage.setItem(reviewDoneSessionStorageKey, true);

      try {
        await createComment(
          {
            projectId: get(project, 'id'),
            projectPublicUrlKey: projectId,
            text,
            user,
            viewMode,
          },
          { create, localUpdateRelationship },
        );
      } catch (_) {
        sessionStorage.removeItem(reviewDoneSessionStorageKey);
      }
    },
    [
      project,
      projectId,
      viewMode,
      user,
      create,
      localUpdateRelationship,
      reviewDoneSessionStorageKey,
    ],
  );
}

export function useOnClickReviewDone({
  liteUserRegisterModalState,
  reviewDoneModalState,
}) {
  const user = useCurrentUser();

  return useCallback(() => {
    if (!user || user.isEmpty()) {
      liteUserRegisterModalState.open();
    } else {
      reviewDoneModalState.open();
    }
  }, [user, liteUserRegisterModalState, reviewDoneModalState]);
}

export default useHandlers;
