import React from 'react';
import PropTypes from 'prop-types';
import { MediaQuery } from '@picter/prisma';
import { trackerShape } from '@picter/tracker';

import LayoutDesktop from './LayoutDesktop';
import LayoutMobile from './LayoutMobile';

const Layout = ({ hasAccessToAllFiles, tracker, withSidebar }) => {
  return (
    <MediaQuery query={{ minWidth: 0 }}>
      {matches =>
        matches ? (
          <LayoutDesktop tracker={tracker} withSidebar={withSidebar} />
        ) : (
          <LayoutMobile
            tracker={tracker}
            withSidebar={withSidebar}
            hasAccessToAllFiles={hasAccessToAllFiles}
          />
        )
      }
    </MediaQuery>
  );
};

Layout.propTypes = {
  hasAccessToAllFiles: PropTypes.bool.isRequired,
  withSidebar: PropTypes.bool.isRequired,
  tracker: trackerShape.isRequired,
};

export default Layout;
