import lodashGet from 'lodash/get';
import lodashHas from 'lodash/has';
import lodashSet from 'lodash/set';
import { $TSFixMe } from 'src/types';

export function get(
  object: $TSFixMe,
  path: string | string[],
  defaultValue?: $TSFixMe,
) {
  if (!object) return defaultValue;

  const parsedPath = Array.isArray(path) ? path : path.split('.');
  return 'getIn' in object
    ? object.getIn(parsedPath, defaultValue)
    : lodashGet(object, parsedPath, defaultValue);
}

export function set(
  object: $TSFixMe,
  path: string | string[],
  value: $TSFixMe,
) {
  if (!object) return value;

  const parsedPath = Array.isArray(path) ? path : path.split('.');
  return 'setIn' in object
    ? object.setIn(parsedPath, value)
    : lodashSet(object, parsedPath, value);
}

export function has(object: $TSFixMe, path: string | string[]) {
  if (!object) return false;

  const parsedPath = Array.isArray(path) ? path : path.split('.');
  return 'hasIn' in object
    ? object.hasIn(parsedPath)
    : lodashHas(object, parsedPath);
}

export function tGet<T, P1 extends keyof T>(object: T, prop1: P1): T[P1];
export function tGet<T, P1 extends keyof T, P2 extends keyof T[P1]>(
  object: T,
  prop1: P1,
  prop2: P2,
): T[P1][P2];
export function tGet(object: $TSFixMe, ...props: string[]) {
  if (!object) return undefined;

  return 'getIn' in object ? object.getIn(props) : lodashGet(object, props);
}
