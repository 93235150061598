import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { TooltipReference as ReakitTooltipReference } from 'reakit/Tooltip';
import isFunction from 'lodash/isFunction';
import classNames from 'classnames';
import { CustomPropTypes } from '@picter/prisma';

import { useTooltip } from '../providers';

/**
 * Properties that should be passed to the HTML element responsible for
 * showing/hiding the tooltip content.
 *
 * @typedef HtmlProps
 * @type {Object}
 *
 * @property {string} aria-describedby
 * @property {Function} onBlur
 * @property {Function} onFocus
 * @property {Function} onMouseEnter
 * @property {Function} onMouseLeave
 * @property {Object} ref - { current: Object }
 * @property {number} tabIndex - Accessibility related
 */

/**
 * Based on Reakit's Tooltip Reference
 *
 * @see https://reakit.io/docs/tooltip/#tooltipreference
 */
function TooltipReference({ children, className, ...props }) {
  const tooltip = useTooltip();

  return (
    <ReakitTooltipReference
      className={classNames(className, 'tooltip__reference')}
      {...tooltip}
      {...props}
    >
      {(htmlProps /** @type {HtmlProps} */) =>
        isFunction(children)
          ? children(tooltip, htmlProps)
          : cloneElement(children, {
              ...htmlProps,
              ...children.props, // prevent overwriting properties assigned to children
            })
      }
    </ReakitTooltipReference>
  );
}

TooltipReference.propTypes = {
  children: CustomPropTypes.oneOrMoreOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  className: PropTypes.string,
};

TooltipReference.defaultProps = {
  className: undefined,
};

export default TooltipReference;
