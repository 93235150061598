import { useMemo, useReducer } from 'react';

import { videoPlayerReducer } from '../reducer';
import { VideoPlayerState } from '../types';

export const defaultState: VideoPlayerState = {
  light: false,
  fullscreen: false,
  playing: false,
  seeking: false,

  muted: false,
  volume: 0.7,

  duration: 0,
  loaded: 0,
  played: 0,
};

export function useVideo(
  initialState: Partial<VideoPlayerState> = defaultState,
) {
  const [state, dispatch] = useReducer(videoPlayerReducer, {
    ...defaultState,
    ...initialState,
  });

  const actions = useMemo(
    () => ({
      setDuration: (durationInSeconds: number) =>
        dispatch({ type: 'set_duration', payload: durationInSeconds }),
      setFullscreen: (fullscreen: boolean) =>
        dispatch({ type: 'set_fullscreen', payload: fullscreen }),
      setLight: (light: boolean | string) =>
        dispatch({ type: 'set_light', payload: light }),
      setPlaying: (playing: boolean) =>
        dispatch({ type: 'set_playing', payload: playing }),
      setProgress: (progress: { played?: number; loaded?: number }) =>
        dispatch({ type: 'set_progress', payload: progress }),
      setSeeking: (seeking: boolean) =>
        dispatch({ type: 'set_seeking', payload: seeking }),
      setVolume: (volumeFraction: number) =>
        dispatch({ type: 'set_volume', payload: volumeFraction }),
      toggleMute: () => dispatch({ type: 'toggle_mute' }),
      togglePlay: () => dispatch({ type: 'toggle_play' }),
      toggleSeek: () => dispatch({ type: 'toggle_seek' }),
    }),
    [],
  );

  return { actions, state };
}
