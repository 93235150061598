import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import {
  ButtonElement as Button,
  Heading,
  Spinner,
  TextField,
} from '@picter/prisma';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Flex, Box } from '@rebass/grid';

import EmbeddedCampaign from 'src/components/EmbeddedCampaign';
import { librarySpaceUrl } from 'src/routes/urls';
import { useToriiActions } from 'src/modules/torii';
import { historyShape, locationShape } from 'src/utils/app-prop-types';
import useFormatMessage from 'src/hooks/use-format-message';
import { Anchor, Body } from 'src/modules/prisma';
import { RolesDropdown } from 'src/components/UserRoleBox';

import Layout from '../_layout';
import messages from './messages';

const Container = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: 4,
})`
  height: 100%;
  margin: 'auto';
`;

const InputGroup = styled(Flex).attrs({
  alignItems: 'flex-start',
})`
  input {
    /* this fixes the height of the input
     * compared to the dropdown */
    height: 45px;
  }

  button p {
    /* this fixes the width of the button to the largest
     * which can change depending on the text inside it */
    min-width: 57px;
  }
`;

const initialInvites = Array(3)
  .fill({ email: undefined, scope: 'member' })
  .map((invite, i) => ({ ...invite, id: i + 1 }));

function Step3Page({
  history,
  location: {
    query: { spaceId },
  },
}) {
  const [invites, setInvites] = useState(initialInvites);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formatMessage = useFormatMessage();
  const { create } = useToriiActions();

  const spaceUrl = useMemo(() => librarySpaceUrl({ spaceId }), [spaceId]);

  const canSubmit = useMemo(() => invites.some(invite => !!invite.email), [
    invites,
  ]);

  const addInvite = useCallback(
    () => setInvites([...invites, { id: invites.length + 1, scope: 'member' }]),
    [invites, setInvites],
  );

  const setInvite = useCallback(
    (id, field, value) =>
      setInvites(
        invites.map(invite =>
          invite.id !== id ? invite : { ...invite, [field]: value },
        ),
      ),
    [invites, setInvites],
  );

  const sendInvites = useCallback(() => {
    setIsSubmitting(true);

    invites.forEach(invite => {
      if (invite.email) {
        create('invitations', {
          attributes: {
            email: invite.email,
            scope: invite.scope,
          },
          relationships: {
            wsSpace: {
              id: spaceId,
              type: 'ws-spaces',
            },
          },
        });
      }
    });

    history.replace(spaceUrl);
  }, [invites, spaceId, spaceUrl, setIsSubmitting, history, create]);

  return (
    <Layout>
      <Layout.Content>
        <Container>
          <Box mb={7}>
            <Heading textSize="large" textAlign="center" whiteSpace="pre-line">
              <FormattedMessage {...messages.pageHeader} />
            </Heading>
          </Box>

          <Box>
            {invites.map(invite => (
              <InputGroup key={`invite-group-${invite.id}`}>
                <Box>
                  <TextField
                    name={`email-${invite.id}`}
                    placeholder={formatMessage(messages.placeholderEmail)}
                    value={invite.email}
                    onChange={e =>
                      setInvite(invite.id, 'email', e.target.value)
                    }
                  />
                </Box>
                <Box pl={2}>
                  <RolesDropdown
                    title={formatMessage(messages.labelRoleInThisSpace)}
                    currentScope={invite.scope}
                    onClickOwner={() => setInvite(invite.id, 'scope', 'owner')}
                    onClickManager={() =>
                      setInvite(invite.id, 'scope', 'manager')
                    }
                    onClickMember={() =>
                      setInvite(invite.id, 'scope', 'member')
                    }
                    onClickViewer={() =>
                      setInvite(invite.id, 'scope', 'viewer')
                    }
                    messageManager={formatMessage(messages.messageManagerRole)}
                    messageMember={formatMessage(messages.messageMemberRole)}
                    messageViewer={formatMessage(messages.messageViewerRole)}
                    buttonBackground
                  />
                </Box>
              </InputGroup>
            ))}

            <Button
              color="primary"
              variant="outlined"
              width={1}
              height={40}
              mb={6}
              onClick={addInvite}
            >
              <FormattedMessage {...messages.labelAddAnotherTeamMember} />
            </Button>

            <Box width={1} style={{ height: '40px' }}>
              {!isSubmitting ? (
                <Button
                  type="submit"
                  color="white"
                  width={1}
                  height={40}
                  disabled={!canSubmit}
                  onClick={sendInvites}
                >
                  <FormattedMessage {...messages.labelSendInvite} />
                </Button>
              ) : (
                <Spinner />
              )}
            </Box>

            <Body textAlign="center" color="grey.600">
              <FormattedMessage {...messages.messageSkip} />
              <Link to={spaceUrl}>
                <Anchor ml={1}>
                  <FormattedMessage {...messages.labelSkipLink} />
                </Anchor>
              </Link>
            </Body>
          </Box>
        </Container>
      </Layout.Content>
      <Layout.Banner>
        <EmbeddedCampaign
          campaign="step-4-invite-team-members"
          category="onboarding"
        />
      </Layout.Banner>
    </Layout>
  );
}

Step3Page.propTypes = {
  history: historyShape.isRequired,
  location: locationShape.isRequired,
};

export default Step3Page;
