import React from 'react';
import { readableColor, transparentize } from 'polished';

import { Sizes } from 'src/types';

import { IDENTICON_COLORS } from '../constants';
import IdenticonWrapper, {
  Props as WrapperProps,
} from '../styles/IdenticonWrapper';
import { hashToRange } from '../utils';

type Props = WrapperProps & {
  color?: string;
  colors?: string[];
  hash: string;
  letter: string;
  interactive?: boolean;
  size?: keyof Sizes<string>;
};

function Identicon({
  colors = IDENTICON_COLORS,
  hash,
  letter,
  ...props
}: Props) {
  const hashRanged = hashToRange(hash, {
    max: colors.length - 1,
  });
  const identiconColor = colors[hashRanged];
  const textColor = readableColor(identiconColor, 'black', 'white');

  return (
    <IdenticonWrapper
      backgroundColor={identiconColor}
      borderColor={identiconColor}
      boxShadow={`0px 0px 3px ${transparentize(0.2, identiconColor)}`}
      color={textColor}
      {...props}
    >
      {letter}
    </IdenticonWrapper>
  );
}

Identicon.displayName = 'Identicon';

export default Identicon;
