import React from 'react';
import PropTypes from 'prop-types';
import { Banner } from '@picter/prisma';
import { withRouter } from 'react-router-dom';

import { locationShape } from 'src/utils/app-prop-types';

const AuthenticationBanner = ({ baseSrc, defaultCampaign, location }) => {
  const type = location.pathname.match(/login|signup/);
  const campaign =
    type && location.query[`${type}-campaign`]
      ? location.query[`${type}-campaign`]
      : defaultCampaign;

  const src = `${baseSrc}${campaign}`;

  return <Banner src={src} width="100%" height="100%" />;
};

AuthenticationBanner.propTypes = {
  baseSrc: PropTypes.string,
  defaultCampaign: PropTypes.string,
  location: locationShape.isRequired,
};

AuthenticationBanner.defaultProps = {
  baseSrc: 'https://embedded-campaigns.picter.com/',
  defaultCampaign: 'login-campaign-frame',
};

export default withRouter(AuthenticationBanner);
