import React, { MouseEventHandler } from 'react';
import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';

import Avatar from 'src/components/Avatar';
import { useComment } from 'src/hooks/use-resource';
import { tGet } from 'src/utils/accessors';

import { TimelineMarker } from '../types';

export const Marker = styled.span.attrs({
  role: 'button',
})<{
  position: number;
}>`
  left: ${props => `calc(${props.position * 100}% - 6px)`};
  appearance: none;
  background-color: ${themeGet('colors.primary')};
  border-radius: 100%;
  border: 0;
  cursor: pointer;
  height: ${themeGet('space.4')}px;
  position: absolute;
  top: ${themeGet('space.3')}px;
  width: ${themeGet('space.4')}px;
`;

export function AvatarTimelineMarker({
  id,
  position,
  onClick,
}: TimelineMarker & {
  onClick: MouseEventHandler;
}) {
  const comment = useComment(
    {
      id,
      includeOnSelector: ['author'],
    },
    { request: false },
  );

  if (!comment) return <Marker position={position} onClick={onClick} />;

  const author = tGet(comment, 'relationships', 'author');
  const authorName = tGet(author, 'attributes', 'publicName');
  const authorEmail = tGet(author, 'attributes', 'email');
  return (
    <Marker position={position} onClick={onClick}>
      <Avatar email={authorEmail} name={authorName} size="xxsmall" selected />
    </Marker>
  );
}
