import { get } from 'src/utils/accessors';
import globalMessages from 'src/messages';

export const getImageTitle = ({ image, intl, formatMessage }) =>
  get(image, 'attributes.title') ||
  (intl && intl.formatMessage(globalMessages.labelUntitledImage)) ||
  formatMessage(globalMessages.labelUntitledImage);

export const getProjectTitle = ({ project, intl, formatMessage }) =>
  get(project, 'attributes.title') ||
  (intl && intl.formatMessage(globalMessages.labelUntitledProject)) ||
  formatMessage(globalMessages.labelUntitledProject);

export const getCollectionName = ({ collection, intl, formatMessage }) =>
  get(collection, 'attributes.name') ||
  (intl && intl.formatMessage(globalMessages.labelUntitledCollection)) ||
  formatMessage(globalMessages.labelUntitledCollection);

export const buildProjectTitle = props => getProjectTitle(props);

export const buildCollectionTitle = props =>
  `${getCollectionName(props)} ∙ ${buildProjectTitle(props)}`;

export const buildProjectImageTitle = props => {
  let title = getImageTitle(props);

  if (props.project) {
    title = title.concat(` ∙ ${buildProjectTitle(props)}`);
  }

  return title;
};

export const buildCollectionImageTitle = props => {
  let title = getImageTitle(props);

  if (props.collection) {
    title = title.concat(` ∙ ${buildCollectionTitle(props)}`);
  }

  return title;
};
