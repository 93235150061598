import { useEffect } from 'react';
import fromEntries from 'fromentries';
import tinykeys, { KeyBindingMap } from 'tinykeys';

export function isEventTargetInputOrTextArea(eventTarget: EventTarget | null) {
  if (eventTarget === null) return false;

  const eventTargetTagName = (eventTarget as HTMLElement).tagName.toLowerCase();
  return ['input', 'textarea'].includes(eventTargetTagName);
}

export default function useTinyKeys(
  target: Document | Window | HTMLElement,
  bindings: KeyBindingMap,
  deps: unknown[],
  blockOnInputs = true,
) {
  useEffect(() => {
    const wrappedBindings = blockOnInputs
      ? fromEntries(
          Object.entries(bindings).map(([key, handler]) => [
            key,
            (event: KeyboardEvent) => {
              if (!isEventTargetInputOrTextArea(event.target)) {
                handler(event);
              }
            },
          ]),
        )
      : bindings;
    const unsubscribe = tinykeys(target as HTMLElement, wrappedBindings);

    return () => {
      unsubscribe();
    };
  }, deps);
}
