import styled from 'styled-components/macro';
import { mediaQuery } from '@picter/prisma';

const PreviewPlaceholderWrapper = styled.div`
  opacity: 0.6;
  background-color: ${props => props.theme.colors.gray[200]};
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    position: absolute;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    div {
      img {
        height: 63px;
        width: 63px;
        margin: 0;
      }
    }
  }

  @media screen and ${mediaQuery.greaterThan('mobile')} {
    div {
      margin-top: 15px;
      margin-bottom: 8px;
    }
  }

  ::before {
    display: block;
    content: '';
    padding-top: 100%;
  }
`;

export default PreviewPlaceholderWrapper;
