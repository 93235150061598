import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@rebass/grid';
import { ButtonElement as Button, Scrollbar } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';
import {
  RemoveRedEye as RemoveRedEyeSvg,
  ArrowDropDown as ArrowDropDownSvg,
  ArrowDropUp as ArrowDropUpSvg,
} from '@styled-icons/material';

import { Body, Box, Checkbox, Dropdown, Icon } from 'src/modules/prisma';

import messages from '../messages';

function ProjectShareContentChooserIcon({ active, onClick, label }) {
  return (
    <Button onClick={onClick} variant="flat" px={0} py={0} textAlign="right">
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Body color="grey.600">{label}</Body>
        <Icon
          type={active ? <ArrowDropUpSvg /> : <ArrowDropDownSvg />}
          color="grey.600"
        />
      </Box>
    </Button>
  );
}

ProjectShareContentChooserIcon.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
};

ProjectShareContentChooserIcon.defaultProps = {
  active: false,
  onClick: undefined,
};

export default function ProjectShareContentChooser({
  onChange,
  options,
  value,
  label,
}) {
  const iconLabel =
    value.length === 0 ? (
      <FormattedMessage {...messages.labelAllFiles} />
    ) : (
      <FormattedMessage
        {...messages.labelNumberOfCollections}
        values={{ count: value.length }}
      />
    );

  return (
    <Box display="flex" justifyContent="space-between" mb={4}>
      <Box display="flex" alignItems="center" mr={4}>
        <Icon type={<RemoveRedEyeSvg />} color="grey.600" size="small" />
        <Body whiteSpace="nowrap" ml={4}>
          {label}
        </Body>
      </Box>
      <Box>
        <Dropdown icon={<ProjectShareContentChooserIcon label={iconLabel} />}>
          <Dropdown.Menu wrapPortal>
            <Dropdown.Item
              label={<FormattedMessage {...messages.labelAllFiles} />}
              onClick={() => onChange({ action: 'removeAll' })}
            />

            <Dropdown.Separator />

            <Scrollbar maxHeight="300px">
              <Flex flexDirection="column">
                {options.map(({ id, name }) => {
                  const isChecked = value.includes(id);
                  const action = isChecked ? 'remove' : 'add';

                  return (
                    <Dropdown.Item
                      key={id}
                      icon={<Checkbox mr={4} checked={isChecked} />}
                      label={name}
                      onClick={() => onChange({ action, payload: id })}
                    />
                  );
                })}
              </Flex>
            </Scrollbar>
          </Dropdown.Menu>
        </Dropdown>
      </Box>
    </Box>
  );
}

ProjectShareContentChooser.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  value: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string.isRequired,
};

ProjectShareContentChooser.defaultProps = {
  options: [],
  value: [],
};
