import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import propTypes from '@styled-system/prop-types';

import Container from '../styles/ContextualTextFieldContainer';
import HiddenSpan from '../styles/ContextualTextFieldHiddenSpan';
import Input from '../styles/ContextualTextFieldInput';

class ContextualTextField extends Component {
  constructor(props) {
    super(props);

    this.inputRef = createRef();
    this.spanRef = createRef();
  }

  componentDidMount() {
    this.setInputSize();
  }

  componentDidUpdate() {
    this.setInputSize();
  }

  setInputSize = () => {
    const { current: inputElem } = this.inputRef;
    const { current: spanElem } = this.spanRef;
    const rect = spanElem.getBoundingClientRect();
    const { minWidth } = this.props;

    if (minWidth !== '100%') {
      inputElem.style.width = `${rect.width}px`;
    }
  };

  handleKeyPress = event => {
    const { current: inputElem } = this.inputRef;
    const { key } = event;

    if (inputElem && key === 'Enter') {
      inputElem.blur();
    }
  };

  handleKeyUp = event => {
    const { current: inputElem } = this.inputRef;
    const { key } = event;

    if (inputElem && key === 'Escape') {
      inputElem.blur();
    }
  };

  focus() {
    const { current: inputElem } = this.inputRef;
    if (inputElem) {
      inputElem.focus();
    }
  }

  render() {
    const {
      id,
      className,
      autoFocus,
      color,
      disabled,
      fontFamily,
      fontWeight,
      maxWidth,
      minWidth,
      onBlur,
      onChange,
      placeholder,
      textAlign,
      textStyle,
      value,
      ...props
    } = this.props;

    return (
      <Container
        id={id}
        className={className}
        maxWidth={maxWidth}
        minWidth={minWidth}
        fontFamily={fontFamily}
        fontWeight={fontWeight}
        textAlign={textAlign}
        textStyle={textStyle}
      >
        <HiddenSpan ref={this.spanRef}>{value || placeholder}</HiddenSpan>
        <Input
          {...props}
          autoFocus={autoFocus}
          disabled={disabled}
          color={color}
          ref={this.inputRef}
          onBlur={onBlur}
          onChange={onChange}
          onInput={this.handleInput}
          onKeyPress={this.handleKeyPress}
          onKeyUp={this.handleKeyUp}
          placeholder={placeholder}
          value={value}
        />
      </Container>
    );
  }
}
ContextualTextField.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  color: propTypes.color.color,
  disabled: PropTypes.bool,
  fontFamily: propTypes.typography.fontFamily,
  fontWeight: propTypes.typography.fontWeight,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  textAlign: PropTypes.string,
  textStyle: propTypes.textStyle.textStyle,
  value: PropTypes.string,
};

ContextualTextField.defaultProps = {
  id: null,
  className: null,
  autoFocus: false,
  color: 'input.text',
  disabled: false,
  fontFamily: 'sansSerif',
  fontWeight: 'regular',
  maxWidth: '100%',
  minWidth: null,
  onBlur: null,
  onChange: null,
  placeholder: null,
  textAlign: 'left',
  textStyle: 'body.regular',
  value: '',
};

export default ContextualTextField;
