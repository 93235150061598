import styled from 'styled-components/macro';

// the props actually exist, but how they are exported
// confuses the linter and causes errors
// eslint-disable-next-line import/named
import { border } from './mixins';
import Box from './Box';

const Panel = styled(Box)`
  ${border};
`;

Panel.defaultProps = {
  blacklist: [...Box.defaultProps.blacklist, ...border.propNames],
};

export default Panel;
