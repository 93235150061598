import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';
import { transparentize, transitions } from 'polished';
import { bodyTextStyle, transition } from '@picter/prisma';

const animationSettings = property => ({
  duration: '200ms',
  property,
});

const SelectFieldMenu = styled.select`
  ${bodyTextStyle};
  appearance: none;
  background-color: ${themeGet('colors.gray.100')};
  border: 1px solid ${themeGet('colors.gray.100')};
  border-radius: 2px;
  color: ${themeGet('colors.black')};
  margin: 0;
  width: 100%;
  ${transitions(
    transition(animationSettings('color')),
    transition(animationSettings('background-color')),
    transition(animationSettings('border-color')),
    transition(animationSettings('padding-left')),
  )};

  &:hover {
    border-color: ${themeGet('colors.gray.300')};
  }

  &:focus {
    background-color: ${themeGet('colors.white')};
    border-color: ${themeGet('colors.primary')};
    box-shadow: 0 0 6px 0
      ${props => transparentize(0.8, themeGet('colors.primary')(props))};
  }

  &:disabled {
    background-color: ${themeGet('colors.white')};
    border-color: ${themeGet('colors.gray.400')};
    cursor: not-allowed;
  }

  &.error {
    border-color: ${themeGet('colors.danger')};
    background-color: ${props =>
      transparentize(0.9, themeGet('colors.error.light')(props))};

    :focus {
      background-color: ${themeGet('colors.white')};
      box-shadow: 0 0 6px 0
        ${props => transparentize(0.8, themeGet('colors.danger')(props))};
    }
  }

  &.empty {
    color: ${themeGet('colors.gray.600')};
  }
`;

export default SelectFieldMenu;
