import kebabCase from 'lodash/kebabCase';
import camelCase from 'lodash/camelCase';
import { requestAsPromise } from '../../json-api-middleware/actions';
import { asyncTypesArray } from '../../utils/action-types-helpers';
import addQueryParameters from '../../utils/add-query-parameters';
import { API_ATTACH_RELATIONSHIP_TYPES } from '../../constants';

const attachRelationship = (
  model,
  { id, relation, data, querySerializers } = {},
  { prefix, baseUrl, postfix } = {},
) => {
  if (!id) {
    throw new Error(
      'You have to define a resource id to attach the relationship to.',
    );
  }

  if (!relation) {
    throw new Error('Relation is required to attach a relationship.');
  }

  if (!data) {
    throw new Error('You have to pass a data parameter.');
  }

  const endpoint = [kebabCase(model), id, 'relationships', kebabCase(relation)];

  if (prefix) endpoint.unshift(prefix);
  if (postfix) endpoint.push(postfix);

  return dispatch =>
    requestAsPromise(dispatch, {
      types: asyncTypesArray(API_ATTACH_RELATIONSHIP_TYPES),
      endpoint: addQueryParameters(endpoint.join('/'), {
        querySerializers,
      }),
      method: 'POST',
      payload: { data },
      meta: {
        model: camelCase(model),
        relation: camelCase(relation),
        id,
        data,
      },
      baseUrl,
    });
};

export default attachRelationship;
