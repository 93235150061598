import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Close, Cancel, Task } from '@styled-icons/material';
import { Flex, Box } from '@rebass/grid';
import { Button, Spinner as SpinnerComponent, P } from '@picter/prisma';
import styled from 'styled-components';
import themeGet from '@styled-system/theme-get';

import { Icon } from 'src/modules/prisma';
import { Bundle, useDownloadManager } from 'src/modules/download-manager';

import messages from './messages';

const SpinnerWrapper = styled(Box)`
  overflow: hidden;
  width: ${themeGet('space.4')}px;
  height: ${themeGet('space.4')}px;

  img {
    width: 100%;
    height: 100%;
  }
`;

function Spinner() {
  return (
    <SpinnerWrapper>
      <SpinnerComponent />
    </SpinnerWrapper>
  );
}

type BundleStatusIndicatorProps = {
  bundle: Bundle;
};

function getStatusMessages(status?: Bundle['bundleStatus']) {
  if (status === 'in-progress') {
    return messages.labelInProgress;
  }

  if (status === 'complete') {
    return messages.labelCompleted;
  }

  return messages.labelFailed;
}

export default function BundleStatusIndicator({
  bundle: { bundleName, bundleStatus, fileCount, bundleId, url, retryUrl },
}: BundleStatusIndicatorProps) {
  const { hideBundle, retry } = useDownloadManager();
  const [isStartingRetry, setIsStartingRetry] = useState(false);
  const isInProgress = bundleStatus === 'in-progress';
  const isCompleted = bundleStatus === 'complete';
  const isFailed = bundleStatus === 'failed';

  return (
    <Flex px={4} py={2} alignItems="center" width={1}>
      <Flex mr={3}>
        {isInProgress && <Spinner />}
        {isCompleted && <Icon size="" type={<Task color="#34D399" />} />}
        {isFailed && <Cancel />}
      </Flex>
      <Box>
        <P color={isFailed ? 'error' : 'muted'} noMargin>
          <FormattedMessage
            {...getStatusMessages(bundleStatus)}
            values={{ count: fileCount }}
          />
        </P>
        <P noMargin>{bundleName}</P>
      </Box>
      <Flex ml="auto" alignItems="center">
        {isCompleted && (
          <Button
            flat
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            href={url!} // we know for sure that the url is not null here
            as="a"
            onClick={() => hideBundle(bundleId)}
          >
            <FormattedMessage {...messages.labelDownload} />
          </Button>
        )}
        {isFailed && (
          <Button
            flat
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              retry(retryUrl!); // the retryUrl is present when the bundleStatus is failed
              setIsStartingRetry(true);
            }}
            disabled={isStartingRetry}
          >
            <FormattedMessage {...messages.labelTryAgain} />
          </Button>
        )}
        {!isCompleted && (
          <Icon type={<Close />} onClick={() => hideBundle(bundleId)} />
        )}
      </Flex>
    </Flex>
  );
}
