import styled from 'styled-components/macro';

const CommentPreviewStack = styled.span`
  box-shadow: -2px 2px 0px 0px white, -4px 4px 0px 0px #d2d2d2,
    -6px 6px 0px 0px white, -8px 8px 0px 0px #d2d2d2;
  display: block;
  margin-left: 8px;
  margin-bottom: 8px;
`;

export default CommentPreviewStack;
