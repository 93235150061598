import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { Map } from 'immutable';

import paths from 'src/routes/paths';
import { get } from 'src/utils/accessors';

import {
  selectAuthenticationStatus,
  selectAuthenticatedUser,
} from '../../selectors';

const RouteAuthenticated = ({
  component,
  render,
  isAuthenticated,
  user,
  redirect,
  ...rest
}) => {
  if (!isAuthenticated && redirect) {
    return (
      <Redirect
        to={{
          pathname: paths.LOGIN_PATH,
          state: { redirectUrl: rest.location.pathname },
        }}
        {...rest}
      />
    );
  }

  if (!isAuthenticated) {
    return null;
  }

  // Block route from lite account users (users, that don't have an email defined).
  // It would be better to use the liteAccountEmail field for this check, but currently,
  // the backend is not returning the liteAccountEmail via /user GET. This might change
  // with the refactor of authorization routes to return JSON:API, but for now we're
  // using this approach.
  // If we should ever implement single sign on via Google, Twitter etc., we need
  // to adjust this, otherwise these users might be blocked as well.
  if (user && get(user, 'email') === null) {
    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: paths.SIGNUP_PATH,
            state: { redirectUrl: rest.location.pathname },
          }}
          {...rest}
        />
      );
    }

    return null;
  }

  return <Route {...rest} render={render} component={component} />;
};

RouteAuthenticated.defaultProps = {
  component: undefined,
  render: undefined,
  user: undefined,
  redirect: true,
};

RouteAuthenticated.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  render: PropTypes.func,
  user: PropTypes.instanceOf(Map),
  isAuthenticated: PropTypes.bool.isRequired,
  redirect: PropTypes.bool,
};

const mapStateToProps = state => ({
  isAuthenticated: selectAuthenticationStatus(state),
  user: selectAuthenticatedUser(state),
});

export default connect(mapStateToProps)(RouteAuthenticated);
