import React, { forwardRef } from 'react';
import { UploadStorageSubscribe } from 'src/utils/upload-storage';

const withUploadStorageSubscribe = WrappedComponent =>
  forwardRef((props, ref) => (
    <UploadStorageSubscribe>
      {uploadStorage => (
        <WrappedComponent {...props} uploadStorage={uploadStorage} ref={ref} />
      )}
    </UploadStorageSubscribe>
  ));
export default withUploadStorageSubscribe;
