import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Coordinate,
  Markup,
  PathMarkup,
  PointMarkup,
  MarkupColor,
} from '../models/Markup';
import { usePan } from '../providers/PanProvider';
import { MarkupTool } from './MarkupToolbar';
import { PathMarkupGraphic } from './MarkupGraphic';
import { useZoom } from '../providers/ZoomProvider';
import { getScaledStrokeWidth } from '../utils';
import { CanvasInfo, TargetInfo } from '../types';
// @ts-ignore
import BrushCursor from '../assets/brush-cursor.cur';

function mouseToSvgSpace(
  event: React.MouseEvent | React.TouchEvent,
  translateX: number,
  translateY: number,
  zoom?: number,
) {
  const rect = event.currentTarget.getBoundingClientRect();
  const isTouchEvent = event.type.match(/touch/gi);
  const pointer = isTouchEvent
    ? (event as React.TouchEvent).targetTouches[0]
    : (event as React.MouseEvent);

  if (zoom)
    return [
      (pointer.clientX - rect.x) / zoom + translateX,
      (pointer.clientY - rect.y) / zoom + translateY,
    ] as Coordinate;

  return [
    pointer.clientX - rect.x + translateX,
    pointer.clientY - rect.y + translateY,
  ] as Coordinate;
}

function normalizeSvgCoordinate(
  coordinate: Coordinate,
  width: number,
  height: number,
): Coordinate {
  return [coordinate[0] / width, coordinate[1] / height];
}

export default function MarkupCanvas({
  children,
  canvasHeight,
  canvasWidth,
  color,
  targetHeight,
  targetWidth,
  tool,
  onClick,
  onDraw,
  onDrawing,
}: React.PropsWithChildren<
  {
    color: MarkupColor;
    tool?: MarkupTool;

    onClick?: (event: React.MouseEvent | React.TouchEvent) => void;
    onDraw: (
      event: React.MouseEvent | React.TouchEvent,
      markups: Markup[],
    ) => void;
    onDrawing: (event: React.MouseEvent | React.TouchEvent) => void;
  } & CanvasInfo &
    TargetInfo
>) {
  const { panOn, setPanOn } = usePan();
  const { current: zoom } = useZoom();
  const [isDrawing, setIsDrawing] = useState(false);
  // const [newDrawing, setNewDrawing] = useState<Markup[]>([]);
  const [newPathMarkup, setNewPathMarkup] = useState<PathMarkup | null>(null);
  const translate = useMemo(() => {
    return {
      x: -(canvasWidth / 2 - targetWidth / 2),
      y: -(canvasHeight / 2 - targetHeight / 2),
    };
  }, [canvasHeight, canvasWidth, targetHeight, targetWidth]);
  const strokeWidth = useMemo(
    () => getScaledStrokeWidth(targetWidth, targetHeight),
    [targetWidth, targetHeight],
  );

  const handleMouseDown = useCallback(
    (event: React.MouseEvent | React.TouchEvent) => {
      if (panOn) return;

      if (tool === MarkupTool.Brush) {
        setIsDrawing(true);
        onDrawing(event);
        const coordinate = mouseToSvgSpace(
          event,
          translate.x,
          translate.y,
          zoom,
        );

        const normalizedCoordinate = normalizeSvgCoordinate(
          coordinate,
          targetWidth,
          targetHeight,
        );

        // true sets normalized flag
        setNewPathMarkup(new PathMarkup([normalizedCoordinate], color, true));
      }
    },
    [
      panOn,
      tool,
      onDrawing,
      translate.x,
      translate.y,
      zoom,
      targetWidth,
      targetHeight,
      color,
    ],
  );

  const handleMouseMove = useCallback(
    (event: React.MouseEvent | React.TouchEvent) => {
      if (panOn) return;

      if (isDrawing) {
        const coordinate = mouseToSvgSpace(
          event,
          translate.x,
          translate.y,
          zoom,
        );

        const normalizedCoordinate = normalizeSvgCoordinate(
          coordinate,
          targetWidth,
          targetHeight,
        );

        setNewPathMarkup(markup =>
          markup
            ? new PathMarkup(
                [...markup.path, normalizedCoordinate],
                color,
                true, // true sets normalized flag
              )
            : null,
        );
      }
    },
    [
      panOn,
      isDrawing,
      translate.x,
      translate.y,
      zoom,
      targetWidth,
      targetHeight,
      color,
    ],
  );

  const handleMouseUp = useCallback(
    (event: React.MouseEvent | React.TouchEvent) => {
      if (panOn) return;

      switch (tool) {
        case MarkupTool.Brush:
          if (isDrawing && newPathMarkup) {
            // Already normalized on mouseMove and mouseDown events
            onDraw(event, [newPathMarkup]);
            setIsDrawing(false);
            setNewPathMarkup(null);
          }
          break;
        case MarkupTool.Pin: {
          const coordinate = mouseToSvgSpace(
            event,
            translate.x,
            translate.y,
            zoom,
          );
          const [x, y] = normalizeSvgCoordinate(
            coordinate,
            targetWidth,
            targetHeight,
          );
          onDraw(event, [new PointMarkup(x, y, color)]);

          break;
        }
        default:
          break;
      }

      onClick?.(event);
    },
    [
      color,
      onClick,
      onDraw,
      isDrawing,
      newPathMarkup,
      panOn,
      targetHeight,
      targetWidth,
      tool,
      translate.x,
      translate.y,
      zoom,
    ],
  );

  const viewBox = `${translate.x} ${translate.y} ${canvasWidth} ${canvasHeight}`;

  const cursor = useMemo(() => {
    if (panOn) return 'inherit';

    switch (tool) {
      case MarkupTool.Brush:
        return `url(${BrushCursor}), cell`;
      case MarkupTool.Pin:
        return 'crosshair';
      default:
        return 'inherit';
    }
  }, [panOn, tool]);

  useEffect(
    function refreshPan() {
      switch (tool) {
        case MarkupTool.Hand:
          setPanOn(true);
          break;

        default:
          setPanOn(false);
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tool],
  );

  return (
    <svg
      cursor={cursor}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onTouchStart={handleMouseDown}
      onTouchMove={handleMouseMove}
      onTouchEnd={handleMouseUp}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <filter
          id="shadow"
          x="-40%"
          y="-40%"
          width="180%"
          height="180%"
          filterUnits="userSpaceOnUse"
        >
          <feGaussianBlur in="SourceAlpha" stdDeviation="1" />
          <feOffset dx="1" dy="1" result="offsetblur" />
          <feOffset dx="0" dy="0" result="offsetblur" />
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter
          id="shadow-highlighted"
          x="-40%"
          y="-40%"
          width="180%"
          height="180%"
          filterUnits="userSpaceOnUse"
        >
          <feGaussianBlur in="SourceAlpha" stdDeviation="2" />
          <feOffset dx="1" dy="1" result="offsetblur" />
          <feOffset dx="0" dy="0" result="offsetblur" />
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      {newPathMarkup && (
        <PathMarkupGraphic
          markup={newPathMarkup}
          strokeWidth={strokeWidth}
          targetHeight={targetHeight}
          targetWidth={targetWidth}
        />
      )}
      {children}
    </svg>
  );
}
