import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux-immutable';
import {
  belongsTo,
  hasMany,
  reducers as jsonApiReducers,
  jsonApiMiddleware,
  workerMiddleware,
  cleanCache,
} from 'src/modules/torii';

import { authentication, user } from 'src/utils/cerberus/reducers';

export const schemas = {
  wsProjects: {
    images: hasMany('wsProjectImages', {
      inverse: 'project',
    }),
    comments: hasMany('wsComments', {
      inverse: 'project',
    }),
    collections: hasMany('wsCollections', {
      inverse: 'project',
    }),
    invitations: hasMany('invitations', {
      inverse: 'wsProject',
    }),
    publicLinks: hasMany('wsPublicLinks', {
      inverse: 'project',
    }),
    space: belongsTo('wsSpaces', {
      inverse: 'projects',
    }),
    follows: hasMany('wsProjectFollows', {
      inverse: 'project',
    }),
    owner: belongsTo('users'),
    editors: hasMany('users'),
    viewers: hasMany('users'),
    users: hasMany('users'),
  },
  wsProjectImages: {
    project: belongsTo('wsProjects', {
      inverse: 'images',
    }),
    imageMetaData: hasMany('imageMetaData'),
    comments: hasMany('wsComments', {
      inverse: 'image',
    }),
    approvals: hasMany('wsApprovals', {
      inverse: 'image',
    }),
    collectionImages: hasMany('wsCollectionImages', {
      inverse: 'image',
    }),
  },
  wsApprovals: {
    image: belongsTo('wsProjectImages', {
      inverse: 'approvals',
    }),
    user: belongsTo('users'),
  },
  wsPublicLinks: {
    project: belongsTo('wsProjects', {
      inverse: 'publicLinks',
    }),
  },
  wsCollections: {
    images: hasMany('wsCollectionImages', {
      inverse: 'collection',
    }),
    project: belongsTo('wsProjects', {
      inverse: 'collections',
    }),
  },
  wsCollectionImages: {
    collection: belongsTo('wsCollections', {
      inverse: 'images',
    }),
    image: belongsTo('wsProjectImages', {
      inverse: 'collectionImages',
    }),
  },
  wsComments: {
    author: belongsTo('users'),
    image: belongsTo('wsProjectImages', {
      inverse: 'comments',
    }),
    parent: belongsTo('wsComments', {
      inverse: 'replies',
    }),
    project: belongsTo('wsProjects', {
      inverse: 'comments',
    }),
    replies: hasMany('wsComments', {
      inverse: 'parent',
    }),
    resolvedBy: belongsTo('users'),
    assignee: belongsTo('users'),
  },
  invitations: {
    wsProject: belongsTo('wsProjects', {
      inverse: 'invitations',
    }),
    wsSpace: belongsTo('wsSpaces', {
      inverse: 'invitations',
    }),
  },
  users: {
    plan: belongsTo('plans'),
    wsProjectImageUsage: belongsTo('wsProjectImageUsage'),
    spaces: hasMany('wsSpaces'),
  },
  plans: {
    features: belongsTo('productFeatures'),
  },
  wsSpaces: {
    projects: hasMany('wsProjects'),
    invitations: hasMany('invitations', {
      inverse: 'wsSpace',
    }),
    users: hasMany('wsSpaceUsers'),
    folders: hasMany('wsFolders', {
      inverse: 'space',
    }),
    referralLink: belongsTo('wsReferralLink', {
      inverse: 'space',
    }),
  },
  wsReferralLink: {
    space: belongsTo('wsSpaces', {
      inverse: 'referralLink',
    }),
  },
  wsFolders: {
    space: belongsTo('wsSpaces', {
      inverse: 'folders',
    }),
    count: belongsTo('wsFolderProjectCounts'),
  },
  wsProjectFollows: {
    project: belongsTo('wsProjects', { inverse: 'follows' }),
  },
};

const jsonApiData = jsonApiReducers({
  schemas,
});

const reducers = combineReducers({
  authentication,
  api: jsonApiData,
  user,
});

const cleanCacheMiddleware = () => next => action => {
  // Fast temporary solution to fix bug on production.
  // We shuld remove it once we move new torii logics to the package
  // so we can just reset the cache in there.
  if (action.type === 'API_RESET_STORE') {
    cleanCache();
  }
  // call the next function
  next(action);
};

const store = createStore(
  reducers,
  compose(
    applyMiddleware(
      thunk,
      jsonApiMiddleware({
        baseUrl: `${process.env.REACT_APP_API_URL}/app-workspace`,
        requestHeaders: () => ({
          'x-picter-socketid': sessionStorage.getItem('pusher_socket_id'),
        }),
      }),
      // pass apiDataPath to nested data reducer so we can map the reducer correctly
      process.env.NODE_ENV !== 'test'
        ? workerMiddleware(schemas, ['api'])
        : () => next => action => next(action),
      cleanCacheMiddleware,
    ),
    /* eslint-disable no-underscore-dangle */
    process.env.NODE_ENV === 'development' &&
      window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f,
    /* eslint-enable no-underscore-dangle */
  ),
);

export default store;
