import styled from 'styled-components/macro';

export default styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: ${props => props.theme.space[5]}px;
  padding-right: ${props => props.theme.space[5]}px;
  padding-bottom: ${props => props.theme.space[2]}px;
  padding-top: ${props => props.theme.space[2]}px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};

  > span {
    margin-top: ${props => props.theme.space[1]}px;
  }

  align-items: center;
  height: 38px;
`;
