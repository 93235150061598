import { List, Map } from 'immutable';

import { viewModeTypes } from 'src/routes/constants';
import { get, has } from 'src/utils/accessors';

// Torii returns a List if project doesn't exist
export const isProjectDefined = project =>
  Map.isMap(project) && !project.isEmpty();

export const isProjectEmpty = project => has(project, 'relationships.images.0');

export const isProjectDownloadEnabled = project =>
  get(project, 'attributes.downloadEnabled');

export const hasProjectPublicUrl = project =>
  !!get(project, 'attributes.publicUrlScope');

// Torii returns a List if owner doesn't exist
export const getProjectOwner = project => {
  const owner = get(project, 'relationships.users', List()).find(
    user => get(user, 'attributes.scope') === 'owner',
  );
  return Map.isMap(owner) ? owner : undefined;
};

export const getProjectSpace = project => {
  const owner = get(project, 'relationships.space');
  return Map.isMap(owner) ? owner : undefined;
};

export function getProjectUsers(project) {
  return get(project, 'relationships.users', List()).filter(
    projectUser => get(projectUser, 'attributes.scope') !== 'owner',
  );
}

export const getProjectPublicUrlKey = project =>
  get(project, 'attributes.publicUrlKey');

// this unique is needed in some places because torii
// doesn't handle create receiving an existing
// resource. I patches the existing one
// but still add a repeated one
export const getProjectInvitationsUnique = project =>
  get(project, 'relationships.invitations', List())
    .filter(i => !!get(i, 'id'))
    .groupBy(i => get(i, 'id'))
    .map(i => i.first())
    .toList();

export const publicLinkTypes = {
  PRESENT: 'present',
  REQUEST_FILES: 'request-files',
  REVIEW: 'review',
};

export function getProjectPublicLinkByType(project, publicLinkType) {
  if (!publicLinkType) {
    throw new ReferenceError('"publicLinkType" is not defined');
  }

  return get(project, 'relationships.publicLinks', List()).find(
    publicLink => get(publicLink, 'attributes.type') === publicLinkType,
  );
}

export const getProjectPublicLink = (project, viewMode) => {
  switch (viewMode) {
    case viewModeTypes.REVIEW: {
      return (
        get(project, 'relationships.publicLinks', List()).find(
          publicLink =>
            get(publicLink, 'attributes.type') === publicLinkTypes.REVIEW,
        ) || Map()
      );
    }
    case viewModeTypes.PRESENT: {
      return (
        get(project, 'relationships.publicLinks', List()).find(
          publicLink =>
            get(publicLink, 'attributes.type') === publicLinkTypes.PRESENT,
        ) || Map()
      );
    }
    case viewModeTypes.REQUEST_FILES: {
      return (
        get(project, 'relationships.publicLinks', List()).find(
          publicLink =>
            get(publicLink, 'attributes.type') ===
            publicLinkTypes.REQUEST_FILES,
        ) || Map()
      );
    }
    default:
      return Map();
  }
};

export const getProjectPublicLinkKeyByViewMode = (project, viewMode) => {
  switch (viewMode) {
    case viewModeTypes.DEFAULT: {
      return get(project, 'attributes.publicUrlKey');
    }
    case viewModeTypes.REVIEW: {
      const link = get(project, 'relationships.publicLinks', List()).find(
        publicLink =>
          get(publicLink, 'attributes.type') === publicLinkTypes.REVIEW,
      );

      if (link) {
        return get(link, 'attributes.key');
      }

      return null;
    }
    case viewModeTypes.PRESENT: {
      const link = get(project, 'relationships.publicLinks', List()).find(
        publicLink =>
          get(publicLink, 'attributes.type') === publicLinkTypes.PRESENT,
      );

      if (link) {
        return get(link, 'attributes.key');
      }

      return null;
    }
    case viewModeTypes.REQUEST_FILES: {
      const link = get(project, 'relationships.publicLinks', List()).find(
        publicLink =>
          get(publicLink, 'attributes.type') === publicLinkTypes.REQUEST_FILES,
      );

      if (link) {
        return get(link, 'attributes.key');
      }

      return null;
    }
    default:
      return null;
  }
};

export const getEnabledPublicLinks = project => {
  return get(project, 'relationships.publicLinks', List()).filter(publicLink =>
    get(publicLink, 'attributes.enabled'),
  );
};

export const hasPublicLinkAccess = (project, { params: { viewMode } = {} }) => {
  const publicLink = getProjectPublicLink(project, viewMode);
  return (
    !publicLink.isEmpty() &&
    get(publicLink, 'attributes.enabled') &&
    get(project, 'attributes.deletedAt') === null
  );
};

export const REVIEW_DONE_SESSIONSTORAGE_KEY = 'project-review-done';

export const getReviewDoneSessionStorageKey = key =>
  `${REVIEW_DONE_SESSIONSTORAGE_KEY}-${key}`;
