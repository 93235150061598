import React from 'react';
import PropTypes from 'prop-types';
import { ButtonElement as Button, Body, Modal } from '@picter/prisma';
import { Flex } from '@rebass/grid';
import { FormattedMessage } from 'react-intl';
import { TrackerEvent, TrackerScope } from '@picter/tracker';

import { formattedMessage } from 'src/utils/app-prop-types';

import messages from '../messages';

function CommentDeleteModal({
  message,
  name,
  onClose,
  onConfirm,
  open,
  title,
}) {
  return (
    <TrackerScope name={name}>
      <Modal title={title} onClickClose={onClose} open={open}>
        <TrackerEvent name={name} trackView>
          <Body>{message}</Body>
          <Flex justifyContent="space-between" mt={7}>
            <TrackerEvent name="Cancel" trackClick>
              <Button
                color="grey.600"
                onClick={onClose}
                px={0}
                py={0}
                textStyle="action.large"
                variant="flat"
              >
                <FormattedMessage {...messages.labelCancelDeleteComment} />
              </Button>
            </TrackerEvent>
            <TrackerEvent name="Delete" trackClick>
              <Button
                color="danger"
                px={0}
                py={0}
                ml={6}
                onClick={onConfirm}
                textStyle="action.large"
                variant="flat"
              >
                <FormattedMessage {...messages.labelDeleteComment} />
              </Button>
            </TrackerEvent>
          </Flex>
        </TrackerEvent>
      </Modal>
    </TrackerScope>
  );
}

CommentDeleteModal.propTypes = {
  message: formattedMessage,
  name: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  title: formattedMessage,
};

CommentDeleteModal.defaultProps = {
  message: <FormattedMessage {...messages.messageModalDeleteComment} />,
  name: 'Delete comment modal',
  onClose: undefined,
  onConfirm: undefined,
  open: false,
  title: <FormattedMessage {...messages.titleDeleteComment} />,
};

export default CommentDeleteModal;
