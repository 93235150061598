import React, { useState, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ContextualTextField } from '@picter/prisma';

import { refShape, formattedMessage } from 'src/utils/app-prop-types';

const ProjectCardEditableTitle = ({
  forwardedRef,
  onChange,
  placeholder,
  value: originalValue,
}) => {
  const [value, setValue] = useState(originalValue);

  useEffect(() => {
    setValue(originalValue);
  }, [originalValue]);

  return (
    <ContextualTextField
      maxLength="100"
      minWidth="100%"
      onBlur={onChange}
      onChange={e => setValue(e.target.value)}
      placeholder={placeholder}
      textStyle="body"
      ref={forwardedRef}
      value={value || undefined}
    />
  );
};

ProjectCardEditableTitle.propTypes = {
  forwardedRef: refShape.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: formattedMessage,
  value: PropTypes.node,
};

ProjectCardEditableTitle.defaultProps = {
  placeholder: 'Untitled project',
  value: null,
};

export default forwardRef((props, ref) => (
  <ProjectCardEditableTitle forwardedRef={ref} {...props} />
));
