import styled from 'styled-components/macro';

/**
 * Disabled buttons do not trigger mouse events like mouse enter and mouse leave.
 * That makes our Tooltip not work correctly with it since it binds to those events.
 * The blocker is necessary so that the events trigger on it.
 */
const Blocker = styled.div`
  cursor: not-allowed;

  > * {
    pointer-events: none;
  }
`;

export default Blocker;
