import React from 'react';
import { Dropdown } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';

import { contestsUrl } from 'src/routes/urls';

import SwitcherToggleButton from './components/SwitcherToggleButton';
import messages from './messages';

const AppSwitcher = () => (
  <Dropdown icon={<SwitcherToggleButton />}>
    <Dropdown.Menu horizontalOffset="-26px" wrapPortal>
      <Dropdown.Header enableClose>
        <FormattedMessage {...messages.titleAppSwitcher} />
      </Dropdown.Header>
      <Dropdown.Item
        hint={<FormattedMessage {...messages.messagePicterWorkspace} />}
        label={<FormattedMessage {...messages.labelPicterWorkspace} />}
        size="auto"
        active
      />
      <Dropdown.Item
        hint={<FormattedMessage {...messages.messagePicterContests} />}
        href={contestsUrl(undefined, {
          utm_source: 'picter-workspace',
          utm_medium: 'app',
          utm_campaign: 'app-switcher',
        })}
        label={<FormattedMessage {...messages.labelPicterContests} />}
        size="auto"
        link
      />
    </Dropdown.Menu>
  </Dropdown>
);

export default AppSwitcher;
