import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';

import { Box } from 'src/modules/prisma';

const Section = styled(Box).attrs({ gmt: 3 })`
  background: ${themeGet('colors.white')};
  border: 1px solid ${themeGet('colors.cold.gray.200')};
  padding: ${themeGet('space.6')}px ${themeGet('space.6')}px;
  position: relative;
`;

export default Section;
