import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { getSpaceSortBy, parseSpaceSortBy } from 'src/utils/accessors/space';
import { comparator as sortByComparator } from 'src/modules/sorter';
import { get } from 'src/utils/accessors';
import {
  useSpace,
  useSpaceFolders,
  useSpaceProjects,
  useCurrentUser,
} from 'src/hooks/use-resource';

export default function useData() {
  const { spaceId } = useParams();
  const { query } = useLocation();
  const sortBy = getSpaceSortBy({ query, spaceId });
  const [attribute, operator] = parseSpaceSortBy(sortBy);

  const user = useCurrentUser();
  const space = useSpace({
    id: spaceId,
    include: ['users.user', 'plan'],
  });
  const folders = useSpaceFolders(
    { id: spaceId, include: ['count'] },
    {
      page: {
        size: 500,
      },
    },
  );
  const projects = useSpaceProjects(
    {
      id: spaceId,
      include: [
        'invitations',
        'users.user',
        'publicLinks',
        'coverImage',
        'follows',
      ],
      includeOnSelector: ['images'],
    },
    {
      page: {
        size: 500,
      },
      // 5 minutes
      revalidate: true,
      revalidateTimeout: 300000,
      autoRevalidate: true,
    },
  );

  const filteredAndSortedFolders = useMemo(
    () =>
      folders.sort(
        sortByComparator(attribute, operator, {
          transform: value =>
            typeof value === 'string' ? value.toLowerCase() : value,
        }),
      ),
    [attribute, folders, operator],
  );

  const filteredAndSortedProjects = useMemo(
    () =>
      projects
        .filter(p => {
          // not inside a folder
          return (
            !get(p, 'relationships.folder.data.id') &&
            // not in trash
            get(p, 'attributes.deletedAt') === null
          );
        })
        .sort(
          sortByComparator(
            attribute === 'name' ? 'title' : attribute,
            operator,
            {
              transform: value =>
                typeof value === 'string' ? value.toLowerCase() : value,
            },
          ),
        ),
    [attribute, operator, projects],
  );

  return {
    user,
    space,
    folders: filteredAndSortedFolders,
    projects: filteredAndSortedProjects,
  };
}
