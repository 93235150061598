import { defineMessages } from 'react-intl';

const messages = defineMessages({
  labelAddCollection: {
    id: 'CollectionActionsDropdown.labelAddCollection',
    defaultMessage: 'Add to new collection',
  },
  labelRemoveFromCollections: {
    id: 'CollectionActionsDropdown.labelRemoveFromCollections',
    defaultMessage: 'Remove from collections',
  },
  titleModalCreate: {
    id: 'CollectionActionsDropdown.titleModalCreate',
    defaultMessage: 'New collection',
  },
  labelModalCreate: {
    id: 'CollectionActionsDropdown.labelModalCreate',
    defaultMessage: 'Create',
  },
  labelModalCancel: {
    id: 'CollectionActionsDropdown.labelModalCancel',
    defaultMessage: 'Cancel',
  },
  labelUntitledCollection: {
    id: 'CollectionActionsDropdown.labelUntitledCollection',
    defaultMessage: 'Untitled',
  },
  messageCollectionCreated: {
    id: 'CollectionActionsDropdown.messageCollectionCreated',
    defaultMessage: 'Collection {collectionName} created.',
  },
  messageImagesAddedToCollection: {
    id: 'CollectionActionsDropdown.messageImagesAddedToCollection',
    defaultMessage: `{imagesSize, plural,
      =0 {No files}
      one {# file}
      other {# files}
    } added to {collectionName}.`,
  },
  messageImagesRemovedFromCollection: {
    id: 'CollectionActionsDropdown.messageImagesRemovedFromCollection',
    defaultMessage: `{imagesSize, plural,
      =0 {No files}
      one {# file}
      other {# files}
    } removed from {collectionName}.`,
  },
  messageImagesRemovedFromCollections: {
    id: 'CollectionActionsDropdown.messageImagesRemovedFromCollections',
    defaultMessage: `{imagesSize, plural,
      =0 {No files}
      one {# file}
      other {# files}
    } removed from collections.`,
  },
});

export default messages;
