import React, { useCallback, useMemo, useState, useEffect } from 'react';

import { createContextWithAccessors } from '../utils';

const STEP = 0.1;
const MIN_ZOOM = 0.01;
const MAX_ZOOM = 5;

type ZoomState = {
  current: number;
  initial: number;
};

export const [ZoomContext, useZoom] = createContextWithAccessors<
  ZoomState & {
    update: (zoom: Partial<ZoomState>) => void;
    increment: () => number;
    decrement: () => number;
    reset: () => void;
  }
>();

export default function ZoomProvider({
  children,
  id,
}: React.PropsWithChildren<{
  id: string;
}>) {
  const [zoom, setZoom] = useState({ initial: 0, current: 1 });

  const update = useCallback(
    function updateZoom(newZoom: Partial<ZoomState>) {
      setZoom(oldZoom => ({ ...oldZoom, ...newZoom }));
    },
    [setZoom],
  );

  const increment = useCallback(
    function incrementZoom() {
      const newZoom = Math.min(MAX_ZOOM, zoom.current + STEP);
      setZoom(oldZoom => ({
        ...oldZoom,
        current: newZoom,
      }));
      return newZoom;
    },
    [setZoom, zoom],
  );

  const decrement = useCallback(
    function decrementZoom() {
      const newZoom = Math.max(MIN_ZOOM, zoom.current - STEP);
      setZoom(oldZoom => ({
        ...oldZoom,
        current: newZoom,
      }));
      return newZoom;
    },
    [zoom],
  );

  const reset = useCallback(
    function resetZoom() {
      setZoom(oldZoom => ({ ...oldZoom, current: oldZoom.initial }));
    },
    [setZoom],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reset(), [id]);

  const contextValue = useMemo(
    () => ({ ...zoom, update, increment, decrement, reset }),
    [zoom, update, increment, decrement, reset],
  );

  return (
    <ZoomContext.Provider value={contextValue}>{children}</ZoomContext.Provider>
  );
}
