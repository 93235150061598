import { defineMessages } from 'react-intl';

export default defineMessages({
  titleReviewDone: {
    id: 'ReviewPage.ReviewPageReviewDoneModal.titleReviewDone',
    defaultMessage: 'Review done',
  },
  messageLeaveMessage: {
    id: 'ReviewPage.ReviewPageReviewDoneModal.messageLeaveMessage',
    defaultMessage: `Leave {space} a short message to let them know you're done.`,
  },
  messageReviewDone: {
    id: 'ReviewPage.ReviewPageReviewDoneModal.messageReviewDone',
    defaultMessage: `I'm done with my review!`,
  },
  labelFinish: {
    id: 'ReviewPage.ReviewPageReviewDoneModal.labelFinish',
    defaultMessage: 'Finish',
  },
  labelCancel: {
    id: 'ReviewPage.ReviewPageReviewDoneModal.labelCancel',
    defaultMessage: 'Cancel',
  },
});
