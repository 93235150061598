import React from 'react';
import PropTypes from 'prop-types';
import propTypes from '@styled-system/prop-types';

import Bar from '../styles/ProgressBar';
import Container from '../styles/ProgressBarContainer';

const ProgressBar = ({ bgColor, color, progress, size, ...props }) => (
  <Container backgroundColor={bgColor} size={size} {...props}>
    <Bar backgroundColor={color} size={progress} />
  </Container>
);

ProgressBar.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  bgColor: propTypes.color.color,
  color: propTypes.color.color,
  progress: PropTypes.number,
  size: PropTypes.number,
};

ProgressBar.defaultProps = {
  id: null,
  className: null,
  bgColor: 'transparent',
  color: 'primary',
  progress: 0,
  size: 2,
};

export default ProgressBar;
