import kebabCase from 'lodash/kebabCase';

/* eslint-disable no-use-before-define, no-param-reassign, @typescript-eslint/no-use-before-define */
const kebabFormatter = input => {
  if (!input || typeof input !== 'object') {
    return input;
  }

  if (Array.isArray(input)) {
    return input.map(formatter);
  }

  return Object.keys(input).reduce((formattedMap, key) => {
    const newKey = kebabCase(key);
    formattedMap[newKey] = formatter(input[key]);
    return formattedMap;
  }, {});
};
/* eslint-enable no-use-before-define, no-param-reassign, @typescript-eslint/no-use-before-define */

const formatter = value => {
  const shouldFormat =
    value && typeof value === 'object' && !(value instanceof Date);
  return shouldFormat ? kebabFormatter(value) : value;
};

export default kebabFormatter;
