import React from 'react';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

export function blocklistProps(Component, blocklistedProps) {
  return function BlocklistedComponent(props) {
    return <Component {...omit(props, blocklistedProps)} />;
  };
}

export function allowlistProps(Component, allowlistedProps) {
  return function AllowlistedComponent(props) {
    return <Component {...pick(props, allowlistedProps)} />;
  };
}
