import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Add as AddSvg } from '@styled-icons/material';

import { ContextualTextField, Icon, Box } from 'src/modules/prisma';
import { ChooserItem, chooserPropsShape } from 'src/components/EntityChooser';

import messages from '../messages';

export default function CreateItemComponent({ chooserProps, title, setTitle }) {
  return (
    <ChooserItem
      cover={
        <Box m={4}>
          <Icon
            type={<AddSvg />}
            size="medium"
            boxSize="xxlarge"
            color="grey.600"
            borderStyle="solid"
            borderWidth="1px"
            borderColor="grey.200"
          />
        </Box>
      }
      name={
        <FormattedMessage {...messages.titleNewProject}>
          {message => (
            <ContextualTextField
              maxLength="100"
              minWidth="100%"
              onChange={e => setTitle(e.target.value)}
              placeholder={message}
              textStyle="body.regular"
              value={title}
            />
          )}
        </FormattedMessage>
      }
      subheader={<FormattedMessage {...messages.messageCreateNewProject} />}
      {...chooserProps}
    />
  );
}

CreateItemComponent.propTypes = {
  title: PropTypes.string,
  setTitle: PropTypes.func.isRequired,
  chooserProps: chooserPropsShape.isRequired,
};

CreateItemComponent.defaultProps = {
  title: undefined,
};
