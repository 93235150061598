import React from 'react';
import {
  MoveToInbox as MoveToInboxSvg,
  DesktopWindows as DesktopWindowsSvg,
  DoneAll as DoneAllSvg,
} from '@styled-icons/material';

import { publicLinkTypes } from 'src/utils/accessors/project';
import {
  projectShareReviewUrl,
  projectSharePresentationUrl,
  projectShareRequestFilesUrl,
} from 'src/routes/urls';

import messages from './messages';

export const publicLinkMessageMap = {
  [publicLinkTypes.REVIEW]: messages.messageReviewLinkTooltip,
  [publicLinkTypes.PRESENT]: messages.messagePresentLinkTooltip,
  [publicLinkTypes.REQUEST_FILES]: messages.messageRequestFilesLinkTooltip,
};

export const publicLinkIconMap = {
  [publicLinkTypes.REVIEW]: <DoneAllSvg />,
  [publicLinkTypes.PRESENT]: <DesktopWindowsSvg />,
  [publicLinkTypes.REQUEST_FILES]: <MoveToInboxSvg />,
};

export const publicLinkModalUrlMap = {
  [publicLinkTypes.REVIEW]: projectShareReviewUrl,
  [publicLinkTypes.PRESENT]: projectSharePresentationUrl,
  [publicLinkTypes.REQUEST_FILES]: projectShareRequestFilesUrl,
};
