import { useContext } from 'react';

import { DownloadManagerContext } from '../providers/DownloadManagerProvider';

export function useDownloadManager() {
  const context = useContext(DownloadManagerContext);

  if (!context) {
    throw new Error(
      'useDownloadManager must be used within a DownloadManagerContextProvider',
    );
  }

  return context;
}
