import React from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import {
  ButtonElement as Button,
  Card,
  Modal,
  MediaQuery,
} from '@picter/prisma';
import { Flex, Box } from '@rebass/grid';
import Keyboardist from 'react-keyboardist';
import { FormattedMessage } from 'react-intl';
import { CloudDownload as CloudDownloadSvg } from '@styled-icons/material';

import {
  Body,
  ContextualTextField,
  Icon,
  SelectField,
} from 'src/modules/prisma';
import useFormatMessage from 'src/hooks/use-format-message';

import useHandlers from './hooks/use-handlers';
import messages from './messages';

function DownloadButton(props) {
  return (
    <Button
      variant="flat"
      color="primary"
      textStyle="action.large"
      wordWrap="nowrap"
      px={0}
      py={0}
      {...props}
    />
  );
}

function ProjectDownloadModal({ onClose, open, project, images }) {
  const formatMessage = useFormatMessage();
  const {
    advancedVisibility,
    setAdvancedVisibility,
    packageName,
    handleChangePackageName,
    fileNamePatternOptions,
    fileNamePattern,
    handleChangeFileNamePattern,
    handleDownloadProject,
  } = useHandlers({ project, images });

  return (
    <Modal
      title={<FormattedMessage {...messages.titleDownload} />}
      onClickClose={onClose}
      open={open}
    >
      <Keyboardist
        bindings={{
          Escape: () => {
            onClose();
            return false;
          },
        }}
      />
      <div>
        <Card>
          <Card.Content px={0}>
            <Flex alignItems="center" justifyContent="space-between">
              <Box m={3}>
                <Icon
                  type={<CloudDownloadSvg />}
                  size="xxxlarge"
                  color="grey.600"
                />
              </Box>
              <Box flex="1 1 auto">
                <ContextualTextField
                  maxWidth="230px"
                  minWidth="10px"
                  maxLength="250"
                  onChange={handleChangePackageName}
                  textStyle="body.regular"
                  fontWeight="light"
                  value={packageName}
                />
                <Body tag="span">.zip</Body>
                <Body textSize="small" fontWeight="light" color="grey.600">
                  <FormattedMessage
                    {...messages.messageImagesCount}
                    values={{ count: images.size }}
                  />
                </Body>
              </Box>
              {/* download button in desktop */}
              <MediaQuery breakpoint="mobile" type="greaterThan">
                <Box m={3}>
                  <DownloadButton
                    onClick={() => {
                      handleDownloadProject();
                      onClose();
                    }}
                    disabled={packageName === ''}
                  >
                    <FormattedMessage {...messages.labelDownloadFiles} />
                  </DownloadButton>
                </Box>
              </MediaQuery>
            </Flex>
            {/* download button in mobile */}
            <MediaQuery query={{ maxWidth: 0 }}>
              <Box m={3}>
                <DownloadButton
                  onClick={() => {
                    handleDownloadProject();
                    onClose();
                  }}
                  disabled={packageName === ''}
                  width={1}
                >
                  <FormattedMessage {...messages.labelDownloadFiles} />
                </DownloadButton>
              </Box>
            </MediaQuery>
          </Card.Content>
        </Card>
        <Box>
          <Body textAlign="right">
            <Button
              onClick={() => setAdvancedVisibility(!advancedVisibility)}
              variant="flat"
              color="grey.600"
              textStyle="action.small"
              fontWeight="light"
              px={0}
              py={0}
            >
              {advancedVisibility ? (
                <FormattedMessage {...messages.labelHideAdvanced} />
              ) : (
                <FormattedMessage {...messages.labelAdvanced} />
              )}
            </Button>
          </Body>
        </Box>
        {advancedVisibility && (
          <Flex mt={7}>
            <Box width={1}>
              <SelectField
                name="fileNamePattern"
                label={formatMessage(messages.labelFileNamePattern)}
                options={fileNamePatternOptions}
                value={fileNamePattern}
                onChange={handleChangeFileNamePattern}
                required
                hideDefaultPlaceholder
              />
            </Box>
          </Flex>
        )}
      </div>
    </Modal>
  );
}

ProjectDownloadModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  project: PropTypes.instanceOf(Map),
  images: PropTypes.instanceOf(List),
};

ProjectDownloadModal.defaultProps = {
  onClose: undefined,
  open: false,
  project: Map(),
  images: List(),
};

export default ProjectDownloadModal;
