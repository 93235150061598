import { defineMessages } from 'react-intl';

export default defineMessages({
  labelMyAccount: {
    id: 'ProjectPageSidebarActions.labelMyAccount',
    defaultMessage: 'My account',
  },
  labelNotifications: {
    id: 'ProjectPageSidebarActions.labelNotifications',
    defaultMessage: 'Notifications',
  },
  labelSupport: {
    id: 'ProjectPageSidebarActions.labelSupport',
    defaultMessage: 'Support',
  },
  labelHideCollections: {
    id: 'ProjectPageSidebarActions.labelHideCollections',
    defaultMessage: 'Hide collections',
  },
  labelShowCollections: {
    id: 'ProjectPageSidebarActions.labelShowCollections',
    defaultMessage: 'Show collections',
  },
});
