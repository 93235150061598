// @ts-nocheck
// TODO: Solve TS problems with the Tooltip component
import React, { useMemo } from 'react';
import { FormattedDate } from 'react-intl';

import { Text, Tooltip } from 'src/modules/prisma';
import { getDateDeltaFromNowInSeconds } from 'src/utils/date';
import FormattedRelativeTinyTime from './FormattedRelativeTinyTime';

export default function CommentPublicationDate({ date }: { date: number }) {
  const deltaDatePublishedDateNow = useMemo(
    () => getDateDeltaFromNowInSeconds(date),
    [date],
  );

  return (
    <Tooltip>
      <Tooltip.Reference>
        <Text
          className="comment__published mute-on-resolve"
          color="grey.600"
          tag="span"
          textStyle="action.small"
        >
          <FormattedRelativeTinyTime
            numeric="auto"
            unit="second"
            updateIntervalInSeconds={10}
            value={deltaDatePublishedDateNow}
          />
        </Text>
      </Tooltip.Reference>
      <Tooltip.Message>
        <span>
          <FormattedDate
            value={date}
            year="numeric"
            month="short"
            day="numeric"
            hour="numeric"
            second="numeric"
            minute="numeric"
          />
        </span>
      </Tooltip.Message>
    </Tooltip>
  );
}
