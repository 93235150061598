import { createSelector } from 'reselect';
import { List } from 'immutable';
import { select } from 'src/modules/torii';

import { get } from 'src/utils/accessors';

export const collectionSelectorById = createSelector(
  (state, { id }) =>
    select(state, 'wsCollections', {
      id,
    }),
  image => image,
);

const projectCollectionsSelector = createSelector(
  state => state.project,
  project => get(project, 'relationships.collections', List()),
);

export const sortedProjectCollectionsSelector = createSelector(
  projectCollectionsSelector,
  collections =>
    collections.sortBy(collection => get(collection, 'attributes.name')),
);

export const projectCollectionSelectorById = createSelector(
  projectCollectionsSelector,
  (_, { id }) => id,
  (collections, id) =>
    collections.find(collection => get(collection, 'id') === id),
);
