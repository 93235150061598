import styled from 'styled-components/macro';

import { kindVariants, sizeVariants } from './variants';

const HintText = styled.div`
  ${sizeVariants};
  color: ${kindVariants};
  text-align: left;
`;

HintText.defaultProps = {
  id: null,
  className: null,
  kind: 'default',
  size: 'medium',
};

export default HintText;
