import { defineMessages } from 'react-intl';

export default defineMessages({
  titleCollections: {
    id: 'ProjectNavigation.titleCollections',
    defaultMessage: 'Collections',
  },
  labelUntitledProject: {
    id: 'ProjectNavigation.labelUntitledProject',
    defaultMessage: 'Untitled project',
  },
  labelNewProject: {
    id: 'ProjectNavigation.labelNewProject',
    defaultMessage: 'New project',
  },
  labelFilterAll: {
    id: 'ProjectNavigation.labelFilterAll',
    defaultMessage: 'All files',
  },
  labelFilterList: {
    id: 'ProjectNavigation.labelFilterList',
    defaultMessage: 'Filters',
  },
  labelInfo: {
    id: 'ProjectNavigation.labelInfo',
    defaultMessage: 'Info',
  },
  labelFilterApproved: {
    id: 'ProjectNavigation.labelFilterApproved',
    defaultMessage: 'Approved',
  },
  labelFilterRejected: {
    id: 'ProjectNavigation.labelFilterRejected',
    defaultMessage: 'Rejected',
  },
  labelFilterAny: {
    id: 'ProjectNavigation.labelFilterAny',
    defaultMessage: 'Any approvals',
  },
  labelUserApprovalFilter: {
    id: 'ProjectNavigation.labelUserApprovalFilter',
    defaultMessage: 'Approved by {nameOrEmail}',
  },
  labelFilterWithoutFlags: {
    id: 'ProjectNavigation.labelFilterWithoutFlags',
    defaultMessage: 'Undecided',
  },
  labelUntitledCollection: {
    id: 'ProjectNavigation.labelUntitledCollection',
    defaultMessage: 'Untitled',
  },
  labelNewCollection: {
    id: 'ProjectNavigation.labelNewCollection',
    defaultMessage: 'New collection',
  },
  messageImagesAddedToCollection: {
    id: 'ProjectNavigation.messageImagesAddedToCollection',
    defaultMessage: `{imagesSize, plural,
      =0 {No files}
      one {# file}
      other {# files}
    } added to {collectionName}.`,
  },

  // DeleteCollectionModal
  titleDeleteCollectionModal: {
    id: 'DeleteCollectionModal.titleDeleteCollectionModal',
    defaultMessage: 'Delete collection',
  },
  messageDeleteCollectionModal: {
    id: 'DeleteCollectionModal.messageDeleteCollectionModal',
    defaultMessage:
      'This will delete the collection. The included files remain in the project.',
  },

  // DeleteCollectionModal
  titleRenameCollection: {
    id: 'RenameCollectionIcon.titleRenameCollection',
    defaultMessage: 'Rename collection',
  },

  labelCancel: {
    id: 'DeleteCollectionModal.labelCancel',
    defaultMessage: 'Cancel',
  },
  labelDelete: {
    id: 'DeleteCollectionModal.labelDelete',
    defaultMessage: 'Delete',
  },

  // TrashInfoPanel
  messageProjectMovedToTrash: {
    id: 'TrashInfoPanel.messageProjectMovedToTrash',
    defaultMessage: `This project has been moved to trash and can't be shared or edited in this state.`,
  },
  labelRestoreProject: {
    id: 'TrashInfoPanel.labelRestoreProject',
    defaultMessage: 'Restore project',
  },
  messageProjectRestored: {
    id: 'TrashInfoPanel.messageProjectRestored',
    defaultMessage: 'Project restored.',
  },
});
