// @ts-nocheck
import React, { useRef } from 'react';
import { Lens as LensSvg } from '@styled-icons/material';

import {
  Popover,
  PopoverReference,
  PopoverPanel,
  Icon,
} from 'src/modules/prisma';

import { MarkupColor } from '../models/Markup';

function ToolColorChooser({
  children,
  onSelectColor,
}: React.PropsWithChildren<{
  color: MarkupColor;
  onSelectColor: (color: MarkupColor) => void;
}>) {
  const popover = useRef();

  return (
    <Popover placement="top" ref={popover}>
      <PopoverReference>{children}</PopoverReference>
      <PopoverPanel
        arrowColor="black"
        backgroundColor="black"
        display="flex"
        p={2}
      >
        {Object.entries(MarkupColor).map(([colorName, colorHex]) => (
          <Icon
            key={colorName}
            color={colorHex}
            type={<LensSvg />}
            onClick={() => {
              onSelectColor(colorHex);
              popover.current.hide();
            }}
            size="small"
          />
        ))}
      </PopoverPanel>
    </Popover>
  );
}

export default ToolColorChooser;
