import styled from 'styled-components/macro';
import { SquareImage } from '@picter/prisma';

const ImageCardImage = styled(SquareImage).attrs({
  fit: 'inherit',
})`
  object-fit: cover;

  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    object-fit: contain;
  }
`;

export default ImageCardImage;
