import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import paths from 'src/routes/paths';
import { EmptyStatePage } from 'src/components/EmptyStateBox';
import NotFoundIllustrationSvg from 'src/assets/NotFoundIllustrationSvg';
import { Anchor, Button } from 'src/modules/prisma';
import { useCurrentUser } from 'src/hooks/use-resource';
import { $TSFixMe } from 'src/types';

import messages from './messages';

const { HOME_PATH } = paths as $TSFixMe;
const PICTER_LANDING_URL = 'https://www.picter.com';

function NotFoundPage() {
  const user = useCurrentUser();
  const buttonProps = {
    width: '236px',
    justifyContent: 'center',
    textStyle: 'action.regular',
  };

  return (
    <EmptyStatePage
      graphic={<NotFoundIllustrationSvg />}
      title={<FormattedMessage {...messages.messageCouldNotFind} />}
      message={<FormattedMessage {...messages.messageCheckForTypos} />}
      action={
        !user || user?.isEmpty() ? (
          <Anchor href={PICTER_LANDING_URL}>
            <Button {...buttonProps}>
              <FormattedMessage {...messages.labelAboutPicter} />
            </Button>
          </Anchor>
        ) : (
          <Link to={HOME_PATH}>
            <Button {...buttonProps}>
              <FormattedMessage {...messages.labelGoToDashboard} />
            </Button>
          </Link>
        )
      }
      onClickHelp={() => {
        window.Intercom?.('show');
      }}
    />
  );
}

export default NotFoundPage;
