import React, { useCallback } from 'react';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import { ButtonElement as Button } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';

import { Box } from 'src/modules/prisma';
import MentionableInputField from 'src/components/MentionableInputField';

import { COMMENT_INPUT_NAME } from '../constants';
import messages from '../messages';

const formikConfig = {
  displayName: 'CommentForm',
  handleSubmit: async (values, { props: { onSubmit }, resetForm }) => {
    await onSubmit(values[COMMENT_INPUT_NAME]);
    resetForm();
  },
  mapPropsToValues: ({ defaultValue }) => ({
    [COMMENT_INPUT_NAME]: defaultValue || '',
  }),
  validate: values => {
    const errors = {};
    if (!values[COMMENT_INPUT_NAME]) {
      errors[COMMENT_INPUT_NAME] = 'Required';
    }

    return errors;
  },
  validateOnMount: true,
};

function CommentInput({
  autoFocus,
  glowOnFocus,
  onKeyDown,
  inputRef,

  // formik props
  handleBlur,
  onFocus,
  handleChange,
  handleSubmit,
  handleReset,
  isSubmitting,
  isValid,
  submitForm,
  values,
  placeholder,
}) {
  const handleKeyDown = useCallback(
    event => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        submitForm();
      }
      onKeyDown(event);
    },
    [onKeyDown, submitForm],
  );

  return (
    <Box flex="1" position="relative">
      <form onReset={handleReset} onSubmit={handleSubmit}>
        <FormattedMessage {...messages.placeholderCommentInput}>
          {formattedMessage => (
            <MentionableInputField
              ref={inputRef}
              autoFocus={autoFocus}
              glowOnFocus={glowOnFocus}
              maxRows={6}
              name={COMMENT_INPUT_NAME}
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={onFocus}
              onKeyDown={handleKeyDown}
              placeholder={placeholder || formattedMessage}
              submitForm={submitForm}
              value={values[COMMENT_INPUT_NAME]}
            />
          )}
        </FormattedMessage>
        <Box bottom="0" position="absolute" right="0">
          <Button
            color="primary"
            disabled={!isValid || isSubmitting}
            textStyle="action.regular"
            lineHeight="164%"
            type="submit"
            variant="flat"
          >
            <FormattedMessage {...messages.labelCommentInputButton} />
          </Button>
        </Box>
      </form>
    </Box>
  );
}

CommentInput.propTypes = {
  autoFocus: PropTypes.bool,
  glowOnFocus: PropTypes.bool,
  onKeyDown: PropTypes.func,
  inputRef: PropTypes.func,

  // formik props
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string,
};

CommentInput.defaultProps = {
  inputRef: f => f,
  autoFocus: false,
  onFocus: f => f,
  glowOnFocus: false,
  placeholder: null,
  onKeyDown: f => f,
};

export default withFormik(formikConfig)(CommentInput);
