import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, P } from '@picter/prisma';

import { useDownloadManager } from 'src/modules/download-manager';

import messages from './messages';

type ResetDownloadsModalProps = {
  open?: boolean;
  onClose?: () => void;
};

export default function ResetDownloadsModal({
  open,
  onClose,
}: ResetDownloadsModalProps) {
  const { reset } = useDownloadManager();

  return (
    <Modal
      title={<FormattedMessage {...messages.resetModalTitle} />}
      open={open}
    >
      <P>
        <FormattedMessage {...messages.resetModalMessage} />
      </P>

      <div>
        <Button variant="primary" onClick={onClose}>
          <FormattedMessage {...messages.resetModalContinue} />
        </Button>
        <Button
          flat
          onClick={() => {
            reset();
            onClose?.();
          }}
        >
          <FormattedMessage {...messages.resetModalCancel} />
        </Button>
      </div>
    </Modal>
  );
}
