import styled from 'styled-components/macro';
import { space } from 'styled-system';

const ImageCardExtraContainer = styled.div`
  ${space};
  align-items: flex-end;
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
`;

ImageCardExtraContainer.defaultProps = {
  px: 5,
  py: 4,
};

export default ImageCardExtraContainer;
