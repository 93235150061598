import React from 'react';
import PropTypes from 'prop-types';
import { Banner } from '@picter/prisma';

function EmbeddedCampaign({ base, campaign, category }) {
  const url = new URL([category, campaign].join('/'), base);
  return <Banner src={url.toString()} width="100%" height="100%" />;
}

EmbeddedCampaign.propTypes = {
  base: PropTypes.string,
  campaign: PropTypes.string.isRequired,
  category: PropTypes.string,
};

EmbeddedCampaign.defaultProps = {
  base: 'https://embedded-campaigns.picter.com/',
  category: undefined,
};

export default EmbeddedCampaign;
