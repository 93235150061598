import React, { Children, createElement, cloneElement, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { CustomPropTypes } from '@picter/prisma';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import parseUrl from 'url-parse';
import { parse as parseQueryString } from 'qs';

import { Box } from 'src/modules/prisma';

import ItemActions from '../styles/NavigationItemActions';
import ItemElementIcon from '../styles/NavigationItemElementIcon';
import ItemIcon from '../styles/NavigationItemIcon';
import ItemLink from '../styles/NavigationItemLink';
import ItemText from '../styles/NavigationItemText';
import List from '../styles/NavigationList';
import { NavigationControlConsumer } from '../utils/navigation-control';

/**
 * Function that creates the navigation item element according to the properties
 * it has. There's three possibilities:
 *
 * 1. Through "component" property which the component receives "elementProps"
 * automatically.
 * 2. Render props, that receives "elementProps" as first argument.
 * 3. Creates the default navigation item element with a custom/default label.
 *
 * @param {NavigationItemProps} props
 * @param {RouteProps + Active state} elementProps
 */
const createNavigationItemElement = (props, elementProps) => {
  const { component, icon, label, textStyle, render } = props;
  let element = null;

  if (component) {
    element = createElement(component, elementProps);
  }

  if (render) {
    element = render(elementProps);
  }

  if (!element && label) {
    element = (
      <>
        {icon &&
          (typeof icon === 'string' ? (
            <ItemIcon type={icon} />
          ) : (
            <ItemElementIcon type={icon} />
          ))}
        <ItemText textStyle={textStyle}>{label}</ItemText>
      </>
    );
  }

  return element;
};

const renderNestedNavigation = children => (
  <List>
    {Children.map(children, child =>
      cloneElement(child, {
        nested: true,
      }),
    )}
  </List>
);

const parseTo = to => {
  const { pathname, query } = parseUrl(to);
  const queryObject = parseQueryString(query, {
    ignoreQueryPrefix: true,
    comma: true,
  });

  return {
    escapedPath: pathname.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1'),
    queryObject,
  };
};

function NavigationItem(props) {
  const {
    active,
    actions,
    component,
    exact,
    icon,
    label,
    textStyle,
    render,
    to,
    // these props can be used to pass data attributes down to the link element
    ...passThroughProps
  } = props;
  let { children } = props;
  const { escapedPath, queryObject } = parseTo(to);

  return (
    <NavigationControlConsumer>
      {({ onNavigate }) => (
        <Route path={escapedPath}>
          {routeProps => {
            const {
              location: { query },
              match,
            } = routeProps;

            // isActive - path matches current URL
            // isExact - path matches exactly the current URL
            const isActive = active !== undefined ? active : !!match;
            const isExact =
              isActive && match.isExact && isEqual(queryObject, query);

            const elementProps = { ...routeProps, active: isActive };
            const element = createNavigationItemElement(
              { component, icon, label, textStyle, render },
              elementProps,
            );

            // Support function as children to pass along route properties and
            // active state.
            if (isFunction(children)) {
              children = children(elementProps);

              if (children && children.type === Fragment) {
                children = children.props.children; // eslint-disable-line no-param-reassign
              }
            }

            const [pathname, destinyQuery] = to.split('?');

            return (
              <Box position="relative" tag="li" style={{ overflow: 'hidden' }}>
                <ItemLink
                  className={
                    (active || (isActive && ((exact && isExact) || !exact))) &&
                    'active'
                  }
                  onClick={onNavigate}
                  to={{
                    pathname,
                    search: destinyQuery ? `?${destinyQuery}` : undefined,
                    state: passThroughProps.state,
                  }}
                  // these props can be used to pass data attributes down to the link element
                  {...passThroughProps}
                >
                  {element}
                </ItemLink>
                {actions && (
                  <ItemActions>
                    {actions.map(action => (
                      <Box key={action.type} display="inline-block" gml={2}>
                        {action}
                      </Box>
                    ))}
                  </ItemActions>
                )}
                {children && renderNestedNavigation(children)}
              </Box>
            );
          }}
        </Route>
      )}
    </NavigationControlConsumer>
  );
}

NavigationItem.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.element),
  active: PropTypes.bool,
  component: PropTypes.func,
  children: PropTypes.oneOfType([
    CustomPropTypes.elementsOf(NavigationItem),
    PropTypes.func,
    PropTypes.node,
  ]),
  exact: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.node,
  textStyle: PropTypes.oneOf(['body', 'heading']),
  to: PropTypes.string,
  render: PropTypes.func,
};

NavigationItem.defaultProps = {
  actions: undefined,
  active: undefined,
  component: null,
  children: null,
  exact: false,
  icon: null,
  label: 'Navigation item',
  to: '/',
  textStyle: 'heading',
  render: null,
};

export default NavigationItem;
