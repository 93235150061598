import styled from 'styled-components/macro';
import { bodyTextStyle } from '@picter/prisma';

const Suggestion = styled.div`
  ${bodyTextStyle};
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  box-sizing: border-box;
  display: flex;
  height: ${props => props.theme.space[7]}px;
  padding: 0 ${props => props.theme.space[3]}px;
`;

export default Suggestion;
