import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  librarySettingsSpaceCreditsUrl,
  librarySettingsSpaceMembersUrl,
  librarySettingsSpaceGeneralUrl,
  librarySettingsSpaceSubscriptionUrl,
  librarySettingsSpaceNotificationsUrl,
  librarySettingsPersonalProfileUrl,
  librarySettingsPersonalManageAccountUrl,
  librarySettingsSpaceIntegrationsUrl,
} from 'src/routes/urls';
import Navigation from 'src/components/Navigation';
import { useCurrentUser } from 'src/hooks/use-resource';
import {
  isSpaceOwner,
  isSpaceManager,
  isSpacePartnerLicensed,
} from 'src/utils/accessors/space';
import {
  Feature,
  FeatureName,
  SpaceFlagsProvider,
} from 'src/containers/FeatureFlags';
import { get } from 'src/utils/accessors';

function SettingsNavigation() {
  const user = useCurrentUser(
    {
      include: [
        'spaces.users.user',
        'spaces.plan.features',
        'spaces.extraFeatures',
        'spaces.partnerLicense',
      ],
    },
    {
      request: true,
    },
  );
  const spaces = get(user, 'relationships.spaces');

  return (
    <>
      {spaces.map(space => {
        const spaceId = get(space, 'id');
        const isOwner = isSpaceOwner(space, user);
        const isManager = isSpaceManager(space, user);
        const isPartnerLicensed = isSpacePartnerLicensed(space);

        return (
          <Navigation.List key={spaceId}>
            <SpaceFlagsProvider space={space}>
              <Navigation.Header>
                {get(space, 'attributes.name')}
              </Navigation.Header>
              <Feature name={FeatureName.TEAM_MEMBER_INVITATION}>
                <Navigation.Item
                  icon="group"
                  label={
                    <FormattedMessage
                      id="SettingsNavigation.labelMembers"
                      defaultMessage="Members"
                    />
                  }
                  to={librarySettingsSpaceMembersUrl({ spaceId })}
                  textStyle="body"
                />
              </Feature>
              <Navigation.Item
                icon="business"
                label={
                  <FormattedMessage
                    id="SettingsNavigation.labelGeneral"
                    defaultMessage="Customization"
                  />
                }
                to={librarySettingsSpaceGeneralUrl({ spaceId })}
                textStyle="body"
              />
              {isOwner && (
                <Navigation.Item
                  icon="credit_card"
                  label={
                    <FormattedMessage
                      id="SettingsNavigation.labelSubscription"
                      defaultMessage="Billing and Plans"
                    />
                  }
                  to={librarySettingsSpaceSubscriptionUrl({ spaceId })}
                  textStyle="body"
                />
              )}
              <Navigation.Item
                icon="notifications"
                label={
                  <FormattedMessage
                    id="SettingsNavigation.labelNotifications"
                    defaultMessage="Notifications"
                  />
                }
                to={librarySettingsSpaceNotificationsUrl({ spaceId })}
                textStyle="body"
              />
              {isOwner && !isPartnerLicensed && (
                <Navigation.Item
                  icon="redeem"
                  label={
                    <FormattedMessage
                      id="SettingsNavigation.labelEarnCredit"
                      defaultMessage="Earn credit"
                    />
                  }
                  to={librarySettingsSpaceCreditsUrl({ spaceId })}
                  textStyle="body"
                />
              )}
              {(isOwner || isManager) && (
                <Navigation.Item
                  icon="open_in_new"
                  label={
                    <FormattedMessage
                      id="SettingsNavigation.labelConnectedApps"
                      defaultMessage="Connected Apps"
                    />
                  }
                  to={librarySettingsSpaceIntegrationsUrl({ spaceId })}
                  textStyle="body"
                />
              )}
            </SpaceFlagsProvider>
          </Navigation.List>
        );
      })}
      <Navigation.List key="personal">
        <Navigation.Header>
          <FormattedMessage
            id="SettingsNavigation.labelPersonal"
            defaultMessage="Personal"
          />
        </Navigation.Header>
        <Navigation.Item
          icon="person"
          label={
            <FormattedMessage
              id="SettingsNavigation.labelProfile"
              defaultMessage="Profile"
            />
          }
          to={librarySettingsPersonalProfileUrl()}
          textStyle="body"
        />
        <Navigation.Item
          icon="power_settings_new"
          label={
            <FormattedMessage
              id="SettingsNavigation.labelManageAccount"
              defaultMessage="Manage account"
            />
          }
          to={librarySettingsPersonalManageAccountUrl()}
          textStyle="body"
        />
      </Navigation.List>
    </>
  );
}

export default SettingsNavigation;
