import styled from 'styled-components/macro';
import { ButtonElement as Button } from '@picter/prisma';

const ShareButton = styled(Button).attrs({
  borderRadius: '100px',
  color: 'white',
  textStyle: 'action.regular',
  lineHeight: '75%',
})`
  ${props => props.active && `background-color: #8331f5;`};
`;

export default ShareButton;
