import styled from 'styled-components/macro';

import { Box } from 'src/modules/prisma';

const NavigationList = styled(Box)`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

NavigationList.defaultProps = {
  gmt: 3,
  tag: 'ul',
};

export default NavigationList;
