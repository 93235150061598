import { useState, useEffect } from 'react';

export default function useUpdatableState(value) {
  const [newValue, setNewValue] = useState(value);
  const [oldValue, setOldValue] = useState(value);

  useEffect(() => {
    if (value !== oldValue) {
      setNewValue(value);
      setOldValue(value);
    }
  }, [oldValue, value]);

  return [newValue, setNewValue];
}
