import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';

const CommentAside = styled.aside.attrs({
  className: 'comment__aside',
})`
  margin-right: ${themeGet('space.4')}px;
  width: 112px;
  max-width: 100%;
`;

export default CommentAside;
