import { useCallback, useEffect } from 'react';
import { useFormik as useFormikOriginal } from 'formik';
import debounce from 'lodash/debounce';

export default function useFormik({
  submitOnChange,
  submitOnChangeDelay = 1000,
  ...formikConfig
}) {
  const formikProps = useFormikOriginal(formikConfig);
  const { dirty, submitForm, values } = formikProps;

  const submitFormDebounced = useCallback(
    debounce(() => submitForm(), submitOnChangeDelay),
    [submitForm],
  );

  useEffect(() => {
    if (submitOnChange && dirty) {
      submitFormDebounced();
    }
    // Missing dependencies: [`dirty`, `submitFormDebounced`, `submitOnChange`]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return formikProps;
}
