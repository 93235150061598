import React from 'react';
import PropTypes from 'prop-types';
import { FlagsProvider as FlaggedFlagsProvider, useFeatures } from 'flagged';
import merge from 'lodash/merge';

function FlagsProvider({ children, features: fs }) {
  let features;

  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    features = useFeatures();
  } catch {
    features = {};
  }

  return (
    <FlaggedFlagsProvider features={merge(features, fs)}>
      {children}
    </FlaggedFlagsProvider>
  );
}

FlagsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  features: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default FlagsProvider;
