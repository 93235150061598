import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';
import { lighten } from 'polished';
import { variants } from '@picter/prisma';

import { Body, Box } from 'src/modules/prisma';

import Card from './ItemCard';

const ActiveCardAction = variants('selected', {
  [true]: css`
    > div {
      background: ${props => lighten(0.6, themeGet('colors.primary')(props))};
    }
  `,
});

const CardAction = styled(Box).attrs({
  mt: 2,
})`
  cursor: pointer;
  ${ActiveCardAction}
`;

export default function ChooserItem({ name, subheader, id, cover, ...props }) {
  return (
    <CardAction key={id} {...props}>
      <Card name={<Body>{name}</Body>} subheader={subheader} cover={cover} />
    </CardAction>
  );
}

ChooserItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  subheader: PropTypes.element.isRequired,
  cover: PropTypes.element.isRequired,
};
