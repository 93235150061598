import React from 'react';
import { lighten } from 'polished';

import { Markup, MarkupType, PathMarkup, PointMarkup } from '../models/Markup';
import { AnnotationId } from '../models/Annotation';
import { TargetInfo } from '../types';

type MarkupGraphicProps = Pick<TargetInfo, 'targetHeight' | 'targetWidth'> & {
  animated?: boolean;
  highlighted?: boolean;
};

export function PathMarkupGraphic({
  animated,
  highlighted,
  markup,
  strokeWidth,
  targetHeight,
  targetWidth,
}: MarkupGraphicProps & {
  markup: PathMarkup;
  strokeWidth: number;
}) {
  const { color } = markup;
  const svgCoordinates = markup
    .getScaledPath(targetWidth, targetHeight)
    .map(([x, y]) => `${x} ${y}`)
    .join('   ');

  return (
    <g pointerEvents={animated ? 'none' : 'stroke'}>
      <path
        stroke={highlighted ? lighten(0.3, color) : color}
        strokeOpacity={1}
        strokeWidth={strokeWidth}
        fill="transparent"
        d={`M ${svgCoordinates}`}
      >
        {animated && (
          <animate
            attributeName="stroke"
            values={`white;${color};white`}
            dur="2s"
            repeatCount="indefinite"
          />
        )}
      </path>
    </g>
  );
}

function PointMarkupGraphic({
  animated,
  markup,
  targetHeight,
  targetWidth,
  zoom,
  highlighted,
}: MarkupGraphicProps & {
  markup: PointMarkup;
  zoom: number;
}) {
  const { color } = markup;
  const [x, y] = markup.getScaledPosition(targetWidth, targetHeight);
  return (
    <g pointerEvents={animated ? 'none' : 'fill'}>
      <circle
        cx={x}
        cy={y}
        r={11 / zoom}
        fill="transparent"
        strokeWidth={1.5 / zoom}
        stroke={color}
      />
      <circle
        cx={x}
        cy={y}
        r={8 / zoom}
        fill={highlighted ? lighten(0.3, color) : color}
      >
        {animated && (
          <animate
            attributeName="fill"
            values={`white;${color};white`}
            dur="2s"
            repeatCount="indefinite"
          />
        )}
      </circle>
    </g>
  );
}

/* eslint-disable react/no-array-index-key */
export default function MarkupGraphic({
  animated = false,
  highlighted = false,
  id,
  markups,
  strokeWidth,
  targetHeight,
  targetWidth,
  zoom,
}: MarkupGraphicProps & {
  id: AnnotationId;
  markups: Markup[];
  zoom: number;
  strokeWidth: number;
}) {
  return (
    <>
      {markups.map((markup, index) => {
        switch (markup.type) {
          case MarkupType.Path:
            return (
              <PathMarkupGraphic
                animated={animated}
                highlighted={highlighted}
                key={`${id}-${index}`}
                markup={markup as PathMarkup}
                strokeWidth={strokeWidth}
                targetHeight={targetHeight}
                targetWidth={targetWidth}
              />
            );
          case MarkupType.Point:
            return (
              <PointMarkupGraphic
                animated={animated}
                highlighted={highlighted}
                key={`${id}-${index}`}
                markup={markup as PointMarkup}
                targetHeight={targetHeight}
                targetWidth={targetWidth}
                zoom={zoom}
              />
            );
          default:
            return null;
        }
      })}
    </>
  );
}
/* eslint-enable react/no-array-index-key */
