import {
  homeUrl,
  librarySpaceUrl,
  librarySpaceFolderUrl,
  librarySpaceTrashUrl,
} from 'src/routes/urls';

export default function generateProjectBackUrl({
  spaceId,
  folderId,
  isDefaultMode,
  isProjectInTrash,
}) {
  let backUrl = homeUrl();

  if (isDefaultMode) {
    if (spaceId) {
      backUrl = librarySpaceUrl({ spaceId });
    }

    if (folderId) {
      backUrl = librarySpaceFolderUrl({ spaceId, folderId });
    }

    if (isProjectInTrash) {
      backUrl = librarySpaceTrashUrl({ spaceId });
    }
  }

  return backUrl;
}
