export const ACTIONS_TYPES = {
  SELECT: 'SELECT',
  DESELECT: 'DESELECT',
  TOGGLE_SELECT: 'TOGGLE_SELECT',
  SELECT_ALL: 'SELECT_ALL',
  CLEAR: 'CLEAR',
  SET_SHIFT_PREVIEW: 'SET_SHIFT_PREVIEW',
  TOGGLE_SHIFT: 'TOGGLE_SHIFT',
};

// selection states
export const SELECTION_STATES = {
  NOT_SELECTED: 0,
  SHIFT_DESELECT_PREVIEW: 1,
  SELECTED: 2,
};
