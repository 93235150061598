import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Flex } from '@rebass/grid';
import { Icon, MediaQuery } from '@picter/prisma';

import AppSwitcher from 'src/components/AppSwitcher';
import { locationShape } from 'src/utils/app-prop-types';
import { librarySpaceUrl } from 'src/routes/urls';
import SettingsNavigation from 'src/components/SettingsNavigation';
import branchMediaQuery from 'src/hocs/branch-media-query';
import Navigation from 'src/components/Navigation';
import AppLayout from 'src/components/AppLayout';
import SidebarLayout, {
  withSidebarControl,
} from 'src/components/SidebarLayout';
import { getPathMetaData } from 'src/routes/paths';
import { FixedBox } from 'src/styles/PositionBox';
import { Box } from 'src/modules/prisma';
import Notifications from 'src/components/Notifications';
import IntercomLauncher from 'src/components/IntercomLauncher';
import AccountMenu from 'src/components/AccountMenu';
import messages from './_messages';

const Layout = ({ children, onNavigate, location }) => {
  const { params } = useMemo(() => getPathMetaData(location.pathname), [
    location.pathname,
  ]);
  const spaceId = params ? params.spaceId : null;

  return (
    <AppLayout>
      <SidebarLayout.MainSidebar>
        <SidebarLayout.MainSidebar.Content>
          <Box flex="0 1 80px">
            <Flex alignItems="center" p={7}>
              <MediaQuery
                query={{ maxWidth: 0 }}
                render={() => (
                  <Box mr={2}>
                    <Icon
                      glyphSize="medium"
                      onClick={onNavigate}
                      size="large"
                      type="menu"
                      interactive
                    />
                  </Box>
                )}
              />
              <Flex alignItems="flex-end">
                <AppSwitcher />
              </Flex>
            </Flex>
          </Box>
          <Navigation
            backTo={
              <Navigation.BackItem
                to={spaceId ? librarySpaceUrl({ spaceId }) : '/'}
                label={<FormattedMessage {...messages.labelSettingsPage} />}
              />
            }
            density="compact"
            onNavigate={onNavigate}
            type="multiple"
          >
            <SettingsNavigation />
          </Navigation>
        </SidebarLayout.MainSidebar.Content>
        <SidebarLayout.MainSidebar.Bottom>
          <FixedBox bottom={0} left={0} zIndex="sidebar">
            <Flex p={5} pr={0}>
              <Box>
                <AccountMenu />
              </Box>
              <Box gml={2}>
                <Notifications />
              </Box>
              <Box gml={2}>
                <IntercomLauncher />
              </Box>
            </Flex>
          </FixedBox>
        </SidebarLayout.MainSidebar.Bottom>
      </SidebarLayout.MainSidebar>
      <SidebarLayout.Content>{children}</SidebarLayout.Content>
    </AppLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  onNavigate: PropTypes.func,
  location: locationShape.isRequired,
};

Layout.defaultProps = {
  onNavigate: null,
};

export default compose(
  withSidebarControl,
  branchMediaQuery(
    { maxWidth: 0 },
    withProps(({ sidebarControl }) => ({
      onNavigate: sidebarControl.closeMainSidebar,
    })),
  ),
  withRouter,
)(Layout);
