import MakeQuerablePromise from './utils/make-querable-promise';

// Keeps track of cached assets
let cacheMap = {};

// Keeps track of cache validation, useful to force revalidation through
// the revalidate flag
let cacheValidationMap = {};

export const cleanCache = () => {
  cacheMap = {};
  cacheValidationMap = {};
};

const CACHE_VALIDATION_TIMEOUT = 60000;

/*
 * makeLoaderHash()
 * this code is a little ugly but it's optimized for good performance
 *
 * map all things to be loaded (loader)
 * if it's not an array just return (it can be just a string representing the entity)
 * if it's an array return a new array with the config (item[1]) modified
 * overrite the querySerializers in the config changing the value object by
 * an array of strings (the strings are the resolved version of the serializers)
 * reference for resolving the serializer: https://github.com/picter/torii/blob/master/src/utils/add-query-parameters/index.js#L34
 */
export function makeLoaderHash(loader) {
  return JSON.stringify(
    loader.map(item =>
      !Array.isArray(item)
        ? item
        : [
            item[0],
            {
              ...item[1],
              querySerializers: Object.entries(
                item[1].querySerializers || {},
              ).map(([k, v]) => v(item[1][k])),
            },
          ],
    ),
  );
}

export function removeCacheValidation(loaderHash) {
  delete cacheValidationMap[loaderHash];
}

const invalidateHash = (
  loaderHash,
  revalidate,
  revalidateTimeout,
  autoRevalidate,
  backgorundRevalidation,
) => {
  let timeout = CACHE_VALIDATION_TIMEOUT;
  if (revalidate === true) {
    // If true parameter is set, we should revalidate immediatly
    timeout = 1000;
    if (Number.isInteger(revalidateTimeout)) {
      // For performance reasons, revalidate should be at least 1 sec
      timeout = revalidateTimeout >= 1000 ? revalidateTimeout : 1000;
    }
  }

  setTimeout(
    () => {
      // Invalidate cache 1 (+ parameter value) second after the component is mounted
      // This helps to avoid requesting twice on component mount.
      if (cacheValidationMap[loaderHash]) {
        if (autoRevalidate) {
          delete cacheValidationMap[loaderHash];
          return backgorundRevalidation();
        }
        delete cacheValidationMap[loaderHash];
      }
      return null;
    },

    timeout,
  );
};

const toriiLoader = ({
  load,
  loader,
  loaderHash,
  handleError,
  revalidate,
  revalidateTimeout,
  autoRevalidate,
}) => {
  let cachedLoaderPromise = cacheMap[loaderHash];

  const parsedLoader = loader.map(entity =>
    Array.isArray(entity) ? entity : [entity, {}],
  );

  const backgroundRevalidation = () => {
    // If revalidation feature is enabled and there is no entry on
    // validation map, this means that its not first time requesting,
    // component is already mounted and we should trigger a new request
    // on the background to revalidate cache.
    cacheValidationMap[loaderHash] = MakeQuerablePromise(
      Promise.all(
        parsedLoader.map(([entity, config]) =>
          load(entity, config, { revalidate }),
        ),
      ),
    );

    // Just update cache to validated value and delete validation after
    // request is complete.
    cacheValidationMap[loaderHash].then(() => {
      cacheMap[loaderHash] = cacheValidationMap[loaderHash];
      invalidateHash(
        loaderHash,
        revalidate,
        revalidateTimeout,
        autoRevalidate,
        backgroundRevalidation,
      );
    });
  };

  if (!cachedLoaderPromise) {
    // If there is no cached promise, then it is loading for the first time and we
    // should set both cacheMap and cacheValidationMap values
    // eslint-disable-next-line no-multi-assign
    cachedLoaderPromise = cacheValidationMap[loaderHash] = cacheMap[
      loaderHash
    ] = MakeQuerablePromise(
      Promise.all(parsedLoader.map(([entity, config]) => load(entity, config))),
    );

    cachedLoaderPromise
      .then(() =>
        invalidateHash(
          loaderHash,
          revalidate,
          revalidateTimeout,
          autoRevalidate,
          backgroundRevalidation,
        ),
      )
      .catch(e => {
        handleError(e);
        // cleanup promise on error so we dont cache it
        delete cacheMap[loaderHash];
        delete cacheValidationMap[loaderHash];
      });
  }

  if (cachedLoaderPromise.isPending()) {
    // Throwing the request to Suspense and calling handleError on error.
    throw cachedLoaderPromise;
  } else if (!cacheValidationMap[loaderHash]) {
    backgroundRevalidation();
  }
};

export default toriiLoader;
