import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';

import { Box } from 'src/modules/prisma';

const NavigationItemActions = styled(Box)`
  background-color: ${themeGet('colors.grey.100')};
`;

NavigationItemActions.defaultProps = {
  position: 'absolute',
  top: '20%',
  right: '-4px',
};

export default NavigationItemActions;
