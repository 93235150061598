import React from 'react';
import {
  FormattedMessage,
  FormattedNumberParts,
  FormattedRelativeTime,
} from 'react-intl';
import { List } from 'immutable';
import {
  CheckCircle as CheckCircleSvg,
  Person as PersonSvg,
  RadioButtonUnchecked as RadioButtonUncheckedSvg,
} from '@styled-icons/material';
import { mediaQuery } from '@picter/prisma';
import styled from 'styled-components';
import themeGet from '@styled-system/theme-get';

import {
  Body,
  Box,
  Checkbox,
  Heading,
  Icon,
  Panel,
  Text,
} from 'src/modules/prisma';
import { getDateDeltaFromNowInSeconds } from 'src/utils/date';
import { ReferralResource } from 'src/types/resources';
import { tGet } from 'src/utils/accessors';

import { SubscriptionStatus } from '../../subscription/_components/SubscriptionService';
import messages from '../_messages';

const ReferralsTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`;
ReferralsTable.displayName = 'ReferralsTable';

const ReferralRow = styled.tr`
  background-color: ${themeGet('colors.white')};
  border: 1px solid ${themeGet('colors.grey.200')};
`;
ReferralRow.displayName = 'ReferralRow';

const ReferralData = styled.td`
  padding: ${themeGet('space.5')}px;
  width: 30%;

  &:last-of-type {
    text-align: right;
    width: 10%;
  }

  @media screen and ${mediaQuery.lessThan('tablet')} {
    &:nth-of-type(2) {
      display: none;
    }
  }
`;
ReferralData.displayName = 'ReferralData';

const ReferralsDataSummary = styled.td.attrs({
  colSpan: 4,
})`
  padding: ${themeGet('space.5')}px;
  text-align: right;
`;
ReferralsDataSummary.displayName = 'ReferralsDataSummary';

function ReferralStatus({
  status,
  trialExpired,
}: {
  status: SubscriptionStatus;
  trialExpired: boolean;
}) {
  const props = {
    backgroundColor: 'emerald',
    borderRadius: 'small',
    color: 'white',
    px: 2,
    py: 1,
    width: 'max-content',
  };

  if (status === SubscriptionStatus.InTrial) {
    if (trialExpired) {
      return (
        <Panel {...props} backgroundColor="stack">
          <Body color="inherit" fontWeight="strong" textSize="small">
            <FormattedMessage {...messages.labelTrialExpired} />
          </Body>
        </Panel>
      );
    }

    return (
      <Panel {...props} backgroundColor="dodgerBlue">
        <Body color="inherit" fontWeight="strong" textSize="small">
          <FormattedMessage {...messages.labelInTrial} />
        </Body>
      </Panel>
    );
  }

  return (
    <Panel {...props} backgroundColor="emerald">
      <Body color="inherit" fontWeight="strong" textSize="small">
        <FormattedMessage {...messages.labelSubscribed} />
      </Body>
    </Panel>
  );
}

export default function ReferralsCreditBalance({
  referrals = List(),
  rewardForReferral = 0,
  successfulReferrals = 0,
}: {
  referrals?: List<ReferralResource>;
  rewardForReferral?: number;
  successfulReferrals?: number;
}) {
  if (referrals.size <= 0) return null;

  const totalCredit = rewardForReferral * successfulReferrals;

  return (
    <section>
      <Heading tag="h2" fontWeight="light" textSize="large">
        <FormattedMessage {...messages.titleReferralsCreditBalance} />
      </Heading>
      <Box mt={5}>
        <ReferralsTable>
          <tbody>
            {referrals.map(referral => {
              if (!referral) return null;

              const referralStatus = tGet(referral, 'attributes', 'status');
              const isInTrial = referralStatus === SubscriptionStatus.InTrial;
              const isTrialExpired = isInTrial
                ? getDateDeltaFromNowInSeconds(
                    tGet(referral, 'attributes', 'trialEndsAt'),
                  ) < 0
                : false;

              return (
                <ReferralRow key={tGet(referral, 'id')}>
                  <ReferralData>
                    <Box
                      alignItems="center"
                      display="inline-flex"
                      flexDirection="row"
                    >
                      <Icon color="grey.600" type={<PersonSvg />} />
                      <Box display="inline-block" ml={4} />
                      <Text fontFamily="sansSerif" textStyle="heading.small">
                        {tGet(referral, 'attributes', 'ownerName')}
                      </Text>
                    </Box>
                  </ReferralData>
                  <ReferralData>
                    <Body>
                      <FormattedMessage
                        {...messages.labelSignedUpDate}
                        values={{
                          signUpDate: (
                            <FormattedRelativeTime
                              value={getDateDeltaFromNowInSeconds(
                                tGet(referral, 'attributes', 'signupAt'),
                              )}
                              numeric="auto"
                              unit="second"
                              updateIntervalInSeconds={10}
                            />
                          ),
                        }}
                      />
                    </Body>
                  </ReferralData>
                  <ReferralData>
                    <ReferralStatus
                      status={referralStatus}
                      trialExpired={isTrialExpired}
                    />
                  </ReferralData>
                  <ReferralData>
                    <Box
                      alignItems="center"
                      display="inline-flex"
                      flexDirection="row"
                    >
                      <Text
                        color={isInTrial ? 'grey.400' : 'black'}
                        fontFamily="sansSerif"
                        textStyle="heading.small"
                      >
                        <FormattedNumberParts
                          style="currency"
                          currency="EUR"
                          value={rewardForReferral}
                        >
                          {parts => (
                            <>
                              {parts[0].value}
                              {parts[1].value}
                            </>
                          )}
                        </FormattedNumberParts>
                      </Text>
                      <Box display="inline-block" ml={4} />
                      <Checkbox
                        defaultColor="grey.400"
                        defaultSvg={RadioButtonUncheckedSvg}
                        checkedColor="emerald"
                        checkedSvg={CheckCircleSvg}
                        checked={!isInTrial}
                        readOnly
                      />
                    </Box>
                  </ReferralData>
                </ReferralRow>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <ReferralsDataSummary>
                <Text
                  color="black"
                  fontFamily="sansSerif"
                  textStyle="heading.small"
                >
                  <FormattedMessage {...messages.messageTotalCreditEarned} />
                </Text>
                <Box display="inline-block" ml={4} />
                <Text
                  color="black"
                  fontFamily="sansSerif"
                  textStyle="heading.small"
                >
                  <FormattedNumberParts
                    style="currency"
                    currency="EUR"
                    value={totalCredit}
                  >
                    {parts => (
                      <>
                        {parts[0].value}
                        {parts[1].value}
                      </>
                    )}
                  </FormattedNumberParts>
                </Text>
                <Box display="inline-block" ml={4} />
                <span
                  role="img"
                  aria-label="money emoji"
                  style={{
                    display: 'inline-block',
                    height: '20px',
                    width: '20px',
                    marginRight: '5px',
                    marginLeft: '-5px',
                  }}
                >
                  🤑
                </span>
              </ReferralsDataSummary>
            </tr>
          </tfoot>
        </ReferralsTable>
      </Box>
    </section>
  );
}
