import React from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import { useSwipeable } from 'react-swipeable';

import ContentContainer from '../styles/ContentContainer';
import { useLayoutControls } from '../utils';

const Content = ({ id, className, children, ...props }) => {
  const controls = useLayoutControls();
  const swipeHandlers = useSwipeable({
    onSwipedRight: controls.openMainSidebar,
    preventDefaultTouchmoveEvent: true,
  });

  return (
    <ContentContainer
      id={id}
      className={className}
      {...swipeHandlers}
      {...props}
    >
      {isFunction(children) ? children(controls) : children}
    </ContentContainer>
  );
};

Content.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  as: PropTypes.oneOf(['main', 'section']),
};

Content.defaultProps = {
  id: null,
  className: null,
  children: null,
  as: null,
};

export default Content;
