import React from 'react';
import PropTypes from 'prop-types';
import { DetailLayout } from '@picter/prisma';
import { Route, Switch } from 'react-router-dom';
import { trackerShape } from '@picter/tracker';
import { Map } from 'immutable';

import AppLayout from 'src/components/AppLayout';
import paths from 'src/routes/paths';
import { Sidebar, Header, Info, Grid } from '../_sections';

const { LIBRARY_PROJECT_INFO_PATH } = paths;

const LayoutMobile = ({ tracker, publicLink, onClickReviewDone }) => {
  return (
    <>
      <AppLayout.Navigation defaultHeader={false}>
        <Sidebar onMobile />
      </AppLayout.Navigation>
      <AppLayout.Content>
        <DetailLayout>
          <Header
            tracker={tracker}
            publicLink={publicLink}
            onClickReviewDone={onClickReviewDone}
            onMobile
          />
          <DetailLayout.Content>
            <Switch>
              <Route path={LIBRARY_PROJECT_INFO_PATH} render={() => <Info />} />
              <Route render={() => <Grid onMobile />} />
            </Switch>
          </DetailLayout.Content>
        </DetailLayout>
      </AppLayout.Content>
    </>
  );
};

LayoutMobile.propTypes = {
  tracker: trackerShape.isRequired,
  publicLink: PropTypes.instanceOf(Map).isRequired,
  onClickReviewDone: PropTypes.func.isRequired,
};

export default LayoutMobile;
