import React from 'react';
import { KeyboardArrowDown as KeyboardArrowDownSvg } from '@styled-icons/material';
import styled from 'styled-components';
import {
  Box,
  Body,
  Dropdown as PrismaDropdown,
  Icon,
} from 'src/modules/prisma';
import { FormattedMessage } from 'react-intl';

// @ts-ignore
const Dropdown = styled(PrismaDropdown)`
  div[class*='dropdown-menu'] {
    left: unset !important;
    right: 0 !important;
  }
`;

export default function OptionsDropdown<T>({
  options,
  value,
  onChange,
  renderToggle = ({ active, onClick }) => (
    <Box alignItems="center" display="flex" onClick={onClick}>
      <Body fontWeight="regular" style={{ cursor: 'pointer' }} textSize="large">
        <FormattedMessage
          id="OptionsDropdown.labelToggle"
          defaultMessage="Toggle"
        />
      </Body>
      <Box display="inline-block" ml={1} />
      <Icon
        active={active}
        type={<KeyboardArrowDownSvg />}
        backgroundColor="white"
        color="black"
        size="medium"
        interactive
      />
    </Box>
  ),
}: {
  options: {
    label: React.ReactNode | string;
    value: T;
  }[];
  value: T;
  onChange: (value: T) => void;
  renderToggle: (props: {
    active?: boolean;
    onClick?: React.MouseEventHandler;
  }) => React.ReactNode;
}) {
  const dropdownRef = React.useRef<{ closeMenu: () => void }>();

  return (
    // @ts-ignore
    <Dropdown icon={renderToggle} ref={dropdownRef}>
      {/* @ts-ignore */}
      <Dropdown.Menu>
        {options.map(({ label: optionLabel, value: optionValue }) => (
          // @ts-ignore
          <Dropdown.Item
            active={value === optionValue}
            key={String(optionValue)}
            label={optionLabel}
            onClick={() => {
              onChange(optionValue);
              dropdownRef.current?.closeMenu();
            }}
            size="normal"
          />
        ))}
        {/* @ts-ignore */}
      </Dropdown.Menu>
    </Dropdown>
  );
}
