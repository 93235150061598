import React from 'react';
import PropTypes from 'prop-types';
import { TrackerScope } from '@picter/tracker';

import SubscriptionService from './_components/SubscriptionService';

const { REACT_APP_API_URL, REACT_APP_CHARGEBEE_SITE } = process.env;

function Document({ children }) {
  return (
    <TrackerScope name="Subscription">
      <SubscriptionService
        endpoint={`${REACT_APP_API_URL}/service-payment/charge-bee/spaces/`}
        site={REACT_APP_CHARGEBEE_SITE}
      >
        {children}
      </SubscriptionService>
    </TrackerScope>
  );
}

Document.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Document;
