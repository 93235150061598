import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';

const DropdownItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${themeGet('space.4')}px ${themeGet('space.5')}px
    ${themeGet('space.4')}px ${themeGet('space.5')}px !important;
  min-width: 230px;
  ${props =>
    props.hovering
      ? `background-color: ${props.theme.colors.gray['100']};`
      : ''}

  > i {
    margin-right: ${themeGet('space.3')}px;
  }

  div {
    padding: 0 !important;
  }
`;

export default DropdownItemWrapper;
