import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { PopoverDisclosure } from 'reakit/Popover';
import isFunction from 'lodash/isFunction';
import classNames from 'classnames';
import { CustomPropTypes } from '@picter/prisma';

import { usePopover } from '../providers';

/**
 * Properties that should be passed to the HTML element responsible for
 * showing/hiding the popover content.
 *
 * @typedef HtmlProps
 * @type {Object}
 *
 * @property {string} aria-describedby
 * @property {Function} onBlur
 * @property {Function} onFocus
 * @property {Function} onMouseEnter
 * @property {Function} onMouseLeave
 * @property {Object} ref - { current: Object }
 * @property {number} tabIndex - Accesibility related
 */

/**
 * Based on Reakit's Popover Disclosure
 *
 * @see https://reakit.io/docs/popover/#popoverdisclosure
 */
function PopoverReference({ children, className, ...props }) {
  const popover = usePopover();

  return (
    <PopoverDisclosure
      className={classNames(className, 'popover__reference')}
      {...popover}
      {...props}
    >
      {(htmlProps /** @type {HtmlProps} */) =>
        isFunction(children)
          ? children(popover, htmlProps)
          : cloneElement(children, {
              ...htmlProps,
              ...children.props, // prevent overwriting properties assign to children
            })
      }
    </PopoverDisclosure>
  );
}

PopoverReference.propTypes = {
  children: CustomPropTypes.oneOrMoreOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  className: PropTypes.string,
};

PopoverReference.defaultProps = {
  className: undefined,
};

export default PopoverReference;
