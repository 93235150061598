import styled, { css } from 'styled-components/macro';
import { transparentize } from 'polished';

const hoveredStyle = css`
  background-color: ${({ theme }) => transparentize(0.9, theme.colors.primary)};
  > li a {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const CollectionNavigationItemWrapper = styled.div`
  > li a {
    padding-left: 48px;
  }

  ${({ isOver }) => isOver && hoveredStyle}
`;

export default CollectionNavigationItemWrapper;
