import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import useRealtimeSocket from '../hooks/use-realtime-socket';

export const ACTION_TYPES = Object.freeze({
  ADD_MEMBER: 'add-member',
  REMOVE_MEMBER: 'remove-member',
  RESET: 'reset',
});

const initialState = { subscribed: false, selectedId: null, members: [] };

function dataStore(state, { type, payload = initialState }) {
  switch (type) {
    case ACTION_TYPES.ADD_MEMBER:
      return {
        ...state,
        members: [...state.members, payload],
      };
    case ACTION_TYPES.REMOVE_MEMBER: {
      const { id: payloadSelectedId } = payload;
      return {
        ...state,
        members: state.members.filter(
          member => member.id !== payloadSelectedId,
        ),
      };
    }
    case ACTION_TYPES.RESET:
      return payload;
    default:
      return state;
  }
}

export const PresenceContext = createContext();
export const PresenceDispatchContext = createContext();

export default function PresenceProvider({ children, channel }) {
  const socket = useRealtimeSocket();
  const [data, dispatch] = useReducer(dataStore, initialState);

  useEffect(() => {
    if (!channel) {
      throw new Error('The channel name is required to subscribe to presence');
    }

    const channelName = `presence-${channel}`;

    const subscription = socket.subscribe(channelName);

    subscription.bind('pusher:subscription_succeeded', initialMembers => {
      const payload = { subscribed: true, members: [] };
      initialMembers.each(member => payload.members.push(member));
      dispatch({ type: ACTION_TYPES.RESET, payload });
    });

    subscription.bind('pusher:member_added', payload => {
      dispatch({ type: ACTION_TYPES.ADD_MEMBER, payload });
    });

    subscription.bind('pusher:member_removed', payload => {
      dispatch({ type: ACTION_TYPES.REMOVE_MEMBER, payload });
    });

    return () => {
      socket.unsubscribe(channelName);
      dispatch({ type: ACTION_TYPES.RESET });
    };
  }, [channel, socket]);

  return (
    <PresenceContext.Provider value={data}>{children}</PresenceContext.Provider>
  );
}

PresenceProvider.propTypes = {
  children: PropTypes.node.isRequired,
  channel: PropTypes.string.isRequired,
};
