import styled from 'styled-components/macro';

// the props actually exist, but how they are exported
// confuses the linter and causes errors
// eslint-disable-next-line import/named
import { flexbox } from 'src/modules/prisma';

type Props = {
  flex: string;
};
const SidebarBottom = styled.div<Props>`
  ${flexbox};
`;

SidebarBottom.defaultProps = {
  flex: '0 1 80px',
};

export default SidebarBottom;
