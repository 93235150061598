import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { useLocation, useParams } from 'react-router-dom';
import { trackerShape } from '@picter/tracker';

import { DoneAll as DoneAllSvg } from '@styled-icons/material';

import ProjectPageHeader from 'src/components/ProjectPageHeader';
import ProjectPageHeaderMobile from 'src/components/ProjectPageHeaderMobile';
import { Icon as SquareIcon } from 'src/modules/prisma';
import { useProject } from 'src/hooks/use-resource';
import useProjectImages from 'src/hooks/use-project-images';
import { projectCollectionSelectorById } from 'src/selectors/collection';
import { get } from 'src/utils/accessors';

import useHandlers from '../_hooks/use-handlers';

const Header = ({ tracker, onMobile, onClickReviewDone }) => {
  const { projectId, collectionId } = useParams();
  const { query: { filter } = {} } = useLocation();

  const project = useProject(
    {
      id: projectId,
      include: ['images', 'collections.images.image', 'follows', 'space'],
    },
    { request: true },
  );

  const images = useProjectImages({
    project,
    collection: get(project, 'relationships.collections', List()).find(
      col => get(col, 'id') === collectionId,
    ),
    filter,
  });

  const collection = projectCollectionSelectorById(
    { project },
    { id: collectionId },
  );

  const { projectDownloadModalState, projectDownloadModal } = useHandlers({
    project,
    images,
    tracker,
  });

  return (
    <>
      {onMobile ? (
        <ProjectPageHeaderMobile
          page="review"
          showMenuIcon
          project={project}
          onClickDownloadProject={projectDownloadModalState.open}
        />
      ) : (
        <ProjectPageHeader
          page="review"
          project={project}
          collection={collection}
          onClickDownloadProject={projectDownloadModalState.open}
          onClickReviewDone={onClickReviewDone}
          PageIcon={
            <SquareIcon
              color="white"
              type={<DoneAllSvg color="white" />}
              bg="info"
              borderRadius="medium"
              size="medium"
              boxSize="xxlarge"
            />
          }
        />
      )}
      {projectDownloadModal}
    </>
  );
};

Header.propTypes = {
  tracker: trackerShape.isRequired,
  onMobile: PropTypes.bool.isRequired,
  onClickReviewDone: PropTypes.func.isRequired,
};

export default Header;
