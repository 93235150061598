import styled from 'styled-components/macro';

const ProjectCardGrid = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: ${props => props.theme.space[4]}px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  position: relative;
  width: 100%;

  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;

export default ProjectCardGrid;
