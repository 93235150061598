import styled from 'styled-components/macro';

/**
 * TODO: Remove this workaround when we have a more flexible Dropdown.
 *
 * Workaround to fix the dropdown menu to the right of the toggle so it doesn't
 * gets hidden by the dynamic size list `overflow: auto`.
 */
const CommentDropdown = styled.div.attrs({
  className: 'comment__dropdown',
})`
  div[class*='dropdown-menu'] {
    left: unset !important;
    right: 0 !important;
  }
`;

export default CommentDropdown;
