import React, { useEffect, useCallback } from 'react';
import { toastRaw, TOAST_POSITION } from '@picter/prisma';
import debounce from 'lodash/debounce';

import executeInCase from 'src/utils/execute-in-case';

import ErrorsToast from './ErrorToast';

const GroupControlledUploadError = props => {
  const onError = useCallback(
    debounce(({ errors, id, clearGroupErrors }) => {
      const toastId = `error_toast_${id}`;
      executeInCase({
        [errors.length > 0 && !toastRaw.isActive(toastId)]: () => {
          // If toast with this id is not active, and we have error,
          // then we should activate the toast with the error data.
          return toastRaw(
            <ErrorsToast
              id={id}
              errors={errors}
              handleClickDismiss={clearGroupErrors}
            />,
            {
              autoClose: false,
              closeOnClick: false,
              position: TOAST_POSITION.BOTTOM_RIGHT,
              toastId,
            },
          );
        },
        [errors.length > 0 && toastRaw.isActive(toastId)]: () => {
          // If toast is already active and we still have errors,
          // then we should update toast data.
          return toastRaw.update(toastId, {
            render: (
              <ErrorsToast
                id={id}
                errors={errors}
                handleClickDismiss={clearGroupErrors}
              />
            ),
          });
        },
        [errors.length === 0 && toastRaw.isActive(toastId)]: () => {
          // If toast is already active and we have no errors,
          // then we should dismiss the toast.
          return toastRaw.dismiss(toastId);
        },
      });
    }, 300),
    [],
  );

  const { id, errors, clearGroupErrors } = props;

  useEffect(
    () => onError({ id, errors, clearGroupErrors }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, errors],
  );

  return null;
};

export default GroupControlledUploadError;
