import React from 'react';

import { Grid } from 'src/modules/prisma';

export default function CardGrid({ children }: { children: React.ReactNode }) {
  return (
    <Grid
      display="grid"
      gridAutoRows="minmax(400px, max-content)"
      gridTemplateColumns="repeat(auto-fill, minmax(250px, 1fr))"
      gridGap={5}
      tag="section"
    >
      {children}
    </Grid>
  );
}
