import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';
import { Box } from 'src/modules/prisma';

const PlansGrid = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;

  @media screen and (min-width: ${props => props.theme.breakpoints[2]}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: ${themeGet('space.3')}px;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'header-1 header-2 header-3'
      'main-1 main-2 main-3'
      'footer-1 footer-2 footer-3';
    justify-items: stretch;
  }
`;

export default PlansGrid;
