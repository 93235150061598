import { defineMessages } from 'react-intl';

export default defineMessages({
  labelGeneralPage: {
    id: 'SpaceGeneralPage.labelGeneralPage',
    defaultMessage: 'Customization',
  },
  labelBusinessName: {
    id: 'SpaceGeneralPage.labelBusinessName',
    defaultMessage: 'Business name',
  },
  labelBusinessIcon: {
    id: 'SpaceGeneralPage.labelBusinessIcon',
    defaultMessage: 'Upload your company logo',
  },
  messageBusinessIcon: {
    id: 'SpaceGeneralPage.messageBusinessIcon',
    defaultMessage:
      'Help clients to recognize your brand. Allowed file types: PNG and JPG',
  },
  requiredField: {
    id: 'SpaceGeneralPage.requiredField',
    defaultMessage: 'Required field',
  },
});
