import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { loginUrl } from 'src/routes/urls';
import { useCurrentUser } from 'src/hooks/use-resource';
import useModal from 'src/hooks/use-modal';

import LiteUserRegisterModal from 'src/components/LiteUserRegisterModal';

import FloatingCommentForm from './FloatingCommentForm';

const commentState = { frame: null, geometry: null, text: null };

function FloatingCommentFormWithLiteUserSupport({ onSubmit, ...props }) {
  const user = useCurrentUser();
  const [comment, setComment] = useState(commentState);
  const [liteUserRegisterModal, { open: openLiteUserRegisterModal }] = useModal(
    <LiteUserRegisterModal
      onLoginRedirect={location => ({
        pathname: loginUrl(),
        search: `?redirectUrl=${[location.pathname, location.search].join('')}`,
        state: {
          comment,
        },
      })}
      onRegister={async () => {
        await onSubmit(comment);
        setComment(commentState);
      }}
    />,
  );

  const handleSubmit = useCallback(
    async ({ frame, geometry, text }) => {
      if (!user || user.isEmpty()) {
        setComment({ frame, geometry, text });
        openLiteUserRegisterModal();
      } else {
        await onSubmit({ frame, geometry, text });
      }
    },
    [user, onSubmit, openLiteUserRegisterModal, setComment],
  );

  return (
    <>
      <FloatingCommentForm {...props} onSubmit={handleSubmit} />
      {liteUserRegisterModal}
    </>
  );
}

FloatingCommentFormWithLiteUserSupport.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default FloatingCommentFormWithLiteUserSupport;
