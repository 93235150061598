import { useEffect, useCallback } from 'react';
// copied from https://usehooks.com/useKeyPress/
// Hook
export default function useKeyPress(targetKey, downHandler, upHandler) {
  // State for keeping track of whether key is pressed
  // If pressed key is our target key then set to true
  const handleKeyDown = useCallback(
    event => {
      if (
        event.key === targetKey &&
        downHandler &&
        event.target.tagName !== 'INPUT' &&
        event.target.tagName !== 'TEXTAREA'
      ) {
        downHandler();
      }
    },
    [downHandler, targetKey],
  );

  // If released key is our target key then set to false
  const handleKeyUp = useCallback(
    ({ key }) => {
      if (key === targetKey && upHandler) {
        upHandler();
      }
    },
    [upHandler, targetKey],
  );

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]); // Empty array ensures that effect is only run on mount and unmount
}
