import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';
import { Card } from '@picter/prisma';
import { ellipsis } from 'polished';
import { FormattedRelativeTime, FormattedMessage } from 'react-intl';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
  FolderOpen as FolderOpenSvg,
  ArrowDropDown as ArrowDropDownSvg,
  ArrowDropUp as ArrowDropUpSvg,
} from '@styled-icons/material';

import { Body, Box, ContextualTextField, Icon } from 'src/modules/prisma';
import MessageTooltip from 'src/components/MessageTooltip';
import MaybeLink from 'src/components/MaybeLink';
import ProjectShareState from 'src/components/ProjectShareState';
import FolderCard from 'src/components/FolderCard';
import * as ProjectCardGrid from 'src/components/ProjectCardGrid';
import { get } from 'src/utils/accessors';
import { getDateDeltaFromNowInSeconds } from 'src/utils/date';
import { librarySpaceUrl, librarySpaceFolderUrl } from 'src/routes/urls';
import { SpaceSortBy, SPACE_SORT_QUERY_KEY } from 'src/utils/accessors/space';
import { Operator as SortByOperator } from 'src/modules/sorter';

import SpaceViewChooser from './SpaceViewChooser';
import messages from '../_messages';

function isOfOrder(sortStr, order) {
  const regex = new RegExp(`${order}$`, 'i');
  return sortStr.match(regex) !== null;
}

function SortByLinkItem({ to, label, icon, active }) {
  return (
    <Link to={to}>
      <Box alignItems="center" display="flex" role="button">
        <Body
          color={active ? 'primary' : 'grey.600'}
          fontWeight="light"
          style={{ cursor: 'pointer' }}
          textSize="regular"
        >
          {label}
        </Body>
        <Box ml={1}>
          <Icon color="grey.600" size="medium" type={icon} interactive />
        </Box>
      </Box>
    </Link>
  );
}

SortByLinkItem.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired,
};

function ContentListHeader() {
  const { query } = useLocation();
  const { folderId, spaceId } = useParams();
  const isFolderPage = !!folderId;
  const buildUrl = isFolderPage ? librarySpaceFolderUrl : librarySpaceUrl;
  const activeSort =
    query[SPACE_SORT_QUERY_KEY] || SpaceSortBy.UPDATED_AT.DESC();

  const isActive = useCallback(sort => activeSort.match(sort()), [activeSort]);

  const getNextSort = useCallback(
    (sortByAttribute, initialOrder) => {
      const sortStr = sortByAttribute();
      let nextOrder = initialOrder;

      if (activeSort.match(sortStr) !== null) {
        if (isOfOrder(activeSort, SortByOperator.DESC)) {
          nextOrder = SortByOperator.ASC.toUpperCase();
        } else {
          nextOrder = SortByOperator.DESC.toUpperCase();
        }
      }

      return sortByAttribute[nextOrder]();
    },
    [activeSort],
  );

  const sortLinks = useMemo(
    () => [
      {
        message: 'labelTitleSort',
        sort: SpaceSortBy.NAME,
        initialOrder: SortByOperator.ASC.toUpperCase(),
        width: 0.35,
      },
      {
        message: 'labelCreatedAtSort',
        sort: SpaceSortBy.CREATED_AT,
        initialOrder: SortByOperator.DESC.toUpperCase(),
        width: 0.25,
      },
      {
        message: 'labelUpdatedAtSort',
        sort: SpaceSortBy.UPDATED_AT,
        initialOrder: SortByOperator.DESC.toUpperCase(),
        width: 0.25,
      },
    ],
    [],
  );

  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center" mb={6}>
      {sortLinks.map(({ message, sort, initialOrder, width }) => {
        const nextSort = getNextSort(sort, initialOrder);
        const isLinkActive = isActive(sort);
        let icon =
          initialOrder === SortByOperator.ASC.toUpperCase() ? (
            <ArrowDropUpSvg />
          ) : (
            <ArrowDropDownSvg />
          );

        if (isLinkActive) {
          icon = isOfOrder(nextSort, SortByOperator.ASC) ? (
            <ArrowDropDownSvg />
          ) : (
            <ArrowDropUpSvg />
          );
        }

        return (
          <Box width={width}>
            <SortByLinkItem
              to={buildUrl(
                { folderId, spaceId },
                { ...query, [SPACE_SORT_QUERY_KEY]: nextSort },
              )}
              label={<FormattedMessage {...messages[message]} />}
              icon={icon}
              active={isLinkActive}
            />
          </Box>
        );
      })}
      <Box width={0.15} display="flex" justifyContent="flex-end">
        <SpaceViewChooser />
      </Box>
    </Box>
  );
}

const TruncatedBody = styled(Body)`
  ${ellipsis()}
`;

const ImageIconContainer = styled('div')`
  img {
    width: ${themeGet('sizes.icon.xxlarge')}px;
    height: ${themeGet('sizes.icon.xxlarge')}px;
    object-fit: cover;
  }

  > div {
    width: ${themeGet('sizes.icon.xxlarge')}px;
    height: ${themeGet('sizes.icon.xxlarge')}px;
    padding: ${themeGet('space.1')}px;
  }
`;

const ContentList = styled(Box).attrs({
  display: 'flex',
  flexDirection: 'column',
})`
  & > div:not(:last-child) {
    border-bottom: none;
  }
`;

function ContentItem({
  link,
  icon,
  title,
  count,
  createdAt,
  updatedAt,
  children,
}) {
  const deltaCreatedAt = useMemo(
    () => getDateDeltaFromNowInSeconds(createdAt),
    [createdAt],
  );

  const deltaUpdatedAt = useMemo(
    () => getDateDeltaFromNowInSeconds(updatedAt),
    [updatedAt],
  );

  return (
    <Card>
      <Card.Content px={4} py={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box pr={3} display="flex" alignItems="center" width={0.35}>
            <MaybeLink to={link}>{icon}</MaybeLink>
            <Box ml={3} width="calc(100% - 36px)">
              <Body>{title}</Body>
              <MaybeLink to={link}>
                <Body textSize="small" color="grey.600" fontWeight="light">
                  {count}
                </Body>
              </MaybeLink>
            </Box>
          </Box>
          <Box pr={3} width={0.25}>
            <MaybeLink to={link}>
              <Body whiteSpace="nowrap">
                <FormattedRelativeTime
                  numeric="auto"
                  unit="second"
                  updateIntervalInSeconds={10}
                  value={deltaCreatedAt}
                />
              </Body>
            </MaybeLink>
          </Box>
          <Box pr={3} width={0.25}>
            <MaybeLink to={link}>
              <Body whiteSpace="nowrap">
                <FormattedRelativeTime
                  numeric="auto"
                  unit="second"
                  updateIntervalInSeconds={10}
                  value={deltaUpdatedAt}
                />
              </Body>
            </MaybeLink>
          </Box>
          <Box width={0.15} display="flex" justifyContent="flex-end">
            {children}
          </Box>
        </Box>
      </Card.Content>
    </Card>
  );
}

ContentItem.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  count: PropTypes.node.isRequired,
  createdAt: PropTypes.node.isRequired,
  updatedAt: PropTypes.node.isRequired,
  link: PropTypes.string,
  children: PropTypes.node,
};

ContentItem.defaultProps = {
  link: undefined,
  children: undefined,
};

export default function SpaceContentList({
  folders,
  lastFolderCreatedId,
  projects,
}) {
  return (
    <ContentList>
      <ContentListHeader />

      {folders.map(folder => (
        <FolderCard
          key={get(folder, 'id')}
          folder={folder}
          isEditing={lastFolderCreatedId === get(folder, 'id')}
          render={({
            name,
            count,
            namePlaceholder,
            setName,
            handleRenameClick,
            handleRemoveFolder,
            handleRenameFolder,
            folderUrl,
          }) => (
            <ContentItem
              link={folderUrl}
              icon={
                <Icon
                  type={<FolderOpenSvg />}
                  size="large"
                  boxSize="xxlarge"
                  color="grey.400"
                />
              }
              title={
                <ContextualTextField
                  maxLength="100"
                  minWidth="100%"
                  onBlur={handleRenameFolder}
                  onChange={e => setName(e.target.value)}
                  placeholder={namePlaceholder}
                  textStyle="body.regular"
                  fontWeight="light"
                  value={name || undefined}
                />
              }
              count={count}
              createdAt={get(folder, 'attributes.createdAt')}
              updatedAt={get(folder, 'attributes.updatedAt')}
            >
              <FolderCard.ActionsDropdown
                onClickRename={handleRenameClick}
                onClickRemove={handleRemoveFolder}
              />
            </ContentItem>
          )}
        />
      ))}

      {projects.map(project => (
        <ProjectCardGrid.ProjectCard
          key={get(project, 'id')}
          project={project}
          render={({
            projectTitle,
            untitledProjectTitle,
            imagesCount,
            coverImage,
            projectLink,
            editableTitleRef,
            spaces,
            folderChooserModalState,
            folderChooserModal,
            following,
            toggleFollow,
            handleRenameProject,
            handleClickInfo,
            handleClickRename,
            handleRemoveProject,
            handleCopyProject,
            handleClickSpaces,
            handleRestoreProject,
            handleTrashProject,
            canEditProject,
            canFollowProject,
            canDeleteProject,
            canDuplicateProject,
            canMoveProject,
            canRestoreProject,
            canTrashProject,
          }) => (
            <ContentItem
              link={projectLink}
              icon={<ImageIconContainer>{coverImage}</ImageIconContainer>}
              title={
                canEditProject ? (
                  <ProjectCardGrid.ProjectCardEditableTitle
                    ref={editableTitleRef}
                    value={projectTitle}
                    onChange={handleRenameProject}
                    placeholder={untitledProjectTitle}
                  />
                ) : (
                  <MessageTooltip
                    message={projectTitle || untitledProjectTitle}
                    showDelay={500}
                  >
                    <TruncatedBody>
                      {projectTitle || untitledProjectTitle}
                    </TruncatedBody>
                  </MessageTooltip>
                )
              }
              count={imagesCount}
              createdAt={get(project, 'attributes.createdAt')}
              updatedAt={get(project, 'attributes.updatedAt')}
            >
              <Box display="flex" alignItems="center" mx={3}>
                <ProjectCardGrid.FollowingBell following={following} />
              </Box>
              <ProjectShareState
                iconOutline="white"
                project={project}
                showInvitations
                showPublicLinks
                showUsers
              />
              <Box ml={3}>
                <ProjectCardGrid.ProjectCardActionsDropdown
                  onClickInfo={handleClickInfo}
                  onClickFollow={canFollowProject && toggleFollow}
                  following={following}
                  onClickRename={canEditProject ? handleClickRename : null}
                  onClickRemove={canDeleteProject ? handleRemoveProject : null}
                  onClickCopy={canDuplicateProject ? handleCopyProject : null}
                  spaces={spaces}
                  onClickSpace={handleClickSpaces}
                  onClickMove={
                    canMoveProject ? folderChooserModalState.open : null
                  }
                  onClickRestore={
                    canRestoreProject ? handleRestoreProject : null
                  }
                  onClickMoveToTrash={
                    canTrashProject ? handleTrashProject : null
                  }
                />
                {folderChooserModal}
              </Box>
            </ContentItem>
          )}
        />
      ))}
    </ContentList>
  );
}

SpaceContentList.propTypes = {
  folders: PropTypes.instanceOf(List),
  projects: PropTypes.instanceOf(List),
  lastFolderCreatedId: PropTypes.string,
};

SpaceContentList.defaultProps = {
  folders: List(),
  projects: List(),
  lastFolderCreatedId: undefined,
};
