import React from 'react';
import PropTypes from 'prop-types';
import { TrackerScope } from '@picter/tracker';

function Document({ children }: { children: React.ReactNode }) {
  return <TrackerScope name="Cancel subscription">{children}</TrackerScope>;
}

Document.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Document;
