import { defineMessages } from 'react-intl';

export default defineMessages({
  // buttons
  labelCancel: {
    id: 'Modal.labelCancel',
    defaultMessage: 'Cancel',
  },
  labelLearnMore: {
    id: 'Modal.labelLearnMore',
    defaultMessage: 'Learn more',
  },
  labelUpgrade: {
    id: 'Modal.labelUpgrade',
    defaultMessage: 'Upgrade',
  },
});
