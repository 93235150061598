import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ActionsDropdown from 'src/components/ActionsDropdown';

import messages from '../messages';

function FolderCardActionsDropdown({ onClickRename, onClickRemove }) {
  return (
    <ActionsDropdown page="folder">
      <ActionsDropdown.Group entity="folder">
        {onClickRename && (
          <ActionsDropdown.Rename entity="folder" onClick={onClickRename} />
        )}
        {onClickRemove && (
          <ActionsDropdown.Delete
            confirmMessage={
              <FormattedMessage {...messages.messageModalDelete} />
            }
            entity="folder"
            onClick={onClickRemove}
          />
        )}
      </ActionsDropdown.Group>
    </ActionsDropdown>
  );
}

FolderCardActionsDropdown.propTypes = {
  onClickRename: PropTypes.func,
  onClickRemove: PropTypes.func,
};

FolderCardActionsDropdown.defaultProps = {
  onClickRename: undefined,
  onClickRemove: undefined,
};

export default FolderCardActionsDropdown;
