import styled from 'styled-components/macro';
import { bodyTextStyle, headingTwoTextStyle, variants } from '@picter/prisma';
import { ellipsis } from 'polished';

const customHeadingTwoTextStyle = props =>
  headingTwoTextStyle(props, {
    lineHeight: `${props.theme.space[5]}px`,
  });

const textStyleProp = variants('textStyle', {
  body: bodyTextStyle,
  heading: customHeadingTwoTextStyle,
});

const NavigationItemText = styled.span`
  color: inherit;
  ${textStyleProp};
  ${ellipsis('100%')};
  width: 100%;
`;

NavigationItemText.defaultProps = {
  textStyle: 'heading',
};

export default NavigationItemText;
