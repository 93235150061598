import React, { useState } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { MediaQuery } from '@picter/prisma';
import { trackerShape } from '@picter/tracker';

import ModalConfirmReplace from 'src/components/ProjectPageModalConfirmReplace';
import { get } from 'src/utils/accessors';
import useModal from 'src/hooks/use-modal';
import LiteUserRegisterModal from 'src/components/LiteUserRegisterModal';
import { loginUrl } from 'src/routes/urls';

import ReviewPageReviewDoneModal from '../_components/ReviewPageReviewDoneModal';
import LayoutDesktop from './LayoutDesktop';
import LayoutMobile from './LayoutMobile';
import {
  useCreateReviewDoneComment,
  useOnClickReviewDone,
} from '../_hooks/use-handlers';

const Layout = ({ publicLink, tracker, withSidebar, project }) => {
  const projectId = get(project, 'id');
  const space = get(project, 'relationships.space');
  const [confirmReplaceImages, setConfirmReplaceImages] = useState(null);

  const createReviewDoneComment = useCreateReviewDoneComment({ project });

  const [reviewDoneModal, reviewDoneModalState] = useModal(
    <ReviewPageReviewDoneModal
      onSubmit={createReviewDoneComment}
      space={get(space, 'attributes.name')}
    />,
  );

  const [liteUserRegisterModal, liteUserRegisterModalState] = useModal(
    <LiteUserRegisterModal
      onLoginRedirect={location => ({
        pathname: loginUrl(),
        search: `?redirectUrl=${[location.pathname, location.search].join('')}`,
      })}
      onRegister={reviewDoneModalState.open}
    />,
  );

  const onClickReviewDone = useOnClickReviewDone({
    reviewDoneModalState,
    liteUserRegisterModalState,
  });

  return (
    <>
      <ModalConfirmReplace
        open={!!confirmReplaceImages}
        onConfirm={confirmReplaceImages}
        onClose={() => setConfirmReplaceImages(null)}
      />
      <MediaQuery query={{ minWidth: 0 }}>
        {matches =>
          matches ? (
            <LayoutDesktop
              projectId={projectId}
              tracker={tracker}
              withSidebar={withSidebar}
              publicLink={publicLink}
              setConfirmReplaceImages={setConfirmReplaceImages}
              onClickReviewDone={onClickReviewDone}
            />
          ) : (
            <LayoutMobile
              tracker={tracker}
              withSidebar={withSidebar}
              publicLink={publicLink}
              onClickReviewDone={onClickReviewDone}
            />
          )
        }
      </MediaQuery>
      {liteUserRegisterModal}
      {reviewDoneModal}
    </>
  );
};

Layout.propTypes = {
  project: PropTypes.instanceOf(Map).isRequired,
  publicLink: PropTypes.instanceOf(Map).isRequired,
  withSidebar: PropTypes.bool.isRequired,
  tracker: trackerShape.isRequired,
};

export default Layout;
