import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const MaybeLink = ({ children, to, ...props }) => {
  if (to) {
    return (
      <Link to={to} {...props}>
        {children}
      </Link>
    );
  }

  if (props.onClick) {
    return (
      <div style={{ cursor: 'pointer' }} {...props}>
        {children}
      </div>
    );
  }

  return children;
};

MaybeLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node.isRequired,
};

MaybeLink.defaultProps = {
  to: null,
};

export default MaybeLink;
