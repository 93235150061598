import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@picter/prisma';

import { Body, Box } from 'src/modules/prisma';
import { formattedMessage } from 'src/utils/app-prop-types';

export default function ItemCard({
  name,
  cover,
  subheader,
  clickable,
  clickableProps,
  extra,
}) {
  return (
    <Card>
      <Card.Content px={0}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {React.cloneElement(cover, { as: clickable, ...clickableProps })}
          <Box
            as={clickable}
            // this calc here is needed because the ellipsis needs a parent
            // with the width set, and if it's set to 100% here it ignores
            // the icon and the context menu element sizes
            // 128px = 32px of icon size + 16px for each side margin
            // for both the icon and the context menu
            width="calc(100% - 128px)"
            style={{ display: 'block' }}
            {...clickableProps}
          >
            {name}
            {subheader && (
              <Body textSize="small" color="grey.600" fontWeight="light">
                {subheader}
              </Body>
            )}
          </Box>
          <Box m={4}>{extra}</Box>
        </Box>
      </Card.Content>
    </Card>
  );
}

ItemCard.propTypes = {
  name: PropTypes.element.isRequired,
  cover: PropTypes.element,
  subheader: formattedMessage,
  clickable: PropTypes.elementType,
  clickableProps: PropTypes.shape({}),
  extra: PropTypes.node,
};

ItemCard.defaultProps = {
  cover: null,
  subheader: undefined,
  clickable: undefined,
  clickableProps: {},
  extra: undefined,
};
