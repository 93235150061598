import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';
import { configureAnalytics } from '@picter/react-app-core';

import browserHistory from 'src/utils/browser-history';
import RouterProvider from 'src/components/RouterProvider';

configureAnalytics();

const AppRouter = ({ children }) => (
  <Router history={browserHistory}>
    <RouterProvider>{Children.only(children)}</RouterProvider>
  </Router>
);

AppRouter.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppRouter;
