import kebabCase from 'lodash/kebabCase';
import camelCase from 'lodash/camelCase';
import { requestAsPromise, request } from '../../json-api-middleware/actions';
import { asyncTypesArray } from '../../utils/action-types-helpers';
import formatRequestPayload from '../../utils/format-request-payload';
import formatOptimisticPayload from '../../utils/format-optimistic-payload';
import addQueryParameters from '../../utils/add-query-parameters';
import { API_UPDATE_DATA_TYPES } from '../../constants';
import select from '../../selectors/select';

const bulkUpdate = (
  model,
  data,
  { prefix, baseUrl, querySerializers, optimistic = false, postfix } = {},
) => {
  if (!data) {
    throw new Error('Bulk update without data is not allowed.');
  }

  const formattedModel = camelCase(model);

  const { requestPayloads, payloads, ids } = data.reduce(
    (acc, { id, attributes, relationships } = {}) => {
      if (!id) {
        throw new Error('Update without id is not allowed.');
      }

      const formattedPayload = formatRequestPayload(model, {
        attributes,
        relationships,
      });

      acc.payloads.push({ id, attributes, relationships });
      acc.requestPayloads.push({ id, ...formattedPayload.data });
      acc.ids.push(id);

      return acc;
    },
    { requestPayloads: [], payloads: [], ids: [] },
  );

  const endpoint = [kebabCase(model)];

  if (prefix) endpoint.unshift(prefix);
  if (postfix) endpoint.push(postfix);

  return (dispatch, getState) => {
    const action = {
      types: asyncTypesArray(API_UPDATE_DATA_TYPES),
      endpoint: addQueryParameters(endpoint.join('/'), {
        querySerializers,
      }),
      method: 'PATCH',
      payload: { data: requestPayloads },
      meta: {
        ids,
        model: formattedModel,
        optimistic,
        previousValue:
          optimistic && select(getState(), formattedModel, { id: ids }),
        optimisticPayload: optimistic
          ? formatOptimisticPayload(model, payloads)
          : undefined,
      },
      baseUrl,
    };

    // optimistic updates don't need to return a promise,
    // since they update the store immediately
    if (optimistic) {
      return dispatch(request(action));
    }

    return requestAsPromise(dispatch, action);
  };
};

export default bulkUpdate;
