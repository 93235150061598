import curry from 'lodash/curry';
import pick from 'lodash/pick';
import { get, has } from 'src/utils/accessors';

export const COMMENT_FILTER_QUERY_KEY = 'cfilter';

export const CommentFilter = Object.freeze({
  ALL: undefined,
  RESOLVED: 'resolved',
  UNRESOLVED: 'unresolved',
  MENTIONS: 'mentions',
});

export function getCommentFilter(query) {
  return get(query, COMMENT_FILTER_QUERY_KEY, CommentFilter.ALL);
}

export function pickCommentFilter(query) {
  return pick(query, COMMENT_FILTER_QUERY_KEY);
}

export const hasGeometry = comment =>
  has(comment, 'attributes.geometry.type') ||
  has(comment, 'attributes.geometry.0');

export const hasRelationship = curry(({ imageId, projectId }, comment) => {
  const commentProjectId = get(comment, 'relationships.project.id');

  if (imageId) {
    const commentImageId = get(comment, 'relationships.image.id');
    return commentProjectId === projectId && commentImageId === imageId;
  }

  return commentProjectId === projectId;
});

export const isCommentFilter = curry(({ filter, userId }, comment) => {
  switch (filter) {
    case CommentFilter.ALL:
      return true;
    case CommentFilter.RESOLVED:
      return get(comment, 'attributes.resolved') === true;
    case CommentFilter.UNRESOLVED: {
      const isResolved = get(comment, 'attributes.resolved');
      return isResolved === false || isResolved === null;
    }
    case CommentFilter.MENTIONS: {
      const commentText = get(comment, 'attributes.text');
      return commentText.includes(userId);
    }
    default:
      return true;
  }
});

export function isComment(comment) {
  return comment && !comment.isEmpty() && get(comment, 'type') === 'wsComments';
}

export function isCommentReply(comment) {
  return (
    isComment(comment) &&
    (has(comment, 'relationships.parent.id') ||
      has(comment, 'relationships.parent.data.id'))
  );
}
