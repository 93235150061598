import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import {
  ANNOTATION_IN_PROGRESS_ID,
  useAnnotations,
} from 'src/components/Annotations';
import { Box } from 'src/modules/prisma';
import { CommentForm } from 'src/components/CommentInputForm';

import useInputOffsetRelativeToMarker from '../hooks/use-input-position-relative-to-marker';

function FloatingCommentForm({ onSubmit, x, y }) {
  const [
    { remove: removeAnnotation, select: selectAnnotation },
    { annotations, selectedId },
  ] = useAnnotations();

  const clearInProgressAnnotation = useCallback(
    event => {
      event.persist();

      if (event.key === 'Escape') {
        event.preventDefault();

        if (
          selectedId === ANNOTATION_IN_PROGRESS_ID &&
          annotations.has(ANNOTATION_IN_PROGRESS_ID)
        ) {
          removeAnnotation(ANNOTATION_IN_PROGRESS_ID);
          selectAnnotation(null);
        }
      }
    },
    [annotations, removeAnnotation, selectAnnotation, selectedId],
  );

  const annotation = annotations.get(ANNOTATION_IN_PROGRESS_ID);
  const inputWidth = 300;
  const position = useInputOffsetRelativeToMarker({
    x,
    y,
  });

  const handleSubmit = useCallback(
    text =>
      onSubmit({
        frame: get(annotation, 'meta.frame'),
        geometry: get(annotation, 'markups'),
        text,
      }),
    [annotation, onSubmit],
  );

  return (
    <Box display={annotation ? 'block' : 'none'}>
      <Box
        data-intercom-target="inline-comment-form"
        {...position}
        position="absolute"
        width={inputWidth}
        zIndex={1}
      >
        <CommentForm
          autoFocus={!!annotation}
          onKeyDown={clearInProgressAnnotation}
          onSubmit={handleSubmit}
          glowOnFocus
        />
      </Box>
    </Box>
  );
}

FloatingCommentForm.defaultProps = {
  x: 0,
  y: 0,
};

FloatingCommentForm.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
};

export default FloatingCommentForm;
