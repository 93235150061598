import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import MaybeLink from 'src/components/MaybeLink';
import ImagePreviewPlaceholder from 'src/components/PreviewPlaceholder';

import Card from '../styles/ImageCardCard';
import Image from './ImageCardImage';

const ImageCard = forwardRef(
  (
    {
      handleClickLink,
      src,
      title,
      selected,
      shiftSelected,
      isNonVisual,
      fileMimeType,
      processing,
    },
    ref,
  ) => {
    return (
      <div data-testid={`image-grid-card-${title}`}>
        <Card innerRef={ref} selected={selected || shiftSelected}>
          <Card.Extra />
          <Card.Content>
            <MaybeLink onClick={handleClickLink}>
              {processing ? (
                <ImagePreviewPlaceholder
                  type={fileMimeType}
                  uploading={false}
                  processing={processing}
                />
              ) : (
                <Image
                  alt={title}
                  src={src}
                  isNonVisual={isNonVisual}
                  type={fileMimeType}
                />
              )}
            </MaybeLink>
          </Card.Content>
        </Card>
      </div>
    );
  },
);

ImageCard.propTypes = {
  selected: PropTypes.bool,
  shiftSelected: PropTypes.number.isRequired,
  handleClickLink: PropTypes.func.isRequired,
  src: PropTypes.string,
  title: PropTypes.string,
  isNonVisual: PropTypes.bool.isRequired,
  fileMimeType: PropTypes.string,
  processing: PropTypes.bool,
};

ImageCard.defaultProps = {
  selected: false,
  src: null,
  title: null,
  fileMimeType: null,
  processing: false,
};

export default ImageCard;
