import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';

import { Icon } from 'src/modules/prisma';

const InteractiveIcon = styled(Icon)`
  &:hover {
    color: ${themeGet('colors.black')};
  }
`;

InteractiveIcon.displayName = 'InteractiveIcon';

export default InteractiveIcon;
