import { useCallback } from 'react';
import { useFormik } from 'formik';

import useAutoSave from 'src/hooks/use-auto-save';

const useFormikWithAutoSave = ({ onSubmit, onError, ...rest }) => {
  const formikState = useFormik({ enableReinitialize: true, ...rest });

  const handleSubmit = useCallback(
    async (values, params) => {
      try {
        const nextState = await onSubmit(values, params);
        params.setErrors({});
        params.resetForm(nextState);
      } catch (e) {
        if (onError) {
          const errors = onError(e);
          params.setErrors(errors);
          // Throw so we can set the error state on the autoSaveState cb
          throw e;
        }
      }
    },
    [onError, onSubmit],
  );

  const autoSaveState = useAutoSave(formikState, handleSubmit);

  return { formikState, autoSaveState };
};

export default useFormikWithAutoSave;
