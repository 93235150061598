import React, { useMemo, useEffect } from 'react';
import { compose } from 'recompose';
import usePermission from 'src/hooks/use-permission';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { ImageProofFilter } from 'src/utils/accessors/project-image';

const withSelectAndDragWrappers = WrappedComponent => {
  return props => {
    const {
      filter,
      connectDragSource,
      connectDragPreview,
      connectDropTarget,
      handleDropPosition,
      isDragging,
      isOver,
      selectionModeActive,
      selected,
    } = props;
    const isNotFiltering = !filter || filter === ImageProofFilter.ALL;
    const { canReorderImages, canMoveToCollection } = usePermission();

    useEffect(() => {
      connectDragPreview(getEmptyImage(), { captureDraggingState: true });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const composedDragFunctions = useMemo(() => {
      const dragFunctions = [];

      const isSelectedDuringSelection = selectionModeActive ? !!selected : true;

      if (canReorderImages && isNotFiltering) {
        dragFunctions.push(connectDropTarget);
      }

      if (
        (canMoveToCollection || canReorderImages) &&
        isSelectedDuringSelection
      ) {
        dragFunctions.push(connectDragSource);
      }

      return compose(...dragFunctions);
    }, [
      canReorderImages,
      connectDragSource,
      connectDropTarget,
      isNotFiltering,
      selectionModeActive,
      selected,
      canMoveToCollection,
    ]);
    return composedDragFunctions(
      <div onDragOver={!isDragging && isOver ? handleDropPosition : undefined}>
        <WrappedComponent {...props} />
      </div>,
    );
  };
};

export default withSelectAndDragWrappers;
