import { useSWR } from 'src/modules/swr';
import { UserResource } from 'src/types/resources';

const { REACT_APP_API_URL } = process.env;

type AssignableUserResource = Pick<UserResource, 'id'> &
  Pick<UserResource['attributes'], 'publicName' | 'email'>;

export default function useSpaceAssignableUsers({
  spaceId,
}: {
  spaceId: string;
}) {
  return useSWR<AssignableUserResource[]>(
    new URL(
      `/app-workspace/ws-spaces/${spaceId}/assignable-users`,
      REACT_APP_API_URL,
    ).toString(),
  );
}
