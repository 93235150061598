const requestHttp = ({ requestHeaders = () => ({}) } = {}) => ({
  url,
  method,
  payload,
}) => {
  let statusCode;
  return fetch(url, {
    method,
    body: payload ? JSON.stringify(payload) : undefined,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...requestHeaders({ url, method, payload }),
    },
  })
    .then(response => {
      statusCode = response.status;

      // try to parse json, if it fails, just
      // return null (otherwise 204s with no content
      // will fail)
      return response.json().catch(() => null);
    })
    .then(json => {
      if (statusCode >= 400) {
        return Promise.reject(json);
      }

      return Promise.resolve(json);
    });
};

export default requestHttp;
