// @ts-nocheck
import React, {
  useMemo,
  MutableRefObject,
  useCallback,
  useLayoutEffect,
} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonElement as Button, Spinner } from '@picter/prisma';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Refresh as RefreshSvg } from '@styled-icons/material';

import {
  ZoomProvider,
  PanProvider,
  ZoomAndPan,
  ZoomDropdown,
  useAnnotationsData,
  useAnnotations,
} from 'src/components/Annotations';
import {
  Box,
  Body,
  Icon,
  Spacer,
  Tooltip,
  Grid,
  Heading,
} from 'src/modules/prisma';
import { tGet } from 'src/utils/accessors';
import { useComment, useCurrentUser } from 'src/hooks/use-resource';
import { CommentResource } from 'src/types/resources';
import {
  libraryProjectCommentUrl,
  libraryProjectImageCommentUrl,
  libraryProjectImageDownloadUrl,
} from 'src/routes/urls';
import {
  FileProofingIcon,
  FileProofingTooltip,
  ManageCollectionsIcon,
  ManageCollectionsTooltip,
} from 'src/components/ContentIcon';
import CollectionActionsDropdown from 'src/components/CollectionActionsDropdown';
import DownloadSvg from 'src/assets/DownloadSvg';
import { useToriiActions } from 'src/modules/torii';
import { $TSFixMe } from 'src/types';
import UploadArea from 'src/components/UploadArea';
import uploadStorage from 'src/utils/upload-storage';

import File from './_components/File';
import TaskAnnotationRenderer from './_components/TaskAnnotationRenderer';

function ProjectTaskPage({
  projectPublicUrlKey,
  taskId,
}: {
  projectPublicUrlKey: string;
  taskId: string;
}) {
  return (
    <Grid display="grid" height="100%" placeItems="center">
      <Box alignItems="center" display="flex" flexDirection="column">
        <Heading fontWeight="light">
          <FormattedMessage
            id="TaskPage.labelCampaignAssets"
            defaultMessage="Campaign assets"
          />
        </Heading>
        <Spacer sy={3} />
        <Link
          to={libraryProjectCommentUrl({
            commentId: taskId,
            projectId: projectPublicUrlKey,
          })}
        >
          <Button color="white">
            <FormattedMessage
              id="TaskPage.labelGoToProject"
              defaultMessage="Go to project"
            />
          </Button>
        </Link>
      </Box>
    </Grid>
  );
}

function FileTaskPage({ comment }: { comment: CommentResource }) {
  /**
   * Subscriptions
   */
  const intl = useIntl();
  const {
    create,
    bulkDestroy,
    localUpdate,
    update,
  } = useToriiActions() as $TSFixMe;

  /**
   * Data
   */
  const taskId = tGet(comment, 'id');

  const user = useCurrentUser();
  const userId = tGet(user, 'id');

  /** File data */
  const file = tGet(comment, 'relationships', 'image');
  const fileId = tGet(file, 'id');
  const fileIds = useMemo(() => [fileId], [fileId]);
  const fileTitle =
    tGet(file, 'attributes', 'title') ||
    intl.formatMessage({
      id: 'TaskPage.labelUntitled',
      defaultMessage: 'Untitled',
    });
  const fileApprovals = tGet(file, 'relationships', 'approvals') ?? [];
  const fileDimensions = useMemo(
    () => ({
      height: tGet(file, 'attributes', 'height') ?? 0,
      width: tGet(file, 'attributes', 'width') ?? 0,
    }),
    [file],
  );
  const fileRejectionUser = tGet(file, 'relationships', 'rejectedByUser');
  const fileCollectionsCounter =
    tGet(file, 'relationships', 'collectionImages')?.size ?? 0;

  const isFilePdf = tGet(file, 'attributes', 'isPdf');
  const isFileNonVisual = tGet(file, 'attributes', 'isNonVisual');
  const isFileRejected = tGet(file, 'attributes', 'rejected');
  const isFileApprovedByCurrentUser = fileApprovals.some(approval => {
    const approvalUser = tGet(approval, 'relationships', 'user');
    return tGet(approvalUser, 'id') === userId;
  });

  const isAnnotationEnabled = !isFilePdf && !isFileNonVisual;
  const isPortraitVideo =
    tGet(file, 'attributes', 'isVideo') &&
    fileDimensions.width / fileDimensions.height <= 1;

  /** Project data */
  const project = tGet(comment, 'relationships', 'project');
  const projectPublicUrlKey = tGet(project, 'attributes', 'publicUrlKey');
  const projectTitle =
    tGet(project, 'attributes', 'title') ||
    intl.formatMessage({
      id: 'TaskPage.labelUntitledProject',
      defaultMessage: 'Untitled project',
    });

  /**
   * Handlers
   */
  const toggleFileApproval = useCallback(
    (_, approve) => {
      if (approve) {
        return create('wsApprovals', {
          attributes: {},
          relationships: {
            image: { type: 'ws-project-images', id: fileId },
          },
        });
      }

      const fileApprovalId = `${fileId}:${userId}`;

      return bulkDestroy('wsApprovals', [
        {
          id: fileApprovalId,
        },
      ]);
    },
    [create, bulkDestroy, fileId, userId],
  );

  const toggleFileRejection = useCallback(
    () =>
      update('wsProjectImages', {
        id: fileId,
        attributes: {
          rejected: !isFileRejected,
        },
      }),
    [fileId, isFileRejected, update],
  );

  const replaceFile = useCallback(
    async ([fileBlob]) => {
      const { data: replacedFile } = await update(
        'wsProjectImages',
        {
          id: fileId,
          attributes: {
            mimeType: fileBlob.type,
            fileName: fileBlob.name,
          },
        },
        {
          postfix: 'versions',
        },
      );

      const uploadEndpoint = tGet(
        replacedFile,
        'attributes',
        'direct-bucket-upload-url',
      );
      const mimeType = tGet(replacedFile, 'attributes', 'mime-type');

      await uploadStorage.queueFile(projectPublicUrlKey, fileId, {
        fileBlob,
        mimeType,
        uploadEndpoint,
        onSuccess: async () => {
          await localUpdate('wsProjectImages', {
            id: fileId,
            attributes: {
              uploadPending: false,
            },
          });
        },
        newVersion: true,
      });
    },
    [fileId, localUpdate, projectPublicUrlKey, update],
  );

  const [{ select }, { selectedId }] = useAnnotations();

  if (selectedId !== taskId) select(taskId);

  return (
    <ZoomProvider id={taskId}>
      <PanProvider id={taskId}>
        <Box
          position="relative"
          height="100%"
          width="100%"
          style={{ transform: 'translate3d(0,0,0)' }}
        >
          <Box
            backgroundColor="grey.100"
            display="flex"
            flexDirection="row"
            width="100%"
            position="absolute"
            zIndex={1}
          >
            <Box px={5} py={4}>
              <Body color="grey.600" textSize="small">
                {projectTitle}
              </Body>
              <Body color="black">{fileTitle}</Body>
            </Box>
            <Spacer sx="auto" />
            <Box alignItems="center" display="flex" px={5} py={4}>
              <FileProofingTooltip
                approvals={fileApprovals}
                rejected={isFileRejected}
                rejectedBy={fileRejectionUser}
              >
                <FileProofingIcon
                  // @ts-ignore
                  approvals={fileApprovals}
                  approvedByUser={isFileApprovedByCurrentUser}
                  className="proof-field"
                  id={`${fileId}-proof-field`}
                  onClickApprove={toggleFileApproval}
                  onClickReject={toggleFileRejection}
                  rejected={isFileRejected}
                  rejectedBy={fileRejectionUser}
                  size="medium"
                  boxSize="xlarge"
                />
              </FileProofingTooltip>
              <Spacer sx={4} />
              <CollectionActionsDropdown
                imagesIds={fileIds}
                closeOnSelect
                icon={(dropdownProps: { active: boolean }) => (
                  <ManageCollectionsTooltip visible={!dropdownProps.active}>
                    <ManageCollectionsIcon
                      {...dropdownProps}
                      // @ts-ignore
                      numberOfCollections={fileCollectionsCounter}
                      size="small"
                      boxSize="xlarge"
                    />
                  </ManageCollectionsTooltip>
                )}
                projectId={projectPublicUrlKey}
                noMargin
              />
              <Spacer sx={4} />
              <span>
                <UploadArea onUpload={replaceFile} disableDrop>
                  {({ openFileDialog }: { openFileDialog: $TSFixMe }) => (
                    <Tooltip>
                      <Tooltip.Reference>
                        <Icon type={<RefreshSvg />} onClick={openFileDialog} />
                      </Tooltip.Reference>
                      <Tooltip.Message>
                        <FormattedMessage
                          id="TaskPage.labelUploadNewVersion"
                          defaultMessage="Upload new version"
                        />
                      </Tooltip.Message>
                    </Tooltip>
                  )}
                </UploadArea>
              </span>
              <Spacer sx={4} />
              <Tooltip>
                <Tooltip.Reference>
                  <a
                    style={{ color: 'inherit' }}
                    href={libraryProjectImageDownloadUrl({
                      imageId: fileId,
                      downloadSize: 'original',
                      projectPublicKey: projectPublicUrlKey,
                    })}
                  >
                    <Icon type={<DownloadSvg />} interactive />
                  </a>
                </Tooltip.Reference>
                <Tooltip.Message>
                  <FormattedMessage
                    id="TaskPage.labelDownloadFile"
                    defaultMessage="Download file"
                  />
                </Tooltip.Message>
              </Tooltip>
              <ZoomDropdown />
              <Link
                to={libraryProjectImageCommentUrl({
                  commentId: taskId,
                  projectId: projectPublicUrlKey,
                  imageId: fileId,
                })}
              >
                <Button color="white">
                  <FormattedMessage
                    id="TaskPage.labelGoToFile"
                    defaultMessage="Go to file"
                  />
                </Button>
              </Link>
            </Box>
          </Box>
          <Box height="100%" width="100%">
            <AutoSizer>
              {({ height, width }) =>
                isAnnotationEnabled ? (
                  <ZoomAndPan
                    key={fileId}
                    height={height}
                    width={width}
                    objectWidth={fileDimensions.width}
                    objectHeight={fileDimensions.height}
                    padding={isPortraitVideo ? 128 : 64}
                  >
                    {({ canvasHeight, canvasWidth, zoom }) => (
                      <TaskAnnotationRenderer
                        canvasWidth={canvasWidth}
                        canvasHeight={canvasHeight}
                        key={fileId}
                        file={file}
                      >
                        {(ref: MutableRefObject<HTMLElement>) => (
                          <File
                            height={fileDimensions.height * zoom}
                            file={file}
                            ref={ref}
                            width={fileDimensions.width * zoom}
                            projectPublicUrlKey={projectPublicUrlKey}
                          />
                        )}
                      </TaskAnnotationRenderer>
                    )}
                  </ZoomAndPan>
                ) : (
                  <Box height={height} width={width}>
                    <File height={height} file={file} width={width} />
                  </Box>
                )
              }
            </AutoSizer>
          </Box>
        </Box>
      </PanProvider>
    </ZoomProvider>
  );
}

export default function TaskPage({
  match: {
    params: { taskId },
  },
}: RouteComponentProps<{ taskId: string }>) {
  const comment: CommentResource = useComment(
    {
      id: taskId,
      // TODO: Improve selection by loading image data with `useProjectImage`
      include: [
        'project',
        'image.approvals.user',
        'image.rejectedByUser',
        'image.collectionImages',
      ],
    },
    { request: false },
  );

  if (!tGet(comment, 'id'))
    // loading comment
    return (
      <Grid display="grid" placeItems="center" height="100%">
        <Spinner />
      </Grid>
    );

  const file = tGet(comment, 'relationships', 'image');

  if (tGet(file, 'id'))
    // comment is related to a file
    return <FileTaskPage comment={comment} />;

  const project = tGet(comment, 'relationships', 'project');
  const projectPublicUrlKey = tGet(project, 'attributes', 'publicUrlKey');
  // Project wide comment
  return (
    <ProjectTaskPage
      taskId={taskId}
      projectPublicUrlKey={projectPublicUrlKey}
    />
  );
}
