import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Box, Flex } from '@rebass/grid';
import { ButtonElement as Button } from '@picter/prisma';

import { Body, Panel, Subheading, Text } from 'src/modules/prisma';

import messages from '../messages';

function PaymentMethodPanel({ currentMethod, onClickChangePayment }) {
  return (
    <Panel
      backgroundColor="white"
      borderColor="grey.200"
      borderStyle="solid"
      borderWidth="1px"
      padding={5}
      tag="section"
    >
      <Subheading tag="h2">
        <FormattedMessage {...messages.titlePaymentMethod} />
      </Subheading>
      <Flex
        alignItems="flex-end"
        flexDirection={['column', null, 'row']}
        justifyContent="space-between"
        mt={5}
      >
        {currentMethod ? (
          <>
            <Body color="grey.600">
              <FormattedMessage
                {...messages.messagePaymentMethod}
                values={{
                  card: (...chunks) => (
                    <Text textTransform="capitalize">{chunks}</Text>
                  ),
                  cardType: currentMethod.cardType,
                  lastNumbers: currentMethod.last4,
                }}
              />
            </Body>
            <Box mt={[3, null, 0]}>
              <Button
                color="primary"
                py={3}
                variant="flat"
                width="100%"
                onClick={onClickChangePayment}
              >
                <FormattedMessage {...messages.labelChangePaymentMethod} />
              </Button>
            </Box>
          </>
        ) : (
          <Body color="grey.600">
            <FormattedMessage {...messages.messageNoPaymentMethod} />
          </Body>
        )}
      </Flex>
    </Panel>
  );
}

PaymentMethodPanel.propTypes = {
  currentMethod: PropTypes.shape({
    cardType: PropTypes.string,
    last4: PropTypes.string,
  }),
  onClickChangePayment: PropTypes.func.isRequired,
};

PaymentMethodPanel.defaultProps = {
  currentMethod: undefined,
};

export default PaymentMethodPanel;
