import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';

import { createContextWithAccessors } from '../utils';

const INITIAL_PAN = { x: 0, y: 0 };

type Point = {
  x: number;
  y: number;
};

type RangeState = {
  x: [number, number];
  y: [number, number];
};

type MouseHandlers = {
  onMouseDown: (e: React.MouseEvent) => void;
  onMouseUp: (e: React.MouseEvent) => void;
  onMouseMove: (e: React.MouseEvent) => void;
};

function trimToRange(value: number, range: [number, number]) {
  return Math.min(Math.max(value, range[0]), range[1]);
}

export const [PanContext, usePan] = createContextWithAccessors<{
  isPanning: boolean;
  pan: Point;
  panDelta: Point;
  panXY: (x: Point['x'], y: Point['y']) => void;
  setXY: (
    cb: (param: {
      x: number;
      y: number;
    }) => {
      values: { x: number; y: number };
      ranges: { x: [number, number]; y: [number, number] };
    },
  ) => void;
  mouseHandlers: MouseHandlers;
  panOn: boolean;
  setPanOn: (p: boolean) => void;
  range: RangeState;
  setRange: (newRange: RangeState) => void;
  reset: () => void;
  setPanDelta: (params: { x: Point['x']; y: Point['y'] }) => void;
}>();

export default function PanProvider({
  children,
  id,
}: React.PropsWithChildren<{ id: string }>) {
  const [pan, setPan] = useState<Point>(INITIAL_PAN);
  const [panOn, setPanOn] = useState<boolean>(false);
  const [panDelta, setPanDelta] = useState<Point>({ x: 0, y: 0 });
  const [isPanning, setIsPanning] = useState(false);
  const [mouseStartPos, setMouseStartPos] = useState<Point>({ x: 0, y: 0 });
  // We need to have the range in ref as well so we dont depend on react lifecycle updates to update it but we still want to
  // update it for children components so we keep the range state and update both using the setRange function
  // Remember to always use rangeRef internally and only pass doen the range state.
  const [range, setStateRange] = useState<RangeState>({ x: [1, 1], y: [1, 1] });
  const rangeRef = useRef<RangeState>({ x: [1, 1], y: [1, 1] });

  const setRange = useCallback(
    r => {
      rangeRef.current = r;
      setStateRange(r);
    },
    [setStateRange],
  );

  const onMouseDown = useCallback(
    (e: React.MouseEvent) => {
      if (panOn) {
        setIsPanning(true);
        setMouseStartPos({ x: e.screenX, y: e.screenY });
      }
    },
    [panOn],
  );

  const onMouseMove = useCallback(
    (e: React.MouseEvent) => {
      if (isPanning) {
        setPanDelta({
          x: mouseStartPos.x - e.screenX,
          y: mouseStartPos.y - e.screenY,
        });
      }
    },
    [mouseStartPos, isPanning],
  );

  const onMouseUp = useCallback(() => {
    setIsPanning(false);
    setPan(p => ({
      x: trimToRange(p.x + panDelta.x, rangeRef.current.x),
      y: trimToRange(p.y + panDelta.y, rangeRef.current.y),
    }));
    setPanDelta({ x: 0, y: 0 });
  }, [panDelta, rangeRef]);

  const mouseHandlers = useMemo(
    () => ({
      onMouseDown,
      onMouseUp,
      onMouseMove,
    }),
    [onMouseDown, onMouseUp, onMouseMove],
  );

  const panXY = useCallback(
    (x: number, y: number) => {
      setPan(p => ({
        x: trimToRange(p.x + x, rangeRef.current.x),
        y: trimToRange(p.y + y, rangeRef.current.y),
      }));
    },
    [rangeRef],
  );

  const setXY = useCallback(
    (
      cb: (param: {
        x: number;
        y: number;
      }) => {
        values: { x: number; y: number };
        ranges: { x: [number, number]; y: [number, number] };
      },
    ) => {
      return setPan(p => {
        const { values, ranges } = cb(p);
        return {
          x: trimToRange(values.x, ranges.x),
          y: trimToRange(values.y, ranges.y),
        };
      });
    },
    [],
  );

  const reset = useCallback(() => {
    setPan(INITIAL_PAN);
  }, [setPan]);

  const contextValue = useMemo(
    () => ({
      isPanning,
      pan,
      panDelta,
      panXY,
      mouseHandlers,
      panOn,
      setPanOn,
      setXY,
      range,
      setRange,
      reset,
      setPanDelta,
    }),
    [
      isPanning,
      pan,
      panDelta,
      panXY,
      mouseHandlers,
      panOn,
      setPanOn,
      setXY,
      range,
      setRange,
      reset,
      setPanDelta,
    ],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reset(), [id]);

  return (
    <PanContext.Provider value={contextValue}>{children}</PanContext.Provider>
  );
}
