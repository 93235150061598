import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import useFormatMessage from 'src/hooks/use-format-message';
import {
  useCurrentUser,
  useSpace,
  useSpaceProjects,
  useFolder,
} from 'src/hooks/use-resource';
import { getSpaceSortBy, parseSpaceSortBy } from 'src/utils/accessors/space';
import { comparator as sortByComparator } from 'src/modules/sorter';
import { get } from 'src/utils/accessors';
import globalMessages from 'src/messages';

export default function useData() {
  const { spaceId, folderId } = useParams();
  const { query } = useLocation();
  const sortBy = getSpaceSortBy({ query, spaceId, folderId });
  const [attribute, operator] = parseSpaceSortBy(sortBy);
  const formatMessage = useFormatMessage();
  const user = useCurrentUser();

  const space = useSpace({
    id: spaceId,
    include: ['users.user', 'plan', 'folders'],
  });

  const folder = useFolder({
    id: folderId,
  });

  const projects = useSpaceProjects(
    {
      id: spaceId,
      include: [
        'invitations',
        'users.user',
        'publicLinks',
        'coverImage',
        'follows',
      ],
      includeOnSelector: ['images'],
    },
    {
      page: {
        size: 500,
      },
      querySerializers: {
        folder: () => `folder=${folderId}`,
      },
    },
  );

  const filteredAndSortedProjects = useMemo(
    () =>
      projects
        .filter(
          p =>
            // is in a folder
            get(p, 'relationships.folder.data.id') === folderId &&
            // is not in trash
            get(p, 'attributes.deletedAt') === null,
        )
        .sort(
          sortByComparator(
            attribute === 'name' ? 'title' : attribute,
            operator,
            {
              transform: value =>
                typeof value === 'string' ? value.toLowerCase() : value,
            },
          ),
        ),
    [attribute, operator, projects, folderId],
  );

  const folderNamePlaceholder = formatMessage(
    globalMessages.labelUntitledFolder,
  );

  return {
    user,
    space,
    folder,
    projects: filteredAndSortedProjects,
    folderNamePlaceholder,
  };
}
