import { useCallback, useContext } from 'react';
import { SelectableComponentsContext } from '../SelectableComponentsProvider';
import { SELECTION_STATES } from '../constants';

const { SELECTED, NOT_SELECTED, SHIFT_DESELECT_PREVIEW } = SELECTION_STATES;
// Hook for selectables. It provides the items selection state
// and all necessary handlers.
export default function useSelectable(id, index) {
  const context = useContext(SelectableComponentsContext);
  if (!context) {
    throw new Error(
      'useSelectable must be used within a SelectableComponentsContext',
    );
  }
  const {
    deselect: deselectDispatcher,
    select: selectDispatcher,
    shiftSelect: shiftSelectHandler,
    toggleSelect: toggleSelectHandler,
    selection,
    shiftPreview,
    isShiftModeSelect,
    shiftPressed,
  } = context;
  // is this item part of a shift-selection preview? If so, is
  // it a select or deselect preview?
  let shiftSelected = NOT_SELECTED;
  if (shiftPressed && shiftPreview.has(String(id))) {
    shiftSelected = isShiftModeSelect ? SELECTED : SHIFT_DESELECT_PREVIEW;
  }

  // is this item selected?
  const selected = selection.has(String(id)) ? SELECTED : NOT_SELECTED;

  // create handler thunks (already using the correct id and index)
  const select = useCallback(() => selectDispatcher(id, index), [
    id,
    index,
    selectDispatcher,
  ]);
  const deselect = useCallback(() => deselectDispatcher(id, index), [
    id,
    index,
    deselectDispatcher,
  ]);
  const shiftSelect = useCallback(() => shiftSelectHandler(id, index), [
    id,
    index,
    shiftSelectHandler,
  ]);

  const toggleSelect = useCallback(() => toggleSelectHandler(id, index), [
    id,
    index,
    toggleSelectHandler,
  ]);
  return {
    deselect,
    select,
    shiftSelect,
    toggleSelect,
    selected,
    shiftSelected,
    selectionModeActive: !!selection.size,
    selection,
    shiftPressed,
  };
}
