import { useMemo, useState } from 'react';
import camelCase from 'lodash/camelCase';

import capitalize from 'src/utils/capitalize';

const useModalStateHandlers = (name = '') => {
  const nameFormatted = capitalize(camelCase(name));
  const [visible, setVisibility] = useState(false);

  const values = {
    [`visible${nameFormatted}Modal`]: visible,
  };

  const handlers = useMemo(
    () => ({
      [`open${nameFormatted}Modal`]: () => setVisibility(true),
      [`close${nameFormatted}Modal`]: () => setVisibility(false),
    }),
    [setVisibility, nameFormatted],
  );

  return { ...values, ...handlers };
};

export default useModalStateHandlers;
