import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';

export const ActionsDropdownContext = React.createContext();

const ActionsDropdownProvider = ({
  goTo,
  children,
  closeMenu,
  setDeleteModalFor,
  multiGroup,
}) => {
  const value = useMemo(
    () => ({
      goTo,
      closeMenu,
      setDeleteModalFor,
      multiGroup,
    }),
    [goTo, closeMenu, setDeleteModalFor, multiGroup],
  );

  return (
    <ActionsDropdownContext.Provider value={value}>
      {children}
    </ActionsDropdownContext.Provider>
  );
};

ActionsDropdownProvider.propTypes = {
  children: PropTypes.node.isRequired,
  closeMenu: PropTypes.func,
  goTo: PropTypes.func,
  setDeleteModalFor: PropTypes.func.isRequired,
  multiGroup: PropTypes.bool.isRequired,
};

ActionsDropdownProvider.defaultProps = {
  closeMenu: undefined,
  goTo: undefined,
};

export default memo(ActionsDropdownProvider);
