import React from 'react';

export default function ProjectCardCoverIllustrationSvg() {
  return (
    <svg
      width="104"
      height="72"
      viewBox="0 0 104 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="44.85"
        y="0.85"
        width="58.2676"
        height="58.2676"
        stroke="#5C62CA"
        strokeWidth="1.7"
        strokeDasharray="7.62 7.62"
      />
      <rect
        x="18.75"
        y="37.1758"
        width="34.074"
        height="34.074"
        stroke="#BE52CC"
        strokeWidth="1.5"
        strokeDasharray="7.62 7.62"
      />
      <rect
        x="0.75"
        y="6.75"
        width="20.4686"
        height="20.4686"
        stroke="white"
        strokeWidth="1.5"
        strokeDasharray="4.71 4.71"
      />
    </svg>
  );
}
