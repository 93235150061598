const defaultPageSerializer = page => {
  const pageQuery = Object.entries(page).map(
    ([key, value]) => `page[${key}]=${value}`,
  );
  return pageQuery.join('&');
};

const defaultFilterSerializer = filter => {
  const filters = Object.entries(filter).map(
    ([key, value]) => `filter[${key}]=${value}`,
  );
  return filters.join('&');
};

const addQueryParameters = (url, options = {}) => {
  const { include, page, filter, sort, querySerializers = {} } = options;
  const serializers = Object.entries(querySerializers);
  const params = [];

  if (include) {
    params.push(`include=${include.join(',')}`);
  }

  if (sort) {
    params.push(`sort=${sort.join(',')}`);
  }

  if (serializers.length) {
    const queries = serializers.map(([key, serialize]) =>
      serialize(options[key]),
    );
    params.push(queries.join('&'));
  }

  if (page && !querySerializers.page) {
    params.push(defaultPageSerializer(page));
  }

  if (filter && !querySerializers.filter) {
    params.push(defaultFilterSerializer(filter));
  }

  return params.length ? `${url}?${params.join('&')}` : url;
};

export default addQueryParameters;
