import React, { forwardRef, memo } from 'react';

import Avatar from 'src/components/Avatar';
import { useCurrentUser } from 'src/hooks/use-resource';
import { get } from 'src/utils/accessors';

const CurrentUserAvatar = forwardRef((props, ref) => {
  const user = useCurrentUser();
  const email = get(user, 'attributes.email');
  const name = get(user, 'attributes.publicName');

  return (
    <Avatar
      {...props}
      email={email}
      name={name}
      ref={ref}
      data-testid="current-user-avatar-icon"
    />
  );
});

CurrentUserAvatar.displayName = 'CurrentUserAvatar';

export default memo(CurrentUserAvatar);
