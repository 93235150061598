import { API_LOCAL_UPDATE } from '../../constants';

const localUpdate = (model, { id, attributes } = {}) => {
  if (!id) {
    throw new Error('Update without id is not allowed.');
  }

  return dispatch =>
    dispatch({
      type: API_LOCAL_UPDATE,
      payload: {
        [model]: {
          [id]: {
            id,
            attributes,
          },
        },
      },
    });
};

export default localUpdate;
