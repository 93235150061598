import React, { useState, useMemo } from 'react';
import classNames from 'classnames';

import Container from '../styles/LayoutContainer';
import Content from './Content';
import ContextSidebar from './ContextSidebar';
import MainSidebar from './MainSidebar';
import { SidebarControlProvider } from '../utils/sidebar-control';

type Props = {
  children: React.ReactNode;
  className?: string;
  contextSidebarMode?: 'resize' | 'shift';
  contextSidebarOpened?: boolean;
  id?: string;
  mainSidebarOpened?: boolean;
};
function SidebarLayout({
  children,
  className,
  contextSidebarMode = 'shift',
  contextSidebarOpened = false,
  id,
  mainSidebarOpened = true,
}: Props) {
  const [contextSidebarOpenedState, setContextSidebarOpened] = useState(
    contextSidebarOpened,
  );
  const [mainSidebarOpenedState, setMainSidebarOpened] = useState(
    mainSidebarOpened,
  );

  const controls = useMemo(
    () => ({
      closeContextSidebar: () => setContextSidebarOpened(false),
      openContextSidebar: () => setContextSidebarOpened(true),

      closeMainSidebar: () => setMainSidebarOpened(false),
      openMainSidebar: () => setMainSidebarOpened(true),

      toggleSidebars: () => {
        setContextSidebarOpened(state => !state);
        setMainSidebarOpened(state => !state);
      },
    }),
    [],
  );

  const value = useMemo(
    () => ({
      ...controls,
      contextSidebarOpened: contextSidebarOpenedState,
      mainSidebarOpened: mainSidebarOpenedState,
    }),
    [controls, contextSidebarOpenedState, mainSidebarOpenedState],
  );

  return (
    <SidebarControlProvider value={value}>
      <Container
        id={id}
        className={classNames(className, {
          'context-sidebar-opened': contextSidebarOpenedState,
          'main-sidebar-opened': mainSidebarOpenedState,
        })}
        contextMode={contextSidebarMode}
      >
        {children}
      </Container>
    </SidebarControlProvider>
  );
}

SidebarLayout.Content = Content;
SidebarLayout.Content.displayName = 'SidebarLayout.Content';

SidebarLayout.ContextSidebar = ContextSidebar;
SidebarLayout.ContextSidebar.displayName = 'SidebarLayout.ContextSidebar';

SidebarLayout.MainSidebar = MainSidebar;
SidebarLayout.MainSidebar.displayName = 'SidebarLayout.MainSidebar';

SidebarLayout.displayName = 'SidebarLayout';

export default SidebarLayout;
