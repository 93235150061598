import { useEffect, useState } from 'react';

const DelayRender = ({ children, delay = 200 }) => {
  const [pastDelay, setPastDelay] = useState(!(delay > 0));

  useEffect(() => {
    const timeout = setTimeout(() => setPastDelay(true), delay);

    return () => clearTimeout(timeout);
  }, [delay, pastDelay]);

  return pastDelay ? children : null;
};

export default DelayRender;
