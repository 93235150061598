import React from 'react';
import PropTypes from 'prop-types';
import { Body, CheckboxField, Modal, Subheading } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';
import { Box, Flex } from '@rebass/grid';
import { TrackerEvent, TrackerScope } from '@picter/tracker';

import capitalize from 'src/utils/capitalize';
import { Icon } from 'src/modules/prisma';

import ShareableLinkBox from './ShareableLinkBox';
import messages from '../messages';

const checkboxesOrder = {
  content: 1,
  downloadEnabled: 2,
  commentsEnabled: 4,
};

function ShareableLinkModal({
  description: { icon, message },
  enableMessage,
  link,
  loadingLink,
  onClickClose,
  onClickDisableLink,
  onClickEnableLink,
  onUpdateSetting,
  settings,
  title,
  type,
}) {
  // Little hack to control the order once Object.keys/Object.entries will use insertion order,
  // changing the checkboxes order every time the user checks one of them.
  // Throwing error to make sure developers will add new checkbox possibilities to the order object.
  const settingsEntries = Object.entries(settings).sort(
    ([entryAKey], [entryBKey]) => {
      if (!checkboxesOrder[entryBKey] || !checkboxesOrder[entryAKey])
        throw new Error('Entry doesnt exist on checkboxesOrder, please add it');
      return checkboxesOrder[entryAKey] - checkboxesOrder[entryBKey];
    },
  );
  const name = capitalize(type);

  return (
    <TrackerScope name={`${name} modal`}>
      <Modal onClickClose={onClickClose} title={title} open>
        <TrackerEvent name={`${name} modal`} trackView>
          <Flex alignItems="center" mb={6}>
            <Icon color="grey.300" size="xxxxlarge" type={icon} />
            <Box ml={4}>
              <Body textSize="small">{message}</Body>
            </Box>
          </Flex>
          <Box mb={6}>
            <Subheading color="grey.600" pb={2}>
              <FormattedMessage {...messages.titleSettings} />
            </Subheading>
            {settingsEntries.map(
              ([key, { name: settingName, label, value, render }]) =>
                render ? (
                  render({
                    label,
                    value,
                    name: settingName,
                    onChange: v => onUpdateSetting(key, v),
                  })
                ) : (
                  <TrackerEvent
                    key={key}
                    name={settingName}
                    properties={{
                      settingEnabled: value,
                    }}
                    trackChange
                  >
                    <CheckboxField
                      name={key}
                      label={label}
                      checked={value}
                      onChange={e =>
                        onUpdateSetting(key, e.currentTarget.checked)
                      }
                    />
                  </TrackerEvent>
                ),
            )}
          </Box>
          <Box>
            <Subheading color="grey.600" pb={2}>
              <FormattedMessage {...messages.titleLink} />
            </Subheading>
            <ShareableLinkBox
              link={link}
              loadingLink={loadingLink}
              type={type}
              enableMessage={enableMessage}
              onClickEnable={onClickEnableLink}
              onClickDisable={onClickDisableLink}
            />
          </Box>
        </TrackerEvent>
      </Modal>
    </TrackerScope>
  );
}

ShareableLinkModal.propTypes = {
  description: PropTypes.shape({
    icon: PropTypes.node,
    message: PropTypes.node,
  }),
  link: PropTypes.string,
  loadingLink: PropTypes.bool,
  onClickClose: PropTypes.func,
  enableMessage: PropTypes.node,
  onClickDisableLink: PropTypes.func,
  onClickEnableLink: PropTypes.func,
  onUpdateSetting: PropTypes.func,
  open: PropTypes.bool,
  settings: PropTypes.shape({}),
  title: PropTypes.node,
  type: PropTypes.string.isRequired,
};

ShareableLinkModal.defaultProps = {
  description: undefined,
  link: undefined,
  loadingLink: false,
  onClickClose: undefined,
  enableMessage: undefined,
  onClickDisableLink: undefined,
  onClickEnableLink: undefined,
  onUpdateSetting: undefined,
  open: false,
  settings: [],
  title: <FormattedMessage {...messages.titleShareableLinkModal} />,
};

export default ShareableLinkModal;
