import kebabCase from 'lodash/kebabCase';
import kebabFormatter from '../kebab-formatter';

const formatRequestPayload = (
  model,
  { attributes = undefined, relationships = undefined } = {},
) => {
  let parsedRelationships;

  if (relationships) {
    parsedRelationships = Object.entries(relationships).reduce(
      (prev, [key, value]) => ({
        ...prev,
        [key]: {
          data: value,
        },
      }),
      {},
    );
  }

  return {
    data: {
      type: kebabCase(model),
      attributes: kebabFormatter(attributes),
      relationships: kebabFormatter(parsedRelationships),
    },
  };
};

export default formatRequestPayload;
