import Tooltip from './components/Tooltip';
import TooltipMessage from './components/TooltipMessage';
import TooltipPanel from './components/TooltipPanel';
import TooltipReference from './components/TooltipReference';

Tooltip.Message = TooltipMessage;
Tooltip.Panel = TooltipPanel;
Tooltip.Reference = TooltipReference;

export default Tooltip;
