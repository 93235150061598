import styled from 'styled-components/macro';
import { transparentize } from 'polished';

import GeneralLayout from 'src/layouts/GeneralLayout';

const OpacityFooter = styled(GeneralLayout.Bottom)`
  background: linear-gradient(
    180deg,
    ${props => transparentize(1, props.theme.colors.gray['100'])} 0%,
    ${props => props.theme.colors.gray['100']} 67.71%
  );
`;

export default OpacityFooter;
