import kebabCase from 'lodash/kebabCase';
import camelCase from 'lodash/camelCase';
import { requestAsPromise } from '../../json-api-middleware/actions';
import { asyncTypesArray } from '../../utils/action-types-helpers';
import addQueryParameters from '../../utils/add-query-parameters';
import { API_DESTROY_DATA_TYPES } from '../../constants';

const bulkDestroy = (
  model,
  data,
  { prefix, baseUrl, querySerializers, postfix } = {},
) => {
  if (!data) {
    throw new Error('Bulk delete without data is not allowed');
  }

  const { requestPayloads, ids } = data.reduce(
    (acc, { id } = {}) => {
      if (!id) {
        throw new Error('Destroy without id is not allowed.');
      }

      acc.requestPayloads.push({ id, type: kebabCase(model) });
      acc.ids.push(id);

      return acc;
    },
    { requestPayloads: [], ids: [] },
  );

  const endpoint = [kebabCase(model)];

  if (prefix) endpoint.unshift(prefix);
  if (postfix) endpoint.push(postfix);

  return dispatch => {
    const action = {
      types: asyncTypesArray(API_DESTROY_DATA_TYPES),
      endpoint: addQueryParameters(endpoint.join('/'), {
        querySerializers,
      }),
      method: 'DELETE',
      payload: { data: requestPayloads },
      meta: {
        ids,
        model: camelCase(model),
      },
      baseUrl,
    };

    return requestAsPromise(dispatch, action);
  };
};

export default bulkDestroy;
