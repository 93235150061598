export enum Plan {
  None = 'ws_none',
  Tier1 = 'ws_tier_1',
  Tier2 = 'ws_tier_2',
  Tier3 = 'ws_tier_3',
}

export const PlanNames = {
  [Plan.Tier1]: 'Standard',
  [Plan.Tier2]: 'Pro',
  [Plan.Tier3]: 'Enterprise',
};

export enum SubscriptionStatus {
  Active = 'active',
  Cancelled = 'cancelled',
  InTrial = 'in_trial',
  NonRenewing = 'non_renewing',
  Paused = 'paused',
}
