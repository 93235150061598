import { CALL_API } from './constants';

export function request({
  types,
  endpoint,
  method,
  expiresIn,
  payload,
  meta,
  baseUrl,
}) {
  return {
    [CALL_API]: { types, endpoint, method, expiresIn, payload, meta, baseUrl },
  };
}

export function requestAsPromise(
  dispatch,
  { types, endpoint, method, expiresIn, payload, meta, baseUrl },
) {
  return new Promise((resolve, reject) => {
    const promisedAction = {
      [CALL_API]: {
        types,
        endpoint,
        method,
        expiresIn,
        payload,
        meta,
        baseUrl,
        promise: { resolve, reject },
      },
    };
    dispatch(promisedAction);
  });
}
