import React from 'react';

const UploadImagesSVG = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="100%"
    width="100%"
    viewBox="0 0 168 137"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#DFD7E7">
        <path d="M1 0h40v6H1zM119 1h11.901v3.344H119zM136 1h11.901v3.344H136zM153 1h11.901v3.344H153z" />
      </g>
      <path fill="#FDFDFD" d="M1 15h54v60H1z" />
      <path fill="#D0B4E9" d="M1 73h42v2H1z" />
      <path fill="#F7F2FC" d="M7 21h42v42H7z" />
      <path fill="#FDFDFD" d="M1 77h54v60H1z" />
      <path fill="#D0B4E9" d="M1 135h33.901v2H1z" />
      <path fill="#F7F2FC" d="M7 83h42v42H7z" />
      <path fill="#FDFDFD" d="M57 77h54v60H57z" />
      <path fill="#D0B4E9" d="M57 135h17.901v2H57z" />
      <path fill="#F7F2FC" d="M63 83h42v42H63z" />
      <g>
        <path fill="#FDFDFD" d="M113 77h54v60h-54z" />
        <path fill="#D0B4E9" d="M113 135h8v2h-8z" />
        <path fill="#F7F2FC" d="M119 83h42v42h-42z" />
      </g>
      <g>
        <path fill="#FDFDFD" d="M57 15h54v60H57z" />
        <path fill="#D0B4E9" d="M57 73h41.901v2H57z" />
        <path fill="#F7F2FC" d="M63 21h42v42H63z" />
      </g>
      <g>
        <path fill="#FDFDFD" d="M113 15h54v60h-54z" />
        <path fill="#D0B4E9" d="M113 73h33.901v2H113z" />
        <path fill="#F7F2FC" d="M119 21h42v42h-42z" />
      </g>
      <path fill="#DFD7E7" fillRule="nonzero" d="M.5 11v-1h167v1z" />
    </g>
  </svg>
);

export default UploadImagesSVG;
