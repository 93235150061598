import styled from 'styled-components/macro';
import { Box as RebassBox, Flex as RebassFlex } from '@rebass/grid';
import { height, display, position } from 'styled-system';

export const Box = styled(RebassBox)`
  ${display};
  ${height};
`;

export const PositionBox = styled(Box)`
  ${position};
`;

export const AbsoluteBox = styled(PositionBox).attrs({
  position: 'absolute',
})``;

export const FixedBox = styled(PositionBox).attrs({
  position: 'fixed',
})``;

export const RelativeBox = styled(PositionBox).attrs({
  position: 'relative',
})``;

export const Flex = styled(RebassFlex)`
  ${height};
`;

export const PositionFlex = styled(Flex)`
  ${position};
`;

export const AbsoluteFlex = styled(PositionFlex).attrs({
  position: 'absolute',
})``;

export const FixedFlex = styled(PositionFlex).attrs({
  position: 'fixed',
})``;

export const RelativeFlex = styled(PositionFlex).attrs({
  position: 'relative',
})``;
