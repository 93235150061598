import React from 'react';
import PropTypes from 'prop-types';

import { FlexAlignCenter, FlexAlignLeft, FlexAlignRight } from '@picter/prisma';

import { useLayoutInfo } from '../utils';
import TopSection from '../styles/GeneralLayoutTopSection';

const GeneralLayoutTop = props => {
  const { mode } = useLayoutInfo();
  return <TopSection mode={mode} {...props} />;
};

GeneralLayoutTop.propTypes = {
  children: PropTypes.node,
};

GeneralLayoutTop.defaultProps = {
  children: null,
};

GeneralLayoutTop.AlignCenter = FlexAlignCenter;
GeneralLayoutTop.AlignLeft = FlexAlignLeft;
GeneralLayoutTop.AlignRight = FlexAlignRight;

export default GeneralLayoutTop;
