import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonElement as Button, Modal, TextField } from '@picter/prisma';

import { Body, Box } from 'src/modules/prisma';
import useFormatMessage from 'src/hooks/use-format-message';

import messages from '../_messages';

export default function ReviewPageReviewDoneModal({
  onClose,
  open,
  onSubmit,
  space,
}) {
  const formatMessage = useFormatMessage();
  const [message, setMessage] = useState(
    formatMessage(messages.messageReviewDone),
  );

  const handleChangeMessage = useCallback(
    e => {
      setMessage(e.target.value);
    },
    [setMessage],
  );

  const handleClickFinish = useCallback(() => {
    if (message) {
      onSubmit(message);
      onClose();
    }
  }, [message, onSubmit, onClose]);

  return (
    <Modal
      open={open}
      onClickClose={onClose}
      title={formatMessage(messages.titleReviewDone)}
    >
      <Box>
        <Body mb={4}>
          {formatMessage(messages.messageLeaveMessage, { space })}
        </Body>
        <TextField
          value={message}
          onChange={handleChangeMessage}
          required
          autoFocus
        />
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={onClose} variant="flat" color="muted">
            {formatMessage(messages.labelCancel)}
          </Button>
          <Button
            onClick={handleClickFinish}
            variant="flat"
            color="primary"
            disabled={!message}
          >
            {formatMessage(messages.labelFinish)}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

ReviewPageReviewDoneModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  space: PropTypes.string.isRequired,
};

ReviewPageReviewDoneModal.defaultProps = {
  onClose: undefined,
  open: false,
};
