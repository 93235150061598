import { useParams } from 'react-router-dom';

import { useProject } from 'src/hooks/use-resource';
import { replacePublicLinkKey } from 'src/utils/inject-public-link-key';
import { get } from 'src/utils/accessors';

/* eslint-disable import/prefer-default-export */
export function useProjectChannelName() {
  const params = useParams();
  const { projectId: projectPublicUrlKey } = params;
  const project = useProject({ id: projectPublicUrlKey }, { request: false });
  const projectId = get(project, 'id');
  const channelName = `resource=ws-projects;id=${projectId};publicKey=${projectPublicUrlKey}`;
  return replacePublicLinkKey(channelName, { params });
}
/* eslint-enable import/prefer-default-export */
