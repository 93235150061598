import { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { useLocation } from 'react-router-dom';

/**
 * useEffectSubscribeToLocationState(modeState => modeState, ['modeState']);
 */
// eslint-disable-next-line import/prefer-default-export
export function useSubscribeToLocationState(onChange, stateKeys) {
  const { state } = useLocation();
  const [stateValues, setStateValues] = useState([]);

  if (state) {
    const values = stateKeys
      .map(stateKey => state[stateKey])
      .filter(value => !!value);

    if (!isEqual(stateValues, values)) {
      setStateValues(values);
    }
  }

  return useEffect(() => {
    onChange(...stateValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateValues]);
}
