import React, { useRef, memo, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { toastInfo } from '@picter/prisma';
import {
  branch,
  compose,
  withHandlers,
  withProps,
  withState,
  mapProps,
} from 'recompose';
import { List } from 'immutable';
import { injectIntl } from 'react-intl';
import { DragSource, DropTarget } from 'react-dnd';

import { get } from 'src/utils/accessors';
import {
  removeImage,
  addApprovalProjectImage,
  removeApprovalProjectImage,
  replaceImageVersion,
} from 'src/actions/project-image';
import branchMediaQuery from 'src/hocs/branch-media-query';
import { useToriiActions } from 'src/modules/torii';
import {
  loginUrl,
  imageInfoUrl,
  libraryProjectImageDownloadUrl,
  imageCommentsUrl,
} from 'src/routes/urls';
import { useProjectImage, useCurrentUser } from 'src/hooks/use-resource';
import injectPublicLinkKey, {
  replacePublicLinkKey,
} from 'src/utils/inject-public-link-key';
import usePermission from 'src/hooks/use-permission';
import withFlexibleDiv from 'src/hocs/with-flexible-div';
import withSelectAndDragWrappers from 'src/hocs/with-select-and-drag-wrappers';
import {
  withSelectableProperties,
  withSelectableLayout,
} from 'src/modules/SelectableComponents';
import {
  getProjectFileThumbnailUrl,
  getProjectVideoDuration,
} from 'src/utils/accessors/project-image';
import useModal from 'src/hooks/use-modal';
import LiteUserRegisterModal from 'src/components/LiteUserRegisterModal';

import ImageCard from './components/ImageCard';
import messages from './messages';
import { dropTargetSpecs, dragSourceSpecs } from './dnd-handlers';
import ImageCardPlaceholder from './components/ImageCardPlaceholder';

export { ImageCardPlaceholder };

const imageProps = ({ image, user }) => {
  const userId = get(user, 'id');
  const approvals = get(image, 'relationships.approvals', List());
  const userHasApproved = approvals.some(approve => {
    const approveUserId = get(approve, 'relationships.user.id');
    return approveUserId === userId;
  });
  const hasBeenRejected = get(image, 'attributes.rejected');

  return {
    // Need to send undefined instead of false. Need boolean to avoid rerenders.
    approvals,
    approvedByUser: userHasApproved ? true : undefined,
    collectionsCounter: get(
      image,
      'relationships.collectionImages.data',
      List(),
    ).size,
    rejected: hasBeenRejected,
    rejectedBy: hasBeenRejected
      ? get(image, 'relationships.rejectedByUser')
      : undefined,
    title: get(image, 'attributes.title'),
    duration: getProjectVideoDuration(image),
    isVideo: get(image, 'attributes.isVideo'),
    isPdf: get(image, 'attributes.isPdf'),
    isNonVisual: get(image, 'attributes.isNonVisual'),
  };
};

const generalProps = ({ match }) => {
  return {
    publicKey: match.params.projectId,
  };
};

const uploadProps = ({ image, uploadPreview, uploading, fileExtension }) => {
  let src;

  if (uploading && uploadPreview) {
    src = uploadPreview;
  } else {
    const isVideo = get(image, 'attributes.isVideo');
    const isPDF = get(image, 'attributes.isPdf');
    const fileThumbnail =
      isVideo || isPDF
        ? getProjectFileThumbnailUrl(image)
        : get(image, 'attributes.files.small.location');
    src = fileThumbnail || uploadPreview;
  }

  return { src, uploading, fileExtension };
};

const handlers = {
  toggleApprove: ({ image, tracker, bulkCreate, bulkDestroy, match, user }) => (
    event,
    approve,
  ) => {
    tracker.track(
      approve ? 'Clicked Image approve' : 'Clicked Image remove approval',
    );
    if (approve) {
      return addApprovalProjectImage(
        match.params.projectId,
        get(user, 'id'),
        get(image, 'id'),
        {
          bulkCreate,
          match,
        },
      );
    }

    const approvalId = `${get(image, 'id')}:${get(user, 'id')}`;

    return removeApprovalProjectImage(match.params.projectId, approvalId, {
      bulkDestroy,
      match,
    });
  },
  handleToggleRejection: ({ image, update, tracker, match }) => async () => {
    const { querySerializers } = injectPublicLinkKey(
      {
        querySerializers: {
          publicKey: () => `publicKey=${match.params.projectId}`,
        },
      },
      match,
    );
    const rejected = !get(image, 'attributes.rejected');
    await update('wsProjectImages', {
      id: get(image, 'id'),
      attributes: {
        rejected,
      },
      querySerializers,
    });
    tracker.track(rejected ? 'Rejected image' : 'Removed rejection from image');
  },
  handleChangeTitle: ({
    match,
    image,
    intl,
    update,
    tracker,
  }) => async event => {
    const { value: newTitle } = event.target;
    const oldTitle = get(image, 'attributes.title');
    if (newTitle !== oldTitle) {
      const { querySerializers } = injectPublicLinkKey(
        {
          querySerializers: {
            publicKey: () => `publicKey=${match.params.projectId}`,
          },
        },
        match,
      );
      await update('wsProjectImages', {
        id: get(image, 'id'),
        attributes: {
          title: newTitle,
        },
        querySerializers,
      });
      tracker.track('Changed Image title');
      toastInfo(intl.formatMessage(messages.messageImageTitleChanged));
    }
  },
  handleClickRemove: ({
    image,
    publicKey,
    match,
    history,
    localDestroy,
    localCreate,
    destroy,
  }) => () => {
    return removeImage({
      image,
      projectPublicUrlKey: publicKey,
      match,
      history,
      localDestroy,
      localCreate,
      destroy,
    });
  },
  handleClickRename: ({ editableTitleRef }) => () => {
    const { current: editableTitleElem } = editableTitleRef;
    if (editableTitleElem) {
      editableTitleElem.focus();
    }
  },
  handleClickDownload: ({ image, match }) => () => {
    const url = replacePublicLinkKey(
      libraryProjectImageDownloadUrl({
        imageId: get(image, 'id'),
        downloadSize: 'original',
        projectPublicKey: get(
          image,
          'relationships.project.attributes.publicUrlKey',
        ),
      }),
      match,
    );
    window.open(url, '_self');
  },
  handleClickComments: ({
    image,
    history,
    location: { query },
    match: {
      params: { projectId: projectPublicUrlKey, collectionId },
    },
  }) => () =>
    history.push(
      imageCommentsUrl(
        {
          imageId: get(image, 'id'),
          projectId: projectPublicUrlKey,
          collectionId,
        },
        query,
      ),
    ),
  handleDropPosition: ({ setHoverSide, hoverSide }) => e => {
    if (e.currentTarget && e.currentTarget.getBoundingClientRect) {
      const rect = e.currentTarget.getBoundingClientRect();
      const hoverMiddleX = (rect.right - rect.left) / 2;
      const mouseX = e.clientX - rect.left;
      if (mouseX >= hoverMiddleX && hoverSide !== 'right') {
        setHoverSide('right');
      } else if (mouseX < hoverMiddleX && hoverSide !== 'left') {
        setHoverSide('left');
      }
    }
  },
  handleReplaceImageVersion: ({
    image,
    match,
    load,
    update,
    localUpdate,
  }) => async files =>
    replaceImageVersion({
      imageId: get(image, 'id'),
      fileBlob: files[0],
      match,
      load,
      update,
      localUpdate,
    }),
  handleClickInfo: ({
    image,
    history,
    match: {
      params: { collectionId, projectId },
    },
    query,
  }) => () =>
    history.push(
      imageInfoUrl(
        {
          imageId: get(image, 'id'),
          collectionId,
          projectId,
        },
        query,
      ),
    ),
  handleClickLink: ({ image, history, location, link }) => () => {
    const { pathname } = location;
    // Pushing the state with the image id to the history first and once its done its
    // pushing the correct one to redirect to the image page. the listen is needed because
    // if I just push both they run in parallel and its kinda buggy.
    // history.push does not return a promise or anything so we can wait.
    const unlisten = history.listen(() => {
      unlisten();
      history.push(link);
    });
    history.push(pathname, { gridPositionImageId: get(image, 'id') });
  },
};

const withLiteUserForm = WrappedComponent => properties => {
  // TODO: This HOC its just a temporary solution. In the future we are going to migrate this component
  // to hooks and this wont be needed anymore

  const modal = useMemo(() => {
    return (
      <LiteUserRegisterModal
        onLoginRedirect={location => ({
          pathname: loginUrl(),
          search: `?redirectUrl=${[location.pathname, location.search].join(
            '',
          )}`,
        })}
      />
    );
  }, []);

  const [liteUserRegisterModal, { open: openLiteUserRegisterModal }] = useModal(
    modal,
  );

  const isUserLogged = properties.user && !properties.user.isEmpty();

  return (
    <>
      {liteUserRegisterModal}
      <WrappedComponent
        {...properties}
        liteUserRegisterModal={liteUserRegisterModal}
        openLiteUserRegisterModal={openLiteUserRegisterModal}
        toggleApprove={
          isUserLogged ? properties.toggleApprove : openLiteUserRegisterModal
        }
        handleToggleRejection={
          isUserLogged
            ? properties.handleToggleRejection
            : openLiteUserRegisterModal
        }
      />
    </>
  );
};

const withMemoTorii = WrappedComponent => properties => {
  // TODO: This HOC its just a temporary solution. In the future we are going to migrate this component
  // to hooks and this wont be needed anymore
  const { id } = properties;

  const image = useProjectImage(
    {
      id,
      include: ['approvals.user', 'rejectedByUser'],
    },
    { request: false },
  );

  const user = useCurrentUser({}, { request: false });

  const {
    create,
    bulkCreate,
    destroy,
    bulkDestroy,
    update,
    load,
    localUpdate,
    localDestroy,
    localCreate,
  } = useToriiActions();

  const editableTitleRef = useRef();

  return (
    <WrappedComponent
      {...properties}
      user={user}
      image={image}
      create={create}
      bulkCreate={bulkCreate}
      destroy={destroy}
      bulkDestroy={bulkDestroy}
      update={update}
      load={load}
      localUpdate={localUpdate}
      localDestroy={localDestroy}
      localCreate={localCreate}
      editableTitleRef={editableTitleRef}
    />
  );
};

export default compose(
  memo,
  injectIntl,
  withRouter,
  withMemoTorii,
  // firefox does not fire any onDragOver events, so we have to
  // set a default value for it (always drop image on the right side)
  withState('hoverSide', 'setHoverSide', 'right'),
  withProps(imageProps),
  withProps(uploadProps),
  withProps(generalProps),
  withHandlers(handlers),
  withProps(() => {
    const { canSelect } = usePermission();
    return { canSelect };
  }),
  branchMediaQuery(
    { minWidth: 0 },
    branch(
      /**
       * In case the user has select permission render selectable functionality
       * otherwise just a div that receives margin and width (because of image grid)
       */
      ({ canSelect }) => canSelect,
      withSelectableProperties,
      withFlexibleDiv,
    ),
    withFlexibleDiv,
  ),
  DragSource('image', dragSourceSpecs, (connectDnD, monitor) => ({
    connectDragSource: connectDnD.dragSource(),
    connectDragPreview: connectDnD.dragPreview(),
    isDragging: monitor.isDragging(),
  })),
  DropTarget('image', dropTargetSpecs, (connectDnD, monitor) => ({
    connectDropTarget: connectDnD.dropTarget(),
    isOver: monitor.isOver(),
  })),
  mapProps(({ selection, ...props }) => {
    return props;
  }),
  memo,
  withSelectAndDragWrappers,
  branchMediaQuery(
    { minWidth: 0 },
    branch(
      ({ canSelect }) => canSelect,
      withSelectableLayout(({ isDragging }) => ({
        shouldHideLayout: isDragging,
      })),
    ),
  ),
  withLiteUserForm,
)(ImageCard);
