import React from 'react';
import PropTypes from 'prop-types';
import { ButtonElement as Button, TextField } from '@picter/prisma';
import { Flex } from '@rebass/grid';
import { FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import useFormatMessage from 'src/hooks/use-format-message';
import useScript from 'src/hooks/use-script';

import messages from '../messages';

function LiteUserRegisterForm({
  onLoginClick,
  onSubmit,
}: {
  onLoginClick: () => void;
  onSubmit: (values: {
    email: string;
    name?: string;
    recaptchaToken: string;
  }) => void;
}) {
  const formatMessage = useFormatMessage();
  const reCaptchaScriptStatus = useScript(
    `https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`,
    { removeOnUnmount: true },
  );
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleReset,
    handleSubmit,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: '',
      name: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(formatMessage(messages.messageLiteUserEmailError))
        .required(formatMessage(messages.messageLiteUserEmailError)),
    }),
    onSubmit({ email, name }) {
      window.grecaptcha?.enterprise.ready(async () => {
        const recaptchaToken = await window.grecaptcha.enterprise.execute(
          process.env.REACT_APP_RECAPTCHA_SITE_KEY as string,
          { action: 'signup' },
        );
        onSubmit({ email, name: name.trim() || undefined, recaptchaToken });
      });
    },
  });
  return (
    <form onReset={handleReset} onSubmit={handleSubmit}>
      <FormattedMessage {...messages.placeholderLiteUserRegisterModalName}>
        {formattedMessage => (
          <TextField
            name="name"
            placeholder={formattedMessage}
            value={values.name}
            error={touched.name && errors.name}
            onBlur={handleBlur}
            onChange={handleChange}
            autoFocus
          />
        )}
      </FormattedMessage>
      <FormattedMessage {...messages.placeholderLiteUserRegisterModalEmail}>
        {formattedMessage => (
          <TextField
            error={touched.email && errors.email}
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={formattedMessage}
            type="email"
            value={values.email}
            required
          />
        )}
      </FormattedMessage>
      <Flex justifyContent="space-between" mt={7}>
        <Link to={onLoginClick}>
          <Button
            color="grey.600"
            textStyle="action"
            py={0}
            px={0}
            type="button"
            variant="flat"
          >
            <FormattedMessage {...messages.labelLiteUserRegisterModalLogin} />
          </Button>
        </Link>
        <Flex>
          <Button
            color="primary"
            disabled={
              !isValid || isSubmitting || reCaptchaScriptStatus !== 'ready'
            }
            textStyle="action.large"
            px={0}
            py={0}
            type="submit"
            variant="flat"
          >
            <FormattedMessage {...messages.labelLiteUserRegisterModalConfirm} />
          </Button>
        </Flex>
      </Flex>
    </form>
  );
}

LiteUserRegisterForm.propTypes = {
  onLoginClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default LiteUserRegisterForm;
