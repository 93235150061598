import { useMemo } from 'react';
import { ImageProofFilter } from 'src/utils/accessors/project-image';

import {
  imageUrl,
  projectUrl,
  projectShareUrl,
  projectShareInviteUrl,
  projectShareReviewUrl,
  projectSharePresentationUrl,
  projectShareRequestFilesUrl,
  projectCommentsUrl,
  projectInfoUrl,
} from 'src/routes/urls';

export default function useFilteredUrls(filter) {
  const urls = useMemo(() => {
    if (!filter || ImageProofFilter.ALL === filter) {
      return {
        filteredImageUrl: imageUrl,
        filteredProjectUrl: projectUrl,
        filteredProjectShareUrl: projectShareUrl,
        filteredProjectInfoUrl: projectInfoUrl,
        filteredProjectCommentsUrl: projectCommentsUrl,
        filteredProjectShareInviteUrl: projectShareInviteUrl,
        filteredProjectShareReviewUrl: projectShareReviewUrl,
        filteredProjectSharePresentationUrl: projectSharePresentationUrl,
        filteredProjectShareRequestFilesUrl: projectShareRequestFilesUrl,
      };
    }

    return {
      filteredImageUrl: imageUrl.builder(imageUrl.placeholder, {
        filter,
      }),
      filteredProjectUrl: projectUrl.builder(imageUrl.placeholder, {
        filter,
      }),
      filteredProjectShareUrl: projectShareUrl.builder(imageUrl.placeholder, {
        filter,
      }),
      filteredProjectCommentsUrl: projectCommentsUrl.builder(
        imageUrl.placeholder,
        {
          filter,
        },
      ),
      filteredProjectInfoUrl: projectInfoUrl.builder(imageUrl.placeholder, {
        filter,
      }),
      filteredProjectShareInviteUrl: projectShareInviteUrl.builder(
        imageUrl.placeholder,
        {
          filter,
        },
      ),
      filteredProjectShareReviewUrl: projectShareReviewUrl.builder(
        imageUrl.placeholder,
        {
          filter,
        },
      ),
      filteredProjectSharePresentationUrl: projectSharePresentationUrl.builder(
        imageUrl.placeholder,
        {
          filter,
        },
      ),
      filteredProjectShareRequestFilesUrl: projectShareRequestFilesUrl.builder(
        imageUrl.placeholder,
        {
          filter,
        },
      ),
    };
  }, [filter]);

  return urls;
}
