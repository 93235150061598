import { useContext, useEffect, useRef, useMemo } from 'react';

import toriiLoader, {
  removeCacheValidation,
  makeLoaderHash,
} from '../torii-loader';
import { ToriiContext } from '../ToriiProvider';
import { SuspenseLoaderContext } from '../hocs/create-with-suspense-loader/SuspenseLoaderProvider';

const useToriiLoader = (
  loader,
  { shouldLoad, revalidate, revalidateTimeout, autoRevalidate } = {
    shouldLoad: true,
  },
) => {
  const loaderHashValue = useRef(null);
  const { load } = useContext(ToriiContext);
  const handleError = useContext(SuspenseLoaderContext);
  const newLoaderHash = useMemo(
    () => (shouldLoad ? makeLoaderHash(loader) : null),
    [loader, shouldLoad],
  );

  useEffect(() => {
    return () => {
      if (autoRevalidate && loaderHashValue.current) {
        // Shuts down autoRevalidate when component unmounts
        removeCacheValidation(loaderHashValue.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLoaderHash]);

  if (shouldLoad && loaderHashValue.current !== newLoaderHash) {
    toriiLoader({
      load,
      loader,
      loaderHash: newLoaderHash,
      revalidate,
      revalidateTimeout,
      handleError,
      autoRevalidate,
    });
    loaderHashValue.current = newLoaderHash;
  }
};

export default useToriiLoader;
