import { defineMessages } from 'react-intl';

export default defineMessages({
  labelRename: {
    id: 'ImageCard.labelRename',
    defaultMessage: 'Rename',
  },
  messageImageTitleChanged: {
    id: 'ImageCard.messageImageTitleChanged',
    defaultMessage: 'File title changed successfully.',
  },
  labelUploadNewVersion: {
    id: 'ImageCard.labelUploadNewVersion',
    defaultMessage: 'Upload new version',
  },
  labelInfo: {
    id: 'ImageCard.labelInfo',
    defaultMessage: 'Info',
  },
  labelDownload: {
    id: 'ImageCard.labelDownload',
    defaultMessage: 'Download',
  },
  labelRemove: {
    id: 'ImageCard.labelRemove',
    defaultMessage: 'Delete',
  },
  labelRejectImage: {
    id: 'ImageCard.labelRejectImage',
    defaultMessage: 'Reject image',
  },
  labelRemoveRejection: {
    id: 'ImageCard.labelRemoveRejection',
    defaultMessage: 'Reset rejection',
  },
});
