import React from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from '@picter/prisma';

import ProjectShareModals from './components/ProjectShareModals';
import ShareButton from './styles/ShareButton';
import ShareDropdownMenu from './components/ShareDropdownMenu';
import messages from './messages';

function ProjectShare() {
  return (
    <>
      <Dropdown
        button={
          <ShareButton data-intercom-target="share-button">
            <FormattedMessage {...messages.labelShare} />
          </ShareButton>
        }
      >
        <ShareDropdownMenu />
      </Dropdown>
      <ProjectShareModals />
    </>
  );
}

export { ProjectShareModals, ShareDropdownMenu };
export default withRouter(ProjectShare);
