import styled from 'styled-components/macro';
import tag from 'clean-tag';
import { system } from 'styled-system';

const Separator = styled(tag.hr)`
  border: none;
  margin: 0;
  border-bottom: 1px solid;
  ${system({
    color: {
      property: 'borderColor',
      scale: 'colors',
    },
    size: {
      properties: ['marginBottom', 'paddingBottom'],
      scale: 'space',
    },
  })}
`;

Separator.defaultProps = {
  color: 'grey.300',
  size: 5,
};

Separator.displayName = 'Separator';

export default Separator;
