import { defineMessages } from 'react-intl';

export default defineMessages({
  titleReplaceImagesModal: {
    id:
      'ProjectPageModalConfirmReplaceModalConfirmReplace.titleReplaceImagesModal',
    defaultMessage: 'Replace files',
  },
  messageReplaceImagesModal: {
    id: 'ProjectPageModalConfirmReplace.labelInviteAPerson',
    defaultMessage:
      'Some filenames of your uploaded files match existing files in this project. Do you want to replace matching files?',
  },
  labelReplaceModalCancel: {
    id: 'ProjectPageModalConfirmReplace.labelReplaceModalCancel',
    defaultMessage: 'Cancel',
  },
  labelReplaceModalKeep: {
    id: 'ProjectPageModalConfirmReplace.labelReplaceModalKeep',
    defaultMessage: 'Keep both',
  },
  labelReplaceModalReplace: {
    id: 'ProjectPageModalConfirmReplace.labelReplaceModalReplace',
    defaultMessage: 'Replace',
  },
});
