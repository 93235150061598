import styled, { css } from 'styled-components/macro';
import { transparentize } from 'polished';

const selectingStyles = css`
  background-color: ${props =>
    transparentize(0.96, props.theme.colors.primary)};
`;

const selectedStyles = css`
  ${selectingStyles};
  box-shadow: inset 0 0 0 2px
    ${props => transparentize(0.8, props.theme.colors.primary)};
`;

const SelectionItemOverlay = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  user-select: none;
  ${props => props.selected && selectedStyles};
  ${props => props.selecting && selectingStyles};
`;

export default SelectionItemOverlay;
