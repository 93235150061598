import React from 'react';
import { FormattedMessage } from 'react-intl';

import IconGroup from 'src/components/IconGroup';
import { Icon, Tooltip } from 'src/modules/prisma';

import { publicLinkMessageMap, publicLinkIconMap } from './constants';

export default function ProjectShareState({ sharing }: { sharing: string[] }) {
  return (
    <IconGroup
      mode="stacked"
      overlapSize={1}
      style={{ justifyContent: 'flex-end' }}
    >
      {sharing.map(publicLinkType => {
        return (
          // @ts-ignore
          <Tooltip>
            {/* @ts-ignore */}
            <Tooltip.Reference className={`${publicLinkType}-reference`}>
              <span>
                <Icon
                  boxSize="xlarge"
                  size="small"
                  borderColor="grey.200"
                  borderStyle="solid"
                  borderWidth="1px"
                  backgroundColor="white"
                  color="grey.600"
                  type={publicLinkIconMap[publicLinkType]}
                />
              </span>
              {/* @ts-ignore */}
            </Tooltip.Reference>
            {/* @ts-ignore */}
            <Tooltip.Message>
              <FormattedMessage {...publicLinkMessageMap[publicLinkType]} />
              {/* @ts-ignore */}
            </Tooltip.Message>
          </Tooltip>
        );
      })}
    </IconGroup>
  );
}
