import React from 'react';

export default function EmptyCollectionIllustrationSvg() {
  return (
    <svg
      width="197"
      height="160"
      viewBox="0 0 197 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="93.0972"
        y="1.35357"
        width="116"
        height="116"
        transform="rotate(28.1596 93.0972 1.35357)"
        stroke="#FFC51F"
        strokeWidth="2"
        strokeDasharray="15 15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.8138 22.1714L39.5347 15L46.7061 16.7209L46.2394 18.6657L41.0128 17.4115L39.7586 22.6381L37.8138 22.1714ZM75.3917 23.6045L61.0489 20.1627L60.5822 22.1075L74.925 25.5493L75.3917 23.6045ZM89.7345 27.0463L89.2678 28.9911L103.611 32.4329L104.077 30.4882L89.7345 27.0463ZM118.42 33.93L117.953 35.8748L132.296 39.3166L132.763 37.3718L118.42 33.93ZM147.106 40.8136L146.639 42.7584L151.866 44.0126L150.612 49.2392L152.556 49.7059L154.277 42.5345L147.106 40.8136ZM149.114 64.0487L147.17 63.582L143.728 77.9249L145.673 78.3915L149.114 64.0487ZM142.231 92.7344L140.286 92.2677L136.844 106.61L138.789 107.077L142.231 92.7344ZM135.347 121.42L133.402 120.953L129.961 135.296L131.905 135.763L135.347 121.42ZM128.464 150.106L126.519 149.639L125.265 154.866L120.038 153.611L119.571 155.556L126.743 157.277L128.464 150.106ZM105.228 152.114L105.695 150.17L91.3523 146.728L90.8857 148.673L105.228 152.114ZM76.5428 145.231L77.0095 143.286L62.6667 139.844L62.2 141.789L76.5428 145.231ZM47.8572 138.347L48.3239 136.402L33.9811 132.96L33.5144 134.905L47.8572 138.347ZM19.1716 131.463L19.6383 129.519L14.4116 128.264L15.6659 123.038L13.7211 122.571L12.0002 129.743L19.1716 131.463ZM17.1629 108.228L19.1077 108.695L22.5495 94.3522L20.6047 93.8855L17.1629 108.228ZM24.0465 79.5427L25.9913 80.0094L29.4331 65.6665L27.4883 65.1999L24.0465 79.5427ZM30.9301 50.857L32.8749 51.3237L37.4449 32.4667L35.5001 32L30.9301 50.857Z"
        fill="#5C62CA"
      />
      <rect x="19" y="54" width="84" height="84" fill="#F0EEF2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118 38H0V156H118V38ZM58.5497 69.5267H61.252V95.6488H87.3742V98.3511H61.252V125.374H58.5497V98.3511H31.5268V95.6488H58.5497V69.5267Z"
        fill="white"
      />
    </svg>
  );
}
