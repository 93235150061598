import React from 'react';
import ContentLoader from 'react-content-loader';
import { withTheme } from 'styled-components/macro';

import DelayRender from 'src/components/DelayRender';
import { themeShape } from 'src/utils/app-prop-types';

import Card from '../styles/ImageCardCard';
import ExtraContainer from '../styles/ImageCardExtraContainer';

const ImageCardPlaceholder = ({ theme }) => (
  <DelayRender>
    <Card>
      <Card.Extra />
      <Card.Content>
        <ContentLoader
          height={195}
          width={200}
          primaryColor={theme.colors.gray['100']}
          secondaryColor={theme.colors.gray['300']}
          style={{ height: '100%', width: '100%' }}
          preserveAspectRatio="none"
        >
          <rect x="0" y="0" rx="0" ry="0" width="200" height="195" />
        </ContentLoader>
      </Card.Content>
      <Card.Extra large>
        <ExtraContainer>
          <ContentLoader
            height={60}
            width={200}
            primaryColor={theme.colors.gray['100']}
            secondaryColor={theme.colors.gray['300']}
            style={{ height: '100%', width: '100%' }}
            preserveAspectRatio="xMinYMin meet"
          >
            <rect x="50" y="4" rx="0" ry="0" width="100" height="18" />
            <rect x="70" y="38" rx="0" ry="0" width="60" height="18" />
          </ContentLoader>
        </ExtraContainer>
      </Card.Extra>
    </Card>
  </DelayRender>
);

ImageCardPlaceholder.propTypes = {
  theme: themeShape.isRequired,
};

export default withTheme(ImageCardPlaceholder);
