import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'RetryModal.title',
    defaultMessage: 'Are you still online?',
  },
  message: {
    id: 'RetryModal.message',
    defaultMessage:
      'Ups. Your internet connection seems to be unstable. We paused the upload so you can check whats going on.',
  },
  labelRetry: {
    id: 'RetryModal.labelRetry',
    defaultMessage: 'Retry',
  },
  labelKeepTrying: {
    id: 'RetryModal.labelKeepRetrying',
    defaultMessage: 'Keep trying',
  },
});
