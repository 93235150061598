export default function validateAction(action) {
  const { types, endpoint, method, expiresIn } = action;

  if (typeof endpoint !== 'string') {
    throw new Error('Expected endpoint to be a URL string');
  }

  if (!Array.isArray(types) && types.length !== 3) {
    throw new Error('Expected types to be an array of 3 strings.');
  }

  const allowedMethods = [
    'POST',
    'PUT',
    'PATCH',
    'GET',
    'OPTION',
    'DELETE',
    'HEAD',
  ];
  if (allowedMethods.indexOf(method) === -1) {
    throw new Error(
      `Method ${method} is not allowed. Allowed methods are: ${allowedMethods.join(
        ',',
      )}`,
    );
  }

  if (expiresIn && method !== 'GET') {
    throw new Error('Caching is only allowed for method GET.');
  }

  if (expiresIn && typeof expiresIn !== 'number') {
    throw new Error('expiresIn must be a number');
  }
}
