import React from 'react';

const ReviewAndDownloadSVG = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="100%"
    width="100%"
    viewBox="0 0 171 114"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#FDFDFD" d="M77 15v38h5.621v61H0V15z" />
      <path fill="#DFD7E7" d="M43.947 106.929v1.767h-.879v-6.187h5.274v4.42z" />
      <path fill="#D0B4E9" d="M35.158 106.929v1.767h-.88v-6.187h5.275v4.42z" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M46.093 104.763l.799.803-.39.388-.797-.801-.796.8-.39-.387.798-.803-.798-.803.39-.388.796.8.797-.8.39.388zM36.161 105.338l1.869-1.88.39.388-1.871 1.882h.001l-.194.194-.194.195v-.001h-.002l-.193-.194-.195-.193.001-.001-.565-.568.39-.388z"
      />
      <path fill="#F0EAF6" d="M15.3 93.552h51.869v4.094H15.3z" />
      <path fill="#F7F2FC" d="M9.18 24.193h64.261v64.35H9.18z" />
      <g>
        <path fill="#FDFDFD" d="M85 53h82.621v61H85z" />
        <path
          fill="#DFD7E7"
          d="M128.947 106.929v1.767h-.879v-6.187h5.274v4.42z"
        />
        <path
          fill="#D0B4E9"
          d="M120.158 106.929v1.767h-.88v-6.187h5.275v4.42z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M131.093 104.763l.799.803-.39.388-.797-.801-.796.8-.39-.387.798-.803-.798-.803.39-.388.796.8.797-.8.39.388zM121.161 105.338l1.869-1.88.39.388-1.871 1.882h.001l-.194.194-.194.195v-.001h-.002l-.193-.194-.195-.193.001-.001-.565-.568.39-.388z"
        />
        <path fill="#F0EAF6" d="M100.3 93.552h51.869v4.094H100.3z" />
        <path fill="#F7F2FC" d="M94.18 53.193h64.261v35.35H94.18z" />
      </g>
      <g>
        <path fill="#FFF" d="M80 10h90.16v40.072H80z" />
        <path
          fill="#E1CCF3"
          d="M99.268 18c.182 0 .349.07.502.21.153.142.23.295.23.462v4.656c0 .167-.077.32-.23.461a.728.728 0 0 1-.502.211h-6.536a.733.733 0 0 1-.51-.203.62.62 0 0 1-.222-.469v-4.656c0-.167.077-.32.23-.461.153-.14.32-.211.502-.211h6.536zm-5.804 4.672h5.072l-1.634-2-1.26 1.5-.919-1-1.26 1.5zM108 19h49.901v3.5H108z"
        />
        <path
          fill="#DFD7E7"
          d="M108 37h49.901v3.5H108zM92.732 41.328h3.625L95.2 39.906l-.902 1.078-.664-.718-.902 1.062zM92 38.672h5.09V42h-4.358a.733.733 0 0 1-.51-.203.62.62 0 0 1-.222-.469v-2.656zM93.464 36h.715v.672h-.715V36zm1.447 0h.732v.672h-.732V36zm-2.18 0v.672H92c0-.167.077-.32.23-.461.153-.14.32-.211.502-.211zm5.09 5.328h.715V42h-.715v-.672zm0-5.328h.715v.672h-.715V36zM92 37.328h.732V38H92v-.672zM99.268 36c.182 0 .349.07.502.21.153.142.23.295.23.462h-.732V36zm0 1.328H100V38h-.732v-.672zM96.358 36h.731v.672h-.732V36zM100 41.328c0 .167-.077.32-.23.461a.728.728 0 0 1-.502.211v-.672H100zm-.732-2.656H100v.656h-.732v-.656zm0 1.328H100v.672h-.732V40z"
        />
      </g>
      <g>
        <path fill="#DFD7E7" d="M121 0h38.901v6H121z" />
        <path fill="#D0B4E9" d="M165.5 5l2.5-4h-5z" />
      </g>
    </g>
  </svg>
);

export default ReviewAndDownloadSVG;
