import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonElement as Button } from '@picter/prisma';

import { Box, Separator } from 'src/modules/prisma';
import { tGet } from 'src/utils/accessors';
import { PlanResource, PartnerLicenseResource } from 'src/types/resources';

import { CurrentPlanPanel } from './SubscriptionPage';
import messages from '../_messages';
import PartnerLicenseTiers from './PartnerLicenseTiers';

export default function PartnerLicenseManagement({
  partnerLicense,
  plan,
}: {
  partnerLicense: PartnerLicenseResource;
  plan: PlanResource;
}) {
  const planName = tGet(plan, 'attributes', 'name');
  const partnerLicenseUrl = tGet(
    partnerLicense,
    'attributes',
    'manageLicenseUrl',
  );

  return (
    <>
      <CurrentPlanPanel
        actionButton={
          <a
            href={`https://appsumo.8odi.net/c/2641777/416948/7443?u=${partnerLicenseUrl}&sharedid=manageLicense`}
            target="blank"
          >
            <Button color="white" width="100%">
              <FormattedMessage {...messages.labelManageLicense} />
            </Button>
          </a>
        }
        planName={planName}
      />
      <Separator size={6} />
      <Box px={5}>
        <Box mx={-5} mb={-3} mt={3}>
          <PartnerLicenseTiers
            currentLicenseSlug={tGet(plan, 'attributes', 'slug')}
            licenseUrl={partnerLicenseUrl}
          />
        </Box>
      </Box>
    </>
  );
}
