import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Delete as DeleteSvg } from '@styled-icons/material';

import { deleteCollection } from 'src/actions/collection';
import { projectUrl } from 'src/routes/urls';
import useModal from 'src/hooks/use-modal';
import useRouter from 'src/hooks/use-router';
import { useToriiActions } from 'src/modules/torii';

import DeleteCollectionModal from './DeleteCollectionModal';
import InteractiveIcon from '../styles/InteractiveIcon';

function DeleteCollectionIcon({ collectionId, projectPublicUrlKey }) {
  const { destroy } = useToriiActions();
  const {
    history,
    match: { params },
  } = useRouter();

  const deleteCollectionAndRedirect = useCallback(async () => {
    await deleteCollection({ collectionId, projectPublicUrlKey }, { destroy });
    if (params.collectionId === collectionId) {
      history.push(
        projectUrl({
          projectId: projectPublicUrlKey,
        }),
      );
    }
  }, [
    collectionId,
    destroy,
    history,
    params.collectionId,
    projectPublicUrlKey,
  ]);

  const [deleteCollectionModal, { open: openDeleteCollectionModal }] = useModal(
    <DeleteCollectionModal onClickDelete={deleteCollectionAndRedirect} />,
  );

  return (
    <>
      <InteractiveIcon
        color="grey.600"
        onClick={openDeleteCollectionModal}
        type={<DeleteSvg />}
      />
      {deleteCollectionModal}
    </>
  );
}

DeleteCollectionIcon.propTypes = {
  collectionId: PropTypes.string.isRequired,
  projectPublicUrlKey: PropTypes.string.isRequired,
};

export default DeleteCollectionIcon;
