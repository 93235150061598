import React from 'react';
import { Dropdown } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';
import {
  NotificationsOff as NotificationsOffSvg,
  NotificationsActive as NotificationsActiveSvg,
} from '@styled-icons/material';
import { Route } from 'react-router-dom';

import ActionsDropdown from 'src/components/ActionsDropdown';
import AddImageSvg from 'src/assets/AddImageSvg';
import CommentsSvg from 'src/assets/CommentsSvg';
import ShareSvg from 'src/assets/ShareSvg';
import usePermission from 'src/hooks/use-permission';
import useRouter from 'src/hooks/use-router';
import { libraryProjectUrl } from 'src/routes/urls';
import { viewModeTypes } from 'src/routes/constants';

import messages from './messages';

export type Props = {
  onClickComments?: () => void;
  onClickDeleteCollection?: () => void;
  onClickDeleteProject?: () => void;
  onClickDownloadProject?: () => void;
  onClickDuplicateProject?: () => void;
  onClickInfoButton?: () => void;
  following?: boolean;
  onClickFollow?: (following: boolean) => void;
  onClickRenameCollection?: () => void;
  onClickRestoreProject?: () => void;
  onClickMoveToTrash?: () => void;
  openFileDialog?: () => void;
};

export default function ProjectPageActionsDropdownMobile({
  onClickComments,
  onClickDeleteCollection,
  onClickDeleteProject,
  onClickDownloadProject,
  onClickDuplicateProject,
  onClickInfoButton,
  following,
  onClickFollow,
  onClickRenameCollection,
  onClickRestoreProject,
  onClickMoveToTrash,
  openFileDialog,
}: Props) {
  const {
    canDownloadImages,
    canDuplicateProject,
    canManageCollections,
    canUploadImages,
    canShareProject,
    canManagePublicLinks,
    canDeleteProject,
    canSeeComments,
    canFollowProject,
    canRestoreProject,
    canTrashProject,
  } = usePermission();
  const { match: params } = useRouter();
  const isInsideCollection = !!params.collectionId;

  return (
    <>
      {canFollowProject && onClickFollow && (
        <>
          <ActionsDropdown.Custom
            keepMenuOpen
            action={following ? 'unfollow project' : 'follow project'}
            label={
              <FormattedMessage
                {...(following
                  ? messages.labelUnfollowProject
                  : messages.labelFollowProject)}
              />
            }
            icon={
              following ? <NotificationsOffSvg /> : <NotificationsActiveSvg />
            }
            onClick={() => onClickFollow(!following)}
            disabled={false}
            entity={null}
            goToMenu={null}
          />
          <Dropdown.Separator />
        </>
      )}
      {(canShareProject || canManagePublicLinks) && (
        <ActionsDropdown.Item
          action="share"
          goToMenu={1}
          icon={<ShareSvg />}
          label={<FormattedMessage {...messages.labelShare} />}
          keepMenuOpen={false}
          disabled={undefined}
          entity={undefined}
          onClick={undefined}
        />
      )}
      {canSeeComments && onClickComments && (
        <ActionsDropdown.Item
          action="comment"
          icon={<CommentsSvg />}
          label={<FormattedMessage {...messages.labelComments} />}
          onClick={onClickComments}
          keepMenuOpen={false}
          disabled={undefined}
          entity={undefined}
          goToMenu={undefined}
        />
      )}
      {canUploadImages && (
        <ActionsDropdown.Item
          action="add images"
          icon={<AddImageSvg />}
          label={<FormattedMessage {...messages.labelAddImages} />}
          onClick={openFileDialog}
          keepMenuOpen={false}
          disabled={undefined}
          entity={undefined}
          goToMenu={undefined}
        />
      )}
      {canDownloadImages && (
        <ActionsDropdown.Download
          disabled={!canDownloadImages}
          onClick={onClickDownloadProject}
        />
      )}
      <Route
        path={libraryProjectUrl({ viewMode: viewModeTypes.PRESENT })}
        render={() => <ActionsDropdown.Info onClick={onClickInfoButton} />}
      />
      {canManageCollections && isInsideCollection && (
        <>
          <Dropdown.Separator />
          {onClickRenameCollection && (
            <ActionsDropdown.Rename
              entity="collection"
              label={<FormattedMessage {...messages.labelRenameCollection} />}
              onClick={onClickRenameCollection}
            />
          )}
          {onClickDeleteCollection && (
            <ActionsDropdown.Delete
              entity="collection"
              label={<FormattedMessage {...messages.labelDeleteCollection} />}
              onClick={onClickDeleteCollection}
              confirm={undefined}
              disabled={undefined}
              confirmTitle={undefined}
              confirmLabel={undefined}
              confirmMessage={undefined}
            />
          )}
        </>
      )}
      {(canDuplicateProject || canDeleteProject || canRestoreProject) && (
        <Dropdown.Separator />
      )}
      {canDuplicateProject && (
        <ActionsDropdown.Duplicate
          entity="project"
          label={<FormattedMessage {...messages.labelDuplicateProject} />}
          onClick={onClickDuplicateProject}
        />
      )}
      {canTrashProject && (
        <ActionsDropdown.MoveToTrash
          entity="project"
          onClick={onClickMoveToTrash}
        />
      )}
      {canDeleteProject && (
        <ActionsDropdown.Delete
          confirmMessage={<FormattedMessage {...messages.messageModalDelete} />}
          entity="project"
          label={<FormattedMessage {...messages.labelDeleteProject} />}
          onClick={onClickDeleteProject}
          confirm={undefined}
          disabled={undefined}
          confirmTitle={undefined}
          confirmLabel={undefined}
        />
      )}
      {canRestoreProject && (
        <ActionsDropdown.Restore
          entity="project"
          onClick={onClickRestoreProject}
        />
      )}
    </>
  );
}
