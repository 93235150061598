import styled, { css } from 'styled-components/macro';
import { transition } from '@picter/prisma';

import { backgroundColor, variants, get } from 'src/modules/prisma';
import themeGet from '@styled-system/theme-get';

const borderVariants = variants('borderPosition', {
  left: css`
    box-shadow: ${p => `inset 3px 0 0px ${get(p.theme.colors, p.borderColor)}`};
  `,
  right: css`
    box-shadow: ${p =>
      `inset -3px 0 0px ${get(p.theme.colors, p.borderColor)}`};
  `,
});

const CommentAndRepliesContainer = styled.section`
  &:hover {
    background-color: ${themeGet('colors.grey.100')};
  }

  && {
    ${backgroundColor};
    ${borderVariants};
  }

  .mute-on-resolve {
    transition: ${transition({ property: 'opacity' })};
    ${props => props.resolved && `opacity: 0.5`};
  }
`;

export default CommentAndRepliesContainer;
