import { Document } from 'react-pdf';
import styled from 'styled-components/macro';

const EnhancedDocument = styled(Document)`
  height: 100%;
  .react-pdf__message--loading {
    height: 100%;
  }
`;

export default EnhancedDocument;
