import React, { memo, useCallback } from 'react';

import { Box } from 'src/modules/prisma';

import SelectionCheckbox from '../components/SelectionCheckbox';
import SelectionItem from '../styles/SelectionItem';
import SelectionItemOverlay from '../styles/SelectionItemOverlay';
import { SELECTION_STATES } from '../constants';

const withSelectableLayout = extractParams => WrappedComponent => {
  // This is a performance improvement once the useContext hook is triggering renders everytime anything changes
  // on the state. Memoizing the wrapped component makes it only update when it receives new props from the context.
  // It's the solution provided here: https://github.com/facebook/react/issues/14110#issuecomment-458771992
  const WrappedMemoComponent = memo(WrappedComponent);

  return componentProps => {
    const { shouldHideLayout } = extractParams
      ? extractParams(componentProps)
      : {};
    const {
      selectionId,
      index,
      shiftSelect,
      toggleSelect,
      selected,
      shiftSelected,
      selectionModeActive,
      hovering,
      hoverEvents,
      shiftPressed,
    } = componentProps;

    if (index === undefined || index === null) {
      throw new Error('An index must be provided to useSelectableLayout!');
    }

    if (selectionId === undefined || selectionId === null) {
      throw new Error(
        'An selectionId must be provided to useSelectableLayout!',
      );
    }

    const handleChangeCheckbox = useCallback(
      event => {
        const { target: checkboxElement } = event;
        checkboxElement.blur(); // move focus to the window enabling the shift select
        toggleSelect();
      },
      [toggleSelect],
    );

    return (
      <SelectionItem {...hoverEvents}>
        <WrappedMemoComponent {...componentProps} />
        {!shouldHideLayout && (shiftPressed || selectionModeActive) && (
          <SelectionItemOverlay
            aria-checked={selected}
            bulk={shiftPressed}
            onClick={toggleSelect}
            onMouseEnter={shiftSelect}
            role="checkbox"
            selected={!!selected}
            selecting={!!shiftSelected}
          />
        )}
        {!shouldHideLayout && (
          <Box display="flex" position="absolute" left={2} top={2}>
            <SelectionCheckbox
              bulk={selectionModeActive}
              checked={!!selected}
              hover={hovering}
              name={selectionId}
              onChange={handleChangeCheckbox}
              selecting={
                selected
                  ? shiftSelected === SELECTION_STATES.SHIFT_DESELECT_PREVIEW
                  : shiftSelected === SELECTION_STATES.SELECTED
              }
              visible={selectionModeActive || hovering}
            />
          </Box>
        )}
      </SelectionItem>
    );
  };
};

export default withSelectableLayout;
