import styled from 'styled-components/macro';
import { transitions, transparentize } from 'polished';
import themeGet from '@styled-system/theme-get';
import { transition } from '@picter/prisma';

import Element from './Element';
import { layout, padding, position, textStyle, typography } from './mixins';

const animationSettings = property => ({
  duration: '200ms',
  property,
});

const Input = styled(Element)`
  ${layout};
  ${position};
  ${textStyle};
  ${typography};
  appearance: none;
  background-color: ${themeGet('colors.input.background.default')};
  border: 1px solid ${themeGet('colors.input.background.default')};
  border-radius: 2px;
  color: ${themeGet('colors.input.text.default')};
  margin: 0;
  padding: ${themeGet('space.1')}px ${themeGet('space.4')}px;
  ${padding};
  ${transitions(
    transition(animationSettings('color')),
    transition(animationSettings('background-color')),
    transition(animationSettings('border-color')),
  )};

  &::placeholder {
    color: ${themeGet('colors.input.text.placeholder')};
  }

  &:hover {
    border-color: ${themeGet('colors.grey.300')};
  }

  &:focus {
    background-color: ${themeGet('colors.input.background.focus')};
    border-color: ${themeGet('colors.primary')};
    box-shadow: 0 0 6px 0
      ${props => transparentize(0.8, themeGet('colors.primary')(props))};
  }

  &:disabled {
    background-color: ${themeGet('colors.input.background.disabled')};
    border-color: ${themeGet('colors.grey.400')};
    color: ${themeGet('colors.input.text.disabled')};
    cursor: not-allowed;
  }
`;

Input.defaultProps = {
  blacklist: [
    ...Element.defaultProps.blacklist,
    ...layout.propNames,
    ...padding.propNames,
    ...position.propNames,
    ...textStyle.propNames,
    ...typography.propNames,
  ],
  fontFamily: 'sansSerif',
  tag: 'input',
  textStyle: 'body.regular',
  width: undefined,
};

Input.displayName = 'Input';

export default Input;
