import React from 'react';
import PropTypes from 'prop-types';
import { MediaQuery } from '@picter/prisma';

import FileWrapperStyle from '../styles/FileWrapper';

const FileWrapper = ({ isPdf, children }) => {
  if (!isPdf) {
    return children;
  }
  return (
    <MediaQuery query={{ minWidth: 0 }}>
      {matches =>
        matches ? <FileWrapperStyle>{children}</FileWrapperStyle> : children
      }
    </MediaQuery>
  );
};

FileWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isPdf: PropTypes.bool,
};

FileWrapper.defaultProps = {
  isPdf: false,
};

export default FileWrapper;
