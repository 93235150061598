import { API_LOCAL_DESTROY } from '../../constants';

const localBulkDestroy = (model, data, { worker } = {}) => {
  if (!data) {
    throw new Error(
      'Bulk destroy without data is not allowed. Try "new Array(size).fill()".',
    );
  }

  const ids = data.map(({ id }) => id);

  return dispatch =>
    dispatch({
      type: API_LOCAL_DESTROY,
      meta: { model, worker, ids },
    });
};

export default localBulkDestroy;
