import React from 'react';
import { Switch, Route } from 'react-router-dom';

import paths from 'src/routes/paths';
import NotFoundPage from 'src/components/NotFoundPage';

import Document from './_document';
import SpacePage from '.';
import FoldersRoutes from './folders/routes';
import TasksPage from './tasks';
import TrashPage from './trash';

const {
  LIBRARY_SPACE_PATH,
  LIBRARY_SPACE_FOLDERS_PATH,
  LIBRARY_SPACE_TRASH_PATH,
  LIBRARY_SPACE_TASKS_PATH,
  LIBRARY_SPACE_TASK_PATH,
} = paths;

export default function SpaceRoutes() {
  return (
    <Document>
      <Switch>
        <Route exact path={LIBRARY_SPACE_PATH} component={SpacePage} />
        <Route path={LIBRARY_SPACE_FOLDERS_PATH} component={FoldersRoutes} />
        <Route path={LIBRARY_SPACE_TRASH_PATH} component={TrashPage} />
        <Route
          path={[LIBRARY_SPACE_TASK_PATH, LIBRARY_SPACE_TASKS_PATH]}
          component={TasksPage}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Document>
  );
}
