import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components/macro';
import { useLocation, useParams } from 'react-router-dom';

import { useLayoutControls, useLayoutInfo } from 'src/layouts/GeneralLayout';
import ImageGrid from 'src/containers/ImageGrid';
import { projectCollectionSelectorById } from 'src/selectors/collection';
import { useProject } from 'src/hooks/use-resource';
import withUploadStorageSubscribe from 'src/hocs/with-upload-storage';

import useGridMeasurements from '../_hooks/use-grid-measurements';
import useGridImages from '../../../_shared/hooks/use-grid-images';

function Grid({ onMobile }) {
  const { projectId, collectionId } = useParams();
  const { query: { filter } = {} } = useLocation();

  const theme = useTheme();
  const layoutControls = useLayoutControls();
  // change appearence of page top bar
  const refreshLayoutMode = useCallback(
    ({ scrollOffset }) => {
      requestAnimationFrame(() => {
        // on top
        if (scrollOffset < 30) {
          layoutControls.enableComfortableMode();
        } else {
          layoutControls.enableCompactMode();
        }
      });
    },
    [layoutControls],
  );

  const gridMeasurements = useGridMeasurements(theme);

  const project = useProject({
    id: projectId,
    include: [
      'users.user',
      'invitations',
      'comments.author',
      'publicLinks',
      'space.users',
      'folder',
      'space.plan.features',
      'space.extraFeatures',
      'follows',

      'images.project',
      'images.approvals.user',
      'images.rejectedByUser',
      'images.comments.author',
      'images.collectionImages.image.comments',
      'images.collectionImages.image.approvals.user',
      'collections.images.image.comments',
      'collections.images.image.approvals.user',
    ],
  });

  const collection = projectCollectionSelectorById(
    { project },
    { id: collectionId },
  );

  const images = useGridImages({
    project,
    collection,
    filter,
  });

  const layoutInfo = useLayoutInfo();

  const {
    modes: { COMFORTABLE },
    sizes: { TOP_SECTION },
  } = !onMobile ? layoutInfo : { modes: {}, sizes: {} };

  return (
    <ImageGrid
      project={project}
      images={images}
      collection={collection}
      filter={filter}
      onScroll={refreshLayoutMode}
      gridMeasurements={gridMeasurements}
      headerHeight={!onMobile && TOP_SECTION[COMFORTABLE]}
      onMobile={onMobile}
    />
  );
}

Grid.propTypes = {
  onMobile: PropTypes.bool,
};

Grid.defaultProps = {
  onMobile: false,
};

export default withUploadStorageSubscribe(Grid);
