import React, { useContext, useCallback, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Box } from '@rebass/grid';
import { Dropdown, Spinner } from '@picter/prisma';
import { Icon } from 'src/modules/prisma';
import { NotificationsNone as NotificationsNoneSvg } from '@styled-icons/material';
import { useCurrentUser } from 'src/hooks/use-resource';
import { get } from 'src/utils/accessors';
import { NotificationsContext } from 'src/components/NotificationsProvider';
import NotificationsWrapper from './styles/NotificationsWrapper';
import NotificationIconWrapper from './styles/NotificationIconWrapper';
import EmptyNotifications from './styles/EmptyNotifications';
import DropdownMenuWrapper from './styles/DropdownMenuWrapper';
import NotificationsHeader from './components/NotificationsHeader';
import ActivityStreamRenderer from './components/ActivityStreamRenderer';

import messages from './messages';

const Notifications = ({
  items,
  unread,
  hasMore,
  loadOlderNotifications,
  markAllAsRead,
  markAllAsSeen,
}) => {
  const handleFetchMore = useCallback(
    async olderComment => {
      loadOlderNotifications(
        {
          idLT: olderComment.groupId || olderComment.id,
        },
        { limit: 5 },
      );
    },
    [loadOlderNotifications],
  );

  useEffect(() => {
    markAllAsSeen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NotificationsHeader markAllAsRead={markAllAsRead} unread={unread} />
      {items ? (
        <NotificationsWrapper length={items.length}>
          {items && items.length > 0 ? (
            <ActivityStreamRenderer
              data={items}
              hasMore={hasMore}
              fetchMore={handleFetchMore}
            />
          ) : (
            <EmptyNotifications>
              <FormattedMessage {...messages.emptyNotificationsLabel} />
            </EmptyNotifications>
          )}
        </NotificationsWrapper>
      ) : (
        <Spinner />
      )}
    </>
  );
};

Notifications.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  unread: PropTypes.number,
  hasMore: PropTypes.bool,
  loadOlderNotifications: PropTypes.func.isRequired,
  markAllAsRead: PropTypes.func.isRequired,
  markAllAsSeen: PropTypes.func.isRequired,
};

Notifications.defaultProps = {
  items: undefined,
  unread: 0,
  hasMore: false,
};

// memo needed here instead of in the export because for some reason we are using
// the default props outside of this file and adding the memo to the export makes
// it lose the values
const NotificationsDropdown = memo(({ mr, toggleElement }) => {
  const {
    state,
    loadOlderNotifications,
    markAllAsRead,
    markAllAsSeen,
  } = useContext(NotificationsContext);

  const user = useCurrentUser(undefined, { request: false });

  // The notifications-dropdown should only be visible for
  // full users (opposed to lite-users or anonymous users).
  if (!get(user, 'attributes.email')) {
    return null;
  }

  const { items, hasMore, unread } = state || {};

  return (
    <Dropdown
      icon={
        <Box mr={mr}>
          <NotificationIconWrapper>
            {toggleElement}
            {unread > 0 ? <div id="unread-counter">{unread}</div> : null}
          </NotificationIconWrapper>
        </Box>
      }
    >
      <DropdownMenuWrapper>
        <Dropdown.Menu
          horizontalOffset="50%"
          verticalOffset="50%"
          width="395px"
        >
          <Notifications
            {...{
              items,
              unread,
              hasMore,
              loadOlderNotifications,
              markAllAsRead,
              markAllAsSeen,
            }}
          />
        </Dropdown.Menu>
      </DropdownMenuWrapper>
    </Dropdown>
  );
});

NotificationsDropdown.propTypes = {
  mr: PropTypes.number,
  toggleElement: PropTypes.element,
};

NotificationsDropdown.defaultProps = {
  mr: null,
  toggleElement: (
    <Icon
      type={<NotificationsNoneSvg />}
      backgroundColor="white"
      border="1px solid"
      borderColor="grey.200"
      boxSize="xxlarge"
      color="grey.600"
      size="medium"
      interactive
    />
  ),
};

export default NotificationsDropdown;
