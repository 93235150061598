import React, { memo } from 'react';

import useHoverEvents from 'src/hooks/use-hover-events';
import useSelectable from '../hooks/use-selectable';

const withSelectableProperties = WrappedComponent => {
  // This is a performance improvement once the useContext hook is triggering renders everytime anything changes
  // on the state. Memoizing the wrapped component makes it only update when it receives new props from the context.
  // It's the solution provided here: https://github.com/facebook/react/issues/14110#issuecomment-458771992
  const WrappedMemoComponent = memo(WrappedComponent);

  return componentProps => {
    const { selectionId, index, margin, width } = componentProps;

    if (index === undefined || index === null) {
      throw new Error('An index must be provided to useSelectableProperties!');
    }

    if (selectionId === undefined || selectionId === null) {
      throw new Error(
        'An selectionId must be provided to useSelectableProperties!',
      );
    }

    const {
      deselect,
      select,
      toggleSelect,
      selected,
      shiftSelected,
      shiftSelect,
      selectionModeActive,
      selection,
      shiftPressed,
    } = useSelectable(selectionId, index);

    const [hovering, hoverEvents] = useHoverEvents();

    return (
      <div style={{ margin, width }}>
        <WrappedMemoComponent
          {...{
            ...componentProps,
            deselect,
            select,
            toggleSelect,
            selected,
            shiftSelected,
            selectionModeActive,
            // Needs to be passed down to be used on react-dnd
            selection,
            hoverEvents,
            hovering,
            shiftSelect,
            shiftPressed,
          }}
        />
      </div>
    );
  };
};

export default withSelectableProperties;
