import { useEffect, useMemo } from 'react';
import isFunction from 'lodash/isFunction';

const useDocumentTitle = (title, concat = false) => {
  const currentTitle = document.title;
  const newTitle = useMemo(() => {
    const t = isFunction(title) ? title() : title;
    return concat
      ? t.concat(` ∙ ${currentTitle} ∙ Picter`)
      : t.concat(' ∙ Picter');
  }, [concat, currentTitle, title]);

  useEffect(() => {
    document.title = newTitle;

    return () => {
      document.title = currentTitle;
    };
  }, [newTitle, currentTitle]);
};

export default useDocumentTitle;
