import React from 'react';

import Bar from '../styles/ProgressBar';
import Container from '../styles/ProgressBarContainer';

const ProgressBar = () => (
  <Container>
    <Bar data-testid="image-card-progress" />
  </Container>
);

export default ProgressBar;
