import React from 'react';
import PropTypes from 'prop-types';
import { Toaster } from '@picter/prisma';

import AcceptTermsModal from 'src/containers/AcceptTermsModal';
import { UploadStorageProvider } from 'src/utils/upload-storage';
import UploadIndicator from 'src/components/UploadIndicator';
import UploadErrors from 'src/components/UploadErrors';
import NotificationsProvider from 'src/components/NotificationsProvider';

const Document = ({ children }) => (
  <NotificationsProvider>
    <UploadStorageProvider>
      <>
        {children}
        <Toaster />
        <AcceptTermsModal />
        <UploadErrors />
        <UploadIndicator />
      </>
    </UploadStorageProvider>
  </NotificationsProvider>
);

Document.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Document;
