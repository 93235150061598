import {
  Fullscreen as FullscreenSvg,
  FullscreenExit as FullscreenExitSvg,
} from '@styled-icons/material';
import React from 'react';
import { Action, Icon, Tooltip } from 'src/modules/prisma';
import { FormattedMessage } from 'react-intl';
import useTinyKeys from 'src/hooks/use-tiny-keys';

export type FullscreenControlProps = {
  fullscreen: boolean;
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent,
  ) => void;
};

export default function FullscreenControl({
  fullscreen,
  onClick,
}: FullscreenControlProps) {
  useTinyKeys(
    window,
    {
      KeyF: (event: KeyboardEvent) => {
        onClick?.(event);
      },
    },
    [],
  );

  return (
    // @ts-ignore
    <Tooltip showDelay={500}>
      {/* @ts-ignore */}
      <Tooltip.Reference>
        {/* @ts-ignore */}
        <Action px={1} onClick={onClick}>
          {fullscreen ? (
            <Icon type={<FullscreenExitSvg />} />
          ) : (
            <Icon type={<FullscreenSvg />} />
          )}
        </Action>
        {/* @ts-ignore */}
      </Tooltip.Reference>
      {/* @ts-ignore */}
      <Tooltip.Message>
        <FormattedMessage
          id="FullscreenControl.labelFullscreen"
          defaultMessage="Fullscreen (F)"
        />
        {/* @ts-ignore */}
      </Tooltip.Message>
    </Tooltip>
  );
}
