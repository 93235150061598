import { useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { pickImageProofFilter } from 'src/utils/accessors/project-image';
import { createComment } from 'src/actions/comments';
import { useToriiActions } from 'src/modules/torii';
import { imageUrl, projectUrl } from 'src/routes/urls';
import useNavigate from 'src/hooks/use-navigate';

function useHandlers({ projectId, projectPublicUrlKey, user }) {
  const { create, localUpdateRelationship } = useToriiActions();
  const navigate = useNavigate();

  const history = useHistory();
  const { collectionId, imageId, viewMode } = useParams();
  const { pathname, query } = useLocation();

  const handleCloseSidebar = useCallback(() => {
    const nextUrl = imageId
      ? imageUrl(
          {
            collectionId,
            imageId,
            projectId: projectPublicUrlKey,
          },
          query,
        )
      : projectUrl(
          {
            collectionId,
            projectId: projectPublicUrlKey,
          },
          query,
        );
    history.push(nextUrl);
  }, [collectionId, query, history, imageId, projectPublicUrlKey]);

  const handleCreateComment = useCallback(
    async ({ geometry, text, commentId }) => {
      await createComment(
        {
          geometry,
          commentId,
          imageId,
          projectId,
          projectPublicUrlKey,
          text,
          user,
          viewMode,
        },
        { create, localUpdateRelationship },
      );
      navigate(pathname, {
        // maintain image filtering while removing comment filtering
        query: pickImageProofFilter(query),
        replace: true,
      });
    },
    [
      create,
      navigate,
      imageId,
      localUpdateRelationship,
      pathname,
      projectId,
      projectPublicUrlKey,
      user,
      query,
      viewMode,
    ],
  );

  const handleInputFocus = useCallback(() => {
    const route = pathname.split('/comments')[0];
    return navigate(`${route}/comments`);
  }, [navigate, pathname]);

  return {
    handleCloseSidebar,
    handleCreateComment,
    handleInputFocus,
  };
}

export default useHandlers;
