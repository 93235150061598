import React from 'react';
import { Route } from 'react-router-dom';

import paths from 'src/routes/paths';
import withSuspenseLoader from 'src/hocs/with-suspense-loader';

import ShareableReviewLinkModal from './ShareableReviewLinkModal';
import ShareablePresentLinkModal from './ShareablePresentLinkModal';
import ShareableRequestFilesLinkModal from './ShareableRequestFilesLinkModal';

const {
  LIBRARY_PROJECT_SHARE_REVIEW_PATH,
  LIBRARY_PROJECT_COLLECTION_SHARE_REVIEW_PATH,
  LIBRARY_PROJECT_SHARE_PRESENTATION_PATH,
  LIBRARY_PROJECT_COLLECTION_SHARE_PRESENTATION_PATH,
  LIBRARY_PROJECT_SHARE_REQUEST_FILES_PATH,
  LIBRARY_PROJECT_COLLECTION_SHARE_REQUEST_FILES_PATH,
} = paths;

function ProjectShareModals() {
  return (
    <>
      <Route
        component={ShareableReviewLinkModal}
        path={[
          LIBRARY_PROJECT_SHARE_REVIEW_PATH,
          LIBRARY_PROJECT_COLLECTION_SHARE_REVIEW_PATH,
        ]}
      />
      <Route
        component={ShareablePresentLinkModal}
        path={[
          LIBRARY_PROJECT_SHARE_PRESENTATION_PATH,
          LIBRARY_PROJECT_COLLECTION_SHARE_PRESENTATION_PATH,
        ]}
      />
      <Route
        component={ShareableRequestFilesLinkModal}
        path={[
          LIBRARY_PROJECT_SHARE_REQUEST_FILES_PATH,
          LIBRARY_PROJECT_COLLECTION_SHARE_REQUEST_FILES_PATH,
        ]}
      />
    </>
  );
}

export default withSuspenseLoader({
  LoadingComponent: null,
  ErrorComponent: null,
})(ProjectShareModals);
