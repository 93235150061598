import { fromJS } from 'immutable';

import { API_STATE_UPDATE } from '../constants';
import ReducersWorker from './reducers.worker';

export const initializeWorker = (workerInstance, schemasObj) =>
  new Promise((resolve, reject) => {
    const onMessage = event => {
      workerInstance.removeEventListener('message', onMessage);

      if (event.data.type === 'INITIALIZED') {
        resolve();
      } else {
        reject();
      }
    };
    const schemas = JSON.stringify(schemasObj);
    workerInstance.addEventListener('message', onMessage);
    workerInstance.postMessage({ type: 'INITIALIZE', schemas });
  });

export const createActionRunner = workerInstance => (
  immutableState,
  actionObj,
) =>
  new Promise((resolve, reject) => {
    const onMessage = event => {
      workerInstance.removeEventListener('message', onMessage);

      if (event.data.type === 'ACTION_FINISHED') {
        const result = JSON.parse(event.data.result);
        const immutableResult = fromJS(result);
        resolve(immutableResult);
      } else {
        reject();
      }
    };
    const action = JSON.stringify(actionObj);
    const jsState = immutableState.toJS();
    const state = JSON.stringify(jsState);
    workerInstance.addEventListener('message', onMessage);
    workerInstance.postMessage({ type: 'RUN_ACTION', action, state });
  });

export const middlewareFn = (runAction, apiDataPath = []) => {
  let workerActionPending = false;
  const queuedActions = [];
  return store => next => async action => {
    if (workerActionPending) {
      queuedActions.push(action);
      return null;
    }
    // Checks if it is not an async action
    const isSyncAction =
      action.type.includes('_SUCCESS') || action.type.includes('LOCAL_');
    const shouldUseWorker = isSyncAction && action.meta && action.meta.worker;
    let returnValue = null;
    let newAction = action;
    if (shouldUseWorker) {
      workerActionPending = true;
      const state = store.getState();
      const result = await runAction(state.getIn(apiDataPath), action);
      newAction = {
        type: API_STATE_UPDATE,
        payload: result,
      };
      // Call the next dispatch method in the middleware chain.
      returnValue = next(newAction);
      while (queuedActions.length) {
        next(queuedActions.shift());
      }
      workerActionPending = false;
    } else {
      // Call the next dispatch method in the middleware chain.
      returnValue = next(newAction);
    }

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue;
  };
};

export const workerMiddleware = (schemas, apiDataPath = []) => {
  const workerInstance = new ReducersWorker();
  initializeWorker(workerInstance, schemas);

  return middlewareFn(createActionRunner(workerInstance), apiDataPath);
};
