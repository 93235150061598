import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Flex } from '@rebass/grid';

import { Text } from 'src/modules/prisma';
import SpaceAvatar from 'src/components/SpaceAvatar';
import { NavigationHeader } from 'src/components/AppLayout';
import ProjectNavigation, {
  PROJECT_NAVIGATION_ELEMENTS,
} from 'src/containers/ProjectNavigation';
import { get } from 'src/utils/accessors';
import { useProject } from 'src/hooks/use-resource';

type Props = {
  hasAccessToAllFiles: boolean;
  showInfoItem: boolean;
  onMobile?: boolean;
};

export default function Sideabar({
  hasAccessToAllFiles,
  showInfoItem,
  onMobile = false,
}: Props) {
  const { projectId } = useParams<{ projectId: string }>();
  const project = useProject(
    {
      id: projectId,
      include: ['space'],
    },
    {
      request: false,
    },
  );
  const space = get(project, 'relationships.space');
  const elements = useMemo(() => {
    const elementsArray = [PROJECT_NAVIGATION_ELEMENTS.COLLECTIONS];
    if (hasAccessToAllFiles) {
      elementsArray.push(PROJECT_NAVIGATION_ELEMENTS.SHOW_ALL);
    }
    if (showInfoItem) {
      elementsArray.push(PROJECT_NAVIGATION_ELEMENTS.INFO);
    }
    return elementsArray;
  }, [hasAccessToAllFiles, showInfoItem]);
  if (onMobile) {
    return (
      <>
        <NavigationHeader>
          <Flex alignItems="center">
            <SpaceAvatar
              name={get(space, 'attributes.name')}
              spaceId={get(space, 'id')}
              src={get(space, 'attributes.logo.small')}
            />
            <Text color="secondary" textStyle="body.small" ml={2}>
              {get(space, 'attributes.name')}
            </Text>
          </Flex>
        </NavigationHeader>
        <Box mr={7}>
          <ProjectNavigation projectId={projectId} elements={elements} />
        </Box>
      </>
    );
  }
  return <ProjectNavigation projectId={projectId} elements={elements} />;
}
