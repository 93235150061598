import { useCallback, useRef } from 'react';
import { toastError, toastInfo } from '@picter/prisma';

import { projectInfoUrl } from 'src/routes/urls';
import { useToriiActions } from 'src/modules/torii';
import { useErrorHandler } from 'src/components/ErrorHandler';
import { get } from 'src/utils/accessors';

import messages from '../messages';

const useHandlers = ({
  publicUrlKey,
  history,
  match,
  project,
  intl: { formatMessage },
}) => {
  const {
    load,
    create,
    update,
    destroy,
    localAttachRelationship,
    localUpdate,
  } = useToriiActions();
  const { handleError } = useErrorHandler();
  const editableTitleRef = useRef(null);
  const { spaceId, folderId } = match.params;

  const handleClickInfo = useCallback(() => {
    const projectInfoLink = projectInfoUrl({ projectId: publicUrlKey });

    history.push(projectInfoLink);
  }, [publicUrlKey, history]);

  const handleClickRename = useCallback(() => {
    const { current: editableTitleElem } = editableTitleRef;

    if (editableTitleElem) {
      editableTitleElem.focus();
    }
  }, [editableTitleRef]);

  const handleRenameProject = useCallback(
    async event => {
      const { value: newTitle } = event.target;
      const oldTitle = get(project, 'attributes.title');

      if (newTitle !== oldTitle) {
        await update('wsProjects', {
          id: get(project, 'id'),
          attributes: {
            title: newTitle,
          },
          querySerializers: {
            publicKey: () => `publicKey=${publicUrlKey}`,
          },
        });

        toastInfo(formatMessage(messages.messageProjectRenamedSuccess));
      }
    },
    [update, project, publicUrlKey, formatMessage],
  );

  const handleRemoveProject = useCallback(async () => {
    await destroy('wsProjects', {
      id: get(project, 'id'),
      querySerializers: {
        publicKey: () => `publicKey=${publicUrlKey}`,
      },
    });

    toastInfo(formatMessage(messages.messageProjectRemovedSuccess));
  }, [destroy, project, publicUrlKey, formatMessage]);

  const handleCopyProject = useCallback(async () => {
    try {
      const relationships = {};

      if (spaceId) {
        relationships.space = {
          id: spaceId,
          type: 'ws-spaces',
        };
      }

      if (folderId) {
        relationships.folder = {
          id: folderId,
          type: 'ws-folders',
        };
      }

      await create('wsProjects', {
        attributes: {
          copyFromProject: get(project, 'id'),
        },
        relationships,
      });

      toastInfo(formatMessage(messages.messageProjectDuplicateSuccess));
    } catch (e) {
      const handled = handleError(e);
      if (handled) return;

      toastError(e);
    }
  }, [create, handleError, project, formatMessage, spaceId, folderId]);

  const handleClickSpaces = useCallback(
    async space => {
      try {
        await update('wsProjects', {
          id: get(project, 'id'),
          relationships: {
            space: {
              id: get(space, 'id'),
              type: 'ws-spaces',
            },
          },
        });

        await localAttachRelationship('wsSpaces', {
          id: get(space, 'id'),
          relation: 'projects',
          data: [{ type: 'ws-projects', id: get(project, 'id') }],
        });

        toastInfo(
          formatMessage(messages.messageProjectMovedToSpace, {
            spaceName: get(space, 'attributes.name'),
          }),
        );
      } catch (e) {
        const handled = handleError(e);
        if (handled) return;

        toastError(e);
      }
    },
    [localAttachRelationship, project, update, formatMessage, handleError],
  );

  const handleMoveProject = useCallback(
    async value => {
      const folder = value !== 'workspace' ? value : undefined;

      await update('wsProjects', {
        id: get(project, 'id'),
        relationships: {
          folder: {
            id: !folder ? 'null' : get(folder, 'id'),
            type: 'ws-folders',
          },
        },
      });

      if (folder) {
        const folderProjectCount = get(folder, 'relationships.count');
        const folderProjectCountId = get(folderProjectCount, 'id');

        if (!folderProjectCountId) {
          load('wsFolders', { id: get(folder, 'id'), include: ['count'] });
        } else {
          localUpdate('wsFolderProjectCounts', {
            id: folderProjectCountId,
            attributes: {
              count: get(folderProjectCount, 'attributes.count') + 1,
            },
          });
        }
      }
    },
    [project, update, load, localUpdate],
  );

  const handleRestoreProject = useCallback(() => {
    update('wsProjects', {
      id: get(project, 'id'),
      attributes: {
        deletedAt: null,
      },
    });

    toastInfo(formatMessage(messages.messageProjectRestoredSuccess));
  }, [project, update, formatMessage]);

  const handleTrashProject = useCallback(() => {
    update('wsProjects', {
      id: get(project, 'id'),
      attributes: {
        deletedAt: new Date().valueOf(),
      },
    });

    toastInfo(formatMessage(messages.messageProjectMoveToTrashSuccess));
  }, [project, update, formatMessage]);

  return {
    editableTitleRef,
    handleClickInfo,
    handleClickRename,
    handleRenameProject,
    handleRemoveProject,
    handleCopyProject,
    handleClickSpaces,
    handleMoveProject,
    handleRestoreProject,
    handleTrashProject,
  };
};

export default useHandlers;
