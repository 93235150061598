import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components/macro';
import { Route, Switch } from 'react-router-dom';
import pick from 'lodash/pick';
import { trackerShape } from '@picter/tracker';

import AppLayout from 'src/components/AppLayout';
import GeneralLayout, { useLayout } from 'src/layouts/GeneralLayout';
import SidebarLayout from 'src/layouts/SidebarLayout';
import TranslateBox from 'src/styles/TranslateBox';
import ProjectPageSidebarActions from 'src/components/ProjectPageSidebarActions';
import paths from 'src/routes/paths';

import useGridMeasurements from '../_hooks/use-grid-measurements';
import { Sidebar, Header, Info, Grid } from '../_sections';

const { LIBRARY_PROJECT_INFO_PATH } = paths;

function LayoutContentWithSidebar() {
  return (
    <GeneralLayout.Content>
      <SidebarLayout>
        <SidebarLayout.MainSidebar>
          <SidebarLayout.MainSidebar.Content>
            <LayoutCoupledBox spacing={['paddingBottom', 'paddingTop']}>
              <Sidebar />
            </LayoutCoupledBox>
          </SidebarLayout.MainSidebar.Content>
          <SidebarLayout.MainSidebar.Bottom />
        </SidebarLayout.MainSidebar>
        <SidebarLayout.Content>
          <Switch>
            <Route
              path={LIBRARY_PROJECT_INFO_PATH}
              render={() => (
                <LayoutCoupledBox>
                  <Info />
                </LayoutCoupledBox>
              )}
            />
            <Route render={() => <Grid />} />
          </Switch>
        </SidebarLayout.Content>
        <ProjectPageSidebarActions />
      </SidebarLayout>
    </GeneralLayout.Content>
  );
}

function LayoutContentWithoutSidebar() {
  return (
    <SidebarLayout.Content>
      <Switch>
        <Route
          path={LIBRARY_PROJECT_INFO_PATH}
          render={() => (
            <LayoutCoupledBox>
              <Info />
            </LayoutCoupledBox>
          )}
        />
        <Route render={() => <Grid />} />
      </Switch>
    </SidebarLayout.Content>
  );
}

function LayoutCoupledBox({ spacing, ...props }) {
  const [
    {
      mode,
      sizes: { TOP_SECTION },
    },
    controls,
  ] = useLayout();
  const theme = useTheme();
  const gridMeasurements = useGridMeasurements(theme);
  // change appearence of page top bar
  const refreshLayoutMode = useCallback(
    ({ target: { scrollTop: scrollOffset } }) => {
      requestAnimationFrame(() => {
        // on top
        if (scrollOffset < 30) {
          controls.enableComfortableMode();
        } else {
          controls.enableCompactMode();
        }
      });
    },
    [controls],
  );

  return (
    <TranslateBox
      {...pick(gridMeasurements, spacing)}
      style={{ overflow: 'auto' }}
      height={`calc(100% - ${TOP_SECTION[mode]}px)`}
      onScroll={refreshLayoutMode}
      translateY={TOP_SECTION[mode]}
      width={1}
      {...props}
    />
  );
}
LayoutCoupledBox.defaultProps = {
  spacing: ['paddingBottom', 'paddingLeft', 'paddingRight', 'paddingTop'],
};
LayoutCoupledBox.propTypes = {
  spacing: PropTypes.arrayOf(PropTypes.string),
};

const LayoutDesktop = props => {
  const { withSidebar, tracker } = props;

  const LayoutContent = withSidebar
    ? LayoutContentWithSidebar
    : LayoutContentWithoutSidebar;

  return (
    <AppLayout.Content>
      <GeneralLayout>
        <Header tracker={tracker} />
        <LayoutContent />
      </GeneralLayout>
    </AppLayout.Content>
  );
};

LayoutDesktop.propTypes = {
  withSidebar: PropTypes.bool.isRequired,
  tracker: trackerShape.isRequired,
};

export default LayoutDesktop;
