import { ReactNode, MouseEvent, HTMLProps } from 'react';
import styled, { css } from 'styled-components/macro';
import { transition } from '@picter/prisma';

import { layout, LayoutProps } from 'src/modules/prisma';
import { containerShapeVariants } from '../variants';

const interactiveStyles = css`
  cursor: pointer;

  &:hover {
    filter: brightness(1.2);
  }

  &:active {
    filter: brightness(0.8);
  }
`;

/* TODO:
 * we need to find a better way to handle this forward
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RefType = any;

export type Props = LayoutProps &
  Omit<HTMLProps<HTMLElement>, 'ref'> &
  Partial<{
    id: string;
    children: ReactNode;
    className: string;
    onClick: (event: MouseEvent<HTMLElement>) => void;
    size: number;
    minSize: number;
    shape: string;
  }> & {
    ref: RefType;
  };

const AvatarContainer = styled.i<Props>`
  ${layout};
  display: block;
  font-size: ${props => props.size}px;
  position: relative;
  user-select: none;
  transition: ${transition({ property: 'filter' })};
  ${props => props.onClick && interactiveStyles};

  > * {
    background-clip: padding-box;
    ${containerShapeVariants}
    position: absolute;
    top: 0;
    left: 0;
  }

  img {
    pointer-events: none;
  }
`;

AvatarContainer.defaultProps = {
  onClick: undefined,
  size: undefined,
};
AvatarContainer.displayName = 'AvatarContainer';

export default AvatarContainer;
