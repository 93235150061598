import styled from 'styled-components/macro';

const FormContent = styled.div`
  &::before {
    clear: both;
    content: '';
    display: table;
  }
`;

export default FormContent;
