import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useSessionStorage } from '@react-hooks-library/core';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';

export type Bundle = {
  bundleId: string; // the id of the bundle related to the job (bundle-pt1, bundle-pt2, ...)
  bundleStatus:
    | 'complete' // Download URL will be available.
    | 'in-progress' // No Download URL will be available.
    | 'failed'; // Retry URL will be available.
  fileCount: number;
  url: string | null; // the download url
  retryUrl?: string; // the retry url
  bundleName: string; // the name of the zip file
};

export type Job = {
  // key is unique between all jobs
  // based on the public key and the bundle prefix + the timestamp
  key: string;
  // when the job is pending (request ongoing) the jobUrl doesn't exist yet
  jobUrl?: string;
  bundles: Array<Bundle>;
  status: 'created' | 'pending';
  bundlePrefix: string;
};

type DownloadManagerContextType = {
  jobs: Job[];
  bundles: readonly Bundle[];
  createDownloadJob: (params: {
    downloadUrl: string;
    projectId: string;
    imagesIds: Array<string>;
    bundlePrefix: string;
    fileNamePattern?: string;
  }) => void;
  hideBundle: (bundleId: string) => void;
  retry: (retryUrl: string) => void;
  reset: () => void;
};

export const DownloadManagerContext = createContext<DownloadManagerContextType>(
  {
    jobs: [],
    bundles: [],
    createDownloadJob: () => undefined,
    hideBundle: () => undefined,
    retry: () => undefined,
    reset: () => undefined,
  },
);

function fetcher(...urls: string[]) {
  return Promise.all(urls.map(url => fetch(url).then(res => res.json())));
}

/**
 * It contains a list of jobs and a function to create a job and start the download.
 * This provider provides context for the download manager popover and is also
 * used by donwload buttons to trigger actions (like automacially opening the popover)
 *
 * Also this provider contains a polling mechanism that polls each jobURL, while it is
 * - not completed or hasn't failed yet or
 * - has never been fetched yet
 */
export function DownloadManagerContextProvider({
  children,
}: PropsWithChildren<{}>) {
  const params = useParams();

  const [localJobs, setLocalJobs] = useSessionStorage<
    DownloadManagerContextType['jobs']
  >('download-manager-jobs', []);

  const [jobs, setJobs] = useState<DownloadManagerContextType['jobs']>([]);

  const [retryJobUrls, setRetryJobURls] = useState<string[]>([]);
  const [hasFetchedInitialData, setHasFetchedInitialData] = useState(false);

  const [hiddenBundleIds, setHiddenBundleIds] = useSessionStorage<{
    [bundleId: string]: boolean;
  }>('download-manager-hidden-bundle-ids', {});

  const createDownloadJob = useCallback<
    DownloadManagerContextType['createDownloadJob']
  >(
    async ({
      downloadUrl,
      projectId: wsProjectId,
      imagesIds: images,
      bundlePrefix,
      fileNamePattern = '{title}',
    }) => {
      if (images.length === 0) return;

      const newJob: Job = {
        key: `${wsProjectId}-${bundlePrefix}-${Date.now()}`,
        jobUrl: '',
        bundles: [],
        status: 'pending',
        bundlePrefix,
      };

      // put new download job at the top of the list
      setJobs(prevJobs => [newJob, ...prevJobs]);

      const response = await fetch(downloadUrl, {
        method: 'POST',
        body: JSON.stringify({
          images,
          bundlePrefix,
          fileNamePattern,
          wsProjectId,
        }),
        credentials: 'include',
      }).catch(err => {
        throw new Error(err);
      });

      const { status: statusCode, statusText } = response;

      if (statusCode === 201) {
        const { jobUrl } = await response.json();
        const updatedNewJob: Job = { ...newJob, jobUrl, status: 'created' };

        setJobs(prevJobs =>
          prevJobs.map(job => (job.key === newJob.key ? updatedNewJob : job)),
        );
        // save the job to the local storage so we can restore it on page reload
        // PS: we don't need to update it afterwards because we don't want to save the bundles
        setLocalJobs([updatedNewJob, ...localJobs]);
      } else {
        setJobs(prevJobs => prevJobs.filter(job => job.key !== newJob.key));
        const message = 'Error when fetching bulk download link.';
        // eslint-disable-next-line no-console
        console.log(message.concat(` ${statusCode} ${statusText}.`));
        Sentry.captureMessage(message, {
          extra: {
            statusCode,
            statusText,
          },
        });
      }
    },
    [localJobs, params, setJobs, setLocalJobs],
  );

  const hideBundle = useCallback<DownloadManagerContextType['hideBundle']>(
    bundleId => {
      if (hiddenBundleIds[bundleId] === undefined) {
        setHiddenBundleIds({ ...hiddenBundleIds, [bundleId]: true });
      }
    },
    [hiddenBundleIds, setHiddenBundleIds],
  );

  const retry = useCallback<DownloadManagerContextType['retry']>(
    async retryUrl => {
      // post to the retry url to get the new job url
      const result = await fetch(retryUrl, { method: 'POST' });
      const { jobUrl }: { jobUrl: string } = await result.json();

      // add it to the retry list if it's not there
      if (!retryJobUrls.includes(jobUrl)) {
        setRetryJobURls([...retryJobUrls, jobUrl]);
      }
    },
    [retryJobUrls, setRetryJobURls],
  );

  const reset = useCallback<DownloadManagerContextType['reset']>(() => {
    setLocalJobs([]);
    setJobs([]);
    setHiddenBundleIds({});
    setHasFetchedInitialData(false);
  }, [setLocalJobs, setHiddenBundleIds]);

  // get all the urls which are in progress or are not fetched yet (as we don't know the status yet)
  const inProgressJobUrls = jobs
    .filter(job => job.status === 'created')
    .filter(
      job =>
        // check if it have any bundle in progress
        // using !! just to cast any null/undefined result to false and work purely with boolean values
        !!job.bundles.some(
          ({ bundleStatus }) => bundleStatus === 'in-progress',
        ) ||
        // or if the job is not fetched yet
        job.bundles.length === 0 ||
        // or if the job has failed and has retry url
        retryJobUrls.includes(job.jobUrl!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
    )
    .map(job => job.jobUrl);

  useSWR<Array<Job>>(inProgressJobUrls, fetcher, {
    refreshInterval: 2500,
    dedupingInterval: 10,
    onSuccess: data => {
      if (data.length > 0) {
        // update jobs with fetched data
        setJobs(prevJobs =>
          prevJobs.map(job => {
            const updatedJobData = data.find(
              ({ jobUrl }) => job.jobUrl === jobUrl,
            );

            if (!updatedJobData) {
              return job;
            }

            return {
              ...job,
              ...updatedJobData,
              bundles: updatedJobData.bundles.map(bundle => ({
                ...bundle,
                bundleName: bundle.bundleName ?? job.bundlePrefix,
                bundleId: `${job.jobUrl}_${bundle.bundleId}`,
              })),
            };
          }),
        );

        if (!hasFetchedInitialData) {
          setHasFetchedInitialData(true);
        }
      }
    },
  });

  const bundles = jobs
    .map(job =>
      job.bundles.length > 0
        ? job.bundles.filter(bundle => !hiddenBundleIds[bundle.bundleId])
        : // fake bundle while we create the job (we don't have job url to fetch bundles at this point)
          ({
            url: null,
            bundleId: job.key,
            bundleName: job.bundlePrefix,
            bundleStatus: 'in-progress',
          } as Bundle),
    )
    .flat();

  // on first page load create jobs from the job urls in session storage
  useEffect(() => {
    if (
      // if we haven't fetched the initial data yet
      !hasFetchedInitialData &&
      // and we have job urls that are not in the jobs list
      localJobs.some(
        localJob =>
          jobs.find(job => job.jobUrl === localJob.jobUrl) === undefined,
      )
    ) {
      setJobs(localJobs);
    }
  }, [hasFetchedInitialData, jobs, localJobs]);

  useEffect(() => {
    // reset when there's no bundles left
    // (eg. all bundles are complete and hidden from the popover)
    if (hasFetchedInitialData && bundles.length === 0) {
      reset();
    }
  }, [hasFetchedInitialData, bundles.length, reset]);

  return (
    <DownloadManagerContext.Provider
      value={{
        createDownloadJob,
        jobs,
        bundles,
        hideBundle,
        retry,
        reset,
      }}
    >
      {children}
    </DownloadManagerContext.Provider>
  );
}
