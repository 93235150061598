import React from 'react';

const HorizontalTilesSVG = () => (
  <svg
    width="260"
    height="187"
    viewBox="0 0 260 187"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <rect
        opacity="0.3"
        x="81.5905"
        y="130.647"
        width="74.552"
        height="114.313"
        rx="6"
        transform="rotate(-90 81.5905 130.647)"
        fill="#B0B7B9"
      />
      <rect
        opacity="0.3"
        x="59.2246"
        y="186.975"
        width="47.6304"
        height="69.1677"
        rx="6"
        transform="rotate(-90 59.2246 186.975)"
        fill="#B0B7B9"
      />
      <rect
        opacity="0.3"
        x="4.96741"
        y="130.646"
        width="53.0147"
        height="69.1676"
        rx="6"
        transform="rotate(-90 4.96741 130.646)"
        fill="#B0B7B9"
      />
      <rect
        opacity="0.3"
        x="94.0416"
        y="47.3457"
        width="42.4298"
        height="69.1676"
        rx="6"
        transform="rotate(-90 94.0416 47.3457)"
        fill="#B0B7B9"
      />
      <rect
        opacity="0.3"
        x="201.42"
        y="95.5947"
        width="20.0617"
        height="29.6913"
        rx="6"
        transform="rotate(-90 201.42 95.5947)"
        fill="#B0B7B9"
      />
      <rect
        opacity="0.3"
        x="195.802"
        y="150.965"
        width="20.0617"
        height="29.6914"
        rx="6"
        transform="rotate(-90 195.802 150.965)"
        fill="#B0B7B9"
      />
      <rect
        opacity="0.3"
        x="207.037"
        y="118.064"
        width="7.22221"
        height="52.963"
        rx="3.61111"
        transform="rotate(-90 207.037 118.064)"
        fill="#B0B7B9"
      />
      <rect
        opacity="0.3"
        x="17.6543"
        y="69.1133"
        width="7.22221"
        height="52.9629"
        rx="3.61111"
        transform="rotate(-90 17.6543 69.1133)"
        fill="#B0B7B9"
      />
      <rect
        opacity="0.3"
        x="138.827"
        y="183.866"
        width="7.22223"
        height="52.963"
        rx="3.61111"
        transform="rotate(-90 138.827 183.866)"
        fill="#B0B7B9"
      />
      <rect
        opacity="0.3"
        y="150.163"
        width="7.22223"
        height="52.963"
        rx="3.61111"
        transform="rotate(-90 0 150.163)"
        fill="#B0B7B9"
      />
      <rect
        opacity="0.3"
        x="174.289"
        y="47.3457"
        width="28.9924"
        height="43.0745"
        rx="6"
        transform="rotate(-90 174.289 47.3457)"
        fill="#B0B7B9"
      />
      <rect
        opacity="0.3"
        x="135.848"
        y="168.337"
        width="28.9924"
        height="43.0745"
        rx="6"
        transform="rotate(-90 135.848 168.337)"
        fill="#B0B7B9"
      />
      <rect
        opacity="0.3"
        x="59.3827"
        y="47.2134"
        width="18.2238"
        height="26.9215"
        rx="6"
        transform="rotate(-90 59.3827 47.2134)"
        fill="#B0B7B9"
      />
      <rect
        opacity="0.3"
        x="174.289"
        y="9.62939"
        width="9.62963"
        height="14.4444"
        rx="4.81481"
        transform="rotate(-90 174.289 9.62939)"
        fill="#B0B7B9"
      />
      <rect
        opacity="0.3"
        x="190.988"
        y="167.015"
        width="9.62964"
        height="14.4444"
        rx="4.81482"
        transform="rotate(-90 190.988 167.015)"
        fill="#B0B7B9"
      />
      <rect
        opacity="0.3"
        x="34.5061"
        y="167.817"
        width="9.62962"
        height="14.4444"
        rx="4.81481"
        transform="rotate(-90 34.5061 167.817)"
        fill="#B0B7B9"
      />
      <rect
        opacity="0.3"
        x="199.968"
        y="69.0122"
        width="9.62963"
        height="14.4444"
        rx="4.81482"
        transform="rotate(-90 199.968 69.0122)"
        fill="#B0B7B9"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="260" height="186.975" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default HorizontalTilesSVG;
