import { defineMessages } from 'react-intl';

export default defineMessages({
  messageWelcomeToPicter: {
    id: 'OnboardingChecklist.messageWelcomeToPicter',
    defaultMessage: '🔥 Welcome to Picter.',
  },
  messageKeepGoing: {
    id: 'OnboardingChecklist.messageKeepGoing',
    defaultMessage: '🎉 Great! Keep going.',
  },
  messageWellDone: {
    id: 'OnboardingChecklist.messageWellDone',
    defaultMessage: "💯 Well done! You're all set.",
  },
  messageStepsPrepared: {
    id: 'OnboardingChecklist.messageStepsPrepared',
    defaultMessage:
      'We prepared a short guide for you to to have a smooth start',
  },
  messageLetsHaveChat: {
    id: 'OnboardingChecklist.messageLetsHaveChat',
    defaultMessage:
      "<intercom>Let's have a chat</intercom>, if you have any questions or feedback.",
  },
  labelDismiss: {
    id: 'OnboardingChecklist.labelDismiss',
    defaultMessage: 'Dismiss',
  },
  labelNextStep: {
    id: 'OnboardingChecklist.labelNextStep',
    defaultMessage: 'Next step: {taskTitle}',
  },

  titleInviteTeamMembers: {
    id: 'OnboardingChecklist.titleInviteTeamMembers',
    defaultMessage: 'Invite team members',
  },
  messageInviteTeamMembers: {
    id: 'OnboardingChecklist.messageInviteTeamMembers',
    defaultMessage: 'Go to settings and invite your team to this workspace.',
  },
  titleAddVisualFiles: {
    id: 'OnboardingChecklist.titleAddVisualFiles',
    defaultMessage: 'Add visual files',
  },
  messageAddVisualFiles: {
    id: 'OnboardingChecklist.messageAddVisualFiles',
    defaultMessage:
      'Create a new project and upload your images, videos or pdf files.',
  },
  titleReviewAnImage: {
    id: 'OnboardingChecklist.titleReviewAnImage',
    defaultMessage: 'Review files',
  },
  messageReviewAnImage: {
    id: 'OnboardingChecklist.messageReviewAnImage',
    defaultMessage:
      'Approve any of your visual files (or even better: let your clients or colleagues do it).',
  },
});
