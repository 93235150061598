import React from 'react';
import isFunction from 'lodash/isFunction';
import { useSwipeable } from 'react-swipeable';

import { SWIPE_THRESHOLD } from '../constants';
import ContentContainer from '../styles/ContentContainer';
import { useSidebarControls } from '../utils/sidebar-control';

type Props = {
  id?: string;
  className?: string;
  children: React.ReactNode;
  as?: 'main' | 'section';
};

const Content: React.FC<Props> = ({ id, className, children, ...props }) => {
  const sidebarControl = useSidebarControls();
  const swipeHandlers = useSwipeable({
    delta: SWIPE_THRESHOLD,
    onSwipedRight: sidebarControl.openMainSidebar,
    preventDefaultTouchmoveEvent: true,
  });

  return (
    <ContentContainer
      id={id}
      className={className}
      {...swipeHandlers}
      {...props}
    >
      {isFunction(children) ? children(sidebarControl) : children}
    </ContentContainer>
  );
};

export default Content;
