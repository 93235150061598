import React from 'react';
import { Switch, Route } from 'react-router-dom';

import NotFoundPage from 'src/components/NotFoundPage';
import paths from 'src/routes/paths';

import Document from './_document';

import ManageAccountPage from './manage-account';
import ProfilePage from './profile';

const {
  LIBRARY_SETTINGS_PERSONAL_PROFILE_PATH,
  LIBRARY_SETTINGS_PERSONAL_MANAGE_ACCOUNT_PATH,
} = paths;

function SettingsPersonalRoutes() {
  return (
    <Document>
      <Switch>
        <Route
          path={LIBRARY_SETTINGS_PERSONAL_PROFILE_PATH}
          component={ProfilePage}
        />
        <Route
          path={LIBRARY_SETTINGS_PERSONAL_MANAGE_ACCOUNT_PATH}
          component={ManageAccountPage}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Document>
  );
}

export default SettingsPersonalRoutes;
