import create from './create';
import bulkCreate from './bulkCreate';
import { load, loadNextPage } from './load';
import localCreate from './localCreate';
import localBulkCreate from './localBulkCreate';
import localBulkUpdate from './localBulkUpdate';
import localBulkDestroy from './localBulkDestroy';
import localUpdate from './localUpdate';
import localDestroy from './localDestroy';
import update from './update';
import bulkUpdate from './bulkUpdate';
import destroy from './destroy';
import bulkDestroy from './bulkDestroy';
import attachRelationship from './attachRelationship';
import removeRelationship from './removeRelationship';
import updateRelationship from './updateRelationship';
import localAttachRelationship from './localAttachRelationship';
import localRemoveRelationship from './localRemoveRelationship';
import localUpdateRelationship from './localUpdateRelationship';
import resetStore from './resetStore';

export default {
  create,
  bulkCreate,
  load,
  loadNextPage,
  localCreate,
  localBulkCreate,
  localUpdate,
  localBulkUpdate,
  localDestroy,
  localBulkDestroy,
  update,
  bulkUpdate,
  destroy,
  bulkDestroy,
  attachRelationship,
  removeRelationship,
  updateRelationship,
  localAttachRelationship,
  localRemoveRelationship,
  localUpdateRelationship,
  resetStore,
};
