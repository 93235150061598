import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router-dom';

import NotFoundPage from 'src/components/NotFoundPage';
import NoPermissionPage from 'src/components/NoPermissionPage';
import ErrorPage from 'src/components/ErrorPage';
import { useCurrentUser } from 'src/hooks/use-resource';
import { loginUrl } from 'src/routes/urls';

const DefaultErrorHandler = ({ error }) => {
  const { pathname, search } = useLocation();
  const user = useCurrentUser();
  const userNotLogged = !user || user.isEmpty();

  switch (error.statusCode) {
    case 403: {
      if (userNotLogged) {
        const redirectUrl = `${pathname}${search}`;

        return <Redirect to={loginUrl(undefined, { redirectUrl })} />;
      }
      return <NoPermissionPage />;
    }
    case 404:
      return <NotFoundPage />;
    default:
      return <ErrorPage />;
  }
};

DefaultErrorHandler.propTypes = {
  error: PropTypes.shape({
    statusCode: PropTypes.number,
  }),
};

DefaultErrorHandler.defaultProps = {
  error: {},
};

export default memo(DefaultErrorHandler);
