import React from 'react';
import { Box } from '@rebass/grid';

/**
 * Necessary so image card works correctly inside an image grid which passes
 * margin and width to its children after calculating all the spaces.
 */
function withFlexibleDiv(WrappedComponent) {
  return props => {
    // eslint-disable-next-line react/prop-types
    const { margin, width } = props;
    return (
      <Box margin={margin} width={width}>
        <WrappedComponent {...props} />
      </Box>
    );
  };
}

export default withFlexibleDiv;
