import Cookies from 'js-cookie';
import { formatForLocalAction } from 'src/modules/torii';

import { get } from 'src/utils/accessors';

export const createCollection = (
  { projectId, projectPublicKey, name, createInboxLinkKey },
  { create },
) =>
  create('wsCollections', {
    attributes: {
      name,
      createInboxLinkKey,
    },
    relationships: {
      project: {
        id: projectId,
        type: 'ws-projects',
      },
    },
    querySerializers: {
      publicLinkKey: () => `publicLinkKey=${projectPublicKey}`,
    },
  }).then(res => res.data);

export const createCollectionForInboxLink = async (
  { projectId, projectPublicKey, collectionName },
  { localCreate },
) => {
  const API_URL = process.env.REACT_APP_API_URL;

  const response = await fetch(
    `${API_URL}/app-workspace/ws-collections?publicLinkKey=${projectPublicKey}`,
    {
      method: 'POST',
      body: JSON.stringify({
        data: {
          type: 'ws-collections',
          attributes: {
            name: collectionName,
            'create-inbox-link-key': true,
          },
          relationships: {
            project: {
              data: {
                id: projectId,
                type: 'ws-projects',
              },
            },
          },
        },
      }),
      credentials: 'include',
    },
  );
  const [createdCollection] = formatForLocalAction(await response.json());
  const inboxLinkKey = get(createdCollection, 'attributes.inboxLinkKey');
  const responseCollectionId = createdCollection.id;
  Cookies.set(
    `inbox-key/${projectPublicKey}`,
    `${responseCollectionId}:${inboxLinkKey}`,
  );
  localCreate('wsCollections', createdCollection);
  return createdCollection;
};

export const addImageToCollection = async (
  { imageId, collectionId, projectPublicKey },
  { create },
) => {
  return create('wsCollectionImages', {
    attributes: {
      // the backend will define the correct order
      order: 0,
    },
    relationships: {
      collection: {
        id: collectionId,
        type: 'ws-collections',
      },
      image: {
        id: imageId,
        type: 'ws-project-images',
      },
    },
    querySerializers: {
      publicKey: () => `publicKey=${projectPublicKey}`,
    },
  });
};

export const addImagesToCollection = async (
  { imagesIds, collectionId, projectPublicKey, inboxLinkKey },
  { bulkCreate },
) => {
  return bulkCreate(
    'wsCollectionImages',
    imagesIds.map(imageId => ({
      attributes: {
        // the backend will define the correct order
        order: 0,
      },
      relationships: {
        collection: {
          id: collectionId,
          type: 'ws-collections',
        },
        image: {
          id: imageId,
          type: 'ws-project-images',
        },
      },
    })),
    {
      querySerializers: {
        publicLinkKey: () => `publicLinkKey=${projectPublicKey}`,
        inboxLinkKey: () =>
          inboxLinkKey !== undefined
            ? `inboxLinkKey=${inboxLinkKey}`
            : undefined,
      },
      worker: imagesIds.length > 30,
    },
  );
};

export const removeImageFromCollection = async (
  { collectionImageId, projectPublicKey },
  { destroy },
) =>
  destroy('wsCollectionImages', {
    id: collectionImageId,
    querySerializers: {
      publicKey: () => `publicKey=${projectPublicKey}`,
    },
  });

export const removeImagesFromCollection = async (
  { collectionImagesIds, projectPublicKey },
  { bulkDestroy },
) =>
  bulkDestroy(
    'wsCollectionImages',
    collectionImagesIds.map(collectionImageId => ({
      id: collectionImageId,
    })),
    {
      querySerializers: {
        publicKey: () => `publicKey=${projectPublicKey}`,
      },
    },
  );

export const removeImagesFromCollections = async (
  { collectionsImagesIds, projectPublicKey },
  { bulkDestroy },
) => {
  for (let i = 0; i < collectionsImagesIds.length; i += 1) {
    const collectionImagesIds = collectionsImagesIds[i];
    // eslint-disable-next-line no-await-in-loop
    await removeImagesFromCollection(
      { collectionImagesIds, projectPublicKey },
      { bulkDestroy },
    );
  }
};

export const createCollectionAndAddItem = async (
  { projectId, imageId, projectPublicKey, name, initialOrder },
  { create },
) => {
  const collection = await createCollection(
    { projectId, projectPublicKey, name },
    { create },
  );

  return addImageToCollection(
    {
      imageId,
      collectionId: collection.id,
      projectPublicKey,
      initialOrder,
    },
    { create },
  );
};

export const createCollectionAndAddItems = async (
  { projectId, imagesIds, projectPublicKey, name, initialOrder },
  { create, bulkCreate },
) => {
  const collection = await createCollection(
    { projectId, projectPublicKey, name },
    { create },
  );

  return addImagesToCollection(
    {
      imagesIds,
      collectionId: collection.id,
      projectPublicKey,
      initialOrder,
    },
    { bulkCreate },
  );
};

export function deleteCollection(
  { collectionId, projectPublicUrlKey },
  { destroy },
) {
  return destroy('wsCollections', {
    id: collectionId,
    querySerializers: {
      publicKey: () => `publicKey=${projectPublicUrlKey}`,
    },
  });
}
