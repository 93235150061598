import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import Avatar from 'src/components/Avatar';
import { get } from 'src/utils/accessors';

const ApprovalsAvatars = memo(({ approvals }) =>
  approvals.map(approval => {
    const user = get(approval, 'relationships.user');
    return (
      <Avatar
        key={get(user, 'id')}
        email={
          get(user, 'attributes.email') ||
          get(user, 'attributes.liteAccountEmail')
        }
        name={get(user, 'attributes.publicName')}
        deleted={Boolean(user?.isEmpty())}
      />
    );
  }),
);

ApprovalsAvatars.propTypes = {
  approvals: PropTypes.instanceOf(List).isRequired,
};

export default ApprovalsAvatars;
