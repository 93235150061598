import { get, has } from 'src/utils/accessors';

// eslint-disable-next-line import/prefer-default-export
export function selectAuthenticationStatus(state) {
  if (!state || !has(state, 'authentication')) {
    throw new Error('Make sure you have the authentication reducer set up.');
  }

  return get(state, 'authentication.authenticated');
}

export function selectAuthenticatedUser(state) {
  if (!state || !has(state, 'user')) {
    throw new Error('Make sure you have the authentication reducer set up.');
  }

  return get(state, 'user');
}
