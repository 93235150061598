import React, { useEffect } from 'react';
import { compose, defaultProps } from 'recompose';
import { withRouter, Route } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { withTracker } from '@picter/tracker';
import Cookies from 'js-cookie';
import { Map } from 'immutable';

import withUploadStorageSubscribe from 'src/hocs/with-upload-storage';
import { buildProjectTitle } from 'src/utils/title-builder';
import { useProject } from 'src/hooks/use-resource';
import withSuspenseLoader from 'src/hocs/with-suspense-loader';
import { useRealtimeJSONApi } from 'src/modules/realtime';
import { replacePublicLinkKey } from 'src/utils/inject-public-link-key';
import paths from 'src/routes/paths';
import { ProjectFlagsProvider } from 'src/containers/FeatureFlags';
import { SelectableComponentsProvider } from 'src/modules/SelectableComponents';
import EditorQuotaModal from 'src/components/ProjectPageEditorQuotaModal';
import { projectCollectionSelectorById } from 'src/selectors/collection';
import AppLayout from 'src/components/AppLayout';
import ProjectCommentsContextSidebar from 'src/containers/ProjectCommentsContextSidebar';
import { get } from 'src/utils/accessors';
import useDocumentTitle from 'src/hooks/use-document-title';
import useFormatMessage from 'src/hooks/use-format-message';

import { useEditorQuotaErrorModal } from '../../_shared/hooks/handlers';
import Layout from './_layout';

const {
  LIBRARY_PROJECT_COLLECTION_COMMENT_PATH,
  LIBRARY_PROJECT_COLLECTION_COMMENTS_PATH,
  LIBRARY_PROJECT_COLLECTION_SHARE_PATH,
  LIBRARY_PROJECT_COMMENT_PATH,
  LIBRARY_PROJECT_COMMENTS_PATH,
  LIBRARY_PROJECT_SHARE_PATH,
} = paths;

const Page = props => {
  const formatMessage = useFormatMessage();
  // eslint-disable-next-line react/prop-types
  const { match, tracker } = props;
  // eslint-disable-next-line react/prop-types
  const { projectId: publicLinkKey } = match.params;
  // eslint-disable-next-line react/prop-types
  const { subscribe: subscribeToRealtime } = useRealtimeJSONApi();

  const project = useProject({
    id: publicLinkKey,
    include: [
      'space',
      'collections',
      'publicLinks',
      'images.collectionImages',
      'images.project',
      'images.approvals.user',
      'images.rejectedByUser',
      'images.comments.author',
      'collections.images.image.comments',
      'collections.images.image.approvals.user',
      'space.plan.features',
      'space.extraFeatures',
    ],
  });

  const collectionCookie = Cookies.get(`inbox-key/${publicLinkKey}`);
  const [collectionId] = collectionCookie ? collectionCookie.split(':') : [];

  const collection =
    projectCollectionSelectorById({ project }, { id: collectionId }) || Map();

  const projectId = get(project, 'id');
  const projectName = buildProjectTitle({ project, formatMessage });

  const {
    editorQuotaErrorModalVisibility,
    closeEditorQuotaErrorModal,
  } = useEditorQuotaErrorModal();

  useEffect(() => {
    const subscription = subscribeToRealtime(
      replacePublicLinkKey(
        `private-resource=ws-projects;id=${projectId};publicKey=${publicLinkKey}`,
        match,
      ),
    );

    return subscription;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeToRealtime, publicLinkKey, projectId]);

  useDocumentTitle(projectName);

  return (
    <ProjectFlagsProvider project={project}>
      <SelectableComponentsProvider>
        <AppLayout>
          <Layout tracker={tracker} project={project} collection={collection} />
          <Route
            path={[
              LIBRARY_PROJECT_SHARE_PATH,
              LIBRARY_PROJECT_COLLECTION_SHARE_PATH,
            ]}
            exact
          >
            {/* TODO: What is this modal doing? It seems like it's always closed? */}
            {() => (
              <EditorQuotaModal
                open={editorQuotaErrorModalVisibility}
                onClose={closeEditorQuotaErrorModal}
              />
            )}
          </Route>
          <Route
            render={routeProps => (
              <ProjectCommentsContextSidebar
                {...routeProps}
                projectId={projectId}
                projectPublicUrlKey={publicLinkKey}
              />
            )}
            path={[
              LIBRARY_PROJECT_COLLECTION_COMMENT_PATH,
              LIBRARY_PROJECT_COLLECTION_COMMENTS_PATH,
              LIBRARY_PROJECT_COMMENT_PATH,
              LIBRARY_PROJECT_COMMENTS_PATH,
            ]}
          />
        </AppLayout>
      </SelectableComponentsProvider>
    </ProjectFlagsProvider>
  );
};

export default compose(
  defaultProps({
    project: Map(),
  }),
  withTracker(),
  injectIntl,
  withUploadStorageSubscribe,
  withRouter,
  withSuspenseLoader(),
)(Page);
