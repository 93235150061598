import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const SuspenseLoaderContext = React.createContext();

const SuspenseLoaderProvider = ({ children, errorFallback }) => {
  const [error, setError] = useState(null);
  const handleError = useCallback(e => setError(e), []);

  useEffect(() => {
    if (error) {
      // This set the error to null when you have changes on children in order to
      // let the page use some auto-recover logics (i.e. image page that goes for the...
      // first image when it can't find the id you're looking for);
      setError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  if (error && errorFallback) {
    return React.cloneElement(errorFallback, { error });
  }

  if (error && !errorFallback) {
    // This prevents the children to be rendered if an error exists
    // solves infinity loop problem.
    return null;
  }

  return (
    <SuspenseLoaderContext.Provider value={handleError}>
      {!error && children}
    </SuspenseLoaderContext.Provider>
  );
};

SuspenseLoaderProvider.propTypes = {
  children: PropTypes.node.isRequired,
  errorFallback: PropTypes.node,
};

SuspenseLoaderProvider.defaultProps = {
  errorFallback: null,
};

export default SuspenseLoaderProvider;
