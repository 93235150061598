import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toastInfo } from '@picter/prisma';

import { libraryProjectUrl } from 'src/routes/urls';
import useFormatMessage from 'src/hooks/use-format-message';
import { useToriiActions } from 'src/modules/torii';
import { useErrorHandler } from 'src/components/ErrorHandler';
import useUpdatableState from 'src/hooks/use-updatable-state';
import { get } from 'src/utils/accessors';

import messages from '../_messages';

export default function useHandlers({ folder }) {
  const { spaceId, folderId } = useParams();
  const { push } = useHistory();
  const { create, update } = useToriiActions();
  const { handleError } = useErrorHandler();
  const formatMessage = useFormatMessage();

  const createProject = useCallback(async () => {
    try {
      const {
        data: {
          attributes: { 'public-url-key': publicUrlKey },
        },
      } = await create('wsProjects', {
        relationships: {
          space: {
            id: spaceId,
            type: 'ws-spaces',
          },
          folder: {
            id: folderId,
            type: 'ws-folders',
          },
        },
      });

      return push(libraryProjectUrl({ projectId: publicUrlKey }));
    } catch (error) {
      handleError(error);
      throw error;
    }
  }, [create, handleError, push, spaceId, folderId]);

  const renameFolder = useCallback(
    async e => {
      const { value: newName } = e.target;
      const oldName = get(folder, 'attributes.name');

      if (newName !== oldName) {
        await update('wsFolders', {
          id: folderId,
          attributes: {
            name: newName,
          },
        });

        toastInfo(formatMessage(messages.messageRenamedSuccess));
      }
    },
    [folderId, folder, update, formatMessage],
  );

  const [folderName, setFolderName] = useUpdatableState(
    get(folder, 'attributes.name'),
  );

  return {
    createProject,
    renameFolder,
    folderName,
    setFolderName,
  };
}
