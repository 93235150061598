import { defineMessages } from 'react-intl';

export default defineMessages({
  labelInviteMember: {
    id: 'InviteMemberModal.labelInviteMember',
    defaultMessage: 'Invite member',
  },
  labelEmail: {
    id: 'InviteMemberModal.labelEmail',
    defaultMessage: 'Enter email...',
  },
  labelSend: {
    id: 'InviteMemberModal.labelSend',
    defaultMessage: 'Send invite',
  },
  labelRoleInThisSpace: {
    id: 'InviteMemberModal.labelRoleInThisSpace',
    defaultMessage: 'Choose role',
  },
  messageEmailRequired: {
    id: 'InviteMemberModal.messageEmailRequired',
    defaultMessage: 'Email is required',
  },
  messageManagerRole: {
    id: 'InviteMemberModal.messageManagerRole',
    defaultMessage: 'Can manage members',
  },
  messageMemberRole: {
    id: 'InviteMemberModal.messageMemberRole',
    defaultMessage: 'Has full access on all projects',
  },
  messageViewerRole: {
    id: 'InviteMemberModal.messageViewerRole',
    defaultMessage: 'Can view and comment all projects',
  },
});
