import React from 'react';
import {
  ArrowBack as ArrowBackSvg,
  ArrowForward as ArrowForwardSvg,
  Close as CloseSvg,
} from '@styled-icons/material';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { TrackerEvent, TrackerScope } from '@picter/tracker';

import { Box, Button, Icon, Separator } from 'src/modules/prisma';

import messages from '../_messages';

export default function CancelSubscriptionNavigation({
  disabled = false,
  onCancel,
  onNext,
  onPrevious,
}: {
  disabled: boolean;
  onCancel?: (event: React.MouseEvent) => void;
  onNext?: (event: React.MouseEvent) => void;
  onPrevious?: (event: React.MouseEvent) => void;
}) {
  return (
    <TrackerScope name="Navigation">
      <Separator size={4} />
      <Box display="flex" justifyContent="space-between">
        <TrackerEvent name="Close" trackClick>
          <Link to="..">
            <Button
              disabled={disabled}
              alignItems="center"
              backgroundColor="transparent"
              color="grey.600"
              fontWeight="regular"
            >
              <Icon color="inherit" size="small" type={<CloseSvg />} />
              <Box display="inline" mr={1} />
              <FormattedMessage {...messages.labelClose} />
            </Button>
          </Link>
        </TrackerEvent>
        <Box display="flex">
          {onPrevious && (
            <TrackerEvent name="Previous" trackClick>
              <Button
                disabled={disabled}
                alignItems="center"
                backgroundColor="transparent"
                color="grey.600"
                fontWeight="regular"
                onClick={onPrevious}
              >
                <Icon color="inherit" size="small" type={<ArrowBackSvg />} />
                <Box display="inline" mr={1} />
                <FormattedMessage {...messages.labelPrevious} />
              </Button>
            </TrackerEvent>
          )}
          {onNext && (
            <TrackerEvent name="Next" trackClick>
              <Button
                disabled={disabled}
                alignItems="center"
                backgroundColor="primary"
                color="white"
                fontWeight="regular"
                onClick={onNext}
              >
                <FormattedMessage {...messages.labelNext} />
                <Box ml={1} />
                <Icon color="inherit" size="small" type={<ArrowForwardSvg />} />
              </Button>
            </TrackerEvent>
          )}
          {onCancel && (
            <TrackerEvent name="Cancel subscription" trackClick>
              <Button
                disabled={disabled}
                alignItems="center"
                backgroundColor="danger"
                color="white"
                fontWeight="regular"
                onClick={onCancel}
              >
                <FormattedMessage {...messages.labelCancelSubscription} />
              </Button>
            </TrackerEvent>
          )}
        </Box>
      </Box>
    </TrackerScope>
  );
}
