import React, { createElement, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ButtonElement as Button } from '@picter/prisma';
import { fromJS, List, Map } from 'immutable';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { Text } from 'src/modules/prisma';
import { useCurrentUser } from 'src/hooks/use-resource';
import MessageTooltip from 'src/components/MessageTooltip';
import { discountShape } from 'src/pages/index/settings/{spaceId}/subscription/_special-offer-config';
import { get } from 'src/utils/accessors';
import usePromise from 'src/hooks/use-promise';

import { Plan, PlanNames, SubscriptionStatus } from '../../SubscriptionService';
import Blocker from '../styles/Blocker';
import PlansGrid from '../styles/PlansGrid';
import messages from '../messages';
import PlanInfo from './PlanInfo';

const { REACT_APP_API_URL } = process.env;

function shouldWrapUi({ ui, wrapper, wrap = false }) {
  return wrap ? createElement(wrapper, null, ui) : ui;
}

function useAvailablePlans({ spaceId }) {
  const cancelTokenSource = useMemo(() => axios.CancelToken.source(), []);
  const resource = new URL(
    'service-payment/charge-bee/spaces/available-plans',
    REACT_APP_API_URL,
  );
  resource.searchParams.set('spaceId', spaceId);
  const [plans, setPlans] = useState(List());

  useEffect(() => {
    axios
      .get(resource, {
        cancelToken: cancelTokenSource.token,
        withCredentials: true,
      })
      .then(({ data: { data }, status }) => {
        if (status === 200) {
          setPlans(fromJS(data));
        }
      });

    return () => cancelTokenSource.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId]);

  return plans;
}

function hubspotFormUrl({ email, firstName, lastName }) {
  const url = new URL('https://share.hsforms.com/1Sg1vkElPTmS8Jq5SE-ndUQ1uq4f');

  if (email) url.searchParams.set('email', email);
  if (firstName) url.searchParams.set('firstname', firstName);
  if (lastName) url.searchParams.set('lastname', lastName);

  return url;
}

function useHubspotFormUrl() {
  const user = useCurrentUser();
  return useMemo(
    () =>
      hubspotFormUrl({
        email: get(user, 'attributes.email'),
        firstName: get(user, 'attributes.firstName'),
        lastName: get(user, 'attributes.lastName'),
      }),
    [user],
  );
}

function PlansInfo({
  // current user data
  currentPlanSlug,
  subscriptionStatus,
  discounts,

  onDowngrade,
  onSubscribe,
  onUpgrade,
}) {
  const { spaceId } = useParams();
  const plans = useAvailablePlans({ spaceId });
  const plansBySlug = useMemo(
    () =>
      Map(
        plans.reduce((map, p) => {
          // eslint-disable-next-line no-param-reassign
          map[get(p, 'attributes.slug')] = p;
          return map;
        }, {}),
      ),
    // Missing dependencies: [`plans`]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [plans && plans.size, spaceId],
  );
  const tier1PlanId = get(plansBySlug, `${Plan.Tier1}.id`);
  const tier2PlanId = get(plansBySlug, `${Plan.Tier2}.id`);
  const tier3PlanId = get(plansBySlug, `${Plan.Tier3}.id`);
  const hasSubscription = [
    SubscriptionStatus.Active,
    SubscriptionStatus.NonRenewing,
    SubscriptionStatus.Paused,
  ].includes(subscriptionStatus);

  const {
    trigger: triggerSubscribe,
    isLoading: isLoadingSubscribe,
  } = usePromise(onSubscribe);
  const {
    trigger: triggerDowngrade,
    isLoading: isLoadingDowngrade,
  } = usePromise(onDowngrade);
  const { trigger: triggerUpgrade, isLoading: isLoadingUpgrade } = usePromise(
    onUpgrade,
  );

  const isLoading =
    isLoadingSubscribe || isLoadingDowngrade || isLoadingUpgrade;

  const additionalStorageCosts = (
    <FormattedMessage
      id="PlansInfo.priceSubline"
      defaultMessage="Additional storage: €25 / TB / month"
      description="Subtitle below seat price"
    />
  );

  return (
    <PlansGrid>
      <PlanInfo
        index={1}
        key={Plan.Tier1}
        action={
          {
            [Plan.None]: shouldWrapUi({
              ui: (
                <Button
                  color="primary"
                  disabled={!tier1PlanId || isLoading}
                  onClick={() => triggerSubscribe(tier1PlanId)}
                  textStyle="action.regular"
                  variant="flat"
                  width="100%"
                >
                  <FormattedMessage
                    {...messages.labelSubscribeToOtherPlan}
                    values={{
                      plan: PlanNames[Plan.Tier1],
                    }}
                  />
                </Button>
              ),
              wrapper: props => (
                <MessageTooltip
                  message={
                    <FormattedMessage {...messages.messageAvailability} />
                  }
                >
                  <Blocker {...props} />
                </MessageTooltip>
              ),
              wrap: !tier1PlanId,
            }),
            [Plan.Tier1]: (
              <Text
                color="success"
                tag="p"
                textAlign="center"
                textStyle="action.regular"
                py={2}
                px={4}
              >
                <FormattedMessage {...messages.messageYourCurrentPlan} />
              </Text>
            ),
            [Plan.Tier2]: shouldWrapUi({
              ui: (
                <Button
                  color="primary"
                  disabled={!tier1PlanId || isLoading}
                  onClick={() => triggerDowngrade(tier1PlanId)}
                  textStyle="action.regular"
                  variant="flat"
                  width="100%"
                >
                  <FormattedMessage {...messages.labelDowngradePlan} />
                </Button>
              ),
              wrapper: props => (
                <MessageTooltip
                  message={
                    <FormattedMessage {...messages.messageAvailability} />
                  }
                >
                  <Blocker {...props} />
                </MessageTooltip>
              ),
              wrap: !tier1PlanId,
            }),
          }[hasSubscription ? currentPlanSlug : Plan.None]
        }
        extra={additionalStorageCosts}
        features={[
          <FormattedMessage
            id="PlansInfo.StrorageTier"
            defaultMessage="100 GB / member"
            description="Storage tier in feature list"
          />,
          <FormattedMessage
            id="PlansInfo.AllFileTypes"
            defaultMessage="Support for all file types"
            description="File type feature in feature list"
          />,
          <FormattedMessage
            id="PlansInfo.TeamClientReviews"
            defaultMessage="Team & client review"
            description="Review feature in feature list"
          />,
          <FormattedMessage
            id="PlansInfo.CommentingApproval"
            defaultMessage="Commenting & Approval"
            description="Commenting feature in feature list"
          />,
          <FormattedMessage
            id="PlansInfo.AssigneesTasks"
            defaultMessage="Assignees and tasks"
            description="Assignees feature in feature list"
          />,
        ]}
        label={PlanNames[Plan.Tier1]}
        price={10}
        discount={discounts[Plan.Tier1]}
      />
      <PlanInfo
        index={2}
        key={Plan.Tier2}
        action={
          {
            [Plan.None]: (
              <Button
                color="white"
                disabled={!tier2PlanId || isLoading}
                onClick={() => triggerSubscribe(tier2PlanId)}
                textStyle="action.regular"
                width="100%"
              >
                <FormattedMessage
                  {...messages.labelSubscribeToOtherPlan}
                  values={{
                    plan: PlanNames[Plan.Tier2],
                  }}
                />
              </Button>
            ),
            [Plan.Tier1]: (
              <Button
                color="primary"
                disabled={!tier2PlanId || isLoading}
                onClick={() => triggerUpgrade(tier2PlanId)}
                textStyle="action.regular"
                variant="flat"
                width="100%"
              >
                <FormattedMessage {...messages.labelUpgradePlan} />
              </Button>
            ),
            [Plan.Tier2]: (
              <Text
                color="success"
                tag="p"
                textAlign="center"
                textStyle="action.regular"
                py={2}
                px={4}
              >
                <FormattedMessage {...messages.messageYourCurrentPlan} />
              </Text>
            ),
          }[hasSubscription ? currentPlanSlug : Plan.None]
        }
        extra={additionalStorageCosts}
        features={[
          <FormattedMessage
            id="PlansInfo.StrorageTier"
            defaultMessage="Everything in Standard and:"
            description="'Tier 1 included' hint in Tier 2 feature list"
          />,
          <FormattedMessage
            id="PlansInfo.RolesFeature"
            defaultMessage="Workspace roles"
            description="Roles feature in feature list"
          />,
          <FormattedMessage
            id="PlansInfo.GuestsFeature"
            defaultMessage="Invite guests"
            description="Guests feature in feature list"
          />,
          <FormattedMessage
            id="PlansInfo.GuestsFeature"
            defaultMessage="Priority support"
            description="Priority support feature in feature list"
          />,
          <FormattedMessage
            id="PlansInfo.VersionFeature"
            defaultMessage="Coming soon: Version history"
            description="Version feature in feature list"
          />,
        ]}
        label={PlanNames[Plan.Tier2]}
        price={20}
        discount={discounts[Plan.Tier2]}
      />
      <PlanInfo
        index={3}
        key={Plan.Tier3}
        action={
          <a
            href={useHubspotFormUrl()}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Button
              color="primary"
              disabled={!tier3PlanId || isLoading}
              textStyle="action.regular"
              variant="flat"
              width="100%"
            >
              <FormattedMessage {...messages.labelLetsTalk} />
            </Button>
          </a>
        }
        features={[
          <FormattedMessage {...messages.messagePowerhouseOrganization} />,
        ]}
        label={PlanNames[Plan.Tier3]}
        price={<FormattedMessage {...messages.labelCustom} />}
      />
    </PlansGrid>
  );
}

PlansInfo.defaultProps = {
  currentPlanSlug: Plan.None,
  subscriptionStatus: undefined,
  onDowngrade: f => f,
  onSubscribe: f => f,
  onUpgrade: f => f,
  discounts: {},
};

PlansInfo.propTypes = {
  currentPlanSlug: PropTypes.oneOf(Object.values(Plan)),
  subscriptionStatus: PropTypes.oneOf(Object.values(SubscriptionStatus)),
  discounts: PropTypes.shape({
    [Plan.Tier1]: discountShape,
    [Plan.Tier2]: discountShape,
  }),
  onDowngrade: PropTypes.func,
  onSubscribe: PropTypes.func,
  onUpgrade: PropTypes.func,
};

export default PlansInfo;
