import styled from 'styled-components/macro';
import { Card } from '@picter/prisma';

const ImageCardCard = styled(Card)`
  ${props => props.mute && 'opacity: 0.4;'};
  ${props => props.hide && 'display: none;'};
  background-color: transparent;
  border-color: transparent;
  ${Card.Content} {
    padding-bottom: 32px;
  }
`;

export default ImageCardCard;
