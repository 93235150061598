import React from 'react';
import { FileCopy as FileCopySvg } from '@styled-icons/material';
import { FormattedMessage } from 'react-intl';

import ActionsDropdownItem from './ActionsDropdownItem';
import messages from '../messages';

function ActionsDropdownCopy(props) {
  return (
    <ActionsDropdownItem
      action="copy"
      icon={<FileCopySvg />}
      label={<FormattedMessage {...messages.messageCopyToProject} />}
      {...props}
    />
  );
}

export default ActionsDropdownCopy;
