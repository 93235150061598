import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';

import { textStyle, typography } from 'src/modules/prisma';

const FormErrorIndicator = styled.span.attrs({
  fontFamily: 'sansSerif',
  fontWeight: 'light',
  textStyle: 'body.small',
})`
  ${textStyle};
  ${typography};
  align-items: center;
  color: ${themeGet('colors.danger')};
  display: inline-flex;

  i {
    margin-right: ${themeGet('space.2')}px;
  }
`;

export default FormErrorIndicator;
