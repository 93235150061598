import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Toast } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';

import { TrackerEvent, TrackerScope } from '@picter/tracker';

import UndoLink from './styles/UndoLink';
import messages from './messages';

// This huge name used in the scope is an edge case in which the component is
// rendered outside the correct scope tree.
const UndoToast = ({ closeToast, imageTitle, onUndo }) => {
  const onLeaveDuringAction = useCallback(e => {
    const confirmationMessage =
      'We are processing your action, please wait until the action message dissappears';

    (e || window.event).returnValue = confirmationMessage; // Gecko + IE
    return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', onLeaveDuringAction);
    return () => {
      window.removeEventListener('beforeunload', onLeaveDuringAction);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TrackerScope
      name="Projects / Project / Image menu / Remove image"
      extend={false}
    >
      <Toast>
        <FormattedMessage
          {...messages.messageImageRemoved}
          values={{
            imageTitle,
          }}
        />{' '}
        <TrackerEvent name="Undo" trackClick>
          <UndoLink
            onClick={() => {
              onUndo();
              closeToast();
            }}
          >
            <FormattedMessage {...messages.labelUndo} />
          </UndoLink>
        </TrackerEvent>
      </Toast>
    </TrackerScope>
  );
};

UndoToast.propTypes = {
  closeToast: PropTypes.func,
  imageTitle: PropTypes.string,
  onUndo: PropTypes.func,
};

UndoToast.defaultProps = {
  closeToast: null,
  imageTitle: 'Untitled',
  onUndo: null,
};

export default UndoToast;
