import React, { useMemo } from 'react';
import { List } from 'immutable';

import {
  Annotation,
  PathMarkup,
  PointMarkup,
  AnnotationsProvider,
} from 'src/components/Annotations';
import { get, tGet } from 'src/utils/accessors';
import { Geometry } from 'src/types/resources';
import { useComment } from 'src/hooks/use-resource';

/**
 * TODO: Remove after markup functionality is complete
 *
 * Parser necessary to accept comments with geometry as an object instead of an
 * an array of markups.
 */
function commentGeometryToAnnotationMarkups(geometry: Geometry[]) {
  if (Array.isArray(geometry) || List.isList(geometry)) {
    return geometry.map(markup => {
      const markupType = tGet(markup, 'type');

      if (markupType === 'path') {
        return new PathMarkup(
          get(markup, 'path').toJS(),
          get(markup, 'color'),
          get(markup, 'normalized'),
        );
      }

      if (markupType === 'point') {
        return new PointMarkup(
          get(markup, 'x'),
          get(markup, 'y'),
          get(markup, 'color'),
        );
      }

      throw new Error(`Markup type: ${markupType} does not exist.`);
    });
  }

  return [new PointMarkup(get(geometry, 'x'), get(geometry, 'y'))];
}

export default function TaskAnnotationProvider({
  children,
  taskId,
}: React.PropsWithChildren<{
  taskId: string;
}>) {
  const task = useComment(
    {
      id: taskId,
    },
    {
      request: false,
    },
  );
  const annotations = useMemo(() => {
    const markerId = tGet(task, 'attributes', 'markerId');
    if (markerId) {
      return [
        new Annotation(
          tGet(task, 'id'),
          commentGeometryToAnnotationMarkups(
            tGet(task, 'attributes', 'geometry'),
          ),
          {
            frame: tGet(task, 'attributes', 'frame'),
            index: Number(tGet(task, 'attributes', 'markerId')),
          },
        ),
      ];
    }

    return [];
  }, [task]);

  return (
    <AnnotationsProvider annotations={annotations} id={taskId}>
      {children}
    </AnnotationsProvider>
  );
}
