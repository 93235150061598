import React from 'react';
import { FolderOpen as FolderOpenSvg } from '@styled-icons/material';
import { FormattedMessage } from 'react-intl';

import ActionsDropdownItem from './ActionsDropdownItem';
import messages from '../messages';

function ActionsDropdownMove(props) {
  return (
    <ActionsDropdownItem
      action="move"
      icon={<FolderOpenSvg />}
      label={<FormattedMessage {...messages.messageMove} />}
      {...props}
    />
  );
}

export default ActionsDropdownMove;
