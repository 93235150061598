import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, P, CheckboxField } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';
import { Flex } from '@rebass/grid';

import messages from './messages';

const RetryModal = ({ reachedRetryLimit, retryNetworkFailedUploads }) => {
  const [keepRetrying, setKeepRetrying] = useState(false);
  return (
    <Modal
      title={<FormattedMessage {...messages.title} />}
      open={reachedRetryLimit}
    >
      <div>
        <P>
          <FormattedMessage {...messages.message} />
        </P>
        <CheckboxField
          name="keepRetrying"
          label={<FormattedMessage {...messages.labelKeepTrying} />}
          onChange={e => setKeepRetrying(e.target.checked)}
          checked={keepRetrying}
        />
        <Flex justifyContent="flex-end">
          <Button
            colorScheme="primary"
            flat
            onClick={() => retryNetworkFailedUploads(keepRetrying)}
          >
            <FormattedMessage {...messages.labelRetry} />
          </Button>
        </Flex>
      </div>
    </Modal>
  );
};

RetryModal.propTypes = {
  reachedRetryLimit: PropTypes.bool.isRequired,
  retryNetworkFailedUploads: PropTypes.func.isRequired,
};

export default RetryModal;
