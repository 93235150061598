import styled from 'styled-components/macro';
import tag from 'clean-tag';
import { color, space } from 'styled-system';
import { mediaQuery } from '@picter/prisma';

const GeneralLayoutContainer = styled(tag)`
  ${color};
  ${space};
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  position: relative;
  width: 100%;
  -webkit-overflow-scrolling: touch;

  @media screen and ${mediaQuery.greaterThan('mobile')} {
    overflow-y: auto;
  }
`;

GeneralLayoutContainer.defaultProps = {
  bg: 'grey.100',
};

export default GeneralLayoutContainer;
