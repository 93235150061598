import styled from 'styled-components/macro';

const SelectionTopbarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
  height: 73px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows.soft};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: ${props => props.theme.space[7]}px;
  padding-right: ${props => props.theme.space[5]}px;
`;

export default SelectionTopbarWrapper;
