import React from 'react';
import { DetailLayout } from '@picter/prisma';
import { Map } from 'immutable';
import { Box } from '@rebass/grid';

import ActionsDropdown from 'src/components/ActionsDropdown';
import ProjectTitle from 'src/components/ProjectPageTitle';
import ProjectPageActionsDropdown, {
  Props as ActionsDropdownProps,
} from 'src/components/ProjectPageActionsDropdownMobile';
import MenuIcon from 'src/components/MenuIcon';
import { get } from 'src/utils/accessors';
import { ShareDropdownMenu } from 'src/containers/ProjectShare';

type Props = {
  showMenuIcon?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  project: Map<string, any>;
} & ActionsDropdownProps;

export default function ProjectPageHeaderMobile({
  following,
  onClickComments,
  onClickDownloadProject,
  onClickDuplicateProject,
  onClickFollow,
  onClickInfoButton,
  onClickMoveToTrash,
  openFileDialog,
  project,
  showMenuIcon = true,
}: Props) {
  return (
    <DetailLayout.Top>
      <DetailLayout.Top.AlignLeft
        style={{ maxWidth: '50%' }}
        justifyContent="flex-start"
        flex="0 0 100px"
      >
        {showMenuIcon && <MenuIcon />}
        <Box px={2} width={1}>
          <ProjectTitle
            // @ts-ignore ProjectTitle is wrapped with a forwardRef and thus not working with TS out-of-the-box
            minWidth="100px"
            title={get(project, 'attributes.title')}
            disabled
          />
        </Box>
      </DetailLayout.Top.AlignLeft>
      <DetailLayout.Top.AlignRight
        style={{ maxWidth: '50%' }}
        justifyContent="flex-end"
        flex="0 0 100px"
      >
        <ActionsDropdown
          page="project"
          menus={[<ShareDropdownMenu key="share-dropdown" />]}
        >
          <ActionsDropdown.Group entity="project">
            <ProjectPageActionsDropdown
              following={following}
              onClickComments={onClickComments}
              onClickDownloadProject={onClickDownloadProject}
              onClickDuplicateProject={onClickDuplicateProject}
              onClickFollow={onClickFollow}
              onClickInfoButton={onClickInfoButton}
              onClickMoveToTrash={onClickMoveToTrash}
              openFileDialog={openFileDialog}
            />
          </ActionsDropdown.Group>
        </ActionsDropdown>
      </DetailLayout.Top.AlignRight>
    </DetailLayout.Top>
  );
}
