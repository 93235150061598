export const CommentAction = Object.freeze({
  ASSIGN: 'comment_action__assign',
  DELETE: 'comment_action__delete',
  EDIT: 'comment_action__edit',
});

export const CommentTarget = Object.freeze({
  COLLECTION: 'ws-collections',
  COMMENT: 'ws-comments',
  FILE: 'ws-project-images',
  PROJECT: 'ws-projects',
});
