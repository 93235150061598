import React, {
  forwardRef,
  useRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { VariableSizeList } from 'react-window';
import ResizeObserver from 'resize-observer-polyfill';

import { component } from 'src/utils/app-prop-types';

// Needed to work with DynamicSizeList
export const ListContext = React.createContext({});

export const useDynamicSizeItemMeasurer = index => {
  const { setSize, unsetSize } = useContext(ListContext);
  const root = useRef({});

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      window.requestAnimationFrame(() => {
        if (root.current) {
          setSize(index, root.current.getBoundingClientRect().height);
        } else {
          unsetSize();
        }
      });
    });

    if (root.current) {
      const { current } = root;
      setSize(index, root.current.getBoundingClientRect().height);
      resizeObserver.observe(current);
      return () => resizeObserver.unobserve(current);
    }
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [root.current]);

  return root;
};

const DynamicSizeList = forwardRef(
  (
    { children, getDefaultSize, itemData: itemDataFromProps, ...props },
    ref,
  ) => {
    // refs
    const elementRef = useRef();
    const innerElementRef = useRef(); // big div (content)
    const listenersRef = useRef({});
    const [itemOverflowMap, setItemOverflowMap] = useState({});

    useImperativeHandle(ref, () => elementRef.current);

    // Small logics implementing basically what DynamicSizeList
    // from react-window does but only with the functionality we need.
    const itemSizeMap = useRef({});
    const setItemSize = useCallback(
      (index, size) => {
        if (elementRef.current) {
          const sizeMapItem = itemSizeMap.current[index];
          if (sizeMapItem !== size) {
            itemSizeMap.current = { ...itemSizeMap.current, [index]: size };
            elementRef.current.resetAfterIndex(index);

            if (sizeMapItem !== undefined) {
              // On item size change, scroll to keep it on the same position
              setItemOverflowMap({ ...itemDataFromProps, [index]: undefined });
              elementRef.current.scrollToItem(index);
              if (listenersRef.current[index]) {
                listenersRef.current[index].forEach(cb =>
                  cb(elementRef.current),
                );
              }
            }
          }
        }
      },
      [elementRef, itemDataFromProps],
    );

    const unsetItemSize = useCallback(index => {
      itemSizeMap.current = { ...itemSizeMap.current, [index]: undefined };
    }, []);

    const subscribeToItemSizeChange = useCallback(
      (index, fn) => {
        setItemOverflowMap({ ...itemDataFromProps, [index]: 'hidden' });
        listenersRef.current[index] = listenersRef.current[index] || [];
        listenersRef.current[index].push(fn);
        return () =>
          listenersRef.current[index].splice(
            listenersRef.current[index].indexOf(fn),
            1,
          );
      },
      [itemDataFromProps],
    );

    const itemData = useMemo(() => {
      return itemDataFromProps.map((item, index) => ({
        data: item,
        extraStyle: { overflow: itemOverflowMap[index] },
      }));
    }, [itemDataFromProps, itemOverflowMap]);

    const getSize = useCallback(
      index => itemSizeMap.current[index] || getDefaultSize(itemSizeMap),
      [getDefaultSize],
    );
    const { width } = props;

    const contextValue = useMemo(
      () => ({
        setSize: setItemSize,
        unsetSize: unsetItemSize,
        width,
        subscribeToItemSizeChange,
      }),
      [setItemSize, subscribeToItemSizeChange, unsetItemSize, width],
    );

    return (
      <ListContext.Provider value={contextValue}>
        <VariableSizeList
          innerRef={innerElementRef}
          itemData={itemData}
          itemSize={getSize}
          ref={elementRef}
          {...props}
        >
          {children}
        </VariableSizeList>
      </ListContext.Provider>
    );
  },
);

DynamicSizeList.displayName = 'DynamicSizeList';

DynamicSizeList.defaultProps = {
  height: undefined,
  width: undefined,
  itemData: undefined,
  itemCount: 0,
};

DynamicSizeList.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  itemData: PropTypes.arrayOf(PropTypes.node),
  itemCount: PropTypes.number,
  getDefaultSize: PropTypes.func.isRequired,
  children: component.isRequired,
};

export default DynamicSizeList;
