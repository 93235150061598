import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ContentCopy as ContentCopySvg,
  Link as LinkSvg,
} from '@styled-icons/material';
import { ButtonElement as Button, toastInfo } from '@picter/prisma';
import copy from 'copy-to-clipboard';

import { Body, Box, Icon, Panel, Subheading } from 'src/modules/prisma';
import { useSpace } from 'src/hooks/use-resource';
import { publicReferralLinkUrl } from 'src/routes/urls';
import { useToriiActions } from 'src/modules/torii';
import TruncateBox from 'src/styles/TruncateBox';
import { ReferralLinkResource } from 'src/types/resources';
import { tGet } from 'src/utils/accessors';

import messages from '../_messages';

function createReferralLink(
  { spaceId }: { spaceId: string },
  { create }: { create: Function },
) {
  return create('wsReferralLinks', {
    attributes: {},
    relationships: {
      space: {
        id: spaceId,
        type: 'ws-spaces',
      },
    },
  });
}

export function useReferralLink({ spaceId }: { spaceId: string }) {
  const space = useSpace({
    id: spaceId,
    include: ['referralLink.referrals'],
  });
  const referralLink: ReferralLinkResource = tGet(
    space,
    'relationships',
    'referralLink',
  );
  const { create, localUpdateRelationship } = useToriiActions() as {
    create: Function;
    localUpdateRelationship: Function;
  };

  useEffect(() => {
    async function createLink() {
      if (!tGet(referralLink, 'id')) {
        const { data } = await createReferralLink({ spaceId }, { create });

        localUpdateRelationship('wsSpaces', {
          id: spaceId,
          relation: 'referralLink',
          data: { type: 'ws-referral-links', id: data.id },
        });
      }
    }
    createLink();
  }, [create, spaceId, localUpdateRelationship, referralLink]);

  return referralLink;
}

export default function CopyReferralLink({ urlKey }: { urlKey?: string }) {
  const intl = useIntl();
  const referralLink = publicReferralLinkUrl({
    urlKey: urlKey ?? '...',
  });

  return (
    <section>
      <Subheading color="grey.600" tag="h3">
        <FormattedMessage {...messages.titleReferralLink} />
      </Subheading>
      <Panel
        backgroundColor="white"
        border="1px solid"
        borderColor="grey.200"
        width="max-content"
        maxWidth="100%"
      >
        <Box alignItems="center" display="flex" flexWrap="wrap" p={4}>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            flex="1 1 auto"
            maxWidth="100%"
          >
            <Icon
              border="1px solid"
              borderColor="grey.200"
              boxSize="xxlarge"
              color="grey.600"
              type={<LinkSvg />}
            />
            <Box display="inline-block" mr={4} />
            <TruncateBox>
              <Body fontWeight="regular">{referralLink}</Body>
            </TruncateBox>
          </Box>
          <Box
            display="flex"
            justifyContent="space-around"
            flex="1 1 auto"
            mt={[4, 4, 4, 0, 0]}
            ml={[0, 0, 0, 4, 4]}
          >
            <Button
              color="primary"
              disabled={!urlKey}
              onClick={() => {
                copy(referralLink);
                toastInfo(<FormattedMessage {...messages.messageLinkCopied} />);
              }}
              py={0}
              px={0}
              variant="flat"
            >
              <Icon color="inherit" type={<ContentCopySvg />} />
              <Box display="inline-block" ml={1} />
              <FormattedMessage {...messages.labelCopyLink} />
            </Button>
            <Box display="inline-block" ml={3} />
            <Button
              color="primary"
              disabled={!urlKey}
              onClick={() => {
                copy(
                  intl.formatMessage(messages.messageInvitationMessage, {
                    referralLink,
                  }),
                );
                toastInfo(
                  <FormattedMessage {...messages.messageInvitationCopied} />,
                );
              }}
              py={0}
              px={0}
              variant="flat"
            >
              <Icon color="inherit" type={<ContentCopySvg />} />
              <Box display="inline-block" ml={1} />
              <FormattedMessage {...messages.labelCopyInvitation} />
            </Button>
          </Box>
        </Box>
      </Panel>
      <Box mt={5} width={[1, 1, 1, 3 / 4, 3 / 4, 1 / 2]}>
        <Body fontWeight="light" textSize="small">
          <FormattedMessage {...messages.messageValidOnlyIfNewWorkspace} />
        </Body>
      </Box>
    </section>
  );
}
