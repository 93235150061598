import React from 'react';
import ContentLoader from 'react-content-loader';
import { withTheme } from 'styled-components/macro';
import { Card } from '@picter/prisma';

import DelayRender from 'src/components/DelayRender';
import { themeShape } from 'src/utils/app-prop-types';
import { AbsoluteBox } from 'src/styles/PositionBox';

import ProjectCardActionsDropdown from './ProjectCardActionsDropdown';

const ProjectCardPlaceholder = ({ theme }) => (
  <DelayRender>
    <Card>
      <Card.Content px={0}>
        <ContentLoader
          height={250}
          width={250}
          primaryColor={theme.colors.gray['100']}
          secondaryColor={theme.colors.gray['300']}
          style={{ height: '100%', width: '100%' }}
          preserveAspectRatio="none"
        >
          <rect x="0" y="0" rx="0" ry="0" width="250" height="135" />

          <rect x="24" y="152" rx="0" ry="0" width="200" height="20" />
          <rect x="24" y="176" rx="0" ry="0" width="200" height="16" />
          <circle cx="38" cy="222" r="14" />
        </ContentLoader>
        <AbsoluteBox bottom={theme.space[4]} right={theme.space[5]}>
          <ProjectCardActionsDropdown />
        </AbsoluteBox>
      </Card.Content>
    </Card>
  </DelayRender>
);

ProjectCardPlaceholder.propTypes = {
  theme: themeShape.isRequired,
};

export default withTheme(ProjectCardPlaceholder);
