import React from 'react';

const AddImageSvg = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line
      x1="10.8564"
      y1="2"
      x2="10.8564"
      y2="8.26832"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <line
      x1="13.8389"
      y1="5.28613"
      x2="7.57054"
      y2="5.28613"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M6.87536 2.69629H3C2.44772 2.69629 2 3.14401 2 3.69629V12.84C2 13.3923 2.44772 13.84 3 13.84H12.1437C12.696 13.84 13.1437 13.3923 13.1437 12.84V8.96461"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
);

export default AddImageSvg;
