import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import { Box } from 'src/modules/prisma';
import FolderCard from 'src/components/FolderCard';
import ProjectCardGrid, { ProjectCard } from 'src/components/ProjectCardGrid';
import { get } from 'src/utils/accessors';

import SpaceSortChooser from './SpaceSortChooser';
import SpaceViewChooser from './SpaceViewChooser';

export default function SpaceContentGrid({
  folders,
  projects,
  lastFolderCreatedId,
}) {
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={6}
        width={1}
      >
        <SpaceSortChooser />
        <SpaceViewChooser />
      </Box>

      {folders.size > 0 ? (
        <FolderCard.Grid>
          {folders.map(folder => (
            <FolderCard
              key={get(folder, 'id')}
              folder={folder}
              isEditing={lastFolderCreatedId === get(folder, 'id')}
            />
          ))}
        </FolderCard.Grid>
      ) : null}

      <ProjectCardGrid>
        {projects.map((project, index) => (
          <ProjectCard
            key={get(project, 'id')}
            project={project}
            index={index}
          />
        ))}
      </ProjectCardGrid>
    </>
  );
}

SpaceContentGrid.propTypes = {
  folders: PropTypes.instanceOf(List),
  projects: PropTypes.instanceOf(List),
  lastFolderCreatedId: PropTypes.string,
};

SpaceContentGrid.defaultProps = {
  folders: List(),
  projects: List(),
  lastFolderCreatedId: undefined,
};
