import kebabCase from 'lodash/kebabCase';
import camelCase from 'lodash/camelCase';
import { requestAsPromise, request } from '../../json-api-middleware/actions';
import { asyncTypesArray } from '../../utils/action-types-helpers';
import formatRequestPayload from '../../utils/format-request-payload';
import formatOptimisticPayload from '../../utils/format-optimistic-payload';
import addQueryParameters from '../../utils/add-query-parameters';
import { API_UPDATE_DATA_TYPES } from '../../constants';
import select from '../../selectors/select';

const update = (
  model,
  { id, attributes, relationships, optimistic = false, querySerializers } = {},
  { prefix, baseUrl, postfix } = {},
) => {
  if (!id) {
    throw new Error('Update without id is not allowed.');
  }

  const endpoint = [kebabCase(model), id];

  if (prefix) endpoint.unshift(prefix);
  if (postfix) endpoint.push(postfix);

  const formattedModel = camelCase(model);

  return (dispatch, getState) => {
    const action = {
      types: asyncTypesArray(API_UPDATE_DATA_TYPES),
      endpoint: addQueryParameters(endpoint.join('/'), {
        querySerializers,
      }),
      method: 'PATCH',
      payload: formatRequestPayload(model, { attributes, relationships }),
      meta: {
        id,
        model: formattedModel,
        optimistic,
        previousValue: optimistic && select(getState(), formattedModel, { id }),
        optimisticPayload: optimistic
          ? formatOptimisticPayload(model, { id, attributes, relationships })
          : undefined,
      },
      baseUrl,
    };

    // optimistic updates don't need to return a promise,
    // since they update the store immediately
    if (optimistic) {
      return dispatch(request(action));
    }

    return requestAsPromise(dispatch, action);
  };
};

export default update;
