import React from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { Group as GroupSvg } from '@styled-icons/material';

import IconGroup from 'src/components/IconGroup';
import { Icon } from 'src/modules/prisma';
import { getEnabledPublicLinks } from 'src/utils/accessors/project';
import { get } from 'src/utils/accessors';

import TrackSharingIndicatorClick from './TrackSharingIndicatorClick';
import { publicLinkIconMap } from '../constants';

const ProjectShareStateMobile = ({
  project,
  iconOutline,
  invitations,
  onClick,
  projectUsers,
  showInvitations,
  showPublicLinks,
  showUsers,
}) => {
  const publicLinks = getEnabledPublicLinks(project);
  const usersCount = showUsers ? projectUsers.size : 0;
  const invitationsCount = showInvitations ? invitations.size : 0;
  const directShareCount = usersCount + invitationsCount;

  return (
    <TrackSharingIndicatorClick trackClick={!!onClick}>
      <IconGroup onClick={onClick} outline={iconOutline} overlapSize={1}>
        {directShareCount > 0 && (
          <Icon
            boxSize="xlarge"
            size="medium"
            borderColor="grey.200"
            borderStyle="solid"
            borderWidth="1px"
            backgroundColor="white"
            color="grey.600"
            type={<GroupSvg />}
          />
        )}
        {showPublicLinks &&
          publicLinks.map(publicLink => {
            const publicLinkType = get(publicLink, 'attributes.type');
            return (
              <Icon
                boxSize="xlarge"
                size="medium"
                borderColor="grey.200"
                borderStyle="solid"
                borderWidth="1px"
                backgroundColor="white"
                color="grey.600"
                type={publicLinkIconMap[publicLinkType]}
              />
            );
          })}
      </IconGroup>
    </TrackSharingIndicatorClick>
  );
};

ProjectShareStateMobile.propTypes = {
  project: PropTypes.instanceOf(Map),
  iconOutline: PropTypes.string,
  invitations: PropTypes.instanceOf(List),
  onClick: PropTypes.func,
  projectUsers: PropTypes.instanceOf(List),

  showInvitations: PropTypes.bool,
  showPublicLinks: PropTypes.bool,
  showUsers: PropTypes.bool,
};

ProjectShareStateMobile.defaultProps = {
  project: null,
  iconOutline: 'white',
  invitations: null,
  onClick: null,
  projectUsers: null,

  showInvitations: false,
  showPublicLinks: false,
  showUsers: false,
};

export default ProjectShareStateMobile;
