import React from 'react';
import { ButtonElement as Button } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Box } from 'src/modules/prisma';

import { librarySettingsSpaceSubscriptionUrl } from 'src/routes/urls';
import useNavigate from 'src/hooks/use-navigate';

import Layout from '../_layout';
import MainNavigation from '../../../_components/MainNavigation';
import messages from '../_messages';

export default function BlockDashboardLayout({
  content,
}: {
  content: React.ReactNode;
}) {
  const navigate = useNavigate();
  const { spaceId } = useParams<{ spaceId: string }>();

  return (
    <Layout>
      <Layout.Navigation>
        <MainNavigation />
      </Layout.Navigation>
      <Layout.Content>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          {content}
          <Button
            color="white"
            height={40}
            mt={6}
            onClick={() =>
              navigate(librarySettingsSpaceSubscriptionUrl({ spaceId }))
            }
            width={280}
          >
            <FormattedMessage {...messages.labelManageSubscription} />
          </Button>
        </Box>
      </Layout.Content>
    </Layout>
  );
}
