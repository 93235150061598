import { useState, useCallback } from 'react';
import Cookies from 'js-cookie';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { useToriiActions } from 'src/modules/torii';

import { useErrorHandler } from 'src/components/ErrorHandler';
import { uploadProjectImages } from 'src/actions/project-image';
import { UPLOAD_TYPE } from 'src/components/UploadArea';
import { getProjectPublicLink } from 'src/utils/accessors/project';
import useAcceptedFileTypes from 'src/hooks/use-accepted-file-types';
import { get } from 'src/utils/accessors';

import {
  useOnClickInfoButton,
  useOnClickComments,
} from '../../../_shared/hooks/handlers';

const useHandlers = () => {
  const [confirmReplaceImages, setConfirmReplaceImages] = useState(null);

  const onClickInfoButton = useOnClickInfoButton();

  const onClickComments = useOnClickComments();

  return {
    onClickInfoButton,
    onClickComments,
    confirmReplaceImages,
    setConfirmReplaceImages,
  };
};

export function useOnFileUpload({
  tracker,
  project,
  collection,
  setConfirmReplaceImages,
  overrideFile = false,
}) {
  const match = useRouteMatch();
  const location = useLocation();
  const actions = useToriiActions();
  const { handleError } = useErrorHandler();
  const acceptedFileTypes = useAcceptedFileTypes();

  return useCallback(
    (files, { uploadType }) => {
      // track upload event before sharing
      const eventName =
        UPLOAD_TYPE.FILE_CHOOSER === uploadType
          ? 'Cicked Add images to upload'
          : 'Dragged Images to upload';

      tracker.track(eventName, {
        numberOfFiles: files.length,
      });

      const publicLink = getProjectPublicLink(project, match.params.viewMode);

      const projectKey = get(publicLink, 'attributes.key');

      const collectionCookie = Cookies.get(`inbox-key/${projectKey}`);
      const [, inboxLinkKey] = collectionCookie
        ? collectionCookie.split(':')
        : [];

      return uploadProjectImages(
        {
          projectId: get(project, 'id'),
          projectPublicKey: projectKey,
          inboxLinkKey,
        },
        files,
        {
          onError: handleError,
          onExistingFileName: confirm => setConfirmReplaceImages(() => confirm),
          overrideFile,
        },
        {
          ...actions,
          location,
          match,
          collection,
          acceptedFileTypes,
        },
      );
    },
    [
      acceptedFileTypes,
      actions,
      collection,
      handleError,
      location,
      match,
      project,
      setConfirmReplaceImages,
      tracker,
    ],
  );
}

export default useHandlers;
