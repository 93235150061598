import {
  branch,
  compose,
  renderComponent,
  renderNothing,
  withProps,
} from 'recompose';

import {
  getProjectInvitationsUnique,
  getProjectUsers,
  getEnabledPublicLinks,
} from 'src/utils/accessors/project';
import branchMediaQuery from 'src/hocs/branch-media-query';

import ProjectShareState from './components/ProjectShareState';
import ProjectShareStateMobile from './components/ProjectShareStateMobile';

export default compose(
  withProps(({ project }) => ({
    invitations: getProjectInvitationsUnique(project),
    projectUsers: getProjectUsers(project),
  })),
  branch(
    ({ invitations, project, projectUsers }) =>
      invitations.size === 0 &&
      projectUsers.size === 0 &&
      !getEnabledPublicLinks(project).size > 0,
    renderNothing,
  ),
  branchMediaQuery({ maxWidth: 0 }, renderComponent(ProjectShareStateMobile)),
)(ProjectShareState);
