import React, { cloneElement, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'src/modules/prisma';
import QuestionSvg from 'src/assets/QuestionSvg';

function IntercomLauncher({ launcher, message }) {
  const handleClick = useCallback(
    event => {
      event.preventDefault();
      const { Intercom } = window;
      if (Intercom) {
        if (message) {
          window.Intercom('showNewMessage', message);
        } else {
          window.Intercom('show');
        }
      }
    },
    [message],
  );

  return cloneElement(launcher, { onClick: handleClick });
}

IntercomLauncher.propTypes = {
  launcher: PropTypes.element,
  message: PropTypes.string,
};

IntercomLauncher.defaultProps = {
  launcher: (
    <Icon
      backgroundColor="white"
      border="1px solid"
      borderColor="grey.200"
      boxSize="xxlarge"
      color="grey.600"
      size="medium"
      type={<QuestionSvg />}
      data-intercom-target="open-help-button"
    />
  ),
  message: null,
};

export default IntercomLauncher;
