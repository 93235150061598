import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { picterTheme } from '@picter/prisma';
import {
  // DISABLE_TRACKING_COOKIE,
  // getCookieValue,
  MaintenanceCheck,
} from '@picter/react-app-core';
import { IntlProvider } from 'react-intl';

import AuthenticationProvider from 'src/utils/cerberus/AuthenticationProvider';
import theme from 'src/theme';
import {
  DevelopmentTracker,
  TrackerProvider,
  // SegmentTracker,
} from '@picter/tracker';
import { ToriiProvider } from 'src/modules/torii';
import { RealtimeProvider } from 'src/modules/realtime';
import { PrismaProvider } from 'src/modules/prisma';
import MaintenancePage from 'src/components/MaintenancePage';
import { SWRConfig } from 'src/modules/swr';
import { DownloadManagerContextProvider } from 'src/modules/download-manager';
import GrowthBookProvider from 'src/containers/GrowthBookProvider';

import store from './store';

// Disable tracking for now
// const TRACKING_ENABLED_ENVIRONMENTS = ['review', 'staging', 'production'];
// const isTrackingDisabled =
//   !TRACKING_ENABLED_ENVIRONMENTS.includes(process.env.REACT_APP_ENV) ||
//   getCookieValue(DISABLE_TRACKING_COOKIE);

const AppProvider = ({ children }) => (
  <PrismaProvider theme={[picterTheme, theme]}>
    <IntlProvider locale="en">
      <Provider store={store}>
        <ToriiProvider>
          <MaintenanceCheck
            errorPage={<MaintenancePage />}
            apiUrl={process.env.REACT_APP_API_URL}
          >
            <DndProvider backend={HTML5Backend}>
              <AuthenticationProvider
                endpointHost={process.env.REACT_APP_API_URL}
              >
                <RealtimeProvider>
                  <TrackerProvider
                    tracker={
                      // isTrackingDisabled ? DevelopmentTracker : SegmentTracker
                      DevelopmentTracker
                    }
                    options={{
                      debug: false,
                      key: process.env.REACT_APP_SEGMENT_KEY,
                    }}
                    properties={{
                      app: 'workspace',
                    }}
                  >
                    <SWRConfig>
                      <GrowthBookProvider>
                        <DownloadManagerContextProvider>
                          {children}
                        </DownloadManagerContextProvider>
                      </GrowthBookProvider>
                    </SWRConfig>
                  </TrackerProvider>
                </RealtimeProvider>
              </AuthenticationProvider>
            </DndProvider>
          </MaintenanceCheck>
        </ToriiProvider>
      </Provider>
    </IntlProvider>
  </PrismaProvider>
);

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
