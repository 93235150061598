import React, { useMemo, useState } from 'react';
import { TextField } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';
import { object, string } from 'yup';
import { Box } from '@rebass/grid';
import isEqual from 'lodash/isEqual';

import FormSection from 'src/components/FormSection';
import { get } from 'src/utils/accessors';
import { Heading } from 'src/modules/prisma';
import { useCurrentUser } from 'src/hooks/use-resource';
import useDocumentTitle from 'src/hooks/use-document-title';
import useFormatMessage from 'src/hooks/use-format-message';
import useFormik from 'src/hooks/use-formik';
import { useToriiActions } from 'src/modules/torii';
import withSuspenseLoader from 'src/hocs/with-suspense-loader';

import Document from './_document';
import Layout from './_layout';
import messages from './_messages';

function ProfilePage() {
  const formatMessage = useFormatMessage();
  const pageTitle = formatMessage(messages.titlePage);

  useDocumentTitle(pageTitle);

  return (
    <Document>
      <Layout>
        <Layout.Top>
          <Heading tag="h1" fontWeight="light" textSize="regular">
            {pageTitle}
          </Heading>
        </Layout.Top>
        <Layout.Content>
          <ProfileSection />
        </Layout.Content>
      </Layout>
    </Document>
  );
}

export default withSuspenseLoader({
  ErrorComponent: null,
  LoadingComponent: null,
})(ProfilePage);

const Status = Object.freeze({
  NONE: 'status__none',
  FAILED: 'status__failed',
  SAVED: 'status__saved',
});

function ProfileSection() {
  const user = useCurrentUser();
  const { update } = useToriiActions();
  const [status, setStatus] = useState(Status.NONE);

  const initialValues = useMemo(
    () => ({
      firstName: get(user, 'attributes.firstName'),
      lastName: get(user, 'attributes.lastName'),
    }),
    [user],
  );

  const {
    errors,
    getFieldProps,
    handleSubmit,
    isSubmitting,
    touched,
  } = useFormik({
    initialValues,
    onSubmit(values) {
      if (isEqual(values, initialValues)) return Promise.resolve();

      return update('users', {
        id: get(user, 'id'),
        attributes: values,
      })
        .then(() => setStatus(Status.SAVED))
        .catch(() => setStatus(Status.FAILED));
    },
    submitOnChange: true,
    submitOnChangeDelay: 1000,
    validationSchema: object({
      firstName: string().label('First name').required(),
      lastName: string().label('Last name').required(),
    }),
  });

  return (
    <FormSection>
      {!isSubmitting && status === Status.FAILED && (
        <FormSection.ErrorIndicator />
      )}
      {isSubmitting && <FormSection.SavingIndicator />}
      {!isSubmitting && status === Status.SAVED && (
        <FormSection.SavedIndicator />
      )}
      <FormSection.Fieldset>
        <FormSection.Content>
          <Box width={[1, 1, 1 / 2]}>
            <form onSubmit={handleSubmit}>
              <TextField
                {...getFieldProps('firstName')}
                error={touched.firstName ? errors.firstName : undefined}
                id="firstName"
                label={<FormattedMessage {...messages.labelFirstName} />}
                type="text"
                required
              />
              <TextField
                {...getFieldProps('lastName')}
                error={touched.lastName ? errors.lastName : undefined}
                id="lastName"
                label={<FormattedMessage {...messages.labelLastName} />}
                type="text"
                required
              />
            </form>
          </Box>
        </FormSection.Content>
      </FormSection.Fieldset>
    </FormSection>
  );
}
