import React, { PropsWithChildren, Suspense, useEffect } from 'react';
import Cookies from 'js-cookie';
import {
  GrowthBook,
  GrowthBookProvider as OriginalGrowthBookProvider,
} from '@growthbook/growthbook-react';
import useSWR from 'swr';
import { Spinner } from '@picter/prisma';

import { useCurrentUser } from 'src/hooks/use-resource';
import { get } from 'src/utils/accessors';

const growthbook = new GrowthBook({
  trackingCallback: (experiment, result) => {
    // @TODO: Send tracking data to our backend
    // eslint-disable-next-line no-console
    console.log('Viewed Experiment', experiment, result);
  },
});

// simple fetcher because default fetcher includes credentials
// and it breaks growthbook
function fetcher(url: string) {
  return fetch(url).then(res => res.json());
}

function GrowthBookSetup({ children }: PropsWithChildren<{}>) {
  const { data } = useSWR(
    process.env.REACT_APP_GROWTHBOOK_FEATURES_ENDPOINT as string,
    fetcher,
  );
  const user = useCurrentUser();
  const userId = user.get('id');

  useEffect(() => {
    if (data) {
      growthbook.setFeatures(data.features);
    }
  }, [data]);

  useEffect(() => {
    if (!userId) {
      return;
    }

    growthbook.setAttributes({
      id: userId,
      lastUserId: Cookies.get('picter-last-user-id'),
      employee:
        get(user, 'attributes.emailVerified') &&
        get(user, 'attributes.email').endsWith('@picter.com'),
    });
  }, [userId, user]);

  return (
    <OriginalGrowthBookProvider growthbook={growthbook}>
      {children}
    </OriginalGrowthBookProvider>
  );
}

export default function GrowthBookProvider({
  children,
}: PropsWithChildren<{}>) {
  return (
    <Suspense fallback={<Spinner />}>
      <GrowthBookSetup>{children}</GrowthBookSetup>
    </Suspense>
  );
}
