import styled from 'styled-components/macro';
import { P } from '@picter/prisma';

const UploadParagraph = styled(P).attrs({
  textStyle: 'caption',
})`
  color: #3f0076;
  display: none;

  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    display: block;
  }
`;

export default UploadParagraph;
