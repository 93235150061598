import styled, { keyframes } from 'styled-components/macro';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`;

const AnimatedSvg = styled.svg`
  animation: ${spin} 0.5s linear infinite;
`;

export default AnimatedSvg;
