import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { pdfjs } from 'react-pdf';
import { Spinner } from '@picter/prisma';
import { Flex, Box } from '@rebass/grid';

import Page from './styles/Page';
import Document from './styles/Document';

// Check webpack section on https://github.com/wojtekmaj/react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const calcScale = (height, width) => {
  const currScale = height > width ? width / 1000 : width / 2000;
  return currScale;
};

const PDF = ({ src }) => {
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(
    calcScale(window.innerHeight, window.innerWidth),
  );

  const onDocumentLoadSuccess = useCallback(({ numPages: newNumPages }) => {
    setNumPages(newNumPages);
  }, []);

  useEffect(() => {
    const onResize = () => {
      setScale(calcScale(window.innerHeight, window.innerWidth));
    };
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  });

  return (
    <div
      style={{
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Document
        file={src}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={
          <Flex
            alignItems="center"
            justifyContent="center"
            style={{ height: '100%' }}
          >
            <Box>
              <Spinner />
            </Box>
          </Flex>
        }
      >
        {new Array(numPages).fill(null).map((_, index) => {
          const currentPage = index + 1;
          return (
            <>
              <Page
                firstPage={index === 0}
                lastPage={currentPage === numPages}
                pageNumber={currentPage}
                scale={scale}
                renderAnnotationLayer={false}
              />
            </>
          );
        })}
      </Document>
    </div>
  );
};

PDF.propTypes = {
  src: PropTypes.string.isRequired,
};

export default PDF;
