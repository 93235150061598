import styled from 'styled-components/macro';
import {
  Box,
  // the props actually exist, but how they are exported
  // confuses the linter and causes errors
  /* eslint-disable import/named */
  grid,
  /* eslint-enable import/named */
} from 'src/modules/prisma';

const GridItem = styled(Box)`
  ${grid}
`;

export default GridItem;
