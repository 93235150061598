import styled from 'styled-components/macro';
import { Box } from '@rebass/grid';

const NotificationWrapper = styled(Box)`
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.colors.grey[100]};
  }
`;

export default NotificationWrapper;
