import React, { forwardRef } from 'react';
import { Spinner } from '@picter/prisma';

import {
  getProjectImageFileInfo,
  getProjectVideoFileUrl,
} from 'src/utils/accessors/project-image';
import Image from 'src/components/ImgWithOriginal';
import PDF from 'src/components/PDF';
import FilePreview from 'src/components/PreviewPlaceholder';
import { Box } from 'src/modules/prisma';
import { tGet } from 'src/utils/accessors';
import { FileResource } from 'src/types/resources';

import VideoPlayer from './VideoPlayer';

type FileProps = {
  file: FileResource;
  height: number;
  width: number;
  projectPublicUrlKey: string;
};

const generateOriginalImageUrl = ({
  file,
  projectPublicUrlKey,
}: {
  file: FileResource;
  projectPublicUrlKey: string;
}) => {
  const originalUrl = getProjectImageFileInfo(file, 'original');

  const publicKeyQueryParameter = `publicUrlKey=${projectPublicUrlKey}`;

  return `${originalUrl}?${publicKeyQueryParameter}`;
};

const File = forwardRef<HTMLElement, FileProps>(
  ({ file, height, width, projectPublicUrlKey }, ref) => {
    const isNonVisual = tGet(file, 'attributes', 'isNonVisual');
    const isVideo = tGet(file, 'attributes', 'isVideo');
    const fileMimeType = tGet(file, 'attributes', 'mimeType');
    let src;

    if (isVideo) {
      src = getProjectVideoFileUrl(file);
    } else if (fileMimeType === 'application/pdf') {
      src = getProjectImageFileInfo(file, 'original');
    } else {
      src = getProjectImageFileInfo(file, 'large');
    }

    if (isVideo) {
      return (
        <VideoPlayer
          // @ts-ignore
          frameRate={tGet(file, 'attributes', 'frameRate')}
          height={height}
          // @ts-ignore
          ref={ref}
          src={src}
          width={width}
        />
      );
    }

    if (fileMimeType === 'application/pdf') {
      return (
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          padding={8}
          height={height}
          width={width}
        >
          <PDF src={src} />
        </Box>
      );
    }

    if (isNonVisual) {
      // @ts-ignore
      return <FilePreview type={fileMimeType} />;
    }

    return (
      <Image
        // @ts-ignore
        alt={tGet(file, 'attributes', 'title')}
        src={src}
        originalSrc={generateOriginalImageUrl({ file, projectPublicUrlKey })}
        ref={ref}
        height={height}
        width={width}
        imageBytes={tGet(file, 'attributes', 'bytes')}
        mimeType={fileMimeType}
        canDownloadImage
        loader={
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            style={{ height: '100%' }}
          >
            <Box>
              <Spinner />
            </Box>
          </Box>
        }
      />
    );
  },
);

export default File;
