import React from 'react';
import { withRouter } from 'react-router-dom';

import ProjectCardGrid, {
  ProjectCardPlaceholder,
} from 'src/components/ProjectCardGrid';

const ProjectsSectionPlaceholder = () => {
  return (
    <ProjectCardGrid>
      <ProjectCardPlaceholder />
    </ProjectCardGrid>
  );
};

export default withRouter(ProjectsSectionPlaceholder);
