import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useContext,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { Mention } from 'react-mentions';

import useTheme from 'src/hooks/use-theme';
import { MentionableUsersContext } from 'src/components/MentionableUsersProvider';

import MentionsInput from './styles/MentionsInput';
import MentionComponent from './components/MentionComponent';

const MentionableInputField = forwardRef(
  (
    {
      autoFocus,
      onFocus,
      glowOnFocus,
      id,
      maxRows,
      name,
      onBlur,
      onChange,
      onKeyDown,
      placeholder,
      value,
    },
    ref,
  ) => {
    const inputRef = useRef();
    const theme = useTheme();

    const mentionableUsers = useContext(MentionableUsersContext);

    /**
     * Necessary to workaround react-mentions mocked event that sends
     * neither the id nor the name of the input to the listener.
     */
    const handleChange = useCallback(
      event => {
        /* eslint-disable no-param-reassign */
        event.target.id = id;
        event.target.name = name;
        if (onChange) onChange(event);
        /* eslint-enable no-param-reassign */
      },
      [id, name, onChange],
    );

    useImperativeHandle(ref, () => inputRef.current);

    useEffect(() => {
      if (autoFocus) {
        const { current: el } = inputRef;
        el.focus({ preventScroll: true });
      }
    }, [autoFocus]);

    return (
      <MentionsInput
        autoFocus={autoFocus}
        className="mentions-container"
        glowOnFocus={glowOnFocus}
        inputRef={inputRef}
        maxRows={maxRows}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        type="textarea"
        value={value}
        allowSuggestionsAboveCursor
        noMargin
      >
        <Mention
          trigger="@"
          data={mentionableUsers}
          renderSuggestion={MentionComponent}
          style={{
            color: theme.colors.primary,
          }}
          displayTransform={(_, display) => `@${display}`}
          appendSpaceOnAdd
        />
      </MentionsInput>
    );
  },
);

MentionableInputField.displayName = 'MentionableInputField';

MentionableInputField.propTypes = {
  autoFocus: PropTypes.bool,
  onFocus: PropTypes.func,
  glowOnFocus: PropTypes.bool,
  id: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  maxRows: PropTypes.number,
};

MentionableInputField.defaultProps = {
  autoFocus: false,
  glowOnFocus: false,
  id: undefined,
  onBlur: f => f,
  onFocus: f => f,
  onChange: f => f,
  onKeyDown: f => f,
  placeholder: null,
  value: null,
  maxRows: undefined,
};

export default MentionableInputField;
