import React, { useCallback, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import { toastError, toastInfo } from '@picter/prisma';

import { createComment } from 'src/actions/comments';
import { get, has } from 'src/utils/accessors';
import { libraryProjectImageCommentUrl } from 'src/routes/urls';
import { ListContext } from 'src/components/DynamicSizeList';
import { pickImageProofFilter } from 'src/utils/accessors/project-image';
import { returnInCase } from 'src/utils/in-case';
import { useAnnotations } from 'src/components/Annotations';
import { useCurrentUser } from 'src/hooks/use-resource';
import { useToriiActions } from 'src/modules/torii';
import useFormatMessage from 'src/hooks/use-format-message';
import useNavigate from 'src/hooks/use-navigate';
import usePermission from 'src/hooks/use-permission';
import { Comment, CommentAction, CommentTarget } from 'src/components/Comment';

import messages from './messages';

function hasRelationship(object, relationship) {
  const relation = get(object, ['relationships', relationship]);
  return has(relation, 'id') || has(relation, 'data.id');
}

export default function ProjectComment({ comment, index }) {
  /**
   * Subscriptions
   */
  const user = useCurrentUser();
  const formatMessage = useFormatMessage();
  const {
    create,
    destroy,
    localBulkDestroy,
    localCreate,
    localUpdateRelationship,
    update,
  } = useToriiActions();
  const { canDeleteComments, canManageAssignees } = usePermission();
  const {
    imageId,
    commentId: paramCommentId,
    projectId: projectKey,
    viewMode,
  } = useParams();
  const [
    { deselect, select },
    { highlightedId, selectedId },
  ] = useAnnotations();
  const navigate = useNavigate();
  const { pathname, query } = useLocation();
  const { subscribeToItemSizeChange } = useContext(ListContext);
  const isOnFileDetailPage = Boolean(imageId);

  /**
   * Data
   */
  const commentId = get(comment, 'id');
  const userId = get(user, 'id');
  const markerId = get(comment, 'attributes.markerId');

  const isOpened = commentId === paramCommentId;
  const isHighlighted = commentId === highlightedId;
  const isSelected = commentId === selectedId || commentId === paramCommentId;

  const target = returnInCase({
    [hasRelationship(comment, 'project')]: () => CommentTarget.PROJECT,
    [hasRelationship(comment, 'collection')]: () => CommentTarget.COLLECTION,
    [hasRelationship(comment, 'image')]: () => CommentTarget.FILE,
    [hasRelationship(comment, 'parent')]: () => CommentTarget.COMMENT,
  });
  const isCommentReply = target === CommentTarget.COMMENT;

  const author = get(comment, 'relationships.author');
  const assignee = get(comment, 'relationships.assignee');
  const file = get(comment, 'relationships.image');
  const project = get(comment, 'relationships.project');
  const replies = get(comment, 'relationships.replies');
  const resolver = get(comment, 'relationships.resolvedBy');

  const authorEmail =
    get(author, 'attributes.email') ||
    get(author, 'attributes.liteAccountEmail');
  const authorName = get(author, 'attributes.publicName');
  const authorData = useMemo(
    () => ({
      email: authorEmail,
      name: authorName,
    }),
    [authorEmail, authorName],
  );

  const assigneeData = useMemo(() => {
    const assigneeId = get(assignee, 'id');

    if (!assigneeId) return undefined;

    const assigneeEmail = get(assignee, 'attributes.email');
    const assigneeName = get(assignee, 'attributes.publicName');

    return {
      id: assigneeId,
      email: assigneeEmail,
      name: assigneeName,
    };
  }, [assignee]);

  const fileData = useMemo(() => {
    if (target !== CommentTarget.FILE) return undefined; // not necessary for replies

    const fileId = get(file, 'id');
    const fileTitle = get(file, 'attributes.title');
    const fileSrc =
      get(file, 'attributes.files.small.location') ||
      get(file, 'attributes.files.thumbnailUrls.0');
    const fileType = (() => {
      switch (true) {
        case get(file, 'attributes.isVideo'):
          return 'video';
        case get(file, 'attributes.isImage'):
          return 'image';
        case get(file, 'attributes.isPdf'):
          return 'pdf';
        case get(file, 'attributes.isNonVisual'):
          return 'non-visual';
        default:
          return undefined;
      }
    })();

    return {
      alt: fileTitle,
      frameRate: get(file, 'attributes.frameRate'),
      id: fileId,
      type: fileType,
      previewSize: 'small',
      showPreview: !isOnFileDetailPage,
      src: fileSrc,
    };
  }, [file, isOnFileDetailPage, target]);

  const projectId = get(project, 'id');
  const projectData = useMemo(
    () => ({
      id: projectId,
    }),
    [projectId],
  );

  const repliesData = useMemo(() => {
    if (isCommentReply || !replies) return undefined; // nested reply not supported

    return (Reflect.has(replies, 'toJS') ? replies.toJS() : replies)
      .sort((reply, otherReply) => {
        return (
          get(reply, 'attributes.createdAt') -
          get(otherReply, 'attributes.createdAt')
        );
      })
      .map(reply => {
        const replyAuthor = get(reply, 'relationships.author');
        const isAuthor = userId === get(replyAuthor, 'id');
        const actions = [];

        if (isAuthor) actions.push(CommentAction.EDIT);
        if (isAuthor || canDeleteComments) actions.push(CommentAction.DELETE);

        return {
          actions,
          content: get(reply, 'attributes.text'),
          edited: get(reply, 'attributes.editedAt') > 0,
          author: {
            email:
              get(replyAuthor, 'attributes.email') ||
              get(replyAuthor, 'attributes.liteAccountEmail'),
            name: get(replyAuthor, 'attributes.publicName'),
          },
          id: get(reply, 'id'),
          published: get(reply, 'attributes.createdAt'),
        };
      });
  }, [canDeleteComments, isCommentReply, replies, userId]);

  /**
   * Permissions
   */
  const availableActions = useMemo(() => {
    const isAuthor = userId === get(author, 'id');
    const actions = [];

    if (isAuthor) actions.push(CommentAction.EDIT);
    if (isAuthor || canDeleteComments) actions.push(CommentAction.DELETE);
    if (canManageAssignees) actions.push(CommentAction.ASSIGN);

    return actions;
  }, [author, canDeleteComments, canManageAssignees, userId]);

  /**
   * Handlers
   */
  const toggleAssignee = useCallback(
    async (id, userToAssign) => {
      if (userToAssign) {
        await localCreate('users', {
          id: userToAssign.id,
          type: 'users',
          attributes: {
            email: userToAssign.email,
            publicName: userToAssign.name,
          },
        });

        return update('wsComments', {
          id,
          relationships: {
            assignee: { type: 'users', id: userToAssign.id },
          },
        });
      }

      return update('wsComments', {
        id,
        relationships: {
          assignee: null,
        },
      });
    },
    [localCreate, update],
  );

  const deleteComment = useCallback(
    async (id, reps) => {
      try {
        await destroy('wsComments', {
          id,
        });

        // locally deleting comment's replies
        if (reps && reps.length > 0) localBulkDestroy('wsComments', reps);

        toastInfo(formatMessage(messages.messageDeleteCommentSuccess));
      } catch (e) {
        toastError(e);
      }
    },
    [destroy, formatMessage, localBulkDestroy],
  );

  const editComment = useCallback(
    (id, text) => {
      try {
        if (text === '') {
          return destroy('wsComments', {
            id,
          });
        }

        return update('wsComments', {
          id,
          attributes: {
            text,
          },
        });
      } catch (e) {
        return toastError(e);
      }
    },
    [update, destroy],
  );

  const toggleResolve = useCallback(
    async (id, resolved) => {
      await update('wsComments', {
        id,
        attributes: {
          resolved,
        },
        optimistic: true,
      });

      return localUpdateRelationship('wsComments', {
        id: commentId,
        relation: 'resolvedBy',
        data: {
          type: 'users',
          id: userId,
        },
      });
    },
    [commentId, localUpdateRelationship, update, userId],
  );

  const createCommentReply = useCallback(
    ({ text }) => {
      return createComment(
        {
          projectId,
          commentId,
          projectPublicUrlKey: projectKey,
          text,
          user,
          viewMode,
        },
        { create, localUpdateRelationship },
      );
    },
    [
      commentId,
      create,
      localUpdateRelationship,
      projectId,
      projectKey,
      user,
      viewMode,
    ],
  );

  const toggleReplies = useCallback(() => {
    const route = pathname.split('/comments')[0];
    if (!isOpened) {
      const unsubscribe = subscribeToItemSizeChange(index, listElement => {
        listElement.scrollToItem(index);
        unsubscribe();
      });

      navigate(`${route}/comments/${commentId}`, {
        query: pickImageProofFilter(query),
      });

      return;
    }

    navigate(`${route}/comments`, {
      query: pickImageProofFilter(query),
    });
  }, [
    commentId,
    index,
    isOpened,
    navigate,
    pathname,
    query,
    subscribeToItemSizeChange,
  ]);

  const toggleMarkerSelection = useCallback(() => {
    if (markerId) {
      // select annotation
      if (isSelected) deselect();
      else select(commentId);
    }
  }, [commentId, markerId, deselect, select, isSelected]);

  const navigateToFilePage = useCallback(() => {
    navigate(
      libraryProjectImageCommentUrl({
        commentId,
        projectId: projectKey,
        imageId: fileData.id,
      }),
    );
  }, [commentId, navigate, fileData, projectKey]);

  const navigateToFilePageWithAnnotationState = useCallback(() => {
    navigate(
      libraryProjectImageCommentUrl({
        commentId,
        projectId: projectKey,
        imageId: fileData.id,
      }),
      { state: { selectedMarkerId: commentId } },
    );
  }, [commentId, navigate, fileData, projectKey]);

  const toggleFocusComment = useCallback(() => {
    // open thread
    if (repliesData && repliesData.length > 0) {
      toggleReplies();
    }

    toggleMarkerSelection();
  }, [repliesData, toggleReplies, toggleMarkerSelection]);

  return (
    <Comment
      actions={availableActions}
      assignee={assigneeData}
      author={authorData}
      content={get(comment, 'attributes.text')}
      direction="right-to-left"
      edited={get(comment, 'attributes.editedAt') > 0}
      file={fileData}
      frame={get(comment, 'attributes.frame')}
      highlighted={isHighlighted}
      id={commentId}
      marker={markerId}
      opened={isOpened}
      project={projectData}
      published={get(comment, 'attributes.createdAt')}
      replies={repliesData}
      resolved={get(comment, 'attributes.resolved')}
      resolver={get(resolver, 'attributes.publicName')}
      selected={isSelected}
      onClick={toggleFocusComment}
      onClickReply={toggleReplies}
      onClickFile={isOnFileDetailPage ? undefined : navigateToFilePage}
      onClickMarker={
        isOnFileDetailPage
          ? toggleMarkerSelection
          : navigateToFilePageWithAnnotationState
      }
      onAssign={toggleAssignee}
      onDelete={deleteComment}
      onEdit={editComment}
      onResolve={toggleResolve}
      onReply={createCommentReply}
    />
  );
}

ProjectComment.propTypes = {
  comment: PropTypes.shape({}),
  index: PropTypes.number.isRequired,
};

ProjectComment.defaultProps = {
  comment: {},
};
