import React from 'react';
import { Icon } from '@picter/prisma';

import { withSidebarControl } from 'src/components/SidebarLayout';

const MenuIcon = withSidebarControl(({ sidebarControl }) => (
  <Icon
    color="muted"
    glyphSize="medium"
    onClick={sidebarControl.openMainSidebar}
    size="large"
    type="menu"
    interactive
  />
));

export default MenuIcon;
