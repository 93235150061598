import React, { MutableRefObject } from 'react';
import { CSSProperties } from 'styled-components';
import { Spinner } from '@picter/prisma';

import { useDynamicSizeItemMeasurer } from 'src/components/DynamicSizeList';
import { CommentResource } from 'src/types/resources';
import { useComment } from 'src/hooks/use-resource';
import { tGet } from 'src/utils/accessors';

import TaskComment from './TaskComment';

function CommentRenderer({
  data,
  style,
  index,
}: {
  data: {
    data: CommentResource['id'];
    extraStyle: CSSProperties;
  }[];
  index: number;
  style: CSSProperties;
}) {
  const { data: commentId, extraStyle } = data[index];
  const itemMeasurerRef = useDynamicSizeItemMeasurer(
    index,
  ) as MutableRefObject<HTMLDivElement>;
  const taskComment = useComment(
    {
      id: commentId,
      includeOnSelector: [
        'assignee',
        'author',
        'collection',
        'image',
        'parent',
        'project.coverImage',
        'replies.author',
        'replies.parent',
        'resolvedBy',
      ],
    },
    { request: false },
  );

  /**
   * Workaround for when you delete tasks and the list doesn't update
   * instantenously because it's fetched in a different manner (with useSWR).
   */
  if (!tGet(taskComment, 'id'))
    return (
      <div key={commentId}>
        <div ref={itemMeasurerRef} />
      </div>
    );

  return (
    <div key={commentId} style={{ ...style, ...extraStyle }}>
      <div ref={itemMeasurerRef}>
        <TaskComment comment={taskComment} index={index} />
      </div>
    </div>
  );
}

function LoadingIndicatorRenderer({
  index,
  style,
}: {
  index: number;
  style: CSSProperties;
}) {
  const itemMeasurerRef = useDynamicSizeItemMeasurer(
    index,
  ) as MutableRefObject<HTMLDivElement>;

  return (
    <div key={`${index}-loading-indicator`} style={style}>
      <div ref={itemMeasurerRef}>
        <Spinner />
      </div>
    </div>
  );
}

export default function TaskRenderer({
  loaded,
  ...props
}: {
  data: {
    data: CommentResource['id'];
    extraStyle: CSSProperties;
  }[];
  index: number;
  style: CSSProperties;
  loaded: boolean;
}) {
  return loaded ? (
    <CommentRenderer {...props} />
  ) : (
    <LoadingIndicatorRenderer {...props} />
  );
}
