import React, { useState } from 'react';
import { ButtonElement as Button, TextField } from '@picter/prisma';
import { useFormik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { object, string, ref } from 'yup';
import { Box } from '@rebass/grid';

import FormSection from 'src/components/FormSection';
import { get } from 'src/utils/accessors';
import { Heading } from 'src/modules/prisma';
import { useCurrentUser } from 'src/hooks/use-resource';
import useDocumentTitle from 'src/hooks/use-document-title';
import useFormatMessage from 'src/hooks/use-format-message';
import { useToriiActions } from 'src/modules/torii';
import withSuspenseLoader from 'src/hocs/with-suspense-loader';

import Document from './_document';
import Layout from './_layout';
import messages from './_messages';

const { REACT_APP_API_URL } = process.env;
const WORKSPACE_ENDPOINT = `${REACT_APP_API_URL}/app-workspace/`;

async function parseResponse(response) {
  let data;

  if (response.status === 400 || response.status === 409) {
    data = await response.json();
  }

  return { body: data, status: response.status };
}

function ManageAccountPage() {
  const formatMessage = useFormatMessage();
  const pageTitle = formatMessage(messages.titlePage);

  useDocumentTitle(pageTitle);

  return (
    <Document>
      <Layout>
        <Layout.Top>
          <Heading tag="h1" fontWeight="light" textSize="regular">
            {pageTitle}
          </Heading>
        </Layout.Top>
        <Layout.Content>
          <ChangeEmailSection />
          <ChangePasswordSection />
        </Layout.Content>
      </Layout>
    </Document>
  );
}

export default withSuspenseLoader({
  ErrorComponent: null,
  LoadingComponent: null,
})(ManageAccountPage);

const Status = Object.freeze({
  NONE: 'status__none',
  FAILED: 'status__failed',
  SAVED: 'status__saved',
});

/**
 *
 * CHANGE EMAIL SECTION
 *
 */
function ChangeEmailSection() {
  const user = useCurrentUser();
  const { load } = useToriiActions();
  const [status, setStatus] = useState(Status.NONE);
  const {
    dirty,
    errors,
    getFieldProps,
    handleSubmit,
    isSubmitting,
    isValid,
    touched,
    values,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: get(user, 'attributes.email'),
      newEmail: '',
      confirmationPassword: '',
    },
    onSubmit: ({ newEmail, confirmationPassword }, { setErrors }) => {
      return fetch(new URL('users/email', WORKSPACE_ENDPOINT).toString(), {
        credentials: 'include',
        method: 'PUT',
        body: JSON.stringify({
          email: newEmail,
          password: confirmationPassword,
        }),
      })
        .then(parseResponse)
        .then(async ({ body, status: statusCode }) => {
          if (statusCode === 400) {
            setStatus(Status.FAILED);
            setErrors({
              confirmationPassword: body.message,
            });
            return;
          }

          if (statusCode === 409) {
            setStatus(Status.FAILED);
            setErrors({
              newEmail: body.message,
            });
            return;
          }

          if (statusCode === 204) {
            setStatus(Status.SAVED);

            await load(
              'users',
              {
                id: get(user, 'id'),
              },
              { ignoreCache: true },
            );

            return;
          }

          setStatus(Status.FAILED);
        });
    },
    validationSchema: object({
      email: string().label('Email'),
      newEmail: string()
        .email()
        .label('New email')
        .notOneOf(
          [ref('email')],
          <FormattedMessage {...messages.messageEqualEmailError} />,
        )
        .required(),
      confirmationPassword: string().label('Password').required(),
    }),
  });

  return (
    <FormSection>
      {!isSubmitting && status === Status.FAILED && (
        <FormSection.ErrorIndicator />
      )}
      {isSubmitting && <FormSection.SavingIndicator />}
      {!isSubmitting && status === Status.SAVED && (
        <FormSection.SavedIndicator />
      )}
      <FormSection.Fieldset>
        <FormSection.Legend>
          <FormattedMessage {...messages.titleChangeEmail} />
        </FormSection.Legend>
        <FormSection.Content>
          <Box width={[1, 1, 1 / 2]}>
            <form onSubmit={handleSubmit}>
              <TextField
                type="email"
                label={<FormattedMessage {...messages.labelEmail} />}
                name="email"
                id="email"
                value={values.email}
                showBadge={false}
                disabled
              />
              <TextField
                {...getFieldProps('newEmail')}
                error={touched.newEmail ? errors.newEmail : undefined}
                id="new-email"
                label={<FormattedMessage {...messages.labelNewEmail} />}
                type="email"
                required
              />
              <TextField
                {...getFieldProps('confirmationPassword')}
                error={
                  touched.confirmationPassword
                    ? errors.confirmationPassword
                    : undefined
                }
                id="confirmation-password"
                label={
                  <FormattedMessage {...messages.labelPasswordConfirmation} />
                }
                type="password"
                required
              />
              <Box mt={4}>
                <Button
                  color="white"
                  disabled={!dirty || !isValid || isSubmitting}
                  py={3}
                  type="submit"
                >
                  <FormattedMessage {...messages.labelSaveEmail} />
                </Button>
              </Box>
            </form>
          </Box>
        </FormSection.Content>
      </FormSection.Fieldset>
    </FormSection>
  );
}

/**
 *
 * CHANGE PASSWORD SECTION
 *
 */
function ChangePasswordSection() {
  const [status, setStatus] = useState(Status.NONE);
  const {
    dirty,
    errors,
    getFieldProps,
    handleSubmit,
    isSubmitting,
    isValid,
    touched,
  } = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
    onSubmit: ({ password, newPassword }, { resetForm, setErrors }) => {
      return fetch(new URL('users/password', WORKSPACE_ENDPOINT).toString(), {
        credentials: 'include',
        method: 'PUT',
        body: JSON.stringify({
          password,
          newPassword,
        }),
      })
        .then(parseResponse)
        .then(({ body, status: statusCode }) => {
          if (statusCode === 400) {
            setStatus(Status.FAILED);
            setErrors({
              password: body.message,
            });
            return;
          }

          if (statusCode === 204) {
            setStatus(Status.SAVED);
            resetForm();
            return;
          }

          setStatus(Status.FAILED);
        });
    },
    validationSchema: object({
      password: string().label('Password').required(),
      newPassword: string()
        .label('New password')
        .min(8)
        .notOneOf(
          [ref('password')],
          <FormattedMessage {...messages.messageEqualPasswordError} />,
        )
        .required(),
      newPasswordConfirmation: string()
        .label('Password confirmation')
        .oneOf(
          [ref('newPassword')],
          <FormattedMessage {...messages.messagePasswordMatchError} />,
        )
        .required(),
    }),
  });

  return (
    <FormSection>
      {!isSubmitting && status === Status.FAILED && (
        <FormSection.ErrorIndicator />
      )}
      {isSubmitting && <FormSection.SavingIndicator />}
      {!isSubmitting && status === Status.SAVED && (
        <FormSection.SavedIndicator />
      )}
      <FormSection.Fieldset>
        <FormSection.Legend>
          <FormattedMessage {...messages.titleChangePassword} />
        </FormSection.Legend>
        <FormSection.Content>
          <Box width={[1, 1, 1 / 2]}>
            <form onSubmit={handleSubmit}>
              <TextField
                {...getFieldProps('password')}
                error={touched.password ? errors.password : undefined}
                id="password"
                label={<FormattedMessage {...messages.labelPassword} />}
                type="password"
                required
              />
              <TextField
                {...getFieldProps('newPassword')}
                error={touched.newPassword ? errors.newPassword : undefined}
                id="new-password"
                label={<FormattedMessage {...messages.labelNewPassword} />}
                type="password"
                required
              />
              <TextField
                {...getFieldProps('newPasswordConfirmation')}
                error={
                  touched.newPasswordConfirmation
                    ? errors.newPasswordConfirmation
                    : undefined
                }
                id="confirmation-new-password"
                label={
                  <FormattedMessage
                    {...messages.labelNewPasswordConfirmation}
                  />
                }
                type="password"
                required
              />
              <Box mt={4}>
                <Button
                  color="white"
                  disabled={!dirty || !isValid || isSubmitting}
                  py={3}
                  type="submit"
                >
                  <FormattedMessage {...messages.labelSavePassword} />
                </Button>
              </Box>
            </form>
          </Box>
        </FormSection.Content>
      </FormSection.Fieldset>
    </FormSection>
  );
}
