import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';
import { transparentize } from 'polished';

import { Box } from 'src/modules/prisma';

const PlanInfoFeaturesPanel = styled(Box)`
  background-color: ${props =>
    transparentize(0.5, themeGet('colors.white')(props))};
  border-radius: ${themeGet('radii.large')};
  display: flex;
  flex-direction: column;
  height: 100%;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      margin-bottom: ${themeGet('space.2')}px;
    }
  }
`;

export default PlanInfoFeaturesPanel;
