import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { stringify as stringifyQuery } from 'qs';

function parseSearchAndQuery({ search, query = {} } = {}) {
  const queryString = [];

  if (search) {
    queryString.push(...search.split('&'));
  }

  if (Object.keys(query).length > 0) {
    queryString.push(
      ...stringifyQuery(query, {
        encode: false,
        arrayFormat: 'comma',
      }).split('&'),
    );
  }

  return queryString.length > 0 ? queryString.join('&') : undefined;
}

export default function useNavigate() {
  const history = useHistory();
  const navigate = useCallback(
    (to, { replace, state, query } = {}) => {
      const [pathname, search] = to.split('?');
      const method = replace ? 'replace' : 'push';

      history[method]({
        pathname,
        search: parseSearchAndQuery({ search, query }),
        state,
      });
    },
    [history],
  );

  return navigate;
}
