import React, { useRef, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PersonAdd as PersonAddSvg } from '@styled-icons/material';

import { Icon, Tooltip } from 'src/modules/prisma';
import Avatar from 'src/components/Avatar';
import SearchableUsersDropdown from 'src/components/SearchableUsersDropdown';
import { useSWR } from 'src/modules/swr';
import { UserResource } from 'src/types/resources';
import { $TSFixMe } from 'src/types';

const { REACT_APP_API_URL } = process.env;

type AssignableUserResource = Pick<UserResource, 'id'> &
  Pick<UserResource['attributes'], 'publicName' | 'email'>;

/**
 * TODO: Move this hook out of Comment component.
 *
 * The goal is to load the users inside either `ProjectComment` or `TaskComment`
 * and send to `<Comment />` as a prop decoupling the component from external context.
 */
function useProjectAssignableUsers({ projectId }: { projectId: string }) {
  return useSWR<AssignableUserResource[]>(
    new URL(
      `/app-workspace/ws-projects/${projectId}/assignable-users`,
      REACT_APP_API_URL,
    ).toString(),
  );
}

type Assignee = {
  id: string;
  email: string;
  name: string;
};

export default function CommentAssignerDropdown({
  assignee,
  commentId,
  onChangeAssignee,
  projectId,
}: {
  assignee: Assignee;
  commentId: string;
  onChangeAssignee: (assignee?: Assignee) => void;
  projectId: string;
}) {
  const { data: assignableUsers } = useProjectAssignableUsers({ projectId });

  const users = useMemo(
    () =>
      assignableUsers
        ? assignableUsers.map(({ id, email, publicName }) => ({
            id,
            email,
            name: publicName,
          }))
        : [],
    [assignableUsers],
  );
  const intl = useIntl();

  return (
    <SearchableUsersDropdown
      id={`comment-${commentId}-assigner-dropdown`}
      key={assignee?.id}
      selectedUser={assignee}
      users={users}
      onChangeUser={user => onChangeAssignee(user)}
      placeholder={intl.formatMessage({
        id: 'AssigneeFilterDropdown.labelFilterBy',
        defaultMessage: 'Assign to...',
      })}
      renderToggle={({ active, onClick }) =>
        assignee ? (
          <AssignedToToggle
            active={active}
            onClick={onClick}
            assignee={assignee}
          />
        ) : (
          <AssignToToggle active={active} onClick={onClick} />
        )
      }
    />
  );
}

type ToggleProps = {
  active?: boolean;
  onClick?: React.MouseEventHandler;
};

export function AssignToToggle({ active, onClick }: ToggleProps) {
  const tooltipRef = useRef<$TSFixMe>();

  return (
    // @ts-ignore
    <Tooltip ref={tooltipRef}>
      {/* @ts-ignore */}
      <Tooltip.Reference>
        <Icon
          className="mute-on-resolve"
          type={<PersonAddSvg />}
          color="grey.600"
          size="medium"
          interactive
          onClick={(event: React.MouseEvent) => {
            tooltipRef.current?.hide();
            onClick?.(event);
          }}
          active={active}
        />
        {/* @ts-ignore */}
      </Tooltip.Reference>
      {/* @ts-ignore */}
      <Tooltip.Message>
        <FormattedMessage
          id="CommentAssignerDropdown.labelAssignToTooltip"
          defaultMessage="Assign to..."
        />
        {/* @ts-ignore */}
      </Tooltip.Message>
    </Tooltip>
  );
}

export function AssignedToToggle({
  active,
  assignee,
  onClick,
}: ToggleProps & {
  assignee: Assignee;
}) {
  const { email: assigneeEmail, name: assigneeName } = assignee || {};
  const tooltipRef = useRef<$TSFixMe>();

  return (
    // @ts-ignore
    <Tooltip ref={tooltipRef}>
      {/* @ts-ignore */}
      <Tooltip.Reference>
        <span>
          <Avatar
            className="mute-on-resolve"
            email={assigneeEmail}
            name={assigneeName}
            size="small"
            onClick={
              onClick
                ? event => {
                    tooltipRef.current?.hide();
                    onClick?.(event);
                  }
                : undefined
            }
            selected={active}
          />
        </span>
        {/* @ts-ignore */}
      </Tooltip.Reference>
      {/* @ts-ignore */}
      <Tooltip.Message>
        <FormattedMessage
          id="CommentAssignerDropdown.labelAssignedToTooltip"
          defaultMessage="Assigned to {user}"
          values={{ user: assigneeName || assigneeEmail }}
        />
        {/* @ts-ignore */}
      </Tooltip.Message>
    </Tooltip>
  );
}
