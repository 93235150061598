import React from 'react';
import { FormattedMessage } from 'react-intl';

import { EmptyStatePage } from 'src/components/EmptyStateBox';
import MaintenaceIllustrationSvg from 'src/assets/MaintenanceIllustrationSvg';

import messages from './messages';

function MaintenancePage() {
  return (
    <EmptyStatePage
      graphic={<MaintenaceIllustrationSvg />}
      title={<FormattedMessage {...messages.messageQuickBreak} />}
      message={<FormattedMessage {...messages.messaageWorkingHard} />}
      onClickHelp={() => {
        window.Intercom?.('show');
      }}
    />
  );
}

export default MaintenancePage;
