import React from 'react';
import PropTypes from 'prop-types';

import ActionsDropdownItem from './ActionsDropdownItem';

const ActionsDropdownCustom = ({
  action,
  disabled,
  entity,
  goToMenu,
  icon,
  label,
  onClick,
  ...props
}) => (
  <ActionsDropdownItem
    action={action}
    icon={icon}
    disabled={disabled}
    label={label}
    onClick={onClick}
    {...props}
  />
);

ActionsDropdownCustom.propTypes = {
  entity: PropTypes.string,
  action: PropTypes.string,
  disabled: PropTypes.bool,
  goToMenu: PropTypes.number,
  onClick: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  icon: PropTypes.node.isRequired,
};

ActionsDropdownCustom.defaultProps = {
  disabled: false,
  entity: null,
  action: null,
  goToMenu: null,
  onClick: undefined,
};

export default ActionsDropdownCustom;
