import React from 'react';

export default function NoPermissionIllustrationSvg() {
  return (
    <svg
      width="202"
      height="202"
      viewBox="0 0 202 202"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="20"
        y="20"
        width="162"
        height="162"
        rx="81"
        fill="white"
        stroke="white"
        strokeWidth="2"
        strokeDasharray="15 15"
      />
      <rect
        x="1"
        y="1"
        width="200"
        height="200"
        rx="100"
        stroke="#FB5C5C"
        strokeWidth="2"
        strokeDasharray="15 15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112 89V95H90V89C90 82.9249 94.9249 78 101 78C107.075 78 112 82.9249 112 89ZM88 95H85C82.7909 95 81 96.7909 81 99V122C81 124.209 82.7909 126 85 126H117C119.209 126 121 124.209 121 122V99C121 96.7909 119.209 95 117 95H114V89C114 81.8203 108.18 76 101 76C93.8203 76 88 81.8203 88 89V95ZM119 99C119 97.8954 118.105 97 117 97H85C83.8954 97 83 97.8954 83 99V122C83 123.105 83.8954 124 85 124H117C118.105 124 119 123.105 119 122V99ZM103 111C103 112.105 102.105 113 101 113C99.8954 113 99 112.105 99 111C99 109.895 99.8954 109 101 109C102.105 109 103 109.895 103 111Z"
        fill="#FB5C5C"
      />
    </svg>
  );
}
