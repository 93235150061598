import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reakit/Tooltip';
import isFunction from 'lodash/isFunction';
import classNames from 'classnames';

import { Panel } from 'src/modules/prisma';

import TooltipArrow from '../styles/TooltipArrow';
import { useTooltip } from '../providers';

function TooltipPanel({
  arrowColor,
  backgroundColor,
  children,
  className,
  ...props
}) {
  const tooltip = useTooltip();

  return (
    <Tooltip
      as={Panel}
      className={classNames(className, 'tooltip__panel')}
      backgroundColor={backgroundColor}
      borderRadius="medium"
      {...tooltip}
      {...props}
    >
      <TooltipArrow color={arrowColor} {...tooltip} />
      {isFunction(children) ? children(tooltip) : children}
    </Tooltip>
  );
}

TooltipPanel.propTypes = {
  arrowColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

TooltipPanel.defaultProps = {
  arrowColor: 'grey.900',
  backgroundColor: 'grey.900',
  className: undefined,
};

export default TooltipPanel;
