import React from 'react';
import PropTypes from 'prop-types';

import ImagePreviewPlaceholder from 'src/components/PreviewPlaceholder';
import { ACCEPTED_IMAGE_FILE_TYPES } from 'src/constants';
import ImagePreview from '../styles/ImageCardImagePreview';

const ImageCardImagePreview = ({ alt, src, type, uploading, processing }) => {
  if (
    ACCEPTED_IMAGE_FILE_TYPES.includes(type) &&
    type !== 'image/tiff' &&
    src
  ) {
    return <ImagePreview alt={alt} src={src} />;
  }
  // Fallback in case you dont want to show a preview. i.e. tiff files cant be displayed on browsers.
  return (
    <ImagePreviewPlaceholder
      type={type}
      uploading={uploading}
      processing={processing}
    />
  );
};

ImageCardImagePreview.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  uploading: PropTypes.bool.isRequired,
  processing: PropTypes.bool.isRequired,
};

export default ImageCardImagePreview;
