import React, { useRef, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import themeGet from '@styled-system/theme-get';
import {
  Close as CloseSvg,
  KeyboardArrowDown as KeyboardArrowDownSvg,
} from '@styled-icons/material';
import AutoSuggest, { InputProps } from 'react-autosuggest';
import styled, { createGlobalStyle } from 'styled-components/macro';

import {
  Box,
  Body,
  Dropdown as PrismaDropdown,
  Icon,
  Input,
} from 'src/modules/prisma';
import Avatar from '../Avatar';

export type SearchableUser = {
  id: string;
  email: string;
  name: string;
};

// @ts-ignore
const Dropdown = styled(PrismaDropdown)`
  div[class*='dropdown-menu'] {
    padding: ${themeGet('space.2')}px 0;
    left: unset !important;
    right: 0 !important;

    button[class*='DropdownItemButton'] {
      box-shadow: unset;
      padding: 0 ${themeGet('space.4')}px;
      width: 100%;
    }
  }
`;

const SearchableUsersAutoSuggestStyles = createGlobalStyle`
  .react-autosuggest__container {
    .react-autosuggest__suggestions-container {
      max-height: 304px;
      overflow: auto;
    }

    .react-autosuggest__suggestions-list {
      list-style: none;
      border: none;
      overflow: hidden;
      padding: 0;
      margin: 0;
      margin-top: ${themeGet('space.2')}px;
    }
  }
`;

function getSuggestionValue(suggestion: SearchableUser) {
  return suggestion.name ?? suggestion.email ?? '';
}

function renderSuggestion(
  suggestion: SearchableUser,
  { isHighlighted }: { isHighlighted: boolean },
) {
  const { email, name } = suggestion;

  return (
    // @ts-ignore
    <Dropdown.Item
      active={isHighlighted}
      icon={<Avatar email={email} name={name} size="small" />}
      label={name ?? email}
    />
  );
}

function renderInputComponent({
  onClickClose,
  ...inputProps
}: InputProps<SearchableUser> & { onClickClose: () => void }) {
  return (
    <Box alignItems="center" display="flex" position="relative" mx={2}>
      <Input {...inputProps} pr={6} width="100%" autoFocus />
      <Box position="absolute" right={2}>
        <Icon
          type={<CloseSvg />}
          boxSize="large"
          color="gray.600"
          size="medium"
          onClick={onClickClose}
        />
      </Box>
    </Box>
  );
}

export default function SearchableUsersDropdown({
  id,
  selectedUser,
  users,
  onChangeUser,
  renderToggle = ({ active, onClick }) => (
    <Box alignItems="center" display="flex" onClick={onClick}>
      <Body fontWeight="regular" style={{ cursor: 'pointer' }} textSize="large">
        <FormattedMessage
          id="OptionsDropdown.labelToggle"
          defaultMessage="Toggle"
        />
      </Body>
      <Box display="inline-block" ml={1} />
      <Icon
        active={active}
        type={<KeyboardArrowDownSvg />}
        backgroundColor="white"
        color="black"
        size="medium"
        interactive
      />
    </Box>
  ),
  placeholder,
}: {
  id: string;
  selectedUser?: SearchableUser;
  users: SearchableUser[];
  onChangeUser: (value?: SearchableUser) => void;
  renderToggle?: (props: {
    active?: boolean;
    onClick?: React.MouseEventHandler;
  }) => React.ReactNode;
  placeholder?: string;
}) {
  const [searchText, setSearchText] = useState(
    selectedUser ? selectedUser.name ?? selectedUser.email : '',
  );
  const [suggestions, setSuggestions] = useState(users);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<{ closeMenu: () => void }>();

  const updateSuggestionsBasedOnInput = useCallback(
    ({ value }) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      const updatedSuggestions =
        inputLength === 0 ||
        selectedUser?.name?.toLowerCase() === inputValue ||
        selectedUser?.email?.toLowerCase() === inputValue
          ? users
          : users.filter(u => u.name?.toLowerCase().includes(inputValue));

      setSuggestions(updatedSuggestions);
    },
    [selectedUser, users],
  );

  const selectSuggestion = useCallback(
    (_, { suggestion }) => onChangeUser(suggestion),
    [onChangeUser],
  );

  const resetSuggestion = useCallback(() => {
    dropdownRef.current?.closeMenu();
    onChangeUser(undefined);
  }, [onChangeUser]);

  const blurInputOnEscape = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        event.key === 'Escape' &&
        (event.target as HTMLInputElement).value.length === 0
      ) {
        inputRef.current?.blur();
      }
    },
    [],
  );

  const suggestionInputProps = useMemo(
    () => ({
      value: searchText,
      onChange: (
        _: unknown,
        {
          newValue,
        }: {
          newValue: string;
        },
      ) => setSearchText(newValue),
      ref: inputRef,
      placeholder,
      onClickClose: resetSuggestion,
      onKeyDown: blurInputOnEscape,
    }),
    [blurInputOnEscape, placeholder, searchText, resetSuggestion],
  );

  /**
   * Deriving state from props
   * @see https://reactjs.org/docs/hooks-faq.html#how-do-i-implement-getderivedstatefromprops
   */
  if (suggestions !== users && searchText.length === 0) {
    setSuggestions(users);
  }

  return (
    <>
      {/* @ts-ignore */}
      <Dropdown icon={renderToggle} ref={dropdownRef}>
        {/* @ts-ignore */}
        <Dropdown.Menu width="250px">
          <SearchableUsersAutoSuggestStyles />
          <AutoSuggest
            id={id}
            suggestions={suggestions}
            onSuggestionsFetchRequested={updateSuggestionsBasedOnInput}
            onSuggestionSelected={selectSuggestion}
            getSuggestionValue={getSuggestionValue}
            // @ts-ignore
            renderInputComponent={renderInputComponent}
            renderSuggestion={renderSuggestion}
            inputProps={suggestionInputProps}
            alwaysRenderSuggestions
          />
          {/* @ts-ignore */}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
