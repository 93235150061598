import { defineMessages } from 'react-intl';

export default defineMessages({
  titlePage: {
    id: 'ManageAccountPage.titlePage',
    defaultMessage: 'Manage account',
  },

  // Change password section
  titleChangePassword: {
    id: 'ChangePasswordSection.titleChangePassword',
    defaultMessage: 'Change password',
  },
  labelPassword: {
    id: 'ChangePasswordSection.labelPassword',
    defaultMessage: 'Current password',
  },
  labelNewPassword: {
    id: 'ChangePasswordSection.labelNewPassword',
    defaultMessage: 'New password',
  },
  labelNewPasswordConfirmation: {
    id: 'ChangePasswordSection.labelNewPasswordConfirmation',
    defaultMessage: 'Repeat new password',
  },
  labelSavePassword: {
    id: 'ChangePasswordSection.labelSavePassword',
    defaultMessage: 'Save new password',
  },
  messagePasswordMatchError: {
    id: 'ChangePasswordSection.messagePasswordMatchError',
    defaultMessage: "Passwords don't match",
  },
  messageEqualPasswordError: {
    id: 'ChangePasswordSection.messageEqualPasswordError',
    defaultMessage: 'New password must be different from your current password',
  },

  // Change email section
  titleChangeEmail: {
    id: 'ChangePasswordSection.titleChangeEmail',
    defaultMessage: 'Change email',
  },
  labelEmail: {
    id: 'ChangePasswordSection.labelEmail',
    defaultMessage: 'Current email',
  },
  labelNewEmail: {
    id: 'ChangePasswordSection.labelNewEmail',
    defaultMessage: 'Enter a new email',
  },
  labelPasswordConfirmation: {
    id: 'ChangePasswordSection.labelPasswordConfirmation',
    defaultMessage: 'Confirm by entering your password',
  },
  labelSaveEmail: {
    id: 'ChangePasswordSection.labelSaveEmail',
    defaultMessage: 'Save new email',
  },
  messageEqualEmailError: {
    id: 'ChangePasswordSection.messageEqualEmailError',
    defaultMessage: 'New email must be different from your current email',
  },
});
