import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import debounce from 'lodash/debounce';
import { TextField } from '@picter/prisma';

import messages from '../messages';

export default function CreateItemComponent({ search, setSearch }) {
  const [localSearch, localSetSearch] = useState(search);
  const debouncedSetSearch = useMemo(() => debounce(setSearch, 300), [
    setSearch,
  ]);
  const handleSearch = useCallback(
    s => {
      localSetSearch(s);
      debouncedSetSearch(s);
    },
    [debouncedSetSearch],
  );

  return (
    <FormattedMessage {...messages.labelSearch}>
      {message => (
        <TextField
          minWidth="100%"
          onChange={e => handleSearch(e.target.value)}
          placeholder={message}
          textStyle="body.regular"
          value={localSearch}
        />
      )}
    </FormattedMessage>
  );
}

CreateItemComponent.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func.isRequired,
};

CreateItemComponent.defaultProps = {
  search: undefined,
};
