import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';

const CommentFooter = styled.footer`
  align-items: center;
  display: flex;
  margin-left: ${themeGet('space.6')}px;
`;

export default CommentFooter;
