import styled from 'styled-components/macro';

const ItemLI = styled.li`
  display: flex;

  .bullet {
    font-size: ${props => props.theme.fontSizes[1]}px;
    margin-right: ${props => props.theme.space[2]}px;
  }
`;

export default ItemLI;
