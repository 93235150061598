import { number, shape, string } from 'prop-types';

import { formattedMessage } from 'src/utils/app-prop-types';

export const taskShape = shape({
  key: string,
  index: number,
  title: formattedMessage.isRequired,
  description: formattedMessage.isRequired,
});
