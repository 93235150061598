import styled from 'styled-components/macro';

import Suggestion from './Suggestion';

function attrsGenerator(props: { shouldDisplayBlockSuggestions: boolean }) {
  return {
    shouldDisplayBlockSuggestions: props.shouldDisplayBlockSuggestions,
  };
}

const SearchFieldWrapper = styled.div.attrs(attrsGenerator)`
  .react-autosuggest__container {
    position: relative;
    box-sizing: border-box;
  }

  .global-search-input-wrapper {
    ::before {
      top: 14px;
    }
  }

  .react-autosuggest__input {
    box-sizing: border-box;
    display: block;
    width: 100%;
    appearance: none;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    height: ${props => props.theme.space[7]}px;
    background-color: ${props => props.theme.colors.white};
    border: none;

    &:active {
      border: none;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .react-autosuggest__input--open {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .react-autosuggest__suggestions-container {
    position: absolute;
    background: white;
    border-top: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding: 0;
    top: 100%;
    width: 100%;
    display: none;
    z-index: 99999999;
  }

  .react-autosuggest__suggestions-container--open {
    display: ${props =>
      props.shouldDisplayBlockSuggestions ? 'block' : 'contents'};
  }

  .react-autosuggest__suggestions-list {
    list-style: none;
    border: none;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
    padding: 0;
    margin: 0;
    background-color: ${props => props.theme.colors.white};

    li:last-child ${Suggestion} {
      border-radius: 0 0 8px 8px;
      border-bottom: solid 1px ${props => props.theme.colors.grey[300]};
    }
  }

  .react-autosuggest__suggestion--highlighted {
    color: #6800c3;
    background: rgba(104, 0, 195, 0.05);
  }
`;

export default SearchFieldWrapper;
