import { useLocation, useParams } from 'react-router-dom';

import {
  // comment urls
  libraryProjectCommentUrl,
  libraryProjectImageCommentUrl,
  libraryProjectInfoCommentUrl,
  libraryProjectCollectionCommentUrl,
  libraryProjectCollectionImageCommentUrl,

  // comments urls
  libraryProjectCommentsUrl,
  libraryProjectImageCommentsUrl,
  libraryProjectInfoCommentsUrl,
  libraryProjectCollectionCommentsUrl,
  libraryProjectCollectionImageCommentsUrl,
} from 'src/routes/urls';
import { returnInCase } from 'src/utils/in-case';

export function useCommentUrlBuilder() {
  const { collectionId, imageId, projectId: projectPublicKey } = useParams();
  const { pathname, query } = useLocation();

  const isOnCollectionPage = !!collectionId;
  const isOnImagePage = !!imageId;
  const isOnInfoSection = pathname.includes('/info');

  const urlBuilder = returnInCase({
    [isOnCollectionPage]: () =>
      returnInCase({
        [isOnImagePage]: () => libraryProjectCollectionImageCommentUrl,
        default: () => libraryProjectCollectionCommentUrl,
      }),
    [isOnImagePage]: () => libraryProjectImageCommentUrl,
    default: () =>
      returnInCase({
        [isOnInfoSection]: () => libraryProjectInfoCommentUrl,
        default: () => libraryProjectCommentUrl,
      }),
  });

  return ids =>
    urlBuilder.builder(
      urlBuilder.placeholder,
      query,
    )({ collectionId, imageId, projectId: projectPublicKey, ...ids });
}

export function useCommentsUrlBuilder() {
  const { collectionId, imageId, projectId: projectPublicKey } = useParams();
  const { pathname, query } = useLocation();

  const isOnCollectionPage = !!collectionId;
  const isOnImagePage = !!imageId;
  const isOnInfoSection = pathname.includes('/info');

  const urlBuilder = returnInCase({
    [isOnCollectionPage]: () =>
      returnInCase({
        [isOnImagePage]: () => libraryProjectCollectionImageCommentsUrl,
        default: () => libraryProjectCollectionCommentsUrl,
      }),
    [isOnImagePage]: () => libraryProjectImageCommentsUrl,
    default: () =>
      returnInCase({
        [isOnInfoSection]: () => libraryProjectInfoCommentsUrl,
        default: () => libraryProjectCommentsUrl,
      }),
  });

  return ids =>
    urlBuilder.builder(
      urlBuilder.placeholder,
      query,
    )({ collectionId, imageId, projectId: projectPublicKey, ...ids });
}
