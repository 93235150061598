import styled, { css } from 'styled-components/macro';
import { variants } from '@picter/prisma';
import themeGet from '@styled-system/theme-get';

import { Subheading } from 'src/modules/prisma';

import NavigationItemButton from './NavigationItemButton';
import NavigationItemLink from './NavigationItemLink';
import NavigationList from './NavigationList';

const densityVariant = variants('density', {
  comfortable: css`
    margin: ${props => props.theme.space[4]}px 0;
  `,
  compact: css`
    margin: ${props => props.theme.space[1]}px 0;
  `,
  default: css`
    margin: ${props => props.theme.space[2]}px 0;
  `,
});

const NavigationNav = styled.nav`
  width: 100%;

  ${Subheading /* NavigationHeader */} {
    margin-bottom: ${themeGet('space.2')}px;
    padding-left: ${themeGet('space.7')}px;
  }

  ${NavigationItemButton},
  ${NavigationItemLink} {
    ${densityVariant};
  }

  ${NavigationList} > li {
    ~ ${Subheading /* NavigationHeader */} {
      margin-top: ${themeGet('space.3')}px;
    }

    > ${NavigationItemLink} {
      padding-left: ${props => props.theme.space[7]}px;
    }

    > ${NavigationItemButton} {
      padding-left: ${props => props.theme.space[7]}px;
    }

    > ${NavigationList /* sc-sel */} > li {
      > ${NavigationItemLink} {
        padding-left: ${props => props.theme.space[7]}px;
      }
    }
  }
`;

export default NavigationNav;
