import { bool, func, shape } from 'prop-types';

const sidebarControlShape = shape({
  closeContextSidebar: func,
  openContextSidebar: func,
  closeMainSidebar: func,
  openMainSidebar: func,
  toggleSidebars: func,
  mainSidebarOpened: bool,
  contextSidebarOpened: bool,
});

export default sidebarControlShape;
