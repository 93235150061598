import styled from 'styled-components/macro';

import { common, content, textStyle, typography } from './mixins';
import Element from './Element';

const Text = styled(Element)`
  margin: 0;
  ${common};
  ${content};
  ${textStyle};
  ${typography};
`;

Text.defaultProps = {
  blacklist: [
    ...Element.defaultProps.blacklist,
    ...common.propNames,
    ...content.propNames,
    ...typography.propNames,
    ...textStyle.propNames,
  ],
  fontFamily: 'sansSerif',
  tag: 'span',
};

Text.displayName = 'Text';

export default Text;
