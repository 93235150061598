import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { loginUrl } from 'src/routes/urls';
import { useCurrentUser } from 'src/hooks/use-resource';
import useModal from 'src/hooks/use-modal';
import LiteUserRegisterModal from 'src/components/LiteUserRegisterModal';

import CommentForm from '../components/CommentForm';

const commentState = { text: null };

function CommentFormWithLiteUserSupport({ onSubmit, ...props }) {
  const user = useCurrentUser();
  const [comment, setComment] = useState(commentState);
  const [liteUserRegisterModal, { open: openLiteUserRegisterModal }] = useModal(
    <LiteUserRegisterModal
      onLoginRedirect={location => ({
        pathname: loginUrl(),
        search: `?redirectUrl=${[location.pathname, location.search].join('')}`,
        state: { comment },
      })}
      onRegister={async () => {
        await onSubmit(comment);
        setComment('');
      }}
    />,
  );

  const handleSubmit = useCallback(
    async text => {
      if (!user || user.isEmpty()) {
        setComment({ text });
        openLiteUserRegisterModal();
      } else {
        await onSubmit({ text });
      }
    },
    [user, onSubmit, openLiteUserRegisterModal, setComment],
  );

  return (
    <>
      <CommentForm {...props} onSubmit={handleSubmit} />
      {liteUserRegisterModal}
    </>
  );
}

CommentFormWithLiteUserSupport.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default CommentFormWithLiteUserSupport;
