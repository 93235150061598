import React, { useMemo } from 'react';
import { SWRConfig as OGSWRConfig } from 'swr';
import { $TSFixMe } from 'src/types';

export class FetchError extends Error {
  constructor(
    public message: string,
    public info?: object,
    public status?: number,
  ) {
    super(message);
  }
}

/**
 *
  @see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
  @see https://swr.vercel.app/docs/error-handling

  The Promise returned from fetch() won’t reject on HTTP error status even if
  the response is an HTTP 404 or 500. Instead, it will resolve normally
  (with ok status set to false), and it will only reject on network failure or
  if anything prevented the request from completing.
 */
export async function fetcher(url: $TSFixMe, options: $TSFixMe) {
  const res = await fetch(url, {
    credentials: 'include',
    ...options,
  });

  // status code is not in the range 200-299
  if (!res.ok) {
    const error = new FetchError('An error occurred while fetching data.');
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json(); // response body
}

export function SWRConfig({
  children,
  value,
}: React.PropsWithChildren<{ value: Record<string, unknown> }>) {
  const options = useMemo(() => (value ? { fetcher, ...value } : { fetcher }), [
    value,
  ]);
  return <OGSWRConfig value={options}>{children}</OGSWRConfig>;
}

export { default as useSWR, useSWRInfinite } from 'swr';
