import { useMemo } from 'react';

import useScript from 'src/hooks/use-script';

function useChargebee({ site }) {
  const scriptStatus = useScript('https://js.chargebee.com/v2/chargebee.js');
  const { Chargebee } = window;
  const instance = useMemo(() => {
    let chargebee;

    if (Chargebee && scriptStatus === 'ready') {
      try {
        // throws error if instance is not created
        chargebee = Chargebee.getInstance();
      } catch {
        chargebee = Chargebee.init({ site });
      }
    }

    return chargebee;
  }, [Chargebee, scriptStatus, site]);

  return instance;
}

export default useChargebee;
