import React, { memo, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Portal } from '@picter/prisma';
import md5 from 'md5';
import { Close as CloseSvg } from '@styled-icons/material';
import { FormattedMessage } from 'react-intl';
import throttle from 'lodash/throttle';
import { readableColor } from 'polished';

import { Body, Box, Icon, Panel } from 'src/modules/prisma';
import { formattedMessage } from 'src/utils/app-prop-types';
import { get } from 'src/utils/accessors';
import { hashToRange, IDENTICON_COLORS } from 'src/components/Avatar';
import { useCurrentUser } from 'src/hooks/use-resource';
import {
  useObservationModeControls,
  useObservationModeData,
} from 'src/components/ObservationMode';
import { useRealtimePresence } from 'src/modules/realtime';
import withSuspenseLoader from 'src/hocs/with-suspense-loader';

import messages from '../messages';

function ObservationModeIndicator() {
  const { members } = useRealtimePresence();
  const { observingId, observersIds } = useObservationModeData();

  const observingMember = useMemo(
    () =>
      observingId
        ? members.find(member => get(member, 'id') === observingId)
        : null,
    [members, observingId],
  );

  if (observingMember) {
    return <ObservingIndicator observed={observingMember} />;
  }

  if (observersIds.size > 0) {
    return <BeingObservedIndicator numberOfObservers={observersIds.size} />;
  }

  return null;
}

const ObservingIndicator = memo(({ observed }) => {
  const { unobserve } = useObservationModeControls();
  const observedName = get(observed, 'info.name');
  const observedEmail = get(observed, 'info.email');

  const emailHash = md5(observedEmail, { encoding: 'binary' });
  const hashRanged = hashToRange(emailHash, {
    max: IDENTICON_COLORS.length - 1,
  });
  const identiconColor = IDENTICON_COLORS[hashRanged];

  return (
    <Indicator
      color={identiconColor}
      label={observedName || observedEmail}
      onClickClose={unobserve}
      withOverlay
    />
  );
});

ObservingIndicator.propTypes = {
  observed: PropTypes.shape({
    info: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
};

const BeingObservedIndicator = memo(({ numberOfObservers }) => {
  const { purge } = useObservationModeControls();
  const user = useCurrentUser();
  const userEmail = get(user, 'attributes.email');

  const emailHash = md5(userEmail, { encoding: 'binary' });
  const hashRanged = hashToRange(emailHash, {
    max: IDENTICON_COLORS.length - 1,
  });
  const identiconColor = IDENTICON_COLORS[hashRanged];

  return (
    <Indicator
      color={identiconColor}
      label={
        <FormattedMessage
          {...messages.labelScreenObservedBy}
          values={{ numberOfObservers }}
        />
      }
      onClickClose={purge}
    />
  );
});

BeingObservedIndicator.propTypes = {
  numberOfObservers: PropTypes.number.isRequired,
};

function Overlay(props) {
  const elementRef = useRef();
  const timeoutRef = useRef();
  return (
    <Box
      bottom={0}
      left={0}
      position="fixed"
      right={0}
      top={0}
      ref={elementRef}
      onWheel={throttle(
        () => {
          elementRef.current.style.pointerEvents = 'none';

          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }

          timeoutRef.current = setTimeout(() => {
            elementRef.current.style.pointerEvents = 'all';
          }, 600);
        },
        600,
        { trailing: false },
      )}
      {...props}
    />
  );
}

function Indicator({ color, label, onClickClose, withOverlay }) {
  const textColor = readableColor(color, 'black', 'white');

  return (
    <Portal>
      {withOverlay && <Overlay onClick={onClickClose} />}
      <Box
        backgroundColor={color}
        display="flex"
        height="3px"
        justifyContent="center"
        left={0}
        position="fixed"
        right={0}
        top={0}
      >
        <Panel
          alignItems="center"
          backgroundColor={color}
          borderRadius="0 0 .2em .2em"
          height="min-content"
          display="flex"
          p={1}
        >
          <Body
            color={textColor}
            fontWeight="regular"
            lineHeight="1em"
            textSize="xsmall"
          >
            {label}
          </Body>
          <Box display="flex" ml={1}>
            <Icon
              color={textColor}
              onClick={onClickClose}
              size="xsmall"
              type={<CloseSvg />}
            />
          </Box>
        </Panel>
      </Box>
    </Portal>
  );
}

Indicator.defaultProps = {
  withOverlay: false,
};

Indicator.propTypes = {
  color: PropTypes.string.isRequired,
  label: formattedMessage.isRequired,
  onClickClose: PropTypes.func.isRequired,
  withOverlay: PropTypes.bool,
};

export default withSuspenseLoader({
  ErrorComponent: null,
  LoadingComponent: null,
})(ObservationModeIndicator);
