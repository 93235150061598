import styled from 'styled-components/macro';
import { Anchor } from '@picter/prisma';

import { Box } from 'src/modules/prisma';

const ShareableItemBoxContainer = styled(Box).attrs({
  alignItems: 'center',
  display: 'flex',
  gmt: 1,
  p: 4,
  width: 1,
})`
  background-color: ${props => props.theme.colors.white};
  border: solid 1px ${props => props.theme.colors.grey[200]};

  ${
    Anchor /* TODO: Remove after https://github.com/picter/prisma/issues/644 is fixed */
  } {
    line-height: 1.15em;
  }
`;

export default ShareableItemBoxContainer;
