import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { Heading } from 'src/modules/prisma';
import useDocumentTitle from 'src/hooks/use-document-title';
import { useSpace } from 'src/hooks/use-resource';
import withSuspenseLoader from 'src/hocs/with-suspense-loader';
import { SpaceResource } from 'src/types/resources';
import { tGet } from 'src/utils/accessors';

import Layout from './_layout';
import messages from './_messages';
import SubscriptionManagement from './_components/SubscriptionManagement';
import PartnerLicenseManagement from './_components/PartnerLicenseManagement';

function SubscriptionPage({
  match: {
    params: { spaceId },
  },
}: RouteComponentProps<{ spaceId: string }>) {
  const space: SpaceResource = useSpace({
    id: spaceId,
    include: ['plan.features', 'partnerLicense'],
  });

  const spacePlan = tGet(space, 'relationships', 'plan');
  const spacePartnerLicense = tGet(space, 'relationships', 'partnerLicense');

  useDocumentTitle('Subscription');

  return (
    <Layout>
      <Layout.Top>
        <Heading tag="h1" fontWeight="light" textSize="regular">
          <FormattedMessage {...messages.titleSubscriptionPage} />
        </Heading>
      </Layout.Top>
      <Layout.Content>
        {tGet(spacePartnerLicense, 'id') ? (
          <PartnerLicenseManagement
            plan={spacePlan}
            partnerLicense={spacePartnerLicense}
          />
        ) : (
          <SubscriptionManagement space={space} />
        )}
      </Layout.Content>
    </Layout>
  );
}

export default withSuspenseLoader({
  ErrorComponent: null,
  LoadingComponent: null,
})(SubscriptionPage);
