import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, Switch, Route, RouteComponentProps } from 'react-router-dom';
import { ArrowBack as ArrowBackSvg } from '@styled-icons/material';
import { TrackerScope } from '@picter/tracker';

import {
  Box,
  Icon,
  Panel,
  Heading,
  Body,
  Grid,
  Spacer,
} from 'src/modules/prisma';
import useDocumentTitle from 'src/hooks/use-document-title';
import { UserResource } from 'src/types/resources';
import {
  librarySpaceUrl,
  librarySpaceTaskUrl,
  librarySpaceTasksUrl,
} from 'src/routes/urls';
import paths from 'src/routes/paths';
import useNavigate from 'src/hooks/use-navigate';

import { useCurrentUser } from 'src/hooks/use-resource';
import { tGet } from 'src/utils/accessors';
import { Layout, LayoutSidebar, LayoutContent } from './_layout';
import messages from './_messages';
import TaskPage from './{taskId}';
import TaskAnnotationProvider from './_components/TaskAnnotationProvider';
import AssigneeFilterDropdown, {
  ASSIGNEE_FILTER_QUERY_KEY,
} from './_components/AssigneeFilterDropdown';
import SortByDropdown, {
  SortByOptions,
  SORT_BY_QUERY_KEY,
} from './_components/SortByDropdown';
import ResolvedFilterDropdown, {
  ResolvedFilterOptions,
  RESOLVED_FILTER_QUERY_KEY,
} from './_components/ResolvedFilterDropdown';
import TasksList from './_components/TasksList';

const { LIBRARY_SPACE_TASK_PATH } = paths as {
  LIBRARY_SPACE_TASK_PATH: string;
};

export default function TasksPage({
  location: { query },
  match: {
    params: { spaceId, taskId },
  },
}: RouteComponentProps<{ spaceId: string; taskId: string }>) {
  const user: UserResource = useCurrentUser();
  const assigneeFilterQuery: string = query[ASSIGNEE_FILTER_QUERY_KEY];
  const commentSortByQuery: SortByOptions =
    query[SORT_BY_QUERY_KEY] || 'created_at.desc';
  const resolvedFilterQuery: ResolvedFilterOptions = query[
    RESOLVED_FILTER_QUERY_KEY
  ]
    ? query[RESOLVED_FILTER_QUERY_KEY] === 'true' // parse boolean value
    : undefined;

  /**
   * Subscriptions
   */
  const intl = useIntl();
  const navigate = useNavigate();

  const taskUrlBuilder = taskId ? librarySpaceTaskUrl : librarySpaceTasksUrl;

  useDocumentTitle(intl.formatMessage(messages.titleTasks));

  useEffect(
    () => {
      navigate(taskUrlBuilder({ spaceId, taskId }), {
        replace: true,
        query: {
          /* Default values for filters */
          [ASSIGNEE_FILTER_QUERY_KEY]: tGet(user, 'id'),
          [RESOLVED_FILTER_QUERY_KEY]: 'false',
          ...query,
        },
      });
    },
    // Only necessary when component page loads
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <TrackerScope name="Tasks">
      <Layout>
        <TaskAnnotationProvider taskId={taskId}>
          <LayoutSidebar>
            <Panel borderBottom="1px solid" borderBottomColor="grey.200" p={4}>
              <Box alignItems="center" display="flex" flexDirection="row">
                <Link to={librarySpaceUrl({ spaceId })}>
                  <Icon
                    backgroundColor="white"
                    boxSize="large"
                    color="grey.600"
                    size="medium"
                    type={<ArrowBackSvg />}
                    interactive
                  />
                </Link>
                <Spacer sx={2} />
                <Body fontWeight="regular" textSize="large">
                  <FormattedMessage
                    defaultMessage="Tasks"
                    id="TasksPage.titleTasks"
                  />
                </Body>
                <Spacer sx="auto" />
                <AssigneeFilterDropdown
                  spaceId={spaceId}
                  value={assigneeFilterQuery}
                  onChange={filter =>
                    navigate(taskUrlBuilder({ spaceId, taskId }), {
                      query: {
                        ...query,
                        [ASSIGNEE_FILTER_QUERY_KEY]: filter,
                      },
                    })
                  }
                />
                <Spacer sx={2} />
                <ResolvedFilterDropdown
                  value={resolvedFilterQuery}
                  onChange={filter =>
                    navigate(taskUrlBuilder({ spaceId, taskId }), {
                      query: {
                        ...query,
                        [RESOLVED_FILTER_QUERY_KEY]: filter,
                      },
                    })
                  }
                />
                <Spacer sx={2} />
                <SortByDropdown
                  value={commentSortByQuery}
                  onChange={sort =>
                    navigate(taskUrlBuilder({ spaceId, taskId }), {
                      query: {
                        ...query,
                        [SORT_BY_QUERY_KEY]: sort,
                      },
                    })
                  }
                />
              </Box>
            </Panel>
            <Box position="relative" height="100%">
              <TasksList
                assigneeFilter={assigneeFilterQuery}
                resolvedFilter={resolvedFilterQuery}
                commentSortBy={commentSortByQuery}
                taskId={taskId}
                spaceId={spaceId}
              />
            </Box>
          </LayoutSidebar>
          <LayoutContent>
            <Switch>
              <Route path={LIBRARY_SPACE_TASK_PATH} component={TaskPage} />
              <Route
                render={() => (
                  <Grid display="grid" placeItems="center" height="100%">
                    <Box
                      alignItems="center"
                      display="flex"
                      flexDirection="column"
                    >
                      <Heading fontWeight="light">
                        <FormattedMessage {...messages.titleNoTaskSelected} />
                      </Heading>
                      <Spacer sy={2} />
                      <Body color="grey.600">
                        <FormattedMessage {...messages.messageNoTaskSelected} />
                      </Body>
                    </Box>
                  </Grid>
                )}
              />
            </Switch>
          </LayoutContent>
        </TaskAnnotationProvider>
      </Layout>
    </TrackerScope>
  );
}
