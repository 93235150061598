import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

/**
 * @see https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
 */

export function useClientRect<T extends Element>() {
  const [rect, setRect] = useState<DOMRect | null>(null);
  const ref = useCallback((node: T | null) => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);
  return [rect, ref] as const;
}

export function useObserveClientRect<T extends Element>() {
  const [rect, setRect] = useState<DOMRect | null>(null);
  const ref = useRef<T | null>(null);
  const observer = useMemo(
    () =>
      new ResizeObserver(entries => {
        window.requestAnimationFrame(() =>
          entries.forEach(entry =>
            setRect(entry.target.getBoundingClientRect()),
          ),
        );
      }),
    [],
  );

  useEffect(() => {
    if (
      typeof ref !== 'object' ||
      ref === null ||
      !(ref.current instanceof Element)
    ) {
      return undefined;
    }

    const { current: element } = ref;

    observer.observe(element);
    setRect(element.getBoundingClientRect());

    return () => {
      observer.unobserve(element);
      setRect(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return [rect, ref] as const;
}
