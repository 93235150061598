import styled from 'styled-components/macro';

import Element from './Element';
import { system } from './mixins';

/**
 * @see https://styled-system.com/guides/spacing/#spacer-component
 */
const Spacer = styled(Element)`
  ${system({
    s: {
      properties: ['marginTop', 'marginLeft'],
      scale: 'space',
    },
    sx: {
      properties: ['marginLeft'],
      scale: 'space',
    },
    sy: {
      properties: ['marginTop'],
      scale: 'space',
    },
  })}
`;

Spacer.displayName = 'Spacer';
Spacer.defaultProps = {
  blacklist: [...Element.defaultProps.blacklist, 's', 'sx', 'sy'],
  tag: 'span',
};

export default Spacer;
