import React, { useCallback } from 'react';

import { useModalElement, useModalState } from 'src/hooks/use-modal';
import useUpdateResourceFieldHandler from 'src/hooks/use-update-resource-field-handler';
import { get } from 'src/utils/accessors';

import EditResourceFieldModal from './components/EditResourceFieldModal';

function useEditResourceFieldModal(options) {
  const {
    element,
    field,
    resource,
    querySerializers,
    title,
    ...opts
  } = options;

  const { update: updateResourceField } = useUpdateResourceFieldHandler({
    resource,
    querySerializers,
  });

  const modalState = useModalState();
  const { close: closeModal, visible: visibleModal } = modalState;

  const handleSave = useCallback(
    async (...params) => {
      await updateResourceField(...params);
      closeModal();
    },
    [closeModal, updateResourceField],
  );

  const modalElement = useModalElement(
    <EditResourceFieldModal
      element={element}
      field={field}
      onCancel={closeModal}
      onSave={handleSave}
      open={visibleModal}
      title={title}
      value={get(resource, `attributes.${field}`) || ''}
      {...opts}
    />,
    modalState,
  );

  return [modalElement, modalState];
}

export default useEditResourceFieldModal;
