import Cookies from 'js-cookie';

import { viewModeTypes } from 'src/routes/constants';

export default function injectPublicLinkKey(config, match) {
  const { viewMode, projectId } = match.params || {};

  if (viewMode === viewModeTypes.DEFAULT) {
    return config;
  }

  const { publicKey, querySerializers, ...destructedConfig } = config;

  const isRequestFilesRoute =
    match.params.viewMode === viewModeTypes.REQUEST_FILES;
  const collectionCookie =
    isRequestFilesRoute && Cookies.get(`inbox-key/${projectId}`);
  const [, inboxLinkKey] = collectionCookie ? collectionCookie.split(':') : [];

  const { publicKey: _, ...querySerializersRest } = querySerializers || {};

  return {
    ...destructedConfig,
    publicLink: projectId,
    querySerializers: {
      ...querySerializersRest,
      publicLinkKey: () => `publicLinkKey=${projectId}`,
      inboxLinkKey: () =>
        inboxLinkKey !== undefined ? `inboxLinkKey=${inboxLinkKey}` : undefined,
    },
  };
}

export function replacePublicLinkKey(string, match) {
  const { viewMode, projectId } = match.params || {};

  if (viewMode === viewModeTypes.DEFAULT) {
    return string;
  }

  return string.replace(
    /publicKey=([^&|\n|\t\s]+)/,
    `publicLinkKey=${projectId}`,
  );
}
