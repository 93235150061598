import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'getstream';
import * as Sentry from '@sentry/react';
import debounce from 'lodash/debounce';
import { Map } from 'immutable';

import { useCurrentUser } from 'src/hooks/use-resource';
import { get } from 'src/utils/accessors';

import ACTIONS_TYPES from './constants';
import reducer from './reducer';

export const NotificationsContext = React.createContext();

// eslint-disable-next-line react/prop-types
const NotificationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {});
  const user = useCurrentUser();

  return (
    <NotificationsMethodsProvider state={state} user={user} dispatch={dispatch}>
      {children}
    </NotificationsMethodsProvider>
  );
};

class NotificationsMethodsProvider extends React.Component {
  constructor(props) {
    super(props);
    const { user } = this.props;

    this.userClient = connect(
      process.env.REACT_APP_STREAM_IO_API_KEY,
      get(user, 'attributes.notificationFeedToken'),
      process.env.REACT_APP_STREAM_IO_APP_ID,
    );

    if (get(user, 'id')) {
      this.userFeed = this.userClient.feed(
        'user_notifications',
        get(user, 'id'),
      );
    }
  }

  componentDidMount() {
    const { user } = this.props;
    if (get(user, 'id')) {
      this.subscribe({ limit: 10 });
    }
  }

  onRealtimeUpdate = (data, config) => {
    if (data.new.length > 0) {
      this.loadNotifications(config);
    } else {
      this.updateNotifications();
    }
  };

  fetchNotifications = async ({ query, limit } = {}) => {
    let url = `${process.env.REACT_APP_API_URL}/app-workspace/ws-notifications`;

    if (limit) {
      url = `${url}?limit=${limit}`;
    }

    if (query) {
      url = `${url}${limit ? '&' : '?'}${query}`;
    }

    const res = await fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res.json();
  };

  loadOlderNotifications = async ({ idLT }, config) => {
    const { state, dispatch } = this.props;

    if (state.hasMore) {
      const data = await this.fetchNotifications({
        ...config,
        query: `id_lt=${idLT}`,
      });
      dispatch({
        type: ACTIONS_TYPES.SET_OLDER_ACTIVITIES,
        payload: { data },
      });
    }
  };

  loadNewerNotifications = async ({ idGT }, config) => {
    const { dispatch } = this.props;

    const data = await this.fetchNotifications({
      ...config,
      query: `id_gt=${idGT}`,
    });

    dispatch({
      type: ACTIONS_TYPES.SET_NEWER_ACTIVITIES,
      payload: { data },
    });
    return data;
  };

  loadNotifications = async config => {
    const { state, dispatch } = this.props;
    if (!get(state, 'items.length')) {
      const data = await this.fetchNotifications(config);
      dispatch({
        type: ACTIONS_TYPES.SET_INITIAL_ACTIVITIES,
        payload: { data },
      });
      return data;
    }
    const { items } = state;
    const lastItem = items[0];
    return this.loadNewerNotifications({ idGT: lastItem.id }, config);
  };

  subscribe = config => {
    const debouncedOnRealtimeUpdate = debounce(this.onRealtimeUpdate, 300);

    const callback = data => {
      debouncedOnRealtimeUpdate(data, config);
    };

    const onSuccess = () => {
      this.loadNotifications(config);
    };

    this.userFeed
      .subscribe(callback)
      .then(onSuccess)
      .catch(e => Sentry.captureException(e));

    return () => {
      this.userFeed.unsubscribe();
      this.userClient.fayeClient.disconnect();
    };
  };

  updateNotifications = async () => {
    const { dispatch } = this.props;
    const data = await this.fetchNotifications();
    return dispatch({
      type: ACTIONS_TYPES.SET_INITIAL_ACTIVITIES,
      payload: { data },
    });
  };

  markAsRead = async id => {
    if (Array.isArray(id)) {
      throw new Error(
        'For now this method only supports a single group for performance reasons',
      );
    }
    const { dispatch } = this.props;
    await this.userFeed.get({ mark_read: id });
    return dispatch({
      type: ACTIONS_TYPES.SET_ACTIVITY_AS_READ,
      payload: { data: { id } },
    });
  };

  markAllAsRead = async () => {
    const { state, dispatch } = this.props;
    if (state.unread) {
      await this.userFeed.get({ mark_read: true });
      return dispatch({
        type: ACTIONS_TYPES.SET_ACTIVITIES_AS_READ,
      });
    }
    return null;
  };

  markAllAsSeen = async () => {
    const { state, dispatch } = this.props;
    if (state.unseen) {
      await this.userFeed.get({ mark_seen: true });
      return dispatch({
        type: ACTIONS_TYPES.SET_ACTIVITIES_AS_SEEN,
      });
    }
    return null;
  };

  render() {
    const { state, children } = this.props;

    return (
      <NotificationsContext.Provider
        value={{
          state,
          loadInitialNotifications: this.loadNotifications,
          loadOlderNotifications: this.loadOlderNotifications,
          loadNewerNotifications: this.loadNewerNotifications,
          subscribe: this.subscribe,
          markAsRead: this.markAsRead,
          markAllAsRead: this.markAllAsRead,
          markAllAsSeen: this.markAllAsSeen,
        }}
      >
        {children}
      </NotificationsContext.Provider>
    );
  }
}

NotificationsMethodsProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object.isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default NotificationsProvider;
