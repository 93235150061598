import React from 'react';
import PropTypes from 'prop-types';
import { TrackerScope, withTracker } from '@picter/tracker';

import CustomDragLayer from 'src/containers/ImageCard/components/CustomDragLayer';
import withSuspenseLoader from 'src/hocs/with-suspense-loader';
import { PresenceProvider } from 'src/modules/realtime';
import { useProjectChannelName } from 'src/hooks/use-realtime-channel-name';
import {
  ObservationModeIndicator,
  ObservationModeProvider,
} from 'src/components/ObservationMode';

import { useTrackPublicLinkView } from './_hooks';

const ProjectPresenceProvider = withSuspenseLoader()(({ children }) => {
  const projectChannelName = useProjectChannelName();

  return (
    <PresenceProvider channel={projectChannelName}>{children}</PresenceProvider>
  );
});

const TrackedObservationModeProvider = withTracker()(
  ({ tracker, ...props }) => (
    <ObservationModeProvider
      {...props}
      onObserve={({ observingChannelUser }) =>
        tracker.track('Observation mode started', {
          observedUser: observingChannelUser,
        })
      }
      onUnobserve={({ observingChannelUser }) =>
        tracker.track('Observation mode stopped', {
          observedUser: observingChannelUser,
        })
      }
    />
  ),
);

function Document({ children }) {
  useTrackPublicLinkView();

  return (
    <TrackerScope name="Project">
      <ProjectPresenceProvider>
        <TrackedObservationModeProvider>
          <CustomDragLayer />
          <ObservationModeIndicator />
          {children}
        </TrackedObservationModeProvider>
      </ProjectPresenceProvider>
    </TrackerScope>
  );
}

Document.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Document;
