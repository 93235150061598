import React from 'react';
import { Route, Switch } from 'react-router-dom';

import paths from 'src/routes/paths';
import { ImageProofFilter } from 'src/utils/accessors/project-image';
import usePermission from 'src/hooks/use-permission';

import { libraryProjectsUrl } from 'src/routes/urls';
import { viewModeTypes } from 'src/routes/constants';

import EmptyCollectionBox from './components/EmptyCollectionBox';
import EmptyFilterBox from './components/EmptyFilterBox';
import EmptyProjectBox from './components/EmptyProjectBox';
import EmptyRequestFilesBox from './components/EmptyRequestFilesBox';
import EmptyPresentBox from './components/EmptyPresentBox';
import EmptyReviewBox from './components/EmptyReviewBox';

const { LIBRARY_PROJECT_COLLECTION_PATH } = paths;

const EmptyImageGrid = () => {
  const { canAddImages } = usePermission();

  return (
    <Switch>
      <Route
        path={LIBRARY_PROJECT_COLLECTION_PATH}
        render={({
          location: {
            query: { filter },
          },
        }) => {
          if (filter && filter !== ImageProofFilter.ALL) {
            return <EmptyFilterBox filter={filter} />;
          }

          return <EmptyCollectionBox />;
        }}
      />
      <Route
        path={libraryProjectsUrl({ viewMode: viewModeTypes.REQUEST_FILES })}
        render={props => (
          <EmptyRequestFilesBox {...props} showAddImage={canAddImages} />
        )}
      />
      <Route
        path={libraryProjectsUrl({ viewMode: viewModeTypes.PRESENT })}
        component={EmptyPresentBox}
      />
      <Route
        path={libraryProjectsUrl({ viewMode: viewModeTypes.REVIEW })}
        render={({
          location: {
            query: { filter },
          },
        }) => {
          if (filter && filter !== ImageProofFilter.ALL) {
            return <EmptyFilterBox filter={filter} />;
          }

          return <EmptyReviewBox />;
        }}
      />
      <Route
        path={libraryProjectsUrl({ viewMode: viewModeTypes.DEFAULT })}
        render={({
          location: {
            query: { filter },
          },
        }) => {
          if (filter && filter !== ImageProofFilter.ALL) {
            return <EmptyFilterBox filter={filter} />;
          }

          return <EmptyProjectBox showAddImage={canAddImages} />;
        }}
      />
    </Switch>
  );
};

export default EmptyImageGrid;
