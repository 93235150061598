import {
  ANNOTATION_IN_PROGRESS_ID,
  AnnotationId,
  AnnotationMeta,
  Annotation,
} from './models/Annotation';
import { Markup } from './models/Markup';

export type AnnotationsState = {
  annotations: Map<AnnotationId, Annotation>;
  isDrawing: boolean;
  highlightedId: AnnotationId | null;
  selectedId: AnnotationId | null;
};

export type AnnotationsAction =
  | { type: 'ANNOTATIONS_CREATE'; payload: { markups: Markup[] } }
  | { type: 'ANNOTATIONS_DESELECT' }
  | { type: 'ANNOTATIONS_HIGHLIGHT'; payload: { id: AnnotationId } }
  | {
      type: 'ANNOTATIONS_MERGE';
      payload: Pick<AnnotationsState, 'annotations'>;
    }
  | {
      type: 'ANNOTATIONS_META';
      payload: { id: AnnotationId; data: AnnotationMeta };
    }
  | { type: 'ANNOTATIONS_REMOVE'; payload: { id: AnnotationId } }
  | { type: 'ANNOTATIONS_RESET'; payload: AnnotationsState }
  | { type: 'ANNOTATIONS_SELECT'; payload: { id: AnnotationId } }
  | { type: 'ANNOTATIONS_DRAWING'; payload: boolean };

export default function annotationsReducer(
  state: AnnotationsState,
  action: AnnotationsAction,
): AnnotationsState {
  const { annotations } = state;

  switch (action.type) {
    case 'ANNOTATIONS_CREATE': {
      const { markups } = action.payload;
      const annotation = new Annotation(ANNOTATION_IN_PROGRESS_ID, markups);
      const newAnnotations = new Map(annotations);
      newAnnotations.set(annotation.id, annotation);

      return {
        ...state,
        annotations: newAnnotations,
      };
    }
    case 'ANNOTATIONS_REMOVE': {
      const { id } = action.payload;
      const newAnnotations = new Map(annotations);
      newAnnotations.delete(id);

      return {
        ...state,
        annotations: newAnnotations,
      };
    }
    case 'ANNOTATIONS_HIGHLIGHT': {
      const { id } = action.payload;
      const { highlightedId } = state;

      return {
        ...state,
        highlightedId: highlightedId === id ? null : id,
      };
    }
    case 'ANNOTATIONS_SELECT': {
      const { id } = action.payload;

      return {
        ...state,
        selectedId: id,
      };
    }
    case 'ANNOTATIONS_DESELECT': {
      return {
        ...state,
        selectedId: null,
      };
    }
    case 'ANNOTATIONS_DRAWING': {
      return {
        ...state,
        isDrawing: action.payload,
      };
    }
    case 'ANNOTATIONS_MERGE': {
      const { annotations: stateAnnotations, ...otherState } = state;
      const { annotations: payloadAnnotations } = action.payload;

      const annotationInProgress = stateAnnotations.get(
        ANNOTATION_IN_PROGRESS_ID,
      );

      // do not remove annotation currently being made when merging
      if (annotationInProgress instanceof Annotation) {
        return {
          ...otherState,
          annotations: new Map([
            [ANNOTATION_IN_PROGRESS_ID, annotationInProgress],
            ...Array.from(payloadAnnotations),
          ]),
        };
      }

      return {
        ...otherState,
        annotations: payloadAnnotations,
      };
    }
    case 'ANNOTATIONS_META': {
      const { id, data } = action.payload;

      const annotation = annotations.get(id);

      if (annotation instanceof Annotation) {
        const newAnnotation = Annotation.clone(annotation);
        newAnnotation.meta = data;

        const newAnnotations = new Map(annotations);
        newAnnotations.set(newAnnotation.id, newAnnotation);

        return {
          ...state,
          annotations: newAnnotations,
        };
      }

      return state;
    }
    case 'ANNOTATIONS_RESET': {
      return action.payload;
    }
    default:
      return state;
  }
}
