import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Delete as DeleteSvg } from '@styled-icons/material';

import { Dropdown } from 'src/modules/prisma';

import { ActionsDropdownContext } from './ActionsDropdownProvider';
import ActionsDropdownItem from './ActionsDropdownItem';
import messages from '../messages';

const ActionsDropdownDelete = ({
  confirm,
  confirmTitle,
  confirmLabel,
  confirmMessage,
  entity,
  onClick,
  ...props
}) => {
  const { closeMenu } = useContext(Dropdown.Context);
  const { setDeleteModalFor } = useContext(ActionsDropdownContext);

  const onClickCB = useCallback(async () => {
    await closeMenu();
    onClick();
  }, [closeMenu, onClick]);

  return (
    <ActionsDropdownItem
      action="delete"
      entity={entity}
      icon={<DeleteSvg />}
      label={<FormattedMessage {...messages.labelDelete} />}
      {...props}
      onClick={
        confirm
          ? () => {
              setDeleteModalFor({
                entity,
                confirmFn: onClickCB,
                confirmTitle: confirmTitle || (
                  <FormattedMessage
                    {...messages.titleModalDelete}
                    values={{ entity }}
                  />
                ),
                confirmLabel,
                confirmMessage,
              });
              closeMenu();
            }
          : onClickCB
      }
    />
  );
};

ActionsDropdownDelete.propTypes = {
  entity: PropTypes.string,
  disabled: PropTypes.bool,
  confirm: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  confirmTitle: PropTypes.node,
  confirmLabel: PropTypes.node,
  confirmMessage: PropTypes.node,
};

ActionsDropdownDelete.defaultProps = {
  entity: null,
  confirm: true,
  disabled: false,
  confirmTitle: null,
  confirmLabel: <FormattedMessage {...messages.labelModalConfirm} />,
  confirmMessage: null,
};

export default ActionsDropdownDelete;
