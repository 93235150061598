import kebabCase from 'lodash/kebabCase';
import { requestAsPromise } from '../../json-api-middleware/actions';
import { asyncTypesArray } from '../../utils/action-types-helpers';
import formatRequestPayload from '../../utils/format-request-payload';
import addQueryParameters from '../../utils/add-query-parameters';
import { API_CREATE_DATA_TYPES } from '../../constants';

const create = (
  model,
  { attributes, relationships, querySerializers } = {},
  { prefix, baseUrl, postfix } = {},
) => {
  const endpoint = [kebabCase(model)];

  if (prefix) endpoint.unshift(prefix);
  if (postfix) endpoint.push(postfix);

  return dispatch =>
    requestAsPromise(dispatch, {
      types: asyncTypesArray(API_CREATE_DATA_TYPES),
      endpoint: addQueryParameters(endpoint.join('/'), {
        querySerializers,
      }),
      method: 'POST',
      payload: formatRequestPayload(model, { attributes, relationships }),
      meta: { type: model },
      baseUrl,
    });
};

export default create;
