import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import { locationShape } from 'src/utils/app-prop-types';
import { TrackerScope } from '@picter/tracker';
import { getPathMetaData } from 'src/routes/paths';

class PageViewTracker extends Component {
  shouldComponentUpdate({ location: newLocation }) {
    const { location: oldLocation } = this.props;
    return (
      oldLocation.pathname !== newLocation.pathname ||
      !isEqual(oldLocation.query, newLocation.query)
    );
  }

  render() {
    const { location } = this.props;
    const { isExact, track, ...metaData } = getPathMetaData(location.pathname);
    return track ? (
      <TrackerScope>
        {({ page }) => {
          page(
            ['Workspace', metaData.scope, `Viewed ${metaData.name}`].join(
              ' / ',
            ),
            Object.assign(metaData, { query: location.query }),
          );
          return null;
        }}
      </TrackerScope>
    ) : null;
  }
}

PageViewTracker.propTypes = {
  location: locationShape.isRequired,
};

export default withRouter(PageViewTracker);
