import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';

const NavigationItemButton = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  color: ${themeGet('colors.grey.600')};
  cursor: pointer;
  display: inline-flex;
  padding: 0;
  width: 100%;

  &:hover {
    color: ${themeGet('colors.black')};
  }
`;

export default NavigationItemButton;
