import React from 'react';
import ContentLoader from 'react-content-loader';
import { withTheme } from 'styled-components/macro';

import themeGet from '@styled-system/theme-get';

export const PreviewPlaceholder = withTheme(props => (
  <ContentLoader
    primaryColor={themeGet('colors.grey.100')(props)}
    secondaryColor={themeGet('colors.grey.300')(props)}
    height={200}
    width={200}
  >
    <rect x="0" y="0" rx="0" ry="0" width="200" height="200" />
  </ContentLoader>
));

export default PreviewPlaceholder;
