import React, { useMemo } from 'react';
import {
  Close as CloseSvg,
  ExpandMore as ExpandMoreSvg,
  ExpandLess as ExpandLessSvg,
} from '@styled-icons/material';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { TrackerEvent, TrackerScope } from '@picter/tracker';
import useSWR from 'swr';
import withSuspenseLoader from 'src/hocs/with-suspense-loader';

import {
  Accordion,
  Anchor,
  Box,
  Heading,
  Icon,
  Text,
} from 'src/modules/prisma';
import { get } from 'src/utils/accessors';
import IntercomLauncher from 'src/components/IntercomLauncher';
import ProgressBar from 'src/components/PrismaProgressBar';
import { returnInCase } from 'src/utils/in-case';
import { useCurrentUser } from 'src/hooks/use-resource';

import ActiveTask from './ActiveTask';
import CompletedTask from './CompletedTask';
import messages from '../messages';
import OnboardingChecklistPlaceholder from './OnboardingChecklistPlaceholder';
import { useLocalStorageItem } from '../hooks';

export const STORAGE_ITEM_KEY = 'picter__ocd';
const { REACT_APP_API_URL } = process.env;

function useOnboardingTasks(spaceId) {
  const { data: onboardingStatus } = useSWR(
    [REACT_APP_API_URL, 'app-workspace', 'onboarding-status', spaceId].join(
      '/',
    ),
    { suspense: true },
  );

  const {
    approvalsExist,
    invitationSentOut,
    singleUserWorkspace,
    uploadedFiles,
  } = onboardingStatus;

  return useMemo(() => {
    const tasks = [
      {
        key: 'addVisualFiles',
        completed: uploadedFiles,
        title: <FormattedMessage {...messages.titleAddVisualFiles} />,
        description: <FormattedMessage {...messages.messageAddVisualFiles} />,
      },
      {
        key: 'reviewAnImage',
        completed: approvalsExist,
        title: <FormattedMessage {...messages.titleReviewAnImage} />,
        description: <FormattedMessage {...messages.messageReviewAnImage} />,
      },
      !singleUserWorkspace && {
        key: 'inviteTeamMembers',
        completed: invitationSentOut,
        title: <FormattedMessage {...messages.titleInviteTeamMembers} />,
        description: (
          <FormattedMessage {...messages.messageInviteTeamMembers} />
        ),
      },
    ].filter(task => !!task);
    const numberOfCompletedTasks = tasks.reduce((tasksCompleted, task) => {
      const completed = tasksCompleted + (task.completed ? 1 : 0);
      return completed;
    }, 0);
    const progress = (numberOfCompletedTasks * 100) / tasks.length;
    const next = tasks.find(task => !task.completed);

    return {
      tasks,
      progress,
      next,
    };
  }, [approvalsExist, invitationSentOut, singleUserWorkspace, uploadedFiles]);
}

function OnboardingChecklist() {
  const user = useCurrentUser();
  const userId = get(user, 'id');
  const { spaceId } = useParams();
  const { tasks, progress, next } = useOnboardingTasks(spaceId);
  const [storageItem, setStorageItem] = useLocalStorageItem(STORAGE_ITEM_KEY);
  const STORAGE_ITEM_VALUE = [spaceId, userId].join('.');

  if (storageItem.includes(STORAGE_ITEM_VALUE)) return null;

  const headerStrongMessage = returnInCase({
    [progress === 0]: () => (
      <FormattedMessage {...messages.messageWelcomeToPicter} />
    ),
    [progress > 0]: () => <FormattedMessage {...messages.messageKeepGoing} />,
    [progress === 100]: () => (
      <FormattedMessage {...messages.messageWellDone} />
    ),
  });
  const headerMessage = returnInCase({
    [progress === 100]: () => (
      <FormattedMessage
        {...messages.messageLetsHaveChat}
        values={{
          intercom: (...chunks) => (
            <IntercomLauncher
              launcher={
                <Text style={{ cursor: 'pointer' }} tag="u">
                  {chunks}
                </Text>
              }
            />
          ),
        }}
      />
    ),
    default: () => <FormattedMessage {...messages.messageStepsPrepared} />,
  });

  return (
    <TrackerScope name="Onboarding checklist">
      <Accordion data-intercom-target="onboarding-checklist">
        <Accordion.Item>
          <Accordion.Item.Disclosure>
            {({ visible }) => (
              <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                pl={4}
                pr={3}
                pt={2}
                pb={3}
              >
                <Heading color="black" textAlign="left" textSize="small">
                  {headerStrongMessage}{' '}
                  <Text
                    color="grey.600"
                    fontFamily="sansSerif"
                    textStyle="heading.small"
                    fontWeight="light"
                  >
                    {headerMessage}
                  </Text>
                </Heading>
                {next ? (
                  <Anchor>
                    <Text whiteSpace="nowrap">
                      <FormattedMessage
                        {...messages.labelNextStep}
                        values={{
                          taskTitle: next.title,
                        }}
                      />
                      <Box display="inline-block" ml={1}>
                        <Icon
                          color="inherit"
                          size="small"
                          type={visible ? <ExpandLessSvg /> : <ExpandMoreSvg />}
                        />
                      </Box>
                    </Text>
                  </Anchor>
                ) : (
                  <Anchor
                    onClick={event => {
                      event.preventDefault();
                      setStorageItem(item => `${item}${STORAGE_ITEM_VALUE}`);
                    }}
                  >
                    <Text whiteSpace="nowrap">
                      <FormattedMessage {...messages.labelDismiss} />
                      <Box display="inline-block" ml={1}>
                        <Icon
                          color="inherit"
                          size="small"
                          type={<CloseSvg />}
                        />
                      </Box>
                    </Text>
                  </Anchor>
                )}
                <ProgressBar
                  bgColor="grey.200"
                  color="info"
                  position="absolute"
                  bottom={0}
                  left={0}
                  size={3}
                  progress={progress}
                />
              </Box>
            )}
          </Accordion.Item.Disclosure>
          <Accordion.Item.Content>
            {state =>
              state.visible && (
                <TrackerEvent name="Onboarding checklist" trackView>
                  <ol
                    style={{
                      listStyleType: 'none',
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {tasks.map((task, index) =>
                      task.completed ? (
                        <CompletedTask key={task.key} task={task} />
                      ) : (
                        <ActiveTask
                          key={task.key}
                          task={{ index: index + 1, ...task }}
                        />
                      ),
                    )}
                  </ol>
                </TrackerEvent>
              )
            }
          </Accordion.Item.Content>
        </Accordion.Item>
      </Accordion>
    </TrackerScope>
  );
}

OnboardingChecklist.displayName = 'OnboardingChecklist';

export default withSuspenseLoader({
  LoadingComponent: OnboardingChecklistPlaceholder,
})(OnboardingChecklist);
