import React from 'react';

import useModal from 'src/hooks/use-modal';
import ProjectDownloadModal from 'src/components/ProjectDownloadModal';
import { useOnClickInfoButton } from '../../../_shared/hooks/handlers';

const useHandlers = props => {
  const { project, images } = props;

  const [projectDownloadModal, projectDownloadModalState] = useModal(
    <ProjectDownloadModal project={project} images={images} />,
  );

  const onClickInfoButton = useOnClickInfoButton();

  return {
    projectDownloadModal,
    projectDownloadModalState,
    onClickInfoButton,
  };
};

export default useHandlers;
