import { defineMessages } from 'react-intl';

export default defineMessages({
  labelStop: {
    id: 'UploadIndicator.labelStop',
    defaultMessage: 'Stop',
  },
  messageUploadingImages: {
    id: 'UploadIndicator.messageUploadingImages',
    defaultMessage: 'Uploading files',
  },
  messageNumberOfImages: {
    id: 'UploadIndicator.messageNumberOfImages',
    defaultMessage: '{uploaded} of {total}',
  },
  messageUploadedBytes: {
    id: 'UploadIndicator.messageNumberOfImages',
    defaultMessage: '{uploaded} of {total}',
  },
});
