import React from 'react';
import { Check as CheckSvg } from '@styled-icons/material';

import { Text, Body, Box, Icon } from 'src/modules/prisma';

import TaskPanel from '../styles/TaskPanel';
import { taskShape } from '../utils';

function CompletedTask({ task: { title, description } }) {
  return (
    <TaskPanel>
      <Icon
        border="1px solid"
        borderColor="grey.400"
        boxSize="xxxlarge"
        color="info"
        size="xlarge"
        type={<CheckSvg />}
      />
      <Box ml={3}>
        <Text
          color="grey.600"
          textStyle="action.regular"
          textDecoration="line-through"
        >
          {title}
        </Text>
        <Body color="grey.600">{description}</Body>
      </Box>
    </TaskPanel>
  );
}

CompletedTask.propTypes = {
  task: taskShape.isRequired,
};

export default CompletedTask;
