/**
 * Format validation errors for redux-form
 * @param  {Object} error Body of api-response
 * @return {Object}       Correctly formated error-object for
 *                        redux-form's stopSubmit()-function
 */
export default function parseHttpError(error) {
  let { validationErrors } = error;
  if (validationErrors) {
    // join arrays of errors to single strings for each field
    Object.keys(error.validationErrors).forEach(key => {
      validationErrors[key] = validationErrors[key].join(', ');
    });
    // Add main error message to form
    validationErrors._error = 'Some fields are invalid'; // eslint-disable-line no-underscore-dangle
  } else {
    validationErrors = { _error: error.message };
  }
  return {
    ...validationErrors,
    statusCode: error.statusCode,
  };
}
