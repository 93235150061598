import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import EmptyStateBox from 'src/components/EmptyStateBox';
import EmptyRequestFilesIllustrationSvg from 'src/assets/EmptyRequestFilesIllustrationSvg';
import { useProject } from 'src/hooks/use-resource';
import { get } from 'src/utils/accessors';

import AddImageButton from './AddImageButton';
import messages from '../messages';

const EmptyRequestFilesBox = () => {
  const project = useProject({ include: ['space'] }, { request: false });
  const owner = get(project, 'relationships.space.attributes.name');

  return (
    <EmptyStateBox
      graphic={<EmptyRequestFilesIllustrationSvg />}
      title={
        <FormattedHTMLMessage {...messages.messageEmptyRequestFilesTitle} />
      }
      message={
        <FormattedHTMLMessage
          {...messages.messageEmptyRequestFilesMessage}
          values={{
            owner,
          }}
        />
      }
      action={<AddImageButton />}
      dashed
    />
  );
};

export default EmptyRequestFilesBox;
