import { List } from 'immutable';
import { getInversableRelationships, getInverseRelation } from '../../schema';

export default function destroyDataReducer(state, action, schemas) {
  const ids = action.meta.ids || [action.meta.id];

  return state.withMutations(mutableState => {
    // eslint-disable-next-line consistent-return
    ids.forEach(id => {
      const resourcePath = [action.meta.model, id];
      const immutableResource = state.getIn(resourcePath);

      if (!immutableResource) {
        return mutableState;
      }

      const resource = immutableResource.toJS();

      // get inversable relationships of the resource
      const inversableRelationships = getInversableRelationships(
        schemas && schemas[action.meta.model]
          ? schemas[action.meta.model]
          : undefined,
        resource,
      );

      mutableState.deleteIn(resourcePath);

      if (inversableRelationships) {
        inversableRelationships.forEach(([relationName, { data }]) => {
          // if there's no related data in the resource defined,
          // we can't update any inverse relationships
          if (!data) {
            return;
          }

          const relatedItems = Array.isArray(data) ? data : [data];
          const inverseRelation = getInverseRelation(
            schemas,
            action.meta.model,
            relationName,
            resource,
          );

          relatedItems.forEach(item => {
            const relationshipPath = [
              item.type,
              item.id,
              'relationships',
              inverseRelation.name,
              'data',
            ];

            if (inverseRelation.relation === 'has-many') {
              mutableState.updateIn(relationshipPath, (list = List()) =>
                list.filter(model => model.get('id') !== id),
              );
            } else {
              mutableState.setIn(relationshipPath, null);
            }
          });
        });
      }
    });
  });
}
