import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Link that works for external URL's as well
 */
const Link = ({ to, children, ...rest }) =>
  /^https?:\/\//.test(to) ? (
    <a href={to} {...rest}>
      {children}
    </a>
  ) : (
    <RRLink to={to} {...rest}>
      {children}
    </RRLink>
  );

Link.propTypes = {
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  children: PropTypes.node.isRequired,
};

export default Link;
