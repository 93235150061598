import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import {
  CharCounter,
  FieldBadge,
  HintMessage,
  FieldWrapper,
  CustomPropTypes,
} from '@picter/prisma';

import { TYPE_OPTIONS } from './constants';
import TextFieldArea from '../styles/TextFieldArea';
import TextFieldContainer from '../styles/TextFieldContainer';
import TextFieldInput from '../styles/TextFieldInput';
import TextFieldLabel from '../styles/TextFieldLabel';

const { refShape } = CustomPropTypes;

class TextField extends React.Component {
  constructor(props) {
    super(props);

    const { value } = this.props;

    this.state = {
      value,
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps({ value }) {
    const { value: stateValue } = this.state;
    if (stateValue !== value) {
      this.setState({ value: value || '' });
    }
  }

  handleChange = event => {
    const { value } = event.target;
    const { onChange } = this.props;

    this.setState({ value });

    if (onChange) {
      onChange(event);
    }
  };

  render() {
    const {
      id,
      className,
      autoFocus,
      disabled,
      error,
      hint,
      inputRef,
      icon,
      label,
      maxLength,
      noMargin,
      name,
      onBlur,
      placeholder,
      required,
      showBadge,
      textarea,
      type,
      inputProps,
    } = this.props;
    const { value } = this.state;
    const isTextArea = type === 'textarea';
    const TextInput = isTextArea ? TextFieldArea : TextFieldInput;

    return (
      <TextFieldContainer id={id} className={className} noMargin={noMargin}>
        <FieldWrapper
          className={`${name}-input-wrapper`}
          iconLeft={icon}
          withLabel={!!label}
        >
          <TextInput
            id={`${name}-input-${type}`}
            className={classNames({
              empty: !value,
              error,
              icon: !!icon,
            })}
            autoFocus={autoFocus}
            disabled={disabled}
            maxLength={maxLength}
            name={name}
            onBlur={onBlur}
            onChange={this.handleChange}
            placeholder={placeholder}
            required={required}
            type={type}
            value={value}
            {...(isTextArea ? { inputRef } : { ref: inputRef })}
            {...(isTextArea ? textarea : {})}
            {...inputProps}
          />
          {label && (
            <TextFieldLabel
              htmlFor={`${name}-input-${type}`}
              floated={label && placeholder}
            >
              {label}
              {showBadge && !required && <FieldBadge>Optional</FieldBadge>}
            </TextFieldLabel>
          )}
        </FieldWrapper>
        {maxLength && (
          <CharCounter
            float="right"
            maxLength={maxLength}
            size="small"
            text={value}
          />
        )}
        {hint && <HintMessage>{hint}</HintMessage>}
        {error && (
          <HintMessage kind="error" size="small">
            {error}
          </HintMessage>
        )}
      </TextFieldContainer>
    );
  }
}

TextField.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.node,
  hint: PropTypes.node,
  icon: PropTypes.string,
  inputRef: refShape,
  label: PropTypes.node,
  maxLength: PropTypes.number,
  noMargin: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showBadge: PropTypes.bool,
  textarea: PropTypes.shape({
    maxRows: PropTypes.number,
    minRows: PropTypes.number,
    resize: PropTypes.oneOf(['none', 'horizontal', 'vertical', 'both']),
  }),
  /**
   * TYPE_OPTIONS:
   * date
   * email
   * number
   * password
   * tel
   * text
   * textarea
   * url
   */
  type: PropTypes.oneOf(TYPE_OPTIONS),
  value: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
};

TextField.defaultProps = {
  id: null,
  className: null,
  autoFocus: false,
  disabled: false,
  error: null,
  hint: null,
  icon: null,
  inputRef: undefined,
  label: null,
  maxLength: null,
  noMargin: false,
  onBlur: null,
  onChange: null,
  placeholder: null,
  required: false,
  showBadge: true,
  textarea: null,
  type: 'text',
  value: '',
  inputProps: {},
};

export default TextField;
