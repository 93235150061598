import React, { useCallback, useEffect, useMemo } from 'react';
import debounce from 'lodash/debounce';

import { Box } from 'src/modules/prisma';

import { usePan } from '../providers/PanProvider';
import { useZoom } from '../providers/ZoomProvider';

type CanvasDimensions = {
  canvasWidth: number;
  canvasHeight: number;
  zoom: number;
};

type Props = {
  width: number;
  height: number;
  objectWidth: number;
  objectHeight: number;
  padding?: number;
  children: (dimensions: CanvasDimensions) => React.ReactNode;
};

function getObjectZoom(
  { canvasHeight, canvasWidth }: Omit<CanvasDimensions, 'zoom'>,
  { objectHeight, objectWidth }: { objectHeight: number; objectWidth: number },
  { padding }: { padding: number },
) {
  return Math.min(
    1,
    (canvasWidth - padding * 2) / objectWidth,
    (canvasHeight - padding * 2) / objectHeight,
  );
}

const calculateRanges = ({
  width,
  height,
  zoomedObjectWidth,
  zoomedObjectHeight,
  padding,
}: {
  width: number;
  height: number;
  zoomedObjectWidth: number;
  zoomedObjectHeight: number;
  padding: number;
}) => {
  // If zoomed image is smaller than current screen, we set the range to 0 so we can have the
  // panning locked. If it is bigger than current screen, we get the difference in order to always
  // limit the panning to the image borders. Also, we add some padding space so the user can add
  // markups to the borders.
  const rangeX =
    zoomedObjectWidth <= width
      ? 0
      : (zoomedObjectWidth - width) / 2 + padding * 2;
  const rangeY =
    zoomedObjectHeight <= height
      ? 0
      : (zoomedObjectHeight - height) / 2 + padding * 2;

  return { rangeX, rangeY };
};

const calcPanAfterZoomOut = (
  p: { x: number; y: number },
  {
    height,
    width,
    padding,
    oldZoom,
    newZoom,
    objectHeight,
    objectWidth,
  }: {
    height: number;
    width: number;
    padding: number;
    oldZoom: number;
    newZoom: number;
    objectHeight: number;
    objectWidth: number;
  },
) => {
  // Getting panning position before zoom in percentage so we can apply the values on new file size
  const beforeZoomObjectWidth = objectWidth * oldZoom;
  const beforeZoomObjectHeight = objectHeight * oldZoom;
  const afterZoomObjectWidth = objectWidth * newZoom;
  const afterZoomObjectHeight = objectHeight * newZoom;
  const panningPositionOnFilePercentage = {
    x: p.x / beforeZoomObjectWidth,
    y: p.y / beforeZoomObjectHeight,
  };

  // Getting new panning position on file after zoom
  const panningPositionOnFileAfterZoom = {
    x: afterZoomObjectWidth * panningPositionOnFilePercentage.x,
    y: afterZoomObjectHeight * panningPositionOnFilePercentage.y,
  };

  // Calculate new range so we can apply the panning taking into consideration the new ranges right after zooming
  const { rangeX, rangeY } = calculateRanges({
    width,
    height,
    padding,
    zoomedObjectWidth: afterZoomObjectWidth,
    zoomedObjectHeight: afterZoomObjectHeight,
  });

  return {
    values: {
      x: panningPositionOnFileAfterZoom.x,
      y: panningPositionOnFileAfterZoom.y,
    },
    ranges: {
      x: [-rangeX, rangeX] as [number, number],
      y: [-rangeY, rangeY] as [number, number],
    },
  };
};

const calcPanAfterZoom = (
  p: { x: number; y: number },
  {
    height,
    width,
    padding,
    oldZoom,
    newZoom,
    objectHeight,
    objectWidth,
    planeHeight,
    planeWidth,
    mousePositionOnCanvas,
  }: {
    height: number;
    width: number;
    padding: number;
    oldZoom: number;
    newZoom: number;
    objectHeight: number;
    objectWidth: number;
    planeHeight: number;
    planeWidth: number;
    mousePositionOnCanvas: { x: number; y: number };
  },
) => {
  const beforeZoomObjectWidth = oldZoom * objectWidth;
  const beforeZoomObjectHeight = oldZoom * objectHeight;
  const beforeZoomPlaneWidth = oldZoom * planeWidth;
  const beforeZoomPlaneHeight = oldZoom * planeHeight;
  const horizontalPadding = beforeZoomPlaneWidth - beforeZoomObjectWidth;
  const verticalPadding = beforeZoomPlaneHeight - beforeZoomObjectHeight;

  const mousePositionOnFile = {
    x: Math.min(
      Math.max(0, mousePositionOnCanvas.x - horizontalPadding / 2),
      beforeZoomObjectWidth,
    ),
    y: Math.min(
      Math.max(0, mousePositionOnCanvas.y - verticalPadding / 2),
      beforeZoomObjectHeight,
    ),
  };

  const mousePositionOnFilePercentage = {
    x: mousePositionOnFile.x / beforeZoomObjectWidth,
    y: mousePositionOnFile.y / beforeZoomObjectHeight,
  };

  // Getting mouse position before zoom in percentage so we can apply the values on new file size
  const afterZoomObjectWidth = objectWidth * newZoom;
  const afterZoomObjectHeight = objectHeight * newZoom;
  const mousePositionOnFileAfterZoom = {
    x: afterZoomObjectWidth * mousePositionOnFilePercentage.x,
    y: afterZoomObjectHeight * mousePositionOnFilePercentage.y,
  };

  // Center of file = 0, so subtracting the mouse position on file by
  // half of its size should do the trick.
  const mouseXY = {
    x: mousePositionOnFile.x - beforeZoomObjectWidth / 2,
    y: mousePositionOnFile.y - beforeZoomObjectHeight / 2,
  };

  const mouseXYAfterZoom = {
    x: mousePositionOnFileAfterZoom.x - afterZoomObjectWidth / 2,
    y: mousePositionOnFileAfterZoom.y - afterZoomObjectHeight / 2,
  };

  const mouseXYDifference = {
    x: mouseXYAfterZoom.x - mouseXY.x,
    y: mouseXYAfterZoom.y - mouseXY.y,
  };

  // Calculate new range so we can apply the panning taking into consideration the new ranges right after zooming
  const { rangeX, rangeY } = calculateRanges({
    width,
    height,
    padding,
    zoomedObjectWidth: afterZoomObjectWidth,
    zoomedObjectHeight: afterZoomObjectHeight,
  });

  return {
    values: {
      x: p.x + mouseXYDifference.x,
      y: p.y + mouseXYDifference.y,
    },
    ranges: {
      x: [-rangeX, rangeX] as [number, number],
      y: [-rangeY, rangeY] as [number, number],
    },
  };
};

export default function ZoomAndPan({
  width,
  height,
  objectWidth,
  objectHeight,
  padding = 0,
  children,
}: Props) {
  // Find initial zoom to be sure to fit the object
  // into the canvas. Objects smaller than the canvas
  // should not be blown up, so we use 1 as maximum
  // initial zoom.
  const initialZoom = getObjectZoom(
    { canvasHeight: height, canvasWidth: width },
    { objectHeight, objectWidth },
    { padding },
  );
  const {
    current: zoom,
    update: setZoom,
    increment: incrementZoom,
    decrement: decrementZoom,
  } = useZoom();
  const setZoomDebounced = useMemo(
    () => debounce(setZoom, 100, { leading: true }),
    [setZoom],
  );

  const zoomedObjectHeight = objectHeight * zoom;
  const zoomedObjectWidth = objectWidth * zoom;

  const planeHeight = 2 * Math.max(height, objectHeight);
  const planeWidth = 2 * Math.max(width, objectWidth);
  const translatedPlaneWidth = zoom * planeWidth;
  const translatedPlaneHeight = zoom * planeHeight;

  const { rangeX, rangeY } = calculateRanges({
    width,
    height,
    zoomedObjectWidth,
    zoomedObjectHeight,
    padding,
  });

  const {
    isPanning,
    pan,
    panDelta,
    mouseHandlers: panHandlers,
    panOn,
    setRange,
    panXY,
    setXY,
  } = usePan();

  const tx = width / 2 - translatedPlaneWidth / 2 - pan.x - panDelta.x;
  const ty = height / 2 - translatedPlaneHeight / 2 - pan.y - panDelta.y;

  useEffect(
    function updateInitialZoom() {
      setZoom({ initial: initialZoom });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialZoom],
  );

  useEffect(() => {
    setRange({ x: [-rangeX, rangeX], y: [-rangeY, rangeY] });
  }, [setRange, rangeX, rangeY]);

  useEffect(
    function recalculateZoom() {
      setZoomDebounced({
        current: getObjectZoom(
          { canvasHeight: height, canvasWidth: width },
          { objectHeight, objectWidth },
          { padding },
        ),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onWheel = useCallback(
    (e: React.WheelEvent) => {
      e.preventDefault();
      if (e.metaKey) {
        const evTarget = e.currentTarget as HTMLElement;
        const mousePositionOnCanvas = {
          x: e.clientX - evTarget?.offsetLeft,
          y: e.clientY - evTarget?.offsetTop,
        };

        if (e.deltaY < 0) {
          const newZoom = incrementZoom();

          setXY(p => {
            return calcPanAfterZoom(p, {
              height,
              width,
              padding,
              oldZoom: zoom,
              newZoom,
              objectHeight,
              objectWidth,
              planeHeight,
              planeWidth,
              mousePositionOnCanvas,
            });
          });
        } else {
          const newZoom = decrementZoom();

          setXY(p => {
            return calcPanAfterZoomOut(p, {
              height,
              width,
              padding,
              oldZoom: zoom,
              newZoom,
              objectHeight,
              objectWidth,
            });
          });
        }
      } else {
        panXY(e.deltaX, e.deltaY);
      }
    },
    [
      incrementZoom,
      setXY,
      height,
      width,
      padding,
      zoom,
      objectHeight,
      objectWidth,
      planeHeight,
      planeWidth,
      decrementZoom,
      panXY,
    ],
  );

  let cursor = 'inherit';

  if (isPanning) {
    cursor = 'grabbing';
  } else if (panOn) {
    cursor = 'grab';
  }

  return (
    <Box
      style={{ cursor }}
      position="relative"
      overflow="hidden"
      height={height}
      width={width}
      {...panHandlers}
    >
      <Box
        onWheel={onWheel}
        alignItems="center"
        backgroundColor="grey.100"
        display="flex"
        flexDirection="row"
        height={Math.round(translatedPlaneHeight)}
        justifyContent="center"
        position="absolute"
        width={Math.round(translatedPlaneWidth)}
        style={{
          backgroundSize: `${Math.round(zoom * 40)}px ${Math.round(
            zoom * 40,
          )}px`,
          left: Math.round(tx),
          top: Math.round(ty),
        }}
      >
        {children({
          canvasWidth: Math.round(planeWidth),
          canvasHeight: Math.round(planeHeight),
          zoom,
        })}
      </Box>
    </Box>
  );
}
