import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal } from '@picter/prisma';
import { TrackerEvent } from '@picter/tracker';

import { Dropdown } from 'src/modules/prisma';
import { useProject } from 'src/hooks/use-resource';

import { ManageCollectionsIcon } from 'src/components/ContentIcon';
import useModalStateHandlers from 'src/hooks/use-modal-state-handlers';
import CreateCollectionModalForm from './components/CreateCollectionModalForm';
import CollectionDropdownBody from './components/CollectionDropdownBody';
import DropdownButtonWrapper from './styles/DropdownButtonWrapper';

import messages from './messages';

const CollectionActionsDropdown = ({
  closeOnSelect,
  icon,
  imagesIds,
  noMargin,
  projectId,
  ...props
}) => {
  const {
    visibleConfirmCreateModal,
    closeConfirmCreateModal,
    openConfirmCreateModal,
  } = useModalStateHandlers('confirmCreate');

  const project = useProject(
    {
      id: projectId,
    },
    { request: false },
  );

  return (
    <TrackerEvent name="collections menu" trackClick>
      <DropdownButtonWrapper noMargin={noMargin} {...props}>
        <Dropdown icon={icon}>
          {({ closeMenu }) => (
            <CollectionDropdownBody
              {...{
                projectId,
                imagesIds,
                closeOnSelect,
                openConfirmCreateModal,
                closeMenu,
              }}
            />
          )}
        </Dropdown>
      </DropdownButtonWrapper>
      <Modal
        open={visibleConfirmCreateModal}
        onClickClose={closeConfirmCreateModal}
        title={<FormattedMessage {...messages.titleModalCreate} />}
      >
        <TrackerEvent name="Create collection modal" trackView>
          <CreateCollectionModalForm
            project={project}
            imagesIds={imagesIds}
            closeConfirmCreateModal={closeConfirmCreateModal}
          />
        </TrackerEvent>
      </Modal>
    </TrackerEvent>
  );
};

CollectionActionsDropdown.propTypes = {
  className: PropTypes.string,
  closeOnSelect: PropTypes.bool,
  glyphSize: PropTypes.string,
  imagesIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  noMargin: PropTypes.bool,
  projectId: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
};

CollectionActionsDropdown.defaultProps = {
  className: null,
  closeOnSelect: false,
  projectId: null,
  glyphSize: 'small',
  noMargin: false,
  size: 'medium',
  icon: <ManageCollectionsIcon />,
};

export default CollectionActionsDropdown;
