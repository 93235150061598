import { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { libraryProjectUrl } from 'src/routes/urls';
import { useToriiActions } from 'src/modules/torii';
import { useErrorHandler } from 'src/components/ErrorHandler';

export default function useHandlers() {
  const { spaceId } = useParams();
  const { push } = useHistory();
  const { create } = useToriiActions();
  const { handleError } = useErrorHandler();

  const createProject = useCallback(async () => {
    try {
      const {
        data: {
          attributes: { 'public-url-key': publicUrlKey },
        },
      } = await create('wsProjects', {
        relationships: {
          space: {
            id: spaceId,
            type: 'ws-spaces',
          },
        },
      });
      return push(libraryProjectUrl({ projectId: publicUrlKey }));
    } catch (error) {
      handleError(error);
      throw error;
    }
  }, [create, handleError, push, spaceId]);

  const [lastFolderCreatedId, setLastFolderCreatedId] = useState(undefined);

  const createFolder = useCallback(async () => {
    try {
      const {
        data: { id },
      } = await create('wsFolders', {
        relationships: {
          space: {
            id: spaceId,
            type: 'ws-spaces',
          },
        },
      });

      setLastFolderCreatedId(id);
    } catch (error) {
      const handled = handleError(error);

      if (!handled) {
        throw error;
      }
    }
  }, [create, handleError, spaceId]);

  return { createProject, createFolder, lastFolderCreatedId };
}
