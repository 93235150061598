import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { warning } from '@picter/prisma';

import { Tooltip } from 'src/modules/prisma';
import { formattedMessage } from 'src/utils/app-prop-types';

warning(
  'MessageTooltip',
  '`src/components/MessageTooltip` has been deprecated in favor of Tooltip.Message from src/modules/prisma`',
);

const MessageTooltip = forwardRef(({ children, message, ...props }, ref) => {
  return (
    <Tooltip ref={ref} {...props}>
      <Tooltip.Reference>{children}</Tooltip.Reference>
      <Tooltip.Message>{message}</Tooltip.Message>
    </Tooltip>
  );
});

MessageTooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  message: formattedMessage.isRequired,
  hideDelay: PropTypes.number,
  showDelay: PropTypes.number,
  visible: PropTypes.bool,
};

MessageTooltip.defaultProps = {
  hideDelay: 0,
  showDelay: 0,
  visible: false,
};

MessageTooltip.displayName = 'MessageTooltip';

export default MessageTooltip;
