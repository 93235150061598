import { API_LOCAL_CREATE } from '../../constants';

const localCreate = (model, { id, type, attributes, relationships } = {}) => {
  if (!id) {
    throw new Error('Local create needs to already have the id.');
  }

  if (!type) {
    throw new Error('Local create needs a type.');
  }

  return dispatch =>
    dispatch({
      type: API_LOCAL_CREATE,
      meta: { type },
      payload: {
        [model]: {
          [id]: {
            id,
            type,
            attributes,
            relationships,
          },
        },
      },
    });
};

export default localCreate;
