import { useCallback, useMemo } from 'react';
import injectPublicLinkKey from 'src/utils/inject-public-link-key';

import { get } from 'src/utils/accessors';

const getNewOrders = (
  images,
  dropDirection,
  targetImageIndex,
  numberOfImages,
) => {
  let orderPrevious;
  let orderNext;
  if (dropDirection === 'left') {
    // image has been dropped left to the target image
    orderPrevious =
      targetImageIndex === 0
        ? 0
        : get(images, `${targetImageIndex - 1}.attributes.order`);
    orderNext = get(images, `${targetImageIndex}.attributes.order`);
  } else {
    // image has been dropped right to the target image
    orderPrevious = get(images, `${targetImageIndex}.attributes.order`);
    orderNext =
      targetImageIndex === images.size - 1
        ? get(images, `${images.size - 1}.attributes.order`) + 100
        : get(images, `${targetImageIndex + 1}.attributes.order`);
  }

  const paddingValue = (orderNext - orderPrevious) / (numberOfImages + 1);

  return new Array(numberOfImages)
    .fill()
    .map((_, i) => orderPrevious + paddingValue * (i + 1));
};

const useHandlers = ({
  collection,
  project,
  images,
  bulkUpdate,
  tracker,
  match,
}) => {
  const projectPublicKey = get(project, 'attributes.publicUrlKey');
  const imagesOrders = useMemo(
    () => images.map(image => get(image, 'attributes.order')).join(),
    [images],
  );
  const isCollectionImages = !!collection;

  // Memoize images by order values and array size, this way updateImageOrder only
  // changes when things it uses change.
  const memoizedImages = useMemo(
    () => images,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imagesOrders],
  );

  const updateImagesOrder = useCallback(
    (
      draggedImagesIds, // the image that gets a new place
      targetImageId, // the image, that draggedImage has been dropped upon
      dropDirection, // the side of the drop: left or right to the dropImage
    ) => {
      const targetImageIndex = memoizedImages.findIndex(
        image => get(image, 'id') === targetImageId,
      );

      const draggedImagesIndexesMap = draggedImagesIds.reduce((acc, next) => {
        return {
          ...acc,
          [next]: memoizedImages.findIndex(image => get(image, 'id') === next),
        };
      }, {});

      const orderedDraggedImagesIds = draggedImagesIds.sort(
        (currDraggedImageId, nextDraggedImageId) => {
          return (
            get(
              memoizedImages,
              `${draggedImagesIndexesMap[currDraggedImageId]}.attributes.order`,
            ) -
            get(
              memoizedImages,
              `${draggedImagesIndexesMap[nextDraggedImageId]}.attributes.order`,
            )
          );
        },
      );

      const newOrders = getNewOrders(
        memoizedImages,
        dropDirection,
        targetImageIndex,
        orderedDraggedImagesIds.length,
      );

      const { querySerializers } = injectPublicLinkKey(
        {
          querySerializers: {
            publicKey: () => `publicKey=${projectPublicKey}`,
          },
        },
        match,
      );

      bulkUpdate(
        isCollectionImages ? 'wsCollectionImages' : 'wsProjectImages',
        orderedDraggedImagesIds.map((draggedImageId, i) => ({
          id: draggedImageId,
          attributes: {
            order: newOrders[i],
          },
        })),
        {
          optimistic: true,
          querySerializers,
        },
      );
      tracker.track('Dragged Image Bulk reorder', {
        items: orderedDraggedImagesIds.map((draggedImageId, i) => ({
          deltas: Math.abs(
            targetImageIndex - draggedImagesIndexesMap[draggedImageId],
          ),
          imageId: draggedImageId,
          newIndex: targetImageIndex + i,
          numberOfImages: memoizedImages.size,
          oldIndexes: draggedImagesIndexesMap[draggedImageId],
        })),
      });
    },
    [
      isCollectionImages,
      memoizedImages,
      bulkUpdate,
      tracker,
      projectPublicKey,
      match,
    ],
  );

  return { updateImagesOrder };
};
export default useHandlers;
