import React from 'react';
import PropTypes from 'prop-types';
import { Disclosure } from 'reakit/Disclosure';
import isFunction from 'lodash/isFunction';
import classNames from 'classnames';
import { CustomPropTypes } from '@picter/prisma';

import { Panel } from 'src/modules/prisma';

import { useAccordionItem } from '../providers';

function AccordionItemDisclosure({ children, className, ...props }) {
  const state = useAccordionItem();

  return (
    <Disclosure
      as={Panel}
      tag="button"
      border="unset"
      borderRadius="2px"
      backgroundColor="white"
      className={classNames(className, 'accordion-item__disclosure')}
      overflow="hidden"
      p={0}
      width="100%"
      {...state}
      {...props}
    >
      {isFunction(children) ? children(state) : children}
    </Disclosure>
  );
}

AccordionItemDisclosure.propTypes = {
  children: CustomPropTypes.oneOrMoreOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  className: PropTypes.string,
};

AccordionItemDisclosure.defaultProps = {
  className: undefined,
};

export default AccordionItemDisclosure;
