import styled from 'styled-components/macro';
import { variant } from 'styled-system';

const SidebarPanel = styled.aside`
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 200;
  ${variant({
    variants: {
      transparent: {},
      opaque: {
        backgroundColor: 'white',
        boxShadow: 'soft',
      },
    },
  })}
`;

SidebarPanel.defaultProps = {
  variant: 'transparent',
};

export default SidebarPanel;
