import styled from 'styled-components/macro';

const ProofFieldContainer = styled.div`
  display: inline-flex;

  .label {
    display: flex;
  }
`;

export default ProofFieldContainer;
