import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Box } from '@rebass/grid';
import { Link, Redirect } from 'react-router-dom';
import { A, FlexAlignLeft, Spinner } from '@picter/prisma';

import { Heading } from 'src/modules/prisma';
import useDocumentTitle from 'src/hooks/use-document-title';
import ProjectCardGrid, { ProjectCard } from 'src/components/ProjectCardGrid';
import { ProjectsSectionPlaceholder } from 'src/containers/ProjectsSection';
import SpaceTrialPanel from 'src/components/SpaceTrialPanel';
import { matchShape } from 'src/utils/app-prop-types';
import { librarySpaceUrl } from 'src/routes/urls';
import withSuspenseLoader from 'src/hocs/with-suspense-loader';
import { useSpace, useSpaceProjects } from 'src/hooks/use-resource';
import { getDateDeltaFromNowInSeconds } from 'src/utils/date';
import NoPermissionPage from 'src/components/NoPermissionPage';
import NotFoundPage from 'src/components/NotFoundPage';
import { useRealtimeJSONApi } from 'src/modules/realtime';
import { get } from 'src/utils/accessors';

import MainNavigation from '../../../_components/MainNavigation';
import messages from './_messages';
import SpacesLayout from '../_layout';

const TrashProjectGrid = ({ spaceId }) => {
  const projects = useSpaceProjects(
    {
      id: spaceId,
      include: [
        'invitations',
        'users.user',
        'publicLinks',
        'coverImage',
        'follows',
      ],
      includeOnSelector: ['images'],
    },
    {
      page: {
        size: 200,
      },
      querySerializers: {
        trash: () => 'trash=true',
      },
    },
  );

  const filteredProjects = useMemo(
    () =>
      projects
        .filter(p => get(p, 'attributes.deletedAt') !== null)
        .sortBy(p => -new Date(get(p, 'attributes.deletedAt'))),
    [projects],
  );

  return (
    <ProjectCardGrid>
      {filteredProjects.map(project => (
        <ProjectCard key={get(project, 'id')} project={project} />
      ))}
    </ProjectCardGrid>
  );
};

TrashProjectGrid.propTypes = {
  spaceId: PropTypes.string.isRequired,
};

const TrashProjectGridWithSuspense = withSuspenseLoader({
  LoadingComponent: ProjectsSectionPlaceholder,
  ErrorComponent: undefined,
})(TrashProjectGrid);

function TrashPage({
  match: {
    params: { spaceId },
  },
}) {
  // resources
  const space = useSpace({
    id: spaceId,
    include: ['users.user', 'plan', 'folders'],
  });

  // realtime
  const { subscribe: subscribeToRealtime } = useRealtimeJSONApi();

  useEffect(() => {
    const subscription = subscribeToRealtime(
      `private-resource=ws-spaces;id=${spaceId}`,
    );

    return subscription;
  }, [subscribeToRealtime, spaceId]);

  // props
  const spaceEndOfTrial = get(space, 'attributes.trialEndsAt');
  const isTrialExpired = useMemo(() => {
    if (spaceEndOfTrial === null) {
      return false;
    }

    return getDateDeltaFromNowInSeconds(spaceEndOfTrial) < 0;
  }, [spaceEndOfTrial]);

  const spaceName = get(space, 'attributes.name');
  const spaceUrl = librarySpaceUrl({ spaceId });

  useDocumentTitle(spaceName);

  if (isTrialExpired) {
    return <Redirect to={spaceUrl} />;
  }

  return (
    <SpacesLayout>
      <SpacesLayout.Navigation>
        <MainNavigation />
        <Box mx={5} mt="auto" mb={2}>
          <SpaceTrialPanel space={space} />
        </Box>
      </SpacesLayout.Navigation>
      <SpacesLayout.Top>
        <FlexAlignLeft>
          <Link to={spaceUrl}>
            <A>
              <Heading
                tag="h1"
                fontWeight="light"
                textSize="regular"
                color="primary"
              >
                {spaceName}
              </Heading>
            </A>
          </Link>
          <Box mx={2}>
            <Heading tag="h1" fontWeight="light" textSize="regular">
              /
            </Heading>
          </Box>
          <Heading tag="h1" fontWeight="light" textSize="regular">
            <FormattedMessage {...messages.titleTrash} />
          </Heading>
        </FlexAlignLeft>
      </SpacesLayout.Top>
      <SpacesLayout.Content>
        <TrashProjectGridWithSuspense spaceId={spaceId} />
      </SpacesLayout.Content>
    </SpacesLayout>
  );
}

TrashPage.propTypes = {
  match: matchShape.isRequired,
};

const TrashLoadingError = ({ error }) => {
  if (error.statusCode === 403) {
    return <NoPermissionPage />;
  }

  return <NotFoundPage />;
};

TrashLoadingError.propTypes = {
  error: PropTypes.shape({
    statusCode: PropTypes.number.isRequired,
  }).isRequired,
};

const TrashPageWithSuspense = withSuspenseLoader({
  LoadingComponent: Spinner,
  ErrorComponent: TrashLoadingError,
})(TrashPage);

export default TrashPageWithSuspense;
