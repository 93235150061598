import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import { trackerShape } from '@picter/tracker';
import { withTheme } from 'styled-components/macro';
import { Box } from '@rebass/grid';
import { useLocation, useRouteMatch, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { useToriiActions } from 'src/modules/torii';
import EmptyImageGrid from 'src/components/EmptyImageGrid';
import { locationShape, matchShape } from 'src/utils/app-prop-types';
import withSuspenseLoader from 'src/hocs/with-suspense-loader';
import { SelectableComponentsFeeder } from 'src/modules/SelectableComponents';
import usePermission from 'src/hooks/use-permission';
import ImageCard from 'src/containers/ImageCard';
import {
  getProjectImageUploadState,
  getProjectImageProcessState,
} from 'src/utils/accessors/project-image';
import { returnInCase } from 'src/utils/in-case';
import { get } from 'src/utils/accessors';
import {
  // collection image
  libraryProjectCollectionImageCommentsUrl,
  libraryProjectCollectionImageInfoUrl,
  libraryProjectCollectionImageUrl,
  // project image
  libraryProjectImageCommentsUrl,
  libraryProjectImageInfoUrl,
  libraryProjectImageUrl,
} from 'src/routes/urls';
import paths from 'src/routes/paths';

import AutoSizer from './AutoSizer';
import Grid from './Grid';
import SelectionTopbar from './SelectionTopbar';
import ImageGridPlaceholder from './ImageGridPlaceholder';

import ImageCardGrid from '../styles/ImageCardGrid';
import useHandlers from '../hooks/use-handlers';

const { LIBRARY_PROJECT_COLLECTION_PATH } = paths;

function useImageUrlBuilder({ onMobile }) {
  const { pathname, query } = useLocation();
  const isOnCollectionPage = !!useRouteMatch(LIBRARY_PROJECT_COLLECTION_PATH);
  const { canSeeComments } = usePermission();

  return returnInCase({
    [isOnCollectionPage]: () =>
      returnInCase({
        [pathname.includes('/comments')]: () =>
          libraryProjectCollectionImageCommentsUrl,
        [pathname.includes('/info')]: () =>
          libraryProjectCollectionImageInfoUrl,
        default: () =>
          canSeeComments && !onMobile
            ? libraryProjectCollectionImageCommentsUrl
            : libraryProjectCollectionImageUrl,
      }),
    default: () =>
      returnInCase({
        [pathname.includes('/comments')]: () => libraryProjectImageCommentsUrl,
        [pathname.includes('/info')]: () => libraryProjectImageInfoUrl,
        default: () =>
          canSeeComments && !onMobile
            ? libraryProjectImageCommentsUrl
            : libraryProjectImageUrl,
      }),
  }).builder(libraryProjectImageUrl.placeholder, query);
}

const ImageGrid = ({
  collection,
  images,
  uploadGroup,
  filter,
  project,
  tracker,
  gridMeasurements,
  gridHeader,
  headerHeight,
  location,
  onScroll,
  CardComponent,
  match,
  onMobile,
}) => {
  const [gridIndex, setGridIndex] = useState(-1);
  const [memoizedIds, setMemoizedIds] = useState([]);
  const { bulkUpdate } = useToriiActions();
  const collectionId = get(collection, 'id');
  const isCollectionImages = !!collection;
  const projectId = get(project, 'id');
  const projectKey = match.params.projectId;
  const imageUrl = useImageUrlBuilder({ onMobile });

  useEffect(() => {
    const imagesIds = images.map(item => get(item, 'id')).toJS();
    if (memoizedIds.join() !== imagesIds.join()) {
      setMemoizedIds(imagesIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  const { updateImagesOrder } = useHandlers({
    collection,
    project,
    images,
    bulkUpdate,
    tracker,
    match,
  });

  const { canDownloadImages } = usePermission();

  const {
    columnWidth,
    controlHeight,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    cardPaddingHorizontal,
  } = gridMeasurements;

  useEffect(() => {
    const imageId = location.state && location.state.gridPositionImageId;
    if (imageId) {
      const index = images.findIndex(image => {
        const id = isCollectionImages
          ? get(image, 'relationships.image.id')
          : get(image, 'id');

        return id === imageId;
      });
      setGridIndex(index || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (images.size === 0) {
    // Useful to tell external components that the grid is going back to its
    // initial position.
    if (onScroll) {
      onScroll({ scrollOffset: 0 });
    }

    return (
      <>
        {gridHeader && <Box mb={5}>{gridHeader}</Box>}
        <Box
          style={{ height: '100%' }}
          flex={1}
          pl={`${paddingLeft}px`}
          pr={`${paddingRight}px`}
          pt={`${paddingTop + headerHeight}px`}
          pb={`${paddingBottom}px`}
        >
          <EmptyImageGrid />
        </Box>
      </>
    );
  }

  return (
    <>
      <SelectableComponentsFeeder ids={memoizedIds} />
      <SelectionTopbar projectId={projectId} projectPublicKey={projectKey} />
      <ImageCardGrid>
        <AutoSizer throttleWidth>
          {({ height, width }) => (
            <Grid
              scrollToIndex={gridIndex}
              columnWidth={columnWidth}
              controlHeight={controlHeight}
              height={height}
              width={width}
              paddingTop={paddingTop}
              paddingBottom={paddingBottom}
              paddingLeft={paddingLeft}
              paddingRight={paddingRight}
              itemCount={images.size}
              gridHeader={gridHeader}
              headerHeight={headerHeight}
              itemPaddingHorizontal={cardPaddingHorizontal}
              onScroll={onScroll}
            >
              {({ index, width: cardWidth, margin, height: cardHeight }) => {
                const image = images.get(index);
                const imageId = get(image, 'id');
                const parsedImage = isCollectionImages
                  ? get(image, 'relationships.image', Map())
                  : image;
                const imageFileId = get(parsedImage, 'id');

                const commentsCounter = get(
                  parsedImage,
                  'relationships.comments',
                  List(),
                ).size;

                const upload =
                  uploadGroup &&
                  uploadGroup.files &&
                  uploadGroup.files[imageFileId];

                const uploading = getProjectImageUploadState(parsedImage);
                const processing = getProjectImageProcessState(parsedImage);
                let uploadPreview;
                const fileExtension = get(
                  parsedImage,
                  'attributes.fileExtension',
                );
                const fileMimeType = get(parsedImage, 'attributes.mimeType');

                if (upload) {
                  uploadPreview = upload.preview;
                }

                return (
                  <CardComponent
                    id={imageFileId}
                    selectionId={imageId}
                    dragAndDropId={imageId}
                    index={index}
                    key={imageId}
                    link={imageUrl({
                      collectionId,
                      projectId: projectKey,
                      imageId: imageFileId,
                    })}
                    projectId={projectId}
                    tracker={tracker}
                    updateImagesOrder={updateImagesOrder}
                    uploading={uploading}
                    processing={processing}
                    uploadPreview={uploadPreview}
                    fileExtension={fileExtension}
                    fileMimeType={fileMimeType}
                    filter={filter}
                    downloadEnabled={
                      canDownloadImages && !uploading && !processing
                    }
                    width={`${cardWidth}%`}
                    height={cardHeight - margin * 2}
                    margin={`${margin}px`}
                    gridWidth={width - margin * 4}
                    gridPaddingLeft={paddingLeft}
                    gridPaddingRight={paddingRight}
                    commentsCounter={commentsCounter}
                  />
                );
              }}
            </Grid>
          )}
        </AutoSizer>
      </ImageCardGrid>
    </>
  );
};

ImageGrid.propTypes = {
  filter: PropTypes.string,
  project: PropTypes.instanceOf(Map),
  collection: PropTypes.instanceOf(Map),
  tracker: trackerShape.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  uploadGroup: PropTypes.object.isRequired,
  images: PropTypes.instanceOf(List).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  gridMeasurements: PropTypes.object.isRequired,
  gridHeader: PropTypes.node,
  headerHeight: PropTypes.number,
  location: locationShape.isRequired,
  onScroll: PropTypes.func,
  CardComponent: PropTypes.shape({
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  }),
  match: matchShape.isRequired,
  onMobile: PropTypes.bool,
};

ImageGrid.defaultProps = {
  collection: null,
  filter: null,
  project: null,
  gridHeader: null,
  headerHeight: 0,
  onScroll: null,
  CardComponent: ImageCard,
  onMobile: false,
};

export default compose(
  withTheme,
  withSuspenseLoader({ LoadingComponent: ImageGridPlaceholder }),
  withRouter,
)(ImageGrid);
