import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';

import Bottom from '../styles/SidebarBottom';
import Content from '../styles/SidebarContent';
import Panel from '../styles/ContextSidebarPanel';
import { useLayoutControls } from '../utils';
import Top from '../styles/SidebarTop';

function ContextSidebar({
  id,
  className,
  children,
  closeOnUnmount,
  openOnMount,
  ...props
}) {
  const controls = useLayoutControls();
  const { openContextSidebar, closeContextSidebar } = controls;

  useEffect(() => {
    if (openOnMount) {
      openContextSidebar();
    }

    return () => {
      if (closeOnUnmount) {
        closeContextSidebar();
      }
    };
  }, [openContextSidebar, closeContextSidebar, openOnMount, closeOnUnmount]);

  return (
    <Panel id={id} className={className} {...props}>
      {isFunction(children) ? children(controls) : children}
    </Panel>
  );
}

ContextSidebar.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  closeOnUnmount: PropTypes.bool,
  openOnMount: PropTypes.bool,
};

ContextSidebar.defaultProps = {
  id: null,
  className: null,
  closeOnUnmount: false,
  openOnMount: false,
};

ContextSidebar.Bottom = Bottom;
ContextSidebar.Content = Content;
ContextSidebar.Top = Top;

export default ContextSidebar;
