import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';

import { Icon } from 'src/modules/prisma';

const NavigationItemElementIcon = styled(Icon).attrs({
  color: 'inherit',
  size: 'medium',
})`
  margin-right: ${themeGet('space.2')}px;

  .active & {
    color: ${themeGet('colors.primary')};
  }
`;

export default NavigationItemElementIcon;
