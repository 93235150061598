import kebabCase from 'lodash/kebabCase';
import { requestAsPromise } from '../../json-api-middleware/actions';
import { asyncTypesArray } from '../../utils/action-types-helpers';
import formatRequestPayload from '../../utils/format-request-payload';
import addQueryParameters from '../../utils/add-query-parameters';
import { API_CREATE_DATA_TYPES } from '../../constants';

const bulkCreate = (
  model,
  data,
  { prefix, baseUrl, querySerializers, postfix, worker } = {},
) => {
  if (!data) {
    throw new Error(
      'Bulk create without data is not allowed. Try "new Array(size).fill()".',
    );
  }

  const requestPayloads = data.map(({ attributes, relationships } = {}) => {
    const formattedPayload = formatRequestPayload(model, {
      attributes,
      relationships,
    });
    return formattedPayload.data;
  });

  const endpoint = [kebabCase(model)];

  if (prefix) endpoint.unshift(prefix);
  if (postfix) endpoint.push(postfix);

  return dispatch => {
    const action = {
      types: asyncTypesArray(API_CREATE_DATA_TYPES),
      endpoint: addQueryParameters(endpoint.join('/'), {
        querySerializers,
      }),
      method: 'POST',
      payload: { data: requestPayloads },
      meta: {
        type: model,
        worker,
      },
      baseUrl,
    };

    return requestAsPromise(dispatch, action);
  };
};

export default bulkCreate;
