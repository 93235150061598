import { defineMessages } from 'react-intl';

export default defineMessages({
  pageHeader: {
    id: 'Step3Page.pageHeader',
    defaultMessage: 'Invite your team members',
  },
  placeholderEmail: {
    id: 'Step3Page.placeholderEmail',
    defaultMessage: 'email',
  },
  labelRoleInThisSpace: {
    id: 'InviteMemberModal.labelRoleInThisSpace',
    defaultMessage: 'Choose role',
  },
  messageManagerRole: {
    id: 'InviteMemberModal.messageManagerRole',
    defaultMessage: 'Can manage members',
  },
  messageMemberRole: {
    id: 'InviteMemberModal.messageMemberRole',
    defaultMessage: 'Has full access on all projects',
  },
  messageViewerRole: {
    id: 'InviteMemberModal.messageViewerRole',
    defaultMessage: 'Can view and comment all projects',
  },
  labelAddAnotherTeamMember: {
    id: 'Step3Page.labelAddAnotherTeamMember',
    defaultMessage: 'Add another team member',
  },
  labelSendInvite: {
    id: 'Step3Page.labelSendInvite',
    defaultMessage: 'Send invite',
  },
  messageSkip: {
    id: 'Step3Page.messageSkip',
    defaultMessage: 'Don’t work in a team?',
  },
  labelSkipLink: {
    id: 'Step3Page.labelSkipLink',
    defaultMessage: 'Skip this',
  },
});
