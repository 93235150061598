import React, { memo, useMemo, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  CheckCircle as CheckCircleSvg,
  Delete as DeleteSvg,
  Edit as EditSvg,
  MoreVert as MoreVertSvg,
  KeyboardArrowDown as KeyboardArrowDownSvg,
  KeyboardArrowUp as KeyboardArrowUpSvg,
  InsertDriveFile as InsertDriveFileSvg,
} from '@styled-icons/material';
import { useTheme } from 'styled-components/macro';
import { SquareImage } from '@picter/prisma';

import {
  Anchor,
  Body,
  Box,
  Checkbox,
  Heading,
  Icon,
  Panel,
  Text,
  Tooltip,
  Spacer,
} from 'src/modules/prisma';
import ActionsDropdown from 'src/components/ActionsDropdown';
import Avatar from 'src/components/Avatar';
import { msToTime } from 'src/utils/accessors/project-image';
import { formattedMessage } from 'src/utils/app-prop-types';
import TruncateBox from 'src/styles/TruncateBox';
import useModal from 'src/hooks/use-modal';
import { transformFrameIntoSeconds } from 'src/utils/framerate';
import { CommentFormWithLiteUserSupport } from 'src/components/CommentInputForm';
import useFormatMessage from 'src/hooks/use-format-message';

import { CommentAction } from '../constants';
import CommentAnnotationMarker from './CommentAnnotationMarker';
import CommentActions from '../styles/CommentActions';
import CommentAndRepliesContainer from '../styles/CommentAndRepliesContainer';
import CommentAside from '../styles/CommentAside';
import CommentDropdown from '../styles/CommentDropdown';
import CommentContainer from '../styles/CommentContainer';
import CommentDeleteModal from './CommentDeleteModal';
import CommentEditForm from './CommentEditForm';
import CommentFooter from '../styles/CommentFooter';
import CommentSection from '../styles/CommentSection';
import CommentPreviewStack from '../styles/CommentPreviewStack';
import CommentHeader from '../styles/CommentHeader';
import CommentImage from '../styles/CommentImage';
import ReplyInputWrapper from '../styles/ReplyInputWrapper';
import CommentPublicationDate from './CommentPublicationDate';
import MarkupRenderer from './MarkupRenderer';
import CommentAssignerDropdown, {
  AssignedToToggle,
} from './CommentAssignerDropdown';
import messages from '../messages';
import CommentReply from './CommentReply';

/* Element used only to capture bubbled events and cancel propagation. */
/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
function BlockEventPropagation(props) {
  const cancelPropagation = useCallback(event => {
    event.stopPropagation();
    event.preventDefault();
  }, []);
  return <div onClick={cancelPropagation} {...props} />;
}
/* eslint-enable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */

function Comment({
  actions,
  assignee,
  author: { email: authorEmail, name: authorName },
  content,
  direction,
  edited,
  file: {
    alt: fileAlt,
    frameRate: fileFrameRate,
    src: fileSrc,
    type: fileType,
    showPreview,
    previewSize,
    previewType,
  },
  opened,
  frame,
  highlighted,
  id,
  marker,
  project,
  published,
  replies,
  resolved,
  resolver,
  selected,

  onClick,
  onClickFile,
  onClickMarker,
  onClickReply,

  onAssign,
  onDelete,
  onEdit,
  onReply,
  onResolve,
}) {
  const formatMessage = useFormatMessage();
  const { space } = useTheme();
  const [editing, setEditing] = useState(false);
  const moreTooltipRef = useRef();
  const isVideo = fileType === 'video';

  const commentTime = useMemo(
    () =>
      isVideo && typeof frame === 'number'
        ? transformFrameIntoSeconds(frame, fileFrameRate)
        : undefined,
    [isVideo, frame, fileFrameRate],
  );

  const [commentDeleteModal, { open: openCommentDeleteModal }] = useModal(
    <CommentDeleteModal onConfirm={() => onDelete(id, replies)} />,
  );
  const numberOfReplies = replies.length;
  const hasReplies = numberOfReplies > 0;

  const containerStyles = useMemo(() => {
    const borderPosition = {
      'left-to-right': 'right',
      'right-to-left': 'left',
    }[direction];

    switch (true) {
      case highlighted:
        return {
          backgroundColor: 'primary',
          backgroundOpacity: 0.04,
        };
      case selected:
        return {
          backgroundColor: 'primary',
          backgroundOpacity: 0.15,
          borderColor: 'primary',
          borderPosition,
        };
      case opened:
        return {
          borderColor: 'grey.400',
          borderPosition,
        };
      default:
        return {};
    }
  }, [highlighted, selected, opened, direction]);

  const messageDeletedUser = formatMessage(messages.messageDeletedUser);

  return (
    <CommentAndRepliesContainer {...containerStyles} resolved={resolved}>
      <CommentContainer assigned={Boolean(assignee)} onClick={onClick}>
        {showPreview && previewSize === 'large' && (
          <CommentAside>
            <BlockEventPropagation>
              <div
                css="cursor: pointer;"
                className="mute-on-resolve"
                onClick={onClickFile}
                onKeyPress={onClickFile}
                role="button"
                tabIndex={0}
              >
                <BigFilePreview
                  fileType={fileType}
                  previewType={previewType}
                  fileSrc={fileSrc}
                  fileAlt={fileAlt}
                />
              </div>
            </BlockEventPropagation>
          </CommentAside>
        )}
        <CommentHeader>
          <Avatar
            className="mute-on-resolve"
            email={authorEmail}
            name={authorName || authorEmail || messageDeletedUser}
            size="small"
            deleted={!authorEmail && !authorName}
          />
          <Spacer sx="2" />
          <TruncateBox maxWidth="50%">
            <Heading
              className="mute-on-resolve"
              tag="h1"
              textSize="small"
              title={authorName || authorEmail || messageDeletedUser}
            >
              {authorName || authorEmail || messageDeletedUser}
            </Heading>
          </TruncateBox>
          <Spacer sx="2" />
          <CommentPublicationDate date={published} />
          {(actions.includes(CommentAction.DELETE) ||
            actions.includes(CommentAction.EDIT)) && (
            <>
              <Spacer sx="auto" />
              <CommentDropdown>
                <BlockEventPropagation>
                  <ActionsDropdown
                    page="project"
                    toggle={({ onClick: onClickToggle }) => {
                      return (
                        <Tooltip ref={moreTooltipRef}>
                          <Tooltip.Reference>
                            <Icon
                              className="mute-on-resolve"
                              color="grey.600"
                              size="medium"
                              type={<MoreVertSvg />}
                              onClick={event => {
                                moreTooltipRef.current.hide();
                                onClickToggle(event);
                              }}
                            />
                          </Tooltip.Reference>
                          <Tooltip.Message>
                            <FormattedMessage {...messages.labelMore} />
                          </Tooltip.Message>
                        </Tooltip>
                      );
                    }}
                  >
                    <ActionsDropdown.Group entity="comment">
                      {actions.includes(CommentAction.EDIT) && (
                        <ActionsDropdown.Item
                          action="edit"
                          icon={<EditSvg />}
                          label={
                            <FormattedMessage {...messages.labelEditComment} />
                          }
                          onClick={() => setEditing(true)}
                        />
                      )}
                      {actions.includes(CommentAction.DELETE) && (
                        <ActionsDropdown.Item
                          action="delete"
                          icon={<DeleteSvg />}
                          label={
                            <FormattedMessage
                              {...messages.labelDeleteComment}
                            />
                          }
                          onClick={openCommentDeleteModal}
                        />
                      )}
                    </ActionsDropdown.Group>
                  </ActionsDropdown>
                </BlockEventPropagation>
              </CommentDropdown>
            </>
          )}
        </CommentHeader>
        <CommentSection>
          {editing ? (
            <Box flex="1">
              <BlockEventPropagation>
                <CommentEditForm
                  onCancel={() => setEditing(false)}
                  onSubmit={async text => {
                    if (text.trim() === '') {
                      openCommentDeleteModal();
                    } else {
                      await onEdit(id, text);
                    }
                    setEditing(false);
                  }}
                  value={content}
                  autoFocus
                />
              </BlockEventPropagation>
            </Box>
          ) : (
            <>
              {marker && (
                <Tooltip>
                  <Tooltip.Reference>
                    <Box
                      className="mute-on-resolve"
                      position="absolute"
                      tag="span"
                      display="inline-block"
                      top={1}
                      left={1}
                    >
                      <BlockEventPropagation>
                        <span
                          css="cursor: pointer;"
                          onClick={onClickMarker}
                          onKeyPress={onClickMarker}
                          role="button"
                          tabIndex={0}
                        >
                          <CommentAnnotationMarker id={id} index={marker} />
                        </span>
                      </BlockEventPropagation>
                    </Box>
                  </Tooltip.Reference>
                  <Tooltip.Message>
                    {isVideo ? (
                      <FormattedMessage
                        {...messages.labelShowAnnotationMarker}
                        values={{
                          isSelected: selected,
                        }}
                      />
                    ) : (
                      <FormattedMessage
                        {...messages.labelFocusAnnotationMarker}
                        values={{
                          isSelected: selected,
                        }}
                      />
                    )}
                  </Tooltip.Message>
                </Tooltip>
              )}
              <Box ml={6}>
                <Text
                  className="mute-on-resolve"
                  fontFamily="sansSerif"
                  lineHeight={4}
                  overflowWrap="break-word"
                  tag="p"
                  textStyle="body.regular"
                  wordBreak="break-word"
                  whiteSpace="pre-wrap"
                >
                  {typeof commentTime === 'number' && (
                    <>
                      <Tooltip>
                        <Tooltip.Reference>
                          <Anchor
                            color="primary"
                            textStyle="body.regular"
                            textDecoration="none"
                            tag="span"
                            lineHeight={4}
                          >
                            {msToTime(commentTime * 1000)}
                          </Anchor>
                        </Tooltip.Reference>
                        <Tooltip.Message>
                          <FormattedMessage {...messages.labelJumpToFrame} />
                        </Tooltip.Message>
                      </Tooltip>{' '}
                    </>
                  )}
                  <MarkupRenderer source={content} />
                  {edited && (
                    <Body
                      data-testid="edited-comment"
                      color="grey.600"
                      tag="span"
                      textSize="small"
                    >
                      <FormattedMessage {...messages.labelEditedComment} />
                    </Body>
                  )}
                </Text>
              </Box>
            </>
          )}
          {showPreview && previewSize === 'small' && (
            <Panel
              className="mute-on-resolve"
              borderLeft={`${space[4]}px solid transparent`}
              ml="auto"
            >
              <BlockEventPropagation>
                <span
                  css="cursor: pointer;"
                  onClick={onClickFile}
                  onKeyPress={onClickFile}
                  role="button"
                  tabIndex={0}
                >
                  <Tooltip showDelay={500}>
                    <Tooltip.Reference>
                      <SmallFilePreview
                        fileType={fileType}
                        fileSrc={fileSrc}
                        fileAlt={fileAlt}
                      />
                    </Tooltip.Reference>
                    <Tooltip.Message>
                      <FormattedMessage
                        id="Comment.messageJumpToFile"
                        defaultMessage="Jump to file"
                      />
                    </Tooltip.Message>
                  </Tooltip>
                </span>
              </BlockEventPropagation>
            </Panel>
          )}
        </CommentSection>
        <CommentFooter>
          <BlockEventPropagation>
            <Anchor
              data-intercom-target="reply-button"
              color={hasReplies ? 'primary' : 'grey.600'}
              textStyle="action.regular"
              fontWeight="regular"
              textDecoration="none"
              onClick={onClickReply}
            >
              <Box display="flex">
                <Box pr={1} className="mute-on-resolve">
                  <FormattedMessage
                    {...(!opened || hasReplies
                      ? messages.labelReply
                      : messages.labelCancel)}
                    values={{
                      numberOfReplies,
                    }}
                  />
                </Box>
                {(hasReplies || opened) && (
                  <Icon
                    className="mute-on-resolve"
                    type={
                      opened ? <KeyboardArrowUpSvg /> : <KeyboardArrowDownSvg />
                    }
                    size="small"
                  />
                )}
              </Box>
            </Anchor>
          </BlockEventPropagation>
          <Spacer sx="auto" />
          <BlockEventPropagation>
            <CommentActions>
              {actions.includes(CommentAction.ASSIGN) ? (
                <CommentAssignerDropdown
                  assignee={assignee}
                  commentId={id}
                  projectId={project.id}
                  onChangeAssignee={newAssignee => onAssign(id, newAssignee)}
                />
              ) : (
                assignee && <AssignedToToggle assignee={assignee} />
              )}
              <Spacer sx={2} />
              <Tooltip key={resolved}>
                <Tooltip.Reference>
                  <Checkbox
                    className="mute-on-resolve"
                    data-testid="resolve-checkbox"
                    checked={resolved}
                    checkedSvg={CheckCircleSvg}
                    checkedColor="emerald"
                    defaultSvg={CheckCircleSvg}
                    defaultColor="grey.400"
                    onChange={evt => onResolve(id, evt.target.checked)}
                    size="medium"
                  />
                </Tooltip.Reference>
                <Tooltip.Message>
                  {resolved ? (
                    <FormattedMessage
                      {...messages.labelResolved}
                      values={{ resolvedBy: resolver }}
                    />
                  ) : (
                    <FormattedMessage {...messages.labelResolve} />
                  )}
                </Tooltip.Message>
              </Tooltip>
            </CommentActions>
          </BlockEventPropagation>
        </CommentFooter>
      </CommentContainer>
      {commentDeleteModal}
      {opened && (
        <>
          {replies.map(reply => (
            <div
              key={reply.id}
              className={previewSize === 'large' ? 'bigger-preview' : undefined}
            >
              <CommentReply {...reply} onDelete={onDelete} onEdit={onEdit} />
            </div>
          ))}
          <FormattedMessage {...messages.labelReplyInputPlaceholder}>
            {message => (
              <ReplyInputWrapper p={2}>
                <CommentFormWithLiteUserSupport
                  placeholder={message}
                  onSubmit={onReply}
                  autoFocus
                />
              </ReplyInputWrapper>
            )}
          </FormattedMessage>
        </>
      )}
    </CommentAndRepliesContainer>
  );
}

Comment.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(CommentAction))),
  assignee: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
  }),
  author: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
  }),
  className: PropTypes.string,
  content: formattedMessage.isRequired,
  direction: PropTypes.oneOf(['left-to-right', 'right-to-left']),
  edited: PropTypes.bool,
  file: PropTypes.shape({
    alt: PropTypes.string,
    frameRate: PropTypes.number,
    isVideo: PropTypes.bool,
    type: PropTypes.oneOf(['image', 'non-visual', 'video', 'pdf']),
    previewSize: PropTypes.oneOf(['small', 'large']),
    previewType: PropTypes.oneOf(['single', 'stack']),
    showPreview: PropTypes.bool,
    src: PropTypes.string,
  }),
  opened: PropTypes.bool,
  frame: PropTypes.number,
  highlighted: PropTypes.bool,
  id: PropTypes.string,
  marker: PropTypes.number,
  project: PropTypes.shape({
    id: PropTypes.string,
  }),
  published: PropTypes.number,
  replies: PropTypes.arrayOf(
    PropTypes.shape({
      actions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(CommentAction))),
      author: PropTypes.shape({
        email: PropTypes.string,
        name: PropTypes.string,
      }),
      content: PropTypes.text,
      edited: PropTypes.bool,
      published: PropTypes.number,
      id: PropTypes.string,
    }),
  ),
  resolved: PropTypes.bool,
  resolver: PropTypes.string,
  selected: PropTypes.bool,

  onClick: PropTypes.func,
  onClickFile: PropTypes.func,
  onClickMarker: PropTypes.func,
  onClickReply: PropTypes.func,
  onAssign: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onReply: PropTypes.func,
  onResolve: PropTypes.func,
};

Comment.defaultProps = {
  actions: [],
  assignee: null,
  author: {},
  className: undefined,
  direction: undefined,
  edited: false,
  file: {},
  opened: false,
  frame: undefined,
  highlighted: false,
  id: undefined,
  marker: undefined,
  project: undefined,
  published: undefined,
  replies: [],
  resolved: false,
  resolver: undefined,
  selected: false,

  onClick: f => f,
  onClickReply: f => f,
  onClickFile: f => f,
  onClickMarker: f => f,
  onAssign: f => f,
  onDelete: f => f,
  onEdit: f => f,
  onReply: f => f,
  onResolve: f => f,
};

export default memo(Comment);

// eslint-disable-next-line react/prop-types
function BigFilePreview({ previewType, fileType, fileSrc, fileAlt }) {
  if (!fileType || !previewType) return null;

  const FilePreview = {
    image: () => <SquareImage alt={fileAlt} src={fileSrc} fit="cover" />,
    video: () => <SquareImage alt={fileAlt} src={fileSrc} fit="cover" />,
    pdf: () => <SquareImage alt={fileAlt} src={fileSrc} fit="cover" />,
    'non-visual': () => (
      <Box
        alignItems="center"
        backgroundColor="gray.200"
        display="flex"
        justifyContent="center"
        height="112px"
        width="100%"
      >
        <Icon type={<InsertDriveFileSvg />} size="xxlarge" color="gray.400" />
      </Box>
    ),
  }[fileType]();

  return {
    single: () => <span>{FilePreview}</span>,
    stack: () => <CommentPreviewStack>{FilePreview}</CommentPreviewStack>,
  }[previewType]();
}
// eslint-disable-next-line react/prop-types
function SmallFilePreview({ fileType, fileSrc, fileAlt }) {
  if (!fileType) return null;

  return {
    image: () => <CommentImage alt={fileAlt} src={fileSrc} />,
    video: () => <CommentImage alt={fileAlt} src={fileSrc} />,
    pdf: () => <CommentImage alt={fileAlt} src={fileSrc} />,
    'non-visual': () => (
      <Icon
        boxSize="xxlarge"
        borderRadius="medium"
        type={<InsertDriveFileSvg />}
        size="medium"
        color="gray.400"
        backgroundColor="gray.200"
      />
    ),
  }[fileType]();
}
