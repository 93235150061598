import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';
import {
  FolderOpen as FolderOpenSvg,
  NotificationsActive as NotificationsActiveSvg,
  NotificationsOff as NotificationsOffSvg,
} from '@styled-icons/material';

import ActionsDropdown from 'src/components/ActionsDropdown';
import { get } from 'src/utils/accessors';

import messages from '../messages';

const ProjectCardActionsDropdown = ({
  onClickInfo,
  onClickFollow,
  following,
  onClickRename,
  onClickCopy,
  onClickRemove,
  spaces,
  onClickSpace,
  onClickMove,
  onClickRestore,
  onClickMoveToTrash,
}) => (
  <ActionsDropdown page="project">
    <ActionsDropdown.Group entity="project">
      {onClickFollow && (
        <>
          <ActionsDropdown.Custom
            keepMenuOpen
            action={following ? 'unfollow project' : 'follow project'}
            label={
              <FormattedMessage
                {...(following
                  ? messages.labelUnfollowProject
                  : messages.labelFollowProject)}
              />
            }
            icon={
              following ? <NotificationsOffSvg /> : <NotificationsActiveSvg />
            }
            onClick={() => onClickFollow(!following)}
          />
          <Dropdown.Separator />
        </>
      )}
      {onClickInfo && <ActionsDropdown.Info onClick={onClickInfo} />}
      {onClickRename && (
        <ActionsDropdown.Rename entity="project" onClick={onClickRename} />
      )}
      {(onClickCopy || onClickRemove || onClickMove || onClickRemove) && (
        <Dropdown.Separator />
      )}
      {spaces.map(space => (
        <ActionsDropdown.Custom
          action="move to space"
          label={
            <FormattedMessage
              {...messages.labelMoveToSpace}
              values={{
                name: get(space, 'attributes.name'),
              }}
            />
          }
          icon={<FolderOpenSvg />}
          onClick={() => onClickSpace(space)}
        />
      ))}
      {onClickCopy && (
        <ActionsDropdown.Duplicate entity="project" onClick={onClickCopy} />
      )}
      {onClickMove && (
        <ActionsDropdown.Move entity="project" onClick={onClickMove} />
      )}
      {onClickMoveToTrash && (
        <ActionsDropdown.MoveToTrash
          entity="project"
          onClick={onClickMoveToTrash}
        />
      )}
      {onClickRemove && (
        <ActionsDropdown.Delete
          confirmMessage={<FormattedMessage {...messages.messageModalDelete} />}
          entity="project"
          onClick={onClickRemove}
        />
      )}
      {onClickRestore && (
        <ActionsDropdown.Restore entity="project" onClick={onClickRestore} />
      )}
    </ActionsDropdown.Group>
  </ActionsDropdown>
);

ProjectCardActionsDropdown.propTypes = {
  onClickInfo: PropTypes.func,
  onClickFollow: PropTypes.func,
  onClickRename: PropTypes.func,
  onClickCopy: PropTypes.func,
  onClickRemove: PropTypes.func,
  onClickSpace: PropTypes.func,
  onClickMove: PropTypes.func,
  onClickRestore: PropTypes.func,
  onClickMoveToTrash: PropTypes.func,
  following: PropTypes.bool,
  spaces: PropTypes.instanceOf(List),
};

ProjectCardActionsDropdown.defaultProps = {
  onClickInfo: undefined,
  onClickFollow: undefined,
  onClickRename: undefined,
  onClickCopy: undefined,
  onClickRemove: undefined,
  onClickSpace: undefined,
  onClickMove: undefined,
  onClickRestore: undefined,
  onClickMoveToTrash: undefined,
  following: undefined,
  spaces: List(),
};

export default ProjectCardActionsDropdown;
