import React from 'react';
import { FormattedMessage } from 'react-intl';

import EmptyStateBox from 'src/components/EmptyStateBox';
import EmptyCollectionIllustrationSvg from 'src/assets/EmptyCollectionIllustrationSvg';

import messages from '../messages';

const EmptyCollectionBox = () => (
  <EmptyStateBox
    graphic={<EmptyCollectionIllustrationSvg />}
    title={<FormattedMessage {...messages.titleEmptyCollection} />}
    message={<FormattedMessage {...messages.messageEmptyCollection} />}
  />
);

export default EmptyCollectionBox;
