import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import {
  ButtonElement as Button,
  Heading,
  SelectField,
  Spinner,
  TextField,
} from '@picter/prisma';
import { FormattedMessage } from 'react-intl';
import { Flex, Box } from '@rebass/grid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import upperFirst from 'lodash/upperFirst';

import EmbeddedCampaign from 'src/components/EmbeddedCampaign';
import { librarySpaceUrl /* librarySpacesNew3Url */ } from 'src/routes/urls';
import { historyShape } from 'src/utils/app-prop-types';
import { useToriiActions } from 'src/modules/torii';
import useFormatMessage from 'src/hooks/use-format-message';
import { REFERRAL_LINK_ID_LOCALSTORAGE_KEY } from 'src/utils/accessors/referral-link';
import useCompanySizeOptions, {
  JUST_ME_VALUE,
} from 'src/hooks/use-company-size-options';

import Layout from '../_layout';
import messages from './_messages';

const Container = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: 4,
})`
  height: 100%;
  margin: 'auto';
`;

const Form = styled(Box).attrs({
  as: 'form',
  width: 1,
})`
  max-width: 360px;
`;

function Step2Page({ history }) {
  const { create } = useToriiActions();
  const formatMessage = useFormatMessage();

  const onSubmit = useCallback(
    async (formValues, formik) => {
      const wsReferralLinkId =
        localStorage.getItem(REFERRAL_LINK_ID_LOCALSTORAGE_KEY) || undefined;

      const { data: newSpace } = await create('wsSpaces', {
        attributes: {
          ...formValues,
          viaReferralLink: wsReferralLinkId,
        },
      });

      if (wsReferralLinkId) {
        localStorage.removeItem(REFERRAL_LINK_ID_LOCALSTORAGE_KEY);
      }

      // For now, we always redirect to the space page until we have
      // a solution for bulk invitations with reCAPTCHA.
      const redirectUrl = librarySpaceUrl({ spaceId: newSpace.id });
      // formValues.segmentationTeamSize === JUST_ME_VALUE
      //   ? librarySpaceUrl({ spaceId: newSpace.id })
      //   : librarySpacesNew3Url(undefined, { spaceId: newSpace.id });

      formik.setSubmitting(false);
      history.replace(redirectUrl);
    },
    [create, history],
  );

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    dirty,
    isValid,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    onSubmit,
    initialValues: {},
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().required(formatMessage(messages.requiredField)),
      segmentationTeamSize: Yup.string()
        .ensure()
        .required(formatMessage(messages.requiredField)),
      phone: Yup.string()
        .trim()
        .max(50)
        .when('segmentationTeamSize', (teamSizeValue, schema) =>
          teamSizeValue !== JUST_ME_VALUE
            ? schema.required(formatMessage(messages.requiredField))
            : schema,
        ),
      website: Yup.string()
        .trim()
        .max(255)
        .when('segmentationTeamSize', (teamSizeValue, schema) =>
          teamSizeValue !== JUST_ME_VALUE
            ? schema.required(formatMessage(messages.requiredField))
            : schema,
        ),
    }),
  });

  const companySizeOptions = useCompanySizeOptions();

  const buildFieldProps = (name, { required = true, type = 'text' } = {}) => ({
    name,
    placeholder: formatMessage(messages[`placeholder${upperFirst(name)}`]),
    ariaLabel:
      type === 'select'
        ? formatMessage(messages[`placeholder${upperFirst(name)}`])
        : undefined,
    value: values[name],
    error: touched[name] && errors[name],
    onChange: handleChange,
    onBlur: handleBlur,
    required,
    disabled: isSubmitting,
    type,
  });

  return (
    <Layout>
      <Layout.Content>
        <Container>
          <Box mb={7}>
            <Heading textSize="large" textAlign="center" whiteSpace="pre-line">
              <FormattedMessage {...messages.pageHeader} />
            </Heading>
          </Box>

          <Form onSubmit={handleSubmit}>
            <TextField {...buildFieldProps('name')} autoFocus />
            <SelectField
              {...buildFieldProps('segmentationTeamSize', { type: 'select' })}
              options={companySizeOptions}
              displayErrorMessage
              onChange={(_, v) => setFieldValue('segmentationTeamSize', v)}
            />
            {values.segmentationTeamSize !== JUST_ME_VALUE && (
              <>
                <TextField
                  {...buildFieldProps('phone', {
                    type: 'tel',
                  })}
                />
                <TextField
                  {...buildFieldProps('website', {
                    type: 'text',
                  })}
                />
              </>
            )}
            <Box mt={4} style={{ height: '40px' }}>
              {!isSubmitting ? (
                <Button
                  type="submit"
                  color="white"
                  width={1}
                  height={40}
                  disabled={!dirty || !isValid}
                >
                  {values.segmentationTeamSize === JUST_ME_VALUE ? (
                    <FormattedMessage {...messages.labelFinishButton} />
                  ) : (
                    <FormattedMessage {...messages.labelNextButton} />
                  )}
                </Button>
              ) : (
                <Spinner />
              )}
            </Box>
          </Form>
        </Container>
      </Layout.Content>
      <Layout.Banner>
        <EmbeddedCampaign campaign="step-3-about-team" category="onboarding" />
      </Layout.Banner>
    </Layout>
  );
}

Step2Page.propTypes = {
  history: historyShape.isRequired,
};

export default Step2Page;
