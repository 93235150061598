import { defineMessages } from 'react-intl';

export default defineMessages({
  labelMyProjects: {
    id: 'ProjectPage.labelMyProjects',
    defaultMessage: 'My projects',
  },
  labelSharedwithMe: {
    id: 'ProjectPage.labelSharedWithMe',
    defaultMessage: 'Shared with me',
  },
  labelNewProject: {
    id: 'ProjectPage.labelNewProject',
    defaultMessage: 'New project',
  },
});
