import React, { useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { List, Map } from 'immutable';
import { ButtonElement as Button } from '@picter/prisma';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';
import { Done as DoneSvg } from '@styled-icons/material';
import negate from 'lodash/negate';

import GeneralLayout from 'src/layouts/GeneralLayout';
import usePermission from 'src/hooks/use-permission';
import ProjectShareState from 'src/components/ProjectShareState';
import ProjectRealtimePresenceState from 'src/components/ProjectRealtimePresenceState';
import { matchShape } from 'src/utils/app-prop-types';
import { Box, Icon, Tooltip } from 'src/modules/prisma';
import ProjectShare from 'src/containers/ProjectShare';
import ProjectTitle from 'src/components/ProjectPageTitle';
import { viewModeTypes } from 'src/routes/constants';
import generateProjectBackUrl from 'src/utils/generate-project-back-url';
import { getReviewDoneSessionStorageKey } from 'src/utils/accessors/project';
import { get } from 'src/utils/accessors';
import { isCommentReply } from 'src/utils/accessors/comment';
import SpaceAvatar from 'src/components/SpaceAvatar';

import ProjectActionsButtons from './components/ProjectPageActionsButtons';
import ActionsDropdown from './components/ProjectPageHeaderActionsDropdown';
import messages from './messages';

const ProjectTitleBox = styled(Box).attrs(({ iconsCount, ...props }) => {
  // the size of the icons (project icon + public link icon if present)
  // plus one pixel between each icon
  const iconsSize = themeGet('sizes.icon.xxlarge')(props) + iconsCount;
  // the margin from the icon to the title
  // and from the title to the buttons
  const spacesSize = themeGet('space.3')(props) * 2;

  return {
    mr: 3,
    width: `calc(100% - ((${iconsSize}px * ${iconsCount}) + ${spacesSize}px))`,
  };
})``;

function ProjectPageHeader({
  page,
  project,

  onClickDownloadProject,
  onChangeProjectTitle,
  onClickDeleteProject,
  onClickInfoButton,
  onClickComments,
  onClickReviewDone,
  onClickUploadDone,
  onClickCopyProject,
  onClickRestoreProject,
  onClickMoveToTrash,

  openFileDialog,

  PageIcon,
  following,
  onClickFollow,
}) {
  const params = useParams();
  const {
    canEditProject,
    canSeeComments,
    canShareProject,
    canManagePublicLinks,
  } = usePermission();

  const titleEl = useRef(null);
  const onClickRenameProject = useCallback(() => {
    titleEl.current.focus();
  }, [titleEl]);

  const comments = get(
    project,
    'relationships.comments',
    get(project, 'relationships.comments.data', List()),
  );
  const commentsCounter = useMemo(
    () => comments.filter(negate(isCommentReply)).size,
    [comments],
  );
  const isDefaultMode = params.viewMode === viewModeTypes.DEFAULT;

  const wsSpaceId = get(project, 'relationships.space.id');
  const wsFolderId = get(project, 'relationships.folder.id');
  const spaceName = get(project, 'relationships.space.attributes.name');
  const spaceLogo = get(project, 'relationships.space.attributes.logo.small');
  const isProjectInTrash = get(project, 'attributes.deletedAt') !== null;
  const reviewDoneSessionStorageKey = getReviewDoneSessionStorageKey(
    params.projectId,
  );
  const isReviewDone = sessionStorage.getItem(reviewDoneSessionStorageKey);

  const backUrl = generateProjectBackUrl({
    spaceId: wsSpaceId,
    folderId: wsFolderId,
    isDefaultMode,
    isProjectInTrash,
  });

  return (
    <GeneralLayout.Top>
      <GeneralLayout.Top.AlignLeft flex="1 1 auto" justifyContent="flex-start">
        <Box mr={3} display="flex">
          {isDefaultMode ? (
            <Tooltip>
              <Tooltip.Reference>
                <Link to={backUrl} data-intercom-target="back-to-workspace">
                  <SpaceAvatar
                    name={spaceName}
                    spaceId={wsSpaceId}
                    size="large"
                    src={spaceLogo}
                  />
                </Link>
              </Tooltip.Reference>
              <Tooltip.Message>
                <FormattedMessage {...messages.messageBackToProjects} />
              </Tooltip.Message>
            </Tooltip>
          ) : (
            <Tooltip>
              <Tooltip.Reference>
                <div>
                  <SpaceAvatar
                    name={spaceName}
                    spaceId={wsSpaceId}
                    size="large"
                    src={spaceLogo}
                  />
                </div>
              </Tooltip.Reference>
              <Tooltip.Message>{spaceName}</Tooltip.Message>
            </Tooltip>
          )}
          {PageIcon && (
            <div style={{ marginLeft: 2, display: 'inline' }}>{PageIcon}</div>
          )}
        </Box>
        <ProjectTitleBox iconsCount={PageIcon ? 2 : 1}>
          <ProjectTitle
            disabled={!canEditProject}
            maxWidth="100%"
            minWidth="100%"
            onChange={onChangeProjectTitle}
            textStyle="heading.regular"
            title={get(project, 'attributes.title')}
            ref={titleEl}
          />
        </ProjectTitleBox>
      </GeneralLayout.Top.AlignLeft>
      <GeneralLayout.Top.AlignRight>
        {isDefaultMode && (
          <Box mr={2}>
            <ProjectRealtimePresenceState />
          </Box>
        )}
        <Box mr={2}>
          <ProjectShareState
            project={project}
            shouldOpenShareModal={canShareProject}
            shouldOpenPublicLinkModal={canManagePublicLinks}
            showInvitations={isDefaultMode}
            showPublicLinks={isDefaultMode}
            showUsers={isDefaultMode}
          />
        </Box>
        {(canShareProject || canManagePublicLinks) && (
          <Box mr={3}>
            <ProjectShare />
          </Box>
        )}
        {canSeeComments && (
          <>
            {onClickReviewDone && (
              <Button
                borderRadius="100px"
                color="white"
                onClick={onClickReviewDone}
                textStyle="action.regular"
                lineHeight="75%"
                disabled={isReviewDone}
                data-intercom-target="review-done-button"
              >
                {isReviewDone && (
                  <Box mr={2} tag="span">
                    <Icon type={<DoneSvg />} size="xsmall" />
                  </Box>
                )}
                <FormattedMessage {...messages.labelReviewDone} />
              </Button>
            )}
            {onClickUploadDone && (
              <Button
                borderRadius="100px"
                color="white"
                onClick={onClickUploadDone}
                textStyle="action.regular"
                lineHeight="75%"
              >
                <FormattedMessage {...messages.labelUploadDone} />
              </Button>
            )}
          </>
        )}
        <ProjectActionsButtons
          commentsCounter={commentsCounter}
          onClickAddImage={openFileDialog}
          onClickComments={onClickComments}
          onClickDownload={onClickDownloadProject}
          onClickInfo={onClickInfoButton}
        />
        <ActionsDropdown
          onClickCopyProject={onClickCopyProject}
          onClickDeleteProject={onClickDeleteProject}
          onClickRenameProject={onClickRenameProject}
          following={following}
          onClickFollow={onClickFollow}
          onClickRestoreProject={onClickRestoreProject}
          onClickMoveToTrash={onClickMoveToTrash}
          page={page}
        />
      </GeneralLayout.Top.AlignRight>
    </GeneralLayout.Top>
  );
}

ProjectPageHeader.propTypes = {
  page: PropTypes.string.isRequired,
  project: PropTypes.instanceOf(Map).isRequired,
  match: matchShape.isRequired,
  following: PropTypes.bool,

  onClickDownloadProject: PropTypes.func.isRequired,
  onChangeProjectTitle: PropTypes.func.isRequired,
  onClickDeleteProject: PropTypes.func.isRequired,
  onClickInfoButton: PropTypes.func.isRequired,
  onClickComments: PropTypes.func.isRequired,
  onClickCopyProject: PropTypes.func.isRequired,
  onClickReviewDone: PropTypes.func,
  onClickUploadDone: PropTypes.func,
  onClickFollow: PropTypes.func,
  onClickRestoreProject: PropTypes.func,
  onClickMoveToTrash: PropTypes.func,

  openFileDialog: PropTypes.func.isRequired,
  openShareProjectModal: PropTypes.func.isRequired,
  PageIcon: PropTypes.element,
};

ProjectPageHeader.defaultProps = {
  onClickReviewDone: undefined,
  onClickUploadDone: undefined,
  PageIcon: null,
  following: undefined,
  onClickFollow: undefined,
  onClickRestoreProject: undefined,
  onClickMoveToTrash: undefined,
};

export default ProjectPageHeader;
