import { defineMessages } from 'react-intl';

export default defineMessages({
  labelProjectsCount: {
    id: 'FolderCard.labelProjectsCount',
    defaultMessage: '{count} projects',
  },
  titleRename: {
    id: 'FolderCard.titleRename',
    defaultMessage: 'Rename Folder',
  },
  labelRename: {
    id: 'FolderCard.ActionsDropdown.labelRename',
    defaultMessage: 'Rename',
  },
  labelRemove: {
    id: 'FolderCard.ActionsDropdown.labelRemove',
    defaultMessage: 'Delete',
  },
  messageModalDelete: {
    id: 'FolderCard.ActionsDropdown.messageModalDelete',
    defaultMessage:
      'Are you sure, you want to delete this folder and move all its projects to trash?',
  },
  messageRenamedSuccess: {
    id: 'FolderCard.ActionsDropdown.messageFolderRenamedSuccess',
    defaultMessage: 'Folder title changed successfully.',
  },
  messageRemovedSuccess: {
    id: 'FolderCard.ActionsDropdown.messageFolderRemovedSuccess',
    defaultMessage: 'Folder removed successfully.',
  },
});
