import React, { memo, useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import AutoSizer from 'react-virtualized-auto-sizer';
import DynamicSizeList from 'src/components/DynamicSizeList';

import ActivityStreamRow from './ActivityStreamRow';

const FETCH_MORE_THRESHOLD = 1;
const DEFAULT_NOTIFICATION_SIZE = 76;

// This function should be outside of the component so we can
// create it only on mount and store the lastOlderId.
// With the lastOlderId, we can avoid calling loadMore with the same
// Parameters. Its needed to be done this way because sometimes the scroll
// component calls it multiple times before the fetching/hasMore value is
// updated, resulting in repeated comments on the state.
const createCheckFetchOldData = () => {
  // Using these variables is faster and more reliable than using state or lodash debounce itself
  let lastOlderId = null;
  let debouncing = false;

  return async ({
    params,
    hasMore,
    currentRef,
    fetchMore,
    currentOldestActivity,
  }) => {
    if (
      hasMore &&
      lastOlderId !== currentOldestActivity.group &&
      !debouncing &&
      params.visibleStopIndex > FETCH_MORE_THRESHOLD &&
      currentRef &&
      currentRef.state.scrollDirection === 'forward'
    ) {
      lastOlderId = currentOldestActivity.group;
      debouncing = true;
      await fetchMore(currentOldestActivity);
      // avoids multiple calls before render
      setTimeout(() => {
        debouncing = false;
      }, 400);
    }
  };
};

const ActivityStreamRenderer = memo(({ data, hasMore, fetchMore }) => {
  const listRef = useRef(null);
  const checkFetchOldData = useMemo(() => createCheckFetchOldData(), []);
  const getDefaultSize = useCallback(() => DEFAULT_NOTIFICATION_SIZE, []);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <DynamicSizeList
          ref={listRef}
          height={height}
          width={width}
          itemCount={data.length}
          itemData={data}
          onItemsRendered={params => {
            checkFetchOldData({
              params,
              hasMore,
              currentRef: listRef.current,
              fetchMore,
              currentOldestActivity: data[data.length - 1],
            });
          }}
          getDefaultSize={getDefaultSize}
        >
          {ActivityStreamRow}
        </DynamicSizeList>
      )}
    </AutoSizer>
  );
});

ActivityStreamRenderer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  hasMore: PropTypes.bool,
  fetchMore: PropTypes.func,
};

ActivityStreamRenderer.defaultProps = {
  data: null,
  hasMore: false,
  fetchMore: null,
};

export default ActivityStreamRenderer;
