import { defineMessages } from 'react-intl';

export default defineMessages({
  labelNewWorkspace: {
    id: 'NewWorkspaceButton.labelNewWorkspace',
    defaultMessage: 'New workspace',
  },
  messageCreateWorkspace: {
    id: 'NewWorkspaceButton.messageCreateWorkspace',
    defaultMessage:
      'Hey, why don’t you get a new<br>fresh workspace just for you?!',
  },
});
