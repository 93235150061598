import React from 'react';

const CurvedRightArrowSVG = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="100%"
    width="100%"
    viewBox="0 0 126 64"
    preserveAspectRatio="none"
    {...props}
  >
    <path
      fill="#DFD7E7"
      fillRule="evenodd"
      d="M109.46 39.238c-.517.553 4.598 6.259 7.86 9.788C10.04 39.118.298 1.268.009.032c.506 2.279 11.828 46.949 116.028 54.989-3.694 3.116-8.633 7.449-8.224 8.014.39.54 16.417-9.413 16.417-9.413a1.999 1.999 0 0 0 .196-3.078s-14.206-12.11-14.964-11.306z"
    />
  </svg>
);

export default CurvedRightArrowSVG;
