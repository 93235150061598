import styled from 'styled-components/macro';

import { truncate } from './mixins';
import Element from './Element';

const Truncate = styled(Element)`
  ${truncate}
`;

Truncate.defaultProps = {
  blacklist: [...Element.defaultProps.blacklist, ...truncate.propNames],
  tag: 'span',
};

Truncate.displayName = 'Truncate';

export default Truncate;
