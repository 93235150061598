import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { FormattedMessage } from 'react-intl';

import { Body, Box, Tooltip } from 'src/modules/prisma';
import { get } from 'src/utils/accessors';
import IconGroup from 'src/components/IconGroup';
import { returnInCase } from 'src/utils/in-case';

import ApprovalsAvatars from './ApprovalsAvatars';
import ApprovedByOthersIcon from './ApprovedByOthersIcon';
import ApprovedByUserIcon from './ApprovedByUserIcon';
import NoApprovalsIcon from './NoApprovalsIcon';
import RejectedIcon from './RejectedIcon';
import messages from '../messages';

const FileProofingIcon = forwardRef(
  (
    {
      approvals,
      approvedByUser,
      rejected,
      rejectedBy,
      onClickApprove,
      onClickReject,
      showTooltip,
      ...props
    },
    ref,
  ) => {
    const numberOfApprovals =
      typeof approvals === 'number' ? approvals : approvals.size;

    return returnInCase({
      [numberOfApprovals > 0]: () =>
        // clicking on approved by others icon sends an approve true to add the approval
        approvedByUser ? (
          <ApprovedByUserIcon
            {...props}
            data-testid="image-proofing-approved-by-user"
            numberOfApprovals={numberOfApprovals}
            onClick={event => onClickApprove(event, false)}
            ref={ref}
          />
        ) : (
          <ApprovedByOthersIcon
            {...props}
            data-testid="image-proofing-approved-by-others"
            numberOfApprovals={numberOfApprovals}
            onClick={event => onClickApprove(event, true)}
            ref={ref}
          />
        ),
      [rejected]: () => (
        <RejectedIcon {...props} onClick={onClickReject} ref={ref} />
      ),
      default: () => (
        <NoApprovalsIcon
          {...props}
          data-testid="image-proofing-no-approvals"
          onClick={event => onClickApprove(event, true)}
          ref={ref}
        />
      ),
    });
  },
);

FileProofingIcon.defaultProps = {
  approvals: List(),
  approvedByUser: undefined,
  boxSize: 'large',
  showTooltip: false,
  onClickApprove: undefined,
  onClickReject: undefined,
  rejected: undefined,
  rejectedBy: undefined,
  size: 'small',
};

FileProofingIcon.propTypes = {
  approvals: PropTypes.oneOfType([
    PropTypes.instanceOf(List),
    PropTypes.number,
  ]),
  approvedByUser: PropTypes.bool,
  boxSize: PropTypes.string,
  showTooltip: PropTypes.bool,
  onClickApprove: PropTypes.func,
  onClickReject: PropTypes.func,
  rejected: PropTypes.bool,
  rejectedBy: PropTypes.instanceOf(Map),
  size: PropTypes.string,
};

export default FileProofingIcon;

export function FileProofingTooltip({
  approvals,
  children,
  rejected,
  rejectedBy,
}) {
  const numberOfApprovals =
    typeof approvals === 'number' ? approvals : approvals.size;

  return returnInCase({
    [numberOfApprovals > 0]: () => (
      // clicking on approved by others icon sends an approve true to add the approval
      <Tooltip placement="bottom">
        <Tooltip.Reference>{children}</Tooltip.Reference>
        <Tooltip.Panel>
          {state =>
            state.visible && (
              <Box m={3}>
                <IconGroup flexWrap="wrap" outline="grey.900" overlapSize={1}>
                  <ApprovalsAvatars approvals={approvals} />
                </IconGroup>
                <Box mt={1}>
                  <Body color="grey.400" textAlign="center" textSize="small">
                    <FormattedMessage {...messages.labelApprovedThis} />
                  </Body>
                </Box>
              </Box>
            )
          }
        </Tooltip.Panel>
      </Tooltip>
    ),
    [rejected]: () => (
      <Tooltip placement="bottom">
        <Tooltip.Reference>{children}</Tooltip.Reference>
        <Tooltip.Message>
          <FormattedMessage
            {...messages.labelRejectedBy}
            values={{
              user: get(
                rejectedBy,
                'attributes.publicName',
                get(rejectedBy, 'attributes.email'),
              ),
            }}
          />
        </Tooltip.Message>
      </Tooltip>
    ),
    default: () => (
      <Tooltip placement="bottom">
        <Tooltip.Reference>{children}</Tooltip.Reference>
        <Tooltip.Message>
          <FormattedMessage {...messages.labelApprove} />
        </Tooltip.Message>
      </Tooltip>
    ),
  });
}
