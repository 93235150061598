import { defineMessages } from 'react-intl';

export default defineMessages({
  messageUserPresent: {
    id: 'ProjectRealtimePresenceState.messageUserPresent',
    defaultMessage: '{name} is online',
  },
  messageUserObserving: {
    id: 'ProjectRealtimePresenceState.messageUserObserving',
    defaultMessage: '{name} is observing you',
  },
});
