import styled from 'styled-components/macro';
import { Group } from 'reakit/Group';

const Accordion = styled(Group).attrs({
  as: 'section',
})`
  .accordion-item__disclosure {
    position: relative;
    z-index: 2;
  }
  .accordion-item__content {
    position: relative;
    z-index: 1;
  }
`;

Accordion.displayName = 'Accordion';

export default Accordion;
