import React, { useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { ContextualTextField } from 'src/modules/prisma';
import { refShape } from 'src/utils/app-prop-types';
import useUpdatableState from 'src/hooks/use-updatable-state';

const ImageCardEditableTitle = ({
  value: title,
  forwardedRef,
  onChange,
  disabled,
}) => {
  const [value, setValue] = useUpdatableState(title);
  const handleChange = useCallback(e => setValue(e.target.value), [setValue]);

  return (
    <ContextualTextField
      className="image-card-editable-title"
      color="grey.600"
      maxLength="100"
      minWidth="100px"
      onBlur={onChange}
      onChange={handleChange}
      placeholder="Untitled"
      textAlign="center"
      textStyle="action.small"
      ref={forwardedRef}
      value={value}
      disabled={disabled}
    />
  );
};

ImageCardEditableTitle.propTypes = {
  forwardedRef: refShape.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.node,
  disabled: PropTypes.bool,
};

ImageCardEditableTitle.defaultProps = {
  value: null,
  disabled: false,
};

export default forwardRef((props, ref) => (
  <ImageCardEditableTitle forwardedRef={ref} {...props} />
));
