import React from 'react';
import { Route } from 'react-router-dom';
import { useCurrentUser } from 'src/hooks/use-resource';
import RouteUnauthenticated from 'src/utils/cerberus/components/RouteUnauthenticated';
import { get } from 'src/utils/accessors';

// Lite users have a special authentication, that allows them to
// do some reduced actions without needing a full account. Nevertheless,
// they have a user object in the store and are authenticated, so we need
// another way to distinguish them from regular users.
// This component allows lite users to access routes, that are actually
// restricted for logged in users (e.g. signup and login pages).

const RouteUnauthenticatedPlusLiteUsers = props => {
  const user = useCurrentUser(undefined, { request: false });
  if (get(user, 'attributes.liteAccountEmail')) {
    return <Route {...props} />;
  }

  return <RouteUnauthenticated {...props} />;
};

export default RouteUnauthenticatedPlusLiteUsers;
