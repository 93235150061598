import { makeMemoSelect } from 'src/modules/torii';
import {
  createSelector,
  createSelectorCreator,
  defaultMemoize,
} from 'reselect';
import { Map } from 'immutable';

import { get, set } from 'src/utils/accessors';

const makeGetProjects = memoizeForEntities => {
  const memoSelect = makeMemoSelect(memoizeForEntities);

  return createSelector(
    state => state,
    (_, props) => props,
    (state, props) =>
      memoSelect(state, 'wsProjects', props && { include: props.include }),
  );
};

const makeGetPublicLinks = memoizeForEntities => {
  const memoSelect = makeMemoSelect(memoizeForEntities);

  return createSelector(
    state => state,
    (_, props) => props,
    (state, props) =>
      memoSelect(state, 'wsPublicLinks', props && { include: props.include }),
  );
};

const projectsMappedByPublicKeySelectorMemoization = (
  currentVal,
  previousVal,
) => currentVal.size === previousVal.size;

const projectsMappedByPublicKeySelectorCreator = createSelectorCreator(
  defaultMemoize,
  projectsMappedByPublicKeySelectorMemoization,
);

const makeProjectsMappedByPublicKeySelector = () => {
  const getProjects = makeGetProjects(['wsProjects']);

  return projectsMappedByPublicKeySelectorCreator(getProjects, projects =>
    projects.reduce(
      (map, project) =>
        set(map, get(project, 'attributes.publicUrlKey'), get(project, 'id')),
      Map(),
    ),
  );
};

const makeProjectsMappedByPublicLinkSelector = () => {
  const getPublicLinks = makeGetPublicLinks(['wsPublicLinks']);
  return projectsMappedByPublicKeySelectorCreator(getPublicLinks, publicLinks =>
    publicLinks.reduce(
      (map, publicLink) =>
        set(
          map,
          get(publicLink, 'attributes.key'),
          get(publicLink, 'relationships.project.data.id'),
        ),
      Map(),
    ),
  );
};

export const projectsMappedByPublicKeySelector = makeProjectsMappedByPublicKeySelector();

export const projectsMappedByPublicLinkSelector = makeProjectsMappedByPublicLinkSelector();

export const projectIdByPublicKeySelector = createSelector(
  state => state,
  (_, props) => props,
  projectsMappedByPublicKeySelector,
  (_, props, projectsByPublicKey) => {
    if (props.publicKey) {
      return get(projectsByPublicKey, props.publicKey);
    }

    return undefined;
  },
);

export const projectIdByPublicLinkSelector = createSelector(
  state => state,
  (_, props) => props,
  projectsMappedByPublicLinkSelector,
  (_, props, projectsByPublicLink) => {
    if (props.publicLink) {
      return get(projectsByPublicLink, props.publicLink);
    }

    return undefined;
  },
);
