import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { DragLayer } from 'react-dnd';

import StackedDragPreview from './StackedDragPreview';

const layerStyles = {
  position: 'fixed',
  overflow: 'visible',
  zIndex: 100,
};

const getItemStyles = (item, currentOffset) => {
  // Checking item.width fixed error when dropping files
  if (!currentOffset || !item.width) {
    return {
      display: 'none',
    };
  }
  let { x, y } = currentOffset;

  const height = item.height / 2;

  // Small hack to push the cards component to the right as it starts growing
  // when we start rotating it for bulk drag and drop
  // reminder: we need to keep the components away from the cursor otherwise
  // we cant scroll
  const valueBasedOnCardSize = item.gridWidth / 100;

  x += valueBasedOnCardSize;
  y -= height / 8;

  return {
    // transform: `translate(${x}px, ${y}px)`,
    // willChange: 'transform',
    position: 'absolute',
    top: `${y}px`,
    left: `${x}px`,
    width: `${item.width.substring(0, item.width.length - 1) / 2}%`,
    height,
  };
};

const collect = monitor => {
  return {
    item: monitor.getItem(),
    isDragging: monitor.isDragging(),
  };
};

const CardDragLayer = ({ item, isDragging }) => {
  const ref = useRef(null);
  const [initialStyle, setInitialStyle] = useState(null);

  useEffect(() => {
    const handleDragOver = event => {
      // Using a setTimeout with no timer makes the thread respect UI inputs
      // so we can avoid freezing or slowing the user experience
      setTimeout(() => {
        if (!initialStyle && isDragging) {
          // We only set the initial style once for every time user
          // starts to drag
          setInitialStyle(
            getItemStyles(item, {
              x: event.clientX,
              y: event.clientY,
            }),
          );
        }
        if (ref.current && isDragging) {
          // We set the style via ref because its faster and more performant than
          // using state and rerendering the components
          const { top, left, width, height } = getItemStyles(item, {
            x: event.clientX,
            y: event.clientY,
          });
          ref.current.style.position = 'absolute';
          ref.current.style.top = top;
          ref.current.style.left = left;
          ref.current.style.width = width;
          ref.current.style.height = height;
        }
      });
    };
    document.addEventListener('dragover', handleDragOver);
    return () => document.removeEventListener('dragover', handleDragOver);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging, item, initialStyle]);

  useEffect(() => {
    // Reset initial style when user stops dragging
    if (!isDragging) {
      setInitialStyle(null);
    }
  }, [isDragging]);

  if (!isDragging || !initialStyle) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div
        style={{
          width: item.gridWidth - item.gridPaddingLeft - item.gridPaddingRight,
          // height: 0 is a hack that we must use in order to make the scroll work
          // It removes the parent div, allowing the user to hover the grid and scroll
          height: 0,
        }}
      >
        <div ref={ref} style={initialStyle}>
          <StackedDragPreview item={item} />
        </div>
      </div>
    </div>
  );
};

CardDragLayer.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    gridWidth: PropTypes.number.isRequired,
    gridPaddingLeft: PropTypes.number.isRequired,
    gridPaddingRight: PropTypes.number.isRequired,
  }).isRequired,
  initialSourceOfsset: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  isDragging: PropTypes.bool.isRequired,
};

CardDragLayer.defaultProps = {
  initialSourceOfsset: null,
};

export default DragLayer(collect)(CardDragLayer);
