import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, withHandlers } from 'recompose';
import { FormattedMessage } from 'react-intl';

import { api } from 'src/modules/torii';
import { Dropdown } from 'src/modules/prisma';
import { logout } from 'src/utils/cerberus/actions';
import CurrentUserAvatar from 'src/containers/CurrentUserAvatar';
import { historyShape } from 'src/utils/app-prop-types';
import { librarySettingsPersonalProfileUrl } from 'src/routes/urls';
import { get } from 'src/utils/accessors';

import messages from 'src/messages';

function AccountMenu({
  authenticated,
  handleLogout,
  liteUser,
  toggleElement,
  history,
}) {
  if (!authenticated) return null;

  return (
    <Dropdown icon={toggleElement}>
      <Dropdown.Menu>
        {!liteUser && (
          <Dropdown.Item
            icon="account_circle"
            label={<FormattedMessage {...messages.labelAccountSettings} />}
            onClick={() => history.push(librarySettingsPersonalProfileUrl())}
            link
          />
        )}
        <Dropdown.Item
          icon="power_settings_new"
          label={<FormattedMessage {...messages.labelLogout} />}
          onClick={handleLogout}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}

AccountMenu.propTypes = {
  authenticated: PropTypes.bool,
  liteUser: PropTypes.bool,
  toggleElement: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  handleLogout: PropTypes.func.isRequired,
  history: historyShape.isRequired,
};

AccountMenu.defaultProps = {
  authenticated: false,
  liteUser: false,
  toggleElement: <CurrentUserAvatar size="large" />,
};

AccountMenu.displayName = 'AccountMenu';

const mapStateToProps = state => ({
  authenticated: get(state, 'authentication.authenticated'),
  liteUser: !!get(
    state,
    `api.users.${get(state, 'user.id')}.attributes.liteAccountEmail`,
  ),
});

const handlers = {
  handleLogout: props => () => {
    props.logout({
      endpointHost: process.env.REACT_APP_API_URL,
      onSuccess: () => {
        props.history.push('/login');
        props.resetStore();
      },
    });
  },
};

export default compose(
  withRouter,
  connect(mapStateToProps, { logout, resetStore: api.resetStore }),
  withHandlers(handlers),
)(AccountMenu);
