import React, { useMemo } from 'react';
import { $Keys } from 'utility-types';
import styled from 'styled-components/macro';
import { Card } from '@picter/prisma';
import { Link } from 'react-router-dom';
import { FormattedRelativeTime } from 'react-intl';
import {
  Photo as PhotoSvg,
  Filter as FilterSvg,
  ChatBubbleOutline as ChatBubbleOutlineSvg,
  PhotoLibrary as PhotoLibrarySvg,
} from '@styled-icons/material';

import { Box, Body, Icon } from 'src/modules/prisma';
import { getDeltaTimeAndUnit } from 'src/utils/date';

import HighlightedText from '../../styles/HighlightedText';

import ProjectShareState from '../ProjectShareState';

type Props = {
  id: string;
  image: string;
  title?: string;
  fadedTitle?: boolean;
  body?: string;
  info: string;
  url: string;
  createdAt: string;
  updatedAt: string;
  // eslint-disable-next-line react/no-unused-prop-types
  sharing: string[];
};

const iconsMap = {
  file: PhotoSvg,
  project: FilterSvg,
  comment: ChatBubbleOutlineSvg,
};

const CardAction = styled(Box).attrs({
  mt: 2,
})`
  cursor: pointer;
`;

const CoverPlaceholder = styled(Icon).attrs({
  color: 'grey.600',
  'data-testid': 'project-card-cover-placeholder',
  glyphSize: 'small',
})`
  border-radius: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
`;

type ImageProps = {
  size: number;
  url: string;
  title?: string;
};

function Image({ size, url, title }: ImageProps) {
  return (
    <Box
      display="flex"
      size={size}
      minHeight={size}
      minWidth={size}
      m={1}
      bg="grey.100"
      alignItems="center"
      justifyContent="center"
    >
      {!url ? (
        <CoverPlaceholder type={<PhotoLibrarySvg />} />
      ) : (
        <img
          style={{
            maxHeight: '100%',
            maxWidth: '100%',
          }}
          alt={`${title}'s cover`}
          src={url}
        />
      )}
    </Box>
  );
}

function SearchResultDesktop({
  id,
  image,
  title,
  fadedTitle,
  body,
  info,
  url,
  createdAt,
  updatedAt,
  sharing,
}: Props) {
  const { value: deltaTimeValue, unit: deltaTimeUnit } = useMemo(
    () => getDeltaTimeAndUnit(new Date(updatedAt || createdAt)),
    [createdAt, updatedAt],
  );

  const [type] = id.split('_') as [$Keys<typeof iconsMap>];
  const EntityIcon = iconsMap[type];

  return (
    <Link to={url}>
      <CardAction>
        <Card>
          <Card.Content px={0}>
            <Box display="flex">
              <Image title={title} url={image} size={92} />
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                flexGrow={1}
                p={3}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  flexGrow={1}
                  mr={3}
                >
                  <Box mb={4}>
                    <Body
                      textSize="medium"
                      color={fadedTitle && 'grey.600'}
                      fontWeight="light"
                    >
                      <HighlightedText
                        dangerouslySetInnerHTML={{ __html: title ?? '' }}
                      />
                    </Body>
                    {body && (
                      <Body
                        textSize="small"
                        color="grey.600"
                        fontWeight="light"
                      >
                        <HighlightedText
                          dangerouslySetInnerHTML={{ __html: body }}
                        />
                      </Body>
                    )}
                  </Box>
                  <Box display="inline-flex" alignItems="baseline">
                    {EntityIcon && (
                      <Box mr={1}>
                        <Icon
                          type={<EntityIcon />}
                          size="small"
                          color="muted"
                        />
                      </Box>
                    )}
                    <Body textSize="small" color="grey.600" fontWeight="light">
                      <HighlightedText
                        dangerouslySetInnerHTML={{ __html: info }}
                      />
                    </Body>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <ProjectShareState sharing={sharing} />
                  <Body textSize="small" color="grey.600" fontWeight="light">
                    <FormattedRelativeTime
                      numeric="auto"
                      unit={deltaTimeUnit}
                      value={deltaTimeValue}
                    />
                  </Body>
                </Box>
              </Box>
            </Box>
          </Card.Content>
        </Card>
      </CardAction>
    </Link>
  );
}

function SearchResultMobile({
  id,
  image,
  title,
  fadedTitle,
  body,
  info,
  url,
  createdAt,
  updatedAt,
}: Props) {
  const { value: deltaTimeValue, unit: deltaTimeUnit } = useMemo(
    () => getDeltaTimeAndUnit(new Date(updatedAt || createdAt)),
    [createdAt, updatedAt],
  );

  const [type] = id.split('_') as [$Keys<typeof iconsMap>];
  const EntityIcon = iconsMap[type];

  return (
    <Link to={url}>
      <CardAction>
        <Card>
          <Card.Content px={0}>
            <Box display="flex" flexDirection="column" p={2}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                flexGrow={1}
              >
                <Image title={title} url={image} size={46} />
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  flexGrow={1}
                  mb={3}
                >
                  <Body
                    textSize="medium"
                    color={fadedTitle && 'grey.600'}
                    fontWeight="light"
                  >
                    <HighlightedText
                      dangerouslySetInnerHTML={{ __html: title ?? '' }}
                    />
                  </Body>
                  <Body textSize="small" color="grey.600" fontWeight="light">
                    <FormattedRelativeTime
                      numeric="auto"
                      unit={deltaTimeUnit}
                      value={deltaTimeValue}
                    />
                  </Body>
                </Box>
              </Box>
              <Box>
                {body && (
                  <Body textSize="small" color="grey.600" fontWeight="light">
                    <HighlightedText
                      dangerouslySetInnerHTML={{ __html: body }}
                    />
                  </Body>
                )}
                <Box display="inline-flex" alignItems="baseline">
                  {EntityIcon && (
                    <Box mr={1}>
                      <Icon type={<EntityIcon />} size="small" color="muted" />
                    </Box>
                  )}
                  <Body textSize="small" color="grey.600" fontWeight="light">
                    <HighlightedText
                      dangerouslySetInnerHTML={{ __html: info }}
                    />
                  </Body>
                </Box>
              </Box>
            </Box>
          </Card.Content>
        </Card>
      </CardAction>
    </Link>
  );
}

export default function SearchResult({
  isMobile,
  ...props
}: Props & { isMobile: boolean }) {
  return isMobile ? (
    <SearchResultMobile {...props} />
  ) : (
    <SearchResultDesktop {...props} />
  );
}
