import styled from 'styled-components/macro';
import { transition } from '@picter/prisma';

import { Box } from 'src/modules/prisma';

const TranslateBox = styled(Box)`
  transition: ${transition({ property: 'transform' })};
  transform: translateY(${props => props.translateY}px);
`;

TranslateBox.defaultProps = {
  blacklist: [...Box.defaultProps.blacklist, 'translateY'],
};

export default TranslateBox;
