import { createContext, useContext } from 'react';

export function createContextWithAccessors<A>(defaultContext?: A) {
  const Context = createContext<A | undefined>(defaultContext);
  function useAccessors() {
    const c = useContext(Context);
    if (!c) throw new Error('`useAccessors` must be used within a `Context`.');
    return c;
  }
  return [Context, useAccessors] as const;
}

export function getRelativeMousePosition(
  event: React.MouseEvent<HTMLDivElement>,
) {
  const { clientX, clientY, currentTarget } = event;
  const { left, top, height, width } = currentTarget.getBoundingClientRect();

  const x = clientX - left; // x position within the element.
  const y = clientY - top; // y position within the element.

  return {
    x: x / width,
    y: y / height,
  };
}

// Calcluate a reasonable stroke width for PathMarkup.
// The bigger the image, the bigger the necessary strokeWidth.
// Otherwise very large images have very thin markup paths in
// fit-to-screen view (which is the default view for photo and video
// reviews). Images with a very high ratio (e.g.website screenshots)
// will only use the short side to calculate the stroke width, since
// they are often reviewed in 100% mode and thus need a rather thin
// stroke width.

export function getScaledStrokeWidth(
  targetWidth: number,
  targetHeight: number,
) {
  const long = Math.max(targetWidth, targetHeight);
  const short = Math.min(targetWidth, targetHeight);
  const ratio = long / short;
  // When we only used web-preview files for review, 7 was the
  // default stroke width for images with ~1500px on the long edge.
  // That's why we use these as constants as reference values in the
  // calculation. The stroke width should never be less than 7.
  return ratio > 3
    ? 7 * Math.max(1, short / 1500)
    : 7 * Math.max(1, long / 1500);
}
