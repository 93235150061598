import { useState, useCallback, useMemo } from 'react';

export default function useHoverEvents() {
  const [hovering, setHovering] = useState(false);
  const enableHovering = useCallback(() => setHovering(true), [setHovering]);
  const disableHovering = useCallback(() => setHovering(false), [setHovering]);

  const events = useMemo(
    () => ({
      onFocus: enableHovering,
      onBlur: disableHovering,
      onMouseEnter: enableHovering,
      onMouseLeave: disableHovering,
      onDragStart: disableHovering,
    }),
    [enableHovering, disableHovering],
  );

  return [hovering, events];
}
