import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { getProjectSpace } from 'src/utils/accessors/project';

import SpaceFlagsProvider from './SpaceFlagsProvider';

function ProjectFlagsProvider({ children, project }) {
  const space = getProjectSpace(project);
  return <SpaceFlagsProvider space={space}>{children}</SpaceFlagsProvider>;
}

ProjectFlagsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  project: PropTypes.instanceOf(Map).isRequired,
};

export default ProjectFlagsProvider;
