import { get } from 'src/utils/accessors';

export const DELIMITER = '.';

export const Operator = Object.freeze({
  ASC: 'asc',
  DESC: 'desc',
});

/**
 * @function comparator
 * @param {string} attribute - Attribute name in camel case.
 * @param {Operator} operator - Tells the comparator to use ascending or descending order.
 * @param {Object} options
 * @param {Function} options.transform - Transform object values before comparing.
 * @returns {Function} - Comparator function that receives two objects and compares them.
 */
export function comparator(
  attribute,
  operator,
  {
    /**
     * Transform function is useful to alter attr values before comparing them.
     * e.g. Upper case letters are ordered before lower case (Z > a). Lower case them all.
     */
    transform = f => f,
  } = {},
) {
  const modifier = {
    [Operator.ASC]: 1,
    [Operator.DESC]: -1,
  }[operator];

  return (resourceA, resourceB) => {
    const attributeA = transform(get(resourceA, ['attributes', attribute]));
    const attributeB = transform(get(resourceB, ['attributes', attribute]));

    if (attributeA === attributeB) return 0;

    // empty, null or undefined values
    if (!attributeA) return 1 * modifier;
    if (!attributeB) return -1 * modifier;

    if (attributeA > attributeB) return 1 * modifier;
    if (attributeA < attributeB) return -1 * modifier;

    return 0;
  };
}

/**
 * Operator object that contains utility functions to format a string
 * with the attribute plus operator.
 *
 * @example
 * // ATTRIBUTE is the BuilderAttribute
 * builder.ATTRIBUTE.ASC() // attribute.asc
 *
 * @typedef BuilderAttribute
 * @type {Object}
 * @property {Function} ASC - Use ascending order. BuilderOperator
 * @property {Function} DESC - Use descending order. BuilderOperator
 */

/**
 * Builder Proxy object
 *
 * @typedef Builder
 * @type {Object}
 * @property {(BuilderAttribute|Function)} [attribute]
 */

/**
 * Factory function used to create sorting related query strings.
 *
 * Uses Proxy behind the scenes to trap (capture) get & set accessors to
 * detect which property is being accessed and return not just the string but
 * also helper functions to use operators.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy
 *
 * @example
 * const sort = builder({
 *   NAME: 'name',
 * });
 * sort.NAME() // name
 * sort.NAME.ASC() // name.asc
 *
 * @name builder
 * @param {Object} attrs - Attributes related to a resource.
 * @returns {Builder}
 */
export function builder(attrs) {
  return new Proxy(attrs, {
    get(object, attr) {
      return Object.assign(() => object[attr], {
        ASC: function asc() {
          return `${this()}${DELIMITER}${Operator.ASC}`;
        },
        DESC: function desc() {
          return `${this()}${DELIMITER}${Operator.DESC}`;
        },
      });
    },
    set() {
      return false;
    },
  });
}
