import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import omit from 'lodash/omit';
import qs from 'qs';

import EmptyStateBox from 'src/components/EmptyStateBox';
import EmptyFilterIllustrationSvg from 'src/assets/EmptyFilterIllustrationSvg';

import messages from '../messages';

function EmptyFilterBox() {
  const { pathname, state, query = {} } = useLocation();
  const search = qs.stringify(omit(query, ['filter']), {
    addQueryPrefix: true,
  });

  return (
    <EmptyStateBox
      graphic={<EmptyFilterIllustrationSvg />}
      title={<FormattedMessage {...messages.titleEmptyFilter} />}
      message={
        <FormattedMessage
          {...messages.messageEmptyFilter}
          values={{
            a: chunks => <Link to={{ pathname, search, state }}>{chunks}</Link>,
          }}
        />
      }
    />
  );
}

export default EmptyFilterBox;
