import { defineMessages } from 'react-intl';

export default defineMessages({
  messageQuickBreak: {
    id: 'MaintenancePage.messageQuickBreak',
    defaultMessage: 'Our systems are on a quick break',
  },
  messaageWorkingHard: {
    id: 'MaintenancePage.messaageWorkingHard',
    defaultMessage: `We're working hard to get back up and running as soon as possible.`,
  },
});
