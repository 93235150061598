import React, { createContext, useContext } from 'react';

const AccordionItemContext = createContext();

export function AccordionItemProvider(props) {
  return <AccordionItemContext.Provider {...props} />;
}

export function useAccordionItem() {
  return useContext(AccordionItemContext);
}
