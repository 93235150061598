import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spinner } from '@picter/prisma';

import * as actions from '../actions';

export const AuthenticationContext = createContext({});

function AuthenticationProvider({
  children,
  endpointHost,
  logout,
  signup,
  login,
  checkAuth,
}) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkAuth({
      endpointHost,
      onSuccess() {
        setLoading(false);
      },
      onFailure() {
        setLoading(false);
      },
    });
  }, [endpointHost, checkAuth, setLoading]);

  const context = {
    endpointHost,
    logout,
    signup,
    login,
    checkAuth,
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <AuthenticationContext.Provider value={context}>
      {children}
    </AuthenticationContext.Provider>
  );
}

AuthenticationProvider.propTypes = {
  children: PropTypes.node.isRequired,
  endpointHost: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  signup: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  checkAuth: PropTypes.func.isRequired,
};

export default connect(undefined, { ...actions })(AuthenticationProvider);

export function withAuthenticationConsumer(WrappedComponent) {
  return props => (
    <AuthenticationContext.Consumer>
      {value => <WrappedComponent {...props} {...value} />}
    </AuthenticationContext.Consumer>
  );
}

export function useAuthenticationConsumer() {
  const context = useContext(AuthenticationContext);

  return context;
}
