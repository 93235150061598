import React, { useCallback, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import groupBy from 'lodash/groupBy';
import { FolderOpen as FolderOpenSvg } from '@styled-icons/material';
import { stringify as parseQuery } from 'qs';

import { useSpaceProjects, useProject } from 'src/hooks/use-resource';
import { useToriiActions, formatForLocalAction } from 'src/modules/torii';
import EntityChooser from 'src/components/EntityChooser';
import withSuspenseLoader from 'src/hocs/with-suspense-loader';

import { get } from 'src/utils/accessors';
import SearchComponent from './components/SearchComponent';
import ItemComponent from './components/ItemComponent';
import CreateItemComponent from './components/CreateItemComponent';

import messages from './messages';

const API_URL = process.env.REACT_APP_API_URL;

function ProjectChooser({
  open,
  onClose,
  spaceId,
  currentProjectPublicKey,
  onSubmit,
}) {
  const { create, localBulkCreate } = useToriiActions();
  const [search, setSearch] = useState();
  const [projectsIds, setProjectsIds] = useState([]);

  const currentProject = useProject(
    { id: currentProjectPublicKey },
    { request: false },
  );

  const currentProjectId = get(currentProject, 'id');

  const relatedProjects = useSpaceProjects(
    { id: spaceId, include: ['coverImage'] },
    {
      // This updates the selector everytime the projectsIds array changes
      projectsIds,
      request: false,
    },
  );

  // Uses the stored search id results and filter the related projects based on it
  const projects = useMemo(
    () =>
      relatedProjects.filter(project =>
        projectsIds.includes(get(project, 'id')),
      ),
    [projectsIds, relatedProjects],
  );

  const requestProjects = useCallback(
    async searchText => {
      let url = `${API_URL}/app-workspace/ws-spaces/${spaceId}/projects?include=coverImage`;
      if (searchText) {
        url = `${url}&${parseQuery({ title: searchText })}`;
      } else {
        url = `${url}&allFolders=true`;
      }

      const result = await fetch(url, {
        method: 'GET',
        credentials: 'include',
      }).then(res => {
        if (res.status !== 200) {
          return [];
        }
        return res.json();
      });

      const resources = formatForLocalAction(result);
      const resourcesGroupedByType = groupBy(resources, 'type');

      // Updates store with request result
      Object.entries(resourcesGroupedByType).forEach(([type, typeResources]) =>
        localBulkCreate(type, typeResources, {
          worker: typeResources.length > 30,
        }),
      );

      // Updates project ids with request result
      setProjectsIds(
        get(result, 'data', [])
          .map(item => item.id)
          // Removes current project from the list
          .filter(id => id !== currentProjectId),
      );
    },
    [localBulkCreate, spaceId, currentProjectId],
  );

  useEffect(() => {
    requestProjects(search);
  }, [requestProjects, search]);

  const [title, setTitle] = useState(undefined);

  const handleCreateProject = useCallback(async () => {
    const result = await create('wsProjects', {
      attributes: { title },
      relationships: {
        space: {
          id: spaceId,
          type: 'ws-spaces',
        },
      },
    });
    return result.data;
  }, [spaceId, create, title]);

  if (!spaceId || !projects) {
    return null;
  }

  return (
    <EntityChooser
      open={open}
      onClose={onClose}
      title={<FormattedMessage {...messages.titleCopy} />}
      items={projects}
      SearchComponent={
        <SearchComponent search={search} setSearch={setSearch} />
      }
      ItemComponent={<ItemComponent icon={<FolderOpenSvg />} />}
      CreateItemComponent={
        <CreateItemComponent title={title} setTitle={setTitle} />
      }
      createNewItem={handleCreateProject}
      SubmitLabel={<FormattedMessage {...messages.labelCopy} />}
      onSubmit={onSubmit}
    />
  );
}

ProjectChooser.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  currentProjectPublicKey: PropTypes.string.isRequired,
  spaceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

ProjectChooser.defaultProps = {
  open: false,
  onClose: undefined,
  title: undefined,
};

export default withSuspenseLoader()(ProjectChooser);
