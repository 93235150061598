import { createContext, useContext } from 'react';

export const SubscriptionServiceContext = createContext();

export const SubscriptionServiceProvider = SubscriptionServiceContext.Provider;

export function useSubscriptionService() {
  const context = useContext(SubscriptionServiceContext);
  if (context === undefined) {
    throw new Error(
      '`useSubscriptionService` must be used within a `SubscriptionService`.',
    );
  }
  return context;
}
