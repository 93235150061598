import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { A, Body, Modal, toast } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { useDispatch } from 'react-redux';
import { Box } from '@rebass/grid';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { useToriiActions } from 'src/modules/torii';
import { localLogin } from 'src/utils/cerberus/actions';
import { useProject } from 'src/hooks/use-resource';
import { get } from 'src/utils/accessors';
import { Separator } from 'src/modules/prisma';
// We import the recaptchaError message from the signup page to avoid
// several copies of the rather long message.
import signupMessages from 'src/pages/signup/_messages';
import signupFormMessages from 'src/components/SignupForm/messages';
import { PRIVACY_POLICY_URL, TERMS_URL } from 'src/components/SignupForm';

import LiteUserRegisterForm from './components/LiteUserRegisterForm';
import messages from './messages';

const Anchor = styled(A)`
  font-size: inherit;
`;

function LiteUserRegisterModal({
  onLoginRedirect,
  onRegister,

  // useModal props
  onClose,
  open,
  title,
  message,
  form,
}) {
  const { localCreate } = useToriiActions();
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const project = useProject(
    { id: projectId, include: ['space'] },
    { request: false },
  );

  const spaceName = get(project, 'relationships.space.attributes.name');

  const registerUser = useCallback(
    async ({ email, name, recaptchaToken }) => {
      try {
        const { status, data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/users`,
          {
            liteAccountEmail: email,
            firstName: name,
            recaptchaToken,
          },
          { withCredentials: true },
        );
        if (status === 201) {
          const user = await localCreate('users', {
            id: data.id,
            type: 'users',
            attributes: data,
          });
          await dispatch(localLogin(data));
          if (onRegister) {
            await onRegister(user);
          }
          onClose();
        }
      } catch (e) {
        if (
          e.response.status === 400 &&
          /recaptcha/gi.test(e.response.data.message)
        ) {
          toast.error(<FormattedMessage {...signupMessages.recaptchaError} />);
          return;
        }
        const validationErrors = Object.keys(e.response.data.validationErrors);
        if (validationErrors.includes('liteAccountEmail')) {
          toast.error(
            <FormattedMessage {...messages.messageLiteUserEmailError} />,
          );
          return;
        }
        if (validationErrors.includes('firstName')) {
          toast.error(
            <FormattedMessage {...messages.messageLiteUserNameError} />,
          );
          return;
        }
        Sentry.captureException(e);
      }
    },
    [dispatch, localCreate, onClose, onRegister],
  );

  if (!open) return null;

  return (
    <Modal
      title={
        <FormattedMessage {...(title || messages.titleLiteUserRegisterModal)} />
      }
      onClickClose={onClose}
      open={open}
    >
      <Body>
        <FormattedMessage
          {...(message || messages.messageLiteUserRegisterModal)}
          values={{ owner: spaceName }}
        />
      </Body>
      <Box mt={6}>
        {form && (
          <>
            {form}
            <Separator />
          </>
        )}
        <LiteUserRegisterForm
          onLoginClick={onLoginRedirect}
          onSubmit={registerUser}
        />
        <Box mt={4}>
          <Body color="grey.600" textSize="small" fontWeight="light">
            <FormattedMessage
              {...signupFormMessages.messageTerms}
              values={{
                terms: (
                  <Anchor href={TERMS_URL} target="_blank">
                    <FormattedMessage {...signupFormMessages.labelTermsOfUse} />
                  </Anchor>
                ),
                privacy: (
                  <Anchor href={PRIVACY_POLICY_URL} target="_blank">
                    <FormattedMessage
                      {...signupFormMessages.labelPrivacyPolicy}
                    />
                  </Anchor>
                ),
              }}
            />
            <br />
            <FormattedMessage
              {...signupFormMessages.messageRecaptcha}
              values={{
                terms: (
                  <Anchor
                    href="https://policies.google.com/terms"
                    target="_blank"
                  >
                    <FormattedMessage
                      {...signupFormMessages.labelTermsOfService}
                    />
                  </Anchor>
                ),
                policy: (
                  <Anchor
                    href="https://policies.google.com/privacy"
                    target="_blank"
                  >
                    <FormattedMessage
                      {...signupFormMessages.labelPrivacyPolicy}
                    />
                  </Anchor>
                ),
              }}
            />
          </Body>
        </Box>
      </Box>
    </Modal>
  );
}

LiteUserRegisterModal.propTypes = {
  title: PropTypes.node,
  message: PropTypes.node,
  onLoginRedirect: PropTypes.func.isRequired,
  onRegister: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  form: PropTypes.node,
};

LiteUserRegisterModal.defaultProps = {
  title: null,
  message: null,
  onClose: f => f,
  onRegister: f => f,
  open: false,
  form: null,
};

export default LiteUserRegisterModal;
