import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import ItemsBox from 'src/components/ItemsBox';
import WrappedItemsBox from '../styles/WrappedItemsBox';

const ErrorToast = ({ errors, handleClickDismiss }) =>
  errors.map(([, fileName, fileErrors]) => (
    <WrappedItemsBox>
      <ItemsBox.Title>{fileName}</ItemsBox.Title>
      <ItemsBox.Icon color="white" onClick={handleClickDismiss} type="close" />
      <ItemsBox.List>
        {fileErrors.length > 1 ? (
          <ItemsBox.List>
            {fileErrors.map(fileError => (
              <ItemsBox.Item key={fileName} item={fileError} />
            ))}
          </ItemsBox.List>
        ) : (
          <ItemsBox.Item key={fileName} item={fileErrors} />
        )}
      </ItemsBox.List>
    </WrappedItemsBox>
  ));

ErrorToast.propTypes = {
  errors: PropTypes.instanceOf(Map),
  handleClickDismiss: PropTypes.func.isRequired,
};

ErrorToast.defaultProps = {
  errors: new Map(),
};

export default ErrorToast;
