import React, { Component } from 'react';
import { Spinner } from '@picter/prisma';

const withDataLoading = (action, options) => WrappedComponent =>
  class WithDataLoading extends Component {
    constructor(props) {
      super(props);

      this.state = {
        loading: true,
        error: null,
      };
    }

    defaultOptions = {
      LoadingComponent: Spinner,
      renderComponent: false,
      shouldLoad: null,
    };

    async UNSAFE_componentWillMount() {
      if (typeof options === 'object') {
        this.options = { ...this.defaultOptions, ...options };
      } else if (options !== null) {
        this.options = { ...this.defaultOptions, ...options(this.props) };
      }
      await this.loadData();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (
        this.options.shouldLoad &&
        this.options.shouldLoad(this.props, nextProps)
      ) {
        this.setState(
          {
            loading: true,
          },
          this.loadData,
        );
      }
    }

    loadData = async () => {
      try {
        await action(this.props);
        this.setState({
          loading: false,
        });
      } catch (e) {
        this.setState({
          error: e,
          loading: false,
        });
      }
    };

    render() {
      const { LoadingComponent, renderComponent } = this.options;
      const { loading } = this.state;

      if (!renderComponent && loading) {
        return <LoadingComponent />;
      }

      return <WrappedComponent {...this.props} {...this.state} />;
    }
  };

export default withDataLoading;
