import React from 'react';
import PropTypes from 'prop-types';

import { FlexAlignCenter, FlexAlignLeft, FlexAlignRight } from '@picter/prisma';

import { useLayoutInfo } from '../utils';
import BottomSection from '../styles/GeneralLayoutBottomSection';

const GeneralLayoutBottom = props => {
  const { mode } = useLayoutInfo();
  return <BottomSection mode={mode} {...props} />;
};

GeneralLayoutBottom.propTypes = {
  children: PropTypes.node,
};

GeneralLayoutBottom.defaultProps = {
  children: null,
};

GeneralLayoutBottom.AlignCenter = FlexAlignCenter;
GeneralLayoutBottom.AlignLeft = FlexAlignLeft;
GeneralLayoutBottom.AlignRight = FlexAlignRight;

export default GeneralLayoutBottom;
