import styled from 'styled-components/macro';

const BoxList = styled.ul.attrs({
  className: 'items-box__list',
})`
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: ${props => props.theme.space[1]}px;
  }

  .items-box__list {
    list-style-type: disc;
    padding-left: ${props => props.theme.space[5]}px;

    li {
      padding-left: ${props => props.theme.space[2]}px;
      margin-bottom: 0;
    }
  }
`;

export default BoxList;
