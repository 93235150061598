import React, { useState, useCallback } from 'react';
import { H4 } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';
import {
  ExpandMore as ExpandMoreSvg,
  ExpandLess as ExpandLessSvg,
} from '@styled-icons/material';

import { Box, Checkbox, Text, Icon, Body } from 'src/modules/prisma';

import { Facet, Filters } from '../../types';
import messages from '../../messages';

const labelFacetTypeMap = {
  project: messages.labelProjectFacet,
  file: messages.labelFileFacet,
  comment: messages.labelCommentFacet,
};

type Props = {
  facets?: {
    [key: string]: Facet[];
  };
  filters: Filters;
  addFilter: (name: string, value: string) => void;
  removeFilter: (name: string, value: string) => void;
  isMobile: boolean;
};

function isFilterApplied(name: string, value: string, filters: Filters) {
  const filter = filters.all.find(innerFilter => innerFilter[name]);
  return filter?.[name].includes(value) ?? false;
}

const facetOrderMap = {
  project: 0,
  file: 1,
  comment: 2,
};

export default function FiltersComponent({
  facets,
  filters,
  addFilter,
  removeFilter,
  isMobile,
}: Props) {
  const [showFilters, setShowFilters] = useState(!isMobile);
  const toggleShowFilters = useCallback(() => {
    return setShowFilters(!showFilters);
  }, [showFilters]);
  const typeFacets = facets?.type?.[0];
  if (!typeFacets) return null;

  return (
    <Box display="flex" flexDirection="column">
      <Box mb={showFilters ? 6 : 2}>
        <Box
          display="inline-flex"
          onClick={isMobile ? toggleShowFilters : null}
        >
          <H4 bold noMargin>
            <FormattedMessage {...messages.labelFilterBy} />
          </H4>
          {isMobile && (
            <Box ml={2}>
              <Icon
                boxSize="small"
                color="grey.600"
                size="small"
                type={showFilters ? <ExpandLessSvg /> : <ExpandMoreSvg />}
              />
            </Box>
          )}
        </Box>
      </Box>
      {showFilters && (
        <>
          <Box mb={2}>
            <Text color="muted" textStyle="body.medium">
              <FormattedMessage {...messages.labelFilterType} />
            </Text>
          </Box>
          <Box display="flex" flexDirection="column">
            {typeFacets.data
              // sort facets based on facetOrderMap
              .sort((a, b) =>
                facetOrderMap[a.value] < facetOrderMap[b.value] ? -1 : 1,
              )
              .map(facet => {
                const applied = isFilterApplied('type', facet.value, filters);
                const handler = applied ? removeFilter : addFilter;
                return (
                  <Box
                    display="flex"
                    mr={3}
                    style={{ overflow: 'hidden', alignItems: 'center' }}
                  >
                    <Box mr={2} style={{ display: 'flex' }}>
                      <Checkbox
                        key={facet.value}
                        checked={applied}
                        onChange={() => handler('type', facet.value)}
                      />
                    </Box>
                    <Body
                      style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        textAlign: 'start',
                      }}
                    >
                      <FormattedMessage {...labelFacetTypeMap[facet.value]} /> (
                      {facet.count})
                    </Body>
                  </Box>
                );
              })}
          </Box>
        </>
      )}
    </Box>
  );
}
