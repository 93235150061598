import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';

import executeInCase from 'src/utils/execute-in-case';
import useModal from 'src/hooks/use-modal';

import { ErrorHandlerProvider } from '../utils';
import TeamLimitModal from './TeamLimitModal';
import GuestsLimitModal from './GuestsLimitModal';

function ErrorHandler({ children }) {
  const [teamLimitModal, { open: openTeamLimitModal }] = useModal(
    <TeamLimitModal />,
  );
  const [guestsLimitModal, { open: openGuestsLimitModal }] = useModal(
    <GuestsLimitModal />,
  );

  const controls = useMemo(
    () => ({
      handleError: (error = {}) => {
        const { statusCode, _error: message } = error;
        let handled = false;

        switch (statusCode) {
          case 403 /* Forbidden */:
            executeInCase({
              [/reached the maximum number of team members allowed/i.test(
                message,
              )]: () => {
                openTeamLimitModal();
                handled = true;
              },
              [/reached the maximum number of guests per project allowed/i.test(
                message,
              )]: () => {
                openGuestsLimitModal();
                handled = true;
              },
            });

            break;
          default:
            break;
        }

        return handled;
      },
    }),
    [openTeamLimitModal, openGuestsLimitModal],
  );

  return (
    <ErrorHandlerProvider value={controls}>
      {children}
      {teamLimitModal}
      {guestsLimitModal}
    </ErrorHandlerProvider>
  );
}

ErrorHandler.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(ErrorHandler);
