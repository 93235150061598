import React, { useCallback, useEffect, useMemo } from 'react';
import { ButtonElement as Button } from '@picter/prisma';
import {
  ExpandMore as ExpandMoreSvg,
  ExpandLess as ExpandLessSvg,
} from '@styled-icons/material';

import { Dropdown, Icon } from 'src/modules/prisma';
import { useZoom, usePan } from 'src/components/Annotations';

// eslint-disable-next-line react/prop-types
function ZoomDropdownButton({ active, onClick, children }) {
  return (
    <Button variant="flat" onClick={onClick}>
      {children}
      <Icon
        type={active ? <ExpandLessSvg /> : <ExpandMoreSvg />}
        size="small"
      />
    </Button>
  );
}

export default function ZoomDropdown() {
  const { current, update, increment, decrement, reset: resetZoom } = useZoom();
  const { reset: resetPan, panXY } = usePan();

  const text = useMemo(() => `${(current * 100).toFixed(0)}%`, [current]);
  const setTo = useCallback(
    n => () => {
      update({ current: n });
      // Set timeout needed otherwise the panXY is triggered
      // before the panning info updates after the zoom changes.
      setTimeout(() => {
        panXY(0, 0);
      });
    },
    [panXY, update],
  );

  const reset = useCallback(() => {
    resetZoom();
    resetPan();
  }, [resetZoom, resetPan]);

  const handleKeyEvents = useCallback(
    function handleKeyEvents(event) {
      if (
        event.target.tagName === 'INPUT' ||
        event.target.tagName === 'TEXTAREA'
      )
        return undefined;

      const { key, code, shiftKey } = event;

      if (key === '+') return increment();
      if (key === '-') return decrement();
      if (code === 'Digit0' && shiftKey) return reset();

      return true;
    },
    [reset, increment, decrement],
  );

  useEffect(
    function bindKeysToDocument() {
      window.document.addEventListener('keydown', handleKeyEvents);

      return function unbindKeysToDocument() {
        window.document.removeEventListener('keydown', handleKeyEvents);
      };
    },
    [handleKeyEvents],
  );

  return (
    <>
      <Dropdown button={<ZoomDropdownButton>{text}</ZoomDropdownButton>}>
        <Dropdown.Menu>
          <Dropdown.Item
            label="Zoom to fit"
            shortcut="Shift 0"
            onClick={reset}
          />
          <Dropdown.Item label="Zoom in" shortcut="+" onClick={increment} />
          <Dropdown.Item label="Zoom out" shortcut="–" onClick={decrement} />
          <Dropdown.Item label="Zoom to 50%" onClick={setTo(0.5)} />
          <Dropdown.Item label="Zoom to 100%" onClick={setTo(1)} />
          <Dropdown.Item label="Zoom to 200%" onClick={setTo(2)} />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
