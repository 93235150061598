import { defineMessages } from 'react-intl';

export default defineMessages({
  // page title
  titleCancelSubscriptionPage: {
    id: 'CancelSubscriptionPage.titleCancelSubscriptionPage',
    defaultMessage: 'Cancel subscription',
  },
  // step losses
  titleIfCanceledMeans: {
    id: 'CancelSubscriptionPage.titleIfCanceledMeans',
    defaultMessage: 'If you cancel your subscription now, this means…',
  },
  titleDeletedProjects: {
    id: 'CancelSubscriptionLosses.titleDeletedProjects',
    defaultMessage: 'All your teams projects & files will be deleted:',
  },
  titleLoseFeaturesAccess: {
    id: 'CancelSubscriptionLosses.titleLoseFeaturesAccess',
    defaultMessage: 'You lose access to all Picter features:',
  },
  messageStoringAndSharing: {
    id: 'CancelSubscriptionLosses.messageStoringAndSharing',
    defaultMessage: 'Storing & sharing your projects',
  },
  messageContentReview: {
    id: 'CancelSubscriptionLosses.messageContentReview',
    defaultMessage: 'Content review & approval',
  },
  messageTeamCollaboration: {
    id: 'CancelSubscriptionLosses.messageTeamCollaboration',
    defaultMessage: 'Team collaboration',
  },
  titleSharedLinksNotAccessible: {
    id: 'CancelSubscriptionLosses.titleSharedLinksNotAccessible',
    defaultMessage: 'Shared links will not be accessible:',
  },
  messageStoppedCollaboration: {
    id: 'CancelSubscriptionLosses.messageStoppedCollaboration',
    defaultMessage:
      'All sharing links will be disabled, so collaboration with clients or co-workers can be interrupted.',
  },
  titleLostTeamWork: {
    id: 'CancelSubscriptionLosses.titleLostTeamWork',
    defaultMessage: 'All your team’s work will be lost:',
  },
  messageComments: {
    id: 'CancelSubscriptionLosses.messageComments',
    defaultMessage: 'Comments',
  },
  messageApprovals: {
    id: 'CancelSubscriptionLosses.messageApprovals',
    defaultMessage: 'Approvals',
  },
  messageTasks: {
    id: 'CancelSubscriptionLosses.messageTasks',
    defaultMessage: 'Tasks',
  },
  // step offers
  titleOffers: {
    id: 'CancelSubscriptionOffers.titleOffers',
    defaultMessage: '{name}, before you cancel, have a look at these offers:',
  },

  // discount card
  titleSubscriptionDiscount: {
    id: 'CancelSubscriptionOffers.titleSubscriptionDiscount',
    defaultMessage: 'Save 40% of your current subscription',
  },
  messageMonthlyCosts: {
    id: 'CancelSubscriptionOffers.messageMonthlyCosts',
    defaultMessage: 'Current monthly costs',
  },
  messageMonthlyCostsWithDiscount: {
    id: 'CancelSubscriptionOffers.messageMonthlyCostsWithDiscount',
    defaultMessage:
      'Discounted price for {numberOfMonths, plural, one {# month} other {# months}}',
  },
  labelApplyDiscount: {
    id: 'CancelSubscriptionOffers.labelApplyDiscount',
    defaultMessage: 'Apply discount',
  },

  // pause subscription card
  titleSubscriptionPause: {
    id: 'CancelSubscriptionOffers.titleSubscriptionPause',
    defaultMessage: 'Pause your subscription for up to 3 months',
  },
  messagePausingConsequences: {
    id: 'CancelSubscriptionOffers.messagePausingConsequences',
    defaultMessage:
      'Your workspace and sharing links will be deactivated, but all projects, files, communication etc. are kept safe until you resume your subscription. No charges apply during the pause.',
  },
  labelPauseSubscription: {
    id: 'CancelSubscriptionOffers.labelPauseSubscription',
    defaultMessage: 'Pause subscription',
  },

  // reduce paid seats card
  titleReducePaidSeats: {
    id: 'CancelSubscriptionOffers.titleReducePaidSeats',
    defaultMessage: 'Reduce paid team seats',
  },
  messageReducePaidSeats: {
    id: 'CancelSubscriptionOffers.messageReducePaidSeats',
    defaultMessage: 'Downgrade paid members to free viewers.',
  },
  labelReducePaidSeats: {
    id: 'CancelSubscriptionOffers.labelReducePaidSeats',
    defaultMessage: 'Manage team',
  },

  // feature request card
  titleFeatureRequest: {
    id: 'CancelSubscriptionOffers.titleFeatureRequest',
    defaultMessage: 'Do you have any special needs?',
  },
  messageFeatureRequest: {
    id: 'CancelSubscriptionOffers.messageFeatureRequest',
    defaultMessage:
      "Get in touch with one of our product specialists to tell us what you need. We're here to help.",
  },
  labelFeatureRequest: {
    id: 'CancelSubscriptionOffers.labelFeatureRequest',
    defaultMessage: 'Contact us',
  },

  // step confirmation
  titleConsequences: {
    id: 'CancelSubscriptionConsequences.titleConsequences',
    defaultMessage: 'Consequences of cancellation:',
  },
  messageSubscriptionEndingOn: {
    id: 'CancelSubscriptionConsequences.messageSubscriptionEndingOn',
    defaultMessage: 'Your subscription will end on <bold>{endingDate}.</bold>',
  },
  messageDeletionAfterSevenDays: {
    id: 'CancelSubscriptionConsequences.messageDeletionAfterSevenDays',
    defaultMessage:
      '7 days after cancellation your Picter workspace including all projects, files, comments, approvals etc. will be deleted.',
  },
  messageMakeSureToSaveFiles: {
    id: 'CancelSubscriptionConsequences.messageMakeSureToSaveFiles',
    defaultMessage:
      'Make sure to save your files before cancelling. <linked>Go to projects</linked> and download your files.',
  },
  messageSharedLinksDeleted: {
    id: 'CancelSubscriptionConsequences.messageSharedLinksDeleted',
    defaultMessage: 'All shared links will be deleted.',
  },
  messageTeamAccountsDeleted: {
    id: 'CancelSubscriptionConsequences.messageTeamAccountsDeleted',
    defaultMessage:
      'Your (and your teams) personal accounts will be deleted. Exception: If you are member of another workspace or if you are using <linked>Picter Contests</linked>, your account will stay active.',
  },

  // navigation
  labelClose: {
    id: 'CancelSubscriptionNavigation.labelClose',
    defaultMessage: 'Close',
  },
  labelPrevious: {
    id: 'CancelSubscriptionNavigation.labelPrevious',
    defaultMessage: 'Previous',
  },
  labelNext: {
    id: 'CancelSubscriptionNavigation.labelNext',
    defaultMessage: 'Next',
  },
  labelCancelSubscription: {
    id: 'CancelSubscriptionNavigation.labelCancelSubscription',
    defaultMessage: 'Cancel subscription',
  },
});
