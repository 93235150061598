import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Block as BlockSvg,
  Delete as DeleteSvg,
  SpeakerNotesOff as SpeakerNotesOffSvg,
  VisibilityOff as VisibilityOffSvg,
} from '@styled-icons/material';
import { Link, useParams } from 'react-router-dom';
import { TrackerEvent, TrackerScope } from '@picter/tracker';

import { Box, Body, Heading } from 'src/modules/prisma';
import { librarySpaceUrl } from 'src/routes/urls';
import { useSpaceProjects } from 'src/hooks/use-resource';
import { get } from 'src/utils/accessors';
import { $TSFixMe } from 'src/types';

import CardGrid from './CardGrid';
import Card, { CardAction } from './Card';
import messages from '../_messages';

export default function CancelSubscriptionLosses() {
  const { spaceId } = useParams<{ spaceId: string }>();
  const projects = useSpaceProjects(
    {
      id: spaceId,
    },
    {
      page: {
        size: 3,
      },
    },
  );

  return (
    <>
      <Heading fontWeight="light" tag="h1" textSize="large">
        <FormattedMessage {...messages.titleIfCanceledMeans} />
      </Heading>
      <Box my={5}>
        <TrackerEvent name="Losses" trackView>
          <TrackerScope name="Losses">
            <CardGrid>
              <Card
                action={
                  <TrackerEvent name="View projects" trackClick>
                    <Link to={librarySpaceUrl({ spaceId })}>
                      <CardAction>View projects</CardAction>
                    </Link>
                  </TrackerEvent>
                }
                color="grey.300"
                iconColor="grey.600"
                iconType={DeleteSvg}
                content={
                  <Box tag="ul" m={0} pl={5}>
                    {projects.slice(0, 3).map((project: $TSFixMe) => (
                      <li key={get(project, 'id')}>
                        <Body>{get(project, 'attributes.title')}</Body>
                      </li>
                    ))}
                  </Box>
                }
                title={<FormattedMessage {...messages.titleDeletedProjects} />}
              />
              <Card
                color="grey.300"
                iconColor="grey.600"
                iconType={BlockSvg}
                content={
                  <Box tag="ul" m={0} pl={5}>
                    <li>
                      <Body>
                        <FormattedMessage
                          {...messages.messageStoringAndSharing}
                        />
                      </Body>
                    </li>
                    <li>
                      <Body>
                        <FormattedMessage {...messages.messageContentReview} />
                      </Body>
                    </li>
                    <li>
                      <Body>
                        <FormattedMessage
                          {...messages.messageTeamCollaboration}
                        />
                      </Body>
                    </li>
                  </Box>
                }
                title={
                  <FormattedMessage {...messages.titleLoseFeaturesAccess} />
                }
              />
              <Card
                color="grey.300"
                iconColor="grey.600"
                iconType={VisibilityOffSvg}
                content={
                  <Body>
                    <FormattedMessage
                      {...messages.messageStoppedCollaboration}
                    />
                  </Body>
                }
                title={
                  <FormattedMessage
                    {...messages.titleSharedLinksNotAccessible}
                  />
                }
              />
              <Card
                color="grey.300"
                iconColor="grey.600"
                iconType={SpeakerNotesOffSvg}
                content={
                  <Box tag="ul" m={0} pl={5}>
                    <li>
                      <Body>
                        <FormattedMessage {...messages.messageComments} />
                      </Body>
                    </li>
                    <li>
                      <Body>
                        <FormattedMessage {...messages.messageApprovals} />
                      </Body>
                    </li>
                    <li>
                      <Body>
                        <FormattedMessage {...messages.messageTasks} />
                      </Body>
                    </li>
                  </Box>
                }
                title={<FormattedMessage {...messages.titleLostTeamWork} />}
              />
            </CardGrid>
          </TrackerScope>
        </TrackerEvent>
      </Box>
    </>
  );
}
