import { useEffect, useState, useCallback, useRef } from 'react';

const MS_TO_MINUTES_MULTIPLICATION_VALUE = 1000 * 60;
const POSTPONE_INTERVAL = 15 * MS_TO_MINUTES_MULTIPLICATION_VALUE;
const CHECK_FOR_UPDATES_INTERVAL = 10 * MS_TO_MINUTES_MULTIPLICATION_VALUE;

function parseHeadersString(headersString) {
  // Transforms headers string into mapped object
  const headersArray = headersString.split('\r\n');
  return headersArray.reduce((acc, current) => {
    const parts = current.split(': ');
    // eslint-disable-next-line prefer-destructuring
    acc[parts[0]] = parts[1];
    return acc;
  }, {});
}

// Needed because fetch api doesn't get the headers
function getHeaders(url) {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    // eslint-disable-next-line func-names
    xhr.addEventListener('readystatechange', function () {
      if (this.readyState === this.HEADERS_RECEIVED) {
        const headersString = this.getAllResponseHeaders();
        const headers = parseHeadersString(headersString);
        resolve(headers);
      }
    });
    // https://stackoverflow.com/questions/15123839/why-do-we-pass-null-to-xmlhttprequest-send
    xhr.send(null);
  });
}

const useAutoUpdate = () => {
  const [isPostponed, setIsPostponed] = useState(false);
  const [hasUpdate, setHasUpdate] = useState(false);

  const postponeTimeoutRef = useRef(null);
  const checkForUpdatesIntervalRef = useRef(null);

  const clearTimer = useCallback(() => {
    clearTimeout(postponeTimeoutRef.current);
    postponeTimeoutRef.current = null;
    setIsPostponed(false);
  }, []);

  const setTimer = useCallback(() => {
    setIsPostponed(true);
    const id = setTimeout(() => {
      clearTimer();
    }, POSTPONE_INTERVAL);
    postponeTimeoutRef.current = id;
  }, [clearTimer]);

  const postpone = useCallback(() => {
    setTimer();
  }, [setTimer]);

  const update = useCallback(() => {
    window.location.reload();
  }, []);

  const getLastModified = useCallback(async () => {
    const headers = await getHeaders('/');
    return headers['last-modified'];
  }, []);

  const setInitialLastModified = useCallback(async () => {
    const lastModifiedDate = await getLastModified();
    return new Date(lastModifiedDate);
  }, [getLastModified]);

  const checkForUpdates = useCallback(
    async lastModified => {
      const requestLastModified = await getLastModified();
      if (new Date(requestLastModified) > lastModified) {
        // Stops checking for updates once founds one.
        clearInterval(checkForUpdatesIntervalRef.current);
        setHasUpdate(true);
      }
    },
    [getLastModified],
  );

  useEffect(() => {
    setInitialLastModified().then(initialModifiedDate => {
      if (initialModifiedDate) {
        // Checks for updates on interval
        checkForUpdatesIntervalRef.current = setInterval(
          () => checkForUpdates(initialModifiedDate),
          CHECK_FOR_UPDATES_INTERVAL,
        );
      } else {
        // eslint-disable-next-line no-console
        console.warn('No last-modified header found. Auto-update is disabled.');
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    update: !isPostponed && hasUpdate ? update : undefined,
    postpone,
  };
};

export default useAutoUpdate;
