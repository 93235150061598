import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Dropdown } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';
import { Map } from 'immutable';
import { IndeterminateCheckBox as IndeterminateCheckBoxSvg } from '@styled-icons/material';

import { Checkbox, Icon } from 'src/modules/prisma';
import { get } from 'src/utils/accessors';

import useHandlers from '../hooks/use-handlers-dropdown-item';
import messages from '../messages';

const DropdownItemContainer = styled.div`
  & > button {
    width: 100%;
  }
`;

const CollectionDropdownItem = ({
  collection,
  // These collection-images are already
  // part of this specific collection
  relatedCollectionImages,
  imagesIds,
  onSelect,
  project,
}) => {
  const { addToCollection, removeFromCollection } = useHandlers({
    collection,
    relatedCollectionImages,
    imagesIds,
    project,
  });

  const toggleImages = useMemo(
    () =>
      relatedCollectionImages.length > 0 &&
      relatedCollectionImages.length === imagesIds.length
        ? removeFromCollection
        : addToCollection,
    [addToCollection, relatedCollectionImages, imagesIds, removeFromCollection],
  );

  return (
    <DropdownItemContainer>
      <Dropdown.Item
        icon={
          relatedCollectionImages.length > 0 &&
          relatedCollectionImages.length !== imagesIds.length ? (
            <Icon
              type={<IndeterminateCheckBoxSvg />}
              size="medium"
              role="button"
            />
          ) : (
            <Checkbox mr={4} checked={relatedCollectionImages.length} />
          )
        }
        label={
          get(collection, 'attributes.name') || (
            <FormattedMessage {...messages.labelUntitledCollection} />
          )
        }
        onClick={() => {
          onSelect();
          toggleImages();
        }}
      />
    </DropdownItemContainer>
  );
};

CollectionDropdownItem.propTypes = {
  collection: PropTypes.instanceOf(Map).isRequired,
  relatedCollectionImages: PropTypes.arrayOf(Map),
  imagesIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func,
  project: PropTypes.instanceOf(Map).isRequired,
};

CollectionDropdownItem.defaultProps = {
  onSelect: () => null,
  relatedCollectionImages: [],
};

export default CollectionDropdownItem;
