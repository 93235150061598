import { createElement } from 'react';
import { renderToString } from 'react-dom/server';

// https://en.wikipedia.org/wiki/Data_URI_scheme
export default function buildDataUriSvg(
  SvgComponent,
  {
    color = 'currentColor',
    height,
    width,
    xmlns = 'http://www.w3.org/2000/svg',
  } = {},
) {
  const svgStringified = renderToString(
    createElement(SvgComponent, {
      color,
      height,
      width,
      xmlns,
    }),
  )
    // Values set by react-icons. Replace is necessary because background-image doesn't inherit
    // properties from its element/parent.
    .replace(/currentColor/g, color);

  return `data:image/svg+xml;utf-8,${encodeURIComponent(svgStringified)}`;
}
