import merge from 'lodash/merge';
import { useParams } from 'react-router-dom';
import { viewModeTypes } from 'src/routes/constants';

export default function usePublicKey({ configKey = 'access' } = {}) {
  const { projectId: projectPublicKey, viewMode } = useParams();
  const queryKey =
    viewMode === viewModeTypes.DEFAULT ? 'publicKey' : 'publicLinkKey';

  /**
   * e.g.
   * {
   *    access: ['publicKey', 'key'],
   *    querySerializers: {
   *      access: ([key, value]) => 'publicKey=key',
   *    }
   * }
   */
  const queryObject = {
    [configKey]: [queryKey, projectPublicKey],
    querySerializers: {
      [configKey]: ([accessKey, accessValue]) => `${accessKey}=${accessValue}`,
    },
  };

  return config => merge(queryObject, config);
}
