import { defineMessages } from 'react-intl';

export default defineMessages({
  /**
   * CurrentPlanPanel
   */
  labelPlanName: {
    id: 'CurrentPlanPanel.labelPlanName',
    defaultMessage: '{planName} plan',
  },
  labelPlanTrialDays: {
    id: 'CurrentPlanPanel.labelTrialDays',
    defaultMessage: '(trial ends {relativeDate})',
  },
  labelPlanSeats: {
    id: 'CurrentPlanPanel.labelPlanSeats',
    defaultMessage: `{numberOfSeats, plural,
      =0 {}
      one {Individual seat}
      other {{numberOfSeats} seats}
    }`,
  },
  labelMonth: {
    id: 'CurrentPlanPanel.labelMonth',
    defaultMessage: 'month',
  },

  /**
   * PlanInfo
   */
  labelPlanPrice: {
    id: 'PlanInfo.labelPlanPrice',
    defaultMessage: '<price>€ {planPrice}</price><date>/ member / month</date>',
  },

  /**
   * PlansInfo
   */
  labelCustom: {
    id: 'PlansInfo.labelCustom',
    defaultMessage: 'Custom',
  },

  labelLetsTalk: {
    id: 'SubscriptionPage.labelLetsTalk',
    defaultMessage: "Let's talk",
  },
  messagePowerhouseOrganization: {
    id: 'SubscriptionPage.messagePowerhouse',
    defaultMessage:
      "Your organization is an visual assets powerhouse?\n\nLet's talk.",
  },

  labelDowngradePlan: {
    id: 'SubscriptionPage.labelDowngradePlan',
    defaultMessage: 'Downgrade plan',
  },
  labelSubscribeToOtherPlan: {
    id: 'SubscriptionPage.labelSubscribeToIndividualPlan',
    defaultMessage: 'Subscribe to {plan} plan',
  },
  messageAvailability: {
    id: 'SubscriptionPage.messageAvailability',
    defaultMessage: 'This plan is only available without\nguests.',
  },

  labelUpgradePlan: {
    id: 'SubscriptionPage.labelUpgradePlan',
    defaultMessage: 'Upgrade plan',
  },
  messageYourCurrentPlan: {
    id: 'SubscriptionPage.messageYourCurrentPlan',
    defaultMessage: 'Your current plan',
  },
  titleYourCurrentPlan: {
    id: 'SubscriptionPage.titleYourCurrentPlan',
    defaultMessage: 'Your current plan',
  },

  /**
   * PaymentMethodPanel
   */
  labelAddPaymentMethod: {
    id: 'PaymentMethodPanel.labelAddPaymentMethod',
    defaultMessage: 'Add payment method',
  },
  labelChangePaymentMethod: {
    id: 'PaymentMethodPanel.labelChangePaymentMethod',
    defaultMessage: 'Change payment',
  },
  messageAddPaymentMethod: {
    id: 'PaymentMethodPanel.messageAddPaymentMethod',
    defaultMessage:
      'Don’t forget to add a payment method before the trial runs out!',
  },
  messageNoPaymentMethod: {
    id: 'PaymentMethodPanel.messageNoPaymentMethod',
    defaultMessage: 'No payment method',
  },
  messagePaymentMethod: {
    id: 'PaymentMethodPanel.messagePaymentMethod',
    defaultMessage:
      '<card>{cardType}</card> credit card – •••• •••• •••• {lastNumbers}',
  },
  titlePaymentMethod: {
    id: 'PaymentMethodPanel.titlePaymentMethod',
    defaultMessage: 'Payment method',
  },

  /**
   * SubscriptionDetails
   */
  titleSubscriptionDetails: {
    id: 'SubscriptDetails.titleSubscriptionDetails',
    defaultMessage: 'Your subscription',
  },
  subscriptionDetailsPlan: {
    id: 'SubscriptDetails.subscriptionDetailsPlan',
    defaultMessage: 'Plan: {plan} ({seats} seats)',
  },
  labelCancelSubscription: {
    id: 'SubscriptDetails.labelCancelSubscription',
    defaultMessage: 'Cancel subscription',
  },
  labelResumeSubscription: {
    id: 'SubscriptDetails.labelResumeSubscription',
    defaultMessage: 'Resume subscription',
  },
  labelRevokeCancellation: {
    id: 'SubscriptDetails.labelRevokeCancellation',
    defaultMessage: 'Revoke cancellation',
  },
  subscriptionDetailsCosts: {
    id: 'SubscriptDetails.subscriptionDetailsCosts',
    defaultMessage: 'Monthly rate: {mrr} {currencyCode}',
  },
  subscriptionDetailsTermEnd: {
    id: 'SubscriptDetails.subscriptionDetailsTermEnd',
    defaultMessage: 'subscription ends on {termEnd}',
  },
  subscriptionDetailsPaused: {
    id: 'SubscriptDetails.subscriptionDetailsPaused',
    defaultMessage: 'Paused until {resumeDate}',
  },
});
