import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import paths from 'src/routes/paths';

import Document from './_document';
import FolderRoutes from './{folderId}/routes';

const { LIBRARY_SPACE_FOLDER_PATH, LIBRARY_SPACES_PATH } = paths;

const FoldersRoutes = () => {
  return (
    <Document>
      <Switch>
        <Route path={LIBRARY_SPACE_FOLDER_PATH} component={FolderRoutes} />
        <Redirect to={LIBRARY_SPACES_PATH} />
      </Switch>
    </Document>
  );
};

export default FoldersRoutes;
