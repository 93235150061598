import React from 'react';
import { FormattedMessage } from 'react-intl';

import EmptyStateBox from 'src/components/EmptyStateBox';
import HorizontalTilesSVG from 'src/assets/HorizontalTilesSVG';

import messages from '../messages';

const EmptySharedWithMeBox = () => (
  <EmptyStateBox
    graphic={<HorizontalTilesSVG />}
    title={<FormattedMessage {...messages.titleEmptySharedProjects} />}
    message={<FormattedMessage {...messages.messageEmptySharedProjects} />}
  />
);

export default EmptySharedWithMeBox;
