import React from 'react';

import withUploadStorage from 'src/hocs/with-upload-storage';

import GroupControlledUploadErrors from './components/GroupControlledUploadErrors';

const UploadErrorsConnectedWithUploadStorage = ({ uploadStorage }) => {
  const { groups } = uploadStorage.state;
  return (
    Object.entries(groups)
      .map(([id, group]) => {
        return [
          id,
          group,
          Object.values(group.files || {})
            .filter(file => file.errors)
            .map(file => {
              return [file.id, file.name, file.errors];
            }),
        ];
      })
      // eslint-disable-next-line no-unused-vars
      .map(([id, group, errors]) => {
        return (
          <GroupControlledUploadErrors
            group={group}
            errors={errors}
            id={id}
            clearGroupErrors={() => uploadStorage.clearGroupErrors(id)}
          />
        );
      })
  );
};

export default withUploadStorage(UploadErrorsConnectedWithUploadStorage);
