import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';

import {
  libraryProjectCommentsUrl,
  libraryProjectImageCommentsUrl,
  libraryProjectCollectionCommentsUrl,
  libraryProjectCollectionImageCommentsUrl,
} from 'src/routes/urls';
import { returnInCase } from 'src/utils/in-case';
import {
  CommentFilter,
  COMMENT_FILTER_QUERY_KEY,
} from 'src/utils/accessors/comment';

import CommentsFilterChooserToggle from './CommentsFilterChooserToggle';
import Dropdown from '../styles/Dropdown';
import messages from '../messages';

function useCommentUrlBuilder() {
  const { collectionId, imageId, projectId } = useParams();
  const isOnCollectionPage = !!collectionId;
  const isOnImagePage = !!imageId;

  return returnInCase({
    [isOnCollectionPage]: () => libraryProjectCollectionCommentsUrl,
    [isOnImagePage]: () => libraryProjectImageCommentsUrl,
    [isOnCollectionPage && isOnImagePage]: () =>
      libraryProjectCollectionImageCommentsUrl,
    default: () => libraryProjectCommentsUrl,
  }).builder({ collectionId, imageId, projectId });
}

function CommentsFilterChooser() {
  const { query } = useLocation();
  const urlBuilder = useCommentUrlBuilder();

  const SwitcherLabel = {
    [CommentFilter.ALL]: <FormattedMessage {...messages.labelAllComments} />,
    [CommentFilter.RESOLVED]: <FormattedMessage {...messages.labelResolved} />,
    [CommentFilter.UNRESOLVED]: (
      <FormattedMessage {...messages.labelUnresolved} />
    ),
    [CommentFilter.MENTIONS]: <FormattedMessage {...messages.labelMentions} />,
  }[query[COMMENT_FILTER_QUERY_KEY] || CommentFilter.ALL];

  return (
    <Dropdown
      icon={
        <CommentsFilterChooserToggle>
          {SwitcherLabel}
        </CommentsFilterChooserToggle>
      }
    >
      <Dropdown.Menu>
        <Dropdown.Header>
          <FormattedMessage {...messages.titleCommentsFilterChooser} />
        </Dropdown.Header>
        <Dropdown.Link
          to={urlBuilder({
            ...query,
            [COMMENT_FILTER_QUERY_KEY]: CommentFilter.ALL,
          })}
          label={<FormattedMessage {...messages.labelAllComments} />}
          size="normal"
          exact
        />
        <Dropdown.Link
          to={urlBuilder({
            ...query,
            [COMMENT_FILTER_QUERY_KEY]: CommentFilter.UNRESOLVED,
          })}
          label={<FormattedMessage {...messages.labelUnresolved} />}
          size="normal"
          exact
        />
        <Dropdown.Link
          to={urlBuilder({
            ...query,
            [COMMENT_FILTER_QUERY_KEY]: CommentFilter.RESOLVED,
          })}
          label={<FormattedMessage {...messages.labelResolved} />}
          size="normal"
          exact
        />
        <Dropdown.Link
          to={urlBuilder({
            ...query,
            [COMMENT_FILTER_QUERY_KEY]: CommentFilter.MENTIONS,
          })}
          label={<FormattedMessage {...messages.labelMentions} />}
          size="normal"
          exact
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default CommentsFilterChooser;
