import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, P } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';

import newTermsOfUseSVG from 'src/assets/new-terms-of-use.svg';

import AcceptTermsModalContent from '../styles/AcceptTermsModalContent';
import TermsOfServiceLink from './TermsOfServiceLink';
import messages from '../messages';

const AcceptTermsModal = ({
  acceptedNewTerms,
  handleAcceptTerms,
  newTermsReleased,
  isUserLoggedIn,
}) =>
  newTermsReleased &&
  !acceptedNewTerms &&
  isUserLoggedIn &&
  false && (
    <Modal title={<FormattedMessage {...messages.titleAcceptTerms} />} open>
      <AcceptTermsModalContent>
        <img src={newTermsOfUseSVG} alt="New terms of use illustration" />
        <P>
          <FormattedMessage
            {...messages.messageAgreeTerm}
            values={{
              termsOfServiceLink: <TermsOfServiceLink />,
            }}
          />
        </P>
        <Button onClick={handleAcceptTerms} flat>
          <FormattedMessage {...messages.labelAcceptAndContinue} />
        </Button>
      </AcceptTermsModalContent>
    </Modal>
  );

AcceptTermsModal.propTypes = {
  isUserLoggedIn: PropTypes.bool,
  acceptedNewTerms: PropTypes.bool,
  handleAcceptTerms: PropTypes.func,
  newTermsReleased: PropTypes.bool,
};

AcceptTermsModal.defaultProps = {
  isUserLoggedIn: false,
  acceptedNewTerms: false,
  handleAcceptTerms: null,
  newTermsReleased: false,
};

export default AcceptTermsModal;
