// stylelint-disable no-descending-specificity
import styled from 'styled-components/macro';
import { Modal as PrismaModal, mediaQuery } from '@picter/prisma';
import themeGet from '@styled-system/theme-get';

const Modal = styled(PrismaModal)`
  &:nth-child(1) {
    align-items: flex-start;
    padding: 45px;
    flex-direction: row;
    > :nth-child(2) {
      overflow: hidden;
      width: 100%;
      max-width: 1200px;
      height: auto;
      ${props => (props.showContent ? 'height: 100%;' : '')}
      max-height: 100%;
      padding: 0;
      background: ${themeGet('colors.grey.100')};

      @media screen and ${mediaQuery.greaterThan('phablet')} {
        border-radius: 8px;
      }

      > i {
        display: none;
      }

      & > div,
      & > div > div:first-child {
        height: 100% !important;
        max-height: 100% !important;
        display: flex;
        flex-direction: column;
        @media screen and ${mediaQuery.greaterThan('phablet')} {
          border-radius: 8px;
        }

        > div {
          display: flex;
          flex-direction: column;
          flex: 1;
        }
      }
    }
  }
`;

export default Modal;
