import { useCallback } from 'react';
import camelcaseKeys from 'camelcase-keys';

const parseResponse = response =>
  response.json().then(body => ({ response, body }));

export default function useService({ chargebee, endpoint }) {
  const load = useCallback(
    async ({ spaceId }) => {
      const response = await fetch(
        new URL(`subscriptions/${spaceId}`, endpoint).toString(),
        {
          credentials: 'include',
          method: 'GET',
        },
      );

      if (!response.ok) {
        const error = new Error(
          'An error happened while loading your subscription.',
        );
        error.info = await response.json();
        error.status = response.status;
        throw error;
      }

      return camelcaseKeys(await response.json(), { deep: true });
    },
    [endpoint],
  );

  const create = useCallback(
    ({ couponId, planId, spaceId }) =>
      new Promise((resolve, reject) => {
        chargebee.openCheckout({
          hostedPage() {
            return fetch(new URL('hosted-pages', endpoint).toString(), {
              body: JSON.stringify({
                couponId,
                planId,
                wsSpaceId: spaceId,
              }),
              credentials: 'include',
              method: 'POST',
            })
              .then(parseResponse)
              .then(({ body, response }) => {
                if (!response.ok) {
                  const error = new Error(
                    'An error happened while creating your subscription.',
                  );
                  error.info = body;
                  error.status = response.status;
                  throw error;
                }

                return body.hosted_page;
              });
          },
          error(error) {
            return reject(error);
          },
          success() {
            return resolve();
          },
        });
      }),
    [chargebee, endpoint],
  );

  const applyCoupon = useCallback(
    async ({ couponCode, spaceId }) => {
      const response = await fetch(
        new URL(`subscriptions/${spaceId}/coupon`, endpoint).toString(),
        {
          body: JSON.stringify({
            couponCode,
          }),
          credentials: 'include',
          method: 'PUT',
        },
      );

      if (!response.ok) {
        const error = new Error('An error happened while applying a coupon.');
        error.info = await response.json();
        error.status = response.status;
        throw error;
      }
    },
    [endpoint],
  );

  const update = useCallback(
    async ({ planId, spaceId }) => {
      const response = await fetch(
        new URL(`subscriptions/${spaceId}`, endpoint).toString(),
        {
          body: JSON.stringify({
            planId,
          }),
          credentials: 'include',
          method: 'PATCH',
        },
      );

      if (!response.ok) {
        const error = new Error(
          'An error happened while updating your subscription.',
        );
        error.info = await response.json();
        error.status = response.status;
        throw error;
      }

      return camelcaseKeys(await response.json(), { deep: true });
    },
    [endpoint],
  );

  const updatePayment = useCallback(
    ({ spaceId }) =>
      new Promise((resolve, reject) => {
        chargebee.openCheckout({
          hostedPage() {
            return fetch(
              new URL(
                `subscriptions/${spaceId}/payment-method`,
                endpoint,
              ).toString(),
              {
                credentials: 'include',
                method: 'PATCH',
              },
            )
              .then(parseResponse)
              .then(({ body, response }) => {
                if (!response.ok) {
                  const error = new Error(
                    'An error happened while updating your subscription.',
                  );
                  error.info = body;
                  error.status = response.status;
                  throw error;
                }

                return body.hosted_page;
              });
          },
          error(error) {
            return reject(error);
          },
          success() {
            return resolve();
          },
        });
      }),
    [chargebee, endpoint],
  );

  const cancel = useCallback(
    async ({ spaceId }) => {
      const response = await fetch(
        new URL(`subscriptions/${spaceId}`, endpoint).toString(),
        {
          credentials: 'include',
          method: 'DELETE',
        },
      );

      if (!response.ok) {
        const error = new Error(
          'An error happened while cancelling your subscription.',
        );
        error.info = await response.json();
        error.status = response.status;
        throw error;
      }

      return camelcaseKeys(await response.json(), { deep: true });
    },
    [endpoint],
  );

  const pause = useCallback(
    async ({ resumeDate, spaceId }) => {
      const response = await fetch(
        new URL(`subscriptions/${spaceId}/pause`, endpoint).toString(),
        {
          body: JSON.stringify({
            resumeDate,
          }),
          credentials: 'include',
          method: 'PUT',
        },
      );

      if (!response.ok) {
        const error = new Error(
          'An error happened while pausing your subscription.',
        );
        error.info = await response.json();
        error.status = response.status;
        throw error;
      }
    },
    [endpoint],
  );

  const resume = useCallback(
    async ({ spaceId }) => {
      const response = await fetch(
        new URL(`subscriptions/${spaceId}/resume`, endpoint).toString(),
        {
          credentials: 'include',
          method: 'PUT',
        },
      );

      if (!response.ok) {
        const error = new Error(
          'An error happened while resuming your subscription.',
        );
        error.info = await response.json();
        error.status = response.status;
        throw error;
      }

      return camelcaseKeys(await response.json(), { deep: true });
    },
    [endpoint],
  );

  return {
    applyCoupon,
    cancel,
    create,
    endpoint,
    load,
    pause,
    resume,
    update,
    updatePayment,
  };
}
