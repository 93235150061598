import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, P } from '@picter/prisma';
import { Flex, Box } from '@rebass/grid';
import { FormattedMessage } from 'react-intl';
import { TrackerEvent, TrackerScope } from '@picter/tracker';

import appAccountUrl from 'src/utils/url-from-env';
import ModalActions from 'src/styles/ModalActions';

import messages from './messages';

function goTo(link) {
  return () => window.open(link);
}

const EditorQuotaModal = ({ onClose, open }) => (
  <TrackerScope name="Editor quota reached modal">
    <Modal
      title={<FormattedMessage {...messages.titleEditorQuotaModal} />}
      onClickClose={onClose}
      open={open}
    >
      <TrackerEvent name="Editor quota reached modal" trackView>
        <P>
          <FormattedMessage {...messages.messageEditorQuotaReached} />
        </P>
        <ModalActions>
          <Button
            flat
            colorScheme="grayscale"
            onClick={onClose}
            style={{ padding: 0 }}
          >
            <FormattedMessage {...messages.labelEditorQuotaModalCancel} />
          </Button>
          <Flex>
            <Box mr={2}>
              <TrackerEvent name="Learn more" trackClick>
                <Button
                  flat
                  colorScheme="primary"
                  style={{ padding: 0 }}
                  onClick={goTo('https://www.picter.com/pricing')}
                >
                  <FormattedMessage
                    {...messages.labelEditorQuotaModalLearnMore}
                  />
                </Button>
              </TrackerEvent>
            </Box>
            <Box ml={2}>
              <TrackerEvent name="Upgrade plan" trackClick>
                <Button
                  flat
                  colorScheme="primary"
                  style={{ padding: 0 }}
                  onClick={goTo(`${appAccountUrl}/subscription`)}
                >
                  <FormattedMessage
                    {...messages.labelEditorQuotaModalUpgrade}
                  />
                </Button>
              </TrackerEvent>
            </Box>
          </Flex>
        </ModalActions>
      </TrackerEvent>
    </Modal>
  </TrackerScope>
);

EditorQuotaModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

EditorQuotaModal.defaultProps = {
  onClose: null,
  open: false,
};

export default EditorQuotaModal;
