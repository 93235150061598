import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { CustomPropTypes } from '@picter/prisma';

import { useTooltipState } from '../hooks';
import { TooltipProvider } from '../providers';

const Tooltip = forwardRef(({ children, ...props }, ref) => {
  const state = useTooltipState(props);

  useImperativeHandle(ref, () => state);

  return <TooltipProvider value={state}>{children}</TooltipProvider>;
});

Tooltip.propTypes = {
  children: CustomPropTypes.oneOrMoreOfType([PropTypes.element]).isRequired,
};

export default Tooltip;
