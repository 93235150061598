import { defineMessages } from 'react-intl';

export default defineMessages({
  messageSpaceCurrentPlanTrialActive: {
    id: 'SubscriptionPage.messageSpaceCurrentPlanTrialActive',
    defaultMessage: '{spaceName} space is currently in {planName} plan Trial.',
  },
  messageSpaceCurrentPlan: {
    id: 'SubscriptionPage.messageSpaceCurrentPlan',
    defaultMessage: '{spaceName} space is currently in {planName} plan Trial.',
  },
  messageSpaceTrialExpired: {
    id: 'SubscriptionPage.messageSpaceTrialExpired',
    defaultMessage:
      'Your trial has expired. Subscribe to one of our plans to continue using Picter.',
  },
  labelContactSales: {
    id: 'SubscriptionPage.labelContactSales',
    defaultMessage: 'Contact sales',
  },
  labelManageLicense: {
    id: 'SubscriptionPage.labelManageLicense',
    defaultMessage: 'Manage license',
  },
  messageLoadSubscriptionError: {
    id: 'SubscriptionPage.messageLoadSubscriptionError',
    defaultMessage: 'There was an error loading your subscription.',
  },
  titleSubscriptionPage: {
    id: 'SubscriptionPage.titleSubscriptionPage',
    defaultMessage: 'Billing and Plans',
  },
  titleWorkspaceDescription: {
    id: 'SubscriptionPage.titleWorkspaceDescription',
    defaultMessage:
      "Picter Workspace scales to meet your team's visual communication needs",
  },
});
