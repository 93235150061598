import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { List, Map } from 'immutable';
import {
  Route,
  useLocation,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import {
  parse as parseQueryString,
  stringify as stringifyQueryString,
} from 'qs';

import { Spacer } from 'src/modules/prisma';
import {
  librarySpaceUrl,
  librarySpaceFolderUrl,
  librarySpaceTrashUrl,
  librarySettingsSpaceUrl,
  librarySpaceTasksUrl,
} from 'src/routes/urls';
import Navigation from 'src/components/Navigation';
import { get } from 'src/utils/accessors';

function SpaceNavigation({ space, index }) {
  const spaceId = get(space, 'id');
  const spaceName = get(space, 'attributes.name');
  const history = useHistory();
  const { pathname, query } = useLocation();

  /**
   * Hardcode active state for the space navigation item because of different
   * rules regarding its child routes.
   *
   * `spaces/:id/folders/:id` - should set space nav item to active.
   * `spaces/:id/trash` - should leave space nav item as it is.
   *
   */
  const matchFolderUrl = useRouteMatch(librarySpaceFolderUrl({ spaceId }));

  const handleStartSearch = useCallback(() => {
    const queryObject = parseQueryString(query, {
      ignoreQueryPrefix: true,
      comma: true,
    });

    return history.push(
      `${pathname}?${stringifyQueryString({
        ...queryObject,
        q: '',
      })}`,
    );
  }, [pathname, query, history]);

  return (
    <Navigation.List key={spaceId}>
      <Navigation.Item
        active={matchFolderUrl ? true : undefined}
        data-intercom-target={`workspace-home-${index}`}
        icon="business"
        label={spaceName}
        textStyle="body"
        to={librarySpaceUrl({ spaceId }, query)}
        exact
      >
        <Route
          path={librarySpaceUrl({ spaceId })}
          render={() => (
            <>
              <Navigation.Button
                icon="search"
                data-intercom-target="workspace-search"
                label={
                  <FormattedMessage
                    id="SpacesNavigation.labelSearch"
                    defaultMessage="Search"
                  />
                }
                onClick={handleStartSearch}
                textStyle="body"
              />
              <Navigation.Item
                icon="task_alt"
                label={
                  <>
                    <FormattedMessage
                      id="SpacesNavigation.labelTasks"
                      defaultMessage="Tasks"
                    />
                    <Spacer sx={3} />
                  </>
                }
                to={librarySpaceTasksUrl({ spaceId })}
                textStyle="body"
              />
              <Navigation.Item
                icon="delete_outline"
                data-intercom-target="workspace-trash"
                label={
                  <FormattedMessage
                    id="SpacesNavigation.labelTrash"
                    defaultMessage="Trash"
                  />
                }
                to={librarySpaceTrashUrl({ spaceId })}
                textStyle="body"
              />
              <Navigation.Item
                icon="settings"
                data-intercom-target="workspace-settings"
                label={
                  <FormattedMessage
                    id="SpacesNavigation.labelSettings"
                    defaultMessage="Settings"
                  />
                }
                to={librarySettingsSpaceUrl({ spaceId })}
                textStyle="body"
              />
            </>
          )}
        />
      </Navigation.Item>
    </Navigation.List>
  );
}

SpaceNavigation.propTypes = {
  index: PropTypes.number.isRequired,
  space: PropTypes.instanceOf(Map).isRequired,
};

function SpacesNavigation({ spaces }) {
  return spaces.map((space, index) => (
    <SpaceNavigation key={get(space, 'id')} space={space} index={index} />
  ));
}

SpacesNavigation.propTypes = {
  spaces: PropTypes.instanceOf(List).isRequired,
};

export default SpacesNavigation;
