export function executeInCase(caseObject) {
  if (caseObject.true) {
    caseObject.true();
    return true;
  }
  if (caseObject.default) {
    caseObject.default();
    return true;
  }
  return false;
}

export function returnInCase(caseObject) {
  if (caseObject.true) {
    return caseObject.true();
  }
  if (caseObject.default) {
    return caseObject.default();
  }
  return undefined;
}
