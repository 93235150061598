import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';

import { Box } from 'src/modules/prisma';

const ReplyInputWrapper = styled(Box)`
  border-bottom: 1px solid ${themeGet('colors.grey.200')};

  textarea {
    border-color: ${themeGet('colors.gray.300')};
  }
`;

export default ReplyInputWrapper;
