import React, {
  createContext,
  useCallback,
  useReducer,
  useMemo,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import useKeyHandler from './hooks/use-key-handler';
import { ACTIONS_TYPES } from './constants';
import { createReducer, initialState } from './reducer';

export const SelectableComponentsContext = createContext();

export default function SelectableComponentsProvider({
  ids: providerIds,
  ...props
}) {
  const [ids, setIds] = useState([]);
  const [store, dispatch] = useReducer(createReducer(), initialState);

  useEffect(() => {
    if (providerIds) {
      setIds(providerIds);
    }
  }, [providerIds]);

  const onShiftDown = useCallback(() => {
    dispatch({
      type: ACTIONS_TYPES.TOGGLE_SHIFT,
      value: true,
    });
  }, [dispatch]);

  const onShiftUp = useCallback(
    () =>
      dispatch({
        type: ACTIONS_TYPES.TOGGLE_SHIFT,
        value: false,
      }),
    [dispatch],
  );

  useKeyHandler('Shift', onShiftDown, onShiftUp);

  // Mouse-enter handler to update current shift-select preview.
  // This is always updated with every mouse-enter event on selectable
  // items.
  const shiftSelect = useCallback(
    (id, index) => {
      return dispatch({
        type: ACTIONS_TYPES.SET_SHIFT_PREVIEW,
        ids,
        id,
        index,
      });
    },
    [ids, dispatch],
  );

  // select handler
  const select = useCallback(
    (id, index) =>
      dispatch({
        type: ACTIONS_TYPES.SELECT,
        id,
        index,
      }),
    [dispatch],
  );

  // deselect handler
  const deselect = useCallback(
    (id, index) =>
      dispatch({
        type: ACTIONS_TYPES.DESELECT,
        id,
        index,
      }),
    [dispatch],
  );

  // toggle handler
  const toggleSelect = useCallback(
    (id, index) =>
      dispatch({
        type: ACTIONS_TYPES.TOGGLE_SELECT,
        id,
        index,
        originalIds: ids,
      }),
    [dispatch, ids],
  );

  // select-all handler
  const selectAll = useCallback(
    () =>
      dispatch({
        type: ACTIONS_TYPES.SELECT_ALL,
        ids,
      }),
    [dispatch, ids],
  );

  // clear handler
  const clear = useCallback(() => dispatch({ type: ACTIONS_TYPES.CLEAR }), [
    dispatch,
  ]);

  // context value
  const value = useMemo(() => {
    return {
      clear,
      deselect,
      isShiftModeSelect: store.isShiftModeSelect,
      select,
      selectAll,
      originalIds: ids,
      selection: store.selection,
      shiftPressed: store.shiftPressed,
      shiftPreview: store.shiftPreview,
      shiftSelect,
      toggleSelect,
      setIds,
    };
  }, [
    clear,
    deselect,
    select,
    selectAll,
    shiftSelect,
    store.isShiftModeSelect,
    ids,
    store.selection,
    store.shiftPressed,
    store.shiftPreview,
    toggleSelect,
    setIds,
  ]);

  return <SelectableComponentsContext.Provider value={value} {...props} />;
}

SelectableComponentsProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  ids: PropTypes.array.isRequired,
};
