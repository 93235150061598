import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Flex } from '@rebass/grid';
import { useParams, Link } from 'react-router-dom';
import { List } from 'immutable';
import { A } from '@picter/prisma';

import {
  Body,
  Heading,
  Box,
  Separator,
  Subheading,
  Anchor,
} from 'src/modules/prisma';
import useDocumentTitle from 'src/hooks/use-document-title';
import { useCurrentUser, useSpace } from 'src/hooks/use-resource';
import { useToriiActions } from 'src/modules/torii';
import withSuspenseLoader from 'src/hocs/with-suspense-loader';
import FormSection from 'src/components/FormSection';
import useFormikWithAutoSave from 'src/hooks/use-formik-with-auto-save';
import { get } from 'src/utils/accessors';
import { librarySettingsSpaceIntegrationsUrl } from 'src/routes/urls';

import NotificationItemInput from './_components/NotificationItemInput';
import NotificationChannelItemInput from './_components/NotificationChannelItemInput';

import Document from './_document';
import Layout from './_layout';
import messages from './_messages';

const notificationsValues = {
  EMAIL: 'email',
  SLACK: 'slack',
};

const getSlackAppChatDeepLink = team =>
  `https://slack.com/app_redirect?app=${process.env.REACT_APP_SLACK_APP_ID}&team=${team}`;

function NotificationsPage() {
  useDocumentTitle('Notifications');
  const { spaceId } = useParams();

  const user = useCurrentUser();
  const space = useSpace({ id: spaceId, include: ['users'] });

  const spaceUser = get(space, 'relationships.users', List()).find(
    item => get(item, 'relationships.user.data.id') === get(user, 'id'),
  );

  const isOwner = spaceUser && get(spaceUser, 'attributes.scope') === 'owner';

  const isManager =
    spaceUser && get(spaceUser, 'attributes.scope') === 'manager';

  const { update } = useToriiActions();

  const notificationsHandleSubmit = useCallback(
    async ({ notificationsMentions, notificationsComments }) => {
      const { data } = await update('users', {
        id: get(user, 'id'),
        attributes: {
          notificationsMentions,
          notificationsComments,
        },
      });
      return {
        notificationsMentions: data.attributes.notificationsMentions,
        notificationsComments: data.attributes.notificationsComments,
      };
    },
    [update, user],
  );

  const notificationsChannelHandleSubmit = useCallback(
    async ({ notificationsChannel }) => {
      const { data } = await update('wsSpaceUsers', {
        id: `${spaceId}:${get(user, 'id')}`,
        attributes: {
          notificationsChannel,
          scope: get(spaceUser, 'attributes.scope'),
        },
      });
      return {
        notificationsChannel: data.attributes.notificationsChannel,
      };
    },
    [update, spaceId, user, spaceUser],
  );

  const communicationHandleSubmit = useCallback(
    async ({ wsNewsletter }) => {
      const { data } = await update('users', {
        id: get(user, 'id'),
        attributes: {
          wsNewsletter,
        },
      });
      return {
        wsNewsletter: data.attributes.wsNewsletter,
      };
    },
    [update, user],
  );

  const {
    formikState: notificationsState,
    autoSaveState: notificationsAutoSave,
  } = useFormikWithAutoSave({
    initialValues: {
      notificationsMentions: get(user, 'attributes.notificationsMentions'),
      notificationsComments: get(user, 'attributes.notificationsComments'),
    },
    onSubmit: notificationsHandleSubmit,
  });

  const {
    formikState: notificationsChannelState,
    autoSaveState: notificationsChannelAutoSave,
  } = useFormikWithAutoSave({
    initialValues: {
      notificationsChannel: get(spaceUser, 'attributes.notificationsChannel'),
    },
    onSubmit: notificationsChannelHandleSubmit,
  });

  const {
    formikState: communicationState,
    autoSaveState: communicationAutoSave,
  } = useFormikWithAutoSave({
    initialValues: {
      wsNewsletter: get(user, 'attributes.wsNewsletter'),
    },
    onSubmit: communicationHandleSubmit,
  });

  const messageSlackChannel = useMemo(() => {
    const slackTeamId = get(space, 'attributes.slackTeamId');

    if (get(spaceUser, 'attributes.slackUserId')) {
      return (
        <A href={getSlackAppChatDeepLink(slackTeamId)} target="_blank">
          <FormattedMessage {...messages.messageOpenPicterSlack} />
        </A>
      );
    }
    if (slackTeamId) {
      return (
        <A href={getSlackAppChatDeepLink(slackTeamId)} target="_blank">
          <FormattedMessage {...messages.messageSlackBindAccounts} />
        </A>
      );
    }

    return isOwner || isManager ? (
      <Link
        to={librarySettingsSpaceIntegrationsUrl({
          spaceId,
        })}
      >
        <A>
          <FormattedMessage {...messages.messageSlackChannelInput} />
        </A>
      </Link>
    ) : (
      <span style={{ cursor: 'not-allowed' }}>
        <FormattedMessage {...messages.messageSlackChannelAskManagerOrOwner} />
      </span>
    );
  }, [isManager, isOwner, space, spaceId, spaceUser]);

  return (
    <Document>
      <Layout>
        <Layout.Top>
          <Heading tag="h1" fontWeight="light" textSize="regular">
            <FormattedMessage {...messages.titleNotificationsPage} />
          </Heading>
        </Layout.Top>
        <Layout.Content>
          <FormSection>
            <FormSection.AutoSaveIndicator {...notificationsAutoSave} />
            <FormSection.Fieldset>
              <FormSection.Content>
                <Subheading tag="h2">
                  <FormattedMessage {...messages.titleNotifyMeAbout} />
                </Subheading>
                <Flex mt={5}>
                  <Body style={{ width: '100%' }}>
                    <NotificationItemInput
                      name="notificationsComments"
                      label={
                        <FormattedMessage {...messages.labelActivitiesInput} />
                      }
                      message={
                        <>
                          <FormattedMessage
                            {...messages.messageActivitiesInput}
                          />{' '}
                          <Anchor
                            href="http://learn.picter.com/en/articles/2727270-follow-projects-to-receive-notifications"
                            target="_blank"
                          >
                            <FormattedMessage
                              id="NotificationsPage.messageActivitiesInputLearnMore"
                              defaultMessage="Learn more"
                            />
                          </Anchor>
                        </>
                      }
                      value={notificationsState.values.notificationsComments}
                      handleChange={notificationsState.setFieldValue}
                      defaultValue={notificationsValues.EMAIL}
                    />
                    <Box mb={6} />
                    <NotificationItemInput
                      name="notificationsMentions"
                      label={
                        <FormattedMessage
                          {...messages.labelMentionsAndAssignmentsInput}
                        />
                      }
                      message={
                        <FormattedMessage
                          {...messages.messageMentionsAndAssignmentsInput}
                        />
                      }
                      value={notificationsState.values.notificationsMentions}
                      handleChange={notificationsState.setFieldValue}
                      defaultValue={notificationsValues.EMAIL}
                    />
                  </Body>
                </Flex>
              </FormSection.Content>
            </FormSection.Fieldset>
          </FormSection>
          <FormSection>
            <FormSection.AutoSaveIndicator {...notificationsChannelAutoSave} />
            <FormSection.Fieldset>
              <FormSection.Content>
                <Subheading tag="h2">
                  <FormattedMessage {...messages.titleNotificationChannel} />
                </Subheading>
                <Flex mt={5}>
                  <Body style={{ width: '100%' }}>
                    <NotificationChannelItemInput
                      id="notificationsChannelEmail"
                      name="notificationsChannel"
                      label={
                        <FormattedMessage
                          {...messages.labelEmailChannelInput}
                        />
                      }
                      message={
                        <FormattedMessage
                          {...messages.messageEmailChannelInput}
                        />
                      }
                      value={
                        notificationsChannelState.values.notificationsChannel
                      }
                      handleChange={notificationsChannelState.setFieldValue}
                      defaultValue={notificationsValues.EMAIL}
                    />
                    <Box mb={6} />
                    <NotificationChannelItemInput
                      id="notificationsChannelSlack"
                      name="notificationsChannel"
                      disabled={!get(space, 'attributes.slackTeamId')}
                      label={
                        <FormattedMessage
                          {...messages.labelSlackChannelInput}
                        />
                      }
                      message={messageSlackChannel}
                      value={
                        notificationsChannelState.values.notificationsChannel
                      }
                      handleChange={notificationsChannelState.setFieldValue}
                      defaultValue={notificationsValues.SLACK}
                    />
                  </Body>
                </Flex>
                <Separator />
                <Body>
                  <FormattedMessage
                    {...messages.labelNotificationsInsidePicter}
                  />
                </Body>
              </FormSection.Content>
            </FormSection.Fieldset>
          </FormSection>
          <FormSection>
            <FormSection.AutoSaveIndicator {...communicationAutoSave} />
            <FormSection.Fieldset>
              <FormSection.Content>
                <Subheading tag="h2">
                  <FormattedMessage {...messages.titleCommunication} />
                </Subheading>
                <Flex mt={5}>
                  <Body style={{ width: '100%' }}>
                    <NotificationItemInput
                      name="wsNewsletter"
                      label={
                        <FormattedMessage {...messages.labelNewsletterInput} />
                      }
                      message={
                        <FormattedMessage
                          {...messages.messageNewsletterInput}
                        />
                      }
                      value={communicationState.values.wsNewsletter}
                      handleChange={communicationState.setFieldValue}
                    />
                  </Body>
                </Flex>
              </FormSection.Content>
            </FormSection.Fieldset>
          </FormSection>
        </Layout.Content>
      </Layout>
    </Document>
  );
}

export default withSuspenseLoader({
  ErrorComponent: null,
  LoadingComponent: null,
})(NotificationsPage);
