import { defineMessages } from 'react-intl';

export default defineMessages({
  titleComments: {
    id: 'ProjectCommentsContextSidebar.titleComments',
    defaultMessage: 'Comments',
  },
  labelNotLoggedLogin: {
    id: 'ProjectCommentsContextSidebar.labelNotLoggedLogin',
    defaultMessage: 'Log in',
  },
  labelNotLoggedSignup: {
    id: 'ProjectCommentsContextSidebar.labelNotLoggedSignup',
    defaultMessage: 'sign up',
  },

  messageImageDeletedPlaceholder: {
    id: 'ProjectCommentsContextSidebar.messageImageDeletedPlaceholder',
    defaultMessage: 'This file has been deleted',
  },
  labelEditComment: {
    id: 'ProjectCommentsContextSidebar.labelEditComment',
    defaultMessage: 'Edit',
  },
  labelEditedComment: {
    id: 'ProjectCommentsContextSidebar.labelEditedComment',
    defaultMessage: ' (edited)',
  },
  labelCancelCommentEdit: {
    id: 'ProjectCommentsContextSidebar.labelCancelCommentEdit',
    defaultMessage: 'Cancel',
  },
  labelSaveCommentEdit: {
    id: 'ProjectCommentsContextSidebar.labelSaveCommentEdit',
    defaultMessage: 'Save changes',
  },
  labelDeleteComment: {
    id: 'ProjectCommentsContextSidebar.labelDeleteComment',
    defaultMessage: 'Delete',
  },
  messageDeleteCommentSuccess: {
    id: 'ProjectCommentsContextSidebar.messageDeleteCommentSuccess',
    defaultMessage: 'Comment deleted',
  },
  messageModalDeleteComment: {
    id: 'ProjectCommentsContextSidebar.messageModalDeleteComment',
    defaultMessage:
      'Are you sure you want to delete this comment? This is irreversible.',
  },

  // CommentsEmpty
  titleEmptyComments: {
    id: 'CommentsEmpty.titleEmptyComments',
    defaultMessage: `{filter, select,
      resolved {No resolved comments}
      unresolved {No unresolved comments}
      mentions {No one's mentioned you yet}
      other {No one's commented yet}
    }`,
  },
  messageEmptyComments: {
    id: 'CommentsEmpty.messageEmptyComments',
    defaultMessage: `{filter, select,
      resolved {Maybe you'll have more luck if you change the comment filter.}
      unresolved {Either everything's sorted or you'll need to change the comment filter.}
      mentions {Try changing the comment filter.}
      other {Why don't you break the ice?}
    }`,
  },

  // CommentsFilterChooser
  titleCommentsFilterChooser: {
    id: 'CommentsFilterChooser.titleCommentsFilterChooser',
    defaultMessage: 'Filter comments',
  },
  labelAllComments: {
    id: 'CommentsFilterChooser.labelAllComments',
    defaultMessage: 'All comments',
  },
  labelUnresolved: {
    id: 'CommentsFilterChooser.labelUnresolved',
    defaultMessage: 'Unresolved comments',
  },
  labelResolved: {
    id: 'CommentsFilterChooser.labelResolved',
    defaultMessage: 'Resolved comments',
  },
  labelMentions: {
    id: 'CommentsFilterChooser.labelMentions',
    defaultMessage: 'Mentions of me',
  },
});
