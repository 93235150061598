import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import {
  Event as EventSvg,
  Business as BusinessSvg,
  CloudDownload as CloudDownloadSvg,
  Link as LinkSvg,
  People as PeopleSvg,
} from '@styled-icons/material';
import { Link, useParams } from 'react-router-dom';
import { TrackerEvent } from '@picter/tracker';

import {
  Box,
  Body,
  Text,
  Heading,
  Icon,
  Grid,
  Anchor,
} from 'src/modules/prisma';
import { contestsUrl, librarySpaceUrl } from 'src/routes/urls';
import { $TSFixMe } from 'src/types';

import messages from '../_messages';

export default function CancelSubscriptionLosses() {
  const { spaceId } = useParams<{ spaceId: string }>();

  return (
    <>
      <Heading fontWeight="light" tag="h1" textSize="large">
        <FormattedMessage {...messages.titleConsequences} />
      </Heading>
      <Box my={5}>
        <Box p={0} m={0} mt={5} tag="ul" width={[1, 1, 1, 2 / 3, 1 / 2]}>
          <TrackerEvent name="Consequences" trackView>
            <Grid
              tag="li"
              display="grid"
              gridTemplateColumns="20px 1fr"
              gridGap={3}
              gmt={5}
            >
              <Icon color="grey.600" type={<EventSvg />} />
              <Box mt={-1}>
                <Body fontWeight="light" textSize="large">
                  <FormattedMessage
                    {...messages.messageSubscriptionEndingOn}
                    values={{
                      bold: (...chunks: unknown[]) => (
                        <Text fontWeight="strong">{chunks}</Text>
                      ),
                      endingDate: (
                        <FormattedDate
                          key={spaceId}
                          value={1604703599 * 1000}
                          year="numeric"
                          month="long"
                          day="2-digit"
                        />
                      ),
                    }}
                  />
                </Body>
              </Box>
            </Grid>
            <Grid
              tag="li"
              display="grid"
              gridTemplateColumns="20px 1fr"
              gridGap={3}
              gmt={5}
            >
              <Icon color="grey.600" type={<BusinessSvg />} />
              <Box mt={-1}>
                <Body fontWeight="light" textSize="large">
                  <FormattedMessage
                    {...messages.messageDeletionAfterSevenDays}
                  />
                </Body>
              </Box>
            </Grid>
            <Grid
              tag="li"
              display="grid"
              gridTemplateColumns="20px 1fr"
              gridGap={3}
              gmt={5}
            >
              <Icon color="grey.600" type={<CloudDownloadSvg />} />
              <Box mt={-1}>
                <Body fontWeight="light" textSize="large">
                  <FormattedMessage
                    {...messages.messageMakeSureToSaveFiles}
                    values={{
                      linked: (...chunks: $TSFixMe) => (
                        <Anchor
                          tag={Link}
                          textStyle="body.large"
                          to={librarySpaceUrl({ spaceId })}
                        >
                          {chunks}
                        </Anchor>
                      ),
                    }}
                  />
                </Body>
              </Box>
            </Grid>
            <Grid
              tag="li"
              display="grid"
              gridTemplateColumns="20px 1fr"
              gridGap={3}
              gmt={5}
            >
              <Icon color="grey.600" type={<LinkSvg />} />
              <Box mt={-1}>
                <Body fontWeight="light" textSize="large">
                  <FormattedMessage {...messages.messageSharedLinksDeleted} />
                </Body>
              </Box>
            </Grid>
            <Grid
              tag="li"
              display="grid"
              gridTemplateColumns="20px 1fr"
              gridGap={3}
              gmt={5}
            >
              <Icon color="grey.600" type={<PeopleSvg />} />
              <Box mt={-1}>
                <Body fontWeight="light" textSize="large">
                  <FormattedMessage
                    {...messages.messageTeamAccountsDeleted}
                    values={{
                      linked: (...chunks: $TSFixMe) => (
                        <Anchor
                          textStyle="body.large"
                          href={contestsUrl()}
                          target="blank"
                        >
                          {chunks}
                        </Anchor>
                      ),
                    }}
                  />
                </Body>
              </Box>
            </Grid>
          </TrackerEvent>
        </Box>
      </Box>
    </>
  );
}
