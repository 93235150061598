import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Add as AddSvg, Check as CheckSvg } from '@styled-icons/material';
import classNames from 'classnames';

import ContentIcon from '../styles/ContentIcon';
import ContentIconGroup from '../styles/ContentIconGroup';

const NoApprovalsIcon = forwardRef(
  ({ active, boxSize, size, id, className, onClick, ...props }, ref) => {
    return (
      <ContentIconGroup
        className={className}
        id={id}
        onClick={onClick}
        role="button"
        ref={ref}
        {...props}
      >
        <ContentIcon
          className={classNames('content-icon content-icon--active', {
            'content-icon--visible': active,
          })}
          borderColor="success"
          color="success"
          content={AddSvg}
          type={<CheckSvg />}
          boxSize={boxSize}
          size={size}
        />
        <ContentIcon
          className="content-icon content-icon--hover"
          borderColor="grey.400"
          color="black"
          content={AddSvg}
          type={<CheckSvg />}
          boxSize={boxSize}
          size={size}
        />
        <ContentIcon
          className="content-icon content-icon--default"
          borderColor="grey.300"
          color="grey.600"
          type={<CheckSvg />}
          boxSize={boxSize}
          size={size}
        />
      </ContentIconGroup>
    );
  },
);

NoApprovalsIcon.defaultProps = {
  active: false,
  boxSize: undefined,
  className: undefined,
  id: undefined,
  onClick: undefined,
  size: undefined,
};

NoApprovalsIcon.propTypes = {
  active: PropTypes.bool,
  boxSize: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
};

export default NoApprovalsIcon;
