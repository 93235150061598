import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Box, Heading } from 'src/modules/prisma';
import { matchShape } from 'src/utils/app-prop-types';
import useDocumentTitle from 'src/hooks/use-document-title';
import withSuspenseLoader from 'src/hocs/with-suspense-loader';
import { librarySettingsSpaceSubscriptionUrl } from 'src/routes/urls';
import useNavigate from 'src/hooks/use-navigate';
import usePromise from 'src/hooks/use-promise';

import CancelSubscriptionConsequences from './_components/CancelSubscriptionConsequences';
import CancelSubscriptionLosses from './_components/CancelSubscriptionLosses';
import CancelSubscriptionOffers from './_components/CancelSubscriptionOffers';
import CancelSubscriptionNavigation from './_components/CancelSubscriptionNavigation';
import Document from './_document';
import Layout from '../_layout';
import messages from './_messages';
import { useSubscriptionService } from '../_components/SubscriptionService';

export enum CancelProcessStep {
  CONSEQUENCES,
  LOSSES,
  OFFERS,
}

function CancelSubscriptionPage() {
  useDocumentTitle('Cancel subscription');

  const [step, setStep] = useState<CancelProcessStep>(CancelProcessStep.LOSSES);
  const navigate = useNavigate();
  const { spaceId } = useParams<{ spaceId: string }>();
  const subscriptionService = useSubscriptionService();
  const {
    trigger: cancelSubscription,
    isLoading: isLoadingCancel,
  } = usePromise(() =>
    subscriptionService
      .cancel({ spaceId })
      .then(() => navigate(librarySettingsSpaceSubscriptionUrl({ spaceId }))),
  );

  return (
    <Document>
      <Layout>
        <Layout.Top>
          <Heading tag="h1" fontWeight="light" textSize="regular">
            <FormattedMessage {...messages.titleCancelSubscriptionPage} />
          </Heading>
        </Layout.Top>
        <Layout.Content>
          <Box display="flex" flexDirection="column" height="100%">
            {step === CancelProcessStep.LOSSES && (
              <>
                <CancelSubscriptionLosses />
                <Box mt="auto">
                  <CancelSubscriptionNavigation
                    disabled={isLoadingCancel}
                    onNext={() => setStep(CancelProcessStep.OFFERS)}
                  />
                </Box>
              </>
            )}
            {step === CancelProcessStep.OFFERS && (
              <>
                <CancelSubscriptionOffers />
                <Box mt="auto">
                  <CancelSubscriptionNavigation
                    disabled={isLoadingCancel}
                    onPrevious={() => setStep(CancelProcessStep.LOSSES)}
                    onNext={() => setStep(CancelProcessStep.CONSEQUENCES)}
                  />
                </Box>
              </>
            )}
            {step === CancelProcessStep.CONSEQUENCES && (
              <>
                <CancelSubscriptionConsequences />
                <Box mt="auto">
                  <CancelSubscriptionNavigation
                    disabled={isLoadingCancel}
                    onPrevious={() => setStep(CancelProcessStep.OFFERS)}
                    onCancel={cancelSubscription}
                  />
                </Box>
              </>
            )}
          </Box>
        </Layout.Content>
      </Layout>
    </Document>
  );
}

CancelSubscriptionPage.propTypes = {
  match: matchShape.isRequired,
};

export default withSuspenseLoader({
  ErrorComponent: null,
  LoadingComponent: null,
})(CancelSubscriptionPage);
