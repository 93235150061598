import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import messages from './messages';

export const JUST_ME_VALUE = 'Just me';

export default function useCompanySizeOptions() {
  const intl = useIntl();

  return useMemo(
    () => [
      {
        value: JUST_ME_VALUE,
        label: intl.formatMessage(messages.labelCompanySizeJustMe),
      },
      {
        value: '1 - 5',
        label: intl.formatMessage(messages.labelCompanySize1To5),
      },
      {
        value: '5 - 25',
        label: intl.formatMessage(messages.labelCompanySize5To25),
      },
      {
        value: '25 - 50',
        label: intl.formatMessage(messages.labelCompanySize25To50),
      },
      {
        value: '50 - 100',
        label: intl.formatMessage(messages.labelCompanySize50To100),
      },
      {
        value: '100+',
        label: intl.formatMessage(messages.labelCompanySize100More),
      },
    ],
    [intl],
  );
}
