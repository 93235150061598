import { defineMessages } from 'react-intl';

export default defineMessages({
  labelScreenObservedBy: {
    defaultMessage: `Your screen is observed by {numberOfObservers, plural,
      =0 {no users}
      one {1 user}
      other {{numberOfObservers} users}
    }`,
    id: 'ObservationModeIndicator.labelScreenObservedBy',
  },
});
