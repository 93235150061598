import styled from 'styled-components/macro';
import { transparentize } from 'polished';

const UploadArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) =>
    transparentize(0.98, theme.colors.primary)};
  border: dashed 1px ${({ theme }) => transparentize(0.8, theme.colors.primary)};
  min-height: 200px;
  padding: ${props => props.theme.space[5]}px;
  width: 100%;

  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    min-height: 260px;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints[4]}) {
    padding: ${props => props.theme.space[8]}px;
  }
`;

export default UploadArea;
