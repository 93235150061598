import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

import RouteAuthenticated from 'src/utils/cerberus/components/RouteAuthenticated';
import NotFoundPage from 'src/components/NotFoundPage';
import SpaceFavicon from 'src/containers/SpaceFavicon';
import paths from 'src/routes/paths';
import { libraryProjectsUrl } from 'src/routes/urls';
import { locationShape, matchShape } from 'src/utils/app-prop-types';
import { PROJECT_RELATION_FILTER } from 'src/constants';
import { viewModeTypes } from 'src/routes/constants';

import Document from './_document';
import IndexPage from './index';
import ProjectRoutes from './p/_routes';
import PresentationRoutes from './v/_routes';
import RequestFilesRoutes from './d/_routes';
import ReviewRoutes from './r/_routes';
import SpacesRoutes from './spaces/_routes';
import SettingsRoutes from './settings/_routes';

const { HOME_PATH, LIBRARY_SPACES_PATH, LIBRARY_SETTINGS_PATH } = paths;

const Routes = () => {
  return (
    <Document>
      {/* Try to use SpaceFavicon on all public sharing links */}
      <Route
        path={`${libraryProjectsUrl({
          viewMode: `(${viewModeTypes.PRESENT}|${viewModeTypes.REVIEW}|${viewModeTypes.REQUEST_FILES})`,
        })}/:projectId`}
        component={SpaceFavicon}
      />
      <Switch>
        <RouteAuthenticated path={HOME_PATH} exact component={IndexPage} />
        <RouteAuthenticated
          path={LIBRARY_SPACES_PATH}
          component={SpacesRoutes}
        />
        <RouteAuthenticated
          path={LIBRARY_SETTINGS_PATH}
          component={SettingsRoutes}
        />
        <Route
          path={libraryProjectsUrl({
            viewMode: `(${Object.values(viewModeTypes).join('|')})`,
          })}
          render={props => {
            if (props.match.isExact && !props.location.query.roles) {
              return (
                <Redirect
                  to={libraryProjectsUrl(
                    { viewMode: viewModeTypes.DEFAULT },
                    {
                      roles: [PROJECT_RELATION_FILTER.OWNER],
                    },
                  )}
                />
              );
            }
            return (
              <Switch>
                <Route
                  path={libraryProjectsUrl({
                    viewMode: viewModeTypes.DEFAULT,
                  })}
                  component={ProjectRoutes}
                />
                <Route
                  path={libraryProjectsUrl({
                    viewMode: viewModeTypes.PRESENT,
                  })}
                  component={PresentationRoutes}
                />
                <Route
                  path={libraryProjectsUrl({
                    viewMode: viewModeTypes.REQUEST_FILES,
                  })}
                  component={RequestFilesRoutes}
                />
                <Route
                  path={libraryProjectsUrl({
                    viewMode: viewModeTypes.REVIEW,
                  })}
                  component={ReviewRoutes}
                />
              </Switch>
            );
          }}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Document>
  );
};

Routes.propTypes = {
  location: locationShape.isRequired,
  match: matchShape.isRequired,
};

export default withRouter(Routes);
