import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  projectIdByPublicKeySelector,
  projectIdByPublicLinkSelector,
} from 'src/selectors/project';
import {
  useToriiSelector,
  useResource,
  useResourceSelector,
  useResourceLoader,
  resourceShouldLoad,
} from 'src/modules/torii';
import uploadStorage from 'src/utils/upload-storage';
import injectPublicLinkKey from 'src/utils/inject-public-link-key';
import { viewModeTypes } from 'src/routes/constants';
import usePublicKey from 'src/hooks/use-public-key';
import Cookies from 'js-cookie';

import { schemas } from 'src/components/AppProvider/store';
import { get } from 'src/utils/accessors';
import useRouter from '../use-router';

function useInjectPublicLinkKey(config) {
  const params = useParams();
  return injectPublicLinkKey(config, { params });
}

export function useCurrentUser(params, config = {}) {
  const userSelector = useCallback(state => get(state, 'user'), []);

  const authUser = useToriiSelector(userSelector);

  const id = get(authUser, 'id');

  // Needed because if you send null or undefined, torii thinks you're loading all users
  // Maybe use undefined check instead of boolean check on torii select?
  const parsedId = id || 'no-id';

  const resourceUser = useResource(
    'users',
    { ...params, id: parsedId },
    {
      ...config,
      request: parsedId !== 'no-id' && config.request,
    },
    schemas,
  );

  return useMemo(() => {
    const mergedAttributes = authUser.merge(get(resourceUser, 'attributes'));

    if (mergedAttributes.isEmpty()) {
      return resourceUser;
    }
    return resourceUser.set('attributes', mergedAttributes);
  }, [authUser, resourceUser]);
}

export function useInvitation(params, config) {
  return useResource('invitations', params, config, schemas);
}

export function useProjectImage(
  { id, include = [], includeOnRequest = [], includeOnSelector = [] },
  config,
) {
  const resourceType = 'wsProjectImages';
  const parsedConfig = useInjectPublicLinkKey(config);

  const data = useResourceSelector({
    resourceType,
    // Uses extracted project id for selector
    id,
    include,
    includeOnSelector,
    schemas,
  });
  // Avoids reload of images if its uploading. Same problem we used to have on app-content.
  const isUploading = uploadStorage.state.groups[config.publicKey];
  const shouldLoad = !isUploading && resourceShouldLoad(data, config);
  useResourceLoader(
    {
      resourceType,
      id,
      include,
      includeOnRequest,
      config: parsedConfig,
    },
    { shouldLoad },
  );

  return data;
}

export function useProjects(
  {
    include = [],
    includeOnRequest = [],
    includeOnSelector = [],
    sortBy = null,
  } = {},
  config,
) {
  const resourceType = 'wsProjects';

  let data = useResourceSelector({
    resourceType,
    include,
    includeOnSelector,
    schemas,
  });
  data = useMemo(() => {
    if (sortBy) {
      return data.sortBy(sortBy);
    }
    return data;
  }, [data, sortBy]);

  const shouldLoad = resourceShouldLoad(data, config);

  useResourceLoader(
    {
      resourceType,
      include,
      includeOnRequest,
      config,
    },
    { ...config, shouldLoad },
  );

  return data;
}

export function useProject(
  {
    id /* project public url key */,
    include = [],
    includeOnRequest = [],
    includeOnSelector = [],
  },
  config,
) {
  const {
    match: { params },
  } = useRouter();
  const resourceType = 'wsProjects';
  const { viewMode = viewModeTypes.DEFAULT, projectId: projectKey } = params;
  // Uses match.params id for consistency but fallsback to parameter id
  const selectorProjectKey = projectKey || id;
  const projectId = useToriiSelector(state => {
    return viewMode === viewModeTypes.DEFAULT
      ? projectIdByPublicKeySelector(state, { publicKey: selectorProjectKey })
      : projectIdByPublicLinkSelector(state, {
          publicLink: selectorProjectKey,
        }) || projectKey;
  });

  const data = useResourceSelector({
    resourceType,
    // Uses extracted project id for selector
    id: projectId,
    include,
    includeOnSelector,
    schemas,
  });

  // Avoids reload of images if its uploading. Same problem we used to have on app-content.
  const group = uploadStorage.state.groups[id];
  const isUploadingImages = group && group.uploadedCount !== group.totalCount;

  const shouldLoad = !isUploadingImages && resourceShouldLoad(data, config);

  let loaderParameters;
  if (viewMode === viewModeTypes.DEFAULT) {
    loaderParameters = [
      {
        resourceType,
        // Uses public key for requests
        id,
        include,
        includeOnRequest,
        config,
      },
      { shouldLoad },
    ];
  } else {
    const parsedForPublicLinkInclude = include.map(
      relation => `project.${relation}`,
    );

    const parsedForPublicLinkIncludeOnRequest = includeOnRequest.map(
      relation => `project.${relation}`,
    );

    const collectionCookie = Cookies.get(`inbox-key/${id}`);
    const [, inboxLinkKey] = collectionCookie
      ? collectionCookie.split(':')
      : [];

    const parsedConfig =
      viewMode === viewModeTypes.REQUEST_FILES && inboxLinkKey
        ? {
            ...config,
            querySerializers: {
              inboxLinkKey: () => `inboxLinkKey=${inboxLinkKey}`,
            },
          }
        : config;

    loaderParameters = [
      {
        resourceType: 'wsPublicLinks',
        // Uses public key for requests
        id: params.projectId,
        include: parsedForPublicLinkInclude,
        includeOnRequest: parsedForPublicLinkIncludeOnRequest,
        config: parsedConfig,
      },
      { shouldLoad },
    ];
  }

  useResourceLoader(...loaderParameters);

  return data;
}

function useWithPublicKey(params, config, dependencies) {
  const injectKey = usePublicKey();
  return this(params, injectKey(config), dependencies);
}

export function useComments(
  {
    include = [],
    includeOnRequest = [],
    includeOnSelector = [],
    sortBy = null,
    filter = null,
  },
  config,
  dependencies = [],
) {
  const resourceType = 'wsComments';

  let data = useResourceSelector({
    resourceType,
    include,
    includeOnSelector,
    schemas,
  });

  /**
   * Using `toString` for DX improvement to prevent recalculations and the need
   * of using `useMemo` to create filter and sorter functions.
   */
  data = useMemo(() => {
    if (filter) return data.filter(filter);
    return data;
    // Warns about spreading `dependencies` array and not statically checking it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filter && filter.toString(), ...dependencies]);

  data = useMemo(() => {
    if (sortBy) return data.sortBy(sortBy);
    return data;
    // Warns about spreading `dependencies` array and not statically checking it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, sortBy && sortBy.toString(), ...dependencies]);

  const shouldLoad = resourceShouldLoad(data, config);

  useResourceLoader(
    {
      resourceType,
      include,
      includeOnRequest,
      config,
    },
    { shouldLoad },
  );

  return data;
}
useComments.withPublicKey = useWithPublicKey.bind(useComments);

export function useComment(
  { id, include = [], includeOnRequest = [], includeOnSelector = [] },
  config,
) {
  const resourceType = 'wsComments';
  const data = useResourceSelector({
    id,
    resourceType,
    include,
    includeOnSelector,
    schemas,
  });

  const shouldLoad = resourceShouldLoad(data, config);

  useResourceLoader(
    {
      resourceType,
      include,
      includeOnRequest,
      config,
    },
    { shouldLoad },
  );

  return data;
}
useComment.withPublicKey = useWithPublicKey.bind(useComment);

export function useSpace(params = {}, config = {}) {
  return useResource('wsSpaces', params, config, schemas);
}

export function useSpaceProjects(params = {}, config = {}) {
  return useResource('wsSpaces.projects', params, config, schemas);
}

export function useSpaceFolders(params = {}, config = {}) {
  return useResource('wsSpaces.folders', params, config, schemas);
}

export function useFolder(params = {}, config = {}) {
  return useResource('wsFolders', params, config, schemas);
}
