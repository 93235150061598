import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@picter/prisma';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import parseUrl from 'url-parse';
import { parse as parseQueryString } from 'qs';

function parseTo(to) {
  const { pathname, query } = parseUrl(to);
  const queryObject = parseQueryString(query, {
    ignoreQueryPrefix: true,
    comma: true,
  });

  return {
    escapedPath: pathname.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1'),
    queryObject,
  };
}

function DropdownLink({ exact, to, active, ...props }) {
  const { escapedPath, queryObject } = parseTo(to);
  const match = useRouteMatch(escapedPath);
  const history = useHistory();
  const { query } = useLocation();
  const { closeMenu } = useContext(Dropdown.Context);

  const doesMatch = !!match;
  const isExact = doesMatch && match.isExact && isEqual(queryObject, query);
  const isActive =
    active !== undefined ? active : doesMatch && ((exact && isExact) || !exact);

  const handleClick = useCallback(() => {
    history.push(to);
    closeMenu();
  }, [closeMenu, history, to]);

  return <Dropdown.Item {...props} active={isActive} onClick={handleClick} />;
}

DropdownLink.propTypes = {
  exact: PropTypes.bool,
  to: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

DropdownLink.defaultProps = {
  exact: false,
  active: undefined,
};

export default DropdownLink;
