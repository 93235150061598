import { defineMessages } from 'react-intl';

export default defineMessages({
  // MainNavigation
  labelPersonal: {
    id: 'MainNavigation.labelPersonal',
    defaultMessage: 'My Projects',
  },
  labelPublic: {
    id: 'MainNavigation.labelPublic',
    defaultMessage: 'Shared with me',
  },
});
