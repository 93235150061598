import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@rebass/grid';

import { Box } from 'src/modules/prisma';
import useHoverEvents from 'src/hooks/use-hover-events';

import ItemButton from '../styles/NavigationItemButton';
import ItemIcon from '../styles/NavigationItemIcon';
import ItemText from '../styles/NavigationItemText';
import ItemActions from '../styles/NavigationItemActions';

const NavigationButton = ({
  icon,
  label,
  onClick,
  textStyle,
  actions,
  rightItem,
  // these props can be used to pass data attributes down to the link element
  ...passThroughProps
}) => {
  const [hovering, hoverEvents] = useHoverEvents();
  return (
    <Box
      position="relative"
      tag="li"
      style={{ overflow: 'hidden' }}
      {...hoverEvents}
    >
      <ItemButton onClick={onClick} {...passThroughProps}>
        <ItemIcon type={icon} />
        <Flex justifyContent="space-between" flex={1}>
          <Box>
            <ItemText textStyle={textStyle}>{label}</ItemText>
          </Box>
          {rightItem && !hovering && (
            <Box>
              <ItemText textStyle={textStyle}>{rightItem}</ItemText>
            </Box>
          )}
        </Flex>
      </ItemButton>
      {(rightItem ? hovering : !rightItem) && actions && (
        <ItemActions>
          {actions.map(action => (
            <Box key={action.type} display="inline-block" gml={2}>
              {action}
            </Box>
          ))}
        </ItemActions>
      )}
    </Box>
  );
};

NavigationButton.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.element),
  icon: PropTypes.string,
  label: PropTypes.node,
  rightItem: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  textStyle: PropTypes.string,
};

NavigationButton.defaultProps = {
  actions: null,
  icon: null,
  label: null,
  rightItem: null,
  textStyle: 'heading',
};

export default NavigationButton;
