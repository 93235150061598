import get from 'lodash/fp/get';
import curry from 'lodash/curry';
import { stringify as stringifyQueryString } from 'qs';

import { viewModeTypes } from './constants';
import paths, { getPathMetaData } from './paths';

const {
  NODE_ENV,
  PUBLIC_URL,
  REACT_APP_API_URL,
  REACT_APP_CONTESTS_URL,
  REACT_APP_ENV,
} = process.env;

function createPathUrlCreator(pathWithPlaceholders, { prefix = '' } = {}) {
  return function createPathUrl(params = {}, queries = {}) {
    // replace placeholders with ids e.g. /p/:projectId - /p/1000
    const pathWithIds = Object.entries(params)
      .filter(([, paramValue]) => !!paramValue)
      .reduce((path, [param, paramValue]) => {
        // eslint-disable-next-line no-useless-escape
        return path.replace(`:${param}`, paramValue);
      }, pathWithPlaceholders);

    // build a query string e.g. filter=approved
    const queryString = stringifyQueryString(queries, {
      encode: false,
      arrayFormat: 'comma',
    });

    return queryString
      ? `${prefix}${pathWithIds}?${queryString}`
      : `${prefix}${pathWithIds}`;
  };
}

function createUrl(...params) {
  const createPathUrl = createPathUrlCreator(...params);

  return Object.assign(createPathUrl, {
    builder: curry(createPathUrl, 2),
    placeholder: curry.placeholder,
  });
}

function createUrlWithViewMode(...rootParams) {
  const createPathUrl = (...params) => {
    const fn = createPathUrlCreator(...rootParams);
    return fn(...params).replace(
      `(${Object.values(viewModeTypes).join('|')})`,
      '',
    );
  };

  const wrappedCreatePathUrl = (params = {}, ...rest) => {
    const { params: matchParams = {} } = getPathMetaData(
      window.location.pathname,
    );
    const viewMode =
      params.viewMode || matchParams.viewMode || viewModeTypes.DEFAULT;
    return createPathUrl({ ...params, viewMode }, ...rest);
  };

  return Object.assign(wrappedCreatePathUrl, {
    builder: curry(wrappedCreatePathUrl, 2),
    placeholder: curry.placeholder,
  });
}

// URL: To use with `Link` components
export const apiUrl = () => REACT_APP_API_URL;
export const contestsUrl = createUrl('', {
  prefix: REACT_APP_CONTESTS_URL,
});
export const domainUrl = () =>
  ({
    development: 'http://localhost:8080',
    production: 'https://workspace.picter.com',
    beta: 'https://workspace.beta.picter.com',
    review: `https://review.picter.io${PUBLIC_URL}`,
    'review-production': `https://production.review.picter.com${PUBLIC_URL}`,
    staging: 'https://workspace.picter.io',
  }[REACT_APP_ENV || NODE_ENV]);
export const homeUrl = () => paths.HOME_PATH;
export const publicReferralLinkUrl = createUrl(paths.REFERRAL_LINK_PATH, {
  prefix: domainUrl(),
});
export const passwordRecoveryUrl = () => paths.PASSWORD_RECOVERY_PATH;
export const librarySpacesUrl = createUrl(paths.LIBRARY_SPACES_PATH);
export const librarySpaceUrl = createUrl(paths.LIBRARY_SPACE_PATH);
export const librarySpacesNewUrl = createUrl(paths.LIBRARY_SPACES_NEW_PATH);
export const librarySpacesNew3Url = createUrl(paths.LIBRARY_SPACES_NEW_3_PATH);
export const librarySpaceFoldersUrl = createUrl(
  paths.LIBRARY_SPACE_FOLDERS_PATH,
);
export const librarySpaceFolderUrl = createUrl(paths.LIBRARY_SPACE_FOLDER_PATH);
export const librarySpaceTrashUrl = createUrl(paths.LIBRARY_SPACE_TRASH_PATH);
export const librarySpaceTasksUrl = createUrl(paths.LIBRARY_SPACE_TASKS_PATH);
export const librarySpaceTaskUrl = createUrl(paths.LIBRARY_SPACE_TASK_PATH);
export const librarySettingsSpaceUrl = createUrl(
  paths.LIBRARY_SETTINGS_SPACE_PATH,
);
export const librarySettingsSpaceGeneralUrl = createUrl(
  paths.LIBRARY_SETTINGS_SPACE_GENERAL_PATH,
);
export const librarySettingsSpaceMembersUrl = createUrl(
  paths.LIBRARY_SETTINGS_SPACE_MEMBERS_PATH,
);
export const librarySettingsSpaceSubscriptionUrl = createUrl(
  paths.LIBRARY_SETTINGS_SPACE_SUBSCRIPTION_PATH,
);
export const librarySettingsSpaceSubscriptionCancelUrl = createUrl(
  paths.LIBRARY_SETTINGS_SPACE_SUBSCRIPTION_CANCEL_PATH,
);
export const librarySettingsSpaceCreditsUrl = createUrl(
  paths.LIBRARY_SETTINGS_SPACE_CREDITS_PATH,
);
export const librarySettingsSpaceIntegrationsUrl = createUrl(
  paths.LIBRARY_SETTINGS_SPACE_INTEGRATIONS_PATH,
);
export const librarySettingsSpaceNotificationsUrl = createUrl(
  paths.LIBRARY_SETTINGS_SPACE_NOTIFICATIONS_PATH,
);
export const librarySettingsPersonalProfileUrl = createUrl(
  paths.LIBRARY_SETTINGS_PERSONAL_PROFILE_PATH,
);
export const librarySettingsPersonalManageAccountUrl = createUrl(
  paths.LIBRARY_SETTINGS_PERSONAL_MANAGE_ACCOUNT_PATH,
);
export const libraryImagesUrl = () => paths.LIBRARY_IMAGES_PATH;
export const libraryProjectsUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECTS_PATH,
);
export const libraryProjectsEmptyUrl = () => paths.LIBRARY_PROJECTS_EMPTY_PATH;

// project
export const libraryProjectUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_PATH,
);
export const libraryProjectInfoUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_INFO_PATH,
);
export const libraryProjectInfoCommentsUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_INFO_COMMENTS_PATH,
);
export const libraryProjectInfoCommentUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_INFO_COMMENT_PATH,
);
export const libraryProjectInboxSuccessUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_INBOX_SUCCESS_PATH,
);
export const libraryProjectCommentsUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_COMMENTS_PATH,
);
export const libraryProjectCommentUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_COMMENT_PATH,
);
export const libraryProjectShareUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_SHARE_PATH,
);
export const publicLibraryProjectUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_PATH,
  {
    prefix: domainUrl(),
  },
);
export const libraryProjectShareInviteUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_SHARE_INVITE_PATH,
);
export const libraryProjectShareReviewUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_SHARE_REVIEW_PATH,
);
export const libraryProjectSharePresentationUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_SHARE_PRESENTATION_PATH,
);
export const libraryProjectShareRequestFilesUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_SHARE_REQUEST_FILES_PATH,
);

// project image
export const libraryProjectImageUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_IMAGE_PATH,
);
export const libraryProjectImageInfoUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_IMAGE_INFO_PATH,
);
export const libraryProjectImageCommentsUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_IMAGE_COMMENTS_PATH,
);
export const libraryProjectImageCommentUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_IMAGE_COMMENT_PATH,
);
export const libraryProjectImageDownloadUrl = ({
  imageId,
  downloadSize,
  projectPublicKey,
}) =>
  `${apiUrl()}/downloads/workspace/images/${imageId}?downloadSize=${downloadSize}&publicKey=${projectPublicKey}`;

// collection
export const libraryProjectCollectionUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_COLLECTION_PATH,
);
export const libraryProjectCollectionCommentsUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_COLLECTION_COMMENTS_PATH,
);
export const libraryProjectCollectionCommentUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_COLLECTION_COMMENT_PATH,
);
export const libraryProjectCollectionShareUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_COLLECTION_SHARE_PATH,
);
export const libraryProjectCollectionImageUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_COLLECTION_IMAGE_PATH,
);

export const libraryProjectCollectionShareInviteUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_COLLECTION_SHARE_INVITE_PATH,
);
export const libraryProjectCollectionShareReviewUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_COLLECTION_SHARE_REVIEW_PATH,
);
export const libraryProjectCollectionSharePresentationUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_COLLECTION_SHARE_PRESENTATION_PATH,
);
export const libraryProjectCollectionShareRequestFilesUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_COLLECTION_SHARE_REQUEST_FILES_PATH,
);
export const libraryProjectCollectionImageInfoUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_COLLECTION_IMAGE_INFO_PATH,
);
export const libraryProjectCollectionImageCommentsUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_COLLECTION_IMAGE_COMMENTS_PATH,
);
export const libraryProjectCollectionImageCommentUrl = createUrlWithViewMode(
  paths.LIBRARY_PROJECT_COLLECTION_IMAGE_COMMENT_PATH,
);

// url function combination
export const projectInfoUrl = (() => {
  function createProjectInfoUrl({ comments, commentId, projectId }, queries) {
    if (commentId)
      return libraryProjectInfoCommentUrl({ commentId, projectId }, queries);
    if (comments) return libraryProjectInfoCommentsUrl({ projectId }, queries);
    return libraryProjectInfoUrl({ projectId }, queries);
  }

  return Object.assign(createProjectInfoUrl, {
    builder: curry(createProjectInfoUrl, 2),
    placeholder: curry.placeholder,
  });
})();

export const projectCommentsUrl = (() => {
  function createProjectCommentsUrl({ collectionId, projectId }, queries) {
    return collectionId
      ? libraryProjectCollectionCommentsUrl(
          { collectionId, projectId },
          queries,
        )
      : libraryProjectCommentsUrl({ projectId }, queries);
  }

  return Object.assign(createProjectCommentsUrl, {
    builder: curry(createProjectCommentsUrl, 2),
    placeholder: curry.placeholder,
  });
})();

export const projectShareUrl = (() => {
  function createProjectUrl({ collectionId, projectId }, queries) {
    return collectionId
      ? libraryProjectCollectionShareUrl({ collectionId, projectId }, queries)
      : libraryProjectShareUrl({ projectId }, queries);
  }

  return Object.assign(createProjectUrl, {
    builder: curry(createProjectUrl, 2),
    placeholder: curry.placeholder,
  });
})();
export const projectShareInviteUrl = (() => {
  function createProjectUrl({ collectionId, projectId }, queries) {
    return collectionId
      ? libraryProjectCollectionShareInviteUrl(
          { collectionId, projectId },
          queries,
        )
      : libraryProjectShareInviteUrl({ projectId }, queries);
  }

  return Object.assign(createProjectUrl, {
    builder: curry(createProjectUrl, 2),
    placeholder: curry.placeholder,
  });
})();
export const projectShareReviewUrl = (() => {
  function createProjectUrl({ collectionId, projectId }, queries) {
    return collectionId
      ? libraryProjectCollectionShareReviewUrl(
          { collectionId, projectId },
          queries,
        )
      : libraryProjectShareReviewUrl({ projectId }, queries);
  }

  return Object.assign(createProjectUrl, {
    builder: curry(createProjectUrl, 2),
    placeholder: curry.placeholder,
  });
})();
export const projectSharePresentationUrl = (() => {
  function createProjectUrl({ collectionId, projectId }, queries) {
    return collectionId
      ? libraryProjectCollectionSharePresentationUrl(
          { collectionId, projectId },
          queries,
        )
      : libraryProjectSharePresentationUrl({ projectId }, queries);
  }

  return Object.assign(createProjectUrl, {
    builder: curry(createProjectUrl, 2),
    placeholder: curry.placeholder,
  });
})();
export const projectShareRequestFilesUrl = (() => {
  function createProjectUrl({ collectionId, projectId }, queries) {
    return collectionId
      ? libraryProjectCollectionShareRequestFilesUrl(
          { collectionId, projectId },
          queries,
        )
      : libraryProjectShareRequestFilesUrl({ projectId }, queries);
  }

  return Object.assign(createProjectUrl, {
    builder: curry(createProjectUrl, 2),
    placeholder: curry.placeholder,
  });
})();

export const projectUrl = (() => {
  function createProjectUrl({ collectionId, projectId }, queries) {
    return collectionId
      ? libraryProjectCollectionUrl({ collectionId, projectId }, queries)
      : libraryProjectUrl({ projectId }, queries);
  }

  return Object.assign(createProjectUrl, {
    builder: curry(createProjectUrl, 2),
    placeholder: curry.placeholder,
  });
})();

export const imageUrl = (() => {
  function createImageUrl({ collectionId, imageId, projectId }, queries) {
    return collectionId
      ? libraryProjectCollectionImageUrl(
          { collectionId, imageId, projectId },
          queries,
        )
      : libraryProjectImageUrl({ imageId, projectId }, queries);
  }

  return Object.assign(createImageUrl, {
    builder: curry(createImageUrl, 2),
    placeholder: curry.placeholder,
  });
})();

export const imageInfoUrl = (() => {
  function createImageUrl({ collectionId, imageId, projectId }, queries) {
    return collectionId
      ? libraryProjectCollectionImageInfoUrl(
          { collectionId, imageId, projectId },
          queries,
        )
      : libraryProjectImageInfoUrl({ imageId, projectId }, queries);
  }

  return Object.assign(createImageUrl, {
    builder: curry(createImageUrl, 2),
    placeholder: curry.placeholder,
  });
})();

export const imageCommentsUrl = (() => {
  function createImageUrl({ collectionId, imageId, projectId }, queries) {
    return collectionId
      ? libraryProjectCollectionImageCommentsUrl(
          { collectionId, imageId, projectId },
          queries,
        )
      : libraryProjectImageCommentsUrl({ imageId, projectId }, queries);
  }

  return Object.assign(createImageUrl, {
    builder: curry(createImageUrl, 2),
    placeholder: curry.placeholder,
  });
})();

export const signupUrl = ({ redirectUrl } = {}) => {
  const url = paths.SIGNUP_PATH;

  if (redirectUrl) {
    return `${url}?redirectUrl=${redirectUrl}`;
  }

  return url;
};

export const loginUrl = createUrl(paths.LOGIN_PATH);

export const acceptInvitationUrl = ({ invitationId }) =>
  [apiUrl(), 'app-workspace', 'invitations', invitationId, 'accepted'].join(
    '/',
  );

export const getRedirectUrl = location =>
  get(['state', 'redirectUrl'], location) ||
  get(['query', 'redirectUrl'], location);

export const profileSetupUrl = ({ redirectUrl }) => {
  const url = paths.PROFILE_SETUP_PATH;

  if (redirectUrl) {
    return `${url}?redirectUrl=${redirectUrl}`;
  }

  return url;
};
