import React from 'react';
import PropTypes from 'prop-types';
import { DisclosureContent } from 'reakit/Disclosure';
import isFunction from 'lodash/isFunction';
import classNames from 'classnames';

import { Panel } from 'src/modules/prisma';

import { useAccordionItem } from '../providers';

function AccordionItemContent({ children, className, ...props }) {
  const state = useAccordionItem();

  return (
    <DisclosureContent
      as={Panel}
      border="1px solid transparent"
      borderRadius="2px"
      backgroundColor="white"
      className={classNames(className, 'accordion-item__content')}
      width="100%"
      {...state}
      {...props}
    >
      {isFunction(children) ? children(state) : children}
    </DisclosureContent>
  );
}

AccordionItemContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  className: PropTypes.string,
};

AccordionItemContent.defaultProps = {
  className: undefined,
};

export default AccordionItemContent;
