import React, { useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { locationShape, historyShape } from 'src/utils/app-prop-types';
import { getPathMetaData } from 'src/routes/paths';

export const RouterContext = React.createContext();

const RouterProvider = ({ children, location, history }) => {
  const match = useMemo(() => getPathMetaData(location.pathname), [
    location.pathname,
  ]);

  const value = useMemo(() => ({ match, location, history }), [
    match,
    location,
    history,
  ]);

  return (
    <RouterContext.Provider value={value}>{children}</RouterContext.Provider>
  );
};

RouterProvider.propTypes = {
  children: PropTypes.node.isRequired,
  location: locationShape.isRequired,
  history: historyShape.isRequired,
};

export default withRouter(RouterProvider);
