import React from 'react';

import { matchShape, locationShape } from 'src/utils/app-prop-types';
import { ImageProofFilter } from 'src/utils/accessors/project-image';
import ProjectPage from '../../index';

const Page = ({
  match: {
    params: { collectionId, projectId },
  },
  location: {
    query: { filter },
  },
}) => {
  return (
    <ProjectPage
      filter={filter || ImageProofFilter.ALL}
      collectionId={collectionId}
      projectId={projectId}
    />
  );
};

Page.propTypes = {
  location: locationShape.isRequired,
  match: matchShape.isRequired,
};

export default Page;
