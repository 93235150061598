import React from 'react';
import PropTypes from 'prop-types';
import { Container, Provider, Subscribe } from 'unstated';
import throttle from 'lodash/throttle';
import merge from 'lodash/fp/merge';
import update from 'lodash/fp/update';
import debounceCollect from 'debounce-collect';
import Uppy from '@uppy/core';
import AWSS3 from '@uppy/aws-s3';
import generateFileID from '@uppy/utils/lib/generateFileID';

import { get } from 'src/utils/accessors';

import createSharedThrottleCollect from './create-shared-throttle-collect';
import RetryModal from './RetryModal';

const CONCURRENCY_LIMIT = 3;

export class UploadStorageContainer extends Container {
  constructor({ concurrency = CONCURRENCY_LIMIT } = {}) {
    super();

    this.state = {
      groups: {},
      shouldKeepRetrying: false,
      reachedRetryLimit: false,
    };
    this._groups = {};
    this._filesIdsMap = {};
    this._retriesMap = {};
    this._concurrency = concurrency;
  }

  _toggleBeforeUnloadListener = () => {
    const isUploading = Object.values(this.state.groups).find(group => {
      return group.uploadedCount < group.totalCount;
    });
    if (isUploading) {
      window.addEventListener('beforeunload', this._onLeaveDuringUpload);
    } else {
      window.removeEventListener('beforeunload', this._onLeaveDuringUpload);
    }
  };

  retryNetworkFailedUploads = shouldKeepRetrying => {
    this.setState({
      shouldKeepRetrying,
      reachedRetryLimit: false,
    });
    Object.values(this._groups).forEach(group => {
      group.retryAll();
    });
  };

  _onLeaveDuringUpload = e => {
    const confirmationMessage =
      'You have unfinshed uploads. If you leave this page, they will be cancelled.';

    (e || window.event).returnValue = confirmationMessage; // Gecko + IE
    return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
  };

  _updateGroup = throttle(group => {
    return new Promise(resolve => {
      const files = Object.values(group.state.files);

      const uploadedBytes = files.reduce((p, file) => {
        return p + Number(file.progress.bytesUploaded || 0);
      }, 0);
      const totalBytes = files.reduce((p, file) => {
        return p + Number(file.progress.bytesTotal || 0);
      }, 0);

      const uploadedFiles = files.filter(item => item.progress.uploadComplete);
      const groupState = {
        uploadedBytes,
        totalBytes,
        uploadedCount: uploadedFiles.length,
        totalCount: files.length,
        progress: (totalBytes ? uploadedBytes / totalBytes : 1) * 100,
      };

      this.setState(state =>
        update(
          `groups[${group.getID()}]`,
          oldGroup => merge(oldGroup, groupState),
          state,
        ),
      ).then(val => {
        if (uploadedFiles.length === files.length) {
          setTimeout(() => {
            this._onUploadComplete(group);
          }, 500);
        }

        resolve(val);
      });
    });
  }, 1000);

  _updateFiles = (group, eventFilesParams) => {
    return new Promise(resolve => {
      if (!Object.keys(group.state.files).length) {
        return null;
      }
      // We use it so we can unset files in a faster way
      const oldFiles = { ...this.state.groups[group.getID()].files };
      const filesStates = eventFilesParams.reduce((acc, [eventFile]) => {
        const file = group.state.files[eventFile.id];
        if (!file) {
          delete oldFiles[eventFile.meta.id];
          return acc;
        }
        if (file.progress) {
          const fileState = {
            id: file.meta.id,
            progress: file.progress.percentage,
            uploadedBytes: file.progress.bytesUploaded,
            totalBytes: file.progress.bytesTotal,
            uploadComplete: file.progress.uploadComplete,
            newVersion: file.meta.newVersion,
          };
          acc[file.meta.id] = fileState;
        }
        return acc;
      }, {});

      return resolve(
        this.setState(state =>
          update(
            `groups[${group.getID()}].files`,
            () => merge(oldFiles, filesStates),
            state,
          ),
        ),
      );
    });
  };

  _onUploadQueueStart = group => async () => {
    await this._updateGroup(group);
    this._toggleBeforeUnloadListener();
  };

  _onFileAdded = group => async eventFilesParams => {
    await this._updateGroup(group);
    await this._updateFiles(group, eventFilesParams);
  };

  _onUploadProgress = group => async eventFilesParams => {
    // Once this is throttled, we filter already finished uploads
    const filteredEventFilesParams = eventFilesParams.filter(([eventFile]) => {
      const stateGroup = this.state.groups[group.getID()];
      return get(stateGroup, `files.${eventFile.meta.id}`);
    });
    if (filteredEventFilesParams.length) {
      await this._updateGroup(group);
      await this._updateFiles(group, filteredEventFilesParams);
    }
  };

  _onUploadSuccess = group => async eventFilesParams => {
    await Promise.all(
      eventFilesParams.map(([eventFile]) => {
        return eventFile.meta.onSuccess();
      }),
    );
    await this._updateGroup(group);
    await this._updateFiles(group, eventFilesParams);
  };

  _onUploadComplete = async group => {
    this._toggleBeforeUnloadListener();
    group.close();
    const groupId = group.getID();
    delete this._groups[groupId];
    delete this._retriesMap[groupId];
  };

  _onUploadCancelled = async group => {
    group.close();
    // call updateGroup before toggling listener because we need to
    // update the group state so the toggle happen correctly.
    await this._updateGroup(group);
    this._toggleBeforeUnloadListener();
    const groupId = group.getID();
    delete this._groups[groupId];
    delete this._retriesMap[groupId];
  };

  _onFileRemoved = group => async eventFilesParams => {
    if (Object.keys(group.state.files).length) {
      await this._updateGroup(group);
      await this._updateFiles(group, eventFilesParams);
    } else {
      await this._onUploadComplete(group);
    }
  };

  _onUploadError = group => (file, _, response) => {
    if (!response || response.status === 424) {
      this._retriesMap = update(
        `${group.getID()}/${file.id}`,
        (fileRetriesCount = 0) => {
          return fileRetriesCount + 1;
        },
        this._retriesMap,
      );
      const shouldStop = Object.values(this._retriesMap).find(
        retries => retries >= 3,
      );

      if (!shouldStop || this.state.shouldKeepRetrying) {
        group.retryUpload(file.id);
      } else {
        this._retriesMap = {};
        this.setState({ reachedRetryLimit: true });
      }
    }
  };

  _createGroup = async groupId => {
    const group = Uppy({
      id: groupId,
      autoProceed: true,
      allowMultipleUploads: true,
    });

    group.use(AWSS3, {
      limit: this._concurrency,
      getUploadParameters: file => {
        return {
          method: 'PUT',
          url: file.xhrUpload.endpoint,
          headers: {
            'Content-Type': file.meta.type,
          },
        };
      },
    });

    this._groups[groupId] = group;
    this._filesIdsMap[groupId] = {};

    const groupData = {
      id: groupId,
      progress: 0,
      files: {},
      cancelGroupUploads: () => this._cancelGroupUploads(groupId),
    };

    await this.setState(state =>
      update(
        `groups[${groupId}]`,
        (oldGroup = {}) => merge(oldGroup, groupData),
        state,
      ),
    );

    const sharedThrottleCollect = createSharedThrottleCollect(1000);

    group.on('file-added', debounceCollect(this._onFileAdded(group), 100));
    group.on('upload', this._onUploadQueueStart(group));
    group.on(
      'upload-progress',
      sharedThrottleCollect(this._onUploadProgress(group)),
    );
    group.on(
      'upload-success',
      sharedThrottleCollect(this._onUploadSuccess(group)),
    );
    group.on('file-removed', debounceCollect(this._onFileRemoved(group), 100));
    group.on('upload-error', this._onUploadError(group));

    return group;
  };

  _cancelGroupUploads = groupId => {
    const group = this._groups[groupId];
    const files = Object.values(this.state.groups[groupId].files).filter(
      file => {
        return !file.uploadComplete;
      },
    );

    const { cancelledNewFiles, cancelledNewVersions } = files.reduce(
      (acc, item) => {
        if (item.newVersion) {
          acc.cancelledNewVersions.push(item.id);
        } else {
          acc.cancelledNewFiles.push(item.id);
        }
        return acc;
      },
      { cancelledNewFiles: [], cancelledNewVersions: [] },
    );

    this._onUploadCancelled(group);

    return { cancelledNewFiles, cancelledNewVersions };
  };

  _clearStorage = async () => {
    const groups = Object.values(this._groups);
    groups.forEach(group => {
      group.close();
    });

    await this.setState({
      groups: {},
      shouldKeepRetrying: false,
      reachedRetryLimit: false,
    });
    this._groups = {};
    this._filesIdsMap = {};
    this._retriesMap = {};
  };

  _createFile = async (
    groupId,
    fileId,
    { fileBlob, uploadEndpoint, mimeType },
  ) => {
    const result = await this._createFiles(groupId, [
      [fileId, { fileBlob, uploadEndpoint, mimeType }],
    ]);
    return result[0];
  };

  _createFiles = async (groupId, files) => {
    const group = this._groups[groupId] || (await this._createGroup(groupId));

    const newFilesDataMap = {};
    const newFilesXHR = { files: {} };
    const result = [];

    const descriptors = files.map(
      ([
        fileId,
        {
          fileBlob,
          onSuccess,
          onCancel,
          onFail,
          uploadEndpoint,
          validate,
          newVersion,
          mimeType,
        },
      ]) => {
        newFilesDataMap[fileId] = {
          id: fileId,
          name: fileBlob.name,
          type: mimeType,
          preview: fileBlob.preview,
          progress: 0,
          uploadedBytes: 0,
          totalBytes: fileBlob.size,
          uploadComplete: false,
        };

        const file = {
          id: fileId,
          name: fileBlob.name,
          type: mimeType,
          data: fileBlob,
          meta: {
            id: fileId,
            onSuccess,
            onCancel,
            onFail,
            uploadEndpoint,
            validate,
            newVersion,
          },
        };

        const uppyFileId = generateFileID(file);
        this._filesIdsMap[groupId][fileId] = uppyFileId;
        newFilesXHR.files[uppyFileId] = {
          progress: {},
          xhrUpload: {
            method: 'PUT',
            endpoint: uploadEndpoint,
            withCredentials: false,
            formData: false,
            bundle: false,
            metaFields: [],
          },
        };
        return file;
      },
    );

    await this.setState(state =>
      update(
        `groups[${groupId}].files`,
        oldFilesDataMap => merge(oldFilesDataMap, newFilesDataMap),
        state,
      ),
    );

    group.addFiles(descriptors);
    Object.entries(newFilesXHR.files).forEach(([itemId, itemValue]) => {
      newFilesXHR.files[itemId] = {
        ...group.state.files[itemId],
        ...itemValue,
      };
    });

    group.setState(merge(group.state, newFilesXHR));

    return result;
  };

  _validateFile = async (id, file, { onFail, validate = () => [] }) => {
    const validateErrors = await validate(file.fileBlob);
    if (validateErrors && validateErrors.length > 0) {
      if (onFail) {
        onFail({
          errors: validateErrors,
          file: { id, ...file, errors: validateErrors },
        });
      }

      return validateErrors;
    }
    return null;
  };

  dequeueFile = (groupId, fileId) => {
    const result = this.dequeueFiles(groupId, [fileId]);
    return result && result[0];
  };

  dequeueFiles = (groupId, fileIds) => {
    const group = this._groups[groupId];
    if (group) {
      const ids = fileIds.map(fileId => {
        const groupData = this._filesIdsMap[groupId];
        const uppyFileId = groupData && groupData[fileId];
        return uppyFileId;
      });
      return group.removeFiles(ids);
    }
    return null;
  };

  queueFiles = async (groupId, filesUploadConfig) => {
    const filesErrors = {};
    const approvedValidationFiles = await filesUploadConfig.reduce(
      async (accP, [fileId, file]) => {
        // Once we are using async functions inside reduce, the next acc value is a
        // promise. We need wrap the initial value in a promise to await for it before we can use it.
        // Otherwise it wont be a promise on first iteration, but it'll be on second and so on.
        const acc = await accP;
        const { onFail, validate = () => [] } = file;

        // custom file validation
        const errors = await this._validateFile(fileId, file, {
          onFail,
          validate,
        });

        if (errors) {
          filesErrors[fileId] = {
            id: fileId,
            name: file.fileBlob.name,
            type: file.fileBlob.type,
            errors,
          };
        } else {
          acc.push([fileId, file]);
        }
        return acc;
      },
      Promise.resolve([]),
    );

    await this.setState(state =>
      update(
        `groups[${groupId}].files`,
        oldFiles => merge(oldFiles, filesErrors),
        state,
      ),
    );

    return this._createFiles(groupId, approvedValidationFiles);
  };

  queueFile = async (groupId, fileId, config) => {
    const result = await this.queueFiles(groupId, [[fileId, config]]);
    return result[0];
  };

  clearGroupErrors = async groupId => {
    const files = Object.values(this.state.groups[groupId].files);
    const deletedFilesErrors = files.reduce((acc, file) => {
      acc[file.id] = { errors: null };
      return acc;
    }, {});

    await this.setState(state =>
      update(
        `groups[${groupId}].files`,
        (oldFiles = {}) => merge(oldFiles, deletedFilesErrors),
        state,
      ),
    );
  };
}

const uploadStorage = new UploadStorageContainer();

export const UploadStorageSubscribe = ({ children, to }) => (
  <Subscribe to={to || [UploadStorageContainer]}>{children}</Subscribe>
);

export const UploadStorageProvider = ({ children, inject }) => (
  <Provider inject={inject || [uploadStorage]}>
    <UploadStorageSubscribe>
      {storage => (
        <RetryModal
          reachedRetryLimit={storage.state.reachedRetryLimit}
          retryNetworkFailedUploads={storage.retryNetworkFailedUploads}
        />
      )}
    </UploadStorageSubscribe>
    {children}
  </Provider>
);

UploadStorageProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  inject: PropTypes.instanceOf(Container),
};

UploadStorageProvider.defaultProps = {
  inject: null,
};

UploadStorageSubscribe.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  to: PropTypes.instanceOf(Container),
};

UploadStorageSubscribe.defaultProps = {
  to: null,
};

export default uploadStorage;
