import themeGet from '@styled-system/theme-get';
import { useTheme } from 'styled-components/macro';

type Position = {
  bottom?: number | string;
  left?: number | string;
  right?: number | string;
};

function usePositionRelativeToMouse({
  x: xPercentage,
  y: yPercentage,
}: {
  x: number;
  y: number;
}) {
  const theme = useTheme();
  const position: Position = {};

  const isNearRightEdge = xPercentage > 50;

  position.bottom = `${100 - yPercentage}%`;

  if (isNearRightEdge) {
    position.right = `calc(${100 - xPercentage}% + ${themeGet('space.7')({
      theme,
    })}px)`;
  } else {
    position.left = `calc(${xPercentage}% + ${themeGet('space.6')({
      theme,
    })}px)`;
  }

  return position;
}

export default usePositionRelativeToMouse;
