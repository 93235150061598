import { defineMessages } from 'react-intl';

export default defineMessages({
  /**
   * Integrations Page
   */
  titleIntegrationsPage: {
    id: 'IntegrationsPage.titleIntegrationsPage',
    defaultMessage: 'Connected Apps',
  },
  titleSlackIntegration: {
    id: 'IntegrationsPage.titleSlackIntegration',
    defaultMessage: 'Slack',
  },
  descriptionSlackIntegration: {
    id: 'IntegrationsPage.descriptionSlackIntegration',
    defaultMessage: 'Receive notifications, assign and resolve tasks in Slack.',
  },
  descriptionChangeSettings: {
    id: 'IntegrationsPage.descriptionChangeSettings',
    defaultMessage: 'Go to Notifications to change settings.',
  },
  labelConnectIntegration: {
    id: 'IntegrationsPage.labelConnectIntegration',
    defaultMessage: 'Connect',
  },
  labelConnectedIntegration: {
    id: 'IntegrationsPage.labelConnectedIntegration',
    defaultMessage: 'Connected',
  },
});
