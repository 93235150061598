import React, { useCallback } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { useProject } from 'src/hooks/use-resource';
import { get, has } from 'src/utils/accessors';
import { SubscriptionStatus } from 'src/pages/index/settings/{spaceId}/subscription/_components/SubscriptionService';
import { getDateDeltaFromNowInSeconds } from 'src/utils/date';
import { librarySpaceUrl } from 'src/routes/urls';

import RestrictedRoute, {
  RestrictedRouteProps,
} from '../components/RestrictedRoute';

export default function SubscriptionRestrictedRoute(
  props: RestrictedRouteProps,
) {
  const { projectId } = useParams<{ projectId?: string }>();
  const project = useProject(
    {
      id: projectId,
      include: ['space'],
    },
    {
      request: (data: unknown) =>
        !(has(data, 'id') && has(data, 'relationships.space.id')),
    },
  );
  const space = get(project, 'relationships.space');
  const subscriptionStatus = get(space, 'attributes.subscriptionStatus');
  const trialEndsAt = get(space, 'attributes.trialEndsAt');

  const isValidSubscription = useCallback(
    () =>
      // valid subscriptions
      // 1. active
      subscriptionStatus === SubscriptionStatus.Active ||
      // 2. in trial not expired
      (subscriptionStatus === SubscriptionStatus.InTrial &&
        getDateDeltaFromNowInSeconds(trialEndsAt) > 0) ||
      // 3. cancelled but yet not expired subscription
      subscriptionStatus === SubscriptionStatus.NonRenewing,
    [subscriptionStatus, trialEndsAt],
  );

  return (
    <RestrictedRoute
      {...props}
      fallbackRender={() => (
        <Redirect to={librarySpaceUrl({ spaceId: get(space, 'id') })} />
      )}
      rule={isValidSubscription}
    />
  );
}
