import React from 'react';

export default function EmptyPresentIllustrationSvg() {
  return (
    <svg
      width="213"
      height="208"
      viewBox="0 0 213 208"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.20312"
        y="1.5"
        width="34"
        height="34"
        stroke="white"
        strokeWidth="2"
        strokeDasharray="15 15"
      />
      <rect
        x="95.2031"
        y="25.5"
        width="116"
        height="116"
        stroke="#5C62CA"
        strokeWidth="2"
        strokeDasharray="15 15"
      />
      <path d="M44.2031 57.5H175.203V188.5H44.2031V57.5Z" fill="white" />
      <rect
        x="8.20312"
        y="138.5"
        width="68"
        height="68"
        stroke="#FFC51F"
        strokeWidth="2"
        strokeDasharray="15 15"
      />
    </svg>
  );
}
