import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';

import { Panel } from 'src/modules/prisma';

const TaskPanel = styled(Panel)`
  & + & {
    border-top: 1px solid ${themeGet('colors.grey.200')};
  }
`;

TaskPanel.defaultProps = {
  borderRadius: 'small',
  display: 'flex',
  px: 6,
  py: 4,
  tag: 'li',
};

export default TaskPanel;
