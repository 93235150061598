import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MediaQuery } from '@picter/prisma';

import { Tooltip } from 'src/modules/prisma';
import ProofFieldApprovedButton from './ProofFieldApprovedButton';
import Container from '../styles/ProofFieldContainer';

class ProofField extends Component {
  /* eslint-disable no-param-reassign */
  handleClick = event => {
    const { onChange, value, values } = this.props;

    if (value) {
      event.target.value = null;
      return onChange(event, null);
    }

    const hasApproved = values && !!values.approved.length;
    const hasNotDefined = values && !!values.notDefined.length;
    const hasOnlyApproved = hasApproved && !hasNotDefined;

    if (hasOnlyApproved && onChange) {
      event.target.value = null;
      return onChange(event, null);
    }

    if (onChange) {
      event.target.value = true;
      return onChange(event, true);
    }

    return null;
  };
  /* eslint-enable no-param-reassign */

  atLeastTwo = (a, b, c) => {
    return a ? b || c : b && c;
  };

  render() {
    const {
      id,
      className,
      name,
      value,
      values,
      bgOpacityColor,
      counter,
    } = this.props;
    const isMultipleSelection = !!values;
    const hasApproved = values && !!values.approved.length;
    const hasNotDefined = values && !!values.notDefined.length;
    const hasMixedValue = this.atLeastTwo(hasApproved, hasNotDefined);
    const approvedActive = isMultipleSelection ? hasApproved : value === true;

    return (
      <Container id={id} className={className}>
        <MediaQuery query={{ minWidth: 0 }}>
          {matches =>
            matches ? (
              <>
                {counter}
                <Tooltip>
                  <Tooltip.Reference>
                    <span>
                      <ProofFieldApprovedButton
                        data-testid="image-proofing-approve"
                        name={name}
                        type={
                          isMultipleSelection && hasApproved && hasMixedValue
                            ? 'more_horiz'
                            : 'check'
                        }
                        active={approvedActive}
                        onClick={e => this.handleClick(e, approvedActive)}
                        glyphSize="xsmall"
                        interactive
                      />
                    </span>
                  </Tooltip.Reference>
                  <Tooltip.Message unstable_portal={false}>
                    Approve
                  </Tooltip.Message>
                </Tooltip>
              </>
            ) : (
              <>
                {counter}
                <ProofFieldApprovedButton
                  data-testid="image-proofing-approve"
                  name={name}
                  type={
                    isMultipleSelection && hasApproved && hasMixedValue
                      ? 'more_horiz'
                      : 'check'
                  }
                  active={approvedActive}
                  onClick={e => this.handleClick(e, approvedActive)}
                  glyphSize="large"
                  bgOpacityColor={bgOpacityColor}
                />
              </>
            )
          }
        </MediaQuery>
      </Container>
    );
  }
}

ProofField.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  counter: PropTypes.number,
  values: PropTypes.objectOf(PropTypes.array),
  bgOpacityColor: PropTypes.string,
};

ProofField.defaultProps = {
  id: null,
  className: null,
  onChange: null,
  value: null,
  counter: null,
  values: null,
  bgOpacityColor: null,
};

ProofField.displayName = 'ProofField';

export default ProofField;
