import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import camelCase from 'lodash/camelCase';
import trim from 'lodash/trim';

import { get } from 'src/utils/accessors';
import useFormatMessage from 'src/hooks/use-format-message';
import { useCurrentUser } from 'src/hooks/use-resource';

import Notification from './Notification';
import messages from '../messages';
import { stripMentionTags } from '../helpers';

export const ACTIVITY_STREAM_TYPES = {
  ACCEPT_INVITATION: 'accept_invitation',
  APPROVE_IMAGE: 'approve_image',
  COMMENT: 'comment',
  REPLY_COMMENT: 'reply_comment',
  DOWNLOAD_IMAGE: 'download_image',
  DOWNLOAD_PROJECT: 'download_project',
  REJECT_IMAGE: 'reject_image',
  REPLACE_FILE: 'replace_file',
  RESOLVE_COMMENT: 'resolve_comment',
  UNRESOLVE_COMMENT: 'unresolve_comment',
  ASSIGN_COMMENT: 'assign_comment',
  UNASSIGN_COMMENT: 'unassign_comment',
  REASSIGN_COMMENT: 'reassign_comment',
  UPLOAD_FILE: 'upload_file',
  VIEW_LINK: 'view_link',
  FINISH_INBOX_UPLOAD: 'finish_inbox_upload',
};

const getTargetLink = ({ target, context, object, count }) => {
  if (!target.attributes) {
    return null;
  }

  // Mentions and comments both have a comment as object. In these
  // cases, it's useful to directly link to this comment. So we
  // need to create a comment appendix for the link.
  const objectIsComment = object && object.type === 'ws-comments';
  let commentAppendix = '';
  if (objectIsComment) {
    switch (count) {
      // If the object is only one comment (not part of a group activity)
      // then we can link directly to the comment thread
      case undefined:
      case 1:
        commentAppendix = `/comments/${object.id}`;
        break;
      // In other cases, we only can link to the list of all comments
      default:
        commentAppendix = `/comments`;
        break;
    }
  }

  switch (target.type) {
    case 'ws-projects':
      return (
        target.attributes &&
        `/p/${target.attributes['public-url-key']}${commentAppendix}`
      );
    case 'ws-project-images':
      return target.attributes
        ? `/p/${context.attributes['public-url-key']}/i/${target.id}${commentAppendix}`
        : context.attributes &&
            `/p/${context.attributes['public-url-key']}${commentAppendix}`;
    case 'ws-comments': {
      const imageId = get(target, 'relationships.image.data.id');
      const publicUrlKey = get(context, 'attributes.public-url-key');
      const threadId = target.id;
      return imageId
        ? `/p/${publicUrlKey}/i/${imageId}/comments/${threadId}`
        : `/p/${publicUrlKey}/comments/${threadId}`;
    }
    default:
      return null;
  }
};

function getTitle(item) {
  if (!item) {
    return null;
  }
  switch (item.type) {
    case 'ws-projects':
    case 'ws-project-images':
      // Return undefined on untitled items and null if item does
      // not exist anymore (deleted).
      // This is necessary to show respective messages for both cases.
      return item.attributes ? item.attributes.title || undefined : null;
    case 'ws-comments': {
      const plainComment = stripMentionTags(get(item, 'attributes.text', ''));
      return plainComment.length > 30
        ? `${plainComment.slice(0, 30)}...`
        : plainComment;
    }
    case 'ws-collections':
      return item.attributes ? item.attributes.name || undefined : null;

    default:
      return null;
  }
}

const ActivityStreamItem = memo(({ data }) => {
  const currentUser = useCurrentUser();
  const formatMessage = useFormatMessage();
  const {
    target,
    actor,
    id,
    published,
    read,
    origin,
    count,
    verb,
    context,
    object,
    new_assignee,
    mentioned_users,
  } = data;

  const targetTitle = getTitle(target);
  const objectTitle = getTitle(object);

  if (mentioned_users && mentioned_users.includes(get(currentUser, 'id'))) {
    const contextTitle = getTitle(context);
    return (
      <Notification
        id={id}
        actor={actor}
        published={published}
        read={read}
        targetLink={getTargetLink({
          target,
          context,
          object,
        })}
      >
        <FormattedHTMLMessage
          {...messages.mentionedNotificationText}
          values={{
            actor: actor && `${actor.name} ${actor.lastName}`,
            target: contextTitle,
            untitledTargetLabel: formatMessage(messages.untitledTargetLabel, {
              targetType: camelCase(context.type),
            }),
            removedTargetLabel: formatMessage(messages.removedTargetLabel, {
              targetType: camelCase(context.type),
            }),
            count,
          }}
        />
      </Notification>
    );
  }

  switch (verb) {
    case ACTIVITY_STREAM_TYPES.COMMENT: {
      return (
        <Notification
          id={id}
          actor={actor}
          published={published}
          read={read}
          targetLink={getTargetLink({
            target,
            context: origin,
            object,
            count,
          })}
        >
          <FormattedHTMLMessage
            {...messages.newCommentNotificationText}
            values={{
              actor: actor && `${actor.name} ${actor.lastName}`,
              target: targetTitle,
              untitledTargetLabel: formatMessage(messages.untitledTargetLabel, {
                targetType: camelCase(target.type),
              }),
              removedTargetLabel: formatMessage(messages.removedTargetLabel, {
                targetType: camelCase(target.type),
              }),
              count,
            }}
          />
        </Notification>
      );
    }
    case ACTIVITY_STREAM_TYPES.REPLY_COMMENT: {
      const snippet = getTitle(target);
      const values = {
        actor: actor && `${actor.name} ${actor.lastName}`,
        snippet,
        count,
      };

      return (
        <Notification
          id={id}
          actor={actor}
          published={published}
          read={read}
          targetLink={getTargetLink({
            target,
            context,
          })}
        >
          {count > 1 ? (
            <FormattedHTMLMessage
              {...messages.messageNewRepliesNotificationText}
              values={values}
            />
          ) : (
            <FormattedHTMLMessage
              {...messages.messageNewReplyNotificationText}
              values={values}
            />
          )}
        </Notification>
      );
    }
    case ACTIVITY_STREAM_TYPES.DOWNLOAD_IMAGE:
      return (
        <Notification
          id={id}
          actor={actor}
          published={published}
          read={read}
          targetLink={getTargetLink({
            target,
            context: origin,
          })}
        >
          <FormattedHTMLMessage
            {...messages.downloadImageNotificationText}
            values={{
              actor: actor && `${actor.name} ${actor.lastName}`,
              target: targetTitle,
              untitledTargetLabel: formatMessage(messages.untitledTargetLabel, {
                targetType: camelCase(target.type),
              }),
              removedTargetLabel: formatMessage(messages.removedTargetLabel, {
                targetType: camelCase(target.type),
              }),
              count,
            }}
          />
        </Notification>
      );
    case ACTIVITY_STREAM_TYPES.DOWNLOAD_PROJECT:
      return (
        <Notification
          id={id}
          actor={actor}
          published={published}
          read={read}
          targetLink={getTargetLink({
            target,
            context: origin,
          })}
        >
          <FormattedHTMLMessage
            {...messages.downloadProjectNotificationText}
            values={{
              actor: actor && `${actor.name} ${actor.lastName}`,
              target: targetTitle,
              untitledTargetLabel: formatMessage(messages.untitledTargetLabel, {
                targetType: camelCase(target.type),
              }),
              removedTargetLabel: formatMessage(messages.removedTargetLabel, {
                targetType: camelCase(target.type),
              }),
            }}
          />
        </Notification>
      );
    case ACTIVITY_STREAM_TYPES.REPLACE_FILE:
      return (
        <Notification
          id={id}
          actor={actor}
          published={published}
          read={read}
          targetLink={getTargetLink({
            target,
            context: origin,
          })}
        >
          <FormattedHTMLMessage
            {...messages.replaceFileNotificationText}
            values={{
              actor: actor && `${actor.name} ${actor.lastName}`,
              target: targetTitle,
              untitledTargetLabel: formatMessage(messages.untitledTargetLabel, {
                targetType: camelCase(target.type),
              }),
              removedTargetLabel: formatMessage(messages.removedTargetLabel, {
                targetType: camelCase(target.type),
              }),
              count,
            }}
          />
        </Notification>
      );
    case ACTIVITY_STREAM_TYPES.UPLOAD_FILE:
      return (
        <Notification
          id={id}
          actor={actor}
          published={published}
          read={read}
          targetLink={getTargetLink({
            target,
            context: origin,
          })}
        >
          <FormattedHTMLMessage
            {...messages.newUploadedFileNotificationText}
            values={{
              actor: actor && `${actor.name} ${actor.lastName}`,
              target: targetTitle,
              untitledTargetLabel: formatMessage(messages.untitledTargetLabel, {
                targetType: camelCase(target.type),
              }),
              removedTargetLabel: formatMessage(messages.removedTargetLabel, {
                targetType: camelCase(target.type),
              }),
              count,
            }}
          />
        </Notification>
      );
    case ACTIVITY_STREAM_TYPES.MENTION_USER:
      // We keep this to support old mention_user events
      return (
        <Notification
          id={id}
          actor={actor}
          published={published}
          read={read}
          targetLink={getTargetLink({
            target,
            context,
            object,
          })}
        >
          <FormattedHTMLMessage
            {...messages.mentionedNotificationText}
            values={{
              actor: actor && `${actor.name} ${actor.lastName}`,
              target: targetTitle,
              untitledTargetLabel: formatMessage(messages.untitledTargetLabel, {
                targetType: camelCase(target.type),
              }),
              removedTargetLabel: formatMessage(messages.removedTargetLabel, {
                targetType: camelCase(target.type),
              }),
              count,
            }}
          />
        </Notification>
      );
    case ACTIVITY_STREAM_TYPES.RESOLVE_COMMENT:
      return (
        <Notification
          id={id}
          actor={actor}
          published={published}
          read={read}
          targetLink={`${getTargetLink({
            target,
            context,
          })}/comments`}
        >
          <FormattedHTMLMessage
            {...messages.messageResolveComment}
            values={{
              actor: actor && `${actor.name} ${actor.lastName}`,
              target: targetTitle,
              untitledTargetLabel: formatMessage(messages.untitledTargetLabel, {
                targetType: camelCase(target.type),
              }),
              removedTargetLabel: formatMessage(messages.removedTargetLabel, {
                targetType: camelCase(target.type),
              }),
              count,
            }}
          />
        </Notification>
      );
    case ACTIVITY_STREAM_TYPES.UNRESOLVE_COMMENT:
      return (
        <Notification
          id={id}
          actor={actor}
          published={published}
          read={read}
          targetLink={`${getTargetLink({
            target,
            context,
          })}/comments`}
        >
          <FormattedHTMLMessage
            {...messages.messageUnresolveComment}
            values={{
              actor: actor && `${actor.name} ${actor.lastName}`,
              target: targetTitle,
              untitledTargetLabel: formatMessage(messages.untitledTargetLabel, {
                targetType: camelCase(target.type),
              }),
              removedTargetLabel: formatMessage(messages.removedTargetLabel, {
                targetType: camelCase(target.type),
              }),
              count,
            }}
          />
        </Notification>
      );
    case ACTIVITY_STREAM_TYPES.ASSIGN_COMMENT:
      return (
        <Notification
          id={id}
          actor={actor}
          published={published}
          read={read}
          targetLink={`${getTargetLink({
            target,
            context,
          })}/comments`}
        >
          <FormattedHTMLMessage
            {...messages.messageAssignComment}
            values={{
              actor: actor && `${actor.name} ${actor.lastName}`,
              target: targetTitle,
              untitledTargetLabel: formatMessage(messages.untitledTargetLabel, {
                targetType: camelCase(target.type),
              }),
              removedTargetLabel: formatMessage(messages.removedTargetLabel, {
                targetType: camelCase(target.type),
              }),
              count,
            }}
          />
        </Notification>
      );
    case ACTIVITY_STREAM_TYPES.UNASSIGN_COMMENT:
      return (
        <Notification
          id={id}
          actor={actor}
          published={published}
          read={read}
          targetLink={`${getTargetLink({
            target,
            context,
          })}/comments`}
        >
          <FormattedHTMLMessage
            {...messages.messageUnassignComment}
            values={{
              actor: actor && `${actor.name} ${actor.lastName}`,
              target: targetTitle,
              untitledTargetLabel: formatMessage(messages.untitledTargetLabel, {
                targetType: camelCase(target.type),
              }),
              removedTargetLabel: formatMessage(messages.removedTargetLabel, {
                targetType: camelCase(target.type),
              }),
              count,
            }}
          />
        </Notification>
      );
    case ACTIVITY_STREAM_TYPES.REASSIGN_COMMENT:
      return (
        <Notification
          id={id}
          actor={actor}
          published={published}
          read={read}
          targetLink={`${getTargetLink({
            target,
            context,
          })}/comments`}
        >
          <FormattedHTMLMessage
            {...(new_assignee
              ? messages.messageReassignCommentFromYou
              : messages.messageReassignCommentToYou)}
            values={{
              actor: actor && `${actor.name} ${actor.lastName}`,
              target: targetTitle,
              untitledTargetLabel: formatMessage(messages.untitledTargetLabel, {
                targetType: camelCase(target.type),
              }),
              removedTargetLabel: formatMessage(messages.removedTargetLabel, {
                targetType: camelCase(target.type),
              }),
              assignedToThemself: new_assignee && new_assignee.id === actor.id,
              newAssignee:
                new_assignee && `${new_assignee.name} ${new_assignee.lastName}`,
              count,
            }}
          />
        </Notification>
      );
    case ACTIVITY_STREAM_TYPES.APPROVE_IMAGE:
      return (
        <Notification
          id={id}
          actor={actor}
          published={published}
          read={read}
          targetLink={`${getTargetLink({
            target,
            context,
          })}?filter[approvedBy]=${actor && actor.id}`}
        >
          <FormattedHTMLMessage
            {...messages.approvedImageNotificationText}
            values={{
              actor: actor && `${actor.name} ${actor.lastName}`,
              target: targetTitle,
              untitledTargetLabel: formatMessage(messages.untitledTargetLabel, {
                targetType: camelCase(target.type),
              }),
              removedTargetLabel: formatMessage(messages.removedTargetLabel, {
                targetType: camelCase(target.type),
              }),
              count,
            }}
          />
        </Notification>
      );
    case ACTIVITY_STREAM_TYPES.REJECT_IMAGE:
      return (
        <Notification
          id={id}
          actor={actor}
          published={published}
          read={read}
          targetLink={`${getTargetLink({
            target,
            context,
          })}?filter[global]=rejected`}
        >
          <FormattedHTMLMessage
            {...messages.rejectedImageNotificationText}
            values={{
              actor: actor && `${actor.name} ${actor.lastName}`,
              target: targetTitle,
              untitledTargetLabel: formatMessage(messages.untitledTargetLabel, {
                targetType: camelCase(target.type),
              }),
              removedTargetLabel: formatMessage(messages.removedTargetLabel, {
                targetType: camelCase(target.type),
              }),
              count,
            }}
          />
        </Notification>
      );
    case ACTIVITY_STREAM_TYPES.ACCEPT_INVITATION:
      return (
        <Notification
          id={id}
          actor={actor}
          published={published}
          read={read}
          targetLink={getTargetLink({ target, context })}
        >
          <FormattedHTMLMessage
            {...messages.invitationAcceptedNotificationText}
            values={{
              actor:
                actor &&
                (trim(`${actor.name} ${actor.lastName}`) || actor.email),
              target: targetTitle,
              untitledTargetLabel: formatMessage(messages.untitledTargetLabel, {
                targetType: camelCase(target.type),
              }),
              removedTargetLabel: formatMessage(messages.removedTargetLabel, {
                targetType: camelCase(target.type),
              }),
              count,
            }}
          />
        </Notification>
      );
    case ACTIVITY_STREAM_TYPES.VIEW_LINK:
      return (
        <Notification
          id={id}
          actor={actor}
          published={published}
          read={read}
          targetLink={getTargetLink({ target, context })}
        >
          <FormattedHTMLMessage
            {...messages.viewLinkNotificationText}
            values={{
              target: targetTitle,
              untitledTargetLabel: formatMessage(messages.untitledTargetLabel, {
                targetType: camelCase(target.type),
              }),
              removedTargetLabel: formatMessage(messages.removedTargetLabel, {
                targetType: camelCase(target.type),
              }),
              type: object.attributes && object.attributes.type,
              count,
            }}
          />
        </Notification>
      );
    case ACTIVITY_STREAM_TYPES.FINISH_INBOX_UPLOAD:
      return (
        <Notification
          id={id}
          actor={actor}
          published={published}
          read={read}
          targetLink={`/p/${context.attributes['public-url-key']}/c/${object.id}`}
        >
          <FormattedHTMLMessage
            {...messages.finishInboxUploadNotificationText}
            values={{
              target: targetTitle,
              object: objectTitle,
              untitledTargetLabel: formatMessage(messages.untitledTargetLabel, {
                targetType: camelCase(target.type),
              }),
              removedTargetLabel: formatMessage(messages.removedTargetLabel, {
                targetType: camelCase(target.type),
              }),
              untitledObjectLabel: formatMessage(messages.untitledTargetLabel, {
                targetType: camelCase(object.type),
              }),
              removedObjectLabel: formatMessage(messages.removedTargetLabel, {
                targetType: camelCase(object.type),
              }),
              count,
            }}
          />
        </Notification>
      );
    default:
      return null;
  }
});

const dataShape = PropTypes.shape({
  actor: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    lastName: PropTypes.string,
  }),
  context: PropTypes.shape({
    type: PropTypes.string,
    attributes: PropTypes.shape({
      'public-url-key': PropTypes.string,
    }),
  }),
  count: PropTypes.number,
  id: PropTypes.string,
  object: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    attributes: PropTypes.shape({
      type: PropTypes.string,
    }),
  }),
  origin: PropTypes.shape({}),
  published: PropTypes.string,
  read: PropTypes.boolean,
  target: PropTypes.shape({
    type: PropTypes.string,
  }),
  new_assignee: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    lastName: PropTypes.string,
  }),
  mentioned_users: PropTypes.arrayOf(PropTypes.string),
  verb: PropTypes.string,
});

ActivityStreamItem.propTypes = {
  data: dataShape.isRequired,
};

export default ActivityStreamItem;
