import { defineMessages } from 'react-intl';

export default defineMessages({
  // main popover
  title: {
    id: 'DownloadManagerPopover.title',
    defaultMessage: 'Downloads',
  },

  // downloaditem
  labelDownload: {
    id: 'DownloadManagerPopover.labelDownload',
    defaultMessage: 'Download',
  },
  labelTryAgain: {
    id: 'DownloadManagerPopover.labelTryAgain',
    defaultMessage: 'Try again',
  },
  labelInProgress: {
    id: 'DownloadManagerPopover.labelInProgress',
    defaultMessage: 'Zipping {count} {count, plural, one {file} other {files}}',
  },
  labelCompleted: {
    id: 'DownloadManagerPopover.labelCompleted',
    defaultMessage:
      '{count} {count, plural, one {file} other {files}} ready for download',
  },
  labelFailed: {
    id: 'DownloadManagerPopover.labelFailed',
    defaultMessage:
      'Failed to zip {count} {count, plural, one {file} other {files}}',
  },

  // reset modal
  resetModalTitle: {
    id: 'DownloadManagerPopover.resetModalTitle',
    defaultMessage: 'Cancel download?',
  },
  resetModalMessage: {
    id: 'DownloadManagerPopover.resetModalMessage',
    defaultMessage:
      'Your download is not complete. Would you like to cancel the download?',
  },
  resetModalCancel: {
    id: 'DownloadManagerPopover.resetModalCancel',
    defaultMessage: 'Cancel download',
  },
  resetModalContinue: {
    id: 'DownloadManagerPopover.resetModalContinue',
    defaultMessage: 'Continue download',
  },
});
