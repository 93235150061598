import { withRouter } from 'react-router-dom';
import { withTracker } from '@picter/tracker';
import { compose, withProps } from 'recompose';
import withUploadStorageSubscribe from 'src/hocs/with-upload-storage';

import ImageGrid from './components/ImageGrid';

export default compose(
  withTracker(),
  withRouter,
  withUploadStorageSubscribe,
  withProps(({ uploadStorage, match }) => {
    const uploadGroups = uploadStorage.state.groups;
    return { uploadGroup: uploadGroups[match.params.projectId] };
  }),
)(ImageGrid);
