import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import paths from 'src/routes/paths';

import { selectAuthenticationStatus } from '../../selectors';

const RouteUnauthenticated = ({
  component,
  render,
  isAuthenticated,
  ...rest
}) => {
  if (isAuthenticated) {
    return <Redirect to={paths.HOME_PATH} {...rest} />;
  }

  return <Route {...rest} render={render} component={component} />;
};

RouteUnauthenticated.defaultProps = {
  component: undefined,
  render: undefined,
};

RouteUnauthenticated.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  render: PropTypes.func,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: selectAuthenticationStatus(state),
});

export default connect(mapStateToProps)(RouteUnauthenticated);
