import React, { Children, cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, P } from '@picter/prisma';
import { TrackerEvent } from '@picter/tracker';

import capitalize from 'src/utils/capitalize';
import ModalActions from 'src/styles/ModalActions';
import { Dropdown } from 'src/modules/prisma';

import ActionsDropdownGroup from './components/ActionsDropdownGroup';
import ActionsDropdownInfo from './components/ActionsDropdownInfo';
import ActionsDropdownItem from './components/ActionsDropdownItem';
import ActionsDropdownRename from './components/ActionsDropdownRename';
import ActionsDropdownDownload from './components/ActionsDropdownDownload';
import ActionsDropdownDelete from './components/ActionsDropdownDelete';
import ActionsDropdownDuplicate from './components/ActionsDropdownDuplicate';
import ActionsDropdownCopyToProject from './components/ActionsDropdownCopyToProject';
import ActionsDropdownMove from './components/ActionsDropdownMove';
import ActionsDropdownRestore from './components/ActionsDropdownRestore';
import ActionsDropdownMoveToTrash from './components/ActionsDropdownMoveToTrash';
import ActionsDropdownCustom from './components/ActionsDropdownCustom';
import ActionsDropdownProvider from './components/ActionsDropdownProvider';
import messages from './messages';

const ActionsDropdown = ({ menus, page, children, toggle, lazyRenderWhen }) => {
  const [deleteModalFor, setDeleteModalFor] = useState(null);
  const childrenArr = (Array.isArray(children)
    ? [...children]
    : [children]
  ).filter(item => item);
  const lastChild = childrenArr.pop();
  const multiGroup = Array.isArray(children);

  return (
    <>
      <ActionsDropdownProvider {...{ setDeleteModalFor, multiGroup }}>
        <TrackerEvent name={`${capitalize(page)} menu`} trackClick>
          <Dropdown icon={toggle}>
            <Dropdown.Menu>
              {Children.map(childrenArr, child => (
                <>
                  {cloneElement(child)}
                  <Dropdown.Separator />
                </>
              ))}
              {cloneElement(lastChild)}
            </Dropdown.Menu>
            {menus.map(menu => menu)}
          </Dropdown>
        </TrackerEvent>
      </ActionsDropdownProvider>
      {lazyRenderWhen && deleteModalFor && (
        <Modal
          onClickClose={() => setDeleteModalFor(null)}
          title={
            deleteModalFor ? (
              deleteModalFor.confirmTitle
            ) : (
              <FormattedMessage
                {...messages.labelSingleDelete}
                values={{ entity: capitalize(page) }}
              />
            )
          }
          open
        >
          <div>
            {deleteModalFor && deleteModalFor.confirmMessage && (
              <P>{deleteModalFor.confirmMessage}</P>
            )}
            <ModalActions>
              <Button
                colorScheme="grayscale"
                flat
                onClick={() => setDeleteModalFor(null)}
              >
                <FormattedMessage {...messages.labelModalCancel} />
              </Button>
              <Button
                colorScheme="destructive"
                flat
                onClick={(...args) => {
                  if (deleteModalFor) {
                    deleteModalFor.confirmFn(...args);
                    setDeleteModalFor(null);
                  }
                }}
              >
                {deleteModalFor ? (
                  deleteModalFor.confirmLabel
                ) : (
                  <FormattedMessage {...messages.labelModalConfirm} />
                )}
              </Button>
            </ModalActions>
          </div>
        </Modal>
      )}
    </>
  );
};

ActionsDropdown.propTypes = {
  page: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  menus: PropTypes.arrayOf(PropTypes.node),
  toggle: PropTypes.oneOfType([
    PropTypes.func, // render props e.g. {({ active, onClick }) => ()}
    PropTypes.element, // e.g.<Icon />
  ]),
  lazyRenderWhen: PropTypes.bool,
};

ActionsDropdown.defaultProps = {
  menus: [],
  toggle: undefined,
  lazyRenderWhen: true,
};

ActionsDropdown.Group = ActionsDropdownGroup;
ActionsDropdown.Info = ActionsDropdownInfo;
ActionsDropdown.Item = ActionsDropdownItem;
ActionsDropdown.Rename = ActionsDropdownRename;
ActionsDropdown.Download = ActionsDropdownDownload;
ActionsDropdown.Delete = ActionsDropdownDelete;
ActionsDropdown.Duplicate = ActionsDropdownDuplicate;
ActionsDropdown.CopyToProject = ActionsDropdownCopyToProject;
ActionsDropdown.Move = ActionsDropdownMove;
ActionsDropdown.Restore = ActionsDropdownRestore;
ActionsDropdown.MoveToTrash = ActionsDropdownMoveToTrash;
ActionsDropdown.Custom = ActionsDropdownCustom;

export default ActionsDropdown;
