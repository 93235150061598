import { useCallback, useMemo, useState } from 'react';
import { Map, List } from 'immutable';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import { useLocation, useParams } from 'react-router-dom';

import useFormatMessage from 'src/hooks/use-format-message';
import useUpdatableState from 'src/hooks/use-updatable-state';
import { get } from 'src/utils/accessors';
import globalMessages from 'src/messages';
import { ImageProofFilter } from 'src/utils/accessors/project-image';
import { useDownloadManager } from 'src/modules/download-manager';
import { replacePublicLinkKey } from 'src/utils/inject-public-link-key';

import { FILE_NAME_PATTERN_OPTIONS } from '../constants';
import messages from '../messages';

const mappedFilterSuffix = {
  [ImageProofFilter.UNDECIDED]: messages.labelUndecidedFilter,
  [ImageProofFilter.REJECTED]: messages.labelRejectedFilter,
  [ImageProofFilter.ANY]: messages.labelAnyFilter,
};

export default function useHandlers({ project, images }) {
  const formatMessage = useFormatMessage();
  const { query } = useLocation();
  const params = useParams();
  const { collectionId } = params;
  const [advancedVisibility, setAdvancedVisibility] = useState(false);
  const { createDownloadJob } = useDownloadManager();

  // packageName

  const initialPackageName = useMemo(() => {
    const projectTitle =
      get(project, 'attributes.title') ||
      formatMessage(globalMessages.labelUntitledProject);

    const approvedByFilter = get(query, 'filter.approvedBy');
    const globalFilter = get(query, 'filter.global');

    const suffixes = [
      (collectionId &&
        get(
          get(project, 'relationships.collections', List()).find(
            c => get(c, 'id') === collectionId,
            Map(),
          ),
          'attributes.name',
        )) ||
        '',
      globalFilter && formatMessage(mappedFilterSuffix[globalFilter]),
      approvedByFilter && formatMessage(messages.labelApprovedByFilter),
    ];

    return [projectTitle, ...suffixes].filter(i => !!i).join(' - ');
  }, [project, query, collectionId, formatMessage]);

  const [packageName, setPackageName] = useUpdatableState(initialPackageName);
  const handleChangePackageName = useCallback(
    e => setPackageName(e.target.value),
    [setPackageName],
  );

  // fileNamePattern

  const fileNamePatternOptions = useMemo(
    () =>
      Object.entries(FILE_NAME_PATTERN_OPTIONS).map(([option, value]) => ({
        label: formatMessage(
          messages[
            `labelFileNamePatternOption${upperFirst(camelCase(option))}`
          ],
        ),
        value,
      })),
    [formatMessage],
  );

  const [fileNamePattern, setFileNamePattern] = useState(
    FILE_NAME_PATTERN_OPTIONS.ORIGINAL_FILENAME,
  );

  const handleChangeFileNamePattern = useCallback(
    e => {
      if (e.target.value !== '') {
        setFileNamePattern(e.target.value);
      } else {
        setFileNamePattern(FILE_NAME_PATTERN_OPTIONS.ORIGINAL_FILENAME);
      }
    },
    [setFileNamePattern],
  );

  // download

  const handleDownloadProject = useCallback(() => {
    if (images.size === 0) return;

    const imagesIds = images
      .filter(image => !!image)
      .map(image =>
        get(image, 'type') === 'wsCollectionImages'
          ? get(image, 'relationships.image.id')
          : get(image, 'id'),
      );

    const publicKey = get(project, 'attributes.publicUrlKey');
    const downloadUrl = replacePublicLinkKey(
      `${process.env.REACT_APP_API_URL}/downloads/workspace?publicKey=${publicKey}`,
      { params },
    );

    createDownloadJob({
      downloadUrl,
      projectId: get(project, 'id'),
      imagesIds: imagesIds.toJS(),
      bundlePrefix: packageName,
      fileNamePattern,
    });
  }, [
    images,
    project,
    params,
    createDownloadJob,
    packageName,
    fileNamePattern,
  ]);

  return {
    advancedVisibility,
    setAdvancedVisibility,
    packageName,
    setPackageName,
    handleChangePackageName,
    fileNamePatternOptions,
    fileNamePattern,
    setFileNamePattern,
    handleChangeFileNamePattern,
    handleDownloadProject,
  };
}
