import { defineMessages } from 'react-intl';

export default defineMessages({
  labelUndo: {
    id: 'UndoToast.labelUndo',
    defaultMessage: 'Undo',
  },
  messageImageRemoved: {
    id: 'UndoToast.messageImageRemoved',
    defaultMessage: 'Deleted {imageTitle}.',
  },
});
