import useMedia from 'src/hooks/use-media';

const COLUMN_WIDTHS = [93, 280, 350];

const useGridMeasurements = theme => {
  const columnWidth = useMedia(
    [
      `(min-width: ${theme.breakpoints[0]})`,
      `(min-width: ${theme.breakpoints[4]})`,
    ],
    [COLUMN_WIDTHS[1], COLUMN_WIDTHS[2]],
    COLUMN_WIDTHS[0],
  );

  const controlHeight = useMedia(
    [`(min-width: ${theme.breakpoints[0]})`],
    [64],
    0,
  );

  const cardPaddingHorizontal = useMedia(
    [`(min-width: ${theme.breakpoints[0]})`],
    [theme.space[6] * 2],
    0,
  );

  const paddingLeft = useMedia(
    [`(min-width: ${theme.breakpoints[0]})`],
    [theme.space[7]],
    theme.space[4],
  );

  const paddingTop = useMedia(
    [`(min-width: ${theme.breakpoints[0]})`],
    [theme.space[6]],
    theme.space[4],
  );

  const paddingRight = useMedia(
    [`(min-width: ${theme.breakpoints[0]})`],
    [theme.space[7]],
    theme.space[4],
  );

  const paddingBottom = useMedia(
    [`(min-width: ${theme.breakpoints[0]})`],
    [theme.space[6]],
    theme.space[4],
  );

  return {
    columnWidth,
    controlHeight,
    cardPaddingHorizontal,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
  };
};

export default useGridMeasurements;
