import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import paths from 'src/routes/paths';
import { libraryProjectsUrl } from 'src/routes/urls';
import { viewModeTypes } from 'src/routes/constants';

import Document from './_document';
import SpacesNewRoutes from './new/_routes';
import SpaceRoutes from './{spaceId}/routes';

const { LIBRARY_SPACE_PATH, LIBRARY_SPACES_NEW_PATH } = paths;

const SpacesRoutes = () => {
  return (
    <Document>
      <Switch>
        <Route path={LIBRARY_SPACES_NEW_PATH} component={SpacesNewRoutes} />
        <Route path={LIBRARY_SPACE_PATH} component={SpaceRoutes} />
        <Redirect
          to={{
            pathname: libraryProjectsUrl({ viewMode: viewModeTypes.DEFAULT }),
          }}
        />
      </Switch>
    </Document>
  );
};

export default SpacesRoutes;
