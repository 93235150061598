import React from 'react';
import ReactDOM from 'react-dom';
import { configureSentry, ErrorBoundary } from '@picter/react-app-core';

import AppProvider from 'src/components/AppProvider';
import AppRouter from 'src/components/AppRouter';
import IdentifyUserTracker from 'src/containers/IdentifyUserTracker';
import IntercomChat from 'src/components/IntercomChat';
import PageViewTracker from 'src/components/PageViewTracker';
import MobileWarning from 'src/components/MobileWarning';
import UpdateReminderModal from 'src/components/UpdateReminderModal';
import ErrorHandler from 'src/components/ErrorHandler';
import ErrorPage from 'src/components/ErrorPage';
import Routes from 'src/pages/_routes';
import { DownloadManagerPopover } from './modules/download-manager';

import * as serviceWorker from './serviceWorker';

configureSentry(['review-production']);

/* eslint-disable global-require */
if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/en'); // Add locale data for en
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en'); // Add locale data for en
}
/* eslint-enable global-require */

const { REACT_APP_INTERCOM_APP_ID } = process.env;

const App = () => (
  <div id="picter-app">
    <AppRouter>
      <AppProvider>
        <ErrorBoundary errorElement={<ErrorPage />}>
          <ErrorHandler>
            <IdentifyUserTracker />
            <IntercomChat appId={REACT_APP_INTERCOM_APP_ID} />
            <PageViewTracker />
            <MobileWarning />
            <UpdateReminderModal />
            <DownloadManagerPopover />
            <Routes />
          </ErrorHandler>
        </ErrorBoundary>
      </AppProvider>
    </AppRouter>
  </div>
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change	ReactDOM.render(<App />, document.getElementById('root'));
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
