import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  RadioButtonChecked as RadioButtonCheckedSvg,
  RadioButtonUnchecked as RadioButtonUncheckedSvg,
} from '@styled-icons/material';

import { Checkbox, Text, Box } from 'src/modules/prisma';

const NotificationItemInput = ({
  id,
  name,
  label,
  disabled,
  message,
  value,
  handleChange,
  defaultValue,
}) => {
  const onChangeCheckbox = useCallback(
    e => {
      if (e.target.checked) {
        handleChange(name, defaultValue);
      }
    },
    [name, defaultValue, handleChange],
  );
  return (
    <Box
      display="flex"
      flexDirection={['column', null, 'row']}
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box mr={4}>
            <Checkbox
              id={id}
              checked={value === defaultValue}
              onChange={onChangeCheckbox}
              disabled={disabled}
              size="large"
              checkedSvg={RadioButtonCheckedSvg}
              defaultSvg={RadioButtonUncheckedSvg}
            />
          </Box>
          <Box display="flex" tag="label" htmlFor={id} flexDirection="column">
            <Text
              color={disabled && 'grey.600'}
              style={{ lineHeight: '1.4em', cursor: disabled && 'not-allowed' }}
            >
              {label}
            </Text>
            <Text color="grey.600" style={{ lineHeight: '1.4em' }}>
              {message}
            </Text>
          </Box>
        </Box>
      </Box>
      {/* <Box width={[1, 1 / 3]} mt={[4, 0]}>
      <SelectField ariaLabel="mentions" />
</Box> */}
    </Box>
  );
};

NotificationItemInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  message: PropTypes.node.isRequired,
  value: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
};

NotificationItemInput.defaultProps = {
  defaultValue: null,
  disabled: undefined,
};

export default NotificationItemInput;
