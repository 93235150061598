import React from 'react';
import PropTypes from 'prop-types';
import { DetailLayout, GeneralLayout, MediaQuery, H2 } from '@picter/prisma';
import picterLogoBlack from '@picter/prisma/lib/assets/picter-logo-black.svg';
import { withRouter } from 'react-router-dom';
import { Flex } from '@rebass/grid';
import { FormattedMessage } from 'react-intl';

import MenuIcon from 'src/components/MenuIcon';
import useDocumentTitle from 'src/hooks/use-document-title';
import withSuspenseLoader from 'src/hocs/with-suspense-loader';

import messages from './_messages';

const Layout = ({ children }) => {
  useDocumentTitle('Customization');

  return (
    <DetailLayout>
      <MediaQuery
        query={{ maxWidth: 0 }}
        render={() => (
          <DetailLayout.Top>
            <DetailLayout.Top.AlignLeft>
              <MenuIcon />
            </DetailLayout.Top.AlignLeft>
            <DetailLayout.Top.AlignCenter>
              <img src={picterLogoBlack} alt="Picter's logo" />
            </DetailLayout.Top.AlignCenter>
          </DetailLayout.Top>
        )}
      />
      <DetailLayout.Content>
        <GeneralLayout limited>
          <GeneralLayout.Top>
            <GeneralLayout.Top.AlignLeft>
              <Flex alignItems="center">
                <H2 noMargin>
                  <FormattedMessage {...messages.labelGeneralPage} />
                </H2>
              </Flex>
            </GeneralLayout.Top.AlignLeft>
          </GeneralLayout.Top>
          <GeneralLayout.Content>{children}</GeneralLayout.Content>
        </GeneralLayout>
      </DetailLayout.Content>
    </DetailLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withSuspenseLoader()(withRouter(Layout));
