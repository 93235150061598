import React from 'react';
import PropTypes from 'prop-types';
import { CustomPropTypes } from '@picter/prisma';

import Icon from '../styles/ItemIcon';
import Text from '../styles/ItemText';
import List from '../styles/BoxList';
import LI from '../styles/ItemLI';

const Item = ({ children, complete, item, iconType, bullet }) => (
  <LI>
    {iconType && <Icon complete={complete} type={iconType} size="small" />}
    {!iconType && bullet && <Text className="bullet">●</Text>}
    <Text>{item}</Text>
    {children}
  </LI>
);

Item.propTypes = {
  children: CustomPropTypes.elementOf(List),
  complete: PropTypes.bool,
  item: PropTypes.node.isRequired,
  iconType: PropTypes.string,
  bullet: PropTypes.bool,
};

Item.defaultProps = {
  children: null,
  complete: true,
  iconType: null,
  bullet: false,
};

export default Item;
