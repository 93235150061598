import styled from 'styled-components/macro';
import { mediaQuery } from '@picter/prisma';

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and ${mediaQuery.greaterThan('phablet')} {
    -webkit-overflow-scrolling: touch;
  }
`;

export default SidebarContent;
