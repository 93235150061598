import get from 'lodash/get';
import union from 'lodash/union';

const getIncludeResources = (resource, include, schemas) => {
  const parsedInclude = Array.isArray(include) ? include : [include];

  const rawResources = parsedInclude.reduce((acc, includeItem) => {
    const [rawResource, deepInclude] = includeItem.split(/\.(.+)/);
    const schemaEntity = get(schemas, `${resource}.${rawResource}.type`);
    if (schemaEntity) {
      acc.push([schemaEntity, deepInclude]);
    }
    return acc;
  }, []);

  return rawResources.reduce((acc, [internalResource, internalInclude]) => {
    if (internalInclude) {
      return [
        ...acc,
        internalResource,
        ...getIncludeResources(internalResource, [internalInclude], schemas),
      ];
    }
    return [...acc, internalResource];
  }, []);
};

// eslint-disable-next-line import/prefer-default-export
export const mapRelationshipsToEntitiesTypes = ({
  resourceType,
  id,
  relationships,
  schemas,
}) => {
  const memoizationArray = [];

  const splitResourceType = resourceType.split('.');
  const rootResourceType = splitResourceType[0];
  const relatedResource = splitResourceType[1];
  const parsedResourceType = relatedResource
    ? get(schemas, `${resourceType}.type`)
    : rootResourceType;

  memoizationArray.push(id ? [rootResourceType, id] : rootResourceType);
  if (relatedResource) {
    memoizationArray.push(parsedResourceType);
  }

  const includeResources = getIncludeResources(
    parsedResourceType,
    relationships,
    schemas,
  );
  memoizationArray.push(...includeResources);
  return union(memoizationArray);
};
