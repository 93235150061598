import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Check as CheckSvg } from '@styled-icons/material';

import { Action, Icon, Spacer, Tooltip } from 'src/modules/prisma';
import { $TSFixMe } from 'src/types';

import OptionsDropdown from './OptionsDropdown';

export const RESOLVED_FILTER_QUERY_KEY = 'resolved';
export type ResolvedFilterOptions = boolean | undefined;

function ToggleLabel({ filterValue }: { filterValue: ResolvedFilterOptions }) {
  switch (filterValue) {
    case true:
      return (
        <FormattedMessage
          id="ResolvedFilterDropdown.labelResolved"
          defaultMessage="Resolved"
        />
      );
    case false:
      return (
        <FormattedMessage
          id="ResolvedFilterDropdown.labelUnresolved"
          defaultMessage="Unresolved"
        />
      );
    default:
      return (
        <FormattedMessage
          id="ResolvedFilterDropdown.labelAllTasks"
          defaultMessage="All tasks"
        />
      );
  }
}

export default function ResolvedFilterDropdown({
  value,
  onChange,
}: {
  value: ResolvedFilterOptions;
  onChange: (value: ResolvedFilterOptions) => void;
}) {
  const tooltipRef = useRef<$TSFixMe>();

  return (
    <OptionsDropdown<ResolvedFilterOptions>
      onChange={onChange}
      options={[
        {
          label: (
            <FormattedMessage
              id="ResolvedFilterDropdown.labelResolved"
              defaultMessage="Resolved"
            />
          ),
          value: true,
        },
        {
          label: (
            <FormattedMessage
              id="ResolvedFilterDropdown.labelUnresolved"
              defaultMessage="Unresolved"
            />
          ),
          value: false,
        },
        {
          label: (
            <FormattedMessage
              id="ResolvedFilterDropdown.labelAllTasks"
              defaultMessage="All tasks"
            />
          ),
          value: undefined,
        },
      ]}
      renderToggle={({ active, onClick }) => (
        // @ts-ignore
        <Tooltip ref={tooltipRef}>
          {/* @ts-ignore */}
          <Tooltip.Reference>
            <Action
              // @ts-ignore
              active={active}
              onClick={(event: React.MouseEvent) => {
                tooltipRef?.current?.hide();
                onClick?.(event);
              }}
            >
              <Icon type={<CheckSvg />} color="inherit" size="small" />
              <Spacer sx={1} />
              <ToggleLabel filterValue={value} />
            </Action>
            {/* @ts-ignore */}
          </Tooltip.Reference>
          {/* @ts-ignore */}
          <Tooltip.Message>
            <FormattedMessage
              id="ResolvedFilterDropdown.messageFilterByStatus"
              defaultMessage="Filter by tasks status"
            />
            {/* @ts-ignore */}
          </Tooltip.Message>
        </Tooltip>
      )}
      value={value}
    />
  );
}
