import styled from 'styled-components/macro';
import { FieldLabel } from '@picter/prisma';

import TextFieldArea from './TextFieldArea';
import TextFieldInput from './TextFieldInput';

export const TextFieldLabel = styled(FieldLabel)`
  ${TextFieldInput /* sc-sel */}:focus ~ &,
    ${TextFieldArea /* sc-sel */}:focus ~ & {
    color: ${props => props.theme.colors.primary};
  }

  ${TextFieldInput /* sc-sel */}.error ~ &,
    ${TextFieldArea /* sc-sel */}.error ~ & {
    color: ${props => props.theme.colors.danger};
  }

  ${TextFieldInput /* sc-sel */}:disabled ~ &,
    ${TextFieldArea /* sc-sel */}:disabled ~ & {
    color: ${props => props.theme.colors.gray['600']};
  }
`;

export default TextFieldLabel;
