import styled from 'styled-components/macro';
import { space } from 'styled-system';

const ImageCardGrid = styled.div`
  ${space};
  flex: 1;
  height: 100%;
  overflow-y: hidden;
`;

export default ImageCardGrid;
