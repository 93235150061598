import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  GeneralLayout,
  Heading,
  ButtonElement as Button,
} from '@picter/prisma';
import { useParams, Link } from 'react-router-dom';

import { useSpace } from 'src/hooks/use-resource';
import { tGet } from 'src/utils/accessors';
import SlackSvg from 'src/assets/SlackSvg';
import { Box, Body } from 'src/modules/prisma';
import { librarySettingsSpaceNotificationsUrl } from 'src/routes/urls';

import Section from './styles/Section';
import Layout from './_layout';
import messages from './_messages';

const SLACK_INTEGRATION_INSTALL_URL =
  'https://slack.com/oauth/v2/authorize?client_id=18198112208.1517637742897&scope=chat:write,commands,im:history,im:read&user_scope=';

export default function IntegrationsPage() {
  const { spaceId } = useParams<{ spaceId: string }>();
  const space = useSpace({ id: spaceId });
  const connectedToSlack = tGet(space, 'attributes', 'slackTeamId');

  return (
    <Layout>
      <Layout.Top>
        <GeneralLayout.Top.AlignLeft>
          <Heading tag="h1" fontWeight="light" textSize="regular">
            <FormattedMessage {...messages.titleIntegrationsPage} />
          </Heading>
        </GeneralLayout.Top.AlignLeft>
      </Layout.Top>
      <Layout.Content>
        <Section>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Box mr="5">
                <SlackSvg />
              </Box>
              <Box display="flex" flexDirection="column">
                <Body tag="span" lineHeight={1} mb={1}>
                  <FormattedMessage {...messages.titleSlackIntegration} />
                </Body>
                <Body tag="span" color="grey.600" lineHeight={1}>
                  <FormattedMessage {...messages.descriptionSlackIntegration} />{' '}
                  <Link to={librarySettingsSpaceNotificationsUrl({ spaceId })}>
                    <FormattedMessage {...messages.descriptionChangeSettings} />
                  </Link>
                </Body>
              </Box>
            </Box>
            <Box display="flex" alignSelf="center">
              {!connectedToSlack ? (
                <Button
                  variant="flat"
                  color="primary"
                  onClick={() =>
                    window.open(SLACK_INTEGRATION_INSTALL_URL, '_blank')
                  }
                >
                  <FormattedMessage {...messages.labelConnectIntegration} />
                </Button>
              ) : (
                <Body color="success" px={4} py={1} fontWeight="400">
                  <FormattedMessage {...messages.labelConnectedIntegration} />
                </Body>
              )}
            </Box>
          </Box>
        </Section>
      </Layout.Content>
    </Layout>
  );
}
