import styled from 'styled-components/macro';
import { PopoverArrow as ReakitPopoverArrow } from 'reakit/Popover';
import themeGet from '@styled-system/theme-get';

const PopoverArrow = styled(ReakitPopoverArrow)`
  svg {
    display: none;
  }

  border-color: transparent;
  border-style: solid;
  border-width: 8px;
  ${props =>
    `border-${props.placement}-color: ${themeGet(`colors.${props.color}`)(
      props,
    )};`};
  font-size: 15px !important;
`;

PopoverArrow.displayName = 'PopoverArrow';

export default PopoverArrow;
