import React from 'react';

export default function AccountCircleSvg(props) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#EDEDED"
      />
      <path
        d="M11.8779 24.0263H11.969C15.8558 24.0118 19.307 22.1495 21.4898 19.2721C21.2265 19.0119 20.9108 18.7601 20.5428 18.5167C19.4576 17.7863 18.0385 17.2019 16.2854 16.7637C14.7411 16.388 13.322 16.2002 12.028 16.2002C10.7341 16.2002 9.31501 16.388 7.77065 16.7637C6.0176 17.2019 4.59848 17.7863 3.51326 18.5167C3.08904 18.7973 2.73436 19.089 2.44922 19.392C4.63629 22.2011 8.04532 24.012 11.8779 24.0263Z"
        fill="#B5B5B5"
      />
      <path
        d="M12.0266 13.4557C11.0875 13.4557 10.2214 13.2209 9.42834 12.7513C8.63529 12.2817 8.00399 11.6504 7.53443 10.8574C7.06486 10.0643 6.83008 9.19827 6.83008 8.25913C6.83008 7.31999 7.06486 6.44348 7.53443 5.62957C8.00399 4.81565 8.63529 4.17392 9.42834 3.70435C10.2214 3.23478 11.0875 3 12.0266 3C12.9657 3 13.8318 3.23478 14.6249 3.70435C15.4179 4.17392 16.0492 4.81565 16.5188 5.62957C16.9883 6.44348 17.2231 7.31999 17.2231 8.25913C17.2231 9.19827 16.9883 10.0643 16.5188 10.8574C16.0492 11.6504 15.4179 12.2817 14.6249 12.7513C13.8318 13.2209 12.9657 13.4557 12.0266 13.4557Z"
        fill="#B5B5B5"
      />
    </svg>
  );
}
