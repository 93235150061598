import React from 'react';
import PropTypes from 'prop-types';
import { DetailLayout, GeneralLayout, MediaQuery } from '@picter/prisma';
import picterLogoBlack from '@picter/prisma/lib/assets/picter-logo-black.svg';
import { withRouter } from 'react-router-dom';
import groupBy from 'lodash/groupBy';

import MenuIcon from 'src/components/MenuIcon';
import useDocumentTitle from 'src/hooks/use-document-title';

// eslint-disable-next-line react/prop-types
function LayoutTop({ children }) {
  return <GeneralLayout.Top>{children}</GeneralLayout.Top>;
}

// eslint-disable-next-line react/prop-types
function LayoutContent({ children }) {
  return <GeneralLayout.Content>{children}</GeneralLayout.Content>;
}

const Layout = ({ children }) => {
  useDocumentTitle('Members');
  const keyedChildren = groupBy(children, 'type');

  return (
    <DetailLayout>
      <MediaQuery
        query={{ maxWidth: 0 }}
        render={() => (
          <DetailLayout.Top>
            <DetailLayout.Top.AlignLeft>
              <MenuIcon />
            </DetailLayout.Top.AlignLeft>
            <DetailLayout.Top.AlignCenter>
              <img src={picterLogoBlack} alt="Picter's logo" />
            </DetailLayout.Top.AlignCenter>
          </DetailLayout.Top>
        )}
      />
      <DetailLayout.Content>
        <GeneralLayout limited>
          {keyedChildren[LayoutTop]}
          {keyedChildren[LayoutContent]}
        </GeneralLayout>
      </DetailLayout.Content>
    </DetailLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

Layout.Top = LayoutTop;
Layout.Content = LayoutContent;

export default withRouter(Layout);
