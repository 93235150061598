import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import { useSwipeable } from 'react-swipeable';

import Bottom from '../styles/SidebarBottom';
import Content from '../styles/SidebarContent';
import Overlay from '../styles/SidebarOverlay';
import Panel from '../styles/MainSidebarPanel';
import Top from '../styles/SidebarTop';
import { useLayout } from '../utils';

function MainSidebar({
  id,
  className,
  children,
  openOnMount,
  closeOnUnmount,
  ...props
}) {
  const [info, controls] = useLayout();
  const { contextSidebarOpened, mainSidebarOpened } = info;
  const { openMainSidebar, closeMainSidebar } = controls;

  const swipeHandlers = useSwipeable({
    onSwipedLeft: closeMainSidebar,
    preventDefaultTouchmoveEvent: true,
  });

  useEffect(() => {
    if (openOnMount) {
      openMainSidebar();
    }

    return () => {
      if (closeOnUnmount) {
        closeMainSidebar();
      }
    };
  }, [openMainSidebar, closeMainSidebar, openOnMount, closeOnUnmount]);

  return (
    <Panel id={id} className={className} {...props} {...swipeHandlers}>
      {(contextSidebarOpened || !mainSidebarOpened) && <Overlay />}
      {isFunction(children) ? children(controls) : children}
    </Panel>
  );
}

MainSidebar.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  closeOnUnmount: PropTypes.bool,
  openOnMount: PropTypes.bool,
};

MainSidebar.defaultProps = {
  id: null,
  className: null,
  closeOnUnmount: false,
  openOnMount: false,
};

MainSidebar.Bottom = Bottom;
MainSidebar.Content = Content;
MainSidebar.Top = Top;

export default MainSidebar;
