// @ts-nocheck
import React from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@styled-icons/material';

import { Icon, Tooltip } from 'src/modules/prisma';

import ToolColorChooser from './ToolColorChooser';
import { MarkupColor } from '../models/Markup';

export function ToolbarTool({
  icon,
  label,
  onClickTool,
  selected,
}: {
  icon: React.ReactElement;
  label: string;
  onClickTool: (color: MarkupColor) => void;
  selected: boolean;
}) {
  return selected ? (
    <span className="tool">
      <Icon
        backgroundColor="black"
        backgroundOpacity={0.7}
        boxSize="xxlarge"
        size="small"
        onClick={onClickTool}
        type={icon}
        color="white"
        opacity={1}
      />
    </span>
  ) : (
    <Tooltip>
      <Tooltip.Reference>
        <span className="tool">
          <Icon
            backgroundColor="black"
            backgroundOpacity={0.7}
            boxSize="xxlarge"
            size="small"
            onClick={onClickTool}
            type={icon}
            color="grey.400"
            opacity={0.7}
          />
        </span>
      </Tooltip.Reference>
      <Tooltip.Message>{label}</Tooltip.Message>
    </Tooltip>
  );
}

export function ToolbarToolWithColorChooser({
  color,
  icon,
  label,
  onClickColor,
  onClickTool,
  selected,
}: {
  color: MarkupColor;
  icon: React.ReactElement;
  label: string;
  onClickColor: (color: MarkupColor) => void;
  onClickTool: (color: MarkupColor) => void;
  selected: boolean;
}) {
  return selected ? (
    <ToolColorChooser color={color} onSelectColor={onClickColor}>
      {({ visible }, htmlProps) => (
        <span {...htmlProps} className="tool">
          <Icon
            backgroundColor="black"
            backgroundOpacity={0.7}
            boxSize="xxlarge"
            size="small"
            type={icon}
            color={color ?? 'white'}
            interactive
            opacity={1}
          />
          <Icon
            backgroundColor="transparent"
            boxSize="small"
            size="small"
            type={visible ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            color="white"
            opacity={0.7}
            style={{
              position: 'absolute',
              right: 1,
              bottom: 0,
              zIndex: 3,
            }}
          />
        </span>
      )}
    </ToolColorChooser>
  ) : (
    <Tooltip>
      <Tooltip.Reference>
        <span className="tool">
          <Icon
            backgroundColor="black"
            backgroundOpacity={0.7}
            boxSize="xxlarge"
            size="small"
            onClick={onClickTool}
            type={icon}
            color="grey.400"
            opacity={0.7}
          />
        </span>
      </Tooltip.Reference>
      <Tooltip.Message>{label}</Tooltip.Message>
    </Tooltip>
  );
}
