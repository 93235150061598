import React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { Box, Flex } from '@rebass/grid';
import { ButtonElement as Button } from '@picter/prisma';
import { TrackerEvent } from '@picter/tracker';

import { Body, Panel, Subheading, Text } from 'src/modules/prisma';

import messages from '../messages';
import { SubscriptionStatus } from '../../SubscriptionService';

export default function SubscriptionDetails({
  currentPlanName,
  disabled = false,
  onClickCancel,
  onClickResume,
  onClickRevoke,
  seats,
  subscription,
  subscriptionStatus,
}: {
  currentPlanName: string;
  disabled: boolean;
  onClickCancel: React.MouseEventHandler<HTMLButtonElement>;
  onClickResume: React.MouseEventHandler<HTMLButtonElement>;
  onClickRevoke: React.MouseEventHandler<HTMLButtonElement>;
  seats: number;
  subscription: {
    currencyCode: string;
    status: string;
    currentTermEnd: number;
    mrr: number;
    resumeDate: number;
  };
  subscriptionStatus: SubscriptionStatus;
}) {
  if (subscriptionStatus === SubscriptionStatus.Cancelled) return null;

  const { mrr, currencyCode, currentTermEnd, resumeDate } = subscription;

  return (
    <Panel
      backgroundColor="white"
      borderColor="grey.200"
      borderStyle="solid"
      borderWidth="1px"
      padding={5}
      tag="section"
    >
      <Subheading tag="h2">
        <FormattedMessage {...messages.titleSubscriptionDetails} />
      </Subheading>
      <Flex
        alignItems="flex-end"
        flexDirection={['column', null, 'row']}
        justifyContent="space-between"
        mt={5}
      >
        <Body color="grey.600">
          <FormattedMessage
            {...messages.subscriptionDetailsPlan}
            values={{ plan: currentPlanName, seats }}
          />
          {subscriptionStatus === SubscriptionStatus.NonRenewing &&
            currentTermEnd && (
              <>
                {' - '}
                <Text color="danger">
                  <FormattedMessage
                    {...messages.subscriptionDetailsTermEnd}
                    values={{
                      termEnd: (
                        <FormattedDate
                          value={currentTermEnd * 1000}
                          year="numeric"
                          month="long"
                          day="2-digit"
                        />
                      ),
                    }}
                  />
                </Text>
              </>
            )}
          {subscriptionStatus === SubscriptionStatus.Paused && resumeDate && (
            <>
              {' - '}
              <Text color="danger">
                <FormattedMessage
                  {...messages.subscriptionDetailsPaused}
                  values={{
                    resumeDate: (
                      <FormattedDate
                        value={resumeDate * 1000}
                        year="numeric"
                        month="long"
                        day="2-digit"
                      />
                    ),
                  }}
                />
              </Text>
            </>
          )}
          {mrr > 0 && (
            <>
              <br />
              <FormattedMessage
                {...messages.subscriptionDetailsCosts}
                values={{ mrr: (mrr / 100).toFixed(2), currencyCode }}
              />
            </>
          )}
        </Body>
        <Box mt="auto">
          {subscriptionStatus === SubscriptionStatus.Paused && (
            <TrackerEvent name="Resume subscription" trackClick>
              <Button
                color="primary"
                disabled={disabled}
                py={3}
                variant="flat"
                width="100%"
                onClick={onClickResume}
              >
                <FormattedMessage {...messages.labelResumeSubscription} />
              </Button>
            </TrackerEvent>
          )}
          {subscriptionStatus === SubscriptionStatus.NonRenewing && (
            <TrackerEvent name="Revoke cancellation" trackClick>
              <Button
                color="primary"
                disabled={disabled}
                py={3}
                variant="flat"
                width="100%"
                onClick={onClickRevoke}
              >
                <FormattedMessage {...messages.labelRevokeCancellation} />
              </Button>
            </TrackerEvent>
          )}
          {subscriptionStatus !== SubscriptionStatus.NonRenewing &&
            subscriptionStatus !== SubscriptionStatus.Paused && (
              <TrackerEvent name="Cancel subscription" trackClick>
                <Button
                  color="primary"
                  disabled={disabled}
                  py={3}
                  variant="flat"
                  width="100%"
                  onClick={onClickCancel}
                >
                  <FormattedMessage {...messages.labelCancelSubscription} />
                </Button>
              </TrackerEvent>
            )}
        </Box>
      </Flex>
    </Panel>
  );
}
