import React from 'react';
import { Switch, Route } from 'react-router-dom';

import paths from 'src/routes/paths';
import NotFoundPage from 'src/components/NotFoundPage';

import Document from './_document';
import FolderPage from '.';

const { LIBRARY_SPACE_FOLDER_PATH } = paths;

const FolderRoutes = () => {
  return (
    <Document>
      <Switch>
        <Route exact path={LIBRARY_SPACE_FOLDER_PATH} component={FolderPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </Document>
  );
};

export default FolderRoutes;
