export const dragSourceSpecs = {
  beginDrag(props) {
    // All props passed here will be available om item object on custom layer
    return {
      id: props.dragAndDropId,
      height: props.height,
      width: props.width,
      margin: props.margin,
      gridWidth: props.gridWidth,
      gridPaddingLeft: props.gridPaddingLeft,
      gridPaddingRight: props.gridPaddingRight,
      selectionSize: props.selection.size,
      title: props.title,
      src: props.src,
      fileMimeType: props.fileMimeType,
      uploading: props.uploading,
      type: props.type,
    };
  },
  isDragging(props, monitor) {
    return props.selectionModeActive
      ? !!props.selected
      : monitor.getItem().id === props.dragAndDropId;
  },
};

export const dropTargetSpecs = {
  drop(props, monitor) {
    const dragIds = props.selectionModeActive
      ? Array.from(props.selection)
      : [monitor.getItem().id];
    const dropTargetId = props.dragAndDropId;
    const projectPublicKey = props.projectId;
    props.updateImagesOrder(
      dragIds,
      dropTargetId,
      props.hoverSide,
      projectPublicKey,
    );
  },
};
