import React, { useCallback, useState, useMemo } from 'react';
import { Anchor, toastInfo, toast } from '@picter/prisma';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { MoveToInbox as MoveToInboxSvg } from '@styled-icons/material';
import copy from 'copy-to-clipboard';

import {
  historyShape,
  locationShape,
  matchShape,
} from 'src/utils/app-prop-types';
import { publicLibraryProjectUrl } from 'src/routes/urls';
import useFilteredUrls from 'src/hooks/use-filtered-urls';
import { useProject } from 'src/hooks/use-resource';
import useFormatMessage from 'src/hooks/use-format-message';
import { useToriiActions } from 'src/modules/torii';
import {
  publicLinkTypes,
  getProjectPublicLinkByType,
} from 'src/utils/accessors/project';
import { viewModeTypes } from 'src/routes/constants';
import { get } from 'src/utils/accessors';

import ShareableLinkModal from './ShareableLinkModal';
import messages from '../messages';

function ShareableReviewLinkModal({
  history,
  match: {
    params: { collectionId, projectId: projectPublicUrlKey },
  },
  location: {
    query: { filter },
  },
}) {
  const [loadingLink, setLoadingLink] = useState(false);
  const formatMessage = useFormatMessage();
  const { create, update } = useToriiActions();
  const { filteredProjectUrl } = useFilteredUrls(filter);
  const project = useProject({
    id: projectPublicUrlKey,
    include: ['publicLinks'],
  });
  const projectId = get(project, 'id');
  const projectPublicLink = getProjectPublicLinkByType(
    project,
    publicLinkTypes.REQUEST_FILES,
  );
  const projectPublicLinkUrl =
    projectPublicLink && get(projectPublicLink, 'attributes.enabled')
      ? publicLibraryProjectUrl({
          projectId: get(projectPublicLink, 'attributes.key'),
          viewMode: viewModeTypes.REQUEST_FILES,
        })
      : null;

  const handleClose = useCallback(
    () =>
      history.push(
        filteredProjectUrl({ collectionId, projectId: projectPublicUrlKey }),
      ),
    [collectionId, filteredProjectUrl, history, projectPublicUrlKey],
  );

  const handleUpdateSetting = useCallback(
    (key, value) => {
      if (projectPublicLink) {
        // state comes from project
        update('wsPublicLinks', {
          id: get(projectPublicLink, 'id'),
          attributes: {
            [key]: value,
          },
        });
        toastInfo(formatMessage(messages[`message${key}`], { enabled: value }));
      }
    },
    [formatMessage, update, projectPublicLink],
  );

  const handleDisableLink = useCallback(() => {
    update('wsPublicLinks', {
      id: get(projectPublicLink, 'id'),
      attributes: {
        enabled: false,
      },
      optimistic: true,
    });
  }, [update, projectPublicLink]);

  const copyLinkFromResponse = responseLink => {
    const link = publicLibraryProjectUrl({
      projectId: responseLink.attributes.key,
      viewMode: viewModeTypes.REQUEST_FILES,
    });
    // {message: null} Forces an error on the prompt fallback. This way we can
    // handle it with a try catch and avoid using the prompt fallback form the lib.
    try {
      copy(link, { message: null });
      toast.info(<FormattedMessage {...messages.messageShareUrlCopySuccess} />);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const handleEnableLink = useCallback(async () => {
    let responseLink = null;
    if (projectPublicLink) {
      update('wsPublicLinks', {
        id: get(projectPublicLink, 'id'),
        attributes: {
          enabled: true,
        },
        optimistic: true,
      });
      responseLink = projectPublicLink.toJS();
    } else {
      setLoadingLink(true);
      const result = await create('wsPublicLinks', {
        attributes: {
          type: publicLinkTypes.REQUEST_FILES,
        },
        relationships: {
          project: { id: projectId, type: 'ws-projects' },
        },
      });
      responseLink = result.data;
      setLoadingLink(false);
    }
    copyLinkFromResponse(responseLink);
  }, [create, update, projectId, projectPublicLink]);

  const description = useMemo(
    () => ({
      icon: <MoveToInboxSvg />,
      message: (
        <>
          <FormattedHTMLMessage
            {...messages.messageRequestFilesLinkDescription}
          />{' '}
          <Anchor
            href="http://learn.picter.com/en/articles/3298195"
            target="blank"
            textStyle="action.small"
          >
            <FormattedMessage {...messages.labelLearnMore} />
          </Anchor>
        </>
      ),
    }),
    [],
  );

  return (
    <ShareableLinkModal
      title={
        <FormattedHTMLMessage
          {...messages.titleShareableRequestFilesLinkModal}
        />
      }
      loadingLink={loadingLink && !projectPublicLinkUrl}
      description={description}
      link={projectPublicLinkUrl}
      onClickClose={handleClose}
      enableMessage={
        <FormattedMessage {...messages.labelGetShareableRequestFilesLink} />
      }
      onClickEnableLink={handleEnableLink}
      onClickDisableLink={handleDisableLink}
      onUpdateSetting={handleUpdateSetting}
      type="inbox"
    />
  );
}

ShareableReviewLinkModal.propTypes = {
  history: historyShape.isRequired,
  location: locationShape.isRequired,
  match: matchShape.isRequired,
};

export default ShareableReviewLinkModal;
