import Navigation from './components/Navigation';

import BackItem from './components/NavigationBackItem';
import Button from './components/NavigationButton';
import Header from './components/NavigationHeader';
import Item from './components/NavigationItem';
import List from './styles/NavigationList';

Navigation.BackItem = BackItem;
Navigation.BackItem.displayName = 'Navigation.BackItem';

Navigation.Button = Button;
Navigation.Button.displayName = 'Navigation.Button';

Navigation.Header = Header;
Navigation.Header.displayName = 'Navigation.Header';

Navigation.Item = Item;
Navigation.Item.displayName = 'Navigation.Item';

Navigation.List = List;
Navigation.List.displayName = 'Navigation.List';

export default Navigation;
