import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@rebass/grid';

import Panel from '../styles/Panel';

const RoleItem = ({ left, middle, right }) => (
  <Panel>
    <Box>{left}</Box>
    <Box flex="1" px={4}>
      {middle}
    </Box>
    <Box>{right}</Box>
  </Panel>
);

RoleItem.propTypes = {
  left: PropTypes.node.isRequired,
  middle: PropTypes.node.isRequired,
  right: PropTypes.node.isRequired,
};

export default RoleItem;
