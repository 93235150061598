import styled from 'styled-components/macro';

const SidebarOverlay = styled.div`
  align-items: center;
  background-color: transparent;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 500;
`;

export default SidebarOverlay;
