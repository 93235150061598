import styled from 'styled-components/macro';

const SpinnerWrapper = styled.div`
  ${props => props.active && `background-color: #8331f5;`};
  height: ${props => props.theme.space[5]}px;
  width: ${props => props.theme.space[5]}px;
  margin-left: ${props => props.theme.space[7]}px;
  img {
    width: ${props => props.theme.space[5]}px;
    height: ${props => props.theme.space[5]}px;
  }
`;

export default SpinnerWrapper;
