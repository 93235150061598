import React from 'react';
import PropTypes from 'prop-types';
import { TrackerScope } from '@picter/tracker';

import capitalize from 'src/utils/capitalize';

const ActionsDropdownGroup = ({ entity, children, disabled }) => {
  const filteredChildren = Array.isArray(children)
    ? children.filter(v => v)
    : [children];

  const childrenWithProps = React.Children.map(filteredChildren, child =>
    React.cloneElement(child, {
      entity,
      disabled: child.props.disabled || disabled,
    }),
  );

  return (
    <TrackerScope name={`${capitalize(entity)} menu`}>
      {childrenWithProps}
    </TrackerScope>
  );
};

ActionsDropdownGroup.propTypes = {
  entity: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

ActionsDropdownGroup.defaultProps = {
  entity: null,
  disabled: false,
};

export default ActionsDropdownGroup;
