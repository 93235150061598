import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Close, ExpandLess, ExpandMore } from '@styled-icons/material';
import { useSessionStorage } from '@react-hooks-library/core';
import { Box, Flex } from '@rebass/grid';
import { H4, Panel } from '@picter/prisma';

import { Icon, Separator } from 'src/modules/prisma';
import { useDownloadManager } from 'src/modules/download-manager';
import useModal from 'src/hooks/use-modal';

import BundleStatusIndicator from './BundleStatusIndicator';
import ResetDownloadsModal from './ResetDownloadsModal';
import messages from './messages';

export default function DownloadManagerPopover() {
  const { bundles } = useDownloadManager();
  const [isMinimized, setIsMinimized] = useSessionStorage(
    'download-manager-popover-minimized',
    false,
  );

  const [resetModalElement, resetModalState] = useModal(
    <ResetDownloadsModal />,
  );

  useEffect(
    () => {
      if (bundles.length > 0) {
        setIsMinimized(false);
      }
    },
    // we only want to check bundle size here and not when minimize changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bundles.length],
  );

  // show the popover only if there are visible bundles
  if (bundles.length === 0) {
    return null;
  }

  return (
    <>
      <Panel position="absolute" bottom={12} right={12}>
        <Box width={1}>
          {/* Header */}
          <Flex
            width={1}
            p={4}
            alignItems="center"
            justifyContent="space-between"
            bg="gray.800"
            color="white"
          >
            <Box as={H4} color="inherit" m={0}>
              <FormattedMessage {...messages.title} />
            </Box>
            <Flex flexWrap="nowrap">
              {isMinimized ? (
                <Icon
                  type={<ExpandLess />}
                  onClick={() => setIsMinimized(!isMinimized)}
                />
              ) : (
                <Icon
                  type={<ExpandMore />}
                  onClick={() => setIsMinimized(!isMinimized)}
                />
              )}
              <Box ml={4}>
                {/* @ts-ignore */}
                <Icon type={<Close />} onClick={resetModalState.open} />
              </Box>
            </Flex>
          </Flex>
          {/* Content */}
          <Box
            width={1}
            height={isMinimized ? 0 : 'auto'}
            style={{ overflow: 'hidden' }}
          >
            {bundles.map((bundle, index) => (
              <>
                {index > 0 && <Separator color="gray.200" size={0} />}
                <BundleStatusIndicator bundle={bundle} key={bundle.bundleId} />
              </>
            ))}
          </Box>
        </Box>
      </Panel>
      {resetModalElement}
    </>
  );
}
