import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Route, useParams } from 'react-router-dom';

import paths from 'src/routes/paths';
import { $TSFixMe } from 'src/types';
import { ProjectResource } from 'src/types/resources';
import { useProject } from 'src/hooks/use-resource';
import withSuspenseLoader from 'src/hocs/with-suspense-loader';
import { tGet } from 'src/utils/accessors';
import { librarySettingsSpaceSubscriptionUrl } from 'src/routes/urls';

import UpgradeModal from './UpgradeModal';

const { LIBRARY_PROJECT_SHARE_PATH } = paths as $TSFixMe;

type AnyProps = {
  open: boolean;
  [key: string]: unknown;
};

function GuestsLimitModal(props: AnyProps) {
  const { projectId } = useParams<{ projectId: string }>();
  const project: ProjectResource = useProject({
    id: projectId,
    include: ['space.plan.features'],
  });
  const space = tGet(project, 'relationships', 'space');
  const plan = tGet(space, 'relationships', 'plan');
  const features = tGet(plan, 'relationships', 'features');
  const guestsFeature = features.find(
    feature => tGet(feature, 'attributes', 'name') === 'guests',
  );
  const guestsFeatureConfig =
    guestsFeature && tGet(guestsFeature, 'attributes', 'config');
  const maxPerProject =
    guestsFeatureConfig &&
    (tGet(guestsFeatureConfig, 'maxPerProject') as number);
  const upgradeLink = librarySettingsSpaceSubscriptionUrl({
    spaceId: tGet(space, 'id'),
  });

  return (
    <UpgradeModal
      {...props}
      name="Team limit reached modal"
      title={
        <FormattedMessage
          id="GuestsLimitModal.titleGuestsLimit"
          defaultMessage="Maximum guests"
        />
      }
      message={
        <FormattedHTMLMessage
          id="GuestsLimitModal.messaGuestsLimitReached"
          defaultMessage={`Sorry! You've reached <b>{max} guests on your project</b>. Upgrade to be able to invite more guests.`}
          values={{ max: maxPerProject }}
        />
      }
      upgradeLink={upgradeLink}
    />
  );
}

function TeamLimitModalWrapper({ open, ...props }: AnyProps) {
  if (!open) {
    return null;
  }

  return (
    <Route
      path={LIBRARY_PROJECT_SHARE_PATH}
      render={() => <GuestsLimitModal {...props} open={open} />}
    />
  );
}

export default withSuspenseLoader({
  ErrorComponent: null,
  LoadingComponent: null,
})(TeamLimitModalWrapper);
