import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, P } from '@picter/prisma';
import { Flex, Box } from '@rebass/grid';
import Keyboardist from 'react-keyboardist';
import { FormattedMessage } from 'react-intl';

import ModalActions from 'src/styles/ModalActions';

import messages from './messages';

const ModalConfirmReplace = ({ onClose, open, onConfirm }) => (
  <Modal
    title={<FormattedMessage {...messages.titleReplaceImagesModal} />}
    onClickClose={onClose}
    open={open}
  >
    <Keyboardist
      bindings={{
        Escape: () => {
          onClose();
          return false;
        },
      }}
    />
    <div>
      <P>
        <FormattedMessage {...messages.messageReplaceImagesModal} />
      </P>
      <ModalActions>
        <Button colorScheme="grayscale" flat onClick={onClose}>
          <FormattedMessage {...messages.labelReplaceModalCancel} />
        </Button>
        <Flex>
          <Box mr={2}>
            <Button
              colorScheme="primary"
              flat
              onClick={() => {
                onClose();
                onConfirm(false);
              }}
            >
              <FormattedMessage {...messages.labelReplaceModalKeep} />
            </Button>
          </Box>
          <Button
            colorScheme="primary"
            flat
            onClick={() => {
              onClose();
              onConfirm(true);
            }}
          >
            <FormattedMessage {...messages.labelReplaceModalReplace} />
          </Button>
        </Flex>
      </ModalActions>
    </div>
  </Modal>
);

ModalConfirmReplace.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
};

ModalConfirmReplace.defaultProps = {
  onClose: null,
  open: false,
  onConfirm: null,
};

export default ModalConfirmReplace;
