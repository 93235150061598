import { Markup } from './Markup';

export const ANNOTATION_IN_PROGRESS_ID = -1;

export type AnnotationId = number | string;

export type AnnotationMeta = {
  index?: number;
  frame?: number;
};

export class Annotation {
  static clone(annotation: Annotation) {
    return new Annotation(annotation.id, annotation.markups, annotation.meta);
  }

  constructor(
    public id: AnnotationId,
    public markups: Markup[] = [],
    public meta?: AnnotationMeta,
  ) {}
}
