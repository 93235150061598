import { get } from 'src/utils/accessors';
import { useComments, useCurrentUser } from 'src/hooks/use-resource';
import withSuspenseLoader from 'src/hocs/with-suspense-loader';
import {
  hasRelationship,
  isCommentFilter,
  isCommentReply,
} from 'src/utils/accessors/comment';
import allPass from 'lodash/fp/allPass';
import negate from 'lodash/negate';

const CommentsLoader = withSuspenseLoader()(
  ({ children, imageId, projectId, filter }) => {
    const user = useCurrentUser();
    const userId = get(user, 'id');
    const comments = useComments.withPublicKey(
      {
        include: [
          'author',
          'collection',
          'image',
          'parent',
          'project',
          'assignee',
          'replies.author',
          'replies.parent',
          'resolvedBy',
        ],
        filter: allPass([
          hasRelationship({ imageId, projectId }),
          isCommentFilter({ filter, userId }),
          negate(isCommentReply),
        ]),
        sortBy: comment => get(comment, 'attributes.createdAt'),
      },
      {
        project: projectId,
        image: imageId,
        querySerializers: {
          project: id => `project=${id}`,
          ...(imageId
            ? {
                image: id => `image=${id}`,
              }
            : undefined),
        },
      },
      // refreshes when this arguments changes because of filter
      [filter, imageId, projectId, userId],
    );

    return children(comments);
  },
);

export default CommentsLoader;
