import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@picter/prisma';

import { Body } from 'src/modules/prisma';

import ToggleButton from '../styles/ToggleButton';

function getToggleButtonIconType(scope) {
  switch (scope) {
    case 'owner':
      return 'vpn_key';
    case 'manager':
      return 'record_voice_over';
    case 'editor':
    case 'member':
      return 'group';
    default:
      return 'remove_red_eye';
  }
}

function getToggleButtonLabelName(scope) {
  switch (scope) {
    case 'owner':
      return 'owner';
    case 'manager':
      return 'manager';
    case 'editor':
    case 'member':
      return 'member';
    default:
      return 'viewer';
  }
}

const RolesDropdownButton = ({
  active,
  onClick,
  currentScope,
  background,
  disabled,
}) => (
  <ToggleButton
    colorScheme="grayscale"
    onClick={onClick}
    background={background}
    active={active}
    disabled={disabled}
  >
    <Icon
      type={getToggleButtonIconType(currentScope)}
      size="medium"
      glyphSize="small"
    />
    <Body textTransform="capitalize" mx={2}>
      {getToggleButtonLabelName(currentScope)}
    </Body>
    {active ? (
      <Icon type="arrow_drop_up" size="medium" glyphSize="small" />
    ) : (
      <Icon type="arrow_drop_down" size="medium" glyphSize="small" />
    )}
  </ToggleButton>
);

RolesDropdownButton.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
  currentScope: PropTypes.string,
  background: PropTypes.bool,
  disabled: PropTypes.bool,
};

RolesDropdownButton.defaultProps = {
  active: false,
  onClick: null,
  currentScope: null,
  background: false,
  disabled: false,
};

export default RolesDropdownButton;
