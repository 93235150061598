import { cloneElement } from 'react';
import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';
import tag from 'clean-tag';

import buildDataUriSvg from 'src/utils/build-data-uri-svg';
import {
  Icon,
  color,
  // the props actually exist, but how they are exported
  // confuses the linter and causes errors
  /* eslint-disable import/named */
  display,
  /* eslint-enable import/named */
} from 'src/modules/prisma';
import { returnInCase } from 'src/utils/in-case';

import HalfCircleSvg from '../assets/HalfCircleSvg';
import FullCircleSvg from '../assets/FullCircleSvg';

function content(props) {
  const contentType = typeof props.content;

  return `
    &::before {
      content: url(${buildDataUriSvg(HalfCircleSvg, {
        color: themeGet(`colors.${props.borderColor}`)(props),
        height: themeGet(`sizes.icon.${props.boxSize || props.size}`)(props),
        width: themeGet(`sizes.icon.${props.boxSize || props.size}`)(props),
      })});
    }

    &::after {
      ${returnInCase({
        [contentType === 'object']: () => `
          content: url(${buildDataUriSvg(props.content, {
            color: themeGet(`colors.${props.contentColor || props.color}`)(
              props,
            ),
          })});
          bottom: -0.1em;
          right: -0.1em;
          `,
        [contentType === 'number' || contentType === 'string']: () => `
          content: '${props.content}';
          color: ${themeGet(`colors.${props.contentColor || props.color}`)};
          bottom: -0.2em;
          right: -0.4em;
        `,
      })}
    }
  `;
}

const ContentIcon = styled(tag.i).attrs(props => ({
  children: cloneElement(props.type, {
    height: '1em',
    width: '1em',
  }),
  role: props.onClick ? 'button' : null,
}))`
  align-items: center;
  border-radius: 50%;
  direction: ltr;
  font-size: ${props => themeGet(`sizes.icon.${props.size}`)(props)}px;
  font-style: normal;
  font-weight: normal;
  height: ${props =>
    themeGet(`sizes.icon.${props.boxSize || props.size}`)(props)}px;
  justify-content: center;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  vertical-align: middle;
  white-space: nowrap;
  width: ${props =>
    themeGet(`sizes.icon.${props.boxSize || props.size}`)(props)}px;
  word-wrap: normal;
  -webkit-font-smoothing: antialiased;
  position: relative;
  ${color};
  ${display};

  &::after {
    color: inherit;
    font-family: ${themeGet('fonts.sansSerif')};
    pointer-events: none;
    position: absolute;
    text-align: left;
    font-weight: ${themeGet('fontWeights.light')};
    line-height: ${themeGet('lineHeights.0')};
    ${props => {
      const contentSize = themeGet(`sizes.icon.${props.boxSize}`)(props) / 2;
      return `
        height: ${contentSize}px;
        font-size: ${contentSize}px;
        width: ${contentSize}px;
      `;
    }}
  }

  &::before {
    content: url(${props =>
      buildDataUriSvg(FullCircleSvg, {
        color: themeGet(`colors.${props.borderColor}`)(props),
        height: themeGet(`sizes.icon.${props.boxSize || props.size}`)(props),
        width: themeGet(`sizes.icon.${props.boxSize || props.size}`)(props),
      })});
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  }

  ${props => props.content && content(props)};
`;

ContentIcon.defaultProps = {
  backgroundColor: null,
  blacklist: [...Icon.defaultProps.blacklist, 'content'],
  borderColor: 'black',
  boxSize: null,
  color: null,
  display: 'inline-flex',
  size: 'medium',
};

export default ContentIcon;
