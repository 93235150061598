import styled from 'styled-components/macro';
import { transparentize } from 'polished';

const HighlightedText = styled.div`
  > em {
    font-style: normal;
    font-weight: 400;
    background-color: ${props =>
      transparentize(0.95, props.theme.colors.primary)};
  }
`;

export default HighlightedText;
