import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { ImportExport as ImportExportSvg } from '@styled-icons/material';

import { Action, Spacer, Icon, Tooltip } from 'src/modules/prisma';
import { $TSFixMe } from 'src/types';

import OptionsDropdown from './OptionsDropdown';

export const SORT_BY_QUERY_KEY = 'sort_by';
export type SortByOptions = 'created_at.desc' | 'updated_at.desc';

function ToggleLabel({ sortBy }: { sortBy: SortByOptions }) {
  if (sortBy === 'created_at.desc') {
    return (
      <FormattedMessage
        id="SortByDropdown.labelCreation"
        defaultMessage="Creation"
      />
    );
  }

  if (sortBy === 'updated_at.desc') {
    return (
      <FormattedMessage
        id="SortByDropdown.labelModification"
        defaultMessage="Modification"
      />
    );
  }

  return (
    <>
      <FormattedMessage
        id="SortByDropdown.labelToggle"
        defaultMessage="Toggle"
      />
    </>
  );
}

export default function SortByDropdown({
  value,
  onChange,
}: {
  value: SortByOptions;
  onChange: (value: SortByOptions) => void;
}) {
  const tooltipRef = useRef<$TSFixMe>();

  return (
    <OptionsDropdown<SortByOptions>
      onChange={onChange}
      options={[
        {
          label: 'Creation',
          value: 'created_at.desc',
        },
        {
          label: 'Modification',
          value: 'updated_at.desc',
        },
      ]}
      renderToggle={({ active, onClick }) => (
        // @ts-ignore
        <Tooltip ref={tooltipRef}>
          {/* @ts-ignore */}
          <Tooltip.Reference>
            <Action
              // @ts-ignore
              active={active}
              onClick={(event: React.MouseEvent) => {
                tooltipRef?.current?.hide();
                onClick?.(event);
              }}
            >
              <Icon type={<ImportExportSvg />} color="inherit" size="small" />
              <Spacer sx={1} />
              <ToggleLabel sortBy={value} />
            </Action>
            {/* @ts-ignore */}
          </Tooltip.Reference>
          {/* @ts-ignore */}
          <Tooltip.Message>
            <FormattedMessage
              id="SortByDropdown.messageSortBy"
              defaultMessage="Sort by"
            />
            {/* @ts-ignore */}
          </Tooltip.Message>
        </Tooltip>
      )}
      value={value}
    />
  );
}
