import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';

const UploadIndicatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: ${themeGet('colors.white')};
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  position: relative;
  height: 96px;
  width: 357px;
`;

export default UploadIndicatorContainer;
