import styled from 'styled-components/macro';
import { Box } from '@rebass/grid';
import colorSchemeVariants from './variants';

const BoxWrapper = styled(Box)`
  ${colorSchemeVariants};
  position: relative;
`;

BoxWrapper.defaultProps = {
  colorScheme: 'requirements',
  mb: 6,
  px: 5,
  py: 4,
};

export default BoxWrapper;
