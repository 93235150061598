const mentionRegex = /@\[[^\]]+\]\([^)]+\)/;
const userNameRegex = /\[(.*?)\]/;

// Strips away all mention syntax for plain text rendering.
// E.g. "Hi @[Joan](123)!" would be converted to "Hi @Joan!"
export function stripMentionTags(text: string) {
  const chunks = text
    .split(new RegExp(`(${mentionRegex.source})`, 'g'))
    .map(item => {
      if (mentionRegex.test(item)) {
        const matches = userNameRegex.exec(item);
        return `@${matches?.[1]}`;
      }

      return item;
    });

  return chunks.join('');
}
