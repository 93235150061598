import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { CustomPropTypes } from '@picter/prisma';
import { usePopoverState } from 'reakit/Popover';

import { PopoverProvider } from '../providers';

const Popover = forwardRef(({ children, ...props }, ref) => {
  const state = usePopoverState(props);

  useImperativeHandle(ref, () => state);

  return <PopoverProvider value={state}>{children}</PopoverProvider>;
});

Popover.propTypes = {
  children: CustomPropTypes.oneOrMoreOfType([PropTypes.element]).isRequired,
};

export default Popover;
