import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { AspectRatioBox, Card, P } from '@picter/prisma';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import { Map, List } from 'immutable';
import { Flex } from '@rebass/grid';
import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';

import { historyShape, intlShape, matchShape } from 'src/utils/app-prop-types';
import {
  getProjectImageFileInfo,
  getProjectFileThumbnailUrl,
} from 'src/utils/accessors/project-image';
import { Box } from 'src/modules/prisma';
import { projectUrl } from 'src/routes/urls';
import TruncateBox from 'src/styles/TruncateBox';
import ProjectShareState from 'src/components/ProjectShareState';
import useProjectPermission from 'src/hooks/use-project-permission';
import { useCurrentUser } from 'src/hooks/use-resource';
import { viewModeTypes } from 'src/routes/constants';
import useModal from 'src/hooks/use-modal';
import FolderChooser from 'src/components/FolderChooser';
import { get } from 'src/utils/accessors';
import globalMessages from 'src/messages';
import useFormatMessage from 'src/hooks/use-format-message';
import useProjectFollow from 'src/hooks/use-project-follow';
import ProjectCardCoverIllustrationSvg from 'src/assets/ProjectCardCoverIllustrationSvg';

import FollowingBell from './FollowingBell';
import ProjectCardCover from '../styles/ProjectCardCover';
import ProjectCardEditableTitle from './ProjectCardEditableTitle';
import ProjectCardActionsDropdown from './ProjectCardActionsDropdown';
import useHandlers from '../hooks/use-handlers';
import messages from '../messages';

const ProjectCardTitleContainer = styled(Box).attrs(props => ({
  mb: 4,
  pr: 2,
  flex: 1,
  width: `calc(100% - (${themeGet('space.5')(props)}px * 2))`,
}))``;

const ProjectCard = ({ project, history, index, intl, match, render }) => {
  const publicUrlKey = get(project, 'attributes.publicUrlKey');

  const formatMessage = useFormatMessage();
  const untitledProjectTitle = formatMessage(
    globalMessages.labelUntitledProject,
  );
  const projectTitle = get(project, 'attributes.title');

  const images = get(project, 'relationships.images');
  const coverFallback = images.find(
    image => !!get(image, 'attributes.files.medium.location'),
  );
  // Fallbacks needed because they are set on a database trigger and not returned
  // on creation
  const imagesSize = get(project, 'attributes.imageCount') || images.size;
  const imagesCount = (
    <FormattedMessage
      {...messages.messageNumberOfImages}
      values={{
        numberOfImages: imagesSize,
      }}
    />
  );

  let cover = get(project, 'relationships.coverImage');
  cover = !cover || cover.isEmpty() ? coverFallback : cover;
  const coverImage =
    cover && !cover.isEmpty() ? (
      <ProjectCardCover
        alt={`${projectTitle || untitledProjectTitle}'s cover`}
        src={
          getProjectImageFileInfo(cover, 'medium') ||
          getProjectFileThumbnailUrl(cover)
        }
      />
    ) : (
      <Box
        data-testid="project-card-cover-placeholder"
        backgroundColor="grey.300"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <ProjectCardCoverIllustrationSvg />
      </Box>
    );

  const isDemo = get(project, 'attributes.isDemo');
  const {
    canEditProject,
    canDeleteProject,
    canDuplicateProject,
    canMoveProject,
    canRestoreProject,
    canFollowProject,
    canTrashProject,
  } = useProjectPermission({
    projectId: publicUrlKey,
  });

  const user = useCurrentUser(
    {
      include: ['spaces'],
    },
    {
      request: false,
    },
  );
  const { toggleFollow, following } = useProjectFollow(project);

  const {
    editableTitleRef,
    handleClickInfo,
    handleClickRename,
    handleRenameProject,
    handleRemoveProject,
    handleCopyProject,
    handleClickSpaces,
    handleMoveProject,
    handleRestoreProject,
    handleTrashProject,
  } = useHandlers({
    publicUrlKey,
    history,
    match,
    project,
    intl,
  });

  const {
    params: { spaceId },
  } = match;

  const spaces =
    history.location.pathname === `/${viewModeTypes.DEFAULT}`
      ? get(user, 'relationships.spaces').filter(
          space => get(space, 'id') !== get(project, 'relationships.space.id'),
        )
      : List();

  const [folderChooserModal, folderChooserModalState] = useModal(
    <FolderChooser
      spaceId={spaceId}
      title={get(project, 'attributes.title')}
      onSubmit={handleMoveProject}
    />,
  );

  const projectLink = projectUrl(
    { projectId: publicUrlKey },
    { product_tour: isDemo ? 'sample-project' : undefined },
  );

  if (render) {
    return render({
      projectTitle,
      untitledProjectTitle,
      imagesCount,
      coverImage,
      projectLink,
      editableTitleRef,
      spaces,
      folderChooserModalState,
      folderChooserModal,
      following,
      toggleFollow,
      handleRenameProject,
      handleClickInfo,
      handleClickRename,
      handleRemoveProject,
      handleCopyProject,
      handleClickSpaces,
      handleRestoreProject,
      handleTrashProject,
      canEditProject,
      canFollowProject,
      canDeleteProject,
      canDuplicateProject,
      canMoveProject,
      canRestoreProject,
      canTrashProject,
    });
  }

  return (
    <Card>
      <Card.Content px={0}>
        <Link
          data-testid="project-card-link"
          data-intercom-target={`project-card-${index}`}
          to={projectLink}
        >
          <Box>
            <AspectRatioBox ratio="highdefinition">{coverImage}</AspectRatioBox>
          </Box>
        </Link>
        <Flex flexDirection="column" px={5} py={4}>
          <Flex flexDirection="row" justifyContent="space-between">
            <ProjectCardTitleContainer>
              <TruncateBox width="100%">
                {canEditProject ? (
                  <ProjectCardEditableTitle
                    ref={editableTitleRef}
                    value={projectTitle}
                    onChange={handleRenameProject}
                    placeholder={untitledProjectTitle}
                  />
                ) : (
                  <P noMargin>{projectTitle || untitledProjectTitle}</P>
                )}
              </TruncateBox>
              <P color="muted" textStyle="caption" noMargin>
                {imagesCount}
              </P>
            </ProjectCardTitleContainer>
            <Box mt={2} mr={1} ml={1}>
              <FollowingBell following={following} />
            </Box>
          </Flex>
          <Flex justifyContent="space-between" flex="1 0 28px">
            <ProjectShareState
              iconOutline="white"
              project={project}
              showInvitations
              showPublicLinks
              showUsers
            />
            <Box ml="auto">
              <ProjectCardActionsDropdown
                onClickInfo={handleClickInfo}
                onClickFollow={canFollowProject && toggleFollow}
                following={following}
                onClickRename={canEditProject ? handleClickRename : null}
                onClickRemove={canDeleteProject ? handleRemoveProject : null}
                onClickCopy={canDuplicateProject ? handleCopyProject : null}
                spaces={spaces}
                onClickSpace={handleClickSpaces}
                onClickMove={
                  spaceId && canMoveProject
                    ? folderChooserModalState.open
                    : null
                }
                onClickRestore={canRestoreProject ? handleRestoreProject : null}
                onClickMoveToTrash={canTrashProject ? handleTrashProject : null}
              />
              {spaceId ? folderChooserModal : null}
            </Box>
          </Flex>
        </Flex>
      </Card.Content>
    </Card>
  );
};

ProjectCard.propTypes = {
  project: PropTypes.instanceOf(Map),
  history: historyShape.isRequired,
  match: matchShape.isRequired,
  intl: intlShape.isRequired,
  index: PropTypes.number,
  render: PropTypes.func,
};

ProjectCard.defaultProps = {
  project: null,
  index: 0,
  render: undefined,
};

export default compose(withRouter, injectIntl)(ProjectCard);
