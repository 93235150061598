import { defineMessages } from 'react-intl';

const messages = defineMessages({
  labelDelete: {
    defaultMessage: 'Delete',
    id: 'ActionsDropdownDelete.labelDelete',
  },
  labelDownload: {
    defaultMessage: 'Download',
    id: 'ActionsDropdownDownload.labelDownload',
  },
  labelDuplicate: {
    defaultMessage: 'Duplicate',
    id: 'ActionsDropdownDuplicate.labelDuplicate',
  },
  messageCopyToProject: {
    id: 'ImageSelectionTopbar.messageCopyToProject',
    defaultMessage: 'Copy to project',
  },
  messageMove: {
    id: 'ImageSelectionTopbar.messageMove',
    defaultMessage: 'Move',
  },
  messageRestore: {
    id: 'ImageSelectionTopbar.messageRestore',
    defaultMessage: 'Restore',
  },
  messageMoveToTrash: {
    id: 'ImageSelectionTopbar.messageMoveToTrash',
    defaultMessage: 'Move to trash',
  },
  labelEdit: {
    defaultMessage: 'Edit',
    id: 'ActionsDropdownEdit.labelEdit',
  },
  labelInfo: {
    defaultMessage: 'Info',
    id: 'ActionsDropdownInfo.labelInfo',
  },
  labelRemove: {
    defaultMessage: 'Remove',
    id: 'ActionsDropdownRemove.labelRemove',
  },
  labelRename: {
    defaultMessage: 'Rename',
    id: 'ActionsDropdownRename.labelRename',
  },

  labelSingleDownload: {
    id: 'ActionsDropdown.labelSingleDownload',
    defaultMessage: 'Download {entity}',
  },
  labelSingleDelete: {
    id: 'ActionsDropdown.labelSingleDelete',
    defaultMessage: 'Delete {entity}',
  },
  labelSingleRename: {
    id: 'ActionsDropdown.labelSingleRename',
    defaultMessage: 'Rename {entity}',
  },
  titleModalDelete: {
    id: 'ActionsDropdown.titleModalDelete',
    defaultMessage: 'Delete {entity}',
  },
  labelModalCancel: {
    id: 'ActionsDropdown.labelModalCancel',
    defaultMessage: 'Cancel',
  },
  labelModalConfirm: {
    id: 'ActionsDropdown.labelModalConfirm',
    defaultMessage: 'Delete',
  },
});

export default messages;
