import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@rebass/grid';

import Container from '../styles/ShareableItemBoxContainer';

const ShareableItemBox = ({ left, middle, right }) => (
  <Container data-intercom-target="sharing-link-box">
    <Box>{left}</Box>
    <Box flex="1" px={4}>
      {middle}
    </Box>
    <Box>{right}</Box>
  </Container>
);

ShareableItemBox.propTypes = {
  left: PropTypes.node,
  middle: PropTypes.node,
  right: PropTypes.node,
};

ShareableItemBox.defaultProps = {
  left: undefined,
  middle: undefined,
  right: undefined,
};

export default ShareableItemBox;
