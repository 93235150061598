import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { CreateNewFolder as CreateNewFolderSvg } from '@styled-icons/material';

import { ContextualTextField, Icon, Box } from 'src/modules/prisma';
import { ChooserItem, chooserPropsShape } from 'src/components/EntityChooser';

import messages from '../messages';

export default function CreateItemComponent({ chooserProps, name, setName }) {
  return (
    <ChooserItem
      cover={
        <Box m={4}>
          <Icon
            type={<CreateNewFolderSvg />}
            size="medium"
            boxSize="xxlarge"
            color="grey.600"
            borderStyle="solid"
            borderWidth="1px"
            borderColor="grey.200"
          />
        </Box>
      }
      name={
        <FormattedMessage {...messages.titleNewFolder}>
          {message => (
            <ContextualTextField
              maxLength="100"
              minWidth="100%"
              onChange={e => setName(e.target.value)}
              placeholder={message}
              textStyle="body.regular"
              value={name}
            />
          )}
        </FormattedMessage>
      }
      subheader={<FormattedMessage {...messages.messageCreateNewFolder} />}
      {...chooserProps}
    />
  );
}

CreateItemComponent.propTypes = {
  name: PropTypes.string,
  setName: PropTypes.func.isRequired,
  chooserProps: chooserPropsShape.isRequired,
};

CreateItemComponent.defaultProps = {
  name: undefined,
};
