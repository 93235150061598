import styled from 'styled-components/macro';

const FormFieldset = styled.fieldset`
  border: none;
  margin: 0;
  min-width: 0;
  padding: 0;
`;

export default FormFieldset;
