import React from 'react';

import AnimatedSvg from '../styles/AnimatedSvg';

const SpinnerSvg = () => (
  <AnimatedSvg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXLink="http://www.w3.org/1999/xlink"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <path
      d="M6 11C6 11.5523 5.54892 12.0087 5.00419 11.9177C2.16441 11.4434 0 8.97446 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6L12 6.00343C11.9999 3.37816 9.52616 1.25 6.84848 1.25C4.17074 1.25 2 3.37464 2 6C2 7.86748 3.27976 9.43606 5.01012 9.87657C5.54533 10.0128 6 10.4477 6 11V11Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="4"
        y1="10.5"
        x2="11.5"
        y2="3"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#747D80" />
        <stop offset="1" stopColor="#747D80" stopOpacity="0.1" />
      </linearGradient>
    </defs>
  </AnimatedSvg>
);

export default SpinnerSvg;
