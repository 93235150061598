import React from 'react';
import { Route, Switch } from 'react-router-dom';

import paths from 'src/routes/paths';

import RequestFilesProjectRoutes from './{projectId}/_routes';

const { LIBRARY_PROJECT_PATH } = paths;

const RequestFilesRoutes = () => {
  return (
    <Switch>
      <Route
        path={LIBRARY_PROJECT_PATH}
        component={RequestFilesProjectRoutes}
      />
    </Switch>
  );
};

export default RequestFilesRoutes;
