import React, { forwardRef } from 'react';
import styled from 'styled-components/macro';

import { backgroundColor as backgroundColorMixin } from 'src/modules/prisma';

export const Progress = styled.progress<{
  backgroundColor: string;
  color: string;
}>`
  ${backgroundColorMixin};
  appearance: none;
  border: 0;
  height: 4px;
  border-radius: unset;
  outline: none;

  &::-moz-progress-bar {
    appearance: none;
    outline: none;
    ${props => backgroundColorMixin({ ...props, bgColor: props.color })};
  }

  &::-webkit-progress-bar {
    appearance: none;
    ${backgroundColorMixin};
    border-radius: unset;
  }

  &::-webkit-progress-value {
    appearance: none;
    ${props => backgroundColorMixin({ ...props, bgColor: props.color })};
  }
`;

export const Range = styled.input.attrs({
  type: 'range',
})`
  appearance: none;
  background-color: transparent;
  border: 0;
  box-shadow: unset;
  height: 4px;

  &::-moz-range-track {
    cursor: pointer;
    height: 4px;
  }

  &::-moz-range-progress {
    appearance: none;
  }

  &::-moz-range-thumb {
    ${props => backgroundColorMixin({ ...props, bgColor: props.color })};
    appearance: none;
    border: 0;
    border-radius: 100%;
    cursor: pointer;
    height: 8px;
    margin-top: -2px;
    width: 8px;
  }

  &::-webkit-slider-runnable-track {
    cursor: pointer;
    height: 4px;
  }

  &::-webkit-slider-thumb {
    ${props => backgroundColorMixin({ ...props, bgColor: props.color })};
    appearance: none;
    border: 0;
    border-radius: 100%;
    cursor: pointer;
    height: 8px;
    margin-top: -2px;
    width: 8px;
  }
`;

export const RangeProgressContainer = styled.div`
  position: relative;
  height: 4px;
  width: 100%;

  ${Progress}, ${Range} {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`;

export type RangeProgressProps = {
  backgroundColor: string;
  color: string;
  max: number;
  step: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onMouseUp?: (event: React.MouseEvent<HTMLInputElement>) => void;
  value: number;
};

const RangeProgress = forwardRef<HTMLInputElement, RangeProgressProps>(
  ({ backgroundColor, color, max, step, onChange, value, ...props }, ref) => {
    return (
      <RangeProgressContainer>
        <Progress
          backgroundColor={backgroundColor}
          color={color}
          max={max}
          value={value}
        />
        <Range
          {...props}
          color={color}
          max={max}
          onChange={onChange}
          step={step}
          value={value}
          ref={ref}
        />
      </RangeProgressContainer>
    );
  },
);

export default RangeProgress;
