import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Step1Page.title',
    defaultMessage: 'Let’s setup your profile',
  },
  placeholderFirstName: {
    id: 'Step1Page.placeholderFirstName',
    defaultMessage: 'First name',
  },
  placeholderLastName: {
    id: 'Step1Page.placeholderLastName',
    defaultMessage: 'Last name',
  },
  placeholderSegmentationJobTitle: {
    id: 'Step1Page.placeholderSegmentationJobTitle',
    defaultMessage: 'Which of these roles best describes you?',
  },
  placeholderWsNewsletter: {
    id: 'Step1Page.placeholderWsNewsletter',
    defaultMessage: 'Receive news and special offers by email',
  },
  labelNext: {
    id: 'Step1Page.labelSignup',
    defaultMessage: 'Next',
  },
  messageFirstNameRequired: {
    id: 'Step1Page.messageFirstNameRequired',
    defaultMessage: 'First name is required',
  },
  messageLastNameRequired: {
    id: 'Step1Page.messageLastNameRequired',
    defaultMessage: 'Last name is required',
  },
  messageSegmentationJobTitleRequired: {
    id: 'Step1Page.messageSegmentationJobTitleRequired',
    defaultMessage: 'You need to select an option',
  },
});
