import { defineMessages } from 'react-intl';

export default defineMessages({
  titleAcceptTerms: {
    id: 'AcceptTermsModal.titleAcceptTerms',
    defaultMessage: 'New terms of use',
  },
  messageAgreeTerm: {
    id: 'AcceptTermsModal.messageAgreeTerm',
    defaultMessage:
      'Before you can access Picter and its new exciting\nfeatures, we need your consent to our new {termsOfServiceLink}.\nPlease read them carefully as they have been updated.',
  },
  labelAcceptAndContinue: {
    id: 'AcceptTermsModal.labelAcceptAndContinue',
    defaultMessage: 'Accept and continue',
  },
  labelTermsOfService: {
    id: 'AcceptTermsModal.labelTermsOfService',
    defaultMessage: 'Terms of Use',
  },
  labelPrivacyPolicy: {
    id: 'AcceptTermsModal.labelPrivacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
});
