import React from 'react';

export default function SlackSvg() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00016 17.6368C6.00016 19.2462 4.69937 20.547 3.08992 20.547C1.48047 20.547 0.179688 19.2462 0.179688 17.6368C0.179688 16.0273 1.48047 14.7266 3.08992 14.7266H6.00016V17.6368ZM7.45528 17.6368C7.45528 16.0273 8.75607 14.7266 10.3655 14.7266C11.975 14.7266 13.2758 16.0273 13.2758 17.6368V24.9124C13.2758 26.5218 11.975 27.8226 10.3655 27.8226C8.75607 27.8226 7.45528 26.5218 7.45528 24.9124V17.6368Z"
        fill="#E01E5A"
      />
      <path
        d="M10.3641 5.95328C8.75468 5.95328 7.45389 4.6525 7.45389 3.04305C7.45389 1.4336 8.75468 0.132812 10.3641 0.132812C11.9736 0.132812 13.2744 1.4336 13.2744 3.04305V5.95328H10.3641ZM10.3641 7.43045C11.9736 7.43045 13.2744 8.73124 13.2744 10.3407C13.2744 11.9501 11.9736 13.2509 10.3641 13.2509H3.06649C1.45704 13.2509 0.15625 11.9501 0.15625 10.3407C0.15625 8.73124 1.45704 7.43045 3.06649 7.43045H10.3641Z"
        fill="#36C5F0"
      />
      <path
        d="M22.0256 10.3407C22.0256 8.73124 23.3264 7.43045 24.9358 7.43045C26.5453 7.43045 27.8461 8.73124 27.8461 10.3407C27.8461 11.9501 26.5453 13.2509 24.9358 13.2509H22.0256V10.3407ZM20.5705 10.3407C20.5705 11.9501 19.2697 13.2509 17.6602 13.2509C16.0508 13.2509 14.75 11.9501 14.75 10.3407V3.04305C14.75 1.4336 16.0508 0.132812 17.6602 0.132812C19.2697 0.132812 20.5705 1.4336 20.5705 3.04305V10.3407Z"
        fill="#2EB67D"
      />
      <path
        d="M17.6602 22.0022C19.2697 22.0022 20.5705 23.3029 20.5705 24.9124C20.5705 26.5218 19.2697 27.8226 17.6602 27.8226C16.0508 27.8226 14.75 26.5218 14.75 24.9124V22.0022H17.6602ZM17.6602 20.547C16.0508 20.547 14.75 19.2462 14.75 17.6368C14.75 16.0273 16.0508 14.7266 17.6602 14.7266H24.9579C26.5673 14.7266 27.8681 16.0273 27.8681 17.6368C27.8681 19.2462 26.5673 20.547 24.9579 20.547H17.6602Z"
        fill="#ECB22E"
      />
    </svg>
  );
}
