import styled from 'styled-components/macro';

const ContentContainer = styled.section`
  background: ${props => props.theme.colors.gray[100]};
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

export default ContentContainer;
