import camelcaseKeys from 'camelcase-keys';
import { fetcher, useSWR } from 'src/modules/swr';

import { useSubscriptionService } from '../components/SubscriptionServiceProvider';

export type Subscription = {
  currencyCode: string;
  currentTermEnd: number;
  mrr: number;
  resumeDate: number;
  status: string;
};

export default function useSubscription({ spaceId }: { spaceId: string }) {
  const { endpoint } = useSubscriptionService();
  const {
    data: subscription,
    isValidating: isLoading,
    mutate,
    ...rest
  } = useSWR(
    new URL(`subscriptions/${spaceId}`, endpoint).toString(),
    url =>
      fetcher(url, {
        credentials: 'include',
      }).then(data => camelcaseKeys(data, { deep: true })),
    {
      onErrorRetry: error => {
        // eslint-disable-next-line no-useless-return
        if (error.status === 404) return;
      },
      revalidateOnFocus: false,
    },
  );

  return {
    ...rest,
    merge: (payload: Subscription, revalidate?: boolean) =>
      mutate((data: Subscription) => Object.assign(data, payload), revalidate),
    isLoading,
    subscription,
  };
}
