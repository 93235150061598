import React, { useCallback, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  ButtonElement as Button,
  GeneralLayout,
  Heading,
  Modal,
  P,
  toast,
} from '@picter/prisma';
import { List } from 'immutable';
import { Flex } from '@rebass/grid';
import Keyboardist from 'react-keyboardist';
import { TrackerEvent } from '@picter/tracker';

import { matchShape } from 'src/utils/app-prop-types';
import { useSpace, useCurrentUser } from 'src/hooks/use-resource';
import { useToriiActions } from 'src/modules/torii';
import UserRoleBox from 'src/components/UserRoleBox';
import ModalActions from 'src/styles/ModalActions';
import useModal from 'src/hooks/use-modal';
import InviteMemberModal from 'src/components/InviteMemberModal';
import { get } from 'src/utils/accessors';
import { useErrorHandler } from 'src/components/ErrorHandler';
import useScript from 'src/hooks/use-script';

import Layout from './_layout';
import messages from './_messages';

const SpaceMembersPage = ({ match: { params } }) => {
  const { handleError } = useErrorHandler();
  const currentUser = useCurrentUser();
  const space = useSpace({
    id: params.spaceId,
    include: ['users.user', 'invitations'],
  });

  const toriiActions = useToriiActions();

  const spaceCurrentUser = get(space, 'relationships.users', List()).find(
    item => get(item, 'relationships.user.id') === get(currentUser, 'id'),
  );

  const isOwner =
    spaceCurrentUser && get(spaceCurrentUser, 'attributes.scope') === 'owner';

  const isManager =
    spaceCurrentUser && get(spaceCurrentUser, 'attributes.scope') === 'manager';

  const canAddMembers = isOwner || isManager;

  const spaceUsers = get(
    space,
    'relationships.users',
    List(),
  ).sortBy(spaceUser =>
    get(spaceUser, 'relationships.user.attributes.publicName'),
  );

  const invitations = get(space, 'relationships.invitations', List());

  const [removeMemberId, setRemoveMemberId] = useState(null);

  const handleUpdateScope = async (userId, newScope) => {
    try {
      await toriiActions.update('wsSpaceUsers', {
        id: `${params.spaceId}:${userId}`,
        attributes: {
          scope: newScope,
        },
      });

      // Needed so relationship can be updated

      toriiActions.localRemoveRelationship('wsSpaces', {
        id: params.spaceId,
        relation: 'users',
        data: [{ type: 'users', id: userId }],
      });

      toriiActions.localAttachRelationship('wsSpaces', {
        id: params.spaceId,
        relation: 'users',
        data: [{ type: 'users', id: userId }],
      });
    } catch (error) {
      toast.error(
        <FormattedMessage
          {...messages.messageErrorMessage}
          values={{ message: error._error }}
        />,
      );
    }
  };

  const handleRemove = async userId => {
    await toriiActions.destroy('wsSpaceUsers', {
      id: `${params.spaceId}:${userId}`,
    });

    toriiActions.localRemoveRelationship('wsSpaces', {
      id: params.spaceId,
      relation: 'users',
      data: [{ type: 'users', id: userId }],
    });
  };

  const onCloseRemoveModal = useCallback(() => {
    setRemoveMemberId(null);
  }, []);

  const createInvitation = useCallback(
    async ({ email, scope }) => {
      window.grecaptcha.enterprise.ready(async () => {
        // Execute reCaptcha to get the token
        const recaptchaToken = await window.grecaptcha.enterprise.execute(
          process.env.REACT_APP_RECAPTCHA_SITE_KEY,
          { action: 'send_ws_invitation' },
        );
        try {
          await toriiActions.create('invitations', {
            attributes: {
              email,
              scope,
              recaptchaToken,
            },
            relationships: {
              wsSpace: {
                id: params.spaceId,
                type: 'ws-spaces',
              },
            },
          });
          toast.info(
            <FormattedMessage
              {...messages.messageInvitationSent}
              values={{ email }}
            />,
          );
        } catch (error) {
          // handleError is a global error handler that checks common
          // plan limits and shows the appropriate modals. If the error
          // is not handled, it returns false and we show a toast with
          // the error message.
          const errorHandled = handleError(error);
          if (!errorHandled) {
            toast.error(
              <FormattedMessage
                {...messages.messageErrorMessage}
                values={{ message: error._error }}
              />,
            );
          }
        }
      });
    },
    [params, toriiActions, handleError],
  );

  const handleUpdateInvitationScope = useCallback(
    async (id, scope) => {
      await toriiActions.update('invitations', {
        id,
        attributes: { scope },
      });
    },
    [toriiActions],
  );

  const handleRemoveInvitation = useCallback(
    async id => {
      await toriiActions.destroy('invitations', {
        id,
      });
    },
    [toriiActions],
  );

  const allowedInvitationRoles = ['manager', 'member', 'viewer'];

  if (isOwner) {
    allowedInvitationRoles.unshift('owner');
  }

  const reCaptchaScriptStatus = useScript(
    `https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`,
    { removeOnUnmount: true },
  );

  const [addMemberModal, addMemberModalState] = useModal(
    <InviteMemberModal
      onSend={createInvitation}
      allowedRoles={allowedInvitationRoles}
      // Only enable the form when the reCaptcha script is ready
      disabled={reCaptchaScriptStatus !== 'ready'}
    />,
  );

  return (
    <Layout>
      <Layout.Top>
        <GeneralLayout.Top.AlignLeft>
          <Flex alignItems="center">
            <Heading>
              <FormattedMessage {...messages.labelMembersPage} />
            </Heading>
          </Flex>
        </GeneralLayout.Top.AlignLeft>
        <GeneralLayout.Top.AlignRight>
          {canAddMembers && (
            <TrackerEvent name="Add space member" trackClick>
              <Button
                variant="flat"
                textStyle="action.regular"
                color="primary"
                onClick={addMemberModalState.open}
                data-intercom-target="add-member-button"
              >
                <FormattedMessage {...messages.labelAddMember} />
              </Button>
            </TrackerEvent>
          )}
        </GeneralLayout.Top.AlignRight>
      </Layout.Top>
      <Layout.Content>
        {canAddMembers ? addMemberModal : null}
        <Modal
          title={<FormattedMessage {...messages.titleRemoveMemberModal} />}
          onClickClose={onCloseRemoveModal}
          open={!!removeMemberId}
        >
          <Keyboardist
            bindings={{
              Escape: () => {
                onCloseRemoveModal();
                return false;
              },
            }}
          />
          <div>
            <P>
              <FormattedMessage {...messages.messageRemoveMemberModal} />
            </P>
            <ModalActions>
              <Button
                variant="flat"
                textStyle="subheading.regular"
                color="muted"
                onClick={onCloseRemoveModal}
              >
                <FormattedMessage {...messages.labelRemoveMemberCancel} />
              </Button>
              <Flex>
                <Button
                  variant="flat"
                  textStyle="subheading.regular"
                  color="danger"
                  onClick={() => {
                    onCloseRemoveModal();
                    handleRemove(removeMemberId);
                  }}
                >
                  <FormattedMessage {...messages.labelRemoveMemberConfirm} />
                </Button>
              </Flex>
            </ModalActions>
          </div>
        </Modal>
        {spaceUsers.map(spaceUser => {
          const userId = get(spaceUser, 'relationships.user.id');
          const spaceUserScope = get(spaceUser, 'attributes.scope');
          const canManage =
            isOwner || (isManager && spaceUserScope !== 'owner');

          return (
            <UserRoleBox
              key={userId}
              disabled={!canManage}
              user={get(spaceUser, 'relationships.user')}
              scope={spaceUserScope}
              title={<FormattedMessage {...messages.titleRoleInThisSpace} />}
              labelRemove={
                <FormattedMessage {...messages.labelRemoveFromThisSpace} />
              }
              onClickOwner={
                isOwner ? () => handleUpdateScope(userId, 'owner') : null
              }
              onClickManager={() => handleUpdateScope(userId, 'manager')}
              messageManager={
                <FormattedMessage {...messages.messageManagerRole} />
              }
              onClickMember={() => handleUpdateScope(userId, 'member')}
              messageMember={
                <FormattedMessage {...messages.messageMemberRole} />
              }
              onClickViewer={() => handleUpdateScope(userId, 'viewer')}
              onClickRemove={() => setRemoveMemberId(userId)}
              messageViewer={
                <FormattedMessage {...messages.messageViewerRole} />
              }
            />
          );
        })}
        {invitations.map(invitation => {
          const id = get(invitation, 'id');

          return (
            <UserRoleBox
              key={id}
              invitation={invitation}
              disabled={!canAddMembers}
              scope={get(invitation, 'attributes.scope')}
              title={<FormattedMessage {...messages.titleRoleInThisSpace} />}
              labelRemove={
                <FormattedMessage {...messages.labelRemoveFromThisSpace} />
              }
              onClickOwner={
                isOwner
                  ? () => handleUpdateInvitationScope(id, 'owner')
                  : undefined
              }
              onClickManager={() => handleUpdateInvitationScope(id, 'manager')}
              messageManager={
                <FormattedMessage {...messages.messageManagerRole} />
              }
              messageMember={
                <FormattedMessage {...messages.messageMemberRole} />
              }
              onClickMember={() => handleUpdateInvitationScope(id, 'member')}
              onClickViewer={() => handleUpdateInvitationScope(id, 'viewer')}
              onClickRemove={() => handleRemoveInvitation(id)}
              messageViewer={
                <FormattedMessage {...messages.messageViewerRole} />
              }
            />
          );
        })}
      </Layout.Content>
    </Layout>
  );
};

SpaceMembersPage.propTypes = {
  match: matchShape.isRequired,
};

export default withRouter(SpaceMembersPage);
