import styled, { css } from 'styled-components/macro';
import { Button } from '@picter/prisma';

const activeBackground = css`
  background: ${props => props.theme.colors.gray[300]};
`;

const background = css`
  background: ${props => props.theme.colors.gray[100]};
  border: 1px solid ${props => props.theme.colors.gray[200]};
  border-radius: 2px;
  padding: ${props => props.theme.space[2]}px;
  ${props => props.active && activeBackground}
`;

const ToggleButton = styled(Button).attrs({
  flat: true,
})`
  margin: 0;
  padding: 0;

  i {
    margin: 0;
  }

  p {
    color: inherit;
  }

  ${props => props.background && background}
`;

export default ToggleButton;
