import React, { useMemo, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Icon, H2, A, P, toastInfo } from '@picter/prisma';
import { Flex, Box } from '@rebass/grid';
import { FormattedMessage } from 'react-intl';
import Keyboardist from 'react-keyboardist';
import { List } from 'immutable';
import { HighlightOff as HighlightOffSvg } from '@styled-icons/material';
import { useParams } from 'react-router-dom';

import { get } from 'src/utils/accessors';
import {
  ManageCollectionsIcon,
  ManageCollectionsTooltip,
} from 'src/components/ContentIcon';
import {
  addApprovalProjectImages,
  removeApprovalProjectImages,
} from 'src/actions/project-image';
import ProofField from 'src/styles/NewProofField';
import CollectionActionsDropdown from 'src/components/CollectionActionsDropdown';
import { useToriiActions } from 'src/modules/torii';
import ActionsDropdown from 'src/components/ActionsDropdown';
import usePermission from 'src/hooks/use-permission';
import injectPublicLinkKey from 'src/utils/inject-public-link-key';
import uploadStorage from 'src/utils/upload-storage';
import { viewModeTypes } from 'src/routes/constants';
import { loginUrl } from 'src/routes/urls';
import { useProject, useCurrentUser } from 'src/hooks/use-resource';
import useModal from 'src/hooks/use-modal';
import ProjectDownloadModal from 'src/components/ProjectDownloadModal';
import LiteUserRegisterModal from 'src/components/LiteUserRegisterModal';
import ProjectChooser from 'src/components/ProjectChooser';
import SelectionTopbarWrapper from './styles/SelectionTopbarWrapper';
import messages from './messages';

const ImageSelectionTopbar = memo(
  ({ projectId, selectAll, closeSelection, selectedItems }) => {
    const { bulkCreate, bulkDestroy, bulkUpdate } = useToriiActions();
    const params = useParams();
    const match = useMemo(() => ({ params }), [params]);
    const {
      canDeleteImages,
      canManageCollections,
      canProofImages,
      canDownloadImages,
      canCopyImagesToProjects,
    } = usePermission();

    const project = useProject(
      {
        id: projectId,
        include: ['folder', 'space', 'collections'],
      },
      { request: false },
    );

    const user = useCurrentUser({}, { request: false });
    const userId = get(user, 'id');

    const spaceId = get(project, 'relationships.space.id');

    const proofedImagesIds = useMemo(
      () =>
        selectedItems.reduce(
          (acc, next) => {
            const approves = get(next, 'relationships.approvals', List());
            const userApproval = approves.find(approve => {
              const approveUserId = get(approve, 'relationships.user.id');
              return approveUserId === userId;
            });

            if (get(next, 'attributes.rejected')) {
              acc.rejected.push(get(next, 'id'));
            } else if (userApproval) {
              acc.approved.push(get(next, 'id'));
            } else {
              acc.notDefined.push(get(next, 'id'));
            }

            return acc;
          },
          { approved: [], rejected: [], notDefined: [] },
        ),
      [selectedItems, userId],
    );

    const selectedImagesIds = useMemo(
      () => [].concat(...Object.values(proofedImagesIds)),
      [proofedImagesIds],
    );

    const selectedImagesCollectionsIdsSet = useMemo(() => {
      return selectedItems.reduce((set, item) => {
        const collectionImages = get(
          item,
          'relationships.collectionImages.data',
          List(), // newly uploaded images don't have this relationship populated yet, so we need a default value
        ); // List of collection images related to this image
        collectionImages.forEach(collectionImage => {
          const [collectionId] = get(collectionImage, 'id').split(':'); // Collection images id format <collectionId>:<projectImageId>
          set.add(collectionId);
        });
        return set;
      }, new Set());
    }, [selectedItems]);

    const toggleApprove = useCallback(
      (event, approve) => {
        if (approve) {
          return addApprovalProjectImages(
            match.params.projectId,
            get(user, 'id'),
            selectedImagesIds,
            {
              bulkCreate,
              match,
            },
          );
        }

        const approvalIds = selectedImagesIds.map(
          imageId => `${imageId}:${get(user, 'id')}`,
        );

        return removeApprovalProjectImages(
          match.params.projectId,
          approvalIds,
          {
            bulkDestroy,
            match,
          },
        );
      },
      [selectedImagesIds, match, bulkDestroy, bulkCreate, user],
    );

    const shouldBulkReject =
      proofedImagesIds.rejected.length !== selectedImagesIds.length;

    const toggleRejection = useCallback(async () => {
      const { querySerializers } = injectPublicLinkKey(
        {
          querySerializers: {
            publicKey: () => `publicKey=${match.params.projectId}`,
          },
        },
        match,
      );
      await bulkUpdate(
        'wsProjectImages',
        selectedImagesIds.map(imageId => ({
          id: imageId,
          attributes: {
            rejected: shouldBulkReject,
          },
        })),
        {
          querySerializers,
        },
      );
    }, [bulkUpdate, match, selectedImagesIds, shouldBulkReject]);

    const [
      liteUserRegisterModal,
      { open: openLiteUserRegisterModal },
    ] = useModal(
      <LiteUserRegisterModal
        onLoginRedirect={location => ({
          pathname: loginUrl(),
          search: `?redirectUrl=${[location.pathname, location.search].join(
            '',
          )}`,
        })}
      />,
    );

    const isUserLogged = user && !user.isEmpty();

    const handleClickDelete = useCallback(async () => {
      const { querySerializers, publicLink } = injectPublicLinkKey(
        {
          querySerializers: {
            publicKey: () => `publicKey=${params.projectId}`,
          },
        },
        { params },
      );

      const groupId =
        params.viewMode === viewModeTypes.DEFAULT
          ? params.projectId
          : publicLink;

      const ids = selectedItems.map(item => get(item, 'id'));

      await uploadStorage.dequeueFiles(groupId, ids);

      await bulkDestroy(
        'wsProjectImages',
        ids.map(id => ({ id })),
        {
          querySerializers,
        },
      );
      closeSelection();
    }, [params, selectedItems, bulkDestroy, closeSelection]);

    const [projectDownloadModal, projectDownloadModalState] = useModal(
      <ProjectDownloadModal project={project} images={selectedItems} />,
    );

    const handleCopyToProject = useCallback(
      async destination => {
        const result = await bulkCreate(
          'wsProjectImages',
          selectedItems.map(item => ({
            attributes: { copyFromAsset: get(item, 'id') },
            relationships: {
              project: { id: get(destination, 'id'), type: 'ws-projects' },
            },
          })),
        );
        toastInfo(
          <FormattedMessage
            {...messages.messageImagesCopied}
            values={{
              projectTitle: get(destination, 'attributes.title'),
            }}
          />,
        );
        return result;
      },
      [bulkCreate, selectedItems],
    );

    const [ProjectChooserModal, projectChooserModalState] = useModal(
      <ProjectChooser
        spaceId={spaceId}
        currentProjectPublicKey={projectId}
        title={get(project, 'attributes.title')}
        onSubmit={handleCopyToProject}
      />,
    );

    return (
      <>
        {ProjectChooserModal}
        {liteUserRegisterModal}
        <SelectionTopbarWrapper>
          <Keyboardist bindings={{ Escape: closeSelection }} />
          <Flex alignItems="center">
            <Box mr={4}>
              <Icon
                type="close"
                size="small"
                color="muted"
                onClick={closeSelection}
                interactive
              />
            </Box>
            <Box mr={5}>
              <H2 noMargin bold>
                {selectedItems.size}{' '}
                <FormattedMessage {...messages.labelSelected} />
              </H2>
            </Box>
            <Box>
              <A onClick={selectAll} bold>
                <FormattedMessage {...messages.labelSelectAll} />
              </A>
            </Box>
          </Flex>
          <Flex alignItems="center">
            {(canProofImages || canManageCollections) && (
              <Box mr={6}>
                <P noMargin>
                  <FormattedMessage {...messages.labelApply} />
                </P>
              </Box>
            )}
            {canProofImages && (
              <Box mr={4}>
                <ProofField
                  noMargin
                  name="collection-image-proof"
                  onChange={
                    isUserLogged ? toggleApprove : openLiteUserRegisterModal
                  }
                  values={proofedImagesIds}
                />
              </Box>
            )}
            {canManageCollections && (
              <Box mr={4}>
                <CollectionActionsDropdown
                  noMargin
                  icon={dropdownProps => (
                    <ManageCollectionsTooltip
                      portal={false}
                      visible={!dropdownProps.active}
                    >
                      <ManageCollectionsIcon
                        {...dropdownProps}
                        numberOfCollections={
                          selectedImagesCollectionsIdsSet.size
                        }
                      />
                    </ManageCollectionsTooltip>
                  )}
                  projectId={projectId}
                  imagesIds={selectedImagesIds}
                />
              </Box>
            )}
            {(canDownloadImages ||
              canDeleteImages ||
              canCopyImagesToProjects) && (
              <ActionsDropdown page="image selection">
                <ActionsDropdown.Group entity="images">
                  {canDownloadImages && (
                    <ActionsDropdown.Download
                      onClick={projectDownloadModalState.open}
                    />
                  )}
                  {canCopyImagesToProjects && (
                    <ActionsDropdown.CopyToProject
                      onClick={projectChooserModalState.open}
                    />
                  )}
                  {canProofImages && (
                    <ActionsDropdown.Item
                      action="reject"
                      label={
                        <FormattedMessage
                          {...(shouldBulkReject
                            ? messages.labelRejectImage
                            : messages.labelRemoveRejection)}
                        />
                      }
                      icon={<HighlightOffSvg />}
                      onClick={
                        isUserLogged
                          ? toggleRejection
                          : openLiteUserRegisterModal
                      }
                    />
                  )}
                  {canDeleteImages && (
                    <ActionsDropdown.Delete
                      onClick={handleClickDelete}
                      confirmMessage={
                        <FormattedMessage {...messages.messageModalDelete} />
                      }
                    />
                  )}
                </ActionsDropdown.Group>
              </ActionsDropdown>
            )}
          </Flex>
          {projectDownloadModal}
        </SelectionTopbarWrapper>
      </>
    );
  },
);

ImageSelectionTopbar.propTypes = {
  projectId: PropTypes.string.isRequired,
  projectPublicKey: PropTypes.string.isRequired,
  selectedItems: PropTypes.instanceOf(List).isRequired,
  selectAll: PropTypes.func.isRequired,
  deselectAll: PropTypes.func.isRequired,
  closeSelection: PropTypes.func.isRequired,
};

export default ImageSelectionTopbar;
