import React, { useRef } from 'react';
import {
  Pause as PauseSvg,
  PlayArrow as PlayArrowSvg,
} from '@styled-icons/material';
import { FormattedMessage } from 'react-intl';

import { Action, Icon, Tooltip } from 'src/modules/prisma';
import useTinyKeys from 'src/hooks/use-tiny-keys';

export type PlayPauseControlProps = {
  playing: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export default function PlayPauseControl({
  playing,
  onClick,
}: PlayPauseControlProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useTinyKeys(
    window,
    {
      Space: event => {
        if (event.target !== buttonRef.current) buttonRef.current?.click();
      },
    },
    [buttonRef.current],
  );

  return (
    // @ts-ignore
    <Tooltip showDelay={500}>
      {/* @ts-ignore */}
      <Tooltip.Reference>
        <span>
          {/* @ts-ignore */}
          <Action onClick={onClick} px={1} ref={buttonRef}>
            {playing ? (
              <Icon type={<PauseSvg />} />
            ) : (
              <Icon type={<PlayArrowSvg />} />
            )}
          </Action>
        </span>
        {/* @ts-ignore */}
      </Tooltip.Reference>
      {/* @ts-ignore */}
      <Tooltip.Message>
        {playing ? (
          <FormattedMessage
            id="PlayPauseControl.labelPause"
            defaultMessage="Pause (Space bar)"
          />
        ) : (
          <FormattedMessage
            id="PlayPauseControl.labelPlay"
            defaultMessage="Play (Space bar)"
          />
        )}
        {/* @ts-ignore */}
      </Tooltip.Message>
    </Tooltip>
  );
}
