import { defineMessages } from 'react-intl';

export default defineMessages({
  searchLabel: {
    id: 'SearchModal.searchLabel',
    defaultMessage: 'Search in workspace',
  },
  labelUntitledFile: {
    id: 'SearchModal.labelUntitledFile',
    defaultMessage: 'Untitled file',
  },
  labelUntitledProject: {
    id: 'SearchModal.labelUntitledProject',
    defaultMessage: 'Untitled project',
  },
  labelUntitledFolder: {
    id: 'SearchModal.labelUntitledFolder',
    defaultMessage: 'Untitled folder',
  },
  labelFilesCount: {
    id: 'SearchModal.labelFilesCount',
    defaultMessage: `{counter, plural,
      =0 {No files}
      one {# file}
      other {# files}
    }`,
  },
  labelFilterBy: {
    id: 'SearchModal.labelFilterBy',
    defaultMessage: 'FILTER BY',
  },
  labelFilterType: {
    id: 'SearchModal.labelFilterType',
    defaultMessage: 'Type',
  },
  labelProjectFacet: {
    id: 'SearchModal.labelProjectFacet',
    defaultMessage: 'Project',
  },
  labelFileFacet: {
    id: 'SearchModal.labelFileFacet',
    defaultMessage: 'File',
  },
  labelCommentFacet: {
    id: 'SearchModal.labelCommentFacet',
    defaultMessage: 'Comment',
  },
  labelNoResults: {
    id: 'SearchModal.labelNoResults',
    defaultMessage: 'We couldn\'t find anything matching "{qTerm}"',
  },
  labelNoResultsInFacet: {
    id: 'SearchModal.labelNoResultsInFacet',
    defaultMessage: `We couldn't find any {type, select,
        project {project}
        file {file}
        comment {comment}
      } matching "{qTerm}". But there seem to be results in other types. Maybe change filter?`,
  },
});
