import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'reakit/Popover';
import isFunction from 'lodash/isFunction';
import classNames from 'classnames';

import { Panel } from 'src/modules/prisma';

import PopoverArrow from '../styles/PopoverArrow';
import { usePopover } from '../providers';

function PopoverPanel({
  arrowColor,
  backgroundColor,
  children,
  className,
  ...props
}) {
  const tooltip = usePopover();

  return (
    <Popover
      as={Panel}
      className={classNames(className, 'popover__panel')}
      backgroundColor={backgroundColor}
      borderRadius="medium"
      {...tooltip}
      {...props}
    >
      <PopoverArrow color={arrowColor} {...tooltip} />
      {isFunction(children) ? children(tooltip) : children}
    </Popover>
  );
}

PopoverPanel.propTypes = {
  arrowColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

PopoverPanel.defaultProps = {
  arrowColor: 'grey.900',
  backgroundColor: 'grey.900',
  className: undefined,
};

export default PopoverPanel;
