import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { TrackerEvent } from '@picter/tracker';
import { Box } from '@rebass/grid';
import {
  ButtonElement as Button,
  FlexAlignLeft,
  FlexAlignRight,
  Spinner,
} from '@picter/prisma';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import qs from 'qs';

import { Heading } from 'src/modules/prisma';
import useDocumentTitle from 'src/hooks/use-document-title';
import SpaceTrialPanel from 'src/components/SpaceTrialPanel';
import withSuspenseLoader from 'src/hocs/with-suspense-loader';
import { getDateDeltaFromNowInSeconds } from 'src/utils/date';
import NoPermissionPage from 'src/components/NoPermissionPage';
import NotFoundPage from 'src/components/NotFoundPage';
import { useRealtimeJSONApi } from 'src/modules/realtime';
import { get } from 'src/utils/accessors';
import {
  hasSpaceScope,
  SPACE_VIEW_QUERY_KEY,
  SPACE_VIEW_SESSIONSTORAGE_KEY,
  SpaceViewTypes,
} from 'src/utils/accessors/space';
import OnboardingChecklist, {
  ShouldShowOnboardingChecklistOnSpace,
} from 'src/containers/OnboardingChecklist';
import SearchModal from 'src/components/SearchModal';
import EmptyStateBox from 'src/components/EmptyStateBox';
import EmtpyProjectIllustrationSvg from 'src/assets/EmtpyProjectIllustrationSvg';

import Layout from './_layout';
import MainNavigation from '../../_components/MainNavigation';
import messages from './_messages';
import SpaceContentGrid from './_components/SpaceContentGrid';
import SpaceContentList from './_components/SpaceContentList';
import TrialExpiredPage from './_components/TrialExpiredPage';
import SubscriptionExpiredPage from './_components/SubscriptionExpiredPage';
import SubscriptionPausedPage from './_components/SubscriptionPausedPage';
import useData from './_hooks/use-data';
import useHandlers from './_hooks/use-handlers';
import { SubscriptionStatus } from '../../settings/{spaceId}/subscription/_components/SubscriptionService';

const API_URL = process.env.REACT_APP_API_URL;

function SpacePage() {
  const { spaceId } = useParams();
  const { query, pathname } = useLocation();
  const { replace } = useHistory();
  const { subscribe: subscribeToRealtime } = useRealtimeJSONApi();
  const { createFolder, createProject, lastFolderCreatedId } = useHandlers();
  const [signedSearchKey, setSignedSearchKey] = useState(null);
  const { user, space, folders, projects } = useData();

  const subscriptionStatus = get(space, 'attributes.subscriptionStatus');
  const isSpaceViewer = hasSpaceScope(space, user, 'viewer');

  const currentView =
    query[SPACE_VIEW_QUERY_KEY] ||
    sessionStorage.getItem(SPACE_VIEW_SESSIONSTORAGE_KEY);

  const trialEndsAt = get(space, 'attributes.trialEndsAt');

  useEffect(() => {
    if (currentView && !query[SPACE_VIEW_QUERY_KEY]) {
      replace(
        `${pathname}?${qs.stringify({
          ...query,
          [SPACE_VIEW_QUERY_KEY]: currentView,
        })}`,
      );
    }
  }, [currentView, query, pathname, replace]);

  useEffect(() => {
    const subscription = subscribeToRealtime(
      `private-resource=ws-spaces;id=${spaceId}`,
    );

    return subscription;
  }, [subscribeToRealtime, spaceId]);

  useEffect(() => {
    fetch(`${API_URL}/app-workspace/signed-search-keys/${spaceId}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(res => res.text())
      .then(res => setSignedSearchKey(res));

    return () => {
      setSignedSearchKey(null);
    };
  }, [spaceId]);

  useDocumentTitle(get(space, 'attributes.name'));

  switch (subscriptionStatus) {
    case SubscriptionStatus.Cancelled:
      return <SubscriptionExpiredPage />;
    case SubscriptionStatus.Paused:
      return <SubscriptionPausedPage />;
    case SubscriptionStatus.InTrial:
      if (
        // seconds until trial expires, if negative it means already did
        getDateDeltaFromNowInSeconds(trialEndsAt) < 0
      )
        return <TrialExpiredPage />;
      break;
    default:
      break;
  }

  return (
    <Layout>
      <Layout.Navigation>
        <MainNavigation />
        <Box mx={5} mt="auto" mb={2}>
          <SpaceTrialPanel space={space} />
        </Box>
      </Layout.Navigation>
      <Layout.Top>
        <FlexAlignLeft>
          <Heading tag="h1" fontWeight="light" textSize="regular">
            {get(space, 'attributes.name')}
          </Heading>
        </FlexAlignLeft>
        <FlexAlignRight>
          {!isSpaceViewer && (
            <>
              <TrackerEvent name="New folder" trackClick>
                <Button
                  variant="flat"
                  textStyle="action.regular"
                  color="primary"
                  onClick={createFolder}
                  data-intercom-target="new-folder-button"
                >
                  <FormattedMessage {...messages.labelNewFolder} />
                </Button>
              </TrackerEvent>
              <TrackerEvent name="New project" trackClick>
                <Button
                  data-testid="new-project-button"
                  data-intercom-target="new-project-button"
                  onClick={createProject}
                  borderRadius="100px"
                  color="white"
                  textStyle="action.regular"
                  lineHeight="75%"
                >
                  <FormattedMessage {...messages.labelNewProject} />
                </Button>
              </TrackerEvent>
            </>
          )}
        </FlexAlignRight>
      </Layout.Top>
      <Layout.Content>
        <Box display="flex" flexDirection="column" height="100%">
          <Box flex="0 1">
            {signedSearchKey && (
              <SearchModal signedSearchKey={signedSearchKey} />
            )}
            <ShouldShowOnboardingChecklistOnSpace key={spaceId} space={space}>
              <Box my={4}>
                <OnboardingChecklist />
              </Box>
            </ShouldShowOnboardingChecklistOnSpace>
            {currentView === SpaceViewTypes.LIST ? (
              <SpaceContentList
                folders={folders}
                projects={projects}
                lastFolderCreatedId={lastFolderCreatedId}
              />
            ) : (
              <SpaceContentGrid
                folders={folders}
                projects={projects}
                lastFolderCreatedId={lastFolderCreatedId}
              />
            )}
          </Box>
          {projects.isEmpty() && (
            <Box my={4} flex="1 1" display="flex" justifyContent="center">
              <EmptyStateBox
                graphic={<EmtpyProjectIllustrationSvg />}
                title={
                  <FormattedMessage
                    {...messages.messageEmptyDashboardTitle}
                    values={{ name: get(user, 'attributes.firstName') }}
                  />
                }
                message={
                  <FormattedMessage {...messages.messageEmptyDashboardText} />
                }
                action={
                  !isSpaceViewer && (
                    <Button
                      onClick={createProject}
                      color="white"
                      data-testid="new-project-button-empty-dashboard"
                    >
                      New project
                    </Button>
                  )
                }
              />
            </Box>
          )}
        </Box>
      </Layout.Content>
    </Layout>
  );
}

const SpaceLoadingError = ({ error }) => {
  if (error.statusCode === 403) {
    return <NoPermissionPage />;
  }

  return <NotFoundPage />;
};

SpaceLoadingError.propTypes = {
  error: PropTypes.shape({
    statusCode: PropTypes.number.isRequired,
  }),
};

SpaceLoadingError.defaultProps = {
  error: undefined,
};

export default withSuspenseLoader({
  LoadingComponent: Spinner,
  ErrorComponent: SpaceLoadingError,
})(SpacePage);
