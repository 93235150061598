import React, { createElement, useCallback } from 'react';
import {
  ListOnItemsRenderedProps,
  VariableSizeListProps,
  ListChildComponentProps,
} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import DynamicSizeList from 'src/components/DynamicSizeList';

export type InfiniteLoaderProps = {
  isItemLoaded: (index: number) => boolean;
  loadMoreItems: (
    startIndex: number,
    stopIndex: number,
  ) => Promise<unknown> | null;
  itemCount: number;
  children: (props: {
    onItemsRendered: (props: ListOnItemsRenderedProps) => unknown;
    ref: React.Ref<unknown>;
  }) => React.ReactNode;
  threshold?: number;
  minimumBatchSize?: number;
};

export type InfiniteListChildComponentProps = ListChildComponentProps & {
  loaded: boolean;
};

export type InfiniteDynamicSizeListProps<T> = Pick<
  InfiniteLoaderProps,
  'loadMoreItems' | 'threshold' | 'minimumBatchSize'
> &
  Omit<VariableSizeListProps, 'children' | 'itemSize' | 'itemCount'> & {
    children: React.ComponentType<InfiniteListChildComponentProps>;
    defaultItemSize: number;
    hasMoreItems: boolean;
    items: T[];
  };

/**
 * Based on react-window-infinite-loader
 * @see https://github.com/bvaughn/react-window-infinite-loader#creating-an-infinite-loading-list
 */
export default function InfiniteDynamicSizeList<T>({
  children,
  defaultItemSize,
  hasMoreItems,
  items,
  loadMoreItems,
  height,
  width,
}: InfiniteDynamicSizeListProps<T>) {
  const itemCount = hasMoreItems ? items.length + 1 : items.length; // One more to show loading indicator
  const isItemLoaded = useCallback(
    (index: number) => !hasMoreItems || index < items.length,
    [hasMoreItems, items.length],
  );

  const getItemSize = useCallback(() => defaultItemSize, [defaultItemSize]);

  const Item = useCallback(
    ({ data, index, style }: ListChildComponentProps) =>
      createElement(children, {
        data,
        index,
        style,
        loaded: isItemLoaded(index),
      }),
    [children, isItemLoaded],
  );

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
      threshold={5}
      minimumBatchSize={5}
    >
      {({ onItemsRendered, ref }) => (
        // @ts-ignore
        <DynamicSizeList
          getDefaultSize={getItemSize}
          height={height}
          itemCount={itemCount}
          itemData={items}
          onItemsRendered={onItemsRendered}
          ref={ref}
          width={width}
        >
          {Item}
        </DynamicSizeList>
      )}
    </InfiniteLoader>
  );
}
