import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from '@rebass/grid';
import { useLocation, useParams, useHistory } from 'react-router-dom';

import { Checkbox, Icon as IconComponent } from 'src/modules/prisma';
import { returnInCase } from 'src/utils/in-case';
import ItemButton from 'src/components/Navigation/styles/NavigationItemButton';
import ItemText from 'src/components/Navigation/styles/NavigationItemText';
import {
  libraryProjectUrl,
  libraryProjectCollectionUrl,
} from 'src/routes/urls';
import {
  ImageProofFilter,
  IMAGE_FILTER_QUERY_KEY,
  IMAGE_APPROVAL_FILTER_QUERY_KEY,
} from 'src/utils/accessors/project-image';

import ActivableFlex from '../styles/ActivableFlex';

function useProjectImagesUrlBuilder() {
  const { collectionId, imageId, projectId } = useParams();
  const isOnCollectionPage = !!collectionId;

  return returnInCase({
    [isOnCollectionPage]: () => libraryProjectCollectionUrl,
    default: () => libraryProjectUrl,
  }).builder({ collectionId, imageId, projectId });
}

const getFilterValuesFromQueryObject = ({
  approvedBy,
  global: globalFilter,
} = {}) => {
  let approvedByFilter = approvedBy;
  if (!approvedBy) {
    approvedByFilter = [];
  }
  return {
    globalFilter,
    approvedByFilter: Array.isArray(approvedByFilter)
      ? approvedByFilter
      : [approvedByFilter],
  };
};

const FilterCheckbox = ({
  label,
  avatar,
  icon,
  value,
  counter,
  // these props can be used to pass data attributes down to the link element
  ...passThroughProps
}) => {
  const { query } = useLocation();
  const history = useHistory();
  const { filter } = query;
  const { approvedByFilter, globalFilter } = getFilterValuesFromQueryObject(
    filter,
  );
  const urlBuilder = useProjectImagesUrlBuilder();
  const checked = [globalFilter, ...approvedByFilter].includes(value);

  const pushUrl = useCallback(() => {
    let queryFilterObj;

    if (Object.values(ImageProofFilter).includes(value)) {
      queryFilterObj = !checked
        ? {
            [IMAGE_FILTER_QUERY_KEY]: value,
          }
        : {};
    }

    if (!queryFilterObj) {
      let newApprovedByFilter;
      if (checked) {
        newApprovedByFilter = approvedByFilter.filter(id => id !== value);
      } else {
        newApprovedByFilter = [];
        if (approvedByFilter) {
          newApprovedByFilter.push(...approvedByFilter);
        }
        newApprovedByFilter.push(value);
      }

      queryFilterObj = newApprovedByFilter.length
        ? {
            [IMAGE_APPROVAL_FILTER_QUERY_KEY]: newApprovedByFilter,
          }
        : {};
    }

    const to = urlBuilder(queryFilterObj);

    const [pathname, destinyQuery] = to.split('?');

    return history.push({
      pathname,
      search: destinyQuery ? `?${destinyQuery}` : undefined,
      state: history.location.state,
    });
  }, [history, urlBuilder, value, approvedByFilter, checked]);

  return (
    <li title={label}>
      <ItemButton onClick={pushUrl} {...passThroughProps}>
        <ItemText textStyle="body">
          <ActivableFlex
            justifyContent="space-between"
            data-testid={`project-filters-approvals-${value}`}
            checked={checked}
          >
            <Flex mr={3} style={{ overflow: 'hidden', alignItems: 'center' }}>
              <Box mr={4} style={{ display: 'flex' }}>
                <Checkbox name={value} checked={checked} />
              </Box>
              <Box mr={2} style={{ color: 'white' }}>
                {avatar || (
                  <IconComponent
                    boxSize="large"
                    color={checked ? 'primary' : 'grey.600'}
                    size="medium"
                    type={icon}
                  />
                )}
              </Box>
              <span
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  textAlign: 'start',
                }}
              >
                {label}
              </span>
            </Flex>
            <span>{counter}</span>
          </ActivableFlex>
        </ItemText>
      </ItemButton>
    </li>
  );
};

FilterCheckbox.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
  avatar: PropTypes.node,
  counter: PropTypes.number,
};

FilterCheckbox.defaultProps = {
  counter: null,
  icon: null,
  avatar: null,
};

export default FilterCheckbox;
