import { useMemo } from 'react';
import { makeFilteredProjectImageSelector } from 'src/selectors/project-image';
import { makeFilteredCollectionImageSelector } from 'src/selectors/collection-image';

export function filteredProjectImages() {
  const createSelectorStore = () => {
    const selectors = {};

    return function getSelector(filter) {
      let selector = selectors[filter];

      if (selector) {
        return selector;
      }

      selector = makeFilteredProjectImageSelector();
      selectors[filter] = selector;

      return selector;
    };
  };
  const getSelectorByFilter = createSelectorStore();

  return ({ filter, project }) => {
    const selector = getSelectorByFilter(filter);
    return selector({ project }, { filter });
  };
}

export function filteredCollectionImages() {
  const createSelectorStore = () => {
    const selectors = {};

    return function getSelector(filter) {
      let selector = selectors[filter];

      if (selector) {
        return selector;
      }

      selector = makeFilteredCollectionImageSelector();
      selectors[filter] = selector;

      return selector;
    };
  };
  const getSelectorByFilter = createSelectorStore();

  return ({ collection, filter }) => {
    const selector = getSelectorByFilter(filter);
    return selector({ collection }, { filter });
  };
}

export function useFilteredProjectImages(props) {
  const filteredImagesSelector = useMemo(
    () =>
      props.collection ? filteredCollectionImages() : filteredProjectImages(),
    [props.collection],
  );

  return filteredImagesSelector(props);
}

export default useFilteredProjectImages;
