import styled from 'styled-components/macro';
import ProofField from 'src/components/ProofField';

const NewProofField = styled(ProofField)`
  ${props => props.noMargin && 'margin: 0;'}

  @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    position: absolute;
    bottom: 4px;
    right: 4px;
    margin: 0;
    padding: 0;
    pointer-events: none;
    > div {
      > input[type='radio'] {
        ~ label {
          display: none;
          width: 16px;
          height: 16px;
          svg {
            width: 16px;
            height: 16px;
          }
        }
        &:checked ~ label {
          position: absolute;
          bottom: 0;
          right: 0;
          display: flex;
        }
        &[value='true']:checked ~ label {
          right: -16px;
        }
      }
    }
  }
`;

export default NewProofField;
