import { defineMessages } from 'react-intl';

export default defineMessages({
  labelGoToDashboard: {
    id: 'NotFoundPage.labelGoToDashboard',
    defaultMessage: 'Go to dashboard',
  },
  labelAboutPicter: {
    id: 'NotFoundPage.labelAboutPicter',
    defaultMessage: 'About picter',
  },
  messageCouldNotFind: {
    id: 'NotFoundPage.messageCouldNotFind',
    defaultMessage: 'We couldn’t find what you were looking for',
  },
  messageCheckForTypos: {
    id: 'NotFoundPage.messageCheckForTypos',
    defaultMessage: 'Check for typos or go to dashboard.',
  },
});
