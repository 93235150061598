import React from 'react';
import { useParams } from 'react-router-dom';

import InfoSection from 'src/components/ProjectPageInfoSection';
import { useProject } from 'src/hooks/use-resource';

const Info = () => {
  const { projectId } = useParams();

  const project = useProject(
    {
      id: projectId,
    },
    {
      request: preReqProject => !preReqProject || preReqProject.isEmpty(),
    },
  );

  return <InfoSection project={project} />;
};

export default Info;
