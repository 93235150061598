import React, { forwardRef, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Add as AddSvg, Create as CreateSvg } from '@styled-icons/material';
import { Collections as CollectionsSvg } from '@styled-icons/material-outlined';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { Tooltip } from 'src/modules/prisma';
import ContentIcon from '../styles/ContentIcon';
import ContentIconGroup from '../styles/ContentIconGroup';
import messages from '../messages';

const ManageCollectionsIcon = forwardRef(
  ({ numberOfCollections, ...props }, ref) => {
    return numberOfCollections > 0 ? (
      <EditCollectionsIcon content={numberOfCollections} {...props} ref={ref} />
    ) : (
      <AddToCollectionsIcon {...props} ref={ref} />
    );
  },
);

ManageCollectionsIcon.defaultProps = {
  boxSize: 'large',
  size: 'xsmall',
  numberOfCollections: 0,
};

ManageCollectionsIcon.propTypes = {
  boxSize: PropTypes.string,
  size: PropTypes.string,
  numberOfCollections: PropTypes.number,
};

export default ManageCollectionsIcon;

const AddToCollectionsIcon = forwardRef(
  ({ active, boxSize, onClick, size, ...props }, ref) => {
    return (
      <ContentIconGroup onClick={onClick} role="button" ref={ref} {...props}>
        <ContentIcon
          borderColor="primary"
          boxSize={boxSize}
          className={classNames('content-icon content-icon--active', {
            'content-icon--visible': active,
          })}
          color="primary"
          content={AddSvg}
          size={size}
          type={<CollectionsSvg />}
        />
        <ContentIcon
          borderColor="grey.400"
          boxSize={boxSize}
          className="content-icon content-icon--hover"
          color="black"
          content={AddSvg}
          size={size}
          type={<CollectionsSvg />}
        />
        <ContentIcon
          borderColor="grey.300"
          boxSize={boxSize}
          className="content-icon content-icon--default"
          color="grey.600"
          size={size}
          type={<CollectionsSvg />}
        />
      </ContentIconGroup>
    );
  },
);

AddToCollectionsIcon.defaultProps = {
  active: false,
  boxSize: 'large',
  onClick: undefined,
  size: 'small',
};

AddToCollectionsIcon.propTypes = {
  active: PropTypes.bool,
  boxSize: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
};

const EditCollectionsIcon = forwardRef(
  ({ active, boxSize, content, onClick, size, ...props }, ref) => {
    return (
      <ContentIconGroup onClick={onClick} role="button" ref={ref} {...props}>
        <ContentIcon
          backgroundColor="info"
          backgroundOpacity="0.05"
          borderColor="primary"
          boxSize={boxSize}
          className={classNames('content-icon content-icon--active', {
            'content-icon--visible': active,
          })}
          color="primary"
          content={CreateSvg}
          size={size}
          type={<CollectionsSvg />}
        />
        <ContentIcon
          backgroundColor="info"
          backgroundOpacity="0.05"
          borderColor="info"
          boxSize={boxSize}
          className="content-icon content-icon--hover"
          color="black"
          content={CreateSvg}
          size={size}
          type={<CollectionsSvg />}
        />
        <ContentIcon
          backgroundColor="info"
          backgroundOpacity="0.05"
          borderColor="info"
          boxSize={boxSize}
          className="content-icon content-icon--default"
          color="info"
          content={content}
          size={size}
          type={<CollectionsSvg />}
        />
      </ContentIconGroup>
    );
  },
);

EditCollectionsIcon.defaultProps = {
  active: false,
  boxSize: 'large',
  content: undefined,
  onClick: undefined,
  size: 'small',
};

EditCollectionsIcon.propTypes = {
  active: PropTypes.bool,
  boxSize: PropTypes.string,
  content: PropTypes.number,
  onClick: PropTypes.func,
  size: PropTypes.string,
};

export function ManageCollectionsTooltip({ children, portal, visible }) {
  const tooltipRef = useRef();

  useMemo(() => {
    if (!visible && tooltipRef.current) tooltipRef.current.hide();
  }, [visible]);

  return (
    <Tooltip placement="bottom" ref={tooltipRef}>
      <Tooltip.Reference>{children}</Tooltip.Reference>
      <Tooltip.Message unstable_portal={portal}>
        <FormattedMessage {...messages.labelCollections} />
      </Tooltip.Message>
    </Tooltip>
  );
}

ManageCollectionsTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  portal: PropTypes.bool,
  visible: PropTypes.bool,
};

ManageCollectionsTooltip.defaultProps = {
  portal: true,
  visible: true,
};
