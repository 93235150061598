import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components/macro';
import {
  CheckCircle as CheckCircleSvg,
  RadioButtonUnchecked as RadioButtonUncheckedSvg,
} from '@styled-icons/material';

import { Checkbox } from 'src/modules/prisma';

export const OpacityHiddenCheckbox = styled(Checkbox)`
  opacity: ${({ visible }) => (visible ? 1 : 0)};

  &:checked {
    opacity: 1;
  }
`;

function SelectionCheckbox({
  bulk,
  checked,
  hover,
  visible,
  selecting,
  ...props
}) {
  const theme = useTheme();
  const mediumIconSize = theme.sizes.icon.medium;

  let checkedColor = 'primary';
  let checkedSvg = CheckCircleSvg;
  let defaultColor = 'grey.400';
  let defaultSvg = CheckCircleSvg;

  if (bulk) {
    // bulk selection active
    defaultSvg = RadioButtonUncheckedSvg;

    if (selecting) {
      // bulk selection active and inside a shift select
      defaultSvg = CheckCircleSvg;
    }

    if (hover) {
      // bulk selection active and being hovered
      defaultColor = 'grey.600';
      defaultSvg = CheckCircleSvg;
    }
  }

  if (checked && selecting) {
    // deselecting state
    checkedColor = 'grey.400';
    checkedSvg = RadioButtonUncheckedSvg;
  }

  return (
    <OpacityHiddenCheckbox
      checked={checked}
      checkedColor={checkedColor}
      checkedSvg={checkedSvg}
      defaultColor={defaultColor}
      defaultSvg={defaultSvg}
      hoverColor="grey.600"
      height={`${mediumIconSize}px`}
      width={`${mediumIconSize}px`}
      visible={visible ? true : undefined}
      {...props}
    />
  );
}

SelectionCheckbox.propTypes = {
  bulk: PropTypes.bool, // on bulk selection
  checked: PropTypes.bool, // being hovered
  hover: PropTypes.bool, // being hovered
  visible: PropTypes.bool,
  selecting: PropTypes.bool, // inside bulk selection
};

SelectionCheckbox.defaultProps = {
  bulk: false,
  checked: false,
  hover: false,
  visible: false,
  selecting: false,
};

export default memo(SelectionCheckbox);
