import kebabCase from 'lodash/kebabCase';
import { requestAsPromise } from '../../json-api-middleware/actions';
import { asyncTypesArray } from '../../utils/action-types-helpers';
import addQueryParameters from '../../utils/add-query-parameters';
import selectPageMeta from '../../selectors/select-page-meta';
import { API_LOAD_DATA_TYPES } from '../../constants';

export const load = (
  model,
  {
    // api options
    id,
    include,
    page,
    filter,
    sort,
    querySerializers,
    ...rest
  } = {},
  {
    // request options
    prefix,
    postfix,
    ignoreCache,
    expiresIn = ignoreCache && 0,
    baseUrl,
  } = {},
) => {
  let url = model.split('.').map(kebabCase).join('.');

  if (id) {
    const partial = url.split('.');
    url =
      partial.length > 1 ? `${partial[0]}/${id}/${partial[1]}` : `${url}/${id}`;
  }

  if (prefix) url = prefix.concat(`/${url}`);
  if (postfix) url = url.concat(`/${postfix}`);

  return dispatch =>
    requestAsPromise(dispatch, {
      types: asyncTypesArray(API_LOAD_DATA_TYPES),
      endpoint: addQueryParameters(url, {
        include,
        page,
        filter,
        sort,
        querySerializers,
        ...rest,
      }),
      method: 'GET',
      expiresIn,
      baseUrl,
    });
};

export const loadNextPage = (model, { page, ...rest } = {}) => (
  dispatch,
  getState,
) => {
  const pageMeta = selectPageMeta(getState(), model);
  const nextPage = pageMeta.get('page') + 1;

  if (
    pageMeta.get('totalPages') === 0 ||
    pageMeta.get('totalPages') < nextPage
  ) {
    return {
      type: undefined,
    };
  }

  return load(model, {
    page: {
      number: nextPage,
      ...page,
    },
    ...rest,
  })(dispatch);
};
