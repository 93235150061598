import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { MediaQuery } from '@picter/prisma';
import { trackerShape } from '@picter/tracker';
import { Map } from 'immutable';

import paths from 'src/routes/paths';

import LayoutDesktop from './LayoutDesktop';
import LayoutMobile from './LayoutMobile';
import UploadModal from '../components/UploadModal';

const { LIBRARY_PROJECT_INBOX_SUCCESS_PATH } = paths;

const Layout = ({ collection, project, tracker, withSidebar }) => {
  const match = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={LIBRARY_PROJECT_INBOX_SUCCESS_PATH} render={() => null} />
        <Route
          render={() => (
            <UploadModal
              project={project}
              collection={collection}
              match={match}
            />
          )}
        />
      </Switch>
      <MediaQuery query={{ minWidth: 0 }}>
        {matches =>
          matches ? (
            <LayoutDesktop
              project={project}
              collection={collection}
              tracker={tracker}
              withSidebar={withSidebar}
            />
          ) : (
            <LayoutMobile
              tracker={tracker}
              withSidebar={withSidebar}
              project={project}
              collection={collection}
            />
          )
        }
      </MediaQuery>
    </>
  );
};

Layout.propTypes = {
  project: PropTypes.instanceOf(Map).isRequired,
  collection: PropTypes.instanceOf(Map),
  withSidebar: PropTypes.bool.isRequired,
  tracker: trackerShape.isRequired,
};

Layout.defaultProps = {
  collection: undefined,
};

export default Layout;
