import { defineMessages } from 'react-intl';

export default defineMessages({
  titleCopy: {
    id: 'titleCopy',
    defaultMessage: 'Copy files to project',
  },
  titleWorkspace: {
    id: 'titleWorkspace',
    defaultMessage: 'Workspace',
  },
  titleNewProject: {
    id: 'titleNewProject',
    defaultMessage: 'New project',
  },
  messageCreateNewProject: {
    id: 'messageCreateNewProject',
    defaultMessage: 'Create new project',
  },
  labelCopy: {
    id: 'labelCopy',
    defaultMessage: 'Copy',
  },
  labelCancel: {
    id: 'labelCancel',
    defaultMessage: 'Cancel',
  },
  labelSearch: {
    id: 'labelSearch',
    defaultMessage: 'Search',
  },
  labelFilesCount: {
    id: 'labelFilesCount',
    defaultMessage: `{count, plural,
      =0 {No files}
      one {# file}
      other {# files}
    }`,
  },
});
