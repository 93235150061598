import React from 'react';
import { RestoreFromTrash as RestoreFromTrashSvg } from '@styled-icons/material';
import { FormattedMessage } from 'react-intl';

import ActionsDropdownItem from './ActionsDropdownItem';
import messages from '../messages';

function ActionsDropdownRestore(props) {
  return (
    <ActionsDropdownItem
      action="restore"
      icon={<RestoreFromTrashSvg />}
      label={<FormattedMessage {...messages.messageRestore} />}
      {...props}
    />
  );
}

export default ActionsDropdownRestore;
