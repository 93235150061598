import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';

export const LayoutSidebar = styled.aside`
  background-color: ${themeGet('colors.white')};
  box-shadow: ${themeGet('shadows.soft')};
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  z-index: 100;
`;
export const LayoutContent = styled.main`
  z-index: 50;
`;
export const Layout = styled.section`
  background: ${themeGet('colors.grey.100')};
  display: grid;
  grid-template-columns: minmax(min(100%, 500px), 25%) 1fr;
  height: 100%;
  max-height: 100vh;
  position: relative;
  transition: grid-template-columns 150ms ease-in-out 0ms;
  z-index: 0;
`;
