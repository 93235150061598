import React from 'react';
import { Switch, Route } from 'react-router-dom';

import paths from 'src/routes/paths';

import Document from './_document';
import SubscriptionPage from '.';
import CancelSubscriptionPage from './cancel';

const { LIBRARY_SETTINGS_SPACE_SUBSCRIPTION_CANCEL_PATH } = paths;

function Routes() {
  return (
    <Document>
      <Switch>
        <Route
          component={CancelSubscriptionPage}
          path={LIBRARY_SETTINGS_SPACE_SUBSCRIPTION_CANCEL_PATH}
        />
        <Route component={SubscriptionPage} />
      </Switch>
    </Document>
  );
}

export default Routes;
