import React from 'react';

export default function NotFoundIllustrationSvg() {
  return (
    <svg
      width="260"
      height="225"
      viewBox="0 0 260 225"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.5"
        y="2.5"
        width="31"
        height="31"
        rx="15.5"
        stroke="#D2D2D2"
        strokeWidth="5"
        strokeMiterlimit="1.5728"
        strokeDasharray="3 15"
      />
      <rect
        x="36"
        y="40"
        width="160"
        height="160"
        rx="80"
        fill="white"
        stroke="white"
        strokeWidth="2"
        strokeDasharray="15 15"
      />
      <rect
        x="127"
        y="19"
        width="108"
        height="108"
        rx="54"
        stroke="#5C62CA"
        strokeWidth="10"
        strokeDasharray="2 30"
      />
      <rect
        x="9"
        y="118"
        width="64"
        height="64"
        rx="32"
        stroke="#FFC51F"
        strokeWidth="6"
        strokeDasharray="3 30"
      />
      <rect
        x="213.287"
        y="195.077"
        width="33.5802"
        height="33.5802"
        rx="16.7901"
        transform="rotate(-30 213.287 195.077)"
        fill="white"
        stroke="white"
        strokeWidth="0.419753"
        strokeDasharray="3.15 3.15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.8916 93.2244C70.432 74.5369 89.0851 61.0247 111.528 59.0424L111.387 57.4526C88.3243 59.4897 69.1645 73.4022 59.4028 92.6305L60.8916 93.2244Z"
        fill="#F3F3F3"
      />
      <path
        d="M111.528 59.0424L111.591 59.7596C111.987 59.7247 112.28 59.3752 112.245 58.9791L111.528 59.0424ZM60.8916 93.2244L60.6248 93.8932C60.9702 94.031 61.3638 93.883 61.5329 93.5518L60.8916 93.2244ZM111.387 57.4526L112.104 57.3893C112.088 57.1991 111.996 57.0233 111.85 56.9007C111.703 56.7781 111.514 56.7186 111.324 56.7354L111.387 57.4526ZM59.4028 92.6305L58.7608 92.3045C58.6693 92.4849 58.6578 92.6954 58.7292 92.8846C58.8005 93.0738 58.9482 93.2243 59.136 93.2992L59.4028 92.6305ZM111.464 58.3252C88.7646 60.3302 69.8987 73.998 60.2503 92.897L61.5329 93.5518C70.9653 75.0757 89.4055 61.7192 111.591 59.7596L111.464 58.3252ZM110.67 57.516L110.81 59.1058L112.245 58.9791L112.104 57.3893L110.67 57.516ZM60.0448 92.9564C69.6987 73.9404 88.6453 60.1841 111.451 58.1698L111.324 56.7354C88.0032 58.7952 68.6302 72.8641 58.7608 92.3045L60.0448 92.9564ZM59.136 93.2992L60.6248 93.8932L61.1584 92.5557L59.6696 91.9617L59.136 93.2992Z"
        fill="#F3F3F3"
      />
    </svg>
  );
}
