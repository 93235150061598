/**
 * @see https://stephaniewalter.design/blog/enhancing-user-experience-with-css-animations/
 */

export function transition({
  property = 'all',
  duration = '0ms',
  timing = 'ease',
  delay = '0ms',
} = {}) {
  return `${property} ${duration} ${timing} ${delay}`;
}
