import React, {
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  matchPath,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';

import paths from 'src/routes/paths';
import { get } from 'src/utils/accessors';
import { useCurrentUser } from 'src/hooks/use-resource';
import useRealtimeSocket from 'src/modules/realtime/hooks/use-realtime-socket';

const { LIBRARY_PROJECT_SHARE_PATH } = paths;

export const ObservationModeEvent = Object.freeze({
  // Triggered by channel owners to remove all members
  CHANNEL_PURGE: 'client-channel:purge',
  MOUSE_MOVE: 'client-mouse:move',
  NAVIGATE: 'client-navigate',
});

export const ObservationModeControlsContext = createContext({
  observe: f => f,
  unobserve: f => f,
  purge: f => f,
});

export const ObservationModeDataContext = createContext({
  observingId: null,
  observersIds: null,
});

function isBeingObserved(observers) {
  return observers.size > 0;
}

function buildChannelName({ id, publicKey }) {
  return `presence-resource=users;id=${id};publicKey=${publicKey}`;
}

function ObservationModeProvider({ children, onObserve, onUnobserve }) {
  const user = useCurrentUser();
  const location = useLocation();
  const history = useHistory();
  const userId = get(user, 'id');
  const { projectId: projectPublicKey } = useParams();
  const channelRef = useRef();
  const [observingId, setObservingId] = useState(null);
  const [observersIds, setObserversIds] = useState(new Set());
  const data = useMemo(
    () => ({
      observingId,
      observersIds,
    }),
    [observingId, observersIds],
  );
  const controls = useMemo(
    () => ({
      observe: id => setObservingId(id),
      unobserve: () => setObservingId(null),
      purge: () => {
        const { current: channel } = channelRef;
        channel.trigger(ObservationModeEvent.CHANNEL_PURGE, 'test');
      },
    }),
    [],
  );
  const socket = useRealtimeSocket();

  /**
   * 1. Creates user channel based on id and project key;
   * 2. Binds to events that handle number of members inside
   *    the presence channel.
   */
  useEffect(() => {
    const channelName = buildChannelName({
      id: userId,
      publicKey: projectPublicKey,
    });
    const channel = socket.subscribe(channelName);

    channel.bind('pusher:subscription_succeeded', members =>
      setObserversIds(ids => {
        members.each(m => m.id !== userId && ids.add(members));
        return new Set(ids);
      }),
    );
    channel.bind('pusher:member_added', member =>
      setObserversIds(ids => new Set(ids.add(member.id))),
    );
    channel.bind('pusher:member_removed', member =>
      setObserversIds(ids => {
        ids.delete(member.id);
        return new Set(ids);
      }),
    );

    channelRef.current = channel;

    return () => {
      channel.unbind();
      socket.unsubscribe(channelName);
    };
  }, [projectPublicKey, socket, userId]);

  /**
   * 1. Checks if current user is being observed;
   * 2. If positive triggers NAVIGATE events on location change.
   */
  useEffect(() => {
    const { current: channel } = channelRef;
    const { pathname } = location;
    if (
      channel &&
      isBeingObserved(observersIds) &&
      !matchPath(pathname, {
        path: LIBRARY_PROJECT_SHARE_PATH,
      })
    ) {
      channel.trigger(ObservationModeEvent.NAVIGATE, location);
    }
  }, [observersIds, location]);

  /**
   * 1. Connects to the observed channel;
   * 2. Listens to NAVIGATE events.
   */
  useEffect(() => {
    if (observingId) {
      const channelName = buildChannelName({
        id: observingId,
        publicKey: projectPublicKey,
      });
      const channel = socket.subscribe(channelName);
      channel.bind('pusher:subscription_succeeded', members =>
        onObserve({
          observingChannelUser: observingId,
          observingChannelMembers: members,
        }),
      );
      channel.bind(ObservationModeEvent.CHANNEL_PURGE, () =>
        controls.unobserve(),
      );

      channel.bind(ObservationModeEvent.NAVIGATE, history.replace);

      return () => {
        channel.unbind();
        onUnobserve({
          observingChannelUser: observingId,
          observingChannelMembers: channel.members,
        });
        socket.unsubscribe(channelName);
      };
    }

    return () => undefined;
    /**
     * Only execute in case the user being observed or the project changes
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observingId, projectPublicKey]);

  return (
    <ObservationModeControlsContext.Provider value={controls}>
      <ObservationModeDataContext.Provider value={data}>
        {children}
      </ObservationModeDataContext.Provider>
    </ObservationModeControlsContext.Provider>
  );
}

ObservationModeProvider.defaultProps = {
  onObserve: f => f,
  onUnobserve: f => f,
};

ObservationModeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  onObserve: PropTypes.func,
  onUnobserve: PropTypes.func,
};

export default ObservationModeProvider;
