import { API_LOCAL_DESTROY } from '../../constants';

const localDestroy = (model, { id } = {}) => {
  if (!id) {
    throw new Error('Local destroy without id is not allowed.');
  }

  return dispatch =>
    dispatch({
      type: API_LOCAL_DESTROY,
      meta: { id, model },
    });
};

export default localDestroy;
