import styled from 'styled-components/macro';
import SidebarPanel from './SidebarPanel';

import { CONTEXT_SIDEBAR_SIZE } from '../constants';

const ContextSidebarPanel = styled(SidebarPanel)`
  height: 100%;
  left: 0;
  overflow: auto;
  position: absolute;
  top: 0;
  width: 100%;

  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    left: 100%;
    width: ${CONTEXT_SIDEBAR_SIZE}px;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints[4]}) {
    width: ${CONTEXT_SIDEBAR_SIZE + 60}px;
  }
`;

export default ContextSidebarPanel;
