import styled from 'styled-components/macro';
import { fontFamily, fontWeight, textAlign, textStyle } from 'styled-system';
import tag from 'clean-tag';

import HiddenSpan from './ContextualTextFieldHiddenSpan';
import Input from './ContextualTextFieldInput';

const displayProperty = props =>
  props.maxWidth !== '100%' && `display: inline-block;`;

const ContextualTextFieldContainer = styled(tag)`
  ${displayProperty};
  max-width: 100%;
  min-width: ${props => props.minWidth};
  position: relative;
  ${textAlign};
  ${HiddenSpan}, ${Input} {
    max-width: ${props => props.maxWidth};
    min-width: ${props => props.minWidth};
    ${fontFamily};
    ${textAlign};
    ${textStyle};
    ${fontWeight}
  }
`;

export default ContextualTextFieldContainer;
