import styled from 'styled-components/macro';
import { transition } from '@picter/prisma';

const ContentIconGroup = styled.div`
  cursor: pointer;
  display: grid;

  .content-icon {
    grid-column: 1;
    grid-row: 1;
    opacity: 0;
    pointer-events: none;
    transition: ${transition({ property: 'opacity' })};
    z-index: 0;
  }

  &:active {
    .content-icon--active {
      opacity: 1;
      z-index: 1;
    }
  }

  &:hover:not(:active) {
    .content-icon--hover {
      opacity: 1;
      z-index: 1;
    }
  }

  &:not(:hover):not(:active) {
    .content-icon.content-icon--visible,
    .content-icon--default {
      opacity: 1;
      z-index: 1;
    }

    .content-icon.content-icon--visible ~ .content-icon--default {
      opacity: 0;
      z-index: 0;
    }
  }
`;

export default ContentIconGroup;
