import themeGet from '@styled-system/theme-get';
import { useTheme } from 'styled-components/macro';

function useInputPositionRelativeToMarker({ x: xPercentage, y: yPercentage }) {
  const theme = useTheme();
  const position = {};

  const isNearRightEdge = xPercentage > 50;

  position.bottom = `${100 - yPercentage}%`;

  if (isNearRightEdge) {
    position.right = `calc(${100 - xPercentage}% + ${themeGet('space.7')({
      theme,
    })}px)`;
  } else {
    position.left = `calc(${xPercentage}% + ${themeGet('space.6')({
      theme,
    })}px)`;
  }

  return position;
}

export default useInputPositionRelativeToMarker;
