import { theme } from '@picter/prisma';

export default {
  ...theme,
  /**
   * Colors extension.
   *
   * @see src/components/Annotations/models/Markup.ts
   */
  colors: {
    ...theme.colors,
    emerald: '#3ddd78',
    easternBlue: '#1ca7a4',
    cyan: '#00e2ff',
    dodgerBlue: '#3697ff',
    indigo: '#5c62ca',
    fuchsiaPink: '#be52cc',
    carnationPink: '#ff9cc8',
    carnation: '#fb5c5c',
    flushOrange: '#ff8300',
    lightiningYellow: '#ffc51f',
    stack: '#8c8c8c',
    alto: '#dddddd',
  },
  accents: {
    primary: {
      dark: '#3F0076',
    },
    secondary: {
      dark: '#747D80',
    },
  },
  sizes: {
    checkbox: {
      xxxxsmall: 2,
      xxxsmall: 4,
      xxsmall: 8,
      xsmall: 12,
      small: 16,
      medium: 20,
      large: 24,
      xlarge: 28,
      xxlarge: 32,
      xxxlarge: 40,
      xxxxlarge: 60,
    },
    icon: {
      xxxxsmall: 2,
      xxxsmall: 4,
      xxsmall: 8,
      xsmall: 12,
      small: 16,
      medium: 20,
      large: 24,
      xlarge: 28,
      xxlarge: 32,
      xxxlarge: 40,
      xxxxlarge: 60,
    },
  },
};
