import React from 'react';
import PropTypes from 'prop-types';
import useProjectPermission from 'src/hooks/use-project-permission';
import useRouter from 'src/hooks/use-router';

export const PermissionContext = React.createContext();

const PermissionProvider = ({ children }) => {
  const { match } = useRouter();
  const value = useProjectPermission(match.params);

  return (
    <PermissionContext.Provider value={value}>
      {children}
    </PermissionContext.Provider>
  );
};

PermissionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PermissionProvider;
