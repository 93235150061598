import { makePermissionSelector } from 'src/selectors/project-permissions';
import { useProject, useCurrentUser } from 'src/hooks/use-resource';

const permissionSelector = makePermissionSelector();

const usePermissionScope = projectPublicKey => {
  // ProjectId is needed right now before useRouter is release by react-router
  const project = useProject(
    {
      id: projectPublicKey,
      include: ['users.user', 'space.users.user'],
    },
    { request: false },
  );

  const user = useCurrentUser();

  return scope => permissionSelector({ project, user }, scope);
};

export default usePermissionScope;
