import { parse as parseQueryString } from 'qs';
import urlParse from 'url-parse';

// customize history object
const basename = process.env.PUBLIC_URL
  ? urlParse(process.env.PUBLIC_URL).pathname
  : '';

const { REACT_APP_HASH_ROUTER } = process.env;

const createHistory = REACT_APP_HASH_ROUTER
  ? require('history').createHashHistory
  : require('history').createBrowserHistory;

const browserHistory = createHistory({ basename });

// Initialize browser history with hash bang url even for regular Router,
// necessary for AWS S3 redirects (redirect from directory to hashbang).
const pathname = window.location.hash.slice(1);
if (pathname && !REACT_APP_HASH_ROUTER) {
  window.location.hash = '';
  browserHistory.replace(pathname);
}

// helper to add parsed query-property to history.location
function addLocationQuery(historyObject) {
  // eslint-disable-next-line no-param-reassign
  historyObject.location = Object.assign(
    historyObject.location,
    // slice(1) removes the `?` at the beginning of `location.search`
    {
      query: parseQueryString(historyObject.location.search.slice(1), {
        comma: true,
      }),
    },
  );
}

// parse query-parameters of first page load
addLocationQuery(browserHistory);

// add parsing for all following history-changes
browserHistory.listen(() => addLocationQuery(browserHistory));

export default browserHistory;
