import React from 'react';
import PropTypes from 'prop-types';
import { DetailLayout } from '@picter/prisma';
import { Route, Switch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { trackerShape } from '@picter/tracker';

import AppLayout from 'src/components/AppLayout';
import paths from 'src/routes/paths';
import EmptyStateBox from 'src/components/EmptyStateBox';
import UploadArea from 'src/components/UploadArea';
import usePermission from 'src/hooks/use-permission';

import { Header, Info, Grid } from '../_sections';
import OpacityFooter from '../styles/OpacityFooter';
import DoneWithUploadButton from '../components/DoneWithUploadButton';
import UploadMoreButton from '../components/UploadMoreButton';
import FilesSentSvg from '../components/FilesSentSvg';
import { useOnFileUpload } from '../_hooks/use-handlers';

import messages from '../messages';

const { LIBRARY_PROJECT_INFO_PATH, LIBRARY_PROJECT_INBOX_SUCCESS_PATH } = paths;

const LayoutMobile = props => {
  const { tracker, project, collection } = props;

  const { canUploadImages } = usePermission();

  const onFileUpload = useOnFileUpload({
    tracker,
    project,
    collection,
    overrideFile: false,
  });

  return (
    <AppLayout.Content>
      <UploadArea onUpload={onFileUpload} disabled={!canUploadImages}>
        {({ openFileDialog }) => (
          <DetailLayout>
            <Header
              tracker={tracker}
              onMobile
              openFileDialog={openFileDialog}
            />
            <DetailLayout.Content>
              <Switch>
                <Route
                  path={LIBRARY_PROJECT_INFO_PATH}
                  render={() => <Info />}
                />
                <Route
                  path={LIBRARY_PROJECT_INBOX_SUCCESS_PATH}
                  render={() => (
                    <EmptyStateBox
                      graphic={<FilesSentSvg />}
                      title={<FormattedMessage {...messages.titleFilesSent} />}
                      message={
                        <FormattedMessage {...messages.messageFilesSent} />
                      }
                    />
                  )}
                />
                <Route
                  render={() => (
                    <Grid project={project} collection={collection} onMobile />
                  )}
                />
              </Switch>
            </DetailLayout.Content>
            <OpacityFooter>
              <Switch>
                <Route
                  path={LIBRARY_PROJECT_INBOX_SUCCESS_PATH}
                  render={() => <UploadMoreButton />}
                />
                <Route render={() => <DoneWithUploadButton />} />
              </Switch>
            </OpacityFooter>
          </DetailLayout>
        )}
      </UploadArea>
    </AppLayout.Content>
  );
};

LayoutMobile.propTypes = {
  project: PropTypes.instanceOf(Map).isRequired,
  collection: PropTypes.instanceOf(Map),
  tracker: trackerShape.isRequired,
};

LayoutMobile.defaultProps = {
  collection: undefined,
};

export default LayoutMobile;
