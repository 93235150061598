import { createContext, useContext } from 'react';

const LayoutControlsContext = createContext({
  enableCompactMode: f => f,
  enableComfortableMode: f => f,
});

const LayoutInfoContext = createContext({
  compact: false,
  leftOffset: 0,
  rightOffset: 0,
  topOffset: 0,
});

export const LayoutControlsProvider = LayoutControlsContext.Provider;
export const LayoutInfoProvider = LayoutInfoContext.Provider;

export function useLayoutControls() {
  const context = useContext(LayoutControlsContext);
  if (context === undefined) {
    throw new Error(
      '`useLayoutControls` must be used within a `LayoutControlsProvider`.',
    );
  }
  return context;
}

export function useLayoutInfo() {
  const context = useContext(LayoutInfoContext);
  if (context === undefined) {
    throw new Error(
      '`useLayoutInfo` must be used within a `LayoutInfoProvider`.',
    );
  }
  return context;
}

export function useLayout() {
  return [useLayoutInfo(), useLayoutControls()];
}
