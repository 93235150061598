import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import EmptyStateBox from 'src/components/EmptyStateBox';
import NoCommentsSvg from 'src/assets/NoCommentsSvg';
import { CommentFilter } from 'src/utils/accessors/comment';

import messages from '../messages';

function CommentsEmpty({ filter }) {
  return (
    <EmptyStateBox
      graphic={<NoCommentsSvg />}
      title={
        <FormattedMessage
          {...messages.titleEmptyComments}
          values={{ filter }}
        />
      }
      message={
        <FormattedMessage
          {...messages.messageEmptyComments}
          values={{ filter }}
        />
      }
    />
  );
}

CommentsEmpty.propTypes = {
  filter: PropTypes.oneOf(Object.values(CommentFilter)),
};

CommentsEmpty.defaultProps = {
  filter: undefined,
};

export default CommentsEmpty;
