import { variants } from '@picter/prisma';

import {
  CONTAINER_SIZES_MAP,
  CONTAINER_SHAPES_MAP,
  IDENTICON_FONT_SIZES_MAP,
} from './constants';

export const containerSizeVariants = variants('size', CONTAINER_SIZES_MAP);
export const containerShapeVariants = variants('shape', CONTAINER_SHAPES_MAP);
export const identiconSizeVariants = variants('size', IDENTICON_FONT_SIZES_MAP);
