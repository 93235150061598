import React from 'react';
import PropTypes from 'prop-types';

function FullCircleSvg({ color, height, width, xmlns }) {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 26 26"
      width={width}
      xmlns={xmlns}
    >
      <circle cx="13" cy="13" r="12" stroke={color} strokeWidth={1.5} />
    </svg>
  );
}

FullCircleSvg.defaultProps = {
  color: 'currentColor',
  height: '26',
  width: '26',
  xmlns: 'http://www.w3.org/2000/svg',
};

FullCircleSvg.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  xmlns: PropTypes.string,
};

export default FullCircleSvg;
