import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'SignUpPage.title',
    defaultMessage: 'Sign up for free trial',
  },
  placeholderEmail: {
    id: 'SignUpPage.placeholderEmail',
    defaultMessage: 'Email',
  },
  placeholderNewPassword: {
    id: 'SignUpPage.placeholderNewPassword',
    defaultMessage: 'Choose a password',
  },
  labelPasswordLimit: {
    id: 'SignUpPage.labelPasswordLimit',
    defaultMessage: 'At least 8 characters',
  },
  labelSignup: {
    id: 'SignUpPage.labelSignup',
    defaultMessage: 'Sign up',
  },
  messageErrorEmailInvalid: {
    id: 'SignUpPage.messageErrorEmailInvalid',
    defaultMessage: 'Please enter a valid email address.',
  },
  messageErrorEmailRegistered: {
    id: 'SignUpPage.messageErrorEmailRegistered',
    defaultMessage: "The email you've entered is already registered.",
  },
  messageErrorPasswordEmpty: {
    id: 'SignUpPage.messageErrorPasswordEmpty',
    defaultMessage: 'Please enter a password.',
  },
  messageErrorPasswordTooShort: {
    id: 'SignUpPage.messageErrorPasswordTooShort',
    defaultMessage:
      'Your password needs at least {min, plural, one {# characters} other {# characters}}.',
  },
  messageTerms: {
    id: 'SignUpPage.messageTerms',
    defaultMessage: `By creating an account you accept Picter's {terms} and acknowledge Picter's {privacy}.`,
  },
  messageRecaptcha: {
    id: 'SignUpPage.messageTerms',
    defaultMessage: `This site is protected by reCAPTCHA and the Google {policy} and {terms} apply.`,
  },
  labelPrivacyPolicy: {
    id: 'SignUpPage.labelPrivacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
  labelTermsOfUse: {
    id: 'SignUpPage.labelTermsOfUse',
    defaultMessage: 'Terms of Use',
  },
  labelTermsOfService: {
    id: 'SignUpPage.labelTermsOfService',
    defaultMessage: 'Terms of Service',
  },
  messageAlreadyHaveAccount: {
    id: 'SignUpPage.messageAlreadyHaveAccount',
    defaultMessage: 'Already have an account? ',
  },
  labelLoginLink: {
    id: 'SignUpPage.labelLoginLink',
    defaultMessage: 'Log in here',
  },
});
