import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Flex } from '@rebass/grid';

import { Text } from 'src/modules/prisma';
import SpaceAvatar from 'src/components/SpaceAvatar';
import { NavigationHeader } from 'src/components/AppLayout';
import ProjectNavigation, {
  PROJECT_NAVIGATION_ELEMENTS,
} from 'src/containers/ProjectNavigation';
import { get } from 'src/utils/accessors';
import {
  publicLinkTypes,
  getProjectPublicLinkByType,
} from 'src/utils/accessors/project';
import { useProject } from 'src/hooks/use-resource';

type Props = {
  onMobile?: boolean;
};
export default function Sidaber({ onMobile = false }: Props) {
  const { projectId: publicLinkKey } = useParams<{ projectId: string }>();

  const project = useProject(
    {
      id: publicLinkKey,
      include: ['publicLinks', 'space'],
    },
    {
      request: false,
    },
  );
  const space = get(project, 'relationships.space');
  const publicLink = getProjectPublicLinkByType(
    project,
    publicLinkTypes.REVIEW,
  );
  const hasAccessToAllFiles = get(publicLink, 'attributes.accessToAllFiles');

  const elements = [
    PROJECT_NAVIGATION_ELEMENTS.INFO,
    PROJECT_NAVIGATION_ELEMENTS.PROOF,
    PROJECT_NAVIGATION_ELEMENTS.COLLECTIONS,
  ];

  if (hasAccessToAllFiles) {
    elements.push(PROJECT_NAVIGATION_ELEMENTS.SHOW_ALL);
  }
  if (onMobile) {
    return (
      <>
        <NavigationHeader>
          <Flex alignItems="center">
            <SpaceAvatar
              name={get(space, 'attributes.name')}
              spaceId={get(space, 'id')}
              src={get(space, 'attributes.logo.small')}
            />
            <Text color="secondary" textStyle="body.small" ml={2}>
              {get(space, 'attributes.name')}
            </Text>
          </Flex>
        </NavigationHeader>
        <Box mr={7}>
          <ProjectNavigation projectId={publicLinkKey} elements={elements} />
        </Box>
      </>
    );
  }

  return <ProjectNavigation projectId={publicLinkKey} elements={elements} />;
}
