import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Map } from 'immutable';
import { ButtonElement as Button } from '@picter/prisma';

import useNavigate from 'src/hooks/use-navigate';
import { Box, Body, Heading, Panel, Separator } from 'src/modules/prisma';
import { Feature, FeatureName } from 'src/containers/FeatureFlags';
import { getDateDeltaFromNowInSeconds } from 'src/utils/date';
import { getSpacePlan } from 'src/utils/accessors/space';
import usePromise from 'src/hooks/use-promise';
import { get, tGet } from 'src/utils/accessors';
import { librarySettingsSpaceSubscriptionCancelUrl } from 'src/routes/urls';
import { useToriiActions } from 'src/modules/torii';
import { $TSFixMe } from 'src/types';

import {
  CurrentPlanPanel,
  PaymentMethodPanel,
  PlansInfo,
  SubscriptionDetails,
} from './SubscriptionPage';
import {
  useSubscriptionService,
  useSubscription,
  SubscriptionStatus,
} from './SubscriptionService';
import messages from '../_messages';
import specialOffers from '../_special-offer-config';

export default function SubscriptionManagement({ space }: { space: $TSFixMe }) {
  return (
    <Feature name={FeatureName.SELF_CHECKOUT}>
      {(isEnabled: boolean) =>
        isEnabled ? (
          <SubscriptionPageContentWithFeatureFlag space={space} />
        ) : (
          <SubscriptionPageContentWithoutFeatureFlag space={space} />
        )
      }
    </Feature>
  );
}

function SubscriptionPageContentWithFeatureFlag({
  space,
}: {
  space: $TSFixMe;
}) {
  const { load } = useToriiActions() as $TSFixMe;
  const spaceId = tGet(space, 'id');
  const subscriptionService = useSubscriptionService();
  const { subscription, revalidate, error, merge } = useSubscription({
    spaceId,
  });

  const navigate = useNavigate();
  const card = get(subscription, 'card');

  const spaceName = get(space, 'attributes.name');
  const subscriptionStatus = get(space, 'attributes.subscriptionStatus');
  const spacePlan = getSpacePlan(space, Map());
  const planSlug = get(spacePlan, 'attributes.slug');
  const planName = get(spacePlan, 'attributes.name');

  const trialEndsAt = get(space, 'attributes.trialEndsAt');
  const secondsToTrialEnding = useMemo(
    () => getDateDeltaFromNowInSeconds(trialEndsAt),
    [trialEndsAt],
  );

  const specialOffer = specialOffers.find(offer =>
    offer.isApplicable({
      subscriptionStatus,
      viaReferralLink: get(space, 'attributes.viaReferralLink'),
    }),
  );
  const isSpecialOfferApplicable = specialOffer !== undefined;

  const createSubscription = useCallback(
    planId =>
      subscriptionService.create({ planId, spaceId }).then(async () => {
        // revalidates chargebee subscription
        await revalidate();
        // reloads current space
        return load(
          'wsSpaces',
          {
            id: spaceId,
            include: ['plan', 'extraFeatures'],
          },
          {
            ignoreCache: true,
          },
        );
      }),
    [load, revalidate, subscriptionService, spaceId],
  );

  const updatePayment = useCallback(
    () =>
      subscriptionService
        .updatePayment({ spaceId })
        .then((payload: $TSFixMe) => merge(payload, false)),
    [merge, subscriptionService, spaceId],
  );

  const updateSubscription = useCallback(
    planId =>
      subscriptionService
        .update({ spaceId, planId })
        .then((payload: $TSFixMe) => {
          merge(payload, false);
          return load(
            'wsSpaces',
            {
              id: spaceId,
              include: ['plan', 'extraFeatures'],
            },
            {
              ignoreCache: true,
            },
          );
        }),
    [load, merge, subscriptionService, spaceId],
  );

  const {
    trigger: resumeSubscription,
    isLoading: isLoadingResume,
  } = usePromise(() =>
    subscriptionService
      .resume({ spaceId })
      // resume returns the subscription without the card
      .then((payload: $TSFixMe) => {
        merge(payload, false);
        return load(
          'wsSpaces',
          {
            id: spaceId,
            include: ['plan', 'extraFeatures'],
          },
          {
            ignoreCache: true,
          },
        );
      }),
  );

  return (
    <>
      {error && error.status !== 404 && (
        <Box>
          <Panel
            backgroundColor="danger"
            backgroundOpacity={0.04}
            py={4}
            px={5}
          >
            <Body color="danger">
              <FormattedMessage {...messages.messageLoadSubscriptionError} />
            </Body>
          </Panel>
        </Box>
      )}
      {subscriptionStatus === SubscriptionStatus.InTrial && (
        <Box gmt={4}>
          <Panel
            backgroundColor="primary"
            backgroundOpacity={0.04}
            py={4}
            px={5}
          >
            <Body color="info">
              {secondsToTrialEnding > 0 ? (
                <FormattedMessage
                  {...messages.messageSpaceCurrentPlanTrialActive}
                  values={{
                    planName,
                    spaceName,
                  }}
                />
              ) : (
                <FormattedMessage {...messages.messageSpaceTrialExpired} />
              )}
            </Body>
          </Panel>
        </Box>
      )}
      {subscription?.id && (
        <Box gmt={4}>
          <SubscriptionDetails
            currentPlanName={planName}
            disabled={isLoadingResume}
            onClickCancel={() =>
              navigate(librarySettingsSpaceSubscriptionCancelUrl({ spaceId }))
            }
            onClickResume={resumeSubscription}
            onClickRevoke={resumeSubscription}
            subscription={subscription}
            subscriptionStatus={subscriptionStatus}
            seats={get(space, 'attributes.billableSeats')}
          />
        </Box>
      )}
      {card && (
        <>
          <Box gmt={4}>
            <PaymentMethodPanel
              currentMethod={card}
              onClickChangePayment={updatePayment}
            />
          </Box>
          <Separator size={6} />
        </>
      )}
      {isSpecialOfferApplicable && (
        <Box gmt={4}>
          <Panel
            backgroundColor="primary"
            backgroundOpacity={0.04}
            py={4}
            px={5}
          >
            <Body color="info">{specialOffer?.message}</Body>
          </Panel>
        </Box>
      )}
      <Box py={4} px={5}>
        <Heading tag="h2" fontWeight="light" textSize="regular">
          <FormattedMessage {...messages.titleWorkspaceDescription} />
        </Heading>
        <Box mx={-5} mb={-3} mt={3}>
          <PlansInfo
            currentPlanSlug={planSlug}
            subscriptionStatus={subscriptionStatus}
            onDowngrade={updateSubscription}
            onSubscribe={createSubscription}
            onUpgrade={updateSubscription}
            discounts={
              isSpecialOfferApplicable ? specialOffer?.discounts : undefined
            }
          />
        </Box>
      </Box>
    </>
  );
}

function SubscriptionPageContentWithoutFeatureFlag({
  space,
}: {
  space: $TSFixMe;
}) {
  const spaceName = tGet(space, 'attributes', 'name');
  const spaceNumberOfSeats = tGet(space, 'attributes', 'quantitySeats');
  const spaceEndOfTrial = tGet(space, 'attributes', 'trialEndsAt');
  const planName = tGet(getSpacePlan(space) ?? Map(), 'attributes', 'name');

  const deltaEndOfTrialInMs = useMemo(
    () => getDateDeltaFromNowInSeconds(spaceEndOfTrial),
    [spaceEndOfTrial],
  );

  return (
    <>
      {deltaEndOfTrialInMs > 0 && (
        <Box>
          <Panel
            backgroundColor="primary"
            backgroundOpacity={0.04}
            py={4}
            px={5}
          >
            <Body color="info">
              <FormattedMessage
                {...messages.messageSpaceCurrentPlan}
                values={{
                  planName,
                  spaceName,
                }}
              />
            </Body>
          </Panel>
        </Box>
      )}
      <Box mt={4}>
        <CurrentPlanPanel
          actionButton={
            <a href="https://www.picter.com/schedule-demo" target="blank">
              <Button color="white" width="100%">
                <FormattedMessage {...messages.labelContactSales} />
              </Button>
            </a>
          }
          planName={planName}
          numberOfSeats={spaceNumberOfSeats}
          secondsLeftInTrial={deltaEndOfTrialInMs}
        />
      </Box>
      <Separator size={6} />
      <Box py={3} px={5}>
        <Heading tag="h2" fontWeight="light" textSize="regular">
          <FormattedMessage {...messages.titleWorkspaceDescription} />
        </Heading>
        <Box mx={-5} mb={-3} mt={3}>
          <PlansInfo />
        </Box>
      </Box>
    </>
  );
}
