import React from 'react';
import { FormattedMessage } from 'react-intl';
import { toastInfo } from '@picter/prisma';

import {
  createCollection,
  createCollectionAndAddItem,
  createCollectionAndAddItems,
} from 'src/actions/collection';
import executeInCase from 'src/utils/execute-in-case';
import { useToriiActions } from 'src/modules/torii';
import { get } from 'src/utils/accessors';

import messages from '../messages';

function useHandlers({ imagesIds, project }) {
  const { create, bulkCreate } = useToriiActions();

  const projectId = get(project, 'id');
  const projectPublicKey = get(project, 'attributes.publicUrlKey');
  const imagesSize = imagesIds.length;

  const createNewCollection = collectionName => {
    executeInCase({
      [imagesSize > 1]: () =>
        createCollectionAndAddItems(
          { projectId, imagesIds, projectPublicKey, name: collectionName },
          { create, bulkCreate },
        ).then(() => {
          toastInfo(
            <FormattedMessage
              {...messages.messageCollectionCreated}
              values={{
                collectionName,
              }}
            />,
          );
          toastInfo(
            <FormattedMessage
              {...messages.messageImagesAddedToCollection}
              values={{
                collectionName,
                imagesSize,
              }}
            />,
          );
        }),
      [imagesSize === 1]: () => {
        const imageId = imagesIds[0];
        return createCollectionAndAddItem(
          { projectId, imageId, projectPublicKey, name: collectionName },
          { create },
        ).then(() => {
          toastInfo(
            <FormattedMessage
              {...messages.messageCollectionCreated}
              values={{
                collectionName,
              }}
            />,
          );
          toastInfo(
            <FormattedMessage
              {...messages.messageImagesAddedToCollection}
              values={{
                collectionName,
                imagesSize,
              }}
            />,
          );
        });
      },
      [imagesSize === 0]: () =>
        createCollection(
          { projectId, projectPublicKey, name: collectionName },
          { create },
        ).then(() => {
          toastInfo(
            <FormattedMessage
              {...messages.messageCollectionCreated}
              values={{
                collectionName,
              }}
            />,
          );
        }),
    });
  };

  return { createNewCollection };
}

export default useHandlers;
