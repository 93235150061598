import { defineMessages } from 'react-intl';

export default defineMessages({
  labelNewProject: {
    id: 'ProjectPage.labelNewProject',
    defaultMessage: 'New project',
  },
  labelSettings: {
    id: 'ProjectPage.labelSettings',
    defaultMessage: 'Settings',
  },

  messageCurrentlyInTrial: {
    id: 'ProjectPage.messageCurrentlyInTrial',
    defaultMessage:
      '{spaceName} space is currently in {planName} Trial. <learnmorelink>Learn more</learnmorelink>',
  },
  messageEndsTrial: {
    id: 'ProjectPage.messageEndsTrial',
    defaultMessage: 'Ends {date}',
  },
});
