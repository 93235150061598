import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';
import { textStyle, typography } from 'styled-system';

const DurationLabel = styled.div`
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: ${themeGet('space.6')}px;
  margin: 0;
  background-color: ${themeGet('colors.black')};
  color: ${themeGet('colors.white')};
  padding: 2px 4px;
  border-radius: 2px;
  opacity: 0.8;
  ${textStyle};
  ${typography}
`;

export default DurationLabel;
