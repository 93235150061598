import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import isFunction from 'lodash/isFunction';

import { AnnotationsRenderer } from 'src/components/Annotations';
import { get } from 'src/utils/accessors';

import { MarkupTool } from 'src/components/Annotations/components/MarkupToolbar';

export default function TaskAnnotationRenderer({
  children,
  canvasHeight,
  canvasWidth,
  file,
}) {
  if (get(file, 'attributes.isVideo')) {
    return (
      <VideoTaskAnnotationRenderer
        canvasHeight={canvasHeight}
        canvasWidth={canvasWidth}
        video={file}
      >
        {children}
      </VideoTaskAnnotationRenderer>
    );
  }

  return (
    <ImageTaskAnnotationRenderer
      canvasHeight={canvasHeight}
      canvasWidth={canvasWidth}
      image={file}
    >
      {children}
    </ImageTaskAnnotationRenderer>
  );
}

TaskAnnotationRenderer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  canvasHeight: PropTypes.number.isRequired,
  canvasWidth: PropTypes.number.isRequired,
  file: PropTypes.instanceOf(Map).isRequired,
};

function ImageTaskAnnotationRenderer({
  children,
  canvasHeight,
  canvasWidth,
  image,
}) {
  const imageRef = useRef();
  const fileDimensions = useMemo(
    () => ({
      height: get(image, 'attributes.height'),
      width: get(image, 'attributes.width'),
    }),
    [image],
  );

  return (
    <AnnotationsRenderer
      canvasHeight={canvasHeight}
      canvasWidth={canvasWidth}
      id="task-annotation"
      markupTool={MarkupTool.Hand}
      renderToolbar={false}
      targetHeight={fileDimensions.height}
      targetType="image"
      targetWidth={fileDimensions.width}
    >
      {isFunction(children) ? children(imageRef) : children}
    </AnnotationsRenderer>
  );
}

ImageTaskAnnotationRenderer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  canvasHeight: PropTypes.number.isRequired,
  canvasWidth: PropTypes.number.isRequired,
  image: PropTypes.instanceOf(Map).isRequired,
};

function VideoTaskAnnotationRenderer({
  children,
  canvasHeight,
  canvasWidth,
  video,
}) {
  // const [
  //   { deselect: deselectAnnotation },
  //   { annotations, selectedId },
  // ] = useAnnotations();

  /**
   * When comment markup gets selected before the player has been started,
   * we need to start the `videoPlayer` and pause it immediately.
   *
   * As soon as the video starts, we will regularly hide all markup.
   *
   * But in case of selection + jump before the player started, we don't want
   * the markup to hide. That's why we need this `preventDeselect` toggle.
   */
  // const preventDeselect = useRef(false);

  // const frameRate = get(video, 'attributes.frameRate');
  const [videoPlayer, setVideoPlayer] = useState();
  const fileDimensions = useMemo(
    () => ({
      height: get(video, 'attributes.height'),
      width: get(video, 'attributes.width'),
    }),
    [video],
  );

  const videoRef = useCallback(player => {
    if (player) {
      setVideoPlayer(player);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const seekBarMarkers = useMemo(() => {
  //   const markers = {};
  //   annotations.forEach(annotation => {
  //     const annotationId = annotation.id;
  //     if (annotationId !== ANNOTATION_IN_PROGRESS_ID)
  //       markers[annotationId] = {
  //         id: annotation.id,
  //         index: annotation.index,
  //         seconds: transformFrameIntoSeconds(annotation.meta.frame, frameRate),
  //       };
  //   });
  //   return markers;
  // }, [annotations, frameRate]);

  const onDrawing = useCallback(() => {}, [videoPlayer]);

  return (
    <AnnotationsRenderer
      canvasHeight={canvasHeight}
      canvasWidth={canvasWidth}
      id="task-annotation"
      markupTool={MarkupTool.Hand}
      onDrawing={onDrawing}
      renderCanvas
      renderMarkup
      renderToolbar={false}
      targetHeight={fileDimensions.height}
      targetType="video"
      targetWidth={fileDimensions.width}
    >
      {isFunction(children) ? children(videoRef) : children}
    </AnnotationsRenderer>
  );
}

VideoTaskAnnotationRenderer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  canvasHeight: PropTypes.number.isRequired,
  canvasWidth: PropTypes.number.isRequired,
  video: PropTypes.instanceOf(Map).isRequired,
};
