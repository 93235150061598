import styled from 'styled-components/macro';
import cleanTag from 'clean-tag';
import { extendTagBlacklist } from '@picter/prisma';

import { blocklistProps } from '../utils/prop-filtering';

const Element = styled(cleanTag).attrs(({ blacklist, tag }) => ({
  as: typeof tag === 'string' ? cleanTag[tag] : blocklistProps(tag, blacklist),
}))``;

Element.defaultProps = {
  blacklist: extendTagBlacklist(['blacklist', 'tag']),
};

Element.displayName = 'Element';

export default Element;
