import React, { useRef, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Avatar from 'src/components/Avatar';
import { Action, Spacer, Tooltip, Truncate } from 'src/modules/prisma';
import { $TSFixMe } from 'src/types';

import SearchableUsersDropdown, {
  SearchableUser,
} from 'src/components/SearchableUsersDropdown';
import useSpaceAssignableUsers from '../_hooks/use-space-assignable-users';

export const ASSIGNEE_FILTER_QUERY_KEY = 'assignee';

export default function AssigneeFilterDropdown({
  value,
  onChange,
  spaceId,
}: {
  value: SearchableUser['id'];
  onChange: (value?: SearchableUser['id']) => void;
  spaceId: string;
}) {
  const { data: assignableUsers } = useSpaceAssignableUsers({ spaceId });

  const users = useMemo(
    () =>
      assignableUsers
        ? assignableUsers.map(({ id, email, publicName }) => ({
            id,
            email,
            name: publicName,
          }))
        : [],
    [assignableUsers],
  );
  const selectedUser = useMemo(() => users.find(user => user.id === value), [
    users,
    value,
  ]);
  const tooltipRef = useRef<$TSFixMe>();
  const intl = useIntl();

  return (
    <SearchableUsersDropdown
      id="assignee-filter-dropdown"
      key={selectedUser?.id}
      selectedUser={selectedUser}
      users={users}
      onChangeUser={user => onChange(user?.id)}
      placeholder={intl.formatMessage({
        id: 'AssigneeFilterDropdown.labelFilterBy',
        defaultMessage: 'Filter by...',
      })}
      renderToggle={({ active, onClick }) => {
        const { email, name } = selectedUser ?? {};

        return (
          // @ts-ignore
          <Tooltip ref={tooltipRef}>
            {/* @ts-ignore */}
            <Tooltip.Reference>
              <Action
                // @ts-ignore
                active={active}
                onClick={(event: React.MouseEvent) => {
                  tooltipRef?.current?.hide();
                  onClick?.(event);
                }}
                colorScheme="primary"
              >
                {selectedUser ? (
                  <>
                    <Avatar email={email} name={name} size="xsmall" />
                    <Spacer sx={1} />
                    <Truncate truncateAt="128px">{name ?? email}</Truncate>
                  </>
                ) : (
                  <FormattedMessage
                    id="AssigneeFilterDropdown.messageFilterAssignee"
                    defaultMessage="All assignees"
                  />
                )}
              </Action>
              {/* @ts-ignore */}
            </Tooltip.Reference>
            {/* @ts-ignore */}
            <Tooltip.Message>
              <FormattedMessage
                id="AssigneeFilterDropdown.messageFilterAssignee"
                defaultMessage="Filter by assignee"
              />
              {/* @ts-ignore */}
            </Tooltip.Message>
          </Tooltip>
        );
      }}
    />
  );
}
