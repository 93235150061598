import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { FormattedMessage } from 'react-intl';
import { ButtonElement as Button, toastInfo } from '@picter/prisma';

import { useToriiActions } from 'src/modules/torii';
import { Body, Panel } from 'src/modules/prisma';
import { get } from 'src/utils/accessors';

import messages from '../messages';

function TrashInfoPanel({ project }) {
  const projectId = get(project, 'id');
  const { update } = useToriiActions();

  const handleRestoreProject = useCallback(async () => {
    await update('wsProjects', {
      id: projectId,
      attributes: {
        deletedAt: null,
      },
    });

    toastInfo(<FormattedMessage {...messages.messageProjectRestored} />);
  }, [projectId, update]);

  return (
    <Panel
      backgroundColor="purple"
      backgroundOpacity={0.04}
      p={3}
      ml={7}
      mb={5}
    >
      <Body textSize="small" fontWeight="light" color="primary">
        <FormattedMessage {...messages.messageProjectMovedToTrash} />
      </Body>

      <Button
        onClick={handleRestoreProject}
        variant="flat"
        color="primary"
        textStyle="body.small"
        mt={2}
        px={0}
        py={0}
      >
        <FormattedMessage {...messages.labelRestoreProject} />
      </Button>
    </Panel>
  );
}

TrashInfoPanel.propTypes = {
  project: PropTypes.instanceOf(Map),
};

TrashInfoPanel.defaultProps = {
  project: null,
};

export default TrashInfoPanel;
