import get from 'lodash/fp/get';
import set from 'lodash/fp/set';
import pipe from 'lodash/fp/pipe';
import concat from 'lodash/fp/concat';

import ACTIONS_TYPES from './constants';

const reducer = (state, { type, payload: { data } = {} }) => {
  switch (type) {
    case ACTIONS_TYPES.SET_INITIAL_ACTIVITIES:
      return data;
    case ACTIONS_TYPES.SET_OLDER_ACTIVITIES:
      return pipe(
        pipedState =>
          set('items', concat(get('items', state), data.items), pipedState),
        pipedState => set('hasMore', data.hasMore, pipedState),
      )(state);
    case ACTIONS_TYPES.SET_NEWER_ACTIVITIES: {
      const updatedGroups = [];
      const updatedArray = data.items
        .map(updatedNotificationActivity => {
          const currentNotification = state.items.find(
            notification =>
              notification.group === updatedNotificationActivity.group,
          );
          if (currentNotification) {
            updatedGroups.push(currentNotification.group);
            return {
              ...currentNotification,
              id: updatedNotificationActivity.id,
              object: updatedNotificationActivity.object,
              target: updatedNotificationActivity.target,
              actor: updatedNotificationActivity.actor,
              count: updatedNotificationActivity.count,
              read: updatedNotificationActivity.read,
              seen: updatedNotificationActivity.seen,
            };
          }
          return updatedNotificationActivity;
        })
        .filter(item => item);

      const filteredState = (state.items || []).filter(
        item => !updatedGroups.includes(item.group),
      );
      const updatedItems = [...updatedArray, ...filteredState];

      return { ...data, items: updatedItems };
    }
    case ACTIONS_TYPES.SET_ACTIVITY_AS_READ: {
      const updatedItems = (state.items || []).map(item => {
        if (item.id === data.id) {
          return {
            ...item,
            read: true,
          };
        }
        return item;
      });
      return {
        ...state,
        items: updatedItems,
        unread: state.unread - 1,
      };
    }
    case ACTIONS_TYPES.SET_ACTIVITIES_AS_READ: {
      const updatedItems = (state.items || []).map(item => {
        return {
          ...item,
          read: true,
        };
      });
      return {
        ...state,
        items: updatedItems,
        unread: 0,
      };
    }
    case ACTIONS_TYPES.SET_ACTIVITIES_AS_SEEN: {
      const updatedItems = (state.items || []).map(item => {
        return {
          ...item,
          seen: true,
        };
      });
      return {
        ...state,
        items: updatedItems,
        unseen: 0,
      };
    }
    default:
      return state;
  }
};

export default reducer;
