import React from 'react';
import { Redirect } from 'react-router-dom';

import paths from 'src/routes/paths';
import { libraryProjectsUrl, librarySpaceUrl } from 'src/routes/urls';
import { useCurrentUser } from 'src/hooks/use-resource';
import { getUserProductFeatures } from 'src/utils/accessors/users';
import { locationShape } from 'src/utils/app-prop-types';
import { PROJECT_RELATION_FILTER } from 'src/constants';
import { get } from 'src/utils/accessors';

const { ONBOARD_PATH, LIBRARY_SPACES_NEW_PATH } = paths;

function IndexPage({ location }) {
  const user = useCurrentUser(
    { include: ['productFlags', 'spaces'] },
    { request: true },
  );

  const spaces = get(user, 'relationships.spaces');
  const productFlags = getUserProductFeatures(user);

  if (get(productFlags, 'isSpaceUser')) {
    return (
      <Redirect to={librarySpaceUrl({ spaceId: get(spaces.first(), 'id') })} />
    );
  }

  if (get(productFlags, 'isSingleWsUser')) {
    return <Redirect to={libraryProjectsUrl()} />;
  }

  if (get(productFlags, 'isContestUser')) {
    return (
      <Redirect
        to={{
          pathname: ONBOARD_PATH,
          state: { redirectUrl: location.pathname },
        }}
      />
    );
  }

  if (get(productFlags, 'isWsGuest')) {
    return (
      <Redirect
        to={libraryProjectsUrl(undefined, {
          roles: [
            PROJECT_RELATION_FILTER.EDITOR,
            PROJECT_RELATION_FILTER.VIEWER,
            PROJECT_RELATION_FILTER.MEMBER,
          ],
        })}
      />
    );
  }

  return <Redirect to={LIBRARY_SPACES_NEW_PATH} />;
}

IndexPage.propTypes = {
  location: locationShape.isRequired,
};

export default IndexPage;
