import React from 'react';
import Avatar, { Props as AvatarProps } from 'src/components/Avatar';

type SpaceAvatarProps = {
  name: string;
  spaceId: number | string;
};

type Props = SpaceAvatarProps & Partial<AvatarProps>;

export default function SpaceAvatar({ name, spaceId, ...avatarProps }: Props) {
  return (
    <Avatar
      name={name}
      gravatar={false}
      uniqueIdentifier={spaceId}
      shape="rounded-square"
      {...avatarProps}
    />
  );
}
