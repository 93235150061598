import { cloneElement } from 'react';
import styled, { css } from 'styled-components/macro';
import tag from 'clean-tag';
import themeGet from '@styled-system/theme-get';
import { extendTagBlacklist, transition } from '@picter/prisma';

import { color, border, display } from './mixins';

const interactiveStyles = css`
  cursor: pointer;
  transition: ${transition({ property: 'filter' })};

  &:hover {
    filter: brightness(0.8);
  }

  &:active {
    filter: brightness(0.6);
  }
`;

const activeStyles = css`
  filter: brightness(0.6);
`;

const Icon = styled(tag.i).attrs(({ onClick, type }) => ({
  children:
    typeof type === 'number'
      ? type
      : cloneElement(type, {
          height: '1em',
          width: '1em',
        }),
  role: onClick ? 'button' : null,
}))`
  ${border};
  ${color};
  ${display};
  align-items: center;
  direction: ltr;
  font-family: ${themeGet('fonts.sansSerif')};
  font-size: ${props => themeGet(`sizes.icon.${props.size}`)(props)}px;
  font-style: normal;
  font-weight: ${themeGet('fontWeights.light')};
  justify-content: center;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-font-smoothing: antialiased;
  ${props => {
    const size = themeGet(`sizes.icon.${props.boxSize || props.size}`)(props);
    return `
      max-height: ${size}px;
      min-height: ${size}px;
      max-width: ${size}px;
      min-width: ${size}px;
    `;
  }}
  ${props => (props.onClick || props.interactive) && interactiveStyles};
  ${props => props.active && activeStyles};
`;

Icon.defaultProps = {
  blacklist: extendTagBlacklist([
    ...border.propNames,
    ...color.propNames,
    'active',
    'boxSize',
    'interactive',
    'type',
  ]),
  backgroundColor: null,
  borderColor: 'black',
  border: null,
  borderRadius: '50%',
  boxSize: null,
  color: null,
  display: 'inline-flex',
  size: 'medium',
  interactive: false,
};

Icon.displayName = 'Icon';

export default Icon;
