import { matchPath } from 'react-router-dom';
import isFunction from 'lodash/isFunction';
import * as pathsMetaData from './paths-meta-data';

/* eslint-disable no-param-reassign */
const paths = Object.keys(pathsMetaData).reduce((curr, pathKey) => {
  const pathMetaData = pathsMetaData[pathKey];
  if (pathMetaData.title) {
    // the reason to use name is avoid conflicts with document.title
    throw new Error(
      `${pathKey} is using "title" property instead of using "name".`,
    );
  }
  curr[`${pathKey}_PATH`] = pathsMetaData[pathKey].path;
  return curr;
}, {});

export const pathMappedMetaData = Object.values(pathsMetaData).reduce(
  (curr, next) => {
    curr[next.path] = next;
    return curr;
  },
  {},
);
/* eslint-enable no-param-reassign */

export const getPathMetaData = pathname => {
  const pathnamewithoutPublicUrl =
    !process.env.PUBLIC_URL || process.env.PUBLIC_URL === '/'
      ? pathname
      : pathname.replace(process.env.PUBLIC_URL, '');
  let matchedObj;
  const matchedPath = Object.keys(pathMappedMetaData).find(key => {
    matchedObj = matchPath(pathnamewithoutPublicUrl, {
      path: key,
      exact: true,
    });
    return matchedObj;
  });
  const metaData = pathMappedMetaData[matchedPath] || {};

  const scope = isFunction(metaData.scope)
    ? metaData.scope(matchedObj.params)
    : metaData.scope;

  return { ...matchedObj, ...metaData, scope };
};

export default paths;
