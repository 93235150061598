import React from 'react';
import PropTypes from 'prop-types';
import Keyboardist from 'react-keyboardist';
import { TrackerEvent, TrackerScope } from '@picter/tracker';
import { Close as CloseSvg } from '@styled-icons/material';
import { useLocation, useParams } from 'react-router-dom';

import { get } from 'src/utils/accessors';
import { getCommentFilter } from 'src/utils/accessors/comment';
import { Box, Icon, Panel } from 'src/modules/prisma';
import { CommentFormWithLiteUserSupport } from 'src/components/CommentInputForm';
import SidebarLayout from 'src/components/SidebarLayout';
import { useCurrentUser } from 'src/hooks/use-resource';
import { useSubscribeToLocationState } from 'src/hooks/use-subscribe-to-location-state';
import MentionableUsersProvider from 'src/components/MentionableUsersProvider';

import CommentsEmpty from './components/CommentsEmpty';
import CommentsLoader from './containers/CommentsLoader';
import CommentsRenderer from './components/CommentsRenderer';
import CommentsFilterChooser from './components/CommentsFilterChooser';
import useHandlers from './hooks/use-handlers';

function ProjectCommentsContextSidebar({ projectId, projectPublicUrlKey }) {
  const user = useCurrentUser();
  const { commentId } = useParams();

  const {
    handleCloseSidebar,
    handleCreateComment,
    handleInputFocus,
  } = useHandlers({
    projectId,
    projectPublicUrlKey,
    user,
  });

  // subscribes to location state and creates a comment
  useSubscribeToLocationState(
    comment => {
      if (
        comment &&
        !user.isEmpty() &&
        !get(user, 'attributes.liteAccountEmail')
      ) {
        handleCreateComment({ ...comment, commentId });
      }
    },
    ['comment'],
  );

  return (
    <TrackerScope name="Comments">
      <SidebarLayout.ContextSidebar openOnMount closeOnUnmount>
        <MentionableUsersProvider
          projectId={projectId}
          projectPublicUrlKey={projectPublicUrlKey}
        >
          <CommentsSidebar
            projectId={projectId}
            onClickClose={handleCloseSidebar}
          />
          <SidebarLayout.ContextSidebar.Bottom flex="0 1">
            <Box data-intercom-target="regular-comment-form" m={1} mt={0}>
              <CommentFormWithLiteUserSupport
                onSubmit={handleCreateComment}
                onFocus={handleInputFocus}
                enableReinitialize
              />
            </Box>
          </SidebarLayout.ContextSidebar.Bottom>
        </MentionableUsersProvider>
      </SidebarLayout.ContextSidebar>
    </TrackerScope>
  );
}

ProjectCommentsContextSidebar.propTypes = {
  projectId: PropTypes.string.isRequired,
  projectPublicUrlKey: PropTypes.string.isRequired,
};

export default ProjectCommentsContextSidebar;

function CommentsSidebar({ projectId, onClickClose }) {
  const { collectionId, imageId } = useParams();
  const { query } = useLocation();

  return (
    <>
      <Keyboardist
        bindings={{
          Escape: onClickClose,
        }}
      />
      <SidebarLayout.ContextSidebar.Top flex="0 1 50px">
        <Panel
          alignItems="center"
          borderBottom="1px solid"
          borderBottomColor="grey.200"
          display="flex"
          justifyContent="space-between"
          p={4}
        >
          <CommentsFilterChooser />
          <TrackerEvent name="Close" trackClick>
            <Icon
              backgroundColor="white"
              boxSize="medium"
              color="grey.600"
              onClick={onClickClose}
              size="small"
              type={<CloseSvg />}
            />
          </TrackerEvent>
        </Panel>
      </SidebarLayout.ContextSidebar.Top>
      <SidebarLayout.ContextSidebar.Content>
        {projectId && (
          <CommentsLoader
            collectionId={collectionId}
            imageId={imageId}
            projectId={projectId}
            filter={getCommentFilter(query)}
          >
            {comments =>
              comments.size > 0 ? (
                <CommentsRenderer comments={comments} />
              ) : (
                <CommentsEmpty filter={getCommentFilter(query)} />
              )
            }
          </CommentsLoader>
        )}
      </SidebarLayout.ContextSidebar.Content>
    </>
  );
}

CommentsSidebar.propTypes = {
  projectId: PropTypes.string.isRequired,
  onClickClose: PropTypes.func.isRequired,
};
