import { useMemo, useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';

const initialState = {
  autoSaveRequest: false,
  autoSaveSuccess: false,
  autoSaveError: false,
  error: null,
};

const useAutoSave = (formikState, handleSubmit) => {
  const [state, setState] = useState(initialState);
  const [oldValues, setOldValues] = useState(null);

  const commit = useMemo(
    () =>
      debounce(async (currentFormikState, currentState) => {
        if (currentFormikState.isValid) {
          setState({
            ...currentState,
            autoSaveRequest: true,
            autoSaveSuccess: false,
            autoSaveError: false,
          });
          try {
            await handleSubmit(currentFormikState.values, currentFormikState);
            setState({
              ...currentState,
              autoSaveRequest: false,
              autoSaveSuccess: true,
            });
          } catch (error) {
            setState({
              ...currentState,
              autoSaveRequest: false,
              autoSaveError: true,
              error,
            });
          }
          setOldValues({ ...currentFormikState.values });
        }
      }, 1000),
    [handleSubmit],
  );

  const { values, isValid, dirty } = formikState;

  useEffect(() => {
    if (dirty && !isEqual(oldValues, values)) {
      commit(formikState, state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, isValid]);

  return state;
};

export default useAutoSave;
