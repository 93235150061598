import React from 'react';
import {
  MoveToInbox as MoveToInboxSvg,
  DesktopWindows as DesktopWindowsSvg,
  DoneAll as DoneAllSvg,
  Group as GroupSvg,
} from '@styled-icons/material';

import { publicLinkTypes } from 'src/utils/accessors/project';

import { defineMessages } from 'react-intl';

const messages = defineMessages({
  messageReviewLinkTooltip: {
    id: 'SearchModal.messageReviewLinkTooltip',
    defaultMessage: 'Accessible by review link',
  },
  messagePresentLinkTooltip: {
    id: 'SearchModal.messagePresentLinkTooltip',
    defaultMessage: 'Accessible by presentation link',
  },
  messageRequestFilesLinkTooltip: {
    id: 'SearchModal.messageLinkTooltip',
    defaultMessage: 'Accessible by inbox link',
  },
  messageGuestsTooltip: {
    id: 'SearchModal.messageGuestsTooltip',
    defaultMessage: 'Accessible by guests',
  },
});

export const publicLinkMessageMap = {
  [publicLinkTypes.REVIEW]: messages.messageReviewLinkTooltip,
  [publicLinkTypes.PRESENT]: messages.messagePresentLinkTooltip,
  [publicLinkTypes.REQUEST_FILES]: messages.messageRequestFilesLinkTooltip,
  guests: messages.messageGuestsTooltip,
};

export const publicLinkIconMap = {
  [publicLinkTypes.REVIEW]: <DoneAllSvg />,
  [publicLinkTypes.PRESENT]: <DesktopWindowsSvg />,
  [publicLinkTypes.REQUEST_FILES]: <MoveToInboxSvg />,
  guests: <GroupSvg />,
};
