import styled, { css } from 'styled-components/macro';
import tag from 'clean-tag';
import { color } from 'styled-system';
import { transitions } from 'polished';
import { extendTagBlacklist, transition, variants } from '@picter/prisma';

const mode = variants('mode', {
  comfortable: css`
    border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
    padding: ${props => props.theme.space[6]}px 0
      ${props => props.theme.space[3]}px 0;
    margin: 0 ${props => props.theme.space[7]}px;
  `,
  compact: css`
    border-bottom: 1px solid transparent;
    box-shadow: ${props => props.theme.shadows.small};
    padding: ${props => props.theme.space[3]}px
      ${props => props.theme.space[4]}px;
  `,
});

const GeneralLayoutTopSection = styled(tag.header)`
  ${color};
  ${mode};
  align-items: center;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  will-change: margin, padding, border-bottom, box-shadow;
  z-index: 150;
  ${transitions(
    transition({ property: 'margin' }),
    transition({ property: 'padding' }),
    transition({ property: 'border-bottom' }),
    transition({ property: 'box-shadow' }),
  )};
`;

GeneralLayoutTopSection.defaultProps = {
  blacklist: extendTagBlacklist('mode'),
  bg: 'grey.100',
};

export default GeneralLayoutTopSection;
