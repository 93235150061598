import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { A } from '@picter/prisma';
import { Link } from 'react-router-dom';
import urlRegex from 'url-regex';
import useTheme from 'src/hooks/use-theme';

const mentionRegex = /@\[[^\]]+\]\([^)]+\)/;
const lookAheadUrlRegexSource = `(${urlRegex({ strict: false }).source})|(${
  mentionRegex.source
})`;
const exactLinkUrlRegex = urlRegex({ exact: true, strict: false });

const LinkElement = ({ children }) => {
  const external = !children.includes(window.location.host);
  if (external) {
    const hasProtocol = children.includes('https') || children.includes('http');
    return (
      <A
        href={hasProtocol ? children : `http://${children}`}
        target="_blank"
        style={{ fontWeight: 'inherit', fontStyle: 'inherit' }}
      >
        {children}
      </A>
    );
  }

  const [, to] = children.split(window.location.host);
  return (
    <Link to={to}>
      <A style={{ fontWeight: 'inherit', fontStyle: 'inherit' }}>{children}</A>
    </Link>
  );
};

LinkElement.propTypes = {
  children: PropTypes.string.isRequired,
};

const MentionElement = ({ children }) => {
  const theme = useTheme();
  const matches = children.match(/\[(.*?)\]/);
  const name = matches[1];
  return <span style={{ color: theme.colors.primary }}>@{name}</span>;
};

MentionElement.propTypes = {
  children: PropTypes.string.isRequired,
};

const replacersMap = {
  // eslint-disable-next-line react/prop-types
  link: LinkElement,
  mention: MentionElement,
};

const parseStringChunk = string => {
  let parsedString = string;

  if (!parsedString) return parsedString;

  const link = exactLinkUrlRegex.test(parsedString);
  if (link) {
    parsedString = <replacersMap.link>{parsedString}</replacersMap.link>;
    return parsedString;
  }

  const mention = mentionRegex.test(parsedString);
  if (mention) {
    parsedString = <replacersMap.mention>{parsedString}</replacersMap.mention>;
    return parsedString;
  }

  return parsedString;
};

const PicterMarkdownRenderer = ({ source }) => {
  const splitSource = useMemo(
    () => source.split(new RegExp(lookAheadUrlRegexSource, 'g')),
    [source],
  );

  const replacedSourceArray = useMemo(() => splitSource.map(parseStringChunk), [
    splitSource,
  ]);

  return replacedSourceArray;
};

export default PicterMarkdownRenderer;
