import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { List, fromJS } from 'immutable';
import { ButtonElement as Button, Modal, Scrollbar } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';

import { Box } from 'src/modules/prisma';
import { get } from 'src/utils/accessors';

import messages from './messages';

export { default as ChooserItem } from './components/ChooserItem';

export const chooserPropsShape = PropTypes.shape({
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
});

export default function EntityChooser({
  open,
  onClose,
  title,
  items,
  SearchComponent,
  ItemComponent,
  CreateItemComponent,
  createNewItem,
  SubmitLabel,
  onSubmit,
  extraOptions,
}) {
  const [selected, setSelected] = useState(null);
  const [shouldCreateNewItem, setShouldCreateNewItem] = useState(false);

  const selectionProps = useCallback(
    value => ({
      selected: selected === value,
      onClick: () => {
        setShouldCreateNewItem(value === 'create-new-item');
        setSelected(value);
      },
    }),
    [selected, setSelected],
  );

  const handleSubmit = useCallback(async () => {
    if (shouldCreateNewItem) {
      const newItem = await createNewItem();
      await onSubmit(fromJS(newItem));
      return onClose();
    }

    const selectedItem = items.find(item => get(item, 'id') === selected);

    await onSubmit(selectedItem);
    return onClose();
  }, [shouldCreateNewItem, items, onSubmit, onClose, createNewItem, selected]);

  return (
    <Modal open={open} onClickClose={onClose} title={title}>
      {SearchComponent}
      <Scrollbar maxHeight="300px">
        {extraOptions &&
          extraOptions.map(extraOption =>
            React.cloneElement(extraOption.component, {
              chooserProps: selectionProps(extraOption.value),
            }),
          )}
        {items.map(item =>
          React.cloneElement(ItemComponent, {
            item,
            chooserProps: selectionProps(get(item, 'id')),
          }),
        )}
      </Scrollbar>
      {React.cloneElement(CreateItemComponent, {
        chooserProps: selectionProps('create-new-item'),
      })}
      <Box display="flex" mt={6} mb={1} justifyContent="flex-end">
        <Button
          color="grey.600"
          onClick={onClose}
          px={0}
          py={0}
          textStyle="action.regular"
          variant="flat"
        >
          <FormattedMessage {...messages.labelCancel} />
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}
          px={0}
          py={0}
          ml={6}
          textStyle="action.regular"
          variant="flat"
        >
          {SubmitLabel}
        </Button>
      </Box>
    </Modal>
  );
}

EntityChooser.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  items: PropTypes.instanceOf(List).isRequired,
  extraOptions: PropTypes.arrayOf({
    component: PropTypes.element.isRequired,
    value: PropTypes.string.isRequired,
  }),
  SearchComponent: PropTypes.element,
  ItemComponent: PropTypes.element.isRequired,
  CreateItemComponent: PropTypes.element.isRequired,
  createNewItem: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  SubmitLabel: PropTypes.element.isRequired,
};

EntityChooser.defaultProps = {
  open: false,
  onClose: undefined,
  title: undefined,
  SearchComponent: null,
  extraOptions: undefined,
};
