import { useMemo } from 'react';
import camelCase from 'lodash/camelCase';

import capitalize from 'src/utils/capitalize';
import { useToriiActions } from 'src/modules/torii';
import { get } from 'src/utils/accessors';

const useUpdateResourceFieldHandler = ({
  field = '',
  resource,
  querySerializers,
}) => {
  const { update } = useToriiActions();
  const handlers = useMemo(
    () => ({
      [`update${capitalize(camelCase(field))}`]: values =>
        update(get(resource, 'type'), {
          id: get(resource, 'id'),
          attributes: values,
          querySerializers,
        }),
      update: values =>
        update(get(resource, 'type'), {
          id: get(resource, 'id'),
          attributes: values,
          querySerializers,
        }),
    }),
    [field, resource, querySerializers, update],
  );

  return handlers;
};

export default useUpdateResourceFieldHandler;
