import styled from 'styled-components/macro';

export default styled.div`
  position: relative;
  > #unread-counter {
    cursor: default;
    position: absolute;
    top: -4px;
    right: -1px;
    font-size: 12px;
    color: white;
    background-color: #fb5c5c;
    border-radius: 50%;
    min-width: 14px;
    min-height: 14px;
    text-align: center;
    padding-right: 2px;
    padding-left: 2px;
  }
`;
