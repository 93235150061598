import {
  logout as logoutRequest,
  signup as signupRequest,
  login as loginRequest,
  checkAuth as checkAuthRequest,
} from './requests';

import {
  LOGIN_SUCCESS,
  SIGNUP_SUCCESS,
  CHECK_USER_SUCCESS,
  LOGOUT_SUCCESS,
  LOGIN_ERROR,
  SIGNUP_ERROR,
  CHECK_USER_ERROR,
  LOGOUT_ERROR,
} from './constants';

export const checkAuth = ({
  endpointHost,
  onSuccess = () => null,
  onFailure = () => null,
  ...rest
}) => dispatch =>
  checkAuthRequest({
    endpointHost,
    onSuccess: data => {
      dispatch({ type: CHECK_USER_SUCCESS, payload: data });
      onSuccess();
    },
    onFailure: res => {
      dispatch({ type: CHECK_USER_ERROR });
      onFailure(res);
    },
    ...rest,
  });

export const localLogin = data => dispatch =>
  dispatch({ type: LOGIN_SUCCESS, payload: data });

export const login = ({
  endpointHost,
  onSuccess = () => null,
  onFailure = () => null,
  ...rest
}) => dispatch =>
  loginRequest({
    endpointHost,
    onSuccess: data => {
      dispatch({ type: LOGIN_SUCCESS, payload: data });
      onSuccess();
    },
    onFailure: res => {
      dispatch({ type: LOGIN_ERROR });
      onFailure(res);
    },
    ...rest,
  });

export const signup = ({
  endpointHost,
  onSuccess = () => null,
  onFailure = () => null,
  ...rest
}) => dispatch =>
  signupRequest({
    endpointHost,
    onSuccess: data => {
      dispatch({ type: SIGNUP_SUCCESS, payload: data });
      onSuccess(data);
    },
    onFailure: res => {
      dispatch({ type: SIGNUP_ERROR });
      onFailure(res);
    },
    ...rest,
  });

export const logout = ({
  endpointHost,
  onSuccess = () => null,
  onFailure = () => null,
  ...rest
}) => dispatch =>
  logoutRequest({
    endpointHost,
    onSuccess: () => {
      dispatch({ type: LOGOUT_SUCCESS });
      onSuccess();
    },
    onFailure: () => {
      dispatch({ type: LOGOUT_ERROR });
      onFailure();
    },
    ...rest,
  });
