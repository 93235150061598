import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Action, Tooltip } from 'src/modules/prisma';

function pad(toPad: string | number) {
  return `0${toPad}`.slice(-2);
}

function format(seconds: number) {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }
  return `${mm}:${ss}`;
}

export type DurationControlProps = {
  duration: number;
  played: number;
};

export default function DurationControl({
  duration,
  played,
}: DurationControlProps) {
  const [remaining, setRemaining] = useState(true);
  const seconds = remaining ? duration * (1 - played) : duration * played;
  const time = format(seconds);
  const minWidth = ({
    3: '46px',
    4: '52px',
    5: '72px',
  } as { [key: number]: string })[time.length];

  return (
    // @ts-ignore
    <Tooltip showDelay={500}>
      {/* @ts-ignore */}
      <Tooltip.Reference>
        {/* @ts-ignore */}
        <Action
          onClick={() => setRemaining(r => !r)}
          // @ts-ignore
          justifyContent="center"
          minWidth={minWidth}
        >
          {remaining ? '-' : ''}
          <time dateTime={`P${Math.round(duration)}S`}>{time}</time>
        </Action>
        {/* @ts-ignore */}
      </Tooltip.Reference>
      {/* @ts-ignore */}
      <Tooltip.Message>
        {remaining ? (
          <FormattedMessage
            id="DurationControl.labelRemaining"
            defaultMessage="Remaining time"
          />
        ) : (
          <FormattedMessage
            id="DurationControl.labelElapsed"
            defaultMessage="Elapsed time"
          />
        )}
        {/* @ts-ignore */}
      </Tooltip.Message>
    </Tooltip>
  );
}
