import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@picter/prisma';
import { withTheme } from 'styled-components/macro';

import { themeShape } from 'src/utils/app-prop-types';

import ProofIconWrapper from '../styles/ProofIconWrapper';

const ProofFieldApprovedButton = ({
  name,
  active,
  theme,
  bgOpacityColor,
  ...props
}) => (
  <ProofIconWrapper className="approve-wrapper" bgOpacityColor={bgOpacityColor}>
    <Icon
      id={`true-${name}`}
      color={active ? 'success' : 'muted'}
      active={active}
      backgroundColor={active ? 'success' : null}
      borderColor={active ? 'success' : theme.colors.gray.light}
      interactiveColor={active ? 'success' : 'muted'}
      {...props}
    />
  </ProofIconWrapper>
);

ProofFieldApprovedButton.propTypes = {
  name: PropTypes.string.isRequired,
  bgOpacityColor: PropTypes.string,
  active: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  theme: themeShape.isRequired,
};

ProofFieldApprovedButton.defaultProps = {
  bgOpacityColor: null,
};

export default withTheme(ProofFieldApprovedButton);
