import { defineMessages } from 'react-intl';

export default defineMessages({
  labelNewProject: {
    id: 'SpacePage.labelNewProject',
    defaultMessage: 'New project',
  },
  labelNewFolder: {
    id: 'SpacePage.labelNewFolder',
    defaultMessage: 'New folder',
  },
  messageEmptyDashboardTitle: {
    id: 'SpacePage.messageEmptyDashboardTitle',
    defaultMessage: `Hi {name, select,
      undefined {there}
      other {{name}}
    }, are you ready to create your first project?`,
  },
  messageEmptyDashboardText: {
    id: 'SpacePage.messageEmptyDashboardText',
    defaultMessage:
      'Upload images, videos and documents now to see just how sleek and simple content review can be!',
  },

  /**
   * TrialExpiredPage
   */
  titleExpired: {
    id: 'TrialExpiredPage.titleExpired',
    defaultMessage: 'Your trial has expired.',
  },
  messageExpired: {
    id: 'TrialExpiredPage.messageExpired',
    defaultMessage:
      'Subscribe to one of our paid plans to continue using Picter.',
  },

  /**
   * SubscriptionPausedPage
   */
  titleSubscriptionPaused: {
    id: 'SubscriptionPausedPage.titleSubscriptionPaused',
    defaultMessage: 'Your subscription is paused',
  },
  messageSubscriptionPaused: {
    id: 'SubscriptionPausedPage.messageSubscriptionResume',
    defaultMessage: 'Resume now to continue using Picter',
  },

  /**
   * SubscriptionExpiredPage
   */
  titleSubscriptionExpired: {
    id: 'SubscriptionExpiredPage.titleSubscriptionExpired',
    defaultMessage: 'Your subscription has expired',
  },
  messageSubscriptionExpired: {
    id: 'SubscriptionExpiredPage.messageSubscriptionExpired',
    defaultMessage:
      'Your Picter workspace including all data will be deleted 7 days after expiration.',
  },

  labelManageSubscription: {
    id: 'TrialExpiredPage.SubscriptionPausedPage.labelManageSubscription',
    defaultMessage: 'Manage subscription',
  },

  /**
   * SpaceSortChooser
   */
  titleSpaceSortChooser: {
    id: 'SpaceSortChooser.titleSpaceSortChooser',
    defaultMessage: 'Sort by',
  },
  labelCreatedAtSort: {
    id: 'SpaceSortChooser.labelCreatedAtSort',
    defaultMessage: 'Created at',
  },
  labelUpdatedAtSort: {
    id: 'SpaceSortChooser.labelUpdatedAtSort',
    defaultMessage: 'Modified at',
  },
  labelTitleSort: {
    id: 'SpaceSortChooser.labelTitleSort',
    defaultMessage: 'Name',
  },
});
