import ItemsBox from './components/Box';
import Icon from './styles/BoxIcon';
import Item from './components/Item';
import List from './styles/BoxList';
import Title from './styles/BoxTitle';
import Text from './styles/BoxText';

ItemsBox.displayName = 'ItemsBox';

ItemsBox.Icon = Icon;
ItemsBox.Icon.displayName = 'ItemsBox.Icon';

ItemsBox.Item = Item;
ItemsBox.Item.displayName = 'ItemsBox.Item';

ItemsBox.List = List;
ItemsBox.List.displayName = 'ItemsBox.List';

ItemsBox.Title = Title;
ItemsBox.Title.displayName = 'ItemsBox.Title';

ItemsBox.Text = Text;
ItemsBox.Text.displayName = 'ItemsBox.Text';

export default ItemsBox;
