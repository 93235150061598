import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import VirtualizedAutoSizer from 'react-virtualized-auto-sizer';
import throttle from 'lodash/throttle';

export default function AutoSizer({
  children,
  throttleHeight,
  throttleWidth,
  ...props
}) {
  const [height, setHeight] = useState(600);
  const [width, setWidth] = useState(600);

  const setHeightThrottled = useCallback(throttle(setHeight, 500), [setHeight]);
  const setWidthThrottled = useCallback(throttle(setWidth, 500), [setWidth]);

  const child = useMemo(() => children({ height, width }), [
    children,
    height,
    width,
  ]);

  const renderChildren = useCallback(
    dimensions => {
      if (throttleHeight) setHeightThrottled(dimensions.height);
      else setHeight(dimensions.height);

      if (throttleWidth) setWidthThrottled(dimensions.width);
      else setWidth(dimensions.width);

      return child;
    },
    [
      child,
      setHeight,
      setWidth,
      setHeightThrottled,
      setWidthThrottled,
      throttleHeight,
      throttleWidth,
    ],
  );

  return (
    <VirtualizedAutoSizer {...props}>{renderChildren}</VirtualizedAutoSizer>
  );
}

AutoSizer.defaultProps = {
  throttleHeight: false,
  throttleWidth: false,
};

AutoSizer.propTypes = {
  children: PropTypes.func.isRequired,
  throttleHeight: PropTypes.bool,
  throttleWidth: PropTypes.bool,
};
