import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@rebass/grid';

import { useDynamicSizeItemMeasurer } from 'src/components/DynamicSizeList';

import ActivityStreamItem from './ActivityStreamItem';

const Row = ({ data, index, style, forwardedRef }) => {
  const root = useDynamicSizeItemMeasurer(index);
  const { data: item, extraStyle } = data[index];

  return (
    <Box
      key={item.group}
      ref={forwardedRef}
      style={{ ...style, ...extraStyle }}
    >
      <div ref={root}>
        <ActivityStreamItem data={item} />
      </div>
    </Box>
  );
};

Row.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  index: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
  forwardedRef: PropTypes.func.isRequired,
};

const RefForwardedRow = React.forwardRef((props, ref) => (
  <Row {...props} forwardedRef={ref} />
));

export default RefForwardedRow;
