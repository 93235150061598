import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';
import { object, string } from 'yup';
import isEqual from 'lodash/isEqual';
import { Map } from 'immutable';

import { Box } from 'src/modules/prisma';
import FormSection from 'src/components/FormSection';
import { get } from 'src/utils/accessors';
import useFormik from 'src/hooks/use-formik';
import { useToriiActions } from 'src/modules/torii';
import usePermission from 'src/hooks/use-permission';
import withSuspenseLoader from 'src/hocs/with-suspense-loader';

import messages from '../messages';

const Status = Object.freeze({
  NONE: 'status__none',
  FAILED: 'status__failed',
  SAVED: 'status__saved',
});

function InfoSection({ project }) {
  const { update } = useToriiActions();
  const [status, setStatus] = useState(Status.NONE);
  const { canEditProject } = usePermission();

  const initialValues = useMemo(
    () => ({
      title: get(project, 'attributes.title'),
      description: get(project, 'attributes.description'),
    }),
    [project],
  );

  const {
    errors,
    getFieldProps,
    handleSubmit,
    isSubmitting,
    touched,
  } = useFormik({
    initialValues,
    onSubmit(values) {
      if (isEqual(values, initialValues)) return Promise.resolve();

      return update('wsProjects', {
        id: get(project, 'id'),
        attributes: values,
      })
        .then(() => setStatus(Status.SAVED))
        .catch(() => setStatus(Status.FAILED));
    },
    submitOnChange: true,
    submitOnChangeDelay: 1000,
    validationSchema: object({
      title: string().label('Title'),
      description: string().label('Description'),
    }),
  });

  return (
    <FormSection minHeight="100%">
      {!isSubmitting && status === Status.FAILED && (
        <FormSection.ErrorIndicator />
      )}
      {isSubmitting && <FormSection.SavingIndicator />}
      {!isSubmitting && status === Status.SAVED && (
        <FormSection.SavedIndicator />
      )}
      <FormSection.Fieldset>
        <FormSection.Content>
          <Box m="auto" maxWidth={650} width={1}>
            <form onSubmit={handleSubmit}>
              <TextField
                {...getFieldProps('title')}
                error={touched.title ? errors.title : undefined}
                id="title"
                label={<FormattedMessage {...messages.labelTitle} />}
                type="text"
                disabled={!canEditProject}
                showBadge={false}
              />
              <TextField
                {...getFieldProps('description')}
                error={touched.description ? errors.description : undefined}
                id="description"
                label={<FormattedMessage {...messages.labelDescription} />}
                textarea={{ minRows: 6 }}
                type="textarea"
                disabled={!canEditProject}
                showBadge={false}
              />
            </form>
          </Box>
        </FormSection.Content>
      </FormSection.Fieldset>
    </FormSection>
  );
}

InfoSection.propTypes = {
  project: PropTypes.instanceOf(Map).isRequired,
};

export default withSuspenseLoader({
  ErrorComponent: null,
  LoadingComponent: null,
})(InfoSection);
