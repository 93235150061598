import styled, { keyframes } from 'styled-components/macro';
import { color } from 'styled-system';

const progress = size => keyframes`
  from {
    width: 0;
  }

  to {
    width: ${size}%;
  }
`;

const ProgressBar = styled.div`
  ${color};
  animation: ${props => progress(props.size)} 0.6s ease;
  display: flex;
  transition: width 0.6s ease;
  width: ${props => props.size}%;
`;

ProgressBar.defaultProps = {
  color: 'primary',
};

export default ProgressBar;
