const ACTIONS_TYPES = {
  SET_INITIAL_ACTIVITIES: 'SET_INITIAL_ACTIVITIES',
  SET_OLDER_ACTIVITIES: 'SET_MORE_ACTIVITIES',
  SET_NEWER_ACTIVITIES: 'SET_NEWER_ACTIVITIES',
  SET_ACTIVITY_AS_READ: 'SET_ACTIVITY_AS_READ',
  SET_ACTIVITIES_AS_READ: 'SET_ACTIVITIES_AS_READ',
  SET_ACTIVITIES_AS_SEEN: 'SET_ACTIVITIES_AS_SEEN',
};

export default ACTIONS_TYPES;
