import React from 'react';
import styled, { css, keyframes } from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';
import { transparentize, transitions } from 'polished';
import { MentionsInput as ReactMentionsMentionsInput } from 'react-mentions';
import { transition } from '@picter/prisma';
import { textStyle, typography } from 'src/modules/prisma';
import omit from 'lodash/omit';

const animationSettings = property => ({
  duration: '200ms',
  property,
});

const glow = color => keyframes`
  0% {
    box-shadow:
      0 0 5px ${transparentize(0.8, color)},
      inset 0 0 5px ${transparentize(0.9, color)},
      0 0 6px 0 rgba(104,0,195,0.2);
  }
  100% {
    box-shadow:
      0 0 20px ${transparentize(0.4, color)},
      inset 0 0 10px ${transparentize(0.6, color)},
      0 0 6px 0 rgba(104,0,195,0.2);
  }
`;

const glowOnFocusAnimation = ({ glowOnFocus }) =>
  glowOnFocus
    ? css`
        &:focus {
          animation: ${props => glow(themeGet('colors.primary')(props))} 800ms
            ease-out infinite alternate;
        }
      `
    : null;

const SPACING_SIZE = 16;
const LINE_HEIGHT = 24;

const MentionsInput = styled(({ className, ...props }) => {
  /**
   * Styled-components v5 for some reason changed the order of the classes it
   * passes to extended components. Now the order is:
   *
   * [<SCComponentClass>, <SCComponentHashClass>, ...<UserInputClass>]
   * ["MentionsInput-dBdwd-0", "oBrsft", "mentions-container"]
   *
   * Since `react-mentions/MentionsInput` takes into account just the first
   * class to build the structure of its components there's a need to put
   * the extendable class in first place.
   */
  const [
    styledComponentClass,
    styledComponentsHashClass,
    ...userClass
  ] = className.split(' ');
  const parsedClass = [
    styledComponentsHashClass,
    styledComponentClass,
    ...userClass,
  ].join(' ');

  // Avoid React errors about props in HTML elements
  const sanitizedProps = omit(props, [
    'autoFocus',
    'glowOnFocus',
    'maxRows',
    'noMargin',
    'textStyle',
  ]);

  return (
    <ReactMentionsMentionsInput
      className={parsedClass}
      {...sanitizedProps}
      // The highlighter needs overflow 'auto', otherwise
      // it won't have scrollbars on overflow and thus
      // the textarea will have a different width compared
      // to the highlighter overlay. This is not an issue, when
      // your scrollbars don't have their own width (e.g. in Mac OS
      // where you can show scrollbars only while scrolling). But as
      // soon as scrollbars have a width, hiding them in the highlighter
      // breaks the sync between input and highlighter.
      style={{ highlighter: { overflow: 'auto' } }}
    />
  );
})`
  ${textStyle};
  ${typography};
  width: 100%;
  min-height: 42px;
  border-radius: 2px;
  background-color: ${themeGet('colors.grey.100')};
  :focus-within {
    background-color: ${themeGet('colors.white')} !important;
  }

  &__control {
    overflow-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
  }

  &__highlighter,
  &__input {
    ${props =>
      props.maxRows
        ? `max-height: ${SPACING_SIZE + LINE_HEIGHT * props.maxRows}px;`
        : ''}
    padding: ${themeGet('space.2')}px 60px ${themeGet('space.2')}px
        ${themeGet('space.4')}px;
  }

  &__suggestions > ul {
    box-shadow: ${props => props.theme.shadows.soft};
    max-height: 400px;
    overflow: auto;
  }

  &__highlighter {
    background-color: inherit;
    border: 1px transparent solid;
    font-weight: inherit;
    line-height: inherit;
  }

  &__highlighter__substring {
    visibility: visible !important;
    color: ${themeGet('colors.black')};
  }

  &__input {
    ${glowOnFocusAnimation};
    border-color: ${themeGet('colors.white')};
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    color: transparent;
    caret-color: ${themeGet('colors.black')};
    font-weight: inherit;
    line-height: inherit;
    overflow: auto !important;
    ${transitions(
      transition(animationSettings('color')),
      transition(animationSettings('background-color')),
      transition(animationSettings('border-color')),
      transition(animationSettings('padding-left')),
    )};

    &::placeholder {
      color: ${themeGet('colors.grey.600')};
    }

    &:hover {
      border-color: ${themeGet('colors.grey.300')};
    }

    &:focus {
      border-color: ${themeGet('colors.primary')};
      box-shadow: 0 0 6px 0
        ${props => transparentize(0.8, props.theme.colors.primary)};
    }
  }
`;

MentionsInput.defaultProps = {
  fontFamily: 'sansSerif',
  textStyle: 'body.regular',
};

export default MentionsInput;
