import { css } from 'styled-components/macro';
import { transparentize } from 'polished';

export const COLOR_SCHEMES_MAP = {
  requirements: css`
    background-color: ${props =>
      transparentize(0.98, props.theme.colors.primary)};
    color: ${props => props.theme.colors.primary};
  `,
  errors: css`
    background-color: ${props =>
      transparentize(0.98, props.theme.colors.error.default)};
    color: ${props => props.theme.colors.error.default};
  `,
};
export const COLOR_SCHEME_OPTIONS = Object.keys(COLOR_SCHEMES_MAP);
