import React from 'react';
import { Edit as EditSvg } from '@styled-icons/material';
import { FormattedMessage } from 'react-intl';

import ActionsDropdownItem from './ActionsDropdownItem';
import messages from '../messages';

function ActionsDropdownRename(props) {
  return (
    <ActionsDropdownItem
      action="rename"
      icon={<EditSvg />}
      label={<FormattedMessage {...messages.labelRename} />}
      {...props}
    />
  );
}

export default ActionsDropdownRename;
