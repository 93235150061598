import { useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { List } from 'immutable';

import { get } from 'src/utils/accessors';
import useProjectImages from 'src/hooks/use-project-images';
import { projectImagesSizeByFilterSelector } from 'src/selectors/project-image';

const useApprovalFilters = project => {
  const params = useParams();
  const { query } = useLocation();

  const collectionWithImages = get(
    project,
    'relationships.collections',
    List(),
  ).find(col => get(col, 'id') && get(col, 'id') === params.collectionId);

  const projectImages = get(project, 'relationships.images');
  const collectionImages = get(
    collectionWithImages,
    'relationships.images',
    List(),
  ).map(collectionImage => get(collectionImage, 'relationships.image'));

  const mappedCollectionImages = useMemo(() => {
    // First, we create this mapped collection images, it will be useful and explained
    // on the next step
    return collectionImages.reduce((acc, collectionImage) => {
      acc[get(collectionImage, 'id')] = collectionImage;
      return acc;
    }, {});
  }, [collectionImages]);

  const { approvals, approvedImagesCounter } = useMemo(() => {
    let approvedImagesC = 0;
    // Then we use the map to, if the image being counted exists inside the collection
    // we update the value of the approvedImagesCounter (any filter counter)
    const approvalsList = projectImages
      .filter(image => get(image, 'attributes.rejected') !== true)
      .reduce((acc, nextImage) => {
        const imageApprovals = get(nextImage, 'relationships.approvals');

        const isPresentInCollection = params.collectionId
          ? mappedCollectionImages[get(nextImage, 'id')]
          : true;
        if (imageApprovals.size && isPresentInCollection) {
          approvedImagesC += 1;
        }
        return acc.concat(imageApprovals);
      }, List());

    return { approvals: approvalsList, approvedImagesCounter: approvedImagesC };
  }, [mappedCollectionImages, params.collectionId, projectImages]);

  const approvalsUsers = useMemo(() => {
    // Same thing here, we only count the approval if the related image is inside
    // the current collection. This way we keep the counter updated
    // based on user location.
    return Object.values(
      approvals.reduce((acc, nextApproval) => {
        const approvalUserId = get(nextApproval, 'relationships.user.id');
        if (approvalUserId) {
          acc[approvalUserId] = acc[approvalUserId] || {};
          acc[approvalUserId].counter = acc[approvalUserId].counter || 0;
          const isPresentInCollection = params.collectionId
            ? mappedCollectionImages[
                get(nextApproval, 'relationships.image.id')
              ]
            : true;
          if (isPresentInCollection) {
            acc[approvalUserId].counter += 1;
          }
          acc[approvalUserId].user = get(nextApproval, 'relationships.user');
        }
        return acc;
      }, {}),
    );
  }, [approvals, mappedCollectionImages, params.collectionId]);

  const {
    rejectedImagesCounter,
    undecidedImagesCounter,
  } = projectImagesSizeByFilterSelector({
    images: params.collectionId ? collectionImages : projectImages,
  });

  const images = useProjectImages({
    project,
    collection: collectionWithImages,
    filter: query.filter,
  });

  return {
    imagesCounter: projectImages.size,
    filtersImagesCounter: images.size,
    rejectedImagesCounter,
    undecidedImagesCounter,
    approvedImagesCounter,
    approvalsUsers,
  };
};

export default useApprovalFilters;
