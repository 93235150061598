import React from 'react';

export default function ErrorIllustrationSvg() {
  return (
    <svg
      width="200"
      height="231"
      viewBox="0 0 200 231"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M104 116.5L61.5 91.5V139.5L104 116.5Z" fill="white" />
      <path
        d="M104 116.5L61.5 91.4658M31 229.5V73.5L61.5 91.4658M31 229.5L199 134.5V98M31 229.5L1 212V19.5M199 98L61.5 175.5V139.5M199 98L31 2L1 19.5M61.5 91.4658V139.5M61.5 139.5L138.5 98L1 19.5"
        stroke="#5C62CA"
        strokeWidth="2"
        strokeDasharray="15 15"
      />
    </svg>
  );
}
