import { defineMessages } from 'react-intl';

export default defineMessages({
  labelUpdateAvailableTitle: {
    id: 'labelUpdateAvailable',
    defaultMessage: 'Picter just got better',
  },
  labelUpdateAvailableMessage: {
    id: 'labelUpdateAvailableMessage',
    defaultMessage:
      'Please refresh your browser to get the latest version of Picter.',
  },
  labelUpdateAvailableRemindMeLater: {
    id: 'labelUpdateAvailableRemindMeLaterrr',
    defaultMessage: 'Remind me later',
  },
  labelUpdateAvailableReload: {
    id: 'labelUpdateAvailableReload',
    defaultMessage: 'Refresh browser',
  },
});
