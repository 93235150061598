import React, { useEffect, useState, useCallback } from 'react';
import useLocalStorage from '@rehooks/local-storage';

import { Box } from 'src/modules/prisma';
import { get } from 'src/utils/accessors';
import FloatingCommentFormWithLiteUserSupport from 'src/components/CommentsAnnotations/components/FloatingCommentFormWithLiteUserSupport';

import { ANNOTATION_IN_PROGRESS_ID, Annotation } from '../models/Annotation';
import { CanvasInfo, TargetInfo } from '../types';
import { Coordinate, Markup, MarkupType, MarkupColor } from '../models/Markup';
import { useAnnotations } from '../providers/AnnotationsProvider';
import { useZoom } from '../providers/ZoomProvider';
import AnnotationGraphic from './AnnotationGraphic';
import MarkupActions from './MarkupActions';
import MarkupCanvas from './MarkupCanvas';
import MarkupToolbar, { MarkupTool } from './MarkupToolbar';

export default function AnnotationsRenderer({
  children,

  id,

  markupColor = MarkupColor.Carnation,
  markupTool = MarkupTool.Pin,

  renderCanvas = true,
  renderMarkup = true,
  renderToolbar = true,

  canvasHeight,
  canvasWidth,
  targetType,
  targetHeight,
  targetWidth,

  onCreate = () => undefined,
  onDrawing = () => undefined,
  onHighlight = () => undefined,
  onSave = () => undefined,
  onSelect = () => undefined,
}: React.PropsWithChildren<
  {
    id?: string;

    markupColor: MarkupColor;
    markupTool: MarkupTool;

    renderCanvas: boolean;
    renderMarkup: boolean;
    renderToolbar: boolean;

    onCreate: (annotation: Annotation | undefined) => void;
    onDrawing: () => void;
    onHighlight: (annotation: Annotation | undefined) => void;
    onSave: (data: { frame: number; geometry: Markup[]; text: string }) => void;
    onSelect: (annotation: Annotation | undefined) => void;
  } & CanvasInfo &
    TargetInfo
>) {
  const [
    { create, deselect, drawing, remove },
    { annotations, isDrawing, highlightedId, selectedId },
  ] = useAnnotations();
  const [lastPosition, setLastPosition] = useState<Coordinate>();
  const [showInput, setShowInput] = useState<boolean>();
  const { current: zoom } = useZoom();
  const [color, setColor] = useLocalStorage<MarkupColor>(
    id ? `markup-color-${id}` : 'markup-color',
    markupColor,
  );
  const [tool, setTool] = useLocalStorage<MarkupTool>(
    id ? `markup-tool-${id}` : 'markup-tool',
    markupTool,
  );

  useEffect(() => {
    const annotation = annotations.get(ANNOTATION_IN_PROGRESS_ID);
    if (annotation) onCreate(annotation);
    else setShowInput(false);
    // Avoid executing when annotations or the callback changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [annotations.size]);

  useEffect(() => {
    if (highlightedId) {
      const annotation = annotations.get(highlightedId);
      onHighlight(annotation);
    }
    // Avoid executing when annotations or the callback changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedId]);

  useEffect(() => {
    if (selectedId) {
      const annotation = annotations.get(selectedId);
      onSelect(annotation);
    }

    if (
      selectedId !== ANNOTATION_IN_PROGRESS_ID &&
      annotations.has(ANNOTATION_IN_PROGRESS_ID)
    ) {
      remove(ANNOTATION_IN_PROGRESS_ID);
      setShowInput(false);
    }
    // Avoid executing when annotations or the callback changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  const handleDrawing = useCallback(() => {
    onDrawing();
    drawing(true);
  }, [drawing, onDrawing]);

  const handleDraw = useCallback(
    (event: React.MouseEvent | React.TouchEvent, markups: Markup[]) => {
      const rect = event.currentTarget.getBoundingClientRect();
      const markupType = get(markups, '0.type') as MarkupType;
      const isTouchEvent = event.type.match(/touch/gi);
      const pointer = isTouchEvent
        ? (event as React.TouchEvent).changedTouches[0]
        : (event as React.MouseEvent);
      const topEdgeGutter = 105; // try to not hide the input under the header
      const topEdgeDifference =
        pointer.clientY < topEdgeGutter ? topEdgeGutter - pointer.clientY : 0;

      // This offset aligns point markup and the inline comment
      // field vertically centered
      const yOffset = markupType === MarkupType.Point ? 22 : 10;

      setLastPosition([
        ((pointer.clientX + 10 - rect.x) / rect.width) * 100,
        ((pointer.clientY + yOffset + topEdgeDifference - rect.y) /
          rect.height) *
          100,
      ]);

      if (get(markups, '0.type') === 'point') setShowInput(true);

      const annotation = annotations.get(ANNOTATION_IN_PROGRESS_ID);
      if (annotation && markupType !== MarkupType.Point) {
        if (showInput) setShowInput(false);
        create([...annotation.markups, ...markups]);
      } else {
        create(markups);
      }
      drawing(false);
    },
    [annotations, create, drawing, showInput],
  );

  const annotationsElements: React.ReactNode[] = [];

  annotations.forEach(annotation => {
    // Filter in-progress annotation out, to be able
    // to render it separately (e.g. while isDrawing)
    if (annotation.id === ANNOTATION_IN_PROGRESS_ID) {
      return;
    }
    const annotationElement = (
      <AnnotationGraphic
        key={annotation.id}
        annotation={annotation}
        targetHeight={targetHeight}
        targetWidth={targetWidth}
        zoom={zoom}
      />
    );
    annotationsElements.push(annotationElement);
  });

  const inProgressAnnotation = annotations.get(ANNOTATION_IN_PROGRESS_ID);

  return (
    <Box
      alignItems="center"
      display="flex"
      height="100%"
      id={id}
      justifyContent="center"
      position="relative"
      width="100%"
    >
      {children}
      {renderCanvas && (
        <Box position="absolute" height="100%" left={0} top={0} width="100%">
          <MarkupCanvas
            canvasHeight={canvasHeight}
            canvasWidth={canvasWidth}
            color={color}
            targetHeight={targetHeight}
            targetType={targetType}
            targetWidth={targetWidth}
            tool={
              selectedId && selectedId !== ANNOTATION_IN_PROGRESS_ID
                ? undefined
                : tool
            }
            onClick={
              selectedId && selectedId !== ANNOTATION_IN_PROGRESS_ID
                ? deselect
                : undefined
            }
            onDraw={handleDraw}
            onDrawing={handleDrawing}
          >
            {renderMarkup && !isDrawing && <g>{annotationsElements}</g>}
            {renderMarkup && inProgressAnnotation && (
              <AnnotationGraphic
                key={inProgressAnnotation.id}
                annotation={inProgressAnnotation}
                targetHeight={targetHeight}
                targetWidth={targetWidth}
                zoom={zoom}
              />
            )}
          </MarkupCanvas>
          {renderToolbar && (
            <MarkupToolbar
              color={color}
              targetType={targetType}
              tool={tool}
              onSelectColor={setColor}
              onSelectTool={setTool}
            />
          )}
        </Box>
      )}
      {!showInput && !isDrawing && (
        <MarkupActions
          x={lastPosition?.[0]}
          y={lastPosition?.[1]}
          onClickDone={() => setShowInput(true)}
        />
      )}
      {showInput && (
        <FloatingCommentFormWithLiteUserSupport
          onSubmit={onSave}
          x={lastPosition?.[0]}
          y={lastPosition?.[1]}
        />
      )}
    </Box>
  );
}
