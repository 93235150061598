import React from 'react';
import PropTypes from 'prop-types';
import Pusher from 'pusher-js';

// override default pusher xhr factory
// so we can send the credentials
// to authenticate the user in the api
// https://github.com/pusher/pusher-js/issues/62#issuecomment-226641731
Pusher.Runtime.createXHR = function createXHR() {
  const xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  return xhr;
};

export const SocketContext = React.createContext();

const socket = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  cluster: 'eu',
  forceTLS: true,
  authEndpoint: `${process.env.REACT_APP_API_URL}/auth/realtime`,
});

socket.connection.bind('connected', () => {
  sessionStorage.setItem('pusher_socket_id', socket.connection.socket_id);
});

export default function SocketProvider({ children }) {
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
}

SocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
