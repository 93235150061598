export function transformSecondsIntoFrame(seconds: number, frameRate = 1) {
  if (seconds === 0) return 0;
  const msPerFrame = 1000 /* one second */ / frameRate;
  const ms = seconds * 1000;
  return Math.round(ms / msPerFrame);
}

export function transformFrameIntoSeconds(frame: number, frameRate = 1) {
  if (frame === 0) return 0;
  const msPerFrame = 1000 /* one second */ / frameRate;
  const frameInMs = frame * msPerFrame;
  return frameInMs / 1000;
}
