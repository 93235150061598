import React from 'react';
import { Delete as DeleteSvg } from '@styled-icons/material';
import { FormattedMessage } from 'react-intl';

import ActionsDropdownItem from './ActionsDropdownItem';
import messages from '../messages';

function ActionsDropdownMoveToTrash(props) {
  return (
    <ActionsDropdownItem
      action="trash"
      icon={<DeleteSvg />}
      label={<FormattedMessage {...messages.messageMoveToTrash} />}
      {...props}
    />
  );
}

export default ActionsDropdownMoveToTrash;
