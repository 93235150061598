import { API_LOCAL_UPDATE } from '../../constants';

const localBulkUpdate = (model, data, { worker } = {}) => {
  if (!data) {
    throw new Error(
      'Bulk update without data is not allowed. Try "new Array(size).fill()".',
    );
  }

  const payload = data.reduce(
    (acc, { id, type, attributes, relationships }) => {
      acc[model][id] = {
        id,
        type,
        attributes,
        relationships,
      };
      return acc;
    },
    { [model]: {} },
  );

  return dispatch =>
    dispatch({
      type: API_LOCAL_UPDATE,
      meta: { type: model, worker },
      payload,
    });
};

export default localBulkUpdate;
