import { useEffect, useState } from 'react';

export function useLocalStorageItem(key) {
  const [item, setItem] = useState(localStorage.getItem(key) || '');

  useEffect(() => {
    localStorage.setItem(key, item);
  }, [key, item]);

  return [item, setItem];
}
