import normalize from 'json-api-normalizer';
import requestHttpBuilder from './request-http';
import validateAction from './validate-action';
import parseError from './parse-error';
import { CALL_API } from './constants';

const serializer = (data, options) => (data ? normalize(data, options) : null);

export default function jsonApiMiddleware({
  baseUrl: globalBaseUrl,
  requestHeaders,
}) {
  const requestHttp = requestHttpBuilder({ requestHeaders });
  return () => next => action => {
    const callAPI = action[CALL_API];
    if (typeof callAPI === 'undefined') {
      return next(action);
    }

    validateAction(callAPI);

    const {
      endpoint,
      method,
      payload,
      meta,
      promise,
      baseUrl: modifiedBaseUrl,
    } = callAPI;
    const [typeRequest, typeSuccess, typeError] = callAPI.types;

    function actionWith(data) {
      const finalAction = { ...action, ...data };
      delete finalAction[CALL_API];
      // TODO: check if action is FSA
      return finalAction;
    }

    next(actionWith({ type: typeRequest, payload, meta, endpoint }));

    const baseUrl = modifiedBaseUrl || globalBaseUrl;
    const url = `${baseUrl}/${endpoint}`;

    return requestHttp({ url, method, payload }).then(
      response => {
        if (promise) {
          promise.resolve(response);
        }

        const serialized = serializer
          ? serializer(response, { endpoint })
          : response;

        return next(
          actionWith({
            type: typeSuccess,
            payload: serialized,
            meta,
            endpoint,
          }),
        );
      },
      error => {
        if (promise) {
          promise.reject(parseError(error));
        }

        next(
          actionWith({
            type: typeError,
            payload: error || 'Something bad happened',
            error: true,
            meta,
            endpoint,
          }),
        );
      },
    );
  };
}
