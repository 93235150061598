import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { Box, Flex } from '@rebass/grid';
import {
  ButtonElement as Button,
  CheckboxField,
  Heading,
  SelectField,
  Spinner,
  TextField,
} from '@picter/prisma';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import upperFirst from 'lodash/upperFirst';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';

import EmbeddedCampaign from 'src/components/EmbeddedCampaign';
import paths from 'src/routes/paths';
import useFormatMessage from 'src/hooks/use-format-message';
import { useToriiActions } from 'src/modules/torii';
import { useCurrentUser } from 'src/hooks/use-resource';
import { get } from 'src/utils/accessors';
import useSegmentationJobTitleOptions from 'src/hooks/use-segmentation-job-title-options';

import Layout from '../_layout';
import messages from './messages';

const Container = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: 4,
})`
  height: 100%;
  margin: 'auto';
`;

const Form = styled(Box).attrs({
  as: 'form',
  width: 1,
})`
  max-width: 360px;
`;

function Step1Page() {
  const formatMessage = useFormatMessage();
  const { update } = useToriiActions();
  const user = useCurrentUser();
  const userId = get(user, 'id');

  const onSubmit = useCallback(
    async (formValues, formik) => {
      await update('users', {
        id: userId,
        attributes: formValues,
      });

      formik.setSubmitting(false);
    },
    [userId, update],
  );

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    dirty,
    isValid,
    isSubmitting,
  } = useFormik({
    onSubmit,
    initialValues: {
      firstName: get(user, 'attributes.firstName', ''),
      lastName: get(user, 'attributes.lastName', ''),
      segmentationJobTitle: get(user, 'attributes.segmentationJobTitle', ''),
      wsNewsletter: false,
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .trim()
        .required(formatMessage(messages.messageFirstNameRequired)),
      lastName: Yup.string()
        .trim()
        .required(formatMessage(messages.messageLastNameRequired)),
      segmentationJobTitle: Yup.string()
        .ensure()
        .required(formatMessage(messages.messageSegmentationJobTitleRequired)),
      wsNewsletter: Yup.boolean(),
    }),
  });

  const segmentationJobTitleOptions = useSegmentationJobTitleOptions();

  const buildFieldProps = (name, type = 'text') => ({
    name,
    placeholder: formatMessage(messages[`placeholder${upperFirst(name)}`]),
    ariaLabel:
      type === 'select'
        ? formatMessage(messages[`placeholder${upperFirst(name)}`])
        : undefined,
    label:
      type === 'checkbox'
        ? formatMessage(messages[`placeholder${upperFirst(name)}`])
        : undefined,
    value: values[name],
    error: touched[name] && errors[name],
    onChange: handleChange,
    onBlur: handleBlur,
    required: true,
    disabled: isSubmitting,
  });

  const allFieldsFilled =
    !!get(user, 'attributes.firstName') &&
    !!get(user, 'attributes.lastName') &&
    !!get(user, 'attributes.segmentationJobTitle');

  if (allFieldsFilled) {
    return <Redirect to={paths.LIBRARY_SPACES_NEW_2_PATH} />;
  }

  return (
    <Layout>
      <Layout.Content>
        <Container>
          <Box mb={7}>
            <Heading textSize="large" textAlign="center" whiteSpace="pre-line">
              <FormattedMessage {...messages.title} />
            </Heading>
          </Box>

          <Form onSubmit={handleSubmit}>
            <TextField {...buildFieldProps('firstName')} autoFocus />
            <TextField {...buildFieldProps('lastName')} />
            <SelectField
              {...buildFieldProps('segmentationJobTitle', 'select')}
              displayErrorMessage
              options={segmentationJobTitleOptions}
              noMargin
            />
            <CheckboxField
              {...buildFieldProps('wsNewsletter', 'checkbox')}
              checked={!!values.wsNewsletter}
              onChange={e => {
                e.target.value = !values.wsNewsletter;
                handleChange(e);
              }}
              value
            />
            <Box mt={4} style={{ height: '40px' }}>
              {!isSubmitting ? (
                <Button
                  type="submit"
                  color="white"
                  width={1}
                  height={40}
                  disabled={!dirty || !isValid}
                >
                  <FormattedMessage {...messages.labelNext} />
                </Button>
              ) : (
                <Spinner />
              )}
            </Box>
          </Form>
        </Container>
      </Layout.Content>
      <Layout.Banner>
        <EmbeddedCampaign
          campaign="step-2-setup-profile"
          category="onboarding"
        />
      </Layout.Banner>
    </Layout>
  );
}

export default Step1Page;
