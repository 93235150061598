import React, { cloneElement, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ButtonElement as Button, Modal, TextField } from '@picter/prisma';
import { Flex } from '@rebass/grid';
import { FormattedMessage } from 'react-intl';
import { TrackerEvent, TrackerScope } from '@picter/tracker';
import { Field, Form, Formik } from 'formik';
import Keyboardist from 'react-keyboardist';
import set from 'lodash/set';

import { Box } from 'src/modules/prisma';
import { formattedMessage } from 'src/utils/app-prop-types';
import globalMessages from 'src/messages';

function EditResourceFieldModal({
  element,
  field,
  name,
  onCancel,
  onSave,
  open,
  title,
  value,
}) {
  const elementRef = useRef(null);
  const fieldObject = set({}, field, value);

  useEffect(() => {
    const { current: elementInput } = elementRef;

    if (open && elementInput) {
      elementInput.focus();
    }
  }, [open]);

  return (
    <TrackerScope name={`${name} modal`}>
      <Modal title={title} onClickClose={onCancel} open={open}>
        <Keyboardist
          bindings={{
            Escape: () => {
              onCancel();
              return false;
            },
          }}
        />
        <TrackerEvent name={`${name} modal`} trackView>
          <TrackerEvent name={name} trackSubmit>
            <Formik initialValues={fieldObject} onSubmit={onSave}>
              {({ isSubmitting, isValid }) => (
                <Form>
                  <Box width={1}>
                    <Field
                      name={field}
                      render={({ field: formikField }) =>
                        cloneElement(element, {
                          ...formikField,
                          inputRef: elementRef,
                        })
                      }
                    />
                  </Box>
                  <Box mt={6} ml="auto" width={1}>
                    <Flex flexDirection="row-reverse">
                      <Box>
                        <TrackerEvent name="Save" trackClick>
                          <Button
                            color="primary"
                            disabled={!isValid || isSubmitting}
                            px={0}
                            py={0}
                            textStyle="action.large"
                            type="submit"
                            variant="flat"
                          >
                            <FormattedMessage {...globalMessages.labelSave} />
                          </Button>
                        </TrackerEvent>
                      </Box>
                      <Box gmr={6}>
                        <TrackerEvent name="Cancel" trackClick>
                          <Button
                            color="grey.600"
                            onClick={onCancel}
                            px={0}
                            py={0}
                            textStyle="action.large"
                            variant="flat"
                          >
                            <FormattedMessage {...globalMessages.labelCancel} />
                          </Button>
                        </TrackerEvent>
                      </Box>
                    </Flex>
                  </Box>
                </Form>
              )}
            </Formik>
          </TrackerEvent>
        </TrackerEvent>
      </Modal>
    </TrackerScope>
  );
}

EditResourceFieldModal.propTypes = {
  element: PropTypes.element,
  field: PropTypes.string,
  name: PropTypes.string,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  open: PropTypes.bool,
  title: formattedMessage.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

EditResourceFieldModal.defaultProps = {
  element: <TextField name="field" />,
  field: undefined,
  name: undefined,
  onCancel: undefined,
  open: false,
  onSave: undefined,
  value: undefined,
};

export default EditResourceFieldModal;
