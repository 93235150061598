import { useContext } from 'react';

import {
  ObservationModeControlsContext,
  ObservationModeDataContext,
} from './components/ObservationModeProvider';

export function useObservationModeControls() {
  const context = useContext(ObservationModeControlsContext);
  if (context === undefined) {
    throw new Error(
      '`useObservationModeControls` must be used within a `ObservationModeProvider`.',
    );
  }
  return context;
}

export function useObservationModeData() {
  const context = useContext(ObservationModeDataContext);
  if (context === undefined) {
    throw new Error(
      '`useObservationModeData` must be used within a `ObservationModeProvider`.',
    );
  }
  return context;
}

export function useObservationMode() {
  return [useObservationModeControls(), useObservationModeData()];
}
