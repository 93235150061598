import styled, { css } from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';
import { transparentize, transitions } from 'polished';
import classNames from 'classnames';

import { align, fontFamily, layout, padding, textStyle } from './mixins';
import { transition } from '../utils/transitions';
import { variants } from '../utils/theming';

const colorScheme = variants('colorScheme', {
  simple: css`
    background-color: transparent;
    color: ${themeGet('colors.grey.600')};

    &.hover:not(:disabled),
    &:hover:not(:disabled) {
      background-color: ${props =>
        transparentize(0.95, themeGet('colors.black')(props))};
      color: ${themeGet('colors.black')};
    }

    &.active:not(:disabled),
    &:active:not(:disabled) {
      background-color: ${props =>
        transparentize(0.9, themeGet('colors.black')(props))};
      color: ${themeGet('colors.black')};
    }
  `,
  primary: css`
    background-color: ${props =>
      transparentize(0.9, themeGet('colors.primary')(props))};
    color: ${themeGet('colors.primary')};

    &.hover:not(:disabled),
    &:hover:not(:disabled) {
      background-color: ${props =>
        transparentize(0.85, themeGet('colors.primary')(props))};
    }

    &.active:not(:disabled),
    &:active:not(:disabled) {
      background-color: ${props =>
        transparentize(0.8, themeGet('colors.primary')(props))};
    }
  `,
});

const Action = styled.button.attrs(({ active, hover, disabled }) => ({
  className: classNames({ active, hover, disabled }),
  fontFamily: 'sansSerif',
}))`
  appearance: none;
  border: none;
  border-radius: ${themeGet('radii.medium')};
  cursor: pointer;
  height: 28px;
  padding: ${themeGet('space.1')}px ${themeGet('space.2')}px;
  ${transitions(
    transition({ property: 'color', duration: '100ms', timing: 'linear ' }),
    transition({
      property: 'background-color',
      duration: '100ms',
      timing: 'linear ',
    }),
  )}

  &.disabled,
  &:disabled {
    cursor: not-allowed;
    filter: opacity(40%);
  }

  ${align};
  ${colorScheme};
  ${fontFamily};
  ${layout};
  ${padding};
  ${textStyle};
`;

Action.defaultProps = {
  tag: 'button',

  active: false,
  disabled: false,
  hover: false,

  alignItems: 'center',
  colorScheme: 'simple',
  display: 'inline-flex',
  textStyle: 'action.small',
};

Action.displayName = 'Action';

export default Action;
