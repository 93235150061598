import { useMemo } from 'react';
import {
  isProjectDownloadEnabled,
  getProjectPublicLink,
} from 'src/utils/accessors/project';
import { useLocation } from 'react-router-dom';
import { List } from 'immutable';

import { useProject } from 'src/hooks/use-resource';
import { viewModeTypes } from 'src/routes/constants';
import useRouter from 'src/hooks/use-router';
import paths from 'src/routes/paths';
import { get } from 'src/utils/accessors';

import usePermissionScope from './use-permission-scope';

const { LIBRARY_PROJECT_INBOX_SUCCESS_PATH } = paths;

const useProjectPermission = ({
  projectId: publicUrlKey,
  viewMode = viewModeTypes.DEFAULT,
} = {}) => {
  const { match } = useRouter();
  // If you're inside a project route, prefer the usePermission hook!
  // ProjectId is needed right now before useRouter is released by react-router
  const hasAccess = usePermissionScope(publicUrlKey);
  const { merged: hasOwnerAccess } = hasAccess('owner');
  const { merged: hasMemberAccess, space: hasSpaceMemberAccess } = hasAccess(
    'member',
  );
  const { merged: hasManagerAccess } = hasAccess('manager');
  const project = useProject(
    {
      id: publicUrlKey,
      includeOnSelector: ['publicLinks', 'collections'],
    },
    { request: false },
  );
  const publicLink = getProjectPublicLink(project, viewMode);
  const isPublicLinkRoute = viewMode !== viewModeTypes.DEFAULT;
  const publicLinkDownloadEnabled = get(
    publicLink,
    'attributes.downloadEnabled',
  );
  const publicLinkCommentsEnabled = get(
    publicLink,
    'attributes.commentsEnabled',
  );
  const projectIsInTrash = get(project, 'attributes.deletedAt') !== null;
  const isInboxSuccessPage = match.path === LIBRARY_PROJECT_INBOX_SUCCESS_PATH;

  const canSeeComments = isPublicLinkRoute ? publicLinkCommentsEnabled : true;
  const canDeleteComments = hasOwnerAccess || hasManagerAccess;
  const canDownloadImages = isPublicLinkRoute
    ? publicLinkDownloadEnabled
    : hasMemberAccess || isProjectDownloadEnabled(project);

  const { query = {} } = useLocation();
  const hasFilterActive = query.filter;

  const canEditProject =
    !isPublicLinkRoute && hasMemberAccess && !projectIsInTrash;
  const canCopyImagesToProjects =
    !isPublicLinkRoute &&
    (hasOwnerAccess || hasSpaceMemberAccess) &&
    !projectIsInTrash;
  const canShareProject =
    !isPublicLinkRoute && hasManagerAccess && !projectIsInTrash;
  const canManagePublicLinks =
    !isPublicLinkRoute && hasMemberAccess && !projectIsInTrash;
  const canDuplicateProject =
    !isPublicLinkRoute &&
    (hasOwnerAccess || hasSpaceMemberAccess) &&
    !projectIsInTrash;
  const canMoveProject =
    !isPublicLinkRoute &&
    (hasOwnerAccess || hasSpaceMemberAccess) &&
    !projectIsInTrash;
  const canDeleteProject =
    !isPublicLinkRoute &&
    (hasSpaceMemberAccess || hasOwnerAccess) &&
    projectIsInTrash;
  const canTrashProject =
    !isPublicLinkRoute &&
    (hasSpaceMemberAccess || hasOwnerAccess) &&
    !projectIsInTrash;
  const canRestoreProject =
    !isPublicLinkRoute &&
    (hasSpaceMemberAccess || hasOwnerAccess) &&
    projectIsInTrash;
  const canFollowProject = !isPublicLinkRoute && !projectIsInTrash;
  const canManageCollections =
    !isPublicLinkRoute && hasMemberAccess && !projectIsInTrash;
  const canUploadImages =
    !isInboxSuccessPage && isPublicLinkRoute
      ? viewMode === viewModeTypes.REQUEST_FILES
      : hasMemberAccess && !projectIsInTrash;
  const canAddImages = canUploadImages;
  const canEditImages = isPublicLinkRoute
    ? viewMode === viewModeTypes.REQUEST_FILES
    : hasMemberAccess && !projectIsInTrash;
  const canProofImages = isPublicLinkRoute
    ? viewMode === viewModeTypes.REVIEW
    : !projectIsInTrash;
  const canReorderImages =
    !hasFilterActive &&
    (isPublicLinkRoute
      ? viewMode === viewModeTypes.REQUEST_FILES
      : hasMemberAccess && !projectIsInTrash);
  // A little tricky to load collections in here but makes sense once we only need to check this
  // in the same places as we check the canReorderImages
  const canMoveToCollection =
    !isPublicLinkRoute &&
    canManageCollections &&
    !!get(project, 'relationships.collections', List()).size;
  const canReplaceImageVersion = isPublicLinkRoute
    ? viewMode === viewModeTypes.REQUEST_FILES
    : hasMemberAccess && !projectIsInTrash;
  const canDeleteImages = isPublicLinkRoute
    ? viewMode === viewModeTypes.REQUEST_FILES
    : hasMemberAccess && !projectIsInTrash;

  const canSelect =
    (canProofImages ||
      canManageCollections ||
      canDownloadImages ||
      canDeleteImages) &&
    !projectIsInTrash;

  const canManageAssignees = viewMode === viewModeTypes.DEFAULT;

  const valueObj = useMemo(
    () => ({
      canEditProject,
      canCopyImagesToProjects,
      canShareProject,
      canDuplicateProject,
      canMoveProject,
      canDeleteProject,
      canTrashProject,
      canRestoreProject,
      canFollowProject,
      canManagePublicLinks,
      canManageCollections,
      canUploadImages,
      canDownloadImages,
      canAddImages,
      canEditImages,
      canProofImages,
      canReorderImages,
      canMoveToCollection,
      canReplaceImageVersion,
      canDeleteImages,
      canSeeComments,
      canDeleteComments,
      canSelect,
      canManageAssignees,
    }),
    [
      canEditProject,
      canCopyImagesToProjects,
      canShareProject,
      canManagePublicLinks,
      canDuplicateProject,
      canMoveProject,
      canDeleteProject,
      canTrashProject,
      canRestoreProject,
      canFollowProject,
      canManageCollections,
      canUploadImages,
      canDownloadImages,
      canAddImages,
      canEditImages,
      canProofImages,
      canReorderImages,
      canMoveToCollection,
      canReplaceImageVersion,
      canDeleteImages,
      canSeeComments,
      canDeleteComments,
      canSelect,
      canManageAssignees,
    ],
  );

  const value = useMemo(
    () =>
      Object.entries(valueObj).reduce((acc, [objKey, objValue]) => {
        // Added (|| null) so we can use conditional render without worrying if
        // we are going to get booleans on the DOM if the permission is false.
        acc[objKey] = objValue || null;
        return acc;
      }, {}),
    [valueObj],
  );

  return value;
};

export default useProjectPermission;
