import React, { useState, useCallback, useMemo, useRef } from 'react';
import AutoSuggest, { InputProps } from 'react-autosuggest';
import debounce from 'lodash/debounce';
import { Close as CloseSvg } from '@styled-icons/material';
// @ts-ignore
import Keyboardist from 'react-keyboardist';

import { Box, TextField, Icon } from 'src/modules/prisma';

import useFormatMessage from 'src/hooks/use-format-message';

import Suggestion from '../../styles/Suggestion';
import SearchFieldWrapper from '../../styles/SearchFieldWrapper';

import messages from '../../messages';

function renderInputComponent(onClickClose: () => void) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (inputProps: InputProps<string>) => {
    return (
      <Box display="flex">
        <TextField
          noMargin
          name="global-search"
          icon="search"
          autoFocus={!inputProps.value}
          inputProps={inputProps}
        />
        <Box
          display="flex"
          p="9px"
          pr="12px"
          backgroundColor="white"
          alignItems="center"
          justifyContent="center"
        >
          <Icon
            type={<CloseSvg />}
            size="medium"
            color="muted"
            onClick={onClickClose}
            interactive
          />
        </Box>
      </Box>
    );
  };
}

function getSuggestionValue(suggestion: string) {
  return suggestion;
}

function renderSuggestion(suggestion: string) {
  return <Suggestion>{suggestion}</Suggestion>;
}

type Props = {
  initialValue?: string;
  onSearch: (q: string) => void;
  loadSuggestions: (q: string) => Promise<string[]>;
  onClickClose: () => void;
};

export default function SearchField({
  initialValue = '',
  onSearch,
  loadSuggestions: loadSearchSuggestions,
  onClickClose,
}: Props) {
  const formatMessage = useFormatMessage();
  const [value, setValue] = useState<string>(initialValue);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const inputRef = useRef<HTMLInputElement>();
  const onChange = useCallback(
    (event, { newValue }) => {
      // Fix of https://github.com/moroshko/react-autosuggest/issues/368
      setValue(typeof newValue === 'string' ? newValue : '');
    },
    [setValue],
  );

  // This handler is needed to trigger a search,
  // if the user hit enter instead of selecting a suggestion
  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;
      // Prevents control f on input to open browser default search
      if (e.key === 'f' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
      }
      if (e.key === 'Enter') {
        if (target.value.length > 0) {
          onSearch(target.value);
          // blur input field whenever search is triggered
          // via hitting Enter
          inputRef.current?.blur();
        }
      }
      if (e.key === 'Escape') {
        onClickClose();
      }
    },
    [onSearch, onClickClose],
  );

  const inputProps = useMemo(
    () => ({
      placeholder: formatMessage(messages.searchLabel),
      value,
      onChange,
      onKeyDown,
      ref: inputRef,
    }),
    [onChange, onKeyDown, value, formatMessage],
  ) as InputProps<string>;

  const loadSuggestions = useCallback(
    async (q: string) => {
      const results = await loadSearchSuggestions(q);
      setSuggestions(results);
    },
    [setSuggestions, loadSearchSuggestions],
  );

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = useCallback(
    debounce(
      ({ value: requestValue }: { value: string }) => {
        loadSuggestions(requestValue);
      },
      300,
      { leading: true },
    ),
    [loadSuggestions],
  );

  const onSuggestionSelected = useCallback(
    (event, { suggestionValue }) => {
      onSearch(suggestionValue);
    },
    [onSearch],
  );

  const onSuggestionsClearRequested = useCallback(() => {
    setSuggestions([]);
  }, [setSuggestions]);

  const bindings = useMemo(
    () => ({
      // Shortcut on Windows
      'Ctrl+KeyF': () => inputRef.current?.focus(),
      // Shortcut on Mac
      'Meta+KeyF': () => inputRef.current?.focus(),
    }),
    [],
  );

  return (
    <SearchFieldWrapper shouldDisplayBlockSuggestions={!!initialValue}>
      <Keyboardist bindings={bindings} />
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderInputComponent={renderInputComponent(onClickClose)}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
    </SearchFieldWrapper>
  );
}
