import { API_LOCAL_REMOVE_RELATIONSHIP } from '../../constants';

const localRemoveRelationship = (model, { id, relation, data } = {}) => {
  if (!id) {
    throw new Error(
      'You have to define a resource id to attach the relationship to.',
    );
  }

  if (!relation) {
    throw new Error('Relation is required to attach a relationship.');
  }

  if (!data) {
    throw new Error('You have to pass a data parameter.');
  }

  return dispatch =>
    dispatch({
      type: API_LOCAL_REMOVE_RELATIONSHIP,
      payload: { data },
      meta: {
        model,
        relation,
        id,
        data,
      },
    });
};

export default localRemoveRelationship;
