import React, { useMemo } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import {
  SentimentVerySatisfied as SentimentVerySatisfiedSvg,
  Pause as PauseSvg,
  People as PeopleSvg,
  Help as HelpSvg,
} from '@styled-icons/material';
import { Link, useParams } from 'react-router-dom';
import { TrackerEvent, TrackerScope } from '@picter/tracker';

import { Box, Body, Heading, Text } from 'src/modules/prisma';
import { get } from 'src/utils/accessors';
import IntercomLauncher from 'src/components/IntercomLauncher';
import {
  librarySettingsSpaceMembersUrl,
  librarySettingsSpaceSubscriptionUrl,
} from 'src/routes/urls';
import { useCurrentUser, useSpace } from 'src/hooks/use-resource';
import useNavigate from 'src/hooks/use-navigate';
import usePromise from 'src/hooks/use-promise';

import CardGrid from './CardGrid';
import Card, { CardAction } from './Card';
import messages from '../_messages';
import {
  SubscriptionStatus,
  useSubscription,
  useSubscriptionService,
} from '../../_components/SubscriptionService';

export enum CouponCode {
  NoCancelDiscount = 'NO-CANCEL-DISCOUNT-A2V',
}

type AppliedCoupon = {
  appliedCount: number;
  couponId: CouponCode;
  object: 'coupon';
};

function getFutureDateIn({
  days,
  months,
  years,
}: {
  days?: number;
  months?: number;
  years?: number;
}) {
  const date = new Date();
  if (days) date.setUTCDate(date.getUTCDate() + days);
  if (months) date.setUTCMonth(date.getUTCMonth() + months);
  if (years) date.setUTCFullYear(date.getUTCFullYear() + years);
  return date;
}

export default function CancelSubscriptionOffers() {
  const { spaceId } = useParams<{ spaceId: string }>();
  const space = useSpace({ id: spaceId });
  const user = useCurrentUser(
    {},
    {
      request: false,
    },
  );
  const subscriptionService = useSubscriptionService();
  const { subscription } = useSubscription({
    spaceId,
  });
  const navigate = useNavigate();
  const {
    trigger: applyCoupon,
    isLoading: isLoadingApplyCoupon,
  } = usePromise(({ couponCode }) =>
    subscriptionService
      .applyCoupon({ couponCode, spaceId })
      .then(() => navigate(librarySettingsSpaceSubscriptionUrl({ spaceId }))),
  );
  const {
    trigger: pauseSubscription,
    isLoading: isLoadingPauseSubscription,
  } = usePromise(({ resumeDate }) =>
    subscriptionService
      .pause({ resumeDate, spaceId })
      .then(() => navigate(librarySettingsSpaceSubscriptionUrl({ spaceId }))),
  );

  const subscriptionStatus = get(space, 'attributes.subscriptionStatus');
  const appliedCoupons: AppliedCoupon[] = get(subscription, 'coupons', []);
  const currencyCode = get(subscription, 'currencyCode', 'EUR');
  const { monthlyCost, monthlyCostWithDiscount } = useMemo(() => {
    if (!subscription)
      return {
        monthlyCost: 0,
        monthlyCostWithDiscount: 0,
      };

    const mrr = get(subscription, 'mrr') / 100;

    return {
      monthlyCost: mrr,
      monthlyCostWithDiscount: (mrr * 60) / 100,
    };
  }, [subscription]);

  const isNoCancelDiscountApplied = appliedCoupons.some(
    appliedCoupon => appliedCoupon.couponId === CouponCode.NoCancelDiscount,
  );
  const isLoading = isLoadingApplyCoupon || isLoadingPauseSubscription;

  return (
    <>
      <Heading fontWeight="light" tag="h1" textSize="large">
        <FormattedMessage
          {...messages.titleOffers}
          values={{ name: get(user, 'attributes.firstName') }}
        />
      </Heading>
      <Box my={5}>
        <TrackerEvent name="Offers" trackView>
          <TrackerScope name="Offers">
            <CardGrid>
              {!isNoCancelDiscountApplied && (
                <Card
                  title={
                    <FormattedMessage {...messages.titleSubscriptionDiscount} />
                  }
                  action={
                    <TrackerEvent name="Apply coupon" trackClick>
                      <CardAction
                        disabled={isLoading}
                        onClick={() =>
                          applyCoupon({
                            couponCode: CouponCode.NoCancelDiscount,
                          })
                        }
                      >
                        <FormattedMessage {...messages.labelApplyDiscount} />
                      </CardAction>
                    </TrackerEvent>
                  }
                  color="#3697ff"
                  iconColor="white"
                  iconType={SentimentVerySatisfiedSvg}
                  content={
                    <>
                      <Box>
                        <Text
                          color="danger"
                          fontFamily="sansSerif"
                          textTransform="uppercase"
                          textStyle="subheading.regular"
                          tag="p"
                        >
                          <FormattedMessage {...messages.messageMonthlyCosts} />
                        </Text>
                        <Text
                          color="danger"
                          fontFamily="sansSerif"
                          textDecoration="line-through"
                          textTransform="uppercase"
                          textStyle="heading.regular"
                          fontWeight="strong"
                          tag="p"
                        >
                          <FormattedNumber
                            currency={currencyCode}
                            style="currency"
                            value={monthlyCost}
                          />
                        </Text>
                      </Box>
                      <br />
                      <Box>
                        <Text
                          color="success"
                          fontFamily="sansSerif"
                          textTransform="uppercase"
                          textStyle="subheading.regular"
                          tag="p"
                        >
                          <FormattedMessage
                            {...messages.messageMonthlyCostsWithDiscount}
                            values={{
                              numberOfMonths: 4,
                            }}
                          />
                        </Text>
                        <Text
                          color="success"
                          fontFamily="sansSerif"
                          textTransform="uppercase"
                          textStyle="heading.regular"
                          fontWeight="strong"
                          tag="p"
                        >
                          <FormattedNumber
                            currency={currencyCode}
                            style="currency"
                            value={monthlyCostWithDiscount}
                          />
                        </Text>
                      </Box>
                    </>
                  }
                />
              )}
              {subscriptionStatus !== SubscriptionStatus.Paused && (
                <Card
                  title={
                    <FormattedMessage {...messages.titleSubscriptionPause} />
                  }
                  action={
                    <TrackerEvent name="Pause subscription" trackClick>
                      <CardAction
                        disabled={isLoading}
                        onClick={() =>
                          pauseSubscription({
                            resumeDate: getFutureDateIn({ months: 3 }),
                          })
                        }
                      >
                        <FormattedMessage
                          {...messages.labelPauseSubscription}
                        />
                      </CardAction>
                    </TrackerEvent>
                  }
                  color="#ffc51f"
                  iconColor="white"
                  iconType={PauseSvg}
                  content={
                    <Body>
                      <FormattedMessage
                        {...messages.messagePausingConsequences}
                      />
                    </Body>
                  }
                />
              )}
              <Card
                title={<FormattedMessage {...messages.titleReducePaidSeats} />}
                action={
                  <TrackerEvent name="Manage team" trackClick>
                    <Link
                      to={librarySettingsSpaceMembersUrl({
                        spaceId: get(space, 'id'),
                      })}
                    >
                      <CardAction>
                        <FormattedMessage {...messages.labelReducePaidSeats} />
                      </CardAction>
                    </Link>
                  </TrackerEvent>
                }
                color="#8c8c8c"
                iconColor="white"
                iconType={PeopleSvg}
                content={
                  <Body>
                    <FormattedMessage {...messages.messageReducePaidSeats} />
                  </Body>
                }
              />
              <Card
                title={<FormattedMessage {...messages.titleFeatureRequest} />}
                action={
                  <IntercomLauncher
                    launcher={
                      <TrackerEvent
                        name="Contact us to request feature"
                        trackClick
                      >
                        <CardAction>
                          <FormattedMessage {...messages.labelFeatureRequest} />
                        </CardAction>
                      </TrackerEvent>
                    }
                  />
                }
                color="black"
                iconColor="white"
                iconType={HelpSvg}
                content={
                  <Body>
                    <FormattedMessage {...messages.messageFeatureRequest} />
                  </Body>
                }
              />
            </CardGrid>
          </TrackerScope>
        </TrackerEvent>
      </Box>
    </>
  );
}
