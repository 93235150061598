import styled from 'styled-components/macro';
import { transparentize } from 'polished';
import themeGet from '@styled-system/theme-get';
import { space, textStyle } from 'styled-system';

const Counter = styled.span`
  ${space};
  ${textStyle};
  color: ${props => transparentize(0.2, themeGet('colors.primary')(props))};
  background-color: ${props =>
    transparentize(0.95, themeGet('colors.primary')(props))};
  border-radius: 50% / 60%;
  line-height: 1.2em;
  padding: 0 ${themeGet('space.1')}px;
`;

export default Counter;
