import styled from 'styled-components/macro';

const ProgressBarContainer = styled.div`
  background-color: transparent;
  bottom: 0;
  display: flex;
  height: 2px;
  left: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
`;

export default ProgressBarContainer;
