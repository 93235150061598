import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Body,
  FlexAlignCenter,
  FlexAlignLeft,
  FlexAlignRight,
  MediaQuery,
} from '@picter/prisma';
import { Flex } from '@rebass/grid';
import { FormattedMessage } from 'react-intl';
import {
  Refresh as RefreshSvg,
  HighlightOff as HighlightOffSvg,
} from '@styled-icons/material';
import { List, Map } from 'immutable';

import ActionsDropdown from 'src/components/ActionsDropdown';
import CollectionActionsDropdown from 'src/components/CollectionActionsDropdown';
import CommentsSvg from 'src/assets/CommentsSvg';
import { Box, Dropdown, Icon } from 'src/modules/prisma';
import {
  FileProofingIcon,
  FileProofingTooltip,
  ManageCollectionsIcon,
  ManageCollectionsTooltip,
} from 'src/components/ContentIcon';
import MaybeLink from 'src/components/MaybeLink';
import ProgressBar from 'src/components/ProgressBar';
import { refShape } from 'src/utils/app-prop-types';
import usePermission from 'src/hooks/use-permission';
import UploadArea from 'src/components/UploadArea';
import { RelativeBox } from 'src/styles/PositionBox';
import { returnInCase } from 'src/utils/in-case';

import FileWrapper from './FileWrapper';
import Card from '../styles/ImageCardCard';
import DurationLabel from '../styles/DurationLabel';
import Image from './ImageCardImage';
import ImagePreview from './ImageCardImagePreview';
import EditableTitle from './ImageCardEditableTitle';
import ProofStatusFlag from '../styles/ProofStatusFlag';
import CommentsCounter from '../styles/CommentsCounter';

import messages from '../messages';

const ImageCard = forwardRef((props, ref) => {
  const {
    // proofing related props
    approvals,
    approvedByUser,
    rejected,
    rejectedBy,
    toggleApprove,

    collectionsCounter,
    projectId,
    downloadEnabled,
    editableTitleRef,
    handleChangeTitle,
    handleClickRemove,
    handleClickRename,
    handleClickDownload,
    handleClickInfo,
    handleClickLink,
    handleReplaceImageVersion,
    hoverSide,
    id,
    index,
    isDragging,
    isOver,
    src,
    isPdf,
    isVideo,
    isNonVisual,
    fileExtension,
    fileMimeType,
    title,
    uploading,
    processing,
    commentsCounter,
    handleClickComments,
    handleToggleRejection,
    duration,
    hovering,
  } = props;

  const {
    canReorderImages,
    canMoveToCollection,
    canEditImages,
    canReplaceImageVersion,
    canManageCollections,
    canSeeComments,
    canProofImages,
    canDeleteImages,
  } = usePermission();

  const numberOfApprovals =
    typeof approvals === 'number' ? approvals : approvals.size;

  const FileProofingTooltipComponent = hovering
    ? FileProofingTooltip
    : React.Fragment;

  const ManageCollectionsTooltipComponent = hovering
    ? ManageCollectionsTooltip
    : React.Fragment;

  const imagesIds = useMemo(() => [id], [id]);
  return (
    <div data-testid={`image-grid-card-${title}`}>
      <Card
        canDrag={canReorderImages || canMoveToCollection}
        innerRef={ref}
        rejected={canProofImages && rejected === true}
        isDragging={isDragging}
        isOver={isOver}
        hoverSide={hoverSide}
      >
        <Card.Extra />
        <Card.Content>
          <MaybeLink
            data-intercom-target="file-card-image"
            onClick={handleClickLink}
          >
            {uploading || processing ? (
              <FileWrapper fileMimeType={fileMimeType}>
                <ImagePreview
                  alt={title}
                  src={src}
                  showPlaceholder={
                    fileMimeType === 'image/tiff' || isNonVisual || !src
                  }
                  type={fileMimeType}
                  uploading={uploading}
                  processing={processing}
                />
              </FileWrapper>
            ) : (
              <FileWrapper isPdf={isPdf}>
                <Image
                  alt={title}
                  src={src}
                  isNonVisual={isNonVisual}
                  type={fileMimeType}
                />
              </FileWrapper>
            )}
          </MaybeLink>
          <MediaQuery
            query={{ maxWidth: 0 }}
            render={() =>
              canProofImages && (
                <ProofStatusFlag
                  variant={returnInCase({
                    [numberOfApprovals > 0]: () => 'approved',
                    [rejected]: () => 'rejected',
                    default: () => 'default',
                  })}
                />
              )
            }
          />
          {isVideo && duration && (
            <DurationLabel textStyle="body.xsmall">{duration}</DurationLabel>
          )}
        </Card.Content>
        <Card.Extra large>
          <RelativeBox>
            <Flex px={5} pt={4} width={1}>
              <FlexAlignCenter centering="margin" flex="1 1 auto">
                <Box width={3 / 4}>
                  <EditableTitle
                    onChange={handleChangeTitle}
                    ref={editableTitleRef}
                    value={title}
                    disabled={!canEditImages}
                  />
                </Box>
              </FlexAlignCenter>
            </Flex>
            <Flex mt={3} px={5} pb={4} width={1}>
              <FlexAlignLeft flex="1 0 40px" justifyContent="flex-start">
                {fileExtension && (
                  <Box mt={-1}>
                    <Badge>{fileExtension}</Badge>
                  </Box>
                )}
                {canSeeComments && commentsCounter > 0 ? (
                  <Box gml={2}>
                    <CommentsCounter onClick={handleClickComments}>
                      <Icon
                        color="grey.600"
                        size="small"
                        type={<CommentsSvg />}
                      />
                      <Body color="grey.600" lineHeight="1em" size="xsmall">
                        {commentsCounter}
                      </Body>
                    </CommentsCounter>
                  </Box>
                ) : null}
              </FlexAlignLeft>
              <FlexAlignCenter centering="margin" flex="1 1 auto">
                <Flex alignItems="center">
                  {canProofImages && (
                    <Box data-intercom-target={`approve-button-${index}`}>
                      <FileProofingTooltipComponent
                        approvals={approvals}
                        rejected={rejected}
                        rejectedBy={rejectedBy}
                      >
                        <FileProofingIcon
                          approvals={approvals}
                          approvedByUser={approvedByUser}
                          className="proof-field"
                          id={`image-proof-${id}`}
                          onClickApprove={toggleApprove}
                          onClickReject={handleToggleRejection}
                          rejected={rejected}
                          rejectedBy={rejectedBy}
                        />
                      </FileProofingTooltipComponent>
                    </Box>
                  )}
                  {canManageCollections && (
                    <Box gml={2}>
                      <CollectionActionsDropdown
                        icon={dropdownProps => (
                          <ManageCollectionsTooltipComponent
                            visible={!dropdownProps.active}
                          >
                            <ManageCollectionsIcon
                              {...dropdownProps}
                              numberOfCollections={collectionsCounter}
                            />
                          </ManageCollectionsTooltipComponent>
                        )}
                        projectId={projectId}
                        imagesIds={imagesIds}
                      />
                    </Box>
                  )}
                </Flex>
              </FlexAlignCenter>
              <FlexAlignRight flex="1 0 40px" justifyContent="flex-end">
                <UploadArea onUpload={handleReplaceImageVersion} disableDrop>
                  {({ openFileDialog }) => (
                    <div data-testid="image-card-actions-dropdown">
                      <ActionsDropdown page="image" lazyRenderWhen={hovering}>
                        <ActionsDropdown.Group entity="image">
                          <ActionsDropdown.Info
                            entity="image"
                            onClick={handleClickInfo}
                          />
                          {canEditImages && (
                            <ActionsDropdown.Rename
                              label={
                                <FormattedMessage {...messages.labelRename} />
                              }
                              onClick={handleClickRename}
                            />
                          )}
                          {downloadEnabled && (
                            <ActionsDropdown.Download
                              label={
                                <FormattedMessage {...messages.labelDownload} />
                              }
                              onClick={handleClickDownload}
                              disabled={uploading || processing}
                            />
                          )}
                          {(canReplaceImageVersion ||
                            canDeleteImages ||
                            canProofImages) && (
                            <>
                              <Dropdown.Separator />
                              {canReplaceImageVersion && (
                                <ActionsDropdown.Item
                                  action="replace"
                                  label={
                                    <FormattedMessage
                                      {...messages.labelUploadNewVersion}
                                    />
                                  }
                                  icon={<RefreshSvg />}
                                  onClick={openFileDialog}
                                  disabled={uploading || processing}
                                />
                              )}

                              {canProofImages && (
                                <ActionsDropdown.Item
                                  action="reject"
                                  label={
                                    <FormattedMessage
                                      {...(rejected
                                        ? messages.labelRemoveRejection
                                        : messages.labelRejectImage)}
                                    />
                                  }
                                  data-testid="image-card-actions-dropdown-reject"
                                  icon={<HighlightOffSvg />}
                                  onClick={handleToggleRejection}
                                />
                              )}

                              {canDeleteImages && (
                                <ActionsDropdown.Delete
                                  label={
                                    <FormattedMessage
                                      {...messages.labelRemove}
                                    />
                                  }
                                  confirm={false}
                                  onClick={handleClickRemove}
                                />
                              )}
                            </>
                          )}
                        </ActionsDropdown.Group>
                      </ActionsDropdown>
                    </div>
                  )}
                </UploadArea>
              </FlexAlignRight>
            </Flex>
            {uploading && <ProgressBar />}
          </RelativeBox>
        </Card.Extra>
      </Card>
    </div>
  );
});

ImageCard.propTypes = {
  approvals: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(List),
  ]),
  approvedByUser: PropTypes.bool,
  handleToggleRejection: PropTypes.func,
  rejected: PropTypes.bool,
  rejectedBy: PropTypes.instanceOf(Map),
  toggleApprove: PropTypes.func,

  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  downloadEnabled: PropTypes.bool,
  editableTitleRef: refShape,
  handleChangeTitle: PropTypes.func,
  handleClickRemove: PropTypes.func,
  handleClickRename: PropTypes.func,
  handleClickDownload: PropTypes.func,
  handleClickInfo: PropTypes.func,
  handleClickComments: PropTypes.func,
  handleClickLink: PropTypes.func.isRequired,
  handleDropPosition: PropTypes.func,
  handleReplaceImageVersion: PropTypes.func,
  hoverSide: PropTypes.string.isRequired,
  id: PropTypes.string,
  index: PropTypes.number.isRequired,
  isDragging: PropTypes.bool.isRequired,
  isOver: PropTypes.bool.isRequired,
  src: PropTypes.string,
  fileExtension: PropTypes.string,
  fileMimeType: PropTypes.string,
  isVideo: PropTypes.bool,
  isPdf: PropTypes.bool,
  isNonVisual: PropTypes.bool,
  title: PropTypes.string,
  uploading: PropTypes.bool,
  processing: PropTypes.bool,
  projectId: PropTypes.string.isRequired,
  publicKey: PropTypes.string.isRequired,
  filter: PropTypes.string,
  collectionsCounter: PropTypes.number,
  commentsCounter: PropTypes.number,
  approvesCounter: PropTypes.number,
  duration: PropTypes.number,
  hovering: PropTypes.bool.isRequired,
};

ImageCard.defaultProps = {
  approvals: 0,
  approvedByUser: undefined,
  handleToggleRejection: undefined,
  rejected: undefined,
  rejectedBy: undefined,
  toggleApprove: undefined,

  downloadEnabled: false,
  editableTitleRef: null,
  handleChangeTitle: null,
  handleClickRemove: null,
  handleClickRename: null,
  handleClickDownload: null,
  handleClickInfo: null,
  handleClickComments: null,
  handleDropPosition: null,
  handleReplaceImageVersion: null,
  id: null,
  src: null,
  fileExtension: null,
  fileMimeType: null,
  title: null,
  uploading: false,
  processing: false,
  filter: null,
  collectionsCounter: 0,
  commentsCounter: 0,
  approvesCounter: 0,
  duration: null,
  isVideo: false,
  isPdf: false,
  isNonVisual: false,
};

export default ImageCard;
