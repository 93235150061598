import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'unstated';
import { ButtonElement as Button } from '@picter/prisma';
import { Flex, Box } from '@rebass/grid';
import { FormattedMessage } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { List } from 'immutable';

import { get } from 'src/utils/accessors';
import { useProject } from 'src/hooks/use-resource';
import { libraryProjectInboxSuccessUrl } from 'src/routes/urls';
import { viewModeTypes } from 'src/routes/constants';
import withUploadStorage from 'src/hocs/with-upload-storage';

import messages from '../messages';

const { REACT_APP_API_URL } = process.env;
const WORKSPACE_ENDPOINT = `${REACT_APP_API_URL}/app-workspace/`;

const DoneWithUploadButton = ({ uploadStorage }) => {
  const { projectId } = useParams();
  const history = useHistory();
  const collectionCookie = Cookies.get(`inbox-key/${projectId}`);
  const [collectionId] = collectionCookie ? collectionCookie.split(':') : [];

  const projectWithImages = useProject(
    {
      id: projectId,
      include: ['collections.images'],
    },
    { request: false },
  );

  const collection = get(
    projectWithImages,
    'relationships.collections',
    List(),
  ).find(col => get(col, 'id') === collectionId);

  const collectionImages = get(collection, 'relationships.images', List());
  const upload = uploadStorage.state.groups[projectId];
  const isUploading = upload && upload.uploadedCount !== upload.totalCount;

  const handleClick = useCallback(async () => {
    const response = await fetch(
      new URL(
        `ws-public-links/${projectId}/finished-inbox-upload`,
        WORKSPACE_ENDPOINT,
      ).toString(),
      {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({ collectionId }),
      },
    );

    if (response.ok) {
      Cookies.remove(`inbox-key/${projectId}`);

      history.replace(
        libraryProjectInboxSuccessUrl({
          viewMode: viewModeTypes.REQUEST_FILES,
          projectId,
        }),
      );
    }
  }, [history, projectId, collectionId]);

  return (
    <Flex style={{ width: '100%' }} justifyContent="center">
      <Box mb={3} mt={3}>
        <Button
          onClick={handleClick}
          disabled={!collectionImages.size || isUploading}
          color="white"
          borderRadius="100px"
        >
          <FormattedMessage {...messages.labelDoneWithUploads} />
        </Button>
      </Box>
    </Flex>
  );
};

DoneWithUploadButton.propTypes = {
  uploadStorage: PropTypes.instanceOf(Container).isRequired,
};

export default withUploadStorage(DoneWithUploadButton);
