import React, { createContext, useContext } from 'react';

const PopoverContext = createContext();

export function PopoverProvider(props) {
  return <PopoverContext.Provider {...props} />;
}

export function usePopover() {
  return useContext(PopoverContext);
}
