import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { get } from 'src/utils/accessors';
import globalMessages from 'src/messages';
import { Icon, Box } from 'src/modules/prisma';
import { ChooserItem, chooserPropsShape } from 'src/components/EntityChooser';

import messages from '../messages';

export default function ItemComponent({ item, chooserProps, icon }) {
  const folderId = get(item, 'id');

  const folderName = get(item, 'attributes.name') || (
    <FormattedMessage {...globalMessages.labelUntitledFolder} />
  );

  const folderProjectCount = get(item, 'relationships.count.attributes.count');

  return (
    <ChooserItem
      id={folderId}
      cover={
        <Box m={4}>
          <Icon
            type={icon}
            size="medium"
            boxSize="xxlarge"
            color="grey.600"
            borderStyle="solid"
            borderWidth="1px"
            borderColor="grey.200"
          />
        </Box>
      }
      name={folderName}
      subheader={
        <FormattedMessage
          {...messages.messageProjectsCount}
          values={{
            count: folderProjectCount || 0,
          }}
        />
      }
      {...chooserProps}
    />
  );
}

ItemComponent.propTypes = {
  icon: PropTypes.element.isRequired,
  item: PropTypes.instanceOf(Map).isRequired,
  chooserProps: chooserPropsShape.isRequired,
};
