import { createContext, useContext } from 'react';
import { bool, func, shape } from 'prop-types';

const LayoutControlsContext = createContext({
  closeContextSidebar: f => f,
  closeMainSidebar: f => f,
  openContextSidebar: f => f,
  openMainSidebar: f => f,
  toggleSidebars: f => f,
});

const LayoutInfoContext = createContext({
  contextSidebarOpened: false,
  mainSidebarOpened: true,
});

export const LayoutControlsProvider = LayoutControlsContext.Provider;
export const LayoutInfoProvider = LayoutInfoContext.Provider;

export function useLayoutControls() {
  const context = useContext(LayoutControlsContext);
  if (context === undefined) {
    throw new Error(
      '`useLayoutControls` must be used within a `LayoutControlsProvider`.',
    );
  }
  return context;
}

export function useLayoutInfo() {
  const context = useContext(LayoutInfoContext);
  if (context === undefined) {
    throw new Error(
      '`useLayoutInfo` must be used within a `LayoutInfoProvider`.',
    );
  }
  return context;
}

export function useLayout() {
  return [useLayoutInfo(), useLayoutControls()];
}

export const layoutControlsShape = shape({
  closeContextSidebar: func,
  closeMainSidebar: func,
  openContextSidebar: func,
  openMainSidebar: func,
  toggleSidebars: func,
});

export const layoutInfoShape = shape({
  contextSidebarOpened: bool,
  mainSidebarOpened: bool,
});
