import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Container from '../styles/LayoutContainer';
import Content from './Content';
import ContextSidebar from './ContextSidebar';
import MainSidebar from './MainSidebar';
import ToggleIcon from './ToggleIcon';
import { LayoutControlsProvider, LayoutInfoProvider } from '../utils';

class SidebarLayout extends Component {
  controls = {
    closeContextSidebar: () => this.setState({ contextSidebarOpened: false }),
    openContextSidebar: () => this.setState({ contextSidebarOpened: true }),

    closeMainSidebar: () => this.setState({ mainSidebarOpened: false }),
    openMainSidebar: () => this.setState({ mainSidebarOpened: true }),

    toggleSidebars: () =>
      this.setState(({ mainSidebarOpened, contextSidebarOpened }) => ({
        mainSidebarOpened: !mainSidebarOpened,
        contextSidebarOpened: !contextSidebarOpened,
      })),
  };

  constructor(props) {
    super(props);

    const { contextSidebarOpened, mainSidebarOpened } = props;

    this.state = {
      contextSidebarOpened,
      mainSidebarOpened,
    };
  }

  render() {
    const { id, className, children, contextSidebarMode } = this.props;
    const { contextSidebarOpened, mainSidebarOpened } = this.state;

    return (
      <LayoutControlsProvider value={this.controls}>
        <LayoutInfoProvider value={this.state}>
          <Container
            id={id}
            className={classNames(className, {
              'context-sidebar-opened': contextSidebarOpened,
              'main-sidebar-opened': mainSidebarOpened,
            })}
            contextMode={contextSidebarMode}
          >
            {children}
          </Container>
        </LayoutInfoProvider>
      </LayoutControlsProvider>
    );
  }
}

SidebarLayout.Content = Content;
SidebarLayout.Content.displayName = 'SidebarLayout.Content';

SidebarLayout.ContextSidebar = ContextSidebar;
SidebarLayout.ContextSidebar.displayName = 'SidebarLayout.ContextSidebar';

SidebarLayout.MainSidebar = MainSidebar;
SidebarLayout.MainSidebar.displayName = 'SidebarLayout.MainSidebar';

SidebarLayout.ToggleIcon = ToggleIcon;
SidebarLayout.ToggleIcon.displayName = 'SidebarLayout.ToggleIcon';

SidebarLayout.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  contextSidebarMode: PropTypes.oneOf(['resize', 'shift']),
  contextSidebarOpened: PropTypes.bool,
  mainSidebarOpened: PropTypes.bool,
};

SidebarLayout.defaultProps = {
  id: null,
  className: null,
  contextSidebarMode: 'shift',
  contextSidebarOpened: false,
  mainSidebarOpened: true,
};

SidebarLayout.displayName = 'SidebarLayout';

export default SidebarLayout;
