import React from 'react';

export default function EmtpyProjectIllustrationSvg() {
  return (
    <svg
      width="213"
      height="208"
      viewBox="0 0 213 208"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.203125 9.5V0.5H9.20312V2.5H2.20312V9.5H0.203125ZM36.2031 9.5V0.5H27.2031V2.5H34.2031V9.5H36.2031ZM36.2031 27.5H34.2031V34.5H27.2031V36.5H36.2031V27.5ZM9.20312 36.5V34.5H2.20312V27.5H0.203125V36.5H9.20312Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.2031 31.875V24.5H101.578V26.5H96.2031V31.875H94.2031ZM131.078 24.5H116.328V26.5H131.078V24.5ZM145.828 24.5V26.5H160.578V24.5H145.828ZM175.328 24.5V26.5H190.078V24.5H175.328ZM204.828 24.5V26.5H210.203V31.875H212.203V24.5H204.828ZM212.203 46.625H210.203V61.375H212.203V46.625ZM212.203 76.125H210.203V90.875H212.203V76.125ZM212.203 105.625H210.203V120.375H212.203V105.625ZM212.203 135.125H210.203V140.5H204.828V142.5H212.203V135.125ZM190.078 142.5V140.5H175.328V142.5H190.078ZM160.578 142.5V140.5H145.828V142.5H160.578ZM131.078 142.5V140.5H116.328V142.5H131.078ZM101.578 142.5V140.5H96.2031V135.125H94.2031V142.5H101.578ZM94.2031 120.375H96.2031V105.625H94.2031V120.375ZM94.2031 90.875H96.2031V76.125H94.2031V90.875ZM94.2031 61.375H96.2031V46.625H94.2031V61.375Z"
        fill="#5C62CA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M175.203 57.5H44.2031V188.5H175.203V57.5ZM109.203 92.5H112.203V121.5H141.203V124.5H112.203V154.5H109.203V124.5H79.2031V121.5H109.203V92.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.20312 146.25V137.5H15.9531V139.5H9.20312V146.25H7.20312ZM50.9531 137.5H33.4531V139.5H50.9531V137.5ZM68.4531 137.5V139.5H75.2031V146.25H77.2031V137.5H68.4531ZM77.2031 163.75H75.2031V181.25H77.2031V163.75ZM77.2031 198.75H75.2031V205.5H68.4531V207.5H77.2031V198.75ZM50.9531 207.5V205.5H33.4531V207.5H50.9531ZM15.9531 207.5V205.5H9.20312V198.75H7.20312V207.5H15.9531ZM7.20312 181.25H9.20312V163.75H7.20312V181.25Z"
        fill="#FFC51F"
      />
    </svg>
  );
}
