import { defineMessages } from 'react-intl';

export default defineMessages({
  messageEmptySharedProjects: {
    id: 'EmptySharedWithMeBox.messageEmptySharedProjects',
    defaultMessage: 'Projects shared with you will appear here.',
  },
  titleEmptySharedProjects: {
    id: 'EmptySharedWithMeBox.titleEmptySharedProjects',
    defaultMessage: 'Nobody shared projects with you yet.',
  },
  labelNewProject: {
    id: 'NewProjectBox.labelNewProject',
    defaultMessage: 'New project',
  },
});
