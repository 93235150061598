import React, { useCallback, useMemo, useState, memo, useEffect } from 'react';
import styled from 'styled-components/macro';
import {
  Brush as BrushSvg,
  PanTool as PanToolSvg,
  Place as PlaceSvg,
} from '@styled-icons/material';
import Keyboardist from 'react-keyboardist';

import { Box, Icon } from 'src/modules/prisma';
import { useObserveClientRect } from 'src/hooks/use-client-rect';

import { TargetInfo } from '../types';
import { useAnnotationsControls } from '../providers/AnnotationsProvider';
import { ANNOTATION_IN_PROGRESS_ID } from '../models/Annotation';
import { ToolbarTool, ToolbarToolWithColorChooser } from './ToolbarTool';
import { MarkupColor } from '../models/Markup';

export enum MarkupTool {
  Brush = 'brush',
  Hand = 'hand',
  Pin = 'pin',
}

const ToolbarContainer = styled.div`
  position: relative;
  z-index: 1;
  .tool {
    display: inline-block;
    position: relative;
  }

  > ${Icon} {
    border-radius: 55% / 52.5%;
    position: relative;
    z-index: 0;

    &:hover {
      z-index: 1;
    }

    &.active {
      z-index: 2;
    }

    :first-of-type {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    :last-of-type {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    :not(:first-of-type):not(:last-of-type) {
      border-radius: 0;
    }
  }

  > *:not(${Icon /* sc-sel */}) {
    ${Icon} {
      border-radius: 55% / 52.5%;
      position: relative;
      z-index: 0;
    }

    & ${Icon /* sc-sel */}:hover {
      z-index: 1;
    }

    & ${Icon /* sc-sel */}.active {
      z-index: 2;
    }

    &:first-of-type {
      ${Icon} {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:last-of-type {
      ${Icon} {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    :not(:first-of-type):not(:last-of-type) {
      ${Icon} {
        border-radius: 0;
      }
    }
  }
`;

function MarkupToolbar({
  targetType,
  color,
  tool,
  onSelectColor,
  onSelectTool,
}: {
  color: MarkupColor;
  tool: MarkupTool;
  onSelectColor: (color: MarkupColor) => void;
  onSelectTool: (tool: MarkupTool) => void;
} & Pick<TargetInfo, 'targetType'>) {
  const { remove, deselect } = useAnnotationsControls();
  const [rect, ref] = useObserveClientRect<HTMLElement>();
  const [prevTool, setPrevTool] = useState<MarkupTool | null>(null);

  const handleSelectTool = useCallback(
    newTool => {
      setPrevTool(tool);
      onSelectTool(newTool);
    },
    [onSelectTool, tool, setPrevTool],
  );

  const bindings = useMemo(
    () => ({
      down: {
        Digit1: () => handleSelectTool(MarkupTool.Hand),
        Digit2: () => handleSelectTool(MarkupTool.Pin),
        Digit3: () => handleSelectTool(MarkupTool.Brush),
        Space: (event: React.KeyboardEvent) => {
          if (
            // For videos, space bar already handles pause / resume,
            // so we should not to use it as pan shortcut as well.
            targetType !== 'video' &&
            !event.repeat /* repeat: true means key's being pressed */
          ) {
            handleSelectTool(MarkupTool.Hand);
          }
        },
      },
      up: {
        Space: () => {
          // For videos, space bar already handles pause / resume,
          // so we should not to use it as pan shortcut as well.
          if (targetType !== 'video') handleSelectTool(prevTool);
        },
      },
    }),
    [handleSelectTool, prevTool, targetType],
  );

  useEffect(
    function removeInProgressWhenToolChanges() {
      if (
        (tool === MarkupTool.Brush && prevTool === MarkupTool.Pin) ||
        (tool === MarkupTool.Pin && prevTool === MarkupTool.Brush)
      ) {
        remove(ANNOTATION_IN_PROGRESS_ID);
        deselect();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tool],
  );

  return (
    <>
      <Keyboardist
        key={`${tool}-${prevTool}-down`}
        bindings={bindings.down}
        eventName="keydown"
      />
      <Keyboardist
        key={`${tool}-${prevTool}-up`}
        bindings={bindings.up}
        eventName="keyup"
      />
      <Box
        bottom={targetType === 'video' ? 8 : 5}
        left={rect ? `calc(50% - ${rect.width / 2}px)` : '50%'}
        position="fixed"
        ref={ref}
      >
        <ToolbarContainer>
          <ToolbarTool
            label="Hand (1)"
            onClickTool={bindings.down.Digit1}
            icon={<PanToolSvg />}
            selected={tool === MarkupTool.Hand}
          />
          <ToolbarToolWithColorChooser
            color={color}
            label="Pin (2)"
            icon={<PlaceSvg />}
            selected={tool === MarkupTool.Pin}
            onClickColor={onSelectColor}
            onClickTool={bindings.down.Digit2}
          />
          <ToolbarToolWithColorChooser
            color={color}
            label="Brush (3)"
            icon={<BrushSvg />}
            selected={tool === MarkupTool.Brush}
            onClickColor={onSelectColor}
            onClickTool={bindings.down.Digit3}
          />
        </ToolbarContainer>
      </Box>
    </>
  );
}

export default memo(MarkupToolbar);
