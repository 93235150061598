import React from 'react';
import PropTypes from 'prop-types';

import PreviewPlaceholder from 'src/components/PreviewPlaceholder';
import { ACCEPTED_IMAGE_FILE_TYPES } from 'src/constants';
import PreviewLoadingPlaceholder from './PreviewPlaceholder';
import PreviewImg from '../styles/PreviewImg';

const Preview = ({ preview, type }) => {
  if (!ACCEPTED_IMAGE_FILE_TYPES.includes(type) || type === 'image/tiff') {
    return <PreviewPlaceholder type={type} />;
  }

  if (preview) {
    return <PreviewImg src={preview} />;
  }

  return <PreviewLoadingPlaceholder />;
};

Preview.propTypes = {
  preview: PropTypes.string,
  type: PropTypes.string.isRequired,
};

Preview.defaultProps = {
  preview: undefined,
};

export default Preview;
