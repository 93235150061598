import { defineMessages } from 'react-intl';

export default defineMessages({
  labelDeleteProject: {
    defaultMessage: 'Delete project',
    id: 'ProjectPageHeaderActionsDropdown.labelDeleteProject',
  },
  labelDownload: {
    defaultMessage: 'Download',
    id: 'ProjectPageActionsButtons.labelDownload',
  },
  labelInfo: {
    defaultMessage: 'Info',
    id: 'ProjectPageActionsButtons.labelInfo',
  },
  labelBackToFiles: {
    defaultMessage: 'Back to files',
    id: 'ProjectPageActionsButtons.labelBackToFiles',
  },
  labelAddImage: {
    id: 'ProjectPageHeaderHeader.labelAddImage',
    defaultMessage: 'Add files',
  },
  labelComments: {
    id: 'ProjectPageActionsButtons.labelComments',
    defaultMessage: 'Comments',
  },
  messageBackToProjects: {
    id: 'ProjectPageHeader.messageBackToProjects',
    defaultMessage: 'Back to projects',
  },
  labelDuplicateProject: {
    id: 'ProjectPageHeader.labelDuplicateProject',
    defaultMessage: 'Duplicate',
  },

  labelReviewDone: {
    id: 'ProjectPageHeader.labelReviewDone',
    defaultMessage: 'Done with my review',
  },
  labelUploadDone: {
    id: 'ProjectPageHeader.labelUploadDone',
    defaultMessage: 'Done with my uploads',
  },
  labelFollowProject: {
    id: 'ProjectPageHeader.labelFollowProject',
    defaultMessage: 'Follow',
  },
  labelUnfollowProject: {
    id: 'ProjectPageHeader.labelUnfollowProject',
    defaultMessage: 'Unfollow',
  },
});
