import React from 'react';
import { Box } from '@rebass/grid';
import PropTypes from 'prop-types';

import { formattedMessage } from 'src/utils/app-prop-types';

import ItemIcon from '../styles/NavigationItemIcon';
import ItemLink from '../styles/NavigationItemLink';
import ItemText from '../styles/NavigationItemText';
import { NavigationControlConsumer } from '../utils/navigation-control';

const NavigationBackItem = ({ label, onNavigate, to }) => (
  <NavigationControlConsumer>
    {navigationControl => (
      <Box mb={6} pl={7}>
        <ItemLink to={to} onClick={onNavigate || navigationControl.onNavigate}>
          <ItemIcon type="arrow_back" />
          <ItemText>{label}</ItemText>
        </ItemLink>
      </Box>
    )}
  </NavigationControlConsumer>
);

NavigationBackItem.propTypes = {
  label: formattedMessage,
  onNavigate: PropTypes.func,
  to: PropTypes.string,
};

NavigationBackItem.defaultProps = {
  label: 'Return',
  onNavigate: null,
  to: '/',
};

export default NavigationBackItem;
