import { createContext, useContext } from 'react';

export type SidebarControlType = {
  closeContextSidebar: () => void;
  openContextSidebar: () => void;
  closeMainSidebar: () => void;
  openMainSidebar: () => void;
  toggleSidebars: () => void;
  mainSidebarOpened: boolean;
  contextSidebarOpened: boolean;
};

const SidebarControlContext = createContext<SidebarControlType>({
  closeContextSidebar: () => {},
  openContextSidebar: () => {},
  closeMainSidebar: () => {},
  openMainSidebar: () => {},
  toggleSidebars: () => {},
  mainSidebarOpened: true,
  contextSidebarOpened: false,
});

export const SidebarControlProvider = SidebarControlContext.Provider;
export const SidebarControlConsumer = SidebarControlContext.Consumer;

export function useSidebarControls() {
  const context = useContext(SidebarControlContext);
  if (context === undefined) {
    throw new Error(
      '`useSidebarControls` must be used within a `SidebarControlProvider`.',
    );
  }
  return context;
}
