import React, { useEffect, useCallback, useMemo } from 'react';
import { toastRaw, TOAST_POSITION } from '@picter/prisma';

import { useToriiActions } from 'src/modules/torii';
import executeInCase from 'src/utils/execute-in-case';

import UploadIndicator from './UploadIndicator';

const GroupControlledUploadIndicator = ({ group, id, cancelGroupUploads }) => {
  const { bulkDestroy, load } = useToriiActions();

  const { preview, type } = useMemo(() => {
    const files = Object.values(group.files);

    const currentUpload =
      files.find(item => !item.uploadComplete) || files[files.length - 1];

    return {
      preview: currentUpload && currentUpload.preview,
      type: currentUpload.type,
    };
  }, [group]);

  const {
    uploadedCount,
    totalCount,
    uploadedBytes,
    totalBytes,
    progress,
  } = group;

  const onClickStop = useCallback(async () => {
    const {
      cancelledNewFiles,
      cancelledNewVersions,
    } = await cancelGroupUploads(id);
    if (cancelledNewFiles.length) {
      bulkDestroy(
        'wsProjectImages',
        cancelledNewFiles.map(canceledId => ({ id: canceledId })),
      );
    }
    if (cancelledNewVersions.length) {
      cancelledNewVersions.forEach(cancelledNewVersion =>
        load('wsProjectImages', { id: cancelledNewVersion }),
      );
    }
    setTimeout(() => {
      toastRaw.dismiss(id);
    }, 1000);
  }, [load, bulkDestroy, cancelGroupUploads, id]);

  useEffect(
    () => {
      executeInCase({
        [uploadedCount !== totalCount && !toastRaw.isActive(id)]: () => {
          // If toast with this id is not active, and we have upload,
          // then we should activate the toast with the upload data.
          return toastRaw(
            <UploadIndicator
              id={id}
              uploadedCount={uploadedCount}
              totalCount={totalCount}
              preview={preview}
              type={type}
              onClickStop={onClickStop}
              uploadedBytes={uploadedBytes}
              totalBytes={totalBytes}
              progress={progress}
            />,
            {
              autoClose: false,
              closeOnClick: false,
              position: TOAST_POSITION.BOTTOM_RIGHT,
              toastId: id,
            },
          );
        },
        [uploadedCount !== totalCount && toastRaw.isActive(id)]: () => {
          // If toast is already active and we still have uploads,
          // then we should update toast data.
          return toastRaw.update(id, {
            render: (
              <UploadIndicator
                id={id}
                uploadedCount={uploadedCount}
                totalCount={totalCount}
                preview={preview}
                type={type}
                onClickStop={onClickStop}
                uploadedBytes={uploadedBytes}
                totalBytes={totalBytes}
                progress={progress}
              />
            ),
          });
        },
        // If toast is already active and we have no uploads left,
        // then we should deactivate the toast. Also works for errors once
        // we are filtering erros when getting `uploading` value.
        [uploadedCount === totalCount && toastRaw.isActive(id)]: () => {
          setTimeout(() => {
            toastRaw.dismiss(id);
          }, 1000);
        },
      });
    },
    // "onClickStop/cancelGroupUploads" is updated together with group but for
    // our use is only important to get the updated function when uploaded has changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      id,
      preview,
      uploadedCount,
      totalCount,
      uploadedBytes,
      totalBytes,
      progress,
    ],
  );

  useEffect(() => {
    return () => toastRaw.dismiss(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default GroupControlledUploadIndicator;
