import styled from 'styled-components/macro';
import { flexbox, get, system } from 'src/modules/prisma';

const outline = system({
  outline: {
    property: 'boxShadow',
    scale: 'colors',
    transform: (value, scale) => `0 0 0 3px ${get(scale, value, value)}`,
  },
});

const overlapSize = system({
  overlapSize: {
    property: 'marginLeft',
    scale: 'space',
    transform: (value, scale) => -get(scale, value, value),
  },
});

const IconGroupStacked = styled.div.attrs(props => ({
  role: props.onClick ? 'button' : null,
}))`
  ${flexbox};
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.onClick ? 'pointer' : null)};
  display: flex;
  position: relative;
  max-width: 150px;

  > * {
    ${outline};
    border-radius: 100%;
    position: relative;
    z-index: 0;

    :hover {
      z-index: 1;
    }

    :not(:nth-child(6n + 1)) {
      ${overlapSize};
    }
  }
`;

export default IconGroupStacked;
