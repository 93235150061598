import styled from 'styled-components/macro';
import { layout } from 'styled-system';

const TruncateBox = styled.div`
  ${layout};
  overflow: hidden;

  > * {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
  }
`;

export default TruncateBox;
