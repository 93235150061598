import { defineMessages } from 'react-intl';

export default defineMessages({
  labelNewProject: {
    id: 'FolderPage.labelNewProject',
    defaultMessage: 'New project',
  },
  messageRenamedSuccess: {
    id: 'FolderPage.messageFolderRenamedSuccess',
    defaultMessage: 'Folder name changed successfully.',
  },
  messageBackToSpace: {
    id: 'FolderPage.messageBackToSpace',
    defaultMessage: 'Back to space',
  },
  messageEmptyDashboardTitle: {
    id: 'FolderPage.messageEmptyDashboardTitle',
    defaultMessage: `This folder's a blank canvas`,
  },
  messageEmptyDashboardText: {
    id: 'FolderPage.messageEmptyDashboardText',
    defaultMessage:
      'Time to get creative, or move existing projects here to fill the void.',
  },
});
