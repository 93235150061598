import React from 'react';
import PropTypes from 'prop-types';
import { Edit as EditSvg } from '@styled-icons/material';
import { FormattedMessage } from 'react-intl';
import { TextField } from '@picter/prisma';

import { useProject } from 'src/hooks/use-resource';
import { projectCollectionSelectorById } from 'src/selectors/collection';

import useEditResourceFieldModal from 'src/hooks/use-edit-resource-field-modal';

import InteractiveIcon from '../styles/InteractiveIcon';
import messages from '../messages';

function RenameCollectionIcon({ collectionId, projectPublicUrlKey }) {
  const project = useProject(
    {
      id: projectPublicUrlKey,
      include: ['collections'],
    },
    { request: false },
  );
  const collection = projectCollectionSelectorById(
    { project },
    { id: collectionId },
  );

  const [
    renameCollectionModal,
    { open: openRenameCollectionModal },
  ] = useEditResourceFieldModal({
    element: <TextField maxLength={50} name="name" />,
    field: 'name',
    name: 'Rename collection',
    querySerializers: {
      publicKey: () => `publicKey=${projectPublicUrlKey}`,
    },
    resource: collection,
    title: <FormattedMessage {...messages.titleRenameCollection} />,
  });

  return (
    <>
      <InteractiveIcon
        color="grey.600"
        onClick={openRenameCollectionModal}
        type={<EditSvg />}
      />
      {renameCollectionModal}
    </>
  );
}

RenameCollectionIcon.propTypes = {
  collectionId: PropTypes.string.isRequired,
  projectPublicUrlKey: PropTypes.string.isRequired,
};

export default RenameCollectionIcon;
