import camelCase from 'lodash/camelCase';

const formatOptimisticPayload = (model, data) => {
  const isBulkAction = Array.isArray(data);
  const parsedData = isBulkAction ? data : [data];
  const formattedModel = camelCase(model);

  const result = parsedData.reduce(
    (acc, { id, attributes = undefined, relationships = undefined } = {}) => {
      let parsedRelationships;

      if (relationships) {
        parsedRelationships = Object.entries(relationships).reduce(
          (prev, [key, value]) => ({
            ...prev,
            [key]: {
              data: value,
            },
          }),
          {},
        );
      }

      return {
        ...acc,
        [id]: {
          id,
          type: formattedModel,
          attributes,
          // We must only set the relationships key, if it's not undefined.
          // Otherwise the deep merge in the reducer is deleting all relationships
          // from the store.
          ...(parsedRelationships
            ? { relationships: parsedRelationships }
            : undefined),
        },
      };
    },
    {},
  );

  return {
    [formattedModel]: result,
  };
};

export default formatOptimisticPayload;
