import styled from 'styled-components/macro';
import { Button } from '@picter/prisma';

const UploadButton = styled(Button)`
  display: block;

  @media screen and (min-width: ${props => props.theme.breakpoints[3]}) {
    max-width: 190px;
    width: 100%;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints[4]}) {
    max-width: 225px;
    width: 100%;
  }
`;

export default UploadButton;
