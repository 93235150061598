import { defineMessages } from 'react-intl';

export default defineMessages({
  notificationsTitle: {
    id: 'Notifications.notificationsTitle',
    defaultMessage: 'Notifications',
  },
  markAllAsReadLabel: {
    id: 'Notifications.markAllAsReadLabel',
    defaultMessage: 'Mark all as read',
  },
  emptyNotificationsLabel: {
    id: 'Notifications.emptyNotificationsLabel',
    defaultMessage: 'You dont have any notification yet',
  },
  unauthenticatedUser: {
    id: 'Notifications.unauthenticatedUser',
    defaultMessage: 'Unknown person',
  },
  untitledTargetLabel: {
    id: 'Notifications.untitledTargetLabel',
    defaultMessage: `Untitled {targetType, select,
        wsProjects {project}
        wsProjectImages {file}
        wsComments {comment}
        wsCollections {collection}
      }`,
  },
  removedTargetLabel: {
    id: 'Notifications.removedTargetLabel',
    defaultMessage: `Removed {targetType, select,
        wsProjects {project}
        wsProjectImages {file}
        wsComments {comment}
        wsCollections {collection}
      }`,
  },
  newCommentNotificationText: {
    id: 'Notifications.newCommentNotificationText',
    defaultMessage: `<b>{actor, select,
      undefined {An unknown person}
      other {{actor}}
    }</b> left {count, plural,
        one {a comment}
        other {# comments}
    } on <b>{target, select,
        undefined {{untitledTargetLabel}}
        null {{removedTargetLabel}}
        other {{target}}
      }</b>`,
  },
  messageNewReplyNotificationText: {
    id: 'Notifications.messageNewReplyNotificationText',
    defaultMessage: `<b>{actor, select,
      undefined {An unknown person}
      other {{actor}}
    }</b> replied to a thread: <b>{snippet}</b>`,
  },
  messageNewRepliesNotificationText: {
    id: 'Notifications.messageNewRepliesNotificationText',
    defaultMessage: `<b>{count, plural,
        one {New reply}
        other {# new replies}
    }</b> on a thread: <b>{snippet}</b>`,
  },
  invitationAcceptedNotificationText: {
    id: 'Notifications.invitationAcceptedNotificationText',
    defaultMessage: `<b>{actor, select,
      undefined {An unknown person}
      other {{actor}}
    }</b> accepted their invitation to <b>{target, select,
        undefined {{untitledTargetLabel}}
        null {{removedTargetLabel}}
        other {{target}}
      }</b>`,
  },
  newUploadedFileNotificationText: {
    id: 'Notifications.newUploadedFileNotificationText',
    defaultMessage: `<b>{actor, select,
      undefined {An unknown person}
      other {{actor}}
    }</b> uploaded {count, plural,
        one {a new file}
        other {# new files}
    } to <b>{target, select,
        undefined {{untitledTargetLabel}}
        null {{removedTargetLabel}}
        other {{target}}
      }</b>`,
  },
  mentionedNotificationText: {
    id: 'Notifications.mentionedNotificationText',
    defaultMessage: `<b>{actor, select,
      undefined {An unknown person}
      other {{actor}}
    }</b> mentioned you {count, plural,
      one {}
      other {# times }
    }on <b>{target, select,
        undefined {{untitledTargetLabel}}
        null {{removedTargetLabel}}
        other {{target}}
      }</b>`,
  },
  messageResolveComment: {
    id: 'Notifications.messageResolveComment',
    defaultMessage: `<b>{actor, select,
      undefined {An unknown person}
      other {{actor}}
    }</b> resolved {count, plural,
      one {a comment}
      other {# comments}
    } on <b>{target, select,
      undefined {{untitledTargetLabel}}
      null {{removedTargetLabel}}
      other {{target}}
    }</b>`,
  },
  messageUnresolveComment: {
    id: 'Notifications.messageUnresolveComment',
    defaultMessage: `<b>{actor, select,
      undefined {An unknown person}
      other {{actor}}
    }</b> unresolved {count, plural,
      one {a comment}
      other {# comments}
    } on <b>{target, select,
      undefined {{untitledTargetLabel}}
      null {{removedTargetLabel}}
      other {{target}}
    }</b>`,
  },
  messageAssignComment: {
    id: 'Notifications.messageAssignComment',
    defaultMessage: `<b>{actor, select,
      undefined {An unknown person}
      other {{actor}}
    }</b> assigned {count, plural,
      one {a task}
      other {# tasks}
    } to you on <b>{target, select,
        undefined {{untitledTargetLabel}}
        null {{removedTargetLabel}}
        other {{target}}
      }</b>`,
  },
  messageUnassignComment: {
    id: 'Notifications.messageAssignComment',
    defaultMessage: `<b>{actor, select,
      undefined {An unknown person}
      other {{actor}}
    }</b> unassigned {count, plural,
      one {a task}
      other {# tasks}
    } from you on <b>{target, select,
        undefined {{untitledTargetLabel}}
        null {{removedTargetLabel}}
        other {{target}}
      }</b>`,
  },
  messageReassignCommentToYou: {
    id: 'Notifications.messageReassignComment',
    defaultMessage: `<b>{actor, select,
      undefined {An unknown person}
      other {{actor}}
    }</b> reassigned {count, plural,
      one {a task}
      other {# tasks}
    } to you on <b>{target, select,
        undefined {{untitledTargetLabel}}
        null {{removedTargetLabel}}
        other {{target}}
      }</b>`,
  },
  messageReassignCommentFromYou: {
    id: 'Notifications.messageReassignComment',
    defaultMessage: `<b>{actor, select,
      undefined {An unknown person}
      other {{actor}}
    }</b> reassigned {count, plural,
      one {a task}
      other {# tasks}
    } to {assignedToThemself, select,
      true {themself}
      other {<b>{newAssignee}</b>}
    } on <b>{target, select,
        undefined {{untitledTargetLabel}}
        null {{removedTargetLabel}}
        other {{target}}
      }</b>`,
  },
  approvedImageNotificationText: {
    id: 'Notifications.approvedImageNotificationText',
    defaultMessage: `<b>{actor, select,
      undefined {An unknown person}
      other {{actor}}
    }</b> approved some files in <b>{target, select,
      undefined {{untitledTargetLabel}}
      null {{removedTargetLabel}}
      other {{target}}
    }</b>`,
  },
  rejectedImageNotificationText: {
    id: 'Notifications.rejectedImageNotificationText',
    defaultMessage: `<b>{actor, select,
      undefined {An unknown person}
      other {{actor}}
    }</b> rejected some files in <b>{target, select,
      undefined {{untitledTargetLabel}}
      null {{removedTargetLabel}}
      other {{target}}
    }</b>`,
  },
  viewLinkNotificationText: {
    id: 'Notifications.viewLinkNotificationText',
    defaultMessage: `<b>{type, select,
      review {Review}
      present {Presentation}
      other {Inbox}
    }</b> link of <b>{target, select,
      undefined {{untitledTargetLabel}}
      null {{removedTargetLabel}}
      other {{target}}
    }</b> has been viewed {count, plural,
      one {# time}
      other {# times}
    }`,
  },
  finishInboxUploadNotificationText: {
    id: 'Notifications.finishInboxUploadNotificationText',
    defaultMessage: `New files have been uploaded to your inbox of <b>{target, select,
      undefined {{untitledTargetLabel}}
      null {{removedTargetLabel}}
      other {{target}}
    }: {object, select,
      undefined {{untitledObjectLabel}}
      null {{removedObjectLabel}}
      other {{object}}
    }`,
  },
  downloadImageNotificationText: {
    id: 'Notifications.downloadImagetNotificationText',
    defaultMessage: `<b>{actor, select,
      undefined {An unknown person}
      other {{actor}}
    }</b> downloaded {count, plural,
        one {a file}
        other {# files}
    } from <b>{target, select,
        undefined {{untitledTargetLabel}}
        null {{removedTargetLabel}}
        other {{target}}
      }</b>`,
  },
  downloadProjectNotificationText: {
    id: 'Notifications.downloadProjectNotificationText',
    defaultMessage: `<b>{actor, select,
      undefined {An unknown person}
      other {{actor}}
    }</b> downloaded <b>{target, select,
        undefined {{untitledTargetLabel}}
        null {{removedTargetLabel}}
        other {{target}}
      }</b>`,
  },
  replaceFileNotificationText: {
    id: 'Notifications.replaceFileNotificationText',
    defaultMessage: `<b>{actor, select,
      undefined {An unknown person}
      other {{actor}}
    }</b> replaced {count, plural,
        one {a file}
        other {# files}
    } in <b>{target, select,
        undefined {{untitledTargetLabel}}
        null {{removedTargetLabel}}
        other {{target}}
      }</b>`,
  },
});
