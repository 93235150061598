import { Dropdown as PrismaDropdown } from '@picter/prisma';
import Dropdown from './components/Dropdown';
import DropdownItem from './components/DropdownItem';
import DropdownLink from './components/DropdownLink';

Dropdown.Context = PrismaDropdown.Context;
Dropdown.Group = PrismaDropdown.Group;
Dropdown.Header = PrismaDropdown.Header;
Dropdown.Item = PrismaDropdown.Item;
Dropdown.Separator = PrismaDropdown.Separator;
Dropdown.Menu = PrismaDropdown.Menu;
Dropdown.Item = DropdownItem;
Dropdown.Link = DropdownLink;

export default Dropdown;
