import { defineMessages } from 'react-intl';

export default defineMessages({
  labelSelected: {
    id: 'ImageSelectionTopbar.labelSelected',
    defaultMessage: 'Selected',
  },
  labelSelectAll: {
    id: 'ImageSelectionTopbar.labelSelectAll',
    defaultMessage: 'Select all',
  },
  labelApply: {
    id: 'ImageSelectionTopbar.labelApply',
    defaultMessage: 'Apply to selection',
  },
  messageModalDelete: {
    id: 'ImageSelectionTopbar.messageModalDelete',
    defaultMessage: 'This will delete all selected files. Are you sure?',
  },
  labelRejectImage: {
    id: 'ImageSelectionTopbar.labelRejectImage',
    defaultMessage: 'Reject images',
  },
  labelRemoveRejection: {
    id: 'ImageSelectionTopbar.labelRemoveRejection',
    defaultMessage: 'Reset rejections',
  },
  messageImagesCopied: {
    id: 'ImageSelectionTopbar.messageImagesCopied',
    defaultMessage: 'Selection copied to {projectTitle}',
  },
});
