import uniq from 'lodash/uniq';
import { Facet, Filters } from './types';

type ResultItem = {
  getRaw: (fieldName: string) => string; // Returns the HTML-unsafe raw value for a field, if it exists
  getSnippet: (fieldName: string) => string; // Returns the HTML-safe snippet value for a field, if it exists
};

export type Client = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  search: (q: string, params: any) => Promise<any>;
};

type ResultList = {
  rawResults: string[]; // List of raw `results` from JSON response
  rawInfo: {
    // Object wrapping the raw `meta` property from JSON response
    meta: {};
  };
  results: ResultItem[]; // List of `results` wrapped in `ResultItem` type
  info: {
    // Currently the same as `rawInfo`
    meta: {};
    facets?: {
      [key: string]: Facet[];
    };
  };
};

function joinSpaceSaparatedHighlights(text?: string) {
  return text?.replace(/<\/em> <em>/g, ' ');
}

export async function loadSearchSuggestions(
  client: Client,
  q: string,
): Promise<string[]> {
  const response: ResultList = await client.search(q, {
    page: {
      size: 20,
      current: 1,
    },
    search_fields: { title: {} },
    result_fields: {
      title: {
        raw: {
          size: 200,
        },
      },
    },
  });
  const lowerCasedQuery = q.toLowerCase();
  const qFragments = lowerCasedQuery.split(' ');

  const sanitizedSuggestions = response.results
    .map((result: ResultItem) => {
      const normalizedTitles = result
        .getRaw('title')
        .toLowerCase()
        // replace all non-word characters (special chars)
        .replace(/([^\w])+/g, ' ')
        .trim();

      if (qFragments.length === 1) {
        // match word that contains qFragment + 2 succeeding words
        const matches = new RegExp(
          `\\w*${lowerCasedQuery}\\w*(?:\\s?\\w*){0,2}`,
          'g',
        ).exec(normalizedTitles);
        return matches ? matches[0] : '';
      }
      if (qFragments.length > 1) {
        const suggestion = uniq(
          qFragments
            .map((qFragment, index) => {
              // Match all words that contain qFragments. Only last
              // fragment can include a succeeding word.
              const regex =
                index < qFragments.length - 1
                  ? new RegExp(`\\w?${qFragment}\\w*`, 'g')
                  : new RegExp(`\\w?${qFragment}\\w*(?:\\s?\\w*){0,1}`, 'g');
              const matches = regex.exec(normalizedTitles);
              return matches ? matches[0] : null;
            })
            .filter(val => val)
            .join(' ')
            .split(' '),
        ).join(' ');
        return suggestion;
      }
      return normalizedTitles;
    })
    // get rid of empty suggestions
    .filter(val => val.length > 0);
  const suggestions = uniq(sanitizedSuggestions).slice(0, 6);
  return suggestions;
}

export async function search(client: Client, q: string, filters: Filters) {
  const response: ResultList = await client.search(q, {
    page: {
      size: 30,
      current: 1,
    },
    search_fields: { title: {}, body: {} },
    result_fields: {
      type: {
        raw: {},
      },
      files: {
        raw: {},
      },
      updated_at: {
        raw: {},
      },
      created_at: {
        raw: {},
      },
      parent_title: {
        snippet: { fallback: true },
      },
      title: {
        snippet: { fallback: true },
      },
      body: {
        snippet: { fallback: true, size: 150 },
      },
      project_key: {
        raw: {},
      },
      image: {
        raw: {},
      },
      project_image_id: {
        raw: {},
      },
      sharing: {
        raw: {},
      },
      folder_id: {
        raw: {},
      },
      parent_comment_id: {
        raw: {},
      },
    },
    facets: {
      type: [
        {
          type: 'value',
          name: 'type',
          sort: { count: 'desc' },
          size: 3,
        },
      ],
    },
    filters,
    disjunctiveFacets: ['type'],
  });
  const results = response.results.map((result: ResultItem) => {
    return {
      id: result.getRaw('id'),
      type: result.getRaw('type'),
      parentTitle: joinSpaceSaparatedHighlights(
        result.getSnippet('parent_title'),
      ),
      title: joinSpaceSaparatedHighlights(result.getSnippet('title')),
      body: joinSpaceSaparatedHighlights(result.getSnippet('body')),
      image: result.getRaw('image'),
      createdAt: result.getRaw('created_at'),
      updatedAt: result.getRaw('updated_at'),
      files: result.getRaw('files'),
      projectImageId: result.getRaw('project_image_id'),
      publicUrlKey: result.getRaw('project_key'),
      sharing: ((result.getRaw('sharing') || []) as unknown) as string[],
      folderId: result.getRaw('folder_id'),
      parentCommentId: result.getRaw('parent_comment_id'),
    };
  });

  return { results, facets: response.info.facets };
}
