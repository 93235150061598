import styled from 'styled-components/macro';
import { transparentize } from 'polished';

const UploadOverlay = styled.div`
  align-items: center;
  background-color: ${props =>
    transparentize(0.15, props.theme.colors.primary)};
  bottom: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 500;

  h1 {
    color: ${props => props.theme.colors.white};
    margin-top: ${props => props.theme.space[6]}px;
  }

  p {
    color: ${props => props.theme.colors.white};
  }
`;

export default UploadOverlay;
