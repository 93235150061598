import React from 'react';
import PropTypes from 'prop-types';

function HalfCircleSvg({ color, height, width, xmlns }) {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 26 26"
      width={width}
      xmlns={xmlns}
    >
      <path
        d="M24.7894 15.25C24.9277 14.5212 25 13.769 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25C13.9017 25 14.7801 24.9006 15.625 24.712"
        stroke={color}
        strokeWidth={1.5}
      />
    </svg>
  );
}

HalfCircleSvg.defaultProps = {
  color: 'currentColor',
  height: '26',
  width: '26',
  xmlns: 'http://www.w3.org/2000/svg',
};

HalfCircleSvg.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  xmlns: PropTypes.string,
};

export default HalfCircleSvg;
