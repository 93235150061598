import { defineMessages } from 'react-intl';

export default defineMessages({
  labelCompanySizeJustMe: {
    id: 'Global.labelCompanySizeJustMe',
    defaultMessage: 'Just me',
  },
  labelCompanySize1To5: {
    id: 'Global.labelCompanySize1To5',
    defaultMessage: '1 - 5',
  },
  labelCompanySize5To25: {
    id: 'Global.labelCompanySize5To25',
    defaultMessage: '5 - 25',
  },
  labelCompanySize25To50: {
    id: 'Global.labelCompanySize25To50',
    defaultMessage: '25 - 50',
  },
  labelCompanySize50To100: {
    id: 'Global.labelCompanySize50To100',
    defaultMessage: '50 - 100',
  },
  labelCompanySize100More: {
    id: 'Global.labelCompanySize100More',
    defaultMessage: '100+',
  },
});
