import { useMemo } from 'react';
import { List } from 'immutable';
import Cookies from 'js-cookie';
import { useRouteMatch } from 'react-router-dom';

import useProjectImages from 'src/hooks/use-project-images';
import { get } from 'src/utils/accessors';

const useGridImages = ({ project, filter, uploadGroup }) => {
  const match = useRouteMatch();
  const projectKey = match.params.projectId;
  const collectionCookie = Cookies.get(`inbox-key/${projectKey}`);
  const [collectionIdFromCookies] = collectionCookie
    ? collectionCookie.split(':')
    : [];
  const collectionId = collectionIdFromCookies || null;

  const collectionWithImages = get(
    project,
    'relationships.collections',
    List(),
  ).find(col => get(col, 'id') && get(col, 'id') === collectionId);

  const images = useProjectImages({
    project,
    collection: collectionWithImages,
    filter,
  });

  const filteredImages = useMemo(() => {
    if (!collectionId) {
      return List();
    }
    // Filters images that have no file and no upload pending. It means images which
    // uploads were canceled or something similar.
    return images.filter(image => {
      const upload = get(uploadGroup, `files.${get(image, 'id')}`);
      return get(image, 'id') || (upload && upload.preview && !upload.errors);
    });
  }, [collectionId, images, uploadGroup]);

  return { collection: collectionWithImages, images: filteredImages };
};

export default useGridImages;
