import React, { createContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { formatForLocalAction, useToriiActions } from 'src/modules/torii';
import { inflate } from 'pako';
import groupBy from 'lodash/groupBy';

import useRealtimeSocket from '../hooks/use-realtime-socket';

function formatResponse(response) {
  try {
    const base64Decoded = window.atob(response);
    const restored = JSON.parse(inflate(base64Decoded, { to: 'string' }));
    return formatForLocalAction(restored);
  } catch (e) {
    // Logs error
    Sentry.captureException(e);
    return [];
  }
}

export const JSONApiRealtimeContext = createContext();

export default function JSONApiRealtimeProvider({ children }) {
  const socket = useRealtimeSocket();
  const {
    localBulkCreate,
    localBulkUpdate,
    localBulkDestroy,
  } = useToriiActions();

  const onCreate = useCallback(
    response => {
      const resources = formatResponse(response);
      const resourcesGroupedByType = groupBy(resources, 'type');

      Object.entries(resourcesGroupedByType).forEach(([type, typeResources]) =>
        localBulkCreate(type, typeResources, {
          worker: typeResources.length > 30,
        }),
      );
    },
    [localBulkCreate],
  );

  const onUpdate = useCallback(
    response => {
      const data = formatResponse(response);
      const { type } = data[0];

      localBulkUpdate(type, data, { worker: data.length > 30 });
    },
    [localBulkUpdate],
  );

  const onDestroy = useCallback(
    response => {
      const data = formatResponse(response);
      const { type } = data[0];

      localBulkDestroy(type, data, { worker: data.length > 30 });
    },
    [localBulkDestroy],
  );

  const subscribe = useCallback(
    channels => {
      let subscriptions;
      let unsubscribe;

      if (Array.isArray(channels)) {
        subscriptions = channels
          .map(channelName => [
            socket.subscribe(channelName),
            // Subscribe to specific socket channel to receive specific events
            socket.subscribe(
              `${channelName};socketId=${socket.connection.socket_id}`,
            ),
          ])
          .flat();

        unsubscribe = () =>
          channels.forEach(channelName => {
            socket.unsubscribe(channelName);
            socket.unsubscribe(
              `${channelName};socketId=${socket.connection.socket_id}`,
            );
          });
      } else {
        subscriptions = [
          socket.subscribe(channels),
          // Subscribe to specific socket channel to receive specific events
          socket.subscribe(
            `${channels};socketId=${socket.connection.socket_id}`,
          ),
        ];

        unsubscribe = () => {
          socket.unsubscribe(channels);
          socket.unsubscribe(
            `${channels};socketId=${socket.connection.socket_id}`,
          );
        };
      }
      subscriptions.forEach(subscription => {
        subscription.bind('CREATE', onCreate);
        subscription.bind('UPDATE', onUpdate);
        subscription.bind('DESTROY', onDestroy);
      });

      return unsubscribe;
    },
    [socket, onCreate, onUpdate, onDestroy],
  );

  const value = useMemo(
    () => ({
      subscribe,
    }),
    [subscribe],
  );

  return (
    <JSONApiRealtimeContext.Provider value={value}>
      {children}
    </JSONApiRealtimeContext.Provider>
  );
}

JSONApiRealtimeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
