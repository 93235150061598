import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { TrackerEvent } from '@picter/tracker';

import { UploadAreaContext } from 'src/components/UploadArea';

import BiggerButton from '../styles/BiggerButton';
import messages from '../messages';

const AddImageButton = () => {
  const controls = useContext(UploadAreaContext);

  return (
    <TrackerEvent name="Add image (empty state)" trackClick>
      <BiggerButton onClick={controls.openFileDialog}>
        <FormattedMessage {...messages.labelAddImages} />
      </BiggerButton>
    </TrackerEvent>
  );
};

export default AddImageButton;
