import React, { lazy, Suspense } from 'react';
import { Spinner } from '@picter/prisma';
import { Switch, Route } from 'react-router-dom';
import { TrackerScope } from '@picter/tracker';
import RouteUnauthenticated from 'src/utils/cerberus/components/RouteUnauthenticated';
import RouteAuthenticated from 'src/utils/cerberus/components/RouteAuthenticated';
import PermissionProvider from 'src/components/PermissionProvider';

import RouteUnauthenticatedPlusLiteUsers from 'src/components/RouteUnauthenticatedPlusLiteUsers';
import IndexRoutes from './index/_routes';
import paths from '../routes/paths';

const InvitationsRoutes = lazy(() => import('./invitations/routes'));
const LoginPage = lazy(() => import('./login'));
const SignupPage = lazy(() => import('./signup'));
const ReferralPage = lazy(() => import('./referral'));
const ActivateAppSumoLicensePage = lazy(() =>
  import('./activate-appsumo-license'),
);
const OnboardPage = lazy(() => import('./onboard-from-contests'));
const PasswordRecoveryPage = lazy(() => import('./password-recovery'));
const ProfileSetupPage = lazy(() => import('./setup'));
const ResetPasswordPage = lazy(() => import('./reset-password'));

const {
  APP_PATH,
  LOGIN_PATH,
  SIGNUP_PATH,
  INVITATIONS_PATH,
  ONBOARD_PATH,
  PASSWORD_RECOVERY_PATH,
  PROFILE_SETUP_PATH,
  RESET_PASSWORD_PATH,
  REFERRAL_LINK_PATH,
  ACTIVATE_APP_SUMO_LICENSE_PATH,
} = paths;

const Routes = () => (
  <TrackerScope name="Workspace">
    <Suspense fallback={<Spinner />}>
      <PermissionProvider>
        <Switch>
          <RouteUnauthenticatedPlusLiteUsers
            path={LOGIN_PATH}
            component={LoginPage}
          />
          <RouteUnauthenticatedPlusLiteUsers
            path={SIGNUP_PATH}
            component={SignupPage}
          />
          <RouteUnauthenticatedPlusLiteUsers
            path={REFERRAL_LINK_PATH}
            component={ReferralPage}
          />
          <RouteUnauthenticated
            path={PASSWORD_RECOVERY_PATH}
            component={PasswordRecoveryPage}
          />
          <RouteUnauthenticated
            path={RESET_PASSWORD_PATH}
            component={ResetPasswordPage}
          />
          <RouteAuthenticated path={ONBOARD_PATH} component={OnboardPage} />
          <RouteAuthenticated
            path={PROFILE_SETUP_PATH}
            component={ProfileSetupPage}
          />
          <Route
            path={ACTIVATE_APP_SUMO_LICENSE_PATH}
            component={ActivateAppSumoLicensePage}
          />
          <Route path={INVITATIONS_PATH} component={InvitationsRoutes} />
          <Route path={APP_PATH} component={IndexRoutes} />
        </Switch>
      </PermissionProvider>
    </Suspense>
  </TrackerScope>
);

export default Routes;
