import { useMemo, useCallback } from 'react';
import { List } from 'immutable';

import { useToriiActions } from 'src/modules/torii';
import { get } from 'src/utils/accessors';

import { useCurrentUser } from './use-resource';

const useProjectFollow = project => {
  const { create, update } = useToriiActions();

  const user = useCurrentUser(undefined, { request: false });
  const followers = get(project, 'relationships.follows', List());

  const projectFollow = useMemo(() => {
    return followers.find(
      follower => get(follower, 'id').split(':')[1] === get(user, 'id'),
    );
  }, [followers, user]);

  const toggleFollow = useCallback(
    follow => {
      if (!projectFollow) {
        create('wsProjectFollows', {
          attributes: {
            follow,
          },
          relationships: {
            project: {
              type: 'ws-projects',
              id: get(project, 'id'),
            },
          },
        });
      } else {
        update('wsProjectFollows', {
          id: get(projectFollow, 'id'),
          attributes: {
            follow,
          },
          optimistic: true,
        });
      }
    },
    [project, projectFollow, create, update],
  );

  return {
    projectFollow,
    following: projectFollow && get(projectFollow, 'attributes.follow'),
    toggleFollow,
  };
};

export default useProjectFollow;
