import React, { createContext, useContext } from 'react';

const TooltipContext = createContext();

export function TooltipProvider(props) {
  return <TooltipContext.Provider {...props} />;
}

export function useTooltip() {
  return useContext(TooltipContext);
}
