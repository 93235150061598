import { useCallback, useMemo, useState } from 'react';
import { toastInfo } from '@picter/prisma';

import { useToriiActions } from 'src/modules/torii';
import useFormatMessage from 'src/hooks/use-format-message';
import useUpdatableState from 'src/hooks/use-updatable-state';
import { get } from 'src/utils/accessors';
import globalMessages from 'src/messages';

import messages from '../messages';

const useHandlers = ({ folder }) => {
  const formatMessage = useFormatMessage();
  const { destroy, update } = useToriiActions();
  const [isEditingName, setIsEditingName] = useState(false);
  const namePlaceholder = formatMessage(globalMessages.labelUntitledFolder);
  const [name, setName] = useUpdatableState(get(folder, 'attributes.name'));

  const count = useMemo(() => {
    const countRelation = get(folder, 'relationships.count');
    return formatMessage(messages.labelProjectsCount, {
      count:
        countRelation && !countRelation.isEmpty()
          ? get(countRelation, 'attributes.count')
          : 0,
    });
  }, [folder, formatMessage]);

  const handleRenameClick = useCallback(() => setIsEditingName(true), [
    setIsEditingName,
  ]);

  const handleRenameFolder = useCallback(
    async event => {
      const { value: newName } = event.target;
      const oldName = get(folder, 'attributes.name');

      setIsEditingName(false);

      if (newName !== oldName) {
        await update('wsFolders', {
          id: get(folder, 'id'),
          attributes: {
            name: newName,
          },
        });

        toastInfo(formatMessage(messages.messageRenamedSuccess));
      }
    },
    [update, folder, formatMessage],
  );

  const handleRemoveFolder = useCallback(async () => {
    await destroy('wsFolders', {
      id: get(folder, 'id'),
    });

    toastInfo(formatMessage(messages.messageRemovedSuccess));
  }, [destroy, folder, formatMessage]);

  return {
    name,
    count,
    setName,
    namePlaceholder,
    isEditingName,
    setIsEditingName,
    handleRenameClick,
    handleRenameFolder,
    handleRemoveFolder,
  };
};

export default useHandlers;
