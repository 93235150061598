import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle, ThemeProvider } from 'styled-components/macro';
import get from 'lodash/get';
import merge from 'lodash/merge';
import {
  AppNormalize,
  CustomPropTypes,
  FontLoader,
  ModernNormalize,
  picterTheme,
} from '@picter/prisma';

/**
 * TODO: Remove this global style when the Toaster component has been
 * migrated from `@picter/prisma`.
 *
 * Necessary to override `box-sizing: unset;` declared in the toast notifcation
 * styles.
 */
const TEMP_SetToastBoxSizing = createGlobalStyle`
  .toast-container .toast-container__toast {
    box-sizing: inherit !important;
  }
`;

const GlobalStyles = createGlobalStyle`
  .grecaptcha-badge {
    visibility: hidden;
  }
`;

function PrismaProvider({ children, fonts, globals, mode, theme }) {
  let t = Array.isArray(theme) ? merge({}, ...theme) : theme;

  if (t.colors) {
    t = merge({}, t, {
      mode,
      colors: {
        ...t.colors,
        ...get(t.colors.modes, mode, t.colors),
      },
    });
  }

  return (
    <>
      {globals}
      {fonts}
      <ThemeProvider theme={t}>{children}</ThemeProvider>
    </>
  );
}

PrismaProvider.defaultProps = {
  children: null,
  fonts: <FontLoader />,
  globals: (
    <>
      <ModernNormalize />
      <AppNormalize />
      {/* eslint-disable react/jsx-pascal-case */}
      <TEMP_SetToastBoxSizing />
      {/* eslint-enable react/jsx-pascal-case */}
      <GlobalStyles />
    </>
  ),
  mode: 'light',
  theme: picterTheme,
};

PrismaProvider.propTypes = {
  children: PropTypes.node,
  fonts: PropTypes.node,
  globals: PropTypes.node,
  mode: PropTypes.string,
  theme: CustomPropTypes.oneOrMoreOfType([PropTypes.object]),
};

export default PrismaProvider;
