import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from '@picter/prisma';
import {
  NotificationsOff as NotificationsOffSvg,
  NotificationsActive as NotificationsActiveSvg,
} from '@styled-icons/material';

import ActionsDropdown from 'src/components/ActionsDropdown';
import globalMessages from 'src/messages';
import usePermission from 'src/hooks/use-permission';

import messages from '../messages';

function ProjectPageHeaderActionsDropdown({
  page,

  onClickDeleteProject,
  onClickCopyProject,
  onClickRenameProject,
  following,
  onClickFollow,
  onClickRestoreProject,
  onClickMoveToTrash,
}) {
  const {
    canDeleteProject,
    canDuplicateProject,
    canEditProject,
    canFollowProject,
    canRestoreProject,
    canTrashProject,
  } = usePermission();

  if (!canEditProject && !canDuplicateProject && !canDeleteProject) {
    return null;
  }

  return (
    <ActionsDropdown page={page}>
      <ActionsDropdown.Group entity="project">
        {canFollowProject && (
          <>
            <ActionsDropdown.Custom
              keepMenuOpen
              action={following ? 'unfollow project' : 'follow project'}
              label={
                <FormattedMessage
                  {...(following
                    ? messages.labelUnfollowProject
                    : messages.labelFollowProject)}
                />
              }
              icon={
                following ? <NotificationsOffSvg /> : <NotificationsActiveSvg />
              }
              onClick={() => onClickFollow(!following)}
            />
            <Dropdown.Separator />
          </>
        )}
        {canEditProject && (
          <ActionsDropdown.Rename
            entity="project"
            onClick={onClickRenameProject}
          />
        )}
        {canDuplicateProject && (
          <ActionsDropdown.Duplicate
            entity="project"
            onClick={onClickCopyProject}
          />
        )}
        {(canEditProject || canDuplicateProject) && <Dropdown.Separator />}
        {canDeleteProject && (
          <ActionsDropdown.Delete
            entity="project"
            onClick={onClickDeleteProject}
            label={<FormattedMessage {...messages.labelDeleteProject} />}
            confirmMessage={
              <FormattedMessage {...globalMessages.messageDeleteProject} />
            }
          />
        )}
        {canTrashProject && (
          <ActionsDropdown.MoveToTrash
            entity="project"
            onClick={onClickMoveToTrash}
          />
        )}
        {canRestoreProject && (
          <ActionsDropdown.Restore
            entity="project"
            onClick={onClickRestoreProject}
          />
        )}
      </ActionsDropdown.Group>
    </ActionsDropdown>
  );
}

ProjectPageHeaderActionsDropdown.propTypes = {
  page: PropTypes.string,
  following: PropTypes.bool,
  onClickFollow: PropTypes.func,
  onClickDeleteProject: PropTypes.func,
  onClickRenameProject: PropTypes.func,
  onClickCopyProject: PropTypes.func,
  onClickRestoreProject: PropTypes.func,
  onClickMoveToTrash: PropTypes.func,
};

ProjectPageHeaderActionsDropdown.defaultProps = {
  page: undefined,
  following: undefined,
  onClickFollow: undefined,
  onClickDeleteProject: undefined,
  onClickRenameProject: undefined,
  onClickCopyProject: undefined,
  onClickRestoreProject: undefined,
  onClickMoveToTrash: undefined,
};

export default ProjectPageHeaderActionsDropdown;
