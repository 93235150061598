import React, { useMemo } from 'react';

import { Annotation, ANNOTATION_IN_PROGRESS_ID } from '../models/Annotation';
import MarkupGraphic from './MarkupGraphic';
import { useAnnotations } from '../providers/AnnotationsProvider';
import { getScaledStrokeWidth } from '../utils';

export default function AnnotationGraphic({
  annotation,
  targetHeight,
  targetWidth,
  zoom,
}: {
  annotation: Annotation;
  targetHeight: number;
  targetWidth: number;
  zoom: number;
}) {
  const { id, markups } = annotation;
  const [
    { deselect, highlight, select },
    { highlightedId, selectedId },
  ] = useAnnotations();

  const selected = id === selectedId;
  const highlighted = id === highlightedId;

  const strokeWidth = useMemo(
    () => getScaledStrokeWidth(targetWidth, targetHeight),
    [targetWidth, targetHeight],
  );

  const handlers = useMemo(
    () => ({
      onMouseDown: (event: React.MouseEvent) => event.stopPropagation(),
      onMouseUp: (event: React.MouseEvent) => event.stopPropagation(),
      onClick: () => (selected ? deselect() : select(id)),
      onFocus: () => highlight(id),
      onMouseOver: () => highlight(id),
      onBlur: () => highlight(id),
      onMouseOut: () => highlight(id),
    }),
    [highlight, deselect, id, select, selected],
  );

  // hide marker in case another one is selected
  if (selectedId !== null && !selected) return null;

  return (
    <g
      cursor="pointer"
      {...handlers}
      style={{
        // These filters are defined in MarkupCanvas.tsx
        filter: highlighted ? 'url(#shadow-highlighted)' : 'url(#shadow)',
      }}
    >
      <MarkupGraphic
        animated={annotation.id === ANNOTATION_IN_PROGRESS_ID}
        highlighted={highlighted}
        id={annotation.id}
        markups={markups}
        strokeWidth={strokeWidth}
        targetHeight={targetHeight}
        targetWidth={targetWidth}
        zoom={zoom}
      />
    </g>
  );
}
