import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  ChevronLeft as ChevronLeftSvg,
  FilterList as FilterListSvg,
} from '@styled-icons/material';

import { Icon } from 'src/modules/prisma';

import { useLayout } from '../utils';

const ToggleIcon = forwardRef(({ onClick, ...props }, ref) => {
  const [
    { mainSidebarOpened },
    { closeMainSidebar, openMainSidebar },
  ] = useLayout();
  const handleClick = useCallback(
    event => {
      if (onClick) onClick(event);
      if (mainSidebarOpened) closeMainSidebar();
      else openMainSidebar();
    },
    [closeMainSidebar, mainSidebarOpened, onClick, openMainSidebar],
  );

  return (
    <Icon
      backgroundColor="white"
      border="1px solid"
      borderColor="grey.200"
      boxSize="xxlarge"
      color="grey.600"
      onClick={handleClick}
      ref={ref}
      size="medium"
      type={mainSidebarOpened ? <ChevronLeftSvg /> : <FilterListSvg />}
      {...props}
    />
  );
});

ToggleIcon.propTypes = {
  onClick: PropTypes.func,
};

ToggleIcon.defaultProps = {
  onClick: undefined,
};

export default ToggleIcon;
