import React from 'react';
import PropTypes from 'prop-types';

import { Box, Text } from 'src/modules/prisma';

import Tooltip from './Tooltip';

function TooltipMessage({ children, ...props }) {
  return (
    <Tooltip.Panel {...props}>
      <Box py={2} px={4}>
        <Text
          color="white"
          fontFamily="sansSerif"
          tag="p"
          textAlign="center"
          textStyle="action.small"
          fontWeight="regular"
          lineHeight={4}
          whiteSpace="pre-line"
        >
          {children}
        </Text>
      </Box>
    </Tooltip.Panel>
  );
}

TooltipMessage.propTypes = {
  children: PropTypes.node.isRequired,
};

TooltipMessage.displayName = 'TooltipMessage';

export default TooltipMessage;
