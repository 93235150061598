import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { TrackerEvent } from '@picter/tracker';
import { Info as InfoSvg } from '@styled-icons/material-outlined';
import { ViewModule as ViewModuleSvg } from '@styled-icons/material';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import IconButton from 'src/components/IconButton';
import AddImageSvg from 'src/assets/AddImageSvg';
import CommentsSvg from 'src/assets/CommentsSvg';
import DownloadSvg from 'src/assets/DownloadSvg';
import { get } from 'src/utils/accessors';
import { libraryProjectInfoUrl, libraryProjectUrl } from 'src/routes/urls';
import useNavigate from 'src/hooks/use-navigate';
import usePermission from 'src/hooks/use-permission';
import { useProject } from 'src/hooks/use-resource';
import { viewModeTypes } from 'src/routes/constants';

import messages from '../messages';

function ProjectActionsButtons({
  commentsCounter,
  onClickAddImage,
  onClickComments,
  onClickDownload,
  onClickInfo,
}) {
  const {
    canUploadImages,
    canDownloadImages,
    canSeeComments,
  } = usePermission();
  const {
    params: { projectId },
  } = useRouteMatch();
  const navigate = useNavigate();
  const project = useProject(
    {
      id: projectId,
    },
    {
      request: false,
    },
  );

  return (
    <>
      {canSeeComments && onClickComments && (
        <TrackerEvent name="Comments" trackClick>
          <IconButton
            counter={commentsCounter}
            icon={<CommentsSvg />}
            label={<FormattedHTMLMessage {...messages.labelComments} />}
            onClick={onClickComments}
            data-intercom-target="comments-button"
          />
        </TrackerEvent>
      )}
      {canUploadImages && (
        <TrackerEvent name="Add image" trackClick>
          <IconButton
            icon={<AddImageSvg />}
            label={<FormattedMessage {...messages.labelAddImage} />}
            onClick={onClickAddImage}
            data-intercom-target="add-files-button"
          />
        </TrackerEvent>
      )}
      {canDownloadImages && (
        <TrackerEvent name="Download images" trackClick>
          <IconButton
            icon={<DownloadSvg />}
            label={<FormattedMessage {...messages.labelDownload} />}
            onClick={onClickDownload}
            data-intercom-target="download-button"
          />
        </TrackerEvent>
      )}
      <Switch>
        <Route
          path={libraryProjectInfoUrl({ viewMode: viewModeTypes.PRESENT })}
          render={() => (
            <IconButton
              icon={<ViewModuleSvg />}
              label={<FormattedMessage {...messages.labelBackToFiles} />}
              onClick={() =>
                navigate(
                  libraryProjectUrl({
                    projectId,
                  }),
                )
              }
            />
          )}
        />
        {!!get(project, 'attributes.description') && (
          <Route
            path={libraryProjectUrl({ viewMode: viewModeTypes.PRESENT })}
            render={() => (
              <TrackerEvent name="Info" trackClick>
                <IconButton
                  icon={<InfoSvg />}
                  label={<FormattedMessage {...messages.labelInfo} />}
                  onClick={onClickInfo}
                  data-intercom-target="info-button"
                />
              </TrackerEvent>
            )}
          />
        )}
      </Switch>
    </>
  );
}

ProjectActionsButtons.propTypes = {
  commentsCounter: PropTypes.number,
  onClickAddImage: PropTypes.func,
  onClickComments: PropTypes.func,
  onClickDownload: PropTypes.func,
  onClickInfo: PropTypes.func,
};

ProjectActionsButtons.defaultProps = {
  commentsCounter: 0,
  onClickAddImage: null,
  onClickComments: null,
  onClickDownload: null,
  onClickInfo: null,
};

export default ProjectActionsButtons;
