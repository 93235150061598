import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { AspectRatioBox, P, ProgressBar } from '@picter/prisma';
import { Link } from 'react-router-dom';
import prettyBytes from 'pretty-bytes';

import { Box } from 'src/modules/prisma';
import { libraryProjectUrl } from 'src/routes/urls';

import Container from '../styles/UploadIndicatorContainer';
import Preview from './Preview';
import StopButton from '../styles/StopButton';
import messages from '../messages';

const previewSize = 96;

const UploadIndicator = memo(
  ({
    id,
    className,
    preview,
    showPlaceholder,
    type,
    uploadedCount,
    totalCount,
    onClickStop,
    uploadedBytes,
    totalBytes,
    progress,
  }) => (
    <Link
      data-testid="upload-indicator-link"
      to={libraryProjectUrl({ projectId: id })}
    >
      <Container data-testid="upload-indicator" id={id} className={className}>
        <Box
          display="inline-block"
          data-testid="upload-indicator-preview"
          width={previewSize}
        >
          <AspectRatioBox ratio="square">
            <Preview
              preview={preview}
              showPlaceholder={showPlaceholder}
              type={type}
            />
          </AspectRatioBox>
        </Box>
        <Box
          display="inline-flex"
          flexDirection="column"
          padding={4}
          position="relative"
          width={`calc(100% - ${previewSize}px)`}
        >
          <P noMargin>
            <FormattedMessage {...messages.messageUploadingImages} />
          </P>
          {uploadedCount !== totalCount && (
            <>
              <Box mt={2}>
                <P
                  color="muted"
                  data-testid="upload-indicator-report-images"
                  textStyle="caption"
                  noMargin
                >
                  <FormattedMessage
                    {...messages.messageNumberOfImages}
                    values={{
                      uploaded:
                        uploadedCount === totalCount
                          ? totalCount
                          : uploadedCount + 1,
                      total: totalCount,
                    }}
                  />
                </P>
                <P
                  color="muted"
                  data-testid="upload-indicator-report-bytes"
                  textStyle="caption"
                  noMargin
                >
                  <FormattedMessage
                    {...messages.messageUploadedBytes}
                    values={{
                      uploaded: prettyBytes(uploadedBytes),
                      total: prettyBytes(totalBytes),
                    }}
                  />
                </P>
              </Box>
              <ProgressBar
                data-testid="upload-indicator-progress"
                progress={progress}
                size={3}
                position="absolute"
                bottom="0"
                left="0"
                right="0"
              />
            </>
          )}
        </Box>
        {onClickStop && (
          <StopButton
            onClick={e => {
              e.preventDefault();
              onClickStop();
            }}
          />
        )}
      </Container>
    </Link>
  ),
);

UploadIndicator.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onClickStop: PropTypes.func,
  preview: PropTypes.string,
  showPlaceholder: PropTypes.bool,
  type: PropTypes.string.isRequired,
  uploadedCount: PropTypes.number,
  totalCount: PropTypes.number,
  uploadedBytes: PropTypes.number,
  totalBytes: PropTypes.number,
  progress: PropTypes.number,
};

UploadIndicator.defaultProps = {
  id: undefined,
  className: undefined,
  onClickStop: undefined,
  preview: undefined,
  showPlaceholder: false,
  uploadedCount: 0,
  totalCount: 0,
  uploadedBytes: 0,
  totalBytes: 0,
  progress: 0,
};

UploadIndicator.displayName = 'UploadIndicator';

export default UploadIndicator;
