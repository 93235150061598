import React from 'react';
import PropTypes from 'prop-types';
import { DetailLayout, GeneralLayout, MediaQuery } from '@picter/prisma';
import picterLogoBlack from '@picter/prisma/lib/assets/picter-logo-black.svg';
import groupBy from 'lodash/groupBy';

import MenuIcon from 'src/components/MenuIcon';

import IndexLayout from '../../_layout';

function LayoutTop({ children }) {
  return <GeneralLayout.Top>{children}</GeneralLayout.Top>;
}

function LayoutContent({ children }) {
  return <GeneralLayout.Content py={[3, 3]}>{children}</GeneralLayout.Content>;
}

function LayoutNavigation({ children }) {
  return <IndexLayout.Navigation>{children}</IndexLayout.Navigation>;
}

function Layout({ children }) {
  const keyedChildren = groupBy(children, 'type');

  return (
    <IndexLayout>
      {keyedChildren[LayoutNavigation]}
      <IndexLayout.Content>
        <DetailLayout>
          <MediaQuery
            query={{ maxWidth: 0 }}
            render={() => (
              <DetailLayout.Top>
                <DetailLayout.Top.AlignLeft>
                  <MenuIcon />
                </DetailLayout.Top.AlignLeft>
                <DetailLayout.Top.AlignCenter>
                  <img src={picterLogoBlack} alt="Picter's logo" />
                </DetailLayout.Top.AlignCenter>
              </DetailLayout.Top>
            )}
          />
          <DetailLayout.Content>
            <GeneralLayout limited>
              {keyedChildren[LayoutTop]}
              {keyedChildren[LayoutContent]}
            </GeneralLayout>
          </DetailLayout.Content>
        </DetailLayout>
      </IndexLayout.Content>
    </IndexLayout>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
LayoutContent.propTypes = {
  children: PropTypes.node.isRequired,
};
LayoutNavigation.propTypes = {
  children: PropTypes.node.isRequired,
};
LayoutTop.propTypes = {
  children: PropTypes.node.isRequired,
};

Layout.Content = LayoutContent;
Layout.Navigation = LayoutNavigation;
Layout.Top = LayoutTop;

export default Layout;
