import styled from 'styled-components/macro';
import { transparentize } from 'polished';
import { Icon } from '@picter/prisma';

const getIconColor = ({ complete, theme }) => {
  if (!complete) return transparentize(0.8, theme.colors.black);

  return 'inherit';
};

const ItemIcon = styled(Icon).attrs({
  size: 'small',
})`
  color: ${props => getIconColor(props)};
  margin-bottom: 2px;
  margin-right: ${props => props.theme.space[1]}px;
`;

export default ItemIcon;
