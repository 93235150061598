export const FeatureName = Object.freeze({
  ANNOTATION_COMMENTS: 'annotation_comments',
  APPROVALS: 'approvals',
  FILE_REPLACEMENT: 'file_replacement',
  GUEST_MEMBER_INVITATION: 'guests',
  IMAGE_QUANTITY_LIMIT: 'ws_image_quantity_limit',
  OBSERVATION_MODE: 'observation_mode',
  GLOBAL_SEARCH: 'global_search',
  PROJECT_EDITOR_QUANTITY_LIMIT: 'ws_project_editor_quantity_limit',
  PROJECT_QUANTITY_LIMIT: 'ws_project_quantity_limit',
  SELF_CHECKOUT: 'self_checkout',
  STORAGE_LIMIT: 'storage_limit',
  TEAM_MEMBER_INVITATION: 'team',
});
