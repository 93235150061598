import styled from 'styled-components/macro';
import { TooltipArrow as ReakitTooltipArrow } from 'reakit/Tooltip';
import themeGet from '@styled-system/theme-get';

const TooltipArrow = styled(ReakitTooltipArrow)`
  svg {
    display: none;
  }

  border-color: transparent;
  border-style: solid;
  border-width: 8px;
  ${props =>
    `border-${props.placement}-color: ${themeGet(`colors.${props.color}`)(
      props,
    )};`};
`;

TooltipArrow.displayName = 'TooltipArrow';

export default TooltipArrow;
