import { defineMessages } from 'react-intl';

export default defineMessages({
  labelPhotographer: {
    id: 'Global.labelPhotographer',
    defaultMessage: 'Photographer',
  },
  labelRetoucher: {
    id: 'Global.labelRetoucher',
    defaultMessage: 'Retoucher',
  },
  labelCreativeDirector: {
    id: 'Global.labelCreativeDirector',
    defaultMessage: 'Creative Director',
  },
  labelArtDirector: {
    id: 'Global.labelArtDirector',
    defaultMessage: 'Art Director',
  },
  labelContentCreator: {
    id: 'Global.labelContentCreator',
    defaultMessage: 'Content Creator',
  },
  labelContentManager: {
    id: 'Global.labelContentManager',
    defaultMessage: 'Content Manager',
  },
  labelCopywriter: {
    id: 'Global.labelCopywriter',
    defaultMessage: 'Copywriter',
  },
  labelDesigner: {
    id: 'Global.labelDesigner',
    defaultMessage: 'Designer',
  },
  labelImageEditor: {
    id: 'Global.labelImageEditor',
    defaultMessage: 'Image Editor',
  },
  labelIntern: {
    id: 'Global.labelIntern',
    defaultMessage: 'Intern',
  },
  labelManagingDirector: {
    id: 'Global.labelManagingDirector',
    defaultMessage: 'Managing Director',
  },
  labelMarketingManager: {
    id: 'Global.labelMarketingManager',
    defaultMessage: 'Marketing Manager',
  },
  labelProducer: {
    id: 'Global.labelProducer',
    defaultMessage: 'Producer',
  },
  labelProjectManager: {
    id: 'Global.labelProjectManager',
    defaultMessage: 'Project Manager',
  },
  labelSocialMediaManager: {
    id: 'Global.labelSocialMediaManager',
    defaultMessage: 'Social Media Manager',
  },
  labelCurator: {
    id: 'Global.labelCurator',
    defaultMessage: 'Curator',
  },
  labelAgent: {
    id: 'Global.labelAgent',
    defaultMessage: 'Agent',
  },
  labelProfessor: {
    id: 'Global.labelProfessor',
    defaultMessage: 'Professor / teacher',
  },
  labelStudent: {
    id: 'Global.labelStudent',
    defaultMessage: 'Student',
  },
  labelOther: {
    id: 'Global.labelOther',
    defaultMessage: 'Other',
  },
});
