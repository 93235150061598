import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Spinner, Body, MediaQuery } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';

import {
  ACCEPTED_VIDEO_FILE_TYPES,
  ACCEPTED_IMAGE_FILE_TYPES,
} from 'src/constants';

import PreviewPlaceholderWrapper from './styles/PreviewPlaceholderWrapper';

import messages from './messages';

const PreviewPlaceholder = ({ type, uploading, processing }) => {
  let icon = 'insert_drive_file';

  if (ACCEPTED_IMAGE_FILE_TYPES.includes(type)) icon = 'image';
  if (ACCEPTED_VIDEO_FILE_TYPES.includes(type)) icon = 'videocam';

  return (
    <PreviewPlaceholderWrapper>
      <Icon type={icon} size="huge" color="muted" />
      {!uploading && processing && (
        <div>
          <MediaQuery query={{ minWidth: 0 }}>
            {matches =>
              matches ? (
                <>
                  <Spinner />
                  <Body textSize="xsmall" fontWeight="light">
                    <FormattedMessage {...messages.labelProcessing} />
                  </Body>
                </>
              ) : (
                <Spinner />
              )
            }
          </MediaQuery>
        </div>
      )}
    </PreviewPlaceholderWrapper>
  );
};

PreviewPlaceholder.propTypes = {
  type: PropTypes.string.isRequired,
  uploading: PropTypes.bool,
  processing: PropTypes.bool,
};

PreviewPlaceholder.defaultProps = {
  uploading: false,
  processing: false,
};

export default PreviewPlaceholder;
