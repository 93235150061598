import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from '@picter/prisma';

import ModalActions from 'src/styles/ModalActions';
import useAutoUpdate from 'src/hooks/use-auto-update';
import { Body, Box, Button } from 'src/modules/prisma';
import UpdateIllustrationSvg from 'src/assets/UpdateIllustrationSvg';

import messages from './messages';

const UpdateReminderModal = () => {
  const { update, postpone } = useAutoUpdate();

  return (
    <Modal
      onClickClose={postpone}
      title={<FormattedMessage {...messages.labelUpdateAvailableTitle} />}
      open={!!update}
    >
      <Box display="flex" alignItems="center" justifyContent="center" mb={7}>
        <UpdateIllustrationSvg />
      </Box>
      <Body mb={6}>
        <FormattedMessage {...messages.labelUpdateAvailableMessage} />
      </Body>
      <ModalActions>
        <Button
          onClick={postpone}
          px={0}
          color="grey.600"
          backgroundColor="transparent"
          textStyle="action.large"
        >
          <FormattedMessage {...messages.labelUpdateAvailableRemindMeLater} />
        </Button>
        <Button
          onClick={update}
          px={0}
          color="primary"
          backgroundColor="transparent"
          textStyle="action.large"
        >
          <FormattedMessage {...messages.labelUpdateAvailableReload} />
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default UpdateReminderModal;
