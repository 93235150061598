import React, { useEffect } from 'react';
import isFunction from 'lodash/isFunction';

import Bottom from '../styles/SidebarBottom';
import Content from '../styles/SidebarContent';
import Panel from '../styles/ContextSidebarPanel';
import Top from '../styles/SidebarTop';
import { useSidebarControls } from '../utils/sidebar-control';

type Props = {
  id?: string;
  className?: string;
  children: React.ReactNode;
  closeOnUnmount?: boolean;
  openOnMount?: boolean;
};
const ContextSidebar: React.FC<Props> & {
  Top: React.FC;
  Content: React.FC;
  Bottom: React.FC;
} = ({
  id,
  className,
  children,
  closeOnUnmount = false,
  openOnMount = false,
}) => {
  const sidebarControl = useSidebarControls();
  const { openContextSidebar, closeContextSidebar } = sidebarControl;

  useEffect(() => {
    if (openOnMount) {
      openContextSidebar();
    }

    return () => {
      if (closeOnUnmount) {
        closeContextSidebar();
      }
    };
  }, [openContextSidebar, closeContextSidebar, openOnMount, closeOnUnmount]);

  return (
    <Panel id={id} className={className}>
      {isFunction(children) ? children(sidebarControl) : children}
    </Panel>
  );
};

ContextSidebar.Top = Top;
ContextSidebar.Content = Content;
ContextSidebar.Bottom = Bottom;

export default ContextSidebar;
