import styled, { keyframes } from 'styled-components/macro';

const gradient = keyframes`
0% {
  left: -80%;
}
100% {
  left: 120%;
}
`;

const ProgressBar = styled.div`
  background: linear-gradient(
    to right,
    transparent,
    ${props => props.theme.colors.primary},
    ${props => props.theme.colors.primary},
    transparent
  );
  width: 80%;
  height: 100%;
  position: relative;
  animation: ${gradient} 2s linear infinite;
`;

export default ProgressBar;
