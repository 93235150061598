import React, { useEffect, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';

import Avatar from 'src/components/Avatar';
import { Box } from 'src/modules/prisma';
import { get } from 'src/utils/accessors';
import MessageTooltip from 'src/components/MessageTooltip';
import { useCurrentUser } from 'src/hooks/use-resource';
import { useFeature, FeatureName } from 'src/containers/FeatureFlags';
import { useObservationMode } from 'src/components/ObservationMode';
import { useRealtimePresence } from 'src/modules/realtime';
import withSuspenseLoader from 'src/hocs/with-suspense-loader';

import messages from './messages';

function ProjectRealtimePresenceState() {
  const user = useCurrentUser(undefined, { request: false });
  const userId = get(user, 'id');
  const { subscribed, members } = useRealtimePresence();
  const [
    { observe, unobserve },
    { observingId, observersIds },
  ] = useObservationMode();
  const hasObservationMode = useFeature(FeatureName.OBSERVATION_MODE);

  // In case user leaves the project unobserve it
  useEffect(() => {
    if (
      subscribed &&
      observingId &&
      !members.some(m => m.id === observingId) &&
      hasObservationMode
    ) {
      unobserve();
    }
  }, [hasObservationMode, members, observingId, subscribed, unobserve]);

  if (!subscribed) {
    return null;
  }

  return (
    <Box display="flex" data-intercom-target="presence-channel">
      {members.map(member => {
        const memberName = get(member, 'info.name');
        const memberEmail = get(member, 'info.email');
        const memberId = get(member, 'id');

        const isCurrentUser = memberId === userId;
        const isMemberObserving = observersIds.has(memberId);
        const shouldDisableAvatar =
          !hasObservationMode || isCurrentUser || isMemberObserving;

        return (
          <Box gml={2} key={memberId}>
            <MessageTooltip
              message={
                <FormattedMessage
                  {...(isMemberObserving
                    ? messages.messageUserObserving
                    : messages.messageUserPresent)}
                  values={{ name: memberName || memberEmail }}
                />
              }
            >
              <span>
                <Avatar
                  email={memberEmail}
                  name={memberName}
                  onClick={
                    shouldDisableAvatar
                      ? undefined
                      : () =>
                          observingId === memberId
                            ? unobserve()
                            : observe(memberId)
                  }
                  highlighted={!shouldDisableAvatar && memberId !== observingId}
                  selected={memberId === observingId}
                  size="medium"
                />
              </span>
            </MessageTooltip>
          </Box>
        );
      })}
    </Box>
  );
}

export default compose(
  withSuspenseLoader({
    ErrorComponent: null,
    LoadingComponent: null,
  }),
  memo,
)(ProjectRealtimePresenceState);
