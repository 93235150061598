import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';
import { transparentize } from 'polished';
import { transition } from '@picter/prisma';

const CommentsCounter = styled.div`
  align-items: center;
  border-radius: 10%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 2px ${themeGet('space.1')}px;
  transition: ${transition({ property: 'background-color' })};

  i {
    cursor: inherit;
    margin-right: ${themeGet('space.1')}px;
  }

  &:hover {
    background-color: ${props =>
      transparentize(0.88, themeGet('colors.grey.600')(props))};
  }

  &:active {
    background-color: ${props =>
      transparentize(0.74, themeGet('colors.grey.600')(props))};
  }
`;

export default CommentsCounter;
