// Part of the implementation comes from the debounce-collect lib
// https://github.com/rstacruz/debounce-collect

export default function createThrottledCollect(wait) {
  let timestamp;

  return function throttle(func, immediate) {
    let context;
    let result;
    let args = [];
    let timer;
    // eslint-disable-next-line no-param-reassign
    if (wait == null) wait = 100;

    function call() {
      return func.call(context, args);
    }

    function reset() {
      context = null;
      args = [];
    }

    function onTimeout() {
      const elapsed = Date.now() - timestamp;

      if (elapsed < wait && elapsed > 0) {
        timer = setTimeout(onTimeout, wait - elapsed);
      } else {
        timer = null;
        timestamp = null;
        if (!immediate) {
          result = call();
          if (!timer) reset();
        }
      }
    }

    return function throttled() {
      context = this;
      // eslint-disable-next-line prefer-rest-params
      args.push([].slice.call(arguments));
      timestamp = timestamp || Date.now();
      const callNow = immediate && !timer;
      if (!timer) timer = setTimeout(onTimeout, wait);
      if (callNow) {
        result = call();
        reset();
      }

      return result;
    };
  };
}
