import camelCase from 'lodash/camelCase';
import { List } from 'immutable';

const select = (state, type, { id, filter, include } = {}) => {
  const apiState = state.get ? state.get('api') : state.api;

  if (type) {
    const [rootType, relatedName] = type.split('.');
    if (relatedName) {
      const related = apiState.getIn([
        rootType,
        id,
        'relationships',
        relatedName,
        'data',
      ]);
      if (!related || related.isEmpty()) return related;
      const isMany = List.isList(related);
      const relatedType = isMany
        ? related.getIn([0, 'type'])
        : related.get('type');
      const relatedIds = isMany
        ? related.map(relatedItem => relatedItem.get('id')).toJS()
        : related.get('id');
      return select(state, relatedType, { id: relatedIds, filter, include });
    }
  }

  let result;
  // some apps don't have a full immutable.js state, so we
  // need a fallback to handle plain JavaScript objects
  const typeCamelized = camelCase(type);

  if (id) {
    const idList = Array.isArray(id) ? id : [id];
    const resourceList = new List(idList);
    result = resourceList.map(resourceId =>
      apiState.getIn([typeCamelized, resourceId]),
    );
  } else {
    result = apiState.has(typeCamelized)
      ? apiState.get(typeCamelized).toList()
      : new List();
  }

  if (Array.isArray(include) && include.length > 0) {
    // group nested relations by top-level relations:
    // use the first level of each relation as key
    const nestedIncludesMap = include
      .map(includePath => includePath.split('.'))
      .reduce((map, [top, ...nested]) => {
        // nestedValue should always be an array of deeper includes
        // if `nested` has several levels, we need to join them again for the next
        // recursion of select
        const nestedValue = nested.length > 0 ? [nested.join('.')] : [];
        return {
          ...map,
          // check if map[top] is an array, to add includes at the same level
          // as siblings, e.g. include: ['projects.images', 'projects.owner']
          [top]: Array.isArray(map[top])
            ? [...map[top], ...nestedValue]
            : nestedValue,
        };
      }, {});
    result = result.map(resource =>
      // make resource mutable (to allow performant mutation of objects),
      // but only if resource is defined
      !resource
        ? resource
        : resource.withMutations(map => {
            // Iterate over all includes and collect nested includes
            Object.keys(nestedIncludesMap).forEach(relation => {
              const relationship = map.getIn(['relationships', relation]);
              // get ids of related resource identifier objects
              if (relationship) {
                const relatedIds = List.isList(relationship.get('data'))
                  ? relationship
                      .get('data')
                      .map(relatedResource => relatedResource.get('id'))
                      .toArray()
                  : relationship.getIn(['data', 'id']);
                // identify type of related resources
                const relatedType = Array.isArray(relatedIds)
                  ? relationship.getIn(['data', 0, 'type'])
                  : relationship.getIn(['data', 'type']);
                // check if resource object data exists and
                // replace json-api's resource identifier objects
                // with actual resource objects
                const relationValue = select(state, relatedType, {
                  id: relatedIds,
                  include: nestedIncludesMap[relation],
                });
                let parsedRelationValue;
                if (List.isList(relationValue)) {
                  parsedRelationValue = relationValue.filter(
                    relatedResource =>
                      relatedResource && relatedResource.get('id'),
                  );
                } else {
                  parsedRelationValue =
                    relationValue && relationValue.get('id')
                      ? relationValue
                      : null;
                }

                map.setIn(['relationships', relation], parsedRelationValue);
              }
            });
          }),
    );
  }

  if (filter) {
    const filteredItems = result.filter(item =>
      Object.entries(filter).every(
        ([key, value]) => item.getIn(['attributes', key]) === value,
      ),
    );

    return filteredItems;
  }

  if (id && !Array.isArray(id)) {
    return result.get(0);
  }

  return result;
};

export default select;
