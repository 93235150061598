import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components/macro';
import { Route, Switch } from 'react-router-dom';
import pick from 'lodash/pick';
import { trackerShape } from '@picter/tracker';
import { Box } from '@rebass/grid';
import { FormattedMessage } from 'react-intl';

import AppLayout from 'src/components/AppLayout';
import GeneralLayout, { useLayout } from 'src/layouts/GeneralLayout';
import TranslateBox from 'src/styles/TranslateBox';
import paths from 'src/routes/paths';
import usePermission from 'src/hooks/use-permission';
import UploadArea from 'src/components/UploadArea';
import EmptyStateBox from 'src/components/EmptyStateBox';

import DoneWithUploadButton from '../components/DoneWithUploadButton';
import UploadMoreButton from '../components/UploadMoreButton';
import FilesSentSvg from '../components/FilesSentSvg';
import OpacityFooter from '../styles/OpacityFooter';
import useGridMeasurements from '../_hooks/use-grid-measurements';
import { useOnFileUpload } from '../_hooks/use-handlers';
import { Header, Info, Grid } from '../_sections';

import messages from '../messages';

const { LIBRARY_PROJECT_INFO_PATH, LIBRARY_PROJECT_INBOX_SUCCESS_PATH } = paths;

function LayoutContent({ project, collection }) {
  const theme = useTheme();
  const gridMeasurements = useGridMeasurements(theme);

  return (
    <>
      <GeneralLayout.Content>
        <Switch>
          <Route
            path={LIBRARY_PROJECT_INBOX_SUCCESS_PATH}
            render={() => (
              <Box
                style={{ height: '100%' }}
                flex={1}
                pl={`${gridMeasurements.paddingLeft}px`}
                pr={`${gridMeasurements.paddingRight}px`}
                pt={`${
                  gridMeasurements.paddingTop + gridMeasurements.headerHeight
                }px`}
                pb={`${gridMeasurements.paddingBottom}px`}
              >
                <EmptyStateBox
                  graphic={<FilesSentSvg />}
                  title={<FormattedMessage {...messages.titleFilesSent} />}
                  message={<FormattedMessage {...messages.messageFilesSent} />}
                />
              </Box>
            )}
          />
          <Route
            path={LIBRARY_PROJECT_INFO_PATH}
            render={() => (
              <LayoutCoupledBox>
                <Info />
              </LayoutCoupledBox>
            )}
          />
          <Route
            render={() => <Grid project={project} collection={collection} />}
          />
        </Switch>
      </GeneralLayout.Content>
      <OpacityFooter>
        <Switch>
          <Route
            path={LIBRARY_PROJECT_INBOX_SUCCESS_PATH}
            render={() => <UploadMoreButton />}
          />
          <Route render={() => <DoneWithUploadButton />} />
        </Switch>
      </OpacityFooter>
    </>
  );
}

LayoutContent.propTypes = {
  project: PropTypes.instanceOf(Map).isRequired,
  collection: PropTypes.instanceOf(Map),
};

LayoutContent.defaultProps = {
  collection: undefined,
};

function LayoutCoupledBox({ spacing, ...props }) {
  const [
    {
      mode,
      sizes: { TOP_SECTION },
    },
    controls,
  ] = useLayout();
  const theme = useTheme();
  const gridMeasurements = useGridMeasurements(theme);
  // change appearence of page top bar
  const refreshLayoutMode = useCallback(
    ({ target: { scrollTop: scrollOffset } }) => {
      requestAnimationFrame(() => {
        // on top
        if (scrollOffset < 30) {
          controls.enableComfortableMode();
        } else {
          controls.enableCompactMode();
        }
      });
    },
    [controls],
  );

  return (
    <TranslateBox
      {...pick(gridMeasurements, spacing)}
      style={{ overflow: 'auto' }}
      height={`calc(100% - ${TOP_SECTION[mode]}px)`}
      onScroll={refreshLayoutMode}
      translateY={TOP_SECTION[mode]}
      width={1}
      {...props}
    />
  );
}
LayoutCoupledBox.defaultProps = {
  spacing: ['paddingBottom', 'paddingLeft', 'paddingRight', 'paddingTop'],
};
LayoutCoupledBox.propTypes = {
  spacing: PropTypes.arrayOf(PropTypes.string),
};

const LayoutDesktop = props => {
  const { tracker, project, collection } = props;

  const { canUploadImages } = usePermission();
  const onFileUpload = useOnFileUpload({
    tracker,
    project,
    collection,
    overrideFile: false,
  });

  return (
    <AppLayout.Content>
      <UploadArea onUpload={onFileUpload} disabled={!canUploadImages}>
        {({ openFileDialog }) => (
          <GeneralLayout>
            <Header tracker={tracker} openFileDialog={openFileDialog} />
            <LayoutContent project={project} collection={collection} />
          </GeneralLayout>
        )}
      </UploadArea>
    </AppLayout.Content>
  );
};

LayoutDesktop.propTypes = {
  project: PropTypes.instanceOf(Map).isRequired,
  collection: PropTypes.instanceOf(Map),
  tracker: trackerShape.isRequired,
};

LayoutDesktop.defaultProps = {
  collection: undefined,
};

export default LayoutDesktop;
