import { List } from 'immutable';
import { useCurrentUser } from 'src/hooks/use-resource';

import { get } from 'src/utils/accessors';

import OnboardingChecklist, {
  STORAGE_ITEM_KEY,
} from './components/OnboardingChecklist';
import { useLocalStorageItem } from './hooks';

const TWO_WEEKS_IN_MS = 14 * 24 * 60 * 60 * 1000;

export function ShouldShowOnboardingChecklistOnSpace({ children, space }) {
  const user = useCurrentUser();
  const userId = get(user, 'id');
  const spaceId = get(space, 'id');
  const spaceUser = get(space, 'relationships.users', List()).find(
    item => get(item, 'relationships.user.id') === get(user, 'id'),
  );
  const [storageItem] = useLocalStorageItem(STORAGE_ITEM_KEY);
  let shouldShowChecklist = false;

  const userScope = get(spaceUser, 'attributes.scope');
  const isOwner = spaceUser && userScope === 'owner';
  const isManager = spaceUser && userScope === 'manager';

  // Is current user manager or owner of this space?
  shouldShowChecklist = isOwner || isManager;

  // Was onboarding checklist dismissed with a cookie?
  shouldShowChecklist =
    shouldShowChecklist && !storageItem.includes(`${spaceId}.${userId}`);

  // Is this space older than 2 weeks?
  shouldShowChecklist =
    shouldShowChecklist &&
    !(Date.now() - get(space, 'attributes.createdAt') > TWO_WEEKS_IN_MS);

  return shouldShowChecklist ? children : null;
}

export default OnboardingChecklist;
