import kebabCase from 'lodash/kebabCase';
import camelCase from 'lodash/camelCase';
import { requestAsPromise } from '../../json-api-middleware/actions';
import { asyncTypesArray } from '../../utils/action-types-helpers';
import addQueryParameters from '../../utils/add-query-parameters';
import { API_DESTROY_DATA_TYPES } from '../../constants';

const destroy = (
  model,
  { id, querySerializers } = {},
  { prefix, baseUrl, postfix } = {},
) => {
  if (!id) {
    throw new Error('Destroy without id is not allowed.');
  }

  const endpoint = [kebabCase(model), id];

  if (prefix) endpoint.unshift(prefix);
  if (postfix) endpoint.push(postfix);

  return dispatch =>
    requestAsPromise(dispatch, {
      types: asyncTypesArray(API_DESTROY_DATA_TYPES),
      endpoint: addQueryParameters(endpoint.join('/'), {
        querySerializers,
      }),
      method: 'DELETE',
      meta: {
        id,
        model: camelCase(model),
      },
      baseUrl,
    });
};

export default destroy;
