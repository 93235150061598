import React from 'react';
import { Route, Switch } from 'react-router-dom';

import paths from 'src/routes/paths';

import PresentProjectRoutes from './{projectId}/_routes';

const { LIBRARY_PROJECT_PATH } = paths;

const PresentRoutes = () => {
  return (
    <Switch>
      <Route path={LIBRARY_PROJECT_PATH} component={PresentProjectRoutes} />
    </Switch>
  );
};

export default PresentRoutes;
