import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import api from '../actions';
import createDebouncedLoader from './create-debounced-loader';

export const ToriiContext = React.createContext();

const ToriiProvider = ({ children, ...toriiApi }) => {
  const load = useMemo(() => createDebouncedLoader(toriiApi.load), [
    toriiApi.load,
  ]);

  return (
    <ToriiContext.Provider
      value={{
        ...toriiApi,
        load,
      }}
    >
      {children}
    </ToriiContext.Provider>
  );
};

ToriiProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const withToriiConsumer = WrappedComponent => props => (
  <ToriiContext.Consumer>
    {value => <WrappedComponent {...props} {...value} />}
  </ToriiContext.Consumer>
);

const mapDispatchToProps = {
  ...api,
};

export default connect(null, mapDispatchToProps)(ToriiProvider);
