import { defineMessages } from 'react-intl';

export default defineMessages({
  labelAccountSettings: {
    defaultMessage: 'Account settings',
    id: 'labelAccountSettings',
  },
  labelAnonymousOwner: {
    defaultMessage: 'Anonymous owner',
    id: 'labelAnonymousOwner',
  },
  labelCancel: {
    defaultMessage: 'Cancel',
    id: 'labelCancel',
  },
  labelSave: {
    defaultMessage: 'Save',
    id: 'labelSave',
  },
  labelLearnMore: {
    defaultMessage: 'Learn more',
    id: 'labelLearnMore',
  },
  labelLogout: {
    defaultMessage: 'Logout',
    id: 'labelLogout',
  },
  labelRenameImage: {
    defaultMessage: 'Rename',
    id: 'labelRenameImage',
  },
  labelUntitledCollection: {
    defaultMessage: 'Untitled',
    id: 'labelUntitledCollection',
  },
  labelUntitledImage: {
    defaultMessage: 'Untitled',
    id: 'labelUntitledImage',
  },
  labelUntitledProject: {
    defaultMessage: 'Untitled project',
    id: 'labelUntitledProject',
  },
  labelUntitledFolder: {
    defaultMessage: 'Untitled folder',
    id: 'labelUntitledFolder',
  },
  labelUpgrade: {
    defaultMessage: 'Upgrade',
    id: 'labelUpgrade',
  },
  messageDeleteProject: {
    defaultMessage:
      'This will permanently delete the project including all its files. This action is irreversible.',
    id: 'messageDeleteProject',
  },
  messageErrorMessage: {
    defaultMessage: 'Error: {message}',
    id: 'messageErrorMessage',
  },
  messageFileTooBig: {
    defaultMessage: 'Exceeds the max size of 5GB.',
    id: 'messageFileTooBig',
  },
  messageForbiddenFormat: {
    defaultMessage:
      '{fileType} is not allowed. Submit files of type {acceptedFileTypes}.',
    id: 'messageForbiddenFormat',
  },
  messageLinkCopied: {
    defaultMessage: 'Link copied to clipboard.',
    id: 'messageLinkCopied',
  },
});
