import styled from 'styled-components/macro';

const ContentContainer = styled.section`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

export default ContentContainer;
