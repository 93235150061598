import React, { forwardRef, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Img from 'src/components/Img';

const cache = {};

const ONE_MB = 1000000;
const ALLOWED_ORIGINAL_MIME_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

const isCurrentDimensionsLargerThanNaturalDimensions = (
  naturalDimensions,
  currentDimensions,
) => {
  return (
    naturalDimensions &&
    (naturalDimensions.height < currentDimensions.height ||
      naturalDimensions.width < currentDimensions.width)
  );
};

const ImgComponent = forwardRef(
  (
    {
      loader,
      src,
      originalSrc,
      onLoadRegularImage,
      canDownloadImage,
      imageBytes,
      alt,
      mimeType,
      ...imgProps
    },
    ref,
  ) => {
    const canLoadOriginalFiles =
      canDownloadImage &&
      ALLOWED_ORIGINAL_MIME_TYPES.includes(mimeType) &&
      process.env.NODE_ENV !== 'development';
    const shouldLoadOriginalFirst =
      canLoadOriginalFiles && imageBytes <= ONE_MB;
    const [canShowOriginal, setCanShowOriginal] = useState(
      shouldLoadOriginalFirst,
    );
    const [canLoadOriginal, setCanLoadOriginal] = useState(
      shouldLoadOriginalFirst,
    );
    const [naturalDimensions, setNaturalDimensions] = useState(null);

    useEffect(() => {
      if (canLoadOriginalFiles && imageBytes > ONE_MB) {
        if (
          naturalDimensions &&
          isCurrentDimensionsLargerThanNaturalDimensions(naturalDimensions, {
            height: imgProps.height,
            width: imgProps.width,
          })
        ) {
          setCanLoadOriginal(true);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imgProps.height, imgProps.width]);

    const cdnForcedOriginalSrc = useMemo(() => {
      return originalSrc.replace(
        /.*amazonaws\.com/,
        process.env.REACT_APP_ASSETS_CDN,
      );
    }, [originalSrc]);

    useEffect(() => {
      if (canLoadOriginal) {
        if (cache[cdnForcedOriginalSrc]) {
          setCanShowOriginal(true);
          return;
        }

        const img = new Image();
        const handleLoad = () => {
          setCanShowOriginal(true);
        };

        img.src = cdnForcedOriginalSrc;

        if (img.decode) {
          img.decode().then(handleLoad);
        } else {
          img.onload = handleLoad;
        }
      }
    }, [canLoadOriginal, cdnForcedOriginalSrc, ref]);

    return canLoadOriginal && canShowOriginal && originalSrc ? (
      <img src={cdnForcedOriginalSrc} alt={alt} {...imgProps} />
    ) : (
      <Img
        src={src}
        alt={alt}
        ref={ref}
        onLoad={el =>
          el &&
          setNaturalDimensions({
            height: el.naturalHeight,
            width: el.naturalWidth,
          })
        }
        {...imgProps}
      />
    );
  },
);

ImgComponent.propTypes = {
  src: PropTypes.string.isRequired,
  originalSrc: PropTypes.string,
  alt: PropTypes.string.isRequired,
  mimeType: PropTypes.string.isRequired,
  onLoadRegularImage: PropTypes.function,
  loader: PropTypes.node,
  imageBytes: PropTypes.number,
  canDownloadImage: PropTypes.bool,
};

ImgComponent.defaultProps = {
  originalSrc: null,
  loader: null,
  onLoadRegularImage: null,
  imageBytes: null,
  canDownloadImage: false,
};

export default ImgComponent;
