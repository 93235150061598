import injectPublicLinkKey from 'src/utils/inject-public-link-key';
import { executeInCase } from 'src/utils/in-case';
import { viewModeTypes } from 'src/routes/constants';

export function createComment(
  {
    collectionId,
    frame,
    commentId,
    geometry,
    imageId,
    projectId,
    projectPublicUrlKey,
    text,
    viewMode = viewModeTypes.DEFAULT,
  },
  { create },
) {
  const relationships = {
    project: {
      id: projectId,
      type: 'ws-projects',
    },
  };

  // last true case is the one executed
  executeInCase({
    [!!collectionId]: () => {
      relationships.collection = {
        id: collectionId,
        type: 'ws-collections',
      };
    },
    [!!imageId]: () => {
      relationships.image = {
        id: imageId,
        type: 'ws-project-images',
      };
    },
    [!!commentId]: () => {
      relationships.parent = {
        id: commentId,
        type: 'ws-comments',
      };
    },
  });

  const { querySerializers } = injectPublicLinkKey(
    {
      querySerializers: {
        publicKey: () => `publicKey=${projectPublicUrlKey}`,
      },
    },
    { params: { viewMode, projectId: projectPublicUrlKey } },
  );

  return create('wsComments', {
    attributes: {
      frame,
      geometry,
      text,
    },
    relationships,
    querySerializers,
  });
}
