import { Page } from 'react-pdf';
import styled from 'styled-components/macro';

const EnhancedPage = styled(Page)`
  display: flex;
  justify-content: center;
  background-color: black;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 4px;
  padding-bottom: 4px;
  ${props => (props.firstPage ? 'padding-top: 14px;' : '')}
  ${props => (props.lastPage ? 'padding-bottom: 14px;' : '')}
`;

export default EnhancedPage;
