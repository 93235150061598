import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Flex, Box } from '@rebass/grid';
import {
  ExpandMore as ExpandMoreSvg,
  ExpandLess as ExpandLessSvg,
} from '@styled-icons/material';

import Navigation from 'src/components/Navigation';
import { Icon } from 'src/modules/prisma';

import FiltersCounter from '../styles/FiltersCounter';
import messages from '../messages';

const FiltersButton = ({
  toggleShowFilters,
  filtersCounter,
  filtersImagesCounter,
  showFilters,
}) => (
  <Navigation.Button
    icon="filter_list"
    onClick={toggleShowFilters}
    data-testid="files-filters-dropdown"
    actions={[
      <Icon
        onClick={toggleShowFilters}
        boxSize="small"
        color="grey.600"
        size="small"
        type={showFilters ? <ExpandLessSvg /> : <ExpandMoreSvg />}
      />,
    ]}
    label={
      <Flex alignItems="center">
        <Box mr={2}>
          <FormattedMessage {...messages.labelFilterList} />
        </Box>
        {filtersCounter > 0 && (
          <FiltersCounter>{filtersCounter}</FiltersCounter>
        )}
      </Flex>
    }
    rightItem={filtersCounter > 0 && <span>{filtersImagesCounter}</span>}
    textStyle="body"
    data-intercom-target="filters"
  />
);

FiltersButton.propTypes = {
  filtersCounter: PropTypes.number.isRequired,
  filtersImagesCounter: PropTypes.number,
  showFilters: PropTypes.bool,
  toggleShowFilters: PropTypes.func.isRequired,
};

FiltersButton.defaultProps = {
  filtersImagesCounter: undefined,
  showFilters: undefined,
};

export default FiltersButton;
