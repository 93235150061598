import { defineMessages } from 'react-intl';

export default defineMessages({
  labelAddImages: {
    id: 'UploadArea.labelAddImages',
    defaultMessage: 'Add files',
  },
  messageDragDropToUpload: {
    id: 'UploadArea.messageDragDropToUpload',
    defaultMessage: 'or just drag’n’drop to upload it here',
  },
  messageUploadImages: {
    id: 'UploadArea.messageUploadImages',
    defaultMessage: '1. Upload files',
  },
  messageShareProject: {
    id: 'UploadArea.messageShareProject',
    defaultMessage: '2. Share project via link',
  },
  messageReviewAndDownload: {
    id: 'UploadArea.messageReviewAndDownload',
    defaultMessage: '3. Allow others to review and download',
  },
  messageDropFiles: {
    id: 'UploadArea.messageDropFiles',
    defaultMessage: 'Drop files...',
  },
});
