import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';

const CommentActions = styled.div.attrs({
  className: 'comment__actions',
})`
  align-items: center;
  display: flex;
  min-height: ${themeGet('space.5')}px;
`;

export default CommentActions;
