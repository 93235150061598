import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TrackerEvent } from '@picter/tracker';

import capitalize from 'src/utils/capitalize';
import { formattedMessage } from 'src/utils/app-prop-types';
import { Dropdown, Icon } from 'src/modules/prisma';

function ActionsDropdownItem({
  action,
  disabled,
  entity,
  icon,

  goToMenu,
  keepMenuOpen,
  onClick,
  ...props
}) {
  const { closeMenu, goTo } = useContext(Dropdown.Context);
  const actionCapitalized = capitalize(action);
  const event = [actionCapitalized, entity].join(' ').trim();

  return (
    <TrackerEvent name={event} trackClick>
      <Dropdown.Item
        {...props}
        icon={icon ? <Icon type={icon} /> : null}
        onClick={() => {
          if (onClick) onClick();
          if (goToMenu) {
            goTo(goToMenu);
          } else if (!keepMenuOpen) {
            closeMenu();
          }
        }}
      />
    </TrackerEvent>
  );
}

ActionsDropdownItem.propTypes = {
  action: PropTypes.string.isRequired,
  keepMenuOpen: PropTypes.bool,
  disabled: PropTypes.bool,
  entity: PropTypes.string,
  icon: PropTypes.element.isRequired,
  goToMenu: PropTypes.number,
  label: formattedMessage.isRequired,
  onClick: PropTypes.func,
};

ActionsDropdownItem.defaultProps = {
  keepMenuOpen: false,
  disabled: undefined,
  entity: undefined,
  goToMenu: undefined,
  onClick: undefined,
};

export default ActionsDropdownItem;
