import styled, { css } from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';
import { mediaQuery } from '@picter/prisma';

import { Checkbox } from 'src/modules/prisma';
import CommentActions from './CommentActions';
import CommentAside from './CommentAside';
import CommentDropdown from './CommentDropdown';
import CommentHeader from './CommentHeader';
import CommentSection from './CommentSection';
import CommentFooter from './CommentFooter';

const CommentContainer = styled.article`
  display: grid;
  grid-template-areas:
    'aside header'
    'aside section'
    'aside footer';
  grid-template-columns: min-content 1fr;
  row-gap: ${themeGet('space.3')}px;
  padding: ${themeGet('space.4')}px;
  border-bottom: 1px solid ${themeGet('colors.grey.200')};

  &.comment-reply {
    padding-left: ${themeGet('space.7')}px;
  }

  .bigger-preview &.comment-reply {
    padding-left: 176px;
  }

  ${CommentAside} {
    grid-area: aside;
  }
  ${CommentHeader} {
    grid-area: header;
  }
  ${CommentFooter} {
    grid-area: footer;
  }
  ${CommentSection} {
    grid-area: section;
  }

  @media screen and ${mediaQuery.greaterThan('mobile')} {
    ${props =>
      !props.assigned &&
      css`
        ${CommentActions} {
          visibility: hidden;

          ${Checkbox /* sc-sel */}:checked {
            visibility: visible;
          }
        }
      `}

    &:hover ${CommentActions} {
      visibility: visible;
    }

    ${CommentDropdown} {
      visibility: hidden;
    }

    &:hover ${CommentDropdown} {
      visibility: visible;
    }
  }
`;

export default CommentContainer;
