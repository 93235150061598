import React, { useMemo } from 'react';
import { Gesture } from '@styled-icons/material';

import { useAnnotations } from 'src/components/Annotations';
import { Icon } from 'src/modules/prisma';

const colorMap = {
  highlighted: 'info',
  selected: 'primary',
  default: 'grey.400',
} as const;

export default function CommentAnnotationMarker({ id }: { id: string }) {
  const [{ highlight }, { highlightedId, selectedId }] = useAnnotations();

  const selected = id === selectedId;
  const highlighted = id === highlightedId;

  const handlers = useMemo(
    () => ({
      onFocus: () => highlight(id),
      onMouseOver: () => highlight(id),
      onBlur: () => highlight(id),
      onMouseOut: () => highlight(id),
    }),
    [highlight, id],
  );

  let variant: keyof typeof colorMap = 'default';

  if (highlighted) variant = 'highlighted';
  if (selected) variant = 'selected';

  return (
    <Icon
      {...handlers}
      backgroundColor="transparent"
      boxSize="small"
      display="flex"
      color={colorMap[variant]}
      size="small"
      type={<Gesture />}
    />
  );
}
