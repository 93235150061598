import styled from 'styled-components/macro';
import tag from 'clean-tag';
import { CheckBox, CheckBoxOutlineBlank } from '@styled-icons/material';
import themeGet from '@styled-system/theme-get';
import { background, space, layout } from 'styled-system';
import { buildDataUriSvg, extendTagBlacklist } from '@picter/prisma';

const Checkbox = styled(tag.input).attrs({
  role: 'checkbox',
  type: 'checkbox',
})`
  ${background};
  ${space};
  ${layout};
  appearance: none;
  background-image: url(${props =>
    buildDataUriSvg(props.defaultSvg, {
      color: themeGet(`colors.${props.defaultColor}`)(props),
    })});
  cursor: pointer;
  height: ${props => themeGet(`sizes.checkbox.${props.size}`)(props)}px;
  width: ${props => themeGet(`sizes.checkbox.${props.size}`)(props)}px;

  &:disabled {
    background-image: url(${props =>
      buildDataUriSvg(props.disabledSvg || props.defaultSvg, {
        color: themeGet(`colors.${props.disabledColor}`)(props),
      })});
    cursor: not-allowed;
  }

  &:hover {
    background-image: url(${props =>
      buildDataUriSvg(props.hoverSvg || props.defaultSvg, {
        color: themeGet(`colors.${props.hoverColor}`)(props),
      })});
  }

  &:checked {
    background-image: url(${props =>
      buildDataUriSvg(props.checkedSvg, {
        color: themeGet(`colors.${props.checkedColor}`)(props),
      })});

    :disabled {
      background-image: url(${props =>
        buildDataUriSvg(props.disabledSvg || props.checkedSvg, {
          color: themeGet(`colors.${props.disabledColor}`)(props),
        })});
    }
  }
`;

Checkbox.propTypes = {};

// order matters for overriding certain props (e.g. font weight)
Checkbox.defaultProps = {
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100%',
  blacklist: extendTagBlacklist([
    'checkedColor',
    'checkedSvg',
    'disabledColor',
    'disabledSvg',
    'defaultColor',
    'defaultSvg',
    'hoverColor',
    'hoverSvg',
  ]),
  checkedColor: 'primary',
  checkedSvg: CheckBox,
  defaultColor: 'grey.600',
  defaultSvg: CheckBoxOutlineBlank,
  disabledColor: 'grey.400',
  disabledSvg: null,
  hoverColor: 'grey.600',
  hoverSvg: null,
  size: 'medium',
};

Checkbox.displayName = 'Checkbox';

export default Checkbox;
