import Immutable from 'immutable';
import camelCase from 'lodash/camelCase';
import {
  API_CREATE_DATA_TYPES,
  API_DESTROY_DATA_TYPES,
  API_LOAD_DATA_TYPES,
  API_UPDATE_DATA_TYPES,
  API_ATTACH_RELATIONSHIP_TYPES,
  API_REMOVE_RELATIONSHIP_TYPES,
  API_UPDATE_RELATIONSHIP_TYPES,
  API_LOCAL_ATTACH_RELATIONSHIP,
  API_LOCAL_REMOVE_RELATIONSHIP,
  API_LOCAL_UPDATE_RELATIONSHIP,
  API_RESET_STORE,
  API_STATE_UPDATE,
  API_LOCAL_CREATE,
  API_LOCAL_UPDATE,
  API_LOCAL_DESTROY,
} from '../../constants';
import createDataReducer from './create';
import destroyDataReducer from './destroy';
import mergeDeepOverwriteLists from '../../utils/merge-deep-overwrite-lists';

const initialState = Immutable.fromJS({
  meta: {},
});

const createReducer = schemas => (state = initialState, action) => {
  switch (action.type) {
    case API_CREATE_DATA_TYPES.REQUEST:
    case API_DESTROY_DATA_TYPES.REQUEST:
    case API_LOAD_DATA_TYPES.REQUEST:
      return state.mergeDeep({
        meta: {
          [action.endpoint]: { loading: true },
        },
      });
    case API_UPDATE_DATA_TYPES.REQUEST:
      return state.mergeDeep({
        meta: {
          [action.endpoint]: { loading: true },
        },
        ...(action.meta && action.meta.optimisticPayload),
      });

    case API_CREATE_DATA_TYPES.SUCCESS:
    case API_LOCAL_CREATE:
      return createDataReducer(state, action, schemas);
    case API_LOAD_DATA_TYPES.SUCCESS:
    case API_UPDATE_DATA_TYPES.SUCCESS: {
      const { meta, ...resources } = action.payload;
      if (action.meta && action.meta.optimistic) {
        return state;
      }

      const mergedState = mergeDeepOverwriteLists(state, {
        ...resources,
        meta: {
          [action.endpoint]: {
            meta: meta[action.endpoint] && meta[action.endpoint].meta,
            loading: false,
          },
        },
      });

      const [endpointWithoutIncludes] = action.endpoint.split('?');
      const [model, modelId, related] = endpointWithoutIncludes.split('/');

      // If its a related load
      if (related && action.type === API_LOAD_DATA_TYPES.SUCCESS) {
        const relatedNewData = meta[endpointWithoutIncludes].data;
        const isMany = Array.isArray(relatedNewData);

        if (isMany && !relatedNewData.length) {
          return mergedState;
        }

        return mergedState.updateIn(
          [camelCase(model), modelId, 'relationships', related, 'data'],
          (relatedData = Immutable.List()) => {
            if (isMany) {
              // Handle deduplication of new relations
              const newIds = relatedNewData.map(item => item.id);
              const existentIds = relatedData.map(item => item.get('id'));
              const ids = Immutable.Set(newIds).subtract(existentIds).toList();
              const relationType = relatedNewData[0].type;
              const items = ids.map(id =>
                Immutable.Map({ id, type: relationType }),
              );
              return relatedData.concat(items);
            }
            // If it's not a to-many relationship, just use relatedItems
            return Immutable.Map({
              id: relatedNewData.id,
              type: relatedNewData.type,
            });
          },
        );
      }
      return mergedState;
    }

    case API_DESTROY_DATA_TYPES.SUCCESS:
    case API_LOCAL_DESTROY:
      return destroyDataReducer(state, action, schemas);

    case API_ATTACH_RELATIONSHIP_TYPES.SUCCESS:
    case API_LOCAL_ATTACH_RELATIONSHIP:
      if (
        state.getIn([
          action.meta.model,
          action.meta.id,
          'relationships',
          action.meta.relation,
        ])
      ) {
        return state.updateIn(
          [
            action.meta.model,
            action.meta.id,
            'relationships',
            action.meta.relation,
          ],
          map => {
            const existing = (map.get('data') || new Immutable.List()).reduce(
              (orderedMap, item) => orderedMap.set(item.get('id'), item),
              new Immutable.OrderedMap(),
            );

            const newItems = action.meta.data.reduce(
              (orderedMap, item) =>
                orderedMap.set(item.id, Immutable.fromJS(item)),
              new Immutable.OrderedMap(),
            );

            return map.set('data', existing.merge(newItems).toList());
          },
        );
      }

      return state.setIn(
        [
          action.meta.model,
          action.meta.id,
          'relationships',
          action.meta.relation,
        ],
        Immutable.fromJS({ data: action.meta.data }),
      );

    case API_UPDATE_RELATIONSHIP_TYPES.SUCCESS:
    case API_LOCAL_UPDATE_RELATIONSHIP:
      return state.setIn(
        [
          action.meta.model,
          action.meta.id,
          'relationships',
          action.meta.relation,
          'data',
        ],
        Immutable.fromJS(action.meta.data),
      );

    case API_REMOVE_RELATIONSHIP_TYPES.SUCCESS:
    case API_LOCAL_REMOVE_RELATIONSHIP:
      return state.updateIn(
        [
          action.meta.model,
          action.meta.id,
          'relationships',
          action.meta.relation,
        ],
        map => {
          const existing = (map.get('data') || new Immutable.List()).reduce(
            (orderedMap, item) => orderedMap.set(item.get('id'), item),
            new Immutable.OrderedMap(),
          );

          const newList = existing
            .withMutations(values =>
              action.meta.data.forEach(item => values.delete(item.id)),
            )
            .toList();

          return map.set('data', newList);
        },
      );

    case API_UPDATE_DATA_TYPES.ERROR: {
      if (
        !(action.meta && action.meta.optimistic && action.meta.previousValue)
      ) {
        return state;
      }

      return state.setIn(
        [action.meta.model, action.meta.id],
        Immutable.fromJS(action.meta.previousValue),
      );
    }

    case API_LOCAL_UPDATE:
      return mergeDeepOverwriteLists(state, {
        ...action.payload,
      });

    case API_STATE_UPDATE:
      return mergeDeepOverwriteLists(state, action.payload);

    case API_RESET_STORE:
      return initialState;

    default:
      return state;
  }
};

export default createReducer;
