import { defineMessages } from 'react-intl';

export default defineMessages({
  titlePage: {
    id: 'ManageAccountPage.titlePage',
    defaultMessage: 'Profile',
  },

  // Profile section
  labelFirstName: {
    id: 'ProfileSection.labelFirstName',
    defaultMessage: 'First name',
  },
  labelLastName: {
    id: 'ProfileSection.labelLastName',
    defaultMessage: 'Last name',
  },
});
