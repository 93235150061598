import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { toastInfo } from '@picter/prisma';

import { useToriiActions } from 'src/modules/torii';
import {
  addImageToCollection,
  addImagesToCollection,
  removeImageFromCollection,
  removeImagesFromCollection,
} from 'src/actions/collection';
import { get } from 'src/utils/accessors';

import messages from '../messages';

function useHandlers({
  collection,
  // These collection-images are already
  // part of this specific collection
  relatedCollectionImages,
  imagesIds,
  project,
}) {
  const { create, bulkCreate, destroy, bulkDestroy } = useToriiActions();

  const collectionId = get(collection, 'id');
  const collectionName = get(collection, 'attributes.name');
  const projectPublicUrlKey = get(project, 'attributes.publicUrlKey');

  const addToCollection = useCallback(() => {
    const notOnCollectionImagesIds =
      relatedCollectionImages.length > 0
        ? // Find all image ids that are not related to this collection yet
          imagesIds.filter(
            imageId =>
              !relatedCollectionImages.find(
                collectionImage =>
                  get(collectionImage, 'relationships.image.id') === imageId,
              ),
          )
        : imagesIds;

    if (notOnCollectionImagesIds.length > 1) {
      return addImagesToCollection(
        {
          imagesIds: notOnCollectionImagesIds,
          collectionId,
          projectPublicKey: projectPublicUrlKey,
        },
        { bulkCreate },
      ).then(() =>
        toastInfo(
          <FormattedMessage
            {...messages.messageImagesAddedToCollection}
            values={{
              collectionName,
              imagesSize: notOnCollectionImagesIds.length,
            }}
          />,
        ),
      );
    }

    return addImageToCollection(
      {
        imageId: notOnCollectionImagesIds[0],
        collectionId,
        projectPublicKey: projectPublicUrlKey,
      },
      { create },
    ).then(() =>
      toastInfo(
        <FormattedMessage
          {...messages.messageImagesAddedToCollection}
          values={{
            collectionName,
            imagesSize: notOnCollectionImagesIds.length,
          }}
        />,
      ),
    );
  }, [
    imagesIds,
    collectionId,
    relatedCollectionImages,
    projectPublicUrlKey,
    bulkCreate,
    collectionName,
    create,
  ]);

  const removeFromCollection = useCallback(() => {
    const collectionImagesIds = relatedCollectionImages.map(collectionImage =>
      get(collectionImage, 'id'),
    );

    if (collectionImagesIds.length > 1) {
      return removeImagesFromCollection(
        {
          collectionImagesIds,
          projectPublicKey: projectPublicUrlKey,
        },
        { bulkDestroy },
      ).then(() =>
        toastInfo(
          <FormattedMessage
            {...messages.messageImagesRemovedFromCollection}
            values={{
              collectionName,
              imagesSize: collectionImagesIds.length,
            }}
          />,
        ),
      );
    }

    const collectionImageId = collectionImagesIds[0];
    return removeImageFromCollection(
      {
        collectionImageId,
        projectPublicKey: projectPublicUrlKey,
      },
      { destroy },
    ).then(() =>
      toastInfo(
        <FormattedMessage
          {...messages.messageImagesRemovedFromCollection}
          values={{
            collectionName,
            imagesSize: collectionImagesIds.length,
          }}
        />,
      ),
    );
  }, [
    bulkDestroy,
    relatedCollectionImages,
    collectionName,
    destroy,
    projectPublicUrlKey,
  ]);

  return { addToCollection, removeFromCollection };
}

export default useHandlers;
