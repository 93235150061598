import React, {
  useMemo,
  useEffect,
  useRef,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { VariableSizeList as List } from 'react-window';

import GridItem from './GridRow';

const Grid = ({
  scrollToIndex,
  children,
  columnWidth,
  controlHeight,
  itemCount,
  height,
  paddingBottom,
  paddingTop,
  paddingLeft,
  paddingRight,
  width,
  itemMargin,
  gridHeader,
  headerHeight,
  itemPaddingHorizontal,
  ...props
}) => {
  const [innerWidth, setInnerWidth] = useState(width);
  const parsedPaddingLeft = paddingLeft - itemMargin;
  const parsedPaddingRight = paddingRight - itemMargin;
  const parsedWidth = innerWidth - (parsedPaddingLeft + parsedPaddingRight);
  // We need a ref to List to access its methods.
  const listRef = useRef();
  // The next two refs are needed to keep the current
  // images on screen when the number of columns changes.
  // The current row index is updated on every scroll event.
  // The previous number of columns is set when the number of
  // columns changes.
  const currentIndex = useRef();
  const prevNumColumns = useRef();

  // Calculate number of columns based on list-width and
  // target column-width.
  const numColumns = useMemo(() => {
    const columns = Math.floor(parsedWidth / columnWidth);
    const marginValues = itemMargin * columns * 2;
    if (columns * columnWidth + marginValues > parsedWidth) return columns - 1;
    return columns;
  }, [parsedWidth, columnWidth, itemMargin]);

  // Calculate number of rows based on number of columns
  // and number of items.
  const numDataRows = useMemo(() => Math.ceil(itemCount / numColumns), [
    itemCount,
    numColumns,
  ]);
  // Calculate row height based on column width and controlHeight.
  const rowHeight = useMemo(() => {
    const widthLessMargins = parsedWidth - itemMargin * numColumns * 2;
    return (
      widthLessMargins / numColumns -
      // Needed in case the item has horizontal padding, which shouldn't be considered when calculating the height.
      itemPaddingHorizontal +
      controlHeight +
      itemMargin * 2
    );
  }, [
    parsedWidth,
    numColumns,
    controlHeight,
    itemMargin,
    itemPaddingHorizontal,
  ]);

  // List padding is created by adding dummy rows at the top
  // and the bottom of the list. This method returns the respective
  // height for padding and regular content rows.
  const getRowHeight = rowIndex => {
    if (rowIndex === 0) return headerHeight;
    if (rowIndex === 1) return paddingTop - itemMargin;
    // last row = number of data rows + 2 (header and padding top)
    if (rowIndex === numDataRows + 2) return paddingBottom - itemMargin;
    return rowHeight;
  };

  // The following effect is called as soon as the list's width or
  // the number of columns change.
  // <VariableSizeList> caches row-heights based on indices.
  // If the number of columns or list-width changes, this
  // cache needs to be reset.
  useEffect(() => {
    if (listRef.current && prevNumColumns.current) {
      // Reset cache and force re-render.
      listRef.current.resetAfterIndex(0, true);
    }

    // Update prevNumColumns.
    if (prevNumColumns.current !== numColumns) {
      prevNumColumns.current = numColumns;
    }
  }, [numColumns, numDataRows, innerWidth]);

  useEffect(() => {
    if (scrollToIndex >= 0) {
      // +2 (header and padding top)
      const targetRow = Math.floor(scrollToIndex / numColumns) + 2;

      // +2 (header and padding top) and +1 to consider we are comparing size to index
      if (numDataRows + 2 === targetRow + 1) {
        // Needed because of weird bug on last item that was not centered properly
        // probably due to size calculations done with getRowHeight once the first
        // render of the list uses the static itemSize so probably the scroll is using
        // the old position and we have no way to check that dynamic value
        listRef.current.scrollToItem(targetRow + 2, 'center');
      } else {
        listRef.current.scrollToItem(targetRow, 'center');
      }
    }
  }, [numColumns, numDataRows, scrollToIndex]);

  const onInnerRefChange = useCallback(element => {
    if (element) {
      // Defer setting the width a little bit to avoid "Maximum update depth exceeded" error
      // (https://reactjs.org/docs/error-decoder.html/?invariant=185)
      requestAnimationFrame(() => setInnerWidth(element.offsetWidth));
    }
  }, []);

  // Used to keep track of the inner width once the with provided is actually counting the
  // scrollbar width and this may be a problem when trying to calc the item height
  const innerElementType = useMemo(
    () =>
      React.forwardRef((listProps, ref) => (
        <div
          ref={r => {
            if (ref) ref(r);
            onInnerRefChange(r);
          }}
          {...listProps}
        />
      )),
    [onInnerRefChange],
  );

  const itemData = useMemo(
    () => ({
      numRows: numDataRows,
      paddingLeft: parsedPaddingLeft,
      paddingRight: parsedPaddingRight,
      numColumns,
      itemCount,
      children,
      rowHeight,
      margin: itemMargin,
      gridHeader,
    }),
    [
      numDataRows,
      parsedPaddingLeft,
      parsedPaddingRight,
      numColumns,
      itemCount,
      children,
      rowHeight,
      itemMargin,
      gridHeader,
    ],
  );

  return (
    <List
      ref={listRef}
      height={height}
      // number of data rows + 3 (header, padding-top, padding-bottom)
      itemCount={numDataRows + 3}
      itemSize={getRowHeight}
      itemData={itemData}
      estimatedItemSize={rowHeight}
      width={width}
      onItemsRendered={({ visibleStartIndex }) => {
        currentIndex.current = visibleStartIndex;
      }}
      innerElementType={innerElementType}
      {...props}
    >
      {GridItem}
    </List>
  );
};

Grid.propTypes = {
  children: PropTypes.func.isRequired,
  controlHeight: PropTypes.number,
  columnWidth: PropTypes.number,
  itemCount: PropTypes.number.isRequired,
  height: PropTypes.number,
  paddingBottom: PropTypes.number,
  paddingTop: PropTypes.number,
  width: PropTypes.number,
  paddingLeft: PropTypes.number,
  paddingRight: PropTypes.number,
  itemMargin: PropTypes.number,
  gridHeader: PropTypes.node,
  headerHeight: PropTypes.number,
  itemPaddingHorizontal: PropTypes.number,
  scrollToIndex: PropTypes.number,
};

Grid.defaultProps = {
  columnWidth: 150,
  controlHeight: 0,
  paddingBottom: 0,
  paddingTop: 0,
  paddingLeft: 0,
  paddingRight: 0,
  height: 600,
  width: 600,
  itemMargin: 2,
  gridHeader: null,
  headerHeight: 0,
  itemPaddingHorizontal: 0,
  scrollToIndex: -1,
};

export default Grid;
