import React, { useEffect } from 'react';
import { compose, defaultProps } from 'recompose';
import { withRouter, Redirect, useLocation, useParams } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { withTracker } from '@picter/tracker';
import { Map } from 'immutable';

import { buildProjectTitle } from 'src/utils/title-builder';
import { useProject } from 'src/hooks/use-resource';
import withSuspenseLoader from 'src/hocs/with-suspense-loader';
import { useRealtimeJSONApi } from 'src/modules/realtime';
import { replacePublicLinkKey } from 'src/utils/inject-public-link-key';
import {
  publicLinkTypes,
  getProjectPublicLinkByType,
} from 'src/utils/accessors/project';
import { ProjectFlagsProvider } from 'src/containers/FeatureFlags';
import { SelectableComponentsProvider } from 'src/modules/SelectableComponents';
import AppLayout from 'src/components/AppLayout';
import { projectUrl } from 'src/routes/urls';
import { get } from 'src/utils/accessors';
import { projectCollectionSelectorById } from 'src/selectors/collection';
import useDocumentTitle from 'src/hooks/use-document-title';
import useFormatMessage from 'src/hooks/use-format-message';

import Layout from './_layout';

// eslint-disable-next-line react/prop-types
function PresentationPage({ tracker }) {
  const formatMessage = useFormatMessage();
  const params = useParams();
  const { pathname } = useLocation();
  const { projectId: publicLinkKey, collectionId } = params;
  const { subscribe: subscribeToRealtime } = useRealtimeJSONApi();

  const project = useProject({
    id: publicLinkKey,
    include: [
      'collections',
      'publicLinks.collections',
      'space.plan.features',
      'space.extraFeatures',
    ],
  });

  const publicLink = getProjectPublicLinkByType(
    project,
    publicLinkTypes.PRESENT,
  );

  const collection = projectCollectionSelectorById(
    { project },
    { id: collectionId },
  );

  const projectId = get(project, 'id');
  const projectName = buildProjectTitle({ project, formatMessage });

  useEffect(() => {
    const subscription = subscribeToRealtime(
      replacePublicLinkKey(
        `private-resource=ws-projects;id=${projectId};publicKey=${publicLinkKey}`,
        { params },
      ),
    );

    return subscription;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeToRealtime, publicLinkKey, projectId]);

  useDocumentTitle(projectName);

  const hasAccessToAllFiles = get(publicLink, 'attributes.accessToAllFiles');
  const publicLinkCollections = get(publicLink, 'relationships.collections');

  if (
    !hasAccessToAllFiles &&
    !collection &&
    publicLinkCollections.size > 0 &&
    !pathname.match(/info/)
  ) {
    return (
      <Redirect
        to={projectUrl({
          projectId: get(publicLink, 'attributes.key'),
          collectionId: get(publicLinkCollections, '0.id'),
        })}
      />
    );
  }

  return (
    <ProjectFlagsProvider project={project}>
      <SelectableComponentsProvider>
        <AppLayout>
          <Layout
            tracker={tracker}
            withSidebar={!hasAccessToAllFiles && publicLinkCollections.size > 1}
            hasAccessToAllFiles={hasAccessToAllFiles}
          />
        </AppLayout>
      </SelectableComponentsProvider>
    </ProjectFlagsProvider>
  );
}

export default compose(
  defaultProps({
    project: Map(),
  }),
  withTracker(),
  injectIntl,
  withRouter,
  withSuspenseLoader(),
)(PresentationPage);
