import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { get } from 'src/utils/accessors';
import { useProject } from 'src/hooks/use-resource';

export default function SpaceFavicon() {
  const { projectId: projectPublicUrlKey } = useParams<{
    projectId: string;
  }>();
  const project = useProject(
    {
      id: projectPublicUrlKey,
      include: ['space'],
    },
    {
      request: false,
    },
  );
  const spaceLogo = get(
    project,
    'relationships.space.attributes.logo.favicon32',
  );
  if (!spaceLogo) {
    return null;
  }

  return (
    <Helmet>
      <link rel="icon" type="image/png" href={spaceLogo} />
    </Helmet>
  );
}
