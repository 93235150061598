import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import flatMap from 'lodash/flatMap';
import { FormattedMessage } from 'react-intl';
import { Dropdown, Scrollbar } from '@picter/prisma';
import { TrackerEvent, TrackerScope } from '@picter/tracker';

import { useProject, useProjectImage } from 'src/hooks/use-resource';
import { sortedProjectCollectionsSelector } from 'src/selectors/collection';
import { get } from 'src/utils/accessors';

import CollectionDropdownItem from './CollectionDropdownItem';
import messages from '../messages';

const CollectionActionsDropdownBody = ({
  closeOnSelect,
  imagesIds,
  projectId,
  closeMenu,
  openConfirmCreateModal,
}) => {
  // Select project including collections, to be able to
  // render list of all available collections
  const project = useProject(
    {
      id: projectId,
      include: ['collections'],
    },
    { request: false },
  );

  // Select all project-images defined in "imageIds" including
  // collection images, to know to which collection these images
  // have been added
  const images = useProjectImage(
    {
      id: imagesIds,
      include: ['collectionImages'],
    },
    { request: false },
  );
  const collections = sortedProjectCollectionsSelector({ project });

  // Create a map, that maps collection ids to lists of related collection
  // images, so we can pass them to each related CollectionDropdownItem
  const collectionImagesMap = useMemo(() => {
    // Get flat map of all existing collection images related to images
    // defined by `imageIds`.
    // The toArray calls need to be removed when migrating from immutable to immer
    const collectionImages = flatMap(images.toArray(), image =>
      get(image, ['relationships', 'collectionImages']).toArray(),
    );
    // Now we have an array of all collection images related to images defined
    // by `imageIds`. This array needs to be grouped by collection ids, so we
    // finally get our desired map
    return groupBy(collectionImages, collectionImage =>
      get(collectionImage, ['relationships', 'collection', 'data', 'id']),
    );
  }, [images]);

  return (
    <>
      <TrackerScope name="collections menu">
        <Dropdown.Menu>
          {collections && collections.size > 0 ? (
            <>
              <Scrollbar maxHeight="200px">
                {collections &&
                  collections.map(collection => {
                    const collectionId = get(collection, 'id');
                    return (
                      <TrackerEvent
                        key={collectionId}
                        name="Toggle collection"
                        trackClick
                      >
                        <CollectionDropdownItem
                          collection={collection}
                          // Pass down related collection-images from
                          // our collectionImageMap. These collection-images
                          // are already part of the specific collection
                          relatedCollectionImages={
                            collectionImagesMap[collectionId]
                          }
                          imagesIds={imagesIds}
                          project={project}
                          onSelect={() => {
                            if (closeOnSelect) {
                              closeMenu();
                            }
                          }}
                        />
                      </TrackerEvent>
                    );
                  })}
              </Scrollbar>
              <Dropdown.Separator />
            </>
          ) : null}
          <TrackerEvent name="Add collection" trackClick>
            <Dropdown.Item
              icon="add_circle_outline"
              label={<FormattedMessage {...messages.labelAddCollection} />}
              onClick={() => {
                closeMenu();
                openConfirmCreateModal();
              }}
            />
          </TrackerEvent>
        </Dropdown.Menu>
      </TrackerScope>
    </>
  );
};

CollectionActionsDropdownBody.propTypes = {
  className: PropTypes.string,
  closeOnSelect: PropTypes.bool.isRequired,
  imagesIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  projectId: PropTypes.string,
  closeMenu: PropTypes.func,
  openConfirmCreateModal: PropTypes.func,
};

CollectionActionsDropdownBody.defaultProps = {
  className: null,
  projectId: null,
  closeMenu: null,
  openConfirmCreateModal: null,
};

export default CollectionActionsDropdownBody;
