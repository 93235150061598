// @ts-nocheck
import React, { useCallback, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import {
  Close as CloseSvg,
  Undo as UndoSvg,
  Check as CheckSvg,
} from '@styled-icons/material';
import themeGet from '@styled-system/theme-get';
import useTinyKeys from 'src/hooks/use-tiny-keys';

import { Box, Button, Icon, Tooltip } from 'src/modules/prisma';
import { isMac } from 'src/utils/user-agent';
import usePositionRelativeToMouse from '../hooks';
import { ANNOTATION_IN_PROGRESS_ID } from '../models/Annotation';
import { useAnnotations } from '../providers/AnnotationsProvider';
import messages from '../messages';

const ActionsContainer = styled.div`
  position: relative;
  z-index: 1;

  ${Button} {
    ${Icon} {
      margin-right: ${themeGet('space.1')}px;
    }
  }

  > ${Button} {
    border-radius: 100px;
    position: relative;
    z-index: 0;

    &:hover {
      z-index: 1;
    }

    &.active {
      z-index: 2;
    }

    :first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    :last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    :not(:first-child):not(:last-child) {
      border-radius: 0;
    }
  }

  > *:not(${Button /* sc-sel */}) {
    ${Button} {
      border-radius: 100px;
      position: relative;
      z-index: 0;
    }

    & ${Button /* sc-sel */}:hover {
      z-index: 1;
    }

    & ${Button /* sc-sel */}.active {
      z-index: 2;
    }

    &:first-child {
      ${Button} {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child {
      ${Button} {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    :not(:first-child):not(:last-child) {
      ${Button} {
        border-radius: 0;
      }
    }
  }
`;

function MarkupActions({
  x = 0,
  y = 0,

  onClickDone,
}: {
  x: number | undefined;
  y: number | undefined;

  onClickDone: () => void;
}) {
  const [{ create, remove, deselect }, { annotations }] = useAnnotations();

  const annotation = annotations.get(ANNOTATION_IN_PROGRESS_ID);
  const position = usePositionRelativeToMouse({
    x,
    y,
  });

  const cancelMarkup = useCallback(
    function cancelMarkup() {
      remove(ANNOTATION_IN_PROGRESS_ID);
      deselect();
    },
    [deselect, remove],
  );

  const undoMarkup = useCallback(
    function undoMarkup() {
      if (annotation) {
        if (annotation.markups.length <= 1) cancelMarkup();
        else create(annotation.markups.slice(0, -1)); // remove last markup
      }
    },
    [annotation, cancelMarkup, create],
  );

  useTinyKeys(
    window.document,
    {
      Enter: (event: KeyboardEvent) => {
        event.preventDefault();
        event.stopPropagation();

        onClickDone();
      },
      Escape: (event: KeyboardEvent) => {
        event.preventDefault();
        event.stopPropagation();

        cancelMarkup();
      },
      '$mod+Z': (event: KeyboardEvent) => {
        event.preventDefault();
        event.stopPropagation();

        undoMarkup();
      },
    },
    [onClickDone, cancelMarkup, undoMarkup],
  );

  return (
    annotation && (
      <>
        <Box {...position} position="absolute">
          <ActionsContainer>
            <Tooltip>
              <Tooltip.Reference>
                <Button
                  alignItems="center"
                  backgroundColor="black"
                  backgroundOpacity={0.75}
                  color="white"
                  display="inline-flex"
                  textStyle="action.small"
                  onClick={onClickDone}
                >
                  <Icon color="success" size="small" type={<CheckSvg />} />
                  <FormattedMessage {...messages.markupDoneButton} />
                </Button>
              </Tooltip.Reference>
              <Tooltip.Message>Enter</Tooltip.Message>
            </Tooltip>
            <Tooltip>
              <Tooltip.Reference>
                <Button
                  alignItems="center"
                  backgroundColor="black"
                  backgroundOpacity={0.75}
                  color="white"
                  display="inline-flex"
                  textStyle="action.small"
                  onClick={undoMarkup}
                  px={2}
                >
                  <Icon color="grey.400" size="small" type={<UndoSvg />} />
                </Button>
              </Tooltip.Reference>
              <Tooltip.Message>
                <FormattedMessage {...messages.markupUndoTooltip} /> (
                {isMac ? 'Cmd + Z' : 'Ctrl + Z'})
              </Tooltip.Message>
            </Tooltip>
            <Tooltip>
              <Tooltip.Reference>
                <Button
                  alignItems="center"
                  backgroundColor="black"
                  backgroundOpacity={0.75}
                  color="white"
                  display="inline-flex"
                  textStyle="action.small"
                  onClick={cancelMarkup}
                  px={2}
                >
                  <Icon color="grey.400" size="small" type={<CloseSvg />} />
                </Button>
              </Tooltip.Reference>
              <Tooltip.Message>
                <FormattedMessage {...messages.markupCancelTooltip} /> (Esc)
              </Tooltip.Message>
            </Tooltip>
          </ActionsContainer>
        </Box>
      </>
    )
  );
}

export default memo(MarkupActions);
