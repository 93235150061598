import React from 'react';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import { ButtonElement as Button } from '@picter/prisma';
import { ReactElementLike } from 'prop-types';

import { Text } from 'src/modules/prisma';
import { PlanResource, PartnerLicenseResource } from 'src/types/resources';

import PlanInfo from './SubscriptionPage/components/PlanInfo';
import PlansGrid from './SubscriptionPage/styles/PlansGrid';

const messages = defineMessages({
  messageMembers: {
    id: 'AppSumoFeatures.messageMembers',
    defaultMessage: '{numberOfMembers} members',
  },
  messageGuests: {
    id: 'AppSumoFeatures.messageGuests',
    defaultMessage: '{numberOfGuests} guests',
  },
  messageStorage: {
    id: 'AppSumoFeatures.messageStorage',
    defaultMessage: '{sizeWithUnit} Storage',
  },
  messageBandwidth: {
    id: 'AppSumoFeatures.messageBandwidth',
    defaultMessage: '{bandwidthWithUnit} Bandwidth per month',
  },
  messageVideoMinutesVolume: {
    id: 'AppSumoFeatures.messageVideoMinutesVolume',
    defaultMessage: '{numberOfMinutes} video minutes encoding volume per month',
  },
  messageTeamManagement: {
    id: 'AppSumoFeatures.messageTeamManagement',
    defaultMessage: 'Team management',
  },
});

function CurrentTierMessage() {
  return (
    <Text
      color="success"
      px={4}
      py={2}
      tag="p"
      textAlign="center"
      textStyle="action.regular"
    >
      <FormattedMessage
        id="PartnerLicenseTiers.labelCurrentTier"
        defaultMessage="Your current tier"
      />
    </Text>
  );
}
function ChangeToButton({
  tier,
  licenseUrl,
}: {
  tier: number;
  licenseUrl: string;
}) {
  return (
    <a
      href={`https://appsumo.8odi.net/c/2641777/416948/7443?u=${licenseUrl}&sharedid=button${tier}`}
      target="blank"
    >
      <Button
        color="primary"
        textStyle="action.regular"
        variant="flat"
        width="100%"
      >
        <FormattedMessage
          id="PartnerLicenseTiers.labelSubscribeToTier2"
          defaultMessage="Change to AppSumo Tier {number}"
          values={{ number: tier }}
        />
      </Button>
    </a>
  );
}

/**
 * @see https://docs.google.com/spreadsheets/d/1u9CMrTTHWdNiBX4Rn0DNPjZ37VgMV9ZYffQEpjPCDXU/edit#gid=839576259
 */
function usePartnerLicenseTiersInfo(
  tierSlug: PlanResource['attributes']['slug'],
  licenseUrl: PartnerLicenseResource['attributes']['manageLicenseUrl'],
) {
  const intl = useIntl();
  return [
    {
      action: ({
        appsumo_tier_1: <CurrentTierMessage />,
        appsumo_tier_2: <ChangeToButton tier={1} licenseUrl={licenseUrl} />,
        appsumo_tier_3: <ChangeToButton tier={1} licenseUrl={licenseUrl} />,
      } as { [key: string]: ReactElementLike })[tierSlug],
      label: 'AppSumo Tier 1',
      features: [
        intl.formatMessage(messages.messageMembers, { numberOfMembers: 3 }),
        intl.formatMessage(messages.messageGuests, {
          numberOfGuests: 6,
        }),
        intl.formatMessage(messages.messageStorage, {
          sizeWithUnit: '250 GB',
        }),
        intl.formatMessage(messages.messageBandwidth, {
          bandwidthWithUnit: '150 GB',
        }),
        intl.formatMessage(messages.messageVideoMinutesVolume, {
          numberOfMinutes: '300',
        }),
        intl.formatMessage(messages.messageTeamManagement),
      ],
    },
    {
      action: ({
        appsumo_tier_1: <ChangeToButton tier={2} licenseUrl={licenseUrl} />,
        appsumo_tier_2: <CurrentTierMessage />,
        appsumo_tier_3: <ChangeToButton tier={2} licenseUrl={licenseUrl} />,
      } as { [key: string]: ReactElementLike })[tierSlug],
      label: 'AppSumo Tier 2',
      features: [
        intl.formatMessage(messages.messageMembers, { numberOfMembers: 10 }),
        intl.formatMessage(messages.messageGuests, {
          numberOfGuests: 20,
        }),
        intl.formatMessage(messages.messageStorage, {
          sizeWithUnit: '1 TB',
        }),
        intl.formatMessage(messages.messageBandwidth, {
          bandwidthWithUnit: '600 GB',
        }),
        intl.formatMessage(messages.messageVideoMinutesVolume, {
          numberOfMinutes: '1500',
        }),
        intl.formatMessage(messages.messageTeamManagement),
      ],
    },
    {
      action: ({
        appsumo_tier_1: <ChangeToButton tier={3} licenseUrl={licenseUrl} />,
        appsumo_tier_2: <ChangeToButton tier={3} licenseUrl={licenseUrl} />,
        appsumo_tier_3: <CurrentTierMessage />,
      } as { [key: string]: ReactElementLike })[tierSlug],
      label: 'AppSumo Tier 3',
      features: [
        intl.formatMessage(messages.messageMembers, { numberOfMembers: 25 }),
        intl.formatMessage(messages.messageGuests, {
          numberOfGuests: 'Unlimited',
        }),
        intl.formatMessage(messages.messageStorage, {
          sizeWithUnit: '3 TB',
        }),
        intl.formatMessage(messages.messageBandwidth, {
          bandwidthWithUnit: '1.5 TB',
        }),
        intl.formatMessage(messages.messageVideoMinutesVolume, {
          numberOfMinutes: '3000',
        }),
        intl.formatMessage(messages.messageTeamManagement),
      ],
    },
  ];
}

export default function PartnerLicenseTiers({
  currentLicenseSlug,
  licenseUrl,
}: {
  currentLicenseSlug: PlanResource['attributes']['slug'];
  licenseUrl: string;
}) {
  const partnerLicenseTiersInfo = usePartnerLicenseTiersInfo(
    currentLicenseSlug,
    licenseUrl,
  );

  return (
    <PlansGrid>
      {partnerLicenseTiersInfo.map((partnerLicenseTierInfo, index) => (
        <PlanInfo index={index + 1} {...partnerLicenseTierInfo} />
      ))}
    </PlansGrid>
  );
}
