import styled from 'styled-components/macro';
import { Icon } from '@picter/prisma';

const BoxIcon = styled(Icon).attrs({
  glyphSize: 'xsmall',
  size: 'small',
})`
  cursor: pointer;
  position: absolute;
  right: ${props => props.theme.space[4]}px;
  top: ${props => props.theme.space[4]}px;
`;

export default BoxIcon;
