import React, { useMemo } from 'react';
import { List } from 'immutable';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { useProjects, useCurrentUser } from 'src/hooks/use-resource';
import withSuspenseLoader from 'src/hocs/with-suspense-loader';
import { PROJECT_RELATION_FILTER } from 'src/constants';
import { locationShape } from 'src/utils/app-prop-types';
import ProjectCardGrid, { ProjectCard } from 'src/components/ProjectCardGrid';
import { get } from 'src/utils/accessors';

import EmptySharedWithMeBox from './EmptySharedWithMeBox';
import ProjectsSectionPlaceholder from './ProjectsSectionPlaceholder';

function getReverseCreationDate(project) {
  return -get(project, 'attributes.createdAt');
}

const ProjectsSection = ({
  location: {
    query: { roles },
  },
}) => {
  const projects = useProjects(
    {
      include: [
        'coverImage',
        'owner',
        'invitations',
        'users.user',
        'publicLinks',
        'space',
        'follows',
      ],
      includeOnSelector: ['images'],
      sortBy: getReverseCreationDate,
    },
    {
      // when there is just one role it comes as a string
      // and when there is more it comes as an array
      scope: Array.isArray(roles) ? roles : [roles],
      querySerializers: {
        scope: scope =>
          scope.map(role => `filter[scope][all][]=${role}`).join('&'),
      },
      page: { size: 200 },
      revalidate: true,
    },
  );

  const user = useCurrentUser({ include: ['spaces'] });

  // TODO: This page was used to display projects shared with the user, projects
  // owned by the user and projects in the user's spaces. Space projects are displayed
  // on the space page now, owned projects are legacy and should be removed. So we should
  // only display projects shared with the user here and remove the other two cases.
  const { userProjects, sharedProjects } = useMemo(
    () =>
      projects.reduce(
        (acc, next) => {
          const projectSpaceId = get(next, 'relationships.space.id');
          const userSpaces = get(user, 'relationships.spaces');

          if (
            projectSpaceId &&
            userSpaces.find(s => get(s, 'id') === projectSpaceId) !== undefined
          ) {
            acc.spaceProjects = acc.spaceProjects.push(next);
          } else if (get(next, 'relationships.owner.id') === get(user, 'id')) {
            acc.userProjects = acc.userProjects.push(next);
          } else {
            acc.sharedProjects = acc.sharedProjects.push(next);
          }
          return acc;
        },
        {
          userProjects: List(),
          sharedProjects: List(),
          spaceProjects: List(),
        },
      ),
    [projects, user],
  );

  const filteredProjects = roles.includes(PROJECT_RELATION_FILTER.OWNER)
    ? userProjects
    : sharedProjects;

  if (filteredProjects.size === 0) {
    return <EmptySharedWithMeBox />;
  }

  return (
    <>
      <ProjectCardGrid>
        {filteredProjects.map((project, index) => (
          <ProjectCard
            key={get(project, 'id')}
            project={project}
            index={index}
          />
        ))}
      </ProjectCardGrid>
    </>
  );
};

ProjectsSection.propTypes = {
  location: locationShape.isRequired,
};

export default compose(
  withRouter,
  withSuspenseLoader({ LoadingComponent: ProjectsSectionPlaceholder }),
)(ProjectsSection);
