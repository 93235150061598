import React from 'react';
import PropTypes from 'prop-types';

import SocketProvider from './SocketProvider';
import JSONApiProvider from './JSONApiProvider';

export default function RealtimeProvider({ children }) {
  return (
    <SocketProvider>
      <JSONApiProvider>{children}</JSONApiProvider>
    </SocketProvider>
  );
}

RealtimeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
