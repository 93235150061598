import React, { useCallback } from 'react';
import { ButtonElement as Button } from '@picter/prisma';
import { Flex, Box } from '@rebass/grid';
import { FormattedMessage } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';

import { viewModeTypes } from 'src/routes/constants';
import { libraryProjectUrl } from 'src/routes/urls';

import messages from '../messages';

const DoneWithUploadButton = () => {
  const { projectId } = useParams();
  const history = useHistory();

  const handleClick = useCallback(() => {
    history.replace(
      libraryProjectUrl({
        viewMode: viewModeTypes.REQUEST_FILES,
        projectId,
      }),
    );
  }, [history, projectId]);

  return (
    <Flex style={{ width: '100%' }} justifyContent="center">
      <Box mb={3} mt={3}>
        <Button onClick={handleClick} color="white" borderRadius="100px">
          <FormattedMessage {...messages.labelUploadMore} />
        </Button>
      </Box>
    </Flex>
  );
};

export default DoneWithUploadButton;
