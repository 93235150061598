import { defineMessages } from 'react-intl';

export default defineMessages({
  // InfoSection
  labelTitle: {
    id: 'InfoSection.labelTitle',
    defaultMessage: 'Title',
  },
  labelDescription: {
    id: 'InfoSection.labelDescription',
    defaultMessage: 'Description',
  },
});
