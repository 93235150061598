import React from 'react';
import styled, { css } from 'styled-components/macro';
import { transparentize } from 'polished';

import { Body, Box, Heading } from 'src/modules/prisma';

const Container = styled(Box).attrs({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  padding: 5,
  width: 1,
})`
  height: 100%;
  ${({ dashed, filled }) =>
    (dashed || filled) &&
    css`
      background-color: ${({ theme }) =>
        transparentize(0.98, theme.colors.primary)};
    `}
  ${({ dashed }) =>
    dashed &&
    css`
      border: dashed 1px
        ${({ theme }) => transparentize(0.8, theme.colors.primary)};
    `}
`;

type Props = {
  graphic?: React.ReactNode;
  title?: React.ReactNode;
  message?: React.ReactNode;
  dashed?: boolean;
  filled?: boolean;
  action?: React.ReactNode;
  actionHint?: React.ReactNode;
};

function EmptyStateBox({
  graphic,
  title,
  message,
  dashed = false,
  filled = false,
  action,
  actionHint,
}: Props) {
  return (
    <Container data-testid="empty-state-box" dashed={dashed} filled={filled}>
      {graphic && (
        <>
          {graphic}
          <br />
          <br />
        </>
      )}
      {title && (
        <Heading mb={2} fontWeight="light">
          {title}
        </Heading>
      )}
      {message && (
        <Body color="grey.600" mb={6} textAlign="center">
          {message}
        </Body>
      )}
      {action && action}
      {actionHint && (
        <Body
          mb={5}
          color="grey.600"
          textAlign="center"
          textSize="xsmall"
          fontWeight="light"
        >
          {actionHint}
        </Body>
      )}
    </Container>
  );
}

export default EmptyStateBox;
