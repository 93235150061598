import React from 'react';
import { FormattedMessage } from 'react-intl';
import { compose, withProps } from 'recompose';
import { Map } from 'immutable';

import { libraryProjectsUrl } from 'src/routes/urls';
import branchMediaQuery from 'src/hocs/branch-media-query';
import Navigation from 'src/components/Navigation';
import { withSidebarControl } from 'src/components/SidebarLayout';
import { PROJECT_RELATION_FILTER } from 'src/constants';
import { useCurrentUser } from 'src/hooks/use-resource';
import { get } from 'src/utils/accessors';

import SpacesNavigation from './SpacesNavigation';
import messages from '../_messages';

const MainNavigation = compose(
  withSidebarControl,
  branchMediaQuery(
    { maxWidth: 0 },
    withProps(({ sidebarControl }) => ({
      onNavigate: sidebarControl.closeMainSidebar,
    })),
  ),
)(({ onNavigate }) => {
  const user = useCurrentUser(
    {
      include: ['productFlags', 'spaces'],
    },
    {
      revalidate: preRequestUser =>
        get(preRequestUser, 'relationships.productFlags', Map()).isEmpty() ||
        get(preRequestUser, 'relationships.spaces', Map()).isEmpty(),
    },
  );
  const spaces = get(user, 'relationships.spaces');
  const productFlags = get(user, 'relationships.productFlags');

  return (
    <Navigation density="compact" onNavigate={onNavigate} type="multiple">
      <SpacesNavigation spaces={spaces} />
      <Navigation.List>
        {!!get(productFlags, 'attributes.isSingleWsUser') && (
          <Navigation.Item
            icon="home"
            label={<FormattedMessage {...messages.labelPersonal} />}
            to={libraryProjectsUrl(undefined, {
              roles: [PROJECT_RELATION_FILTER.OWNER],
            })}
            textStyle="body"
          />
        )}
        {!!get(productFlags, 'attributes.isWsGuest') && (
          <Navigation.Item
            icon="public"
            label={<FormattedMessage {...messages.labelPublic} />}
            to={libraryProjectsUrl(undefined, {
              roles: [
                PROJECT_RELATION_FILTER.EDITOR,
                PROJECT_RELATION_FILTER.VIEWER,
                PROJECT_RELATION_FILTER.MEMBER,
              ],
            })}
            textStyle="body"
          />
        )}
      </Navigation.List>
    </Navigation>
  );
});

export default MainNavigation;
