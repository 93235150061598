import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import paths from 'src/routes/paths';
import Step1Page from './1';
import Step2Page from './2';
import Step3Page from './3';

const {
  LIBRARY_SPACES_NEW_1_PATH,
  LIBRARY_SPACES_NEW_2_PATH,
  LIBRARY_SPACES_NEW_3_PATH,
} = paths;

function SpacesNewRoutes() {
  return (
    <Switch>
      <Route path={LIBRARY_SPACES_NEW_1_PATH} component={Step1Page} />
      <Route path={LIBRARY_SPACES_NEW_2_PATH} component={Step2Page} />
      <Route path={LIBRARY_SPACES_NEW_3_PATH} component={Step3Page} />
      <Redirect to={LIBRARY_SPACES_NEW_1_PATH} />
    </Switch>
  );
}

export default SpacesNewRoutes;
