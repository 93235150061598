import React from 'react';
import PropTypes from 'prop-types';
import { ImageCardPlaceholder } from 'src/containers/ImageCard';

const ImageGridPlaceholder = ({
  headerHeight,
  gridHeader,
  columnWidth,
  paddingLeft,
  paddingTop,
}) => {
  return (
    <div>
      {gridHeader}
      <div
        style={{
          width: columnWidth,
          paddingLeft,
          paddingTop: paddingTop + headerHeight,
        }}
      >
        <ImageCardPlaceholder />
      </div>
    </div>
  );
};

ImageGridPlaceholder.propTypes = {
  columnWidth: PropTypes.number.isRequired,
  paddingLeft: PropTypes.number.isRequired,
  paddingTop: PropTypes.number.isRequired,
  headerHeight: PropTypes.number,
  gridHeader: PropTypes.node,
};

ImageGridPlaceholder.defaultProps = {
  headerHeight: 0,
  gridHeader: null,
};

export default ImageGridPlaceholder;
