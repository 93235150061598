import styled, { css } from 'styled-components/macro';
import { darken } from 'polished';
import {
  variants,
  bodyTextStyle,
  captionTextStyle,
  captionSmallTextStyle,
} from '@picter/prisma';

const sizeProp = variants('size', {
  normal: css`
    height: 40px;
    small {
      ${captionSmallTextStyle};
    }
  `,
  large: css`
    height: 48px;
    small {
      ${captionTextStyle};
    }
  `,
  auto: css`
    align-items: flex-start;
    height: auto;
    padding-top: ${props => props.theme.space[4]}px;
    padding-bottom: ${props => props.theme.space[4]}px;
    width: 100%;
    > div {
      padding-top: 2px;
    }
    small {
      ${captionSmallTextStyle};
    }
  `,
});

const DropdownItemButton = styled.button`
  ${props => bodyTextStyle(props, { lineHeight: 1 })};
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${props => props.theme.colors.text};
  cursor: pointer;
  display: inline-flex;
  margin-top: ${props => props.footer && '28px'};
  padding: 0 ${props => props.theme.space[5]}px;
  text-align: left;
  i {
    color: ${props => props.theme.colors.gray['600']};
    margin-right: ${props => props.theme.space[4]}px;
  }
  > div {
    overflow: hidden;
  }
  small {
    color: ${props => props.theme.colors.gray['600']};
  }

  ${sizeProp};
  &:hover {
    background-color: ${props => props.theme.colors.gray['100']};
    &,
    small,
    i {
      color: ${props => props.theme.colors.black};
    }
  }
  &:disabled,
  &.disabled {
    cursor: not-allowed;
    &,
    small,
    i {
      color: ${props => props.theme.colors.gray['300']};
    }
  }
  &.active {
    background-color: ${props => props.theme.colors.gray['100']};
    box-shadow: inset 5px 0 0 -1px ${props => props.theme.colors.primary};
    &,
    i {
      color: ${props => props.theme.colors.primary};
    }
    small {
      color: ${props => props.theme.colors.gray['600']};
    }
    &:hover {
      background-color: ${props => props.theme.colors.gray['200']};
      box-shadow: inset 5px 0 0 -1px ${props => darken(0.15, props.theme.colors.primary)};
      &,
      i {
        color: ${props => darken(0.15, props.theme.colors.primary)};
      }
    }
  }
`;

export default DropdownItemButton;
