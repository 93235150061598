import styled from 'styled-components/macro';

const DropdownButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.noMargin && 'margin: 0;'}
`;

export default DropdownButtonWrapper;
