import styled from 'styled-components/macro';
import { darken, lighten } from 'polished';
import themeGet from '@styled-system/theme-get';
import { transition } from '@picter/prisma';

import { common, textStyle, typography, layout, position } from './mixins';
import Element from './Element';

function themeGetColor(props, propName = 'color') {
  return themeGet(`colors.${props[propName]}`)(props);
}

const Anchor = styled(Element)`
  ${common};
  ${layout};
  ${position};
  ${textStyle};
  ${typography};
  cursor: pointer;
  transition: ${transition({ property: 'color' })};

  &:hover {
    color: ${props => lighten(0.15, themeGetColor(props))};
    text-decoration: underline;
  }

  &:active {
    color: ${props => darken(0.15, themeGetColor(props))};
  }
`;

// order matters for overriding certain props (e.g. font weight)
Anchor.defaultProps = {
  blacklist: [
    ...Element.defaultProps.blacklist,
    ...common.propNames,
    ...layout.propNames,
    ...position.propNames,
    ...typography.propNames,
    ...textStyle.propNames,
  ],
  color: 'primary',
  fontFamily: 'sansSerif',
  tag: 'a',
  textStyle: 'body.regular',
  fontWeight: 'light',
  textDecoration: 'none',
};

Anchor.displayName = 'Anchor';

export default Anchor;
