import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { CustomPropTypes } from '@picter/prisma';

import BackItem from './NavigationBackItem';
import List from '../styles/NavigationList';
import Nav from '../styles/NavigationNav';
import { NavigationControlProvider } from '../utils/navigation-control';

function Navigation({
  id,
  className,
  children,
  backTo,
  density,
  onNavigate,
  type,
}) {
  const controls = useMemo(() => ({ onNavigate }), [onNavigate]);
  const child = {
    single: <List>{children}</List>,
    multiple: children,
  }[type];

  return (
    <NavigationControlProvider value={controls}>
      <Nav id={id} className={className} density={density}>
        {backTo && backTo}
        {child}
      </Nav>
    </NavigationControlProvider>
  );
}

Navigation.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  backTo: CustomPropTypes.elementOf(BackItem),
  density: PropTypes.oneOf(['comfortable', 'compact', 'default']),
  onNavigate: PropTypes.func,
  type: PropTypes.string,
};

Navigation.defaultProps = {
  id: null,
  className: null,
  backTo: null,
  density: 'default',
  onNavigate: null,
  type: 'single',
};

export default Navigation;
