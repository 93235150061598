import React from 'react';

export default function MaintenanceIllustrationSvg() {
  return (
    <svg
      width="207"
      height="207"
      viewBox="0 0 207 207"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M103.5 28C61.5264 28 27.5 62.0264 27.5 104L103.5 104L103.5 28Z"
        fill="#FB5C5C"
      />
      <path
        d="M111.851 104C111.851 108.971 107.822 113 102.851 113C97.8806 113 34.5 104 34.5 104C34.5 104 97.8806 95 102.851 95C107.822 95 111.851 99.0294 111.851 104Z"
        fill="#5C62CA"
      />
      <rect
        x="8.77119"
        y="8.77119"
        width="189.458"
        height="189.458"
        rx="94.7288"
        stroke="#5C62CA"
        strokeWidth="17.5424"
        strokeDasharray="2.61 52.63"
      />
    </svg>
  );
}
