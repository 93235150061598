import React, { createFactory } from 'react';
import { MediaQuery } from '@picter/prisma';
import { setDisplayName, wrapDisplayName } from 'recompose';

const identity = Component => Component;

const branchMediaQuery = (
  query,
  left,
  right = identity,
) => WrappedComponent => {
  let leftFactory;
  let rightFactory;

  const BranchMediaQuery = props => (
    <MediaQuery query={query}>
      {matches => {
        if (matches) {
          leftFactory = leftFactory || createFactory(left(WrappedComponent));
          return leftFactory(props);
        }
        rightFactory = rightFactory || createFactory(right(WrappedComponent));
        return rightFactory(props);
      }}
    </MediaQuery>
  );

  return setDisplayName(wrapDisplayName(WrappedComponent, 'branchMediaQuery'))(
    BranchMediaQuery,
  );
};

export default branchMediaQuery;
