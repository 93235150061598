import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { Button, H1, P, MediaQuery } from '@picter/prisma';

import { checkMobileReadyUrl } from 'src/utils/mobile-ready';

import MobileWarningContainer from './styles/MobileWarningContainer';
import messages from './messages';

class MobileWarning extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showWarning: true,
    };
  }

  closeWarning = () =>
    this.setState({
      showWarning: false,
    });

  render() {
    const {
      location: { pathname },
    } = this.props;
    const { showWarning } = this.state;

    if (showWarning || checkMobileReadyUrl(pathname)) {
      return null;
    }

    return (
      <MediaQuery
        query={{ maxWidth: 1 }}
        render={() => (
          <MobileWarningContainer>
            <H1 center>
              <FormattedMessage {...messages.messageHeadline} />
            </H1>
            <P>
              <FormattedMessage {...messages.messageParagraph} />
            </P>
            <Button onClick={this.closeWarning}>
              <FormattedMessage {...messages.labelProceed} />
            </Button>
          </MobileWarningContainer>
        )}
      />
    );
  }
}

MobileWarning.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(MobileWarning);
