import useFormatMessage from 'src/hooks/use-format-message';

import {
  libraryProjectUrl,
  libraryProjectImageUrl,
  libraryProjectCommentsUrl,
  libraryProjectImageCommentsUrl,
  libraryProjectCommentUrl,
  libraryProjectImageCommentUrl,
} from 'src/routes/urls';

import { Result } from './types';
import messages from './messages';

type FormatMessage = ReturnType<typeof useFormatMessage>;

function parseFolderTitle(
  title: string | undefined,
  formatMessage: FormatMessage,
) {
  return title ?? formatMessage(messages.labelUntitledFolder);
}

function parseProjectTitle(
  title: string | undefined,
  formatMessage: FormatMessage,
) {
  return title ?? formatMessage(messages.labelUntitledProject);
}

function parseFileTitle(
  title: string | undefined,
  formatMessage: FormatMessage,
) {
  return title ?? formatMessage(messages.labelUntitledFile);
}

function parseFilesCounter(counter: string, formatMessage: FormatMessage) {
  return formatMessage(messages.labelFilesCount, { counter });
}

// eslint-disable-next-line import/prefer-default-export
export function extractResultProps(
  result: Result,
  formatMessage: FormatMessage,
) {
  switch (result.type) {
    case 'project': {
      const folderTitle =
        result.folderId && parseFolderTitle(result.parentTitle, formatMessage);
      const projectTitle = parseProjectTitle(result.title, formatMessage);
      const filesCounter = parseFilesCounter(result.files, formatMessage);
      const titles = [folderTitle, projectTitle].filter(i => i).join('/');
      const infoArr = ['Project', filesCounter, titles].filter(i => i);
      return {
        id: result.id,
        key: result.id,
        title: projectTitle,
        body: result.body,
        image: result.image,
        info: infoArr.join(' • '),
        url: libraryProjectUrl({
          projectId: result.publicUrlKey,
        }),
        createdAt: result.createdAt,
        updatedAt: result.updatedAt,
        sharing: result.sharing,
      };
    }
    case 'file': {
      const projectTitle = parseProjectTitle(result.parentTitle, formatMessage);
      const fileTitle = parseFileTitle(result.title, formatMessage);
      const infoArr = ['File', projectTitle].filter(i => i);
      return {
        id: result.id,
        key: result.id,
        title: fileTitle,
        body: result.body,
        image: result.image,
        info: infoArr.join(' • '),
        url: libraryProjectImageUrl({
          projectId: result.publicUrlKey,
          imageId: result.id.replace('file_', ''),
        }),
        createdAt: result.createdAt,
        updatedAt: result.updatedAt,
        sharing: result.sharing,
      };
    }
    case 'comment': {
      const projectTitle = parseProjectTitle(result.parentTitle, formatMessage);
      const fileTitle =
        result.projectImageId && parseFileTitle(result.title, formatMessage);
      const titles = [projectTitle, fileTitle].filter(i => i).join('/');
      const infoArr = ['Comment', titles].filter(i => i);

      let url;

      if (result.parentCommentId) {
        url = result.projectImageId
          ? libraryProjectImageCommentUrl({
              projectId: result.publicUrlKey,
              imageId: result.projectImageId,
              commentId: result.parentCommentId,
            })
          : libraryProjectCommentUrl({
              projectId: result.publicUrlKey,
              commentId: result.parentCommentId,
            });
      } else {
        url = result.projectImageId
          ? libraryProjectImageCommentsUrl({
              projectId: result.publicUrlKey,
              imageId: result.projectImageId,
            })
          : libraryProjectCommentsUrl({
              projectId: result.publicUrlKey,
            });
      }

      return {
        id: result.id,
        key: result.id,
        title: result.body,
        fadedTitle: true,
        image: result.image,
        info: infoArr.join(' • '),
        url,
        createdAt: result.createdAt,
        updatedAt: result.updatedAt,
        sharing: result.sharing,
      };
    }
    default:
      return null;
  }
}
