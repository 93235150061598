import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonElement as Button } from '@picter/prisma';
import { Add as AddSvg } from '@styled-icons/material';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Text, Icon } from 'src/modules/prisma';
import Tooltip from 'src/components/MessageTooltip';
import { librarySpacesNewUrl } from 'src/routes/urls';

import messages from './messages';

export default function NewWorkspaceButton({ showTooltip }) {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  useLayoutEffect(() => {
    if (showTooltip) {
      setTimeout(() => setTooltipVisible(true), 1000);
    }
  }, [showTooltip, setTooltipVisible]);

  if (showTooltip && !tooltipVisible) {
    return null;
  }

  return (
    <Tooltip
      message={<FormattedHTMLMessage {...messages.messageCreateWorkspace} />}
      visible={tooltipVisible}
    >
      <Button
        variant="outlined"
        color="grey.600"
        bg="grey.200"
        width={1}
        py={3}
        textAlign="left"
      >
        <Link to={librarySpacesNewUrl()}>
          <Icon type={<AddSvg />} color="grey.600" />
          <Text textStyle="action.regular" tag="span" ml={2} color="grey.600">
            <FormattedMessage {...messages.labelNewWorkspace} />
          </Text>
        </Link>
      </Button>
    </Tooltip>
  );
}

NewWorkspaceButton.propTypes = {
  showTooltip: PropTypes.bool,
};

NewWorkspaceButton.defaultProps = {
  showTooltip: false,
};
