import React, { cloneElement, useRef, memo } from 'react';
import { Flex } from '@rebass/grid';

import AccountMenu from 'src/components/AccountMenu';
import { Box } from 'src/modules/prisma';
import { FixedBox } from 'src/styles/PositionBox';
import { FormattedMessage } from 'react-intl';
import IntercomLauncher from 'src/components/IntercomLauncher';
import Notifications from 'src/components/Notifications';
import SidebarLayout, {
  MAIN_SIDEBAR_SIZE,
  useLayoutInfo as useSidebarLayoutInfo,
} from 'src/layouts/SidebarLayout';
import useRouter from 'src/hooks/use-router';
import { viewModeTypes } from 'src/routes/constants';
import CurrentUserAvatar from 'src/containers/CurrentUserAvatar';
import MessageTooltip from 'src/components/MessageTooltip';

import messages from './messages';

function ProjectPageSidebarActions() {
  const { mainSidebarOpened } = useSidebarLayoutInfo();
  const {
    match: { params },
  } = useRouter();
  const isDefaultMode = params.viewMode === viewModeTypes.DEFAULT;
  const accountTooltipRef = useRef();
  const notificationsTooltipRef = useRef();
  const intercomTooltipRef = useRef();

  return (
    <FixedBox bottom={0} left={0} zIndex="sidebar">
      <Flex p={5} pr={0} width={MAIN_SIDEBAR_SIZE + 32 /* Toggle icon size */}>
        {isDefaultMode && (
          <>
            <Box>
              <AccountMenu
                toggleElement={({ onClick }) => (
                  <MessageTooltip
                    message={<FormattedMessage {...messages.labelMyAccount} />}
                    ref={accountTooltipRef}
                  >
                    <Box display="inline-flex" tag="span">
                      <CurrentUserAvatar
                        onClick={event => {
                          onClick(event);
                          accountTooltipRef.current.hide();
                        }}
                        size="large"
                      />
                    </Box>
                  </MessageTooltip>
                )}
              />
            </Box>
            <Box gml={2}>
              <Notifications
                toggleElement={
                  <MessageTooltip
                    message={
                      <FormattedMessage {...messages.labelNotifications} />
                    }
                    ref={notificationsTooltipRef}
                  >
                    {cloneElement(Notifications.defaultProps.toggleElement, {
                      onClick: () => notificationsTooltipRef.current.hide(),
                    })}
                  </MessageTooltip>
                }
              />
            </Box>
            <Box gml={2}>
              <MessageTooltip
                message={<FormattedMessage {...messages.labelSupport} />}
                onClick={() => intercomTooltipRef.current.hide()}
                ref={intercomTooltipRef}
              >
                <div>
                  <IntercomLauncher />
                </div>
              </MessageTooltip>
            </Box>
          </>
        )}
        <Box
          gml={mainSidebarOpened ? 'auto' : 2}
          ml={mainSidebarOpened ? 'auto' : undefined}
        >
          <MessageTooltip
            message={
              mainSidebarOpened ? (
                <FormattedMessage {...messages.labelHideCollections} />
              ) : (
                <FormattedMessage {...messages.labelShowCollections} />
              )
            }
          >
            {(state, htmlProps) => (
              <SidebarLayout.ToggleIcon
                {...htmlProps}
                onClick={event => htmlProps.onBlur(event)}
              />
            )}
          </MessageTooltip>
        </Box>
      </Flex>
    </FixedBox>
  );
}

export default memo(ProjectPageSidebarActions);
