import React from 'react';
import PropTypes from 'prop-types';
import { A, Dropdown, Icon } from '@picter/prisma';
import { Box } from '@rebass/grid';
import { FormattedMessage } from 'react-intl';
import { TrackerEvent } from '@picter/tracker';

import RolesDropdownButton from './RolesDropdownButton';
import messages from '../messages';

const RolesDropdown = ({
  currentScope,
  title,
  labelRemove,
  buttonBackground,

  // click handlers
  onClickOwner,
  onClickManager,
  messageManager,
  onClickMember,
  labelMember,
  messageMember,
  onClickEditor,
  onClickViewer,
  labelViewer,
  messageViewer,
  onClickDisableLink,
  onClickRemove,
  disabled,

  // links
  linkToLearnMore,
}) => (
  <Dropdown
    button={
      <RolesDropdownButton
        disabled={disabled}
        currentScope={currentScope}
        background={buttonBackground}
      />
    }
  >
    {({ closeMenu }) => (
      <Dropdown.Menu width="320px" closeMenu={closeMenu} wrapPortal>
        <Dropdown.Header enableClose>{title}</Dropdown.Header>

        {onClickOwner && (
          <FormattedMessage {...messages.labelOwner}>
            {labelOwner => (
              <TrackerEvent
                name="Change role"
                properties={{
                  role: 'owner',
                }}
                trackClick
              >
                <Dropdown.Item
                  active={currentScope === 'owner'}
                  icon={<Icon type="vpn_key" size="small" />}
                  label={labelOwner}
                  hint={
                    <FormattedMessage {...messages.messageFullAccessRole} />
                  }
                  onClick={() => {
                    onClickOwner();
                    closeMenu();
                  }}
                  size="auto"
                />
              </TrackerEvent>
            )}
          </FormattedMessage>
        )}

        {onClickManager && (
          <FormattedMessage {...messages.labelManager}>
            {labelManager => (
              <TrackerEvent
                name="Change role"
                properties={{
                  role: 'manager',
                }}
                trackClick
              >
                <Dropdown.Item
                  active={currentScope === 'manager'}
                  icon={<Icon type="record_voice_over" size="small" />}
                  label={labelManager}
                  hint={
                    messageManager || (
                      <FormattedMessage {...messages.messageFullAccessRole} />
                    )
                  }
                  onClick={() => {
                    onClickManager();
                    closeMenu();
                  }}
                  size="auto"
                />
              </TrackerEvent>
            )}
          </FormattedMessage>
        )}

        {onClickMember && (
          <FormattedMessage {...(labelMember || messages.labelMember)}>
            {labelMemberText => (
              <TrackerEvent
                name="Change role"
                properties={{
                  role: 'member',
                }}
                trackClick
              >
                <Dropdown.Item
                  active={currentScope === 'member'}
                  icon={<Icon type="group" size="small" />}
                  label={labelMemberText}
                  hint={
                    messageMember || (
                      <FormattedMessage {...messages.messageMemberRole} />
                    )
                  }
                  onClick={() => {
                    onClickMember();
                    closeMenu();
                  }}
                  size="auto"
                />
              </TrackerEvent>
            )}
          </FormattedMessage>
        )}

        {onClickEditor && (
          <FormattedMessage {...(labelMember || messages.labelEditor)}>
            {labelEditor => (
              <TrackerEvent
                name="Change role"
                properties={{
                  role: 'editor',
                }}
                trackClick
              >
                <Dropdown.Item
                  active={currentScope === 'editor'}
                  icon={<Icon type="group" size="small" />}
                  label={labelEditor}
                  hint={<FormattedMessage {...messages.messageMemberRole} />}
                  onClick={() => {
                    onClickEditor();
                    closeMenu();
                  }}
                  size="auto"
                />
              </TrackerEvent>
            )}
          </FormattedMessage>
        )}

        {onClickViewer && (
          <FormattedMessage {...(labelViewer || messages.labelViewer)}>
            {labelViewerText => (
              <TrackerEvent
                name="Change role"
                properties={{
                  role: 'viewer',
                }}
                trackClick
              >
                <Dropdown.Item
                  active={currentScope === 'viewer'}
                  icon={<Icon type="remove_red_eye" size="small" />}
                  label={labelViewerText}
                  hint={
                    messageViewer || (
                      <FormattedMessage {...messages.messageViewerRole} />
                    )
                  }
                  onClick={() => {
                    onClickViewer();
                    closeMenu();
                  }}
                  size="auto"
                />
              </TrackerEvent>
            )}
          </FormattedMessage>
        )}

        {(onClickDisableLink || onClickRemove || linkToLearnMore) && (
          <Dropdown.Separator />
        )}

        {onClickDisableLink && (
          <TrackerEvent name="Disable sharing" trackClick>
            <Dropdown.Item
              icon={<Icon type="power_settings_new" size="small" />}
              label={<FormattedMessage {...messages.labelDisableLinkAccess} />}
              onClick={() => {
                onClickDisableLink();
                closeMenu();
              }}
            />
          </TrackerEvent>
        )}

        {onClickRemove && (
          <Dropdown.Item
            icon={<Icon type="delete" size="small" />}
            label={labelRemove}
            onClick={() => {
              onClickRemove();
              closeMenu();
            }}
          />
        )}

        {linkToLearnMore && (
          <Box px={5}>
            <A href={linkToLearnMore} target="_blank">
              <FormattedMessage {...messages.labelLearnMoreAboutRoles} />
            </A>
          </Box>
        )}
      </Dropdown.Menu>
    )}
  </Dropdown>
);

RolesDropdown.propTypes = {
  currentScope: PropTypes.oneOf([
    'owner',
    'manager',
    'editor',
    'member',
    'viewer',
  ]),
  title: PropTypes.node.isRequired,
  labelRemove: PropTypes.node.isRequired,
  buttonBackground: PropTypes.bool,
  onClickOwner: PropTypes.func,
  onClickManager: PropTypes.func,
  messageManager: PropTypes.node,
  onClickMember: PropTypes.func,
  labelMember: PropTypes.objectOf({
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
  }),
  messageMember: PropTypes.node,
  onClickEditor: PropTypes.func,
  onClickViewer: PropTypes.func,
  labelViewer: PropTypes.objectOf({
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
  }),
  messageViewer: PropTypes.node,
  onClickDisableLink: PropTypes.func,
  onClickRemove: PropTypes.func,
  linkToLearnMore: PropTypes.string,
  disabled: PropTypes.bool,
};

RolesDropdown.defaultProps = {
  currentScope: null,
  buttonBackground: undefined,
  onClickOwner: null,
  onClickManager: null,
  messageManager: null,
  onClickMember: null,
  labelMember: null,
  messageMember: null,
  onClickEditor: null,
  onClickViewer: null,
  labelViewer: null,
  messageViewer: null,
  onClickDisableLink: null,
  onClickRemove: null,
  linkToLearnMore: null,
  disabled: false,
};

export default RolesDropdown;
