import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import paths from 'src/routes/paths';
import { loginUrl } from 'src/routes/urls';
import { useAuthenticationConsumer } from 'src/utils/cerberus/AuthenticationProvider';
import { useToriiActions } from 'src/modules/torii';
import { useCurrentUser } from 'src/hooks/use-resource';
import { $TSFixMe } from 'src/types';
import { Button } from 'src/modules/prisma';
import { EmptyStatePage } from 'src/components/EmptyStateBox';
import NoPermissionIllustrationSvg from 'src/assets/NoPermissionIllustrationSvg';

import messages from './messages';

const { HOME_PATH } = paths as $TSFixMe;

function NoPermissionPage() {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { endpointHost, logout } = useAuthenticationConsumer() as $TSFixMe;
  const { resetStore } = useToriiActions() as $TSFixMe;
  const user = useCurrentUser();

  const redirectUrl = [pathname, search].join('');
  const loginLink = loginUrl(undefined, { redirectUrl });

  const logoutAndRedirect = useCallback(() => {
    if (user?.isEmpty()) {
      history.push(loginLink);
    } else {
      logout({
        endpointHost,
        onSuccess() {
          history.push(loginLink);
          resetStore();
        },
      });
    }
  }, [user, history, endpointHost, logout, resetStore, loginLink]);

  return (
    <EmptyStatePage
      graphic={<NoPermissionIllustrationSvg />}
      title={<FormattedMessage {...messages.messageYouNeedPermission} />}
      message={
        <FormattedMessage
          {...messages.messageWantIn}
          values={{
            a: (chunk: React.ReactNode) => (
              <Button
                onClick={logoutAndRedirect}
                color="primary"
                backgroundColor="transparent"
                display="inline-block"
                py={0}
                px={0}
              >
                {chunk}
              </Button>
            ),
          }}
        />
      }
      onClickBack={() => {
        history.push(HOME_PATH);
      }}
      onClickHelp={() => {
        window.Intercom?.('show');
      }}
    />
  );
}

export default NoPermissionPage;
