import React from 'react';
import { FormattedMessage } from 'react-intl';

import InfoSvg from 'src/assets/InfoSvg';

import ActionsDropdownItem from './ActionsDropdownItem';
import messages from '../messages';

function ActionsDropdownInfo(props) {
  return (
    <ActionsDropdownItem
      action="info"
      icon={<InfoSvg />}
      label={<FormattedMessage {...messages.labelInfo} />}
      {...props}
    />
  );
}

export default ActionsDropdownInfo;
