import { defineMessages } from 'react-intl';

export default defineMessages({
  titleMove: {
    id: 'titleMove',
    defaultMessage: 'Move {title}',
  },
  titleWorkspace: {
    id: 'titleWorkspace',
    defaultMessage: 'Workspace',
  },
  titleNewFolder: {
    id: 'titleNewFolder',
    defaultMessage: 'New folder',
  },
  messageCreateNewFolder: {
    id: 'messageCreateNewFolder',
    defaultMessage: 'Create new folder',
  },
  messageProjectsCount: {
    id: 'messageProjectsCount',
    defaultMessage: '{count} projects',
  },
  labelMove: {
    id: 'labelMove',
    defaultMessage: 'Move',
  },
  labelCancel: {
    id: 'labelCancel',
    defaultMessage: 'Cancel',
  },
});
