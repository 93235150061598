// @ts-nocheck
import React, { useEffect, useRef, useCallback } from 'react';
import {
  VolumeDown as VolumeDownSvg,
  VolumeMute as VolumeMuteSvg,
  VolumeOff as VolumeOffSvg,
  VolumeUp as VolumeUpSvg,
} from '@styled-icons/material';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import useTinyKeys from 'src/hooks/use-tiny-keys';
import { Action, Box, Icon, Tooltip } from 'src/modules/prisma';

import RangeProgress, { RangeProgressProps } from './RangeProgress';

const VolumeContainer = styled.div`
  transform-origin: left;
  transform: rotate(-90deg) translateY(15px);
`;

const Container = styled.div`
  position: relative;

  ${VolumeContainer} {
    position: absolute;
    bottom: 0;
    transition: visibility 0ms linear 60ms, width 100ms ease-in-out 0ms;
    visibility: hidden;
    width: 0px;
  }

  &:hover ${VolumeContainer} {
    transition: visibility 0ms linear 2ms, width 50ms ease-in-out 0ms;
    visibility: visible;
    width: 144px;
  }
`;

export type VolumeControlProps = {
  muted: boolean;
  volume: number;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement> | KeyboardEvent,
    newVolume: number,
  ) => void;
  onToggle?: (
    event: React.MouseEvent<HTMLButtonElement> | KeyboardEvent,
  ) => void;
};

export default function VolumeControl({
  muted,
  volume,
  onChange,
  onToggle,
}: VolumeControlProps) {
  const currentVolume = useRef(volume);
  const seekStep = 0.1;
  const max = 1.0;

  const decreaseVolume = useCallback(
    (event: KeyboardEvent) => {
      const newVolume = currentVolume.current - seekStep;
      const endVolume = Math.max(newVolume, 0);

      onChange?.(event, endVolume);
    },
    [onChange],
  );

  const increaseVolume = useCallback(
    (event: KeyboardEvent) => {
      const newVolume = currentVolume.current + seekStep;
      const endVolume = Math.min(newVolume, max);

      onChange?.(event, endVolume);
    },
    [onChange],
  );

  useEffect(() => {
    currentVolume.current = volume;
  }, [volume]);

  useTinyKeys(
    window,
    {
      KeyM: (event: KeyboardEvent) => {
        onToggle?.(event);
      },
      ArrowUp: (event: KeyboardEvent) => {
        increaseVolume(event);
      },
      ArrowDown: (event: KeyboardEvent) => {
        decreaseVolume(event);
      },
    },
    [],
  );

  return (
    <Container>
      <Tooltip placement="right" showDelay={500}>
        <Tooltip.Reference>
          <Action onClick={onToggle} px={1}>
            <VolumeIcon volume={volume} muted={muted} />
          </Action>
        </Tooltip.Reference>
        <Tooltip.Message>
          {muted ? (
            <FormattedMessage
              id="VolumeControl.labelUnmute"
              defaultMessage="Unmute (M)"
            />
          ) : (
            <FormattedMessage
              id="VolumeControl.labelMute"
              defaultMessage="Mute (M)"
            />
          )}
        </Tooltip.Message>
      </Tooltip>
      <VolumeContainer>
        <Box ml={5}>
          <Box
            alignItems="center"
            display="flex"
            height="28px"
            px={2}
            py={1}
            width="100%"
          >
            <VolumeRangeProgress
              volume={volume}
              onChange={event =>
                onChange?.(event, parseFloat(event.target.value))
              }
            />
          </Box>
        </Box>
      </VolumeContainer>
    </Container>
  );
}

type VolumeRangeProgressProps = Pick<RangeProgressProps, 'onChange'> & {
  volume: RangeProgressProps['value'];
};

function VolumeRangeProgress({
  volume,
  onChange,
  ...props
}: VolumeRangeProgressProps) {
  return (
    <RangeProgress
      {...props}
      backgroundColor="grey.300"
      color="black"
      max={1.0}
      onMouseDown={(event: React.MouseEvent) => {
        event.stopPropagation();
      }}
      onMouseUp={(event: React.MouseEvent) => {
        event.stopPropagation();
      }}
      onChange={onChange}
      step={0.01}
      value={volume}
    />
  );
}

type VolumeIconProps = {
  muted: boolean;
  volume: number;
};

function VolumeIcon({ muted, volume }: VolumeIconProps) {
  if (muted) return <Icon type={<VolumeOffSvg />} />;

  switch (true) {
    case volume > 0.6:
      return <Icon type={<VolumeUpSvg />} />;
    case volume <= 0.6 && volume > 0.2:
      return <Icon type={<VolumeDownSvg />} />;
    case volume <= 0.2:
      return <Icon type={<VolumeMuteSvg />} />;
    default:
      return <Icon type={<VolumeUpSvg />} />;
  }
}
