import React from 'react';
import { FormattedMessage } from 'react-intl';

import globalMessages from 'src/messages';
import { MAX_IMAGE_FILE_SIZE } from 'src/constants';

// TODO: UNUSED FILE
const validateImageUpload = file => {
  const errors = [];
  if (file.size > MAX_IMAGE_FILE_SIZE) {
    errors.push(<FormattedMessage {...globalMessages.messageFileTooBig} />);
  }

  return errors;
};

export default validateImageUpload;
