import React, { useRef, memo } from 'react';
import { withRouter } from 'react-router-dom';
import { branch, compose, withHandlers, withProps, withState } from 'recompose';
import { injectIntl } from 'react-intl';

import branchMediaQuery from 'src/hocs/branch-media-query';
import { useToriiActions } from 'src/modules/torii';
import {
  imageInfoUrl,
  libraryProjectImageDownloadUrl,
  imageCommentsUrl,
} from 'src/routes/urls';
import { useProjectImage } from 'src/hooks/use-resource';
import { replacePublicLinkKey } from 'src/utils/inject-public-link-key';
import usePermission from 'src/hooks/use-permission';
import withFlexibleDiv from 'src/hocs/with-flexible-div';
import {
  withSelectableProperties,
  withSelectableLayout,
} from 'src/modules/SelectableComponents';
import {
  getProjectFileThumbnailUrl,
  getProjectImageProcessState,
} from 'src/utils/accessors/project-image';
import { get } from 'src/utils/accessors';

import ImageCard from './components/ImageCard';

const imageProps = ({ image }) => {
  return {
    approved: get(image, 'attributes.approved'),
    title: get(image, 'attributes.title'),
    src:
      get(image, 'attributes.files.small.location') ||
      getProjectFileThumbnailUrl(image),
    isVideo: get(image, 'attributes.isVideo'),
    isNonVisual: get(image, 'attributes.isNonVisual'),
    fileMimeType: get(image, 'attributes.mimeType'),
    processing: getProjectImageProcessState(image),
  };
};

const generalProps = ({ match }) => {
  return {
    publicKey: match.params.projectId,
  };
};

const handlers = {
  handleClickDownload: ({ image, match }) => () => {
    const url = replacePublicLinkKey(
      libraryProjectImageDownloadUrl({
        imageId: get(image, 'id'),
        downloadSize: 'original',
        projectPublicKey: get(
          image,
          'relationships.project.attributes.publicUrlKey',
        ),
      }),
      match,
    );
    window.open(url, '_self');
  },
  handleClickComments: ({
    image,
    history,
    location: {
      query: { filter },
    },
    match: {
      params: { projectId: projectPublicUrlKey, collectionId },
    },
  }) => () =>
    history.push(
      imageCommentsUrl(
        {
          imageId: get(image, 'id'),
          projectId: projectPublicUrlKey,
          collectionId,
        },
        { filter },
      ),
    ),
  handleClickInfo: ({
    image,
    history,
    match: {
      params: { collectionId, projectId },
    },
    filter,
  }) => () =>
    history.push(
      imageInfoUrl(
        {
          imageId: get(image, 'id'),
          collectionId,
          projectId,
        },
        { filter },
      ),
    ),
  handleClickLink: ({ image, history, location, link }) => () => {
    const { pathname } = location;
    // Pushing the state with the image id to the history first and once its done its
    // pushing the correct one to redirect to the image page. the listen is needed because
    // if I just push both they run in parallel and its kinda buggy.
    // history.push does not return a promise or anything so we can wait.
    const unlisten = history.listen(() => {
      unlisten();
      history.push(link);
    });
    history.push(pathname, { gridPositionImageId: get(image, 'id') });
  },
};

const withMemoTorii = WrappedComponent => properties => {
  // TODO: This HOC its just a temporary solution. In the future we are going to migrate this component
  // to hooks and this wont be needed anymore
  const { id } = properties;

  const image = useProjectImage(
    {
      id,
    },
    { request: false },
  );

  const { create, destroy, update, localUpdate } = useToriiActions();

  const editableTitleRef = useRef();

  return (
    <WrappedComponent
      {...properties}
      image={image}
      create={create}
      destroy={destroy}
      update={update}
      localUpdate={localUpdate}
      editableTitleRef={editableTitleRef}
    />
  );
};

export { default as ImageCardPlaceholder } from './components/ImageCardPlaceholder';

export default compose(
  memo,
  injectIntl,
  withRouter,
  withMemoTorii,
  // firefox does not fire any onDragOver events, so we have to
  // set a default value for it (always drop image on the right side)
  withState('hoverSide', 'setHoverSide', 'right'),
  withProps(imageProps),
  withProps(generalProps),
  withHandlers(handlers),
  branchMediaQuery(
    { minWidth: 0 },
    branch(
      /**
       * In case the user has select permission render selectable functionality
       * otherwise just a div that receives margin and width (because of image grid)
       */
      () => {
        const { canSelect } = usePermission();
        return canSelect;
      },
      compose(withSelectableProperties, withSelectableLayout()),
      withFlexibleDiv,
    ),
    withFlexibleDiv,
  ),
)(ImageCard);
