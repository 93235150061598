import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import api from '../actions';

const useToriiActions = () => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      Object.entries(api).reduce(
        (curr, [actionName /* e.g. update */, actionFunction]) => ({
          ...curr,
          [actionName]: (...params) => dispatch(actionFunction(...params)),
        }),
        {},
      ),
    [dispatch],
  );

  return actions;
};

export default useToriiActions;
