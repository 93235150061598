import { createSelector } from 'reselect';
import { List, Map } from 'immutable';
import { select } from 'src/modules/torii';

import { get } from 'src/utils/accessors';
import createImmutableSelector from 'src/utils/create-immutable-selector';
import {
  ImageProofFilter,
  isImageProofFilter,
} from 'src/utils/accessors/project-image';

import { filterSelector } from '.';

export const imageSelectorById = createSelector(
  (state, { id }) =>
    select(state, 'wsProjectImages', {
      id,
    }),
  image => image,
);

export const projectImagesSelector = createImmutableSelector(
  state => get(state.project, 'relationships.images', List()),
  images => images.filter(image => image),
);

export const projectImageSelectorById = createSelector(
  projectImagesSelector,
  (_, { id }) => id,
  (images, id) => images.find(image => get(image, 'id') === id),
);

const sortImages = images =>
  images.sortBy(image => get(image, 'attributes.order'));

export const sortedProjectImagesSelector = createSelector(
  projectImagesSelector,
  sortImages,
);

export const makeFilteredProjectImageSelector = () =>
  createSelector(
    sortedProjectImagesSelector,
    filterSelector,
    (images, filter) => images.filter(isImageProofFilter(filter)),
  );

const makeSelectCollectionImages = () =>
  createSelector(
    collection => get(collection, 'relationships.images', List()),
    collectionImages =>
      collectionImages.filter(image =>
        Map.isMap(get(image, 'relationships.image')),
      ),
  );

export const makeCollectionImagesSelector = () => {
  const selectCollectionImages = makeSelectCollectionImages();

  return createSelector(({ collection }) => collection, selectCollectionImages);
};

export const makeOrderedCollectionImagesSelector = () => {
  const collectionImagesSelector = makeCollectionImagesSelector();
  return createSelector(collectionImagesSelector, sortImages);
};

export const projectImagesSizeByFilterSelector = createSelector(
  ({ images }) => images,
  images => {
    const rejectedImagesCounter = images.filter(
      isImageProofFilter({ global: ImageProofFilter.REJECTED }),
    ).size;
    const undecidedImagesCounter = images.filter(
      isImageProofFilter({ global: ImageProofFilter.UNDECIDED }),
    ).size;

    return {
      rejectedImagesCounter,
      undecidedImagesCounter,
    };
  },
);
