import React from 'react';

import useBulkSelection from '../hooks/use-bulk-selection';

/**
 * TODO: Use the memo component after fixing the bulk proof indicator
 *
 * https://app.asana.com/0/1162225950173970/1162410756333132
 */
const withBulkSelection = WrappedComponent => {
  // const MemoWrappedComponent = memo(WrappedComponent);
  return props => {
    const actions = useBulkSelection();

    return <WrappedComponent {...props} {...actions} />;
  };
};

export default withBulkSelection;
