import styled, { keyframes } from 'styled-components/macro';

import FormSavedIndicator from './FormSavedIndicator';

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const FormAutoSaveIndicator = styled.span`
  position: absolute;
  width: 72px;

  ${FormSavedIndicator} {
    animation: ${fadeOut} 500ms ease-in 1s forwards;
  }
`;

export default FormAutoSaveIndicator;
