import { defineMessages } from 'react-intl';

export default defineMessages({
  messageNumberOfImages: {
    id: 'ProjectCardGrid.messageNumberOfImages',
    defaultMessage:
      '{numberOfImages, plural, =0 {No files} one {1 file} other {{numberOfImages} files}}',
  },
  labelRename: {
    id: 'ProjectCardGrid.ActionsDropdown.labelRename',
    defaultMessage: 'Rename',
  },
  labelDuplicate: {
    id: 'ProjectCardGrid.ActionsDropdown.labelDuplicate',
    defaultMessage: 'Duplicate',
  },
  labelRemove: {
    id: 'ProjectCardGrid.ActionsDropdown.labelRemove',
    defaultMessage: 'Delete',
  },
  messageModalDelete: {
    id: 'ProjectCardGrid.ActionsDropdown.messageModalDelete',
    defaultMessage:
      'This will permanently delete the project including all its files. This action is irreversible.',
  },
  messageProjectRenamedSuccess: {
    id: 'ProjectCardGrid.ActionsDropdown.messageProjectRenamedSuccess',
    defaultMessage: 'Project title changed successfully.',
  },
  messageProjectRemovedSuccess: {
    id: 'ProjectCardGrid.ActionsDropdown.messageProjectRemovedSuccess',
    defaultMessage: 'Project removed successfully.',
  },
  messageProjectDuplicateSuccess: {
    id: 'ProjectCardGrid.ActionsDropdown.messageProjectDuplicateSuccess',
    defaultMessage: 'Project duplicated successfully.',
  },
  labelMoveToSpace: {
    id: 'ProjectCardGrid.ActionsDropdown.labelMoveToSpace',
    defaultMessage: 'Move to {name}',
  },
  messageProjectMovedToSpace: {
    id: 'ProjectCardGrid.ActionsDropdown.messageProjectMovedToSpace',
    defaultMessage: 'Project moved to {spaceName}',
  },
  labelFollowProject: {
    id: 'ProjectCardGrid.ActionsDropdown.labelFollowProject',
    defaultMessage: 'Follow',
  },
  labelUnfollowProject: {
    id: 'ProjectCardGrid.ActionsDropdown.labelUnfollowProject',
    defaultMessage: 'Unfollow',
  },
  messageProjectMoveToTrashSuccess: {
    id: 'ProjectCardGrid.ActionsDropdown.messageProjectMoveToTrashSuccess',
    defaultMessage: 'Project moved to trash.',
  },
  messageProjectRestoredSuccess: {
    id: 'ProjectCardGrid.ActionsDropdown.messageProjectRestoredSuccess',
    defaultMessage: 'Project restored from trash.',
  },
});
