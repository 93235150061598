import styled from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';

const CommentImage = styled.img`
  border-radius: ${themeGet('radii.medium')};
  height: 32px;
  object-fit: cover;
  width: 32px;
`;

export default CommentImage;
