import { createContext, useContext } from 'react';

const ErrorHandlerContext = createContext({
  handleError: f => f,
});

export const ErrorHandlerProvider = ErrorHandlerContext.Provider;

export function useErrorHandler() {
  const context = useContext(ErrorHandlerContext);
  if (context === undefined) {
    throw new Error(
      '`useErrorHandler` must be used within a `ErrorHandlerProvider`.',
    );
  }
  return context;
}
