import styled from 'styled-components/macro';
import { mediaQuery } from '@picter/prisma';

import SidebarPanel from './SidebarPanel';
import { MAIN_SIDEBAR_SIZE } from '../constants';

const MainSidebarPanel = styled(SidebarPanel)`
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: auto;
  position: absolute;
  right: 100%;
  width: 100%;

  @media screen and ${mediaQuery.greaterThan('mobile')} {
    flex-basis: ${MAIN_SIDEBAR_SIZE}px;
    max-width: ${MAIN_SIDEBAR_SIZE}px;
    position: relative;
    right: unset;
    width: unset;
  }

  @media screen and ${mediaQuery.greaterThan('desktopHd')} {
    flex-basis: ${MAIN_SIDEBAR_SIZE}px;
    max-width: ${MAIN_SIDEBAR_SIZE}px;
  }
`;

export default MainSidebarPanel;
