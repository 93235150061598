import React, { Suspense } from 'react';
import SuspenseLoaderProvider from './SuspenseLoaderProvider';

const createWithSuspenseLoader = (components = {}) => (
  overrideComponents = {},
) => WrappedComponent => {
  const { LoadingComponent, ErrorComponent } = {
    ...components,
    ...overrideComponents,
  };

  return props => (
    <SuspenseLoaderProvider
      errorFallback={ErrorComponent && <ErrorComponent {...props} />}
    >
      <Suspense fallback={LoadingComponent && <LoadingComponent {...props} />}>
        <WrappedComponent {...props} />
      </Suspense>
    </SuspenseLoaderProvider>
  );
};

export default createWithSuspenseLoader;
