import { Optional } from 'utility-types';

import { Range } from 'src/types';

export function repeat(word: string, times = 1, delimiter = ''): string {
  return Array.from(Array(times), () => word).join(delimiter);
}

export function scaleNumberToRange(
  num: number,
  from: Range<number>,
  to: Range<number>,
): number {
  const { max: fromMax, min: fromMin } = from;
  const { max: toMax, min: toMin } = to;

  if (num < fromMin || num > fromMax) {
    throw new RangeError(
      `Number (${num}) is out of range (${fromMin}..${fromMax}).`,
    );
  }

  return Math.round(
    ((toMax - toMin) * (num - fromMin)) / (fromMax - fromMin) + toMin,
  );
}

/**
 * Based on this explanation of how to scale down numbers
 * https://stackoverflow.com/questions/5294955/how-to-scale-down-a-range-of-numbers-with-a-known-min-and-max-value/5295202
 */
export function hashToRange(
  hash: string,
  { max, min = 0 }: Optional<Range<number>, 'min'>,
): number {
  const numberOfDigits = String(max).length;
  const lastHashDigits = hash.slice(-numberOfDigits);
  const hexInDecimalNumber = Number(`0x${lastHashDigits}`);
  const maxHexValue = Number(`0x${repeat('f', numberOfDigits)}`);
  const minHexValue = 0;

  return scaleNumberToRange(
    hexInDecimalNumber,
    {
      // from
      max: maxHexValue,
      min: minHexValue,
    },
    {
      // to
      max,
      min,
    },
  );
}
