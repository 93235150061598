import React, { forwardRef, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Tooltip } from 'src/modules/prisma';
import CommentsSvg from 'src/assets/CommentsSvg';

import ContentIcon from '../styles/ContentIcon';
import ContentIconGroup from '../styles/ContentIconGroup';
import messages from '../messages';

const CommentsIcon = forwardRef(
  ({ active, boxSize, counter, onClick, size, ...props }, ref) => {
    return (
      <ContentIconGroup onClick={onClick} role="button" ref={ref} {...props}>
        <ContentIcon
          backgroundColor="info"
          backgroundOpacity="0.05"
          borderColor="primary"
          boxSize={boxSize}
          className={classNames('content-icon content-icon--active', {
            'content-icon--visible': active,
          })}
          color="primary"
          content={counter}
          size={size}
          type={<CommentsSvg />}
        />
        {active ? (
          <>
            <ContentIcon
              backgroundColor="info"
              backgroundOpacity="0.05"
              borderColor="info"
              boxSize={boxSize}
              className="content-icon content-icon--hover"
              color="black"
              content={counter}
              size={size}
              type={<CommentsSvg />}
            />
            <ContentIcon
              backgroundColor="info"
              backgroundOpacity="0.05"
              borderColor="info"
              boxSize={boxSize}
              className="content-icon content-icon--default"
              color="info"
              content={counter}
              size={size}
              type={<CommentsSvg />}
            />
          </>
        ) : (
          <>
            <ContentIcon
              borderColor="grey.400"
              boxSize={boxSize}
              className="content-icon content-icon--hover"
              color="black"
              content={counter}
              size={size}
              type={<CommentsSvg />}
            />
            <ContentIcon
              borderColor="grey.300"
              boxSize={boxSize}
              className="content-icon content-icon--default"
              color="grey.600"
              content={counter}
              size={size}
              type={<CommentsSvg />}
            />
          </>
        )}
      </ContentIconGroup>
    );
  },
);

CommentsIcon.defaultProps = {
  active: false,
  boxSize: 'large',
  counter: undefined,
  onClick: undefined,
  size: 'small',
};

CommentsIcon.propTypes = {
  active: PropTypes.bool,
  boxSize: PropTypes.string,
  counter: PropTypes.number,
  onClick: PropTypes.func,
  size: PropTypes.string,
};

export default CommentsIcon;

export function CommentsIconTooltip({ children, portal, visible }) {
  const tooltipRef = useRef();

  useMemo(() => {
    if (!visible) tooltipRef.current.hide();
  }, [visible]);

  return (
    <Tooltip placement="bottom" ref={tooltipRef}>
      <Tooltip.Reference>{children}</Tooltip.Reference>
      <Tooltip.Message unstable_portal={portal}>
        <FormattedMessage {...messages.labelComments} />
      </Tooltip.Message>
    </Tooltip>
  );
}

CommentsIconTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  portal: PropTypes.bool,
  visible: PropTypes.bool,
};

CommentsIconTooltip.defaultProps = {
  portal: true,
  visible: true,
};
