import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { useSpace } from 'src/hooks/use-resource';
import { useToriiActions } from 'src/modules/torii';
import { get } from 'src/utils/accessors';
import EntityChooser from 'src/components/EntityChooser';
import {
  FolderOpen as FolderOpenSvg,
  FolderShared as FolderSharedSvg,
} from '@styled-icons/material';

import ItemComponent from './components/ItemComponent';
import CreateItemComponent from './components/CreateItemComponent';

import messages from './messages';

function FolderChooser({ open, onClose, title, spaceId, onSubmit }) {
  const { create } = useToriiActions();
  const space = useSpace(
    { id: spaceId, include: ['projects.folder', 'folders.count'] },
    { request: false },
  );

  const folders = get(space, 'relationships.folders');

  const [name, setName] = useState(undefined);

  const handleCreateFolder = useCallback(async () => {
    const result = await create('wsFolders', {
      attributes: { name },
      relationships: {
        space: {
          id: spaceId,
          type: 'ws-spaces',
        },
      },
    });
    return result.data;
  }, [spaceId, create, name]);

  const extraOptions = useMemo(
    () => [
      {
        component: <ItemComponent icon={<FolderSharedSvg />} item={space} />,
        value: 'workspace',
      },
    ],
    [space],
  );

  if (!spaceId) {
    return null;
  }

  return (
    <EntityChooser
      open={open}
      onClose={onClose}
      title={<FormattedMessage {...messages.titleMove} values={{ title }} />}
      items={folders}
      ItemComponent={<ItemComponent icon={<FolderOpenSvg />} />}
      CreateItemComponent={
        <CreateItemComponent name={name} setName={setName} />
      }
      createNewItem={handleCreateFolder}
      SubmitLabel={<FormattedMessage {...messages.labelMove} />}
      onSubmit={onSubmit}
      extraOptions={extraOptions}
    />
  );
}

FolderChooser.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  spaceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

FolderChooser.defaultProps = {
  open: false,
  onClose: undefined,
  title: undefined,
};

export default FolderChooser;
