import React from 'react';
import PropTypes from 'prop-types';

import { COLOR_SCHEME_OPTIONS } from './constants';
import Wrapper from '../styles/BoxWrapper';

const Box = ({ children, colorScheme, ...props }) => (
  <Wrapper colorScheme={colorScheme} {...props}>
    {children}
  </Wrapper>
);

Box.propTypes = {
  children: PropTypes.node,
  colorScheme: PropTypes.oneOf(COLOR_SCHEME_OPTIONS),
};

Box.defaultProps = {
  children: null,
  colorScheme: 'requirements',
};

export default Box;
