import React, {
  useMemo,
  useRef,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import { flatten, groupBy } from 'lodash';
import AutoSizer from 'react-virtualized-auto-sizer';
import camelCase from 'lodash/camelCase';
import { FormattedMessage } from 'react-intl';
import { ButtonElement as Button } from '@picter/prisma';

import { Box, Heading, Body, Grid, Spacer, Anchor } from 'src/modules/prisma';
import { fetcher, useSWRInfinite } from 'src/modules/swr';
import { CommentResource } from 'src/types/resources';
import { formatForLocalAction, useToriiActions } from 'src/modules/torii';
import { $TSFixMe } from 'src/types';
import NoPermissionPage from 'src/components/NoPermissionPage';
import NotFoundPage from 'src/components/NotFoundPage';
import useNavigate from 'src/hooks/use-navigate';
import { librarySpaceTaskUrl, librarySpaceTasksUrl } from 'src/routes/urls';

import NoTasksFoundSvg from '../_assets/NoTasksFoundSvg';
import InfiniteDynamicSizeList from './InfiniteDynamicSizeList';
import TaskRenderer from './TaskRenderer';
import { SortByOptions } from './SortByDropdown';
import {
  ResolvedFilterOptions,
  RESOLVED_FILTER_QUERY_KEY,
} from './ResolvedFilterDropdown';
import { ASSIGNEE_FILTER_QUERY_KEY } from './AssigneeFilterDropdown';
import { RevalidateTasksProvider } from '../_context';

const { REACT_APP_API_URL } = process.env;
const DEFAULT_ACTIVITY_SIZE = 0;

function literalSnakedSortToOperatorCamelSort(
  sortBy: string /* e.g. created_at.desc */,
) {
  const [field, order] = sortBy.split('.');
  const operatorOrder = ({
    asc: '',
    desc: '-',
  } as { [key: string]: '' | '-' })[order];
  return `${operatorOrder}${camelCase(field)}`; /* -createdAt */
}

/**
 * 1. Load paginated data
 * 2. Store paginated data in torii
 * 3. Store order of item-ids in pagination provider
 * 4. Pass these ids to list items
 * 5. Inside list-items: use torii to select denormalized item by id (with all included relationships)
 */
function getUrl({
  assigneeId,
  spaceId,
  taskId,
  resolved,
  sortBy,
}: {
  assigneeId?: string;
  spaceId: string;
  taskId?: string;
  resolved?: ResolvedFilterOptions;
  sortBy?: SortByOptions;
}) {
  const url = new URL(
    `/app-workspace/ws-spaces/${spaceId}/comments`,
    REACT_APP_API_URL,
  );
  url.searchParams.append('assignee', assigneeId ?? 'true');
  url.searchParams.append('trash', 'false');
  if (taskId !== undefined) url.searchParams.append('comment', taskId);
  if (resolved !== undefined)
    url.searchParams.append('resolved', String(resolved));
  if (sortBy !== undefined)
    url.searchParams.append(
      'sort',
      literalSnakedSortToOperatorCamelSort(sortBy),
    );

  url.searchParams.append(
    'include',
    [
      'assignee',
      'author',
      'parent',
      'project.coverImage',
      'replies.author',
      'replies.parent',
      'resolvedBy',
      // TODO: Move includes below inside TaskPage to reduce unnecessary data loading
      'collection',
      'image.approvals.user',
      'image.collectionImages.collection',
      'image.rejectedByUser',
      'project.collections',
    ].join(','),
  );

  /**
   * @see https://swr.vercel.app/docs/pagination
   */
  return function getUrlPaginated(
    pageIndex: number,
    previousPageData: $TSFixMe,
  ) {
    if (previousPageData && !previousPageData.length) return null; // reached the end

    url.searchParams.set(
      'page[number]',
      String(pageIndex /* starts at 0 */ + 1),
    );
    url.searchParams.set('page[size]', String(10));

    return url.toString();
  };
}

function useTasks({
  assigneeId,
  spaceId,
  taskId,
  resolved,
  sortBy,
}: {
  assigneeId?: string;
  spaceId: string;
  taskId?: string;
  resolved?: ResolvedFilterOptions;
  sortBy?: SortByOptions;
}) {
  const { localBulkCreate } = useToriiActions() as {
    localBulkCreate: $TSFixMe;
  };
  const metaPagination = useRef<{
    page: number;
    pageSize: number;
    totalPages: number;
    totalRows: number;
  }>({
    page: 0,
    pageSize: 0,
    /**
     * Assume some existent data to show loading indicator at first.
     * See `hasMoreItems` from `InfiniteDynamicSizeList`.
     */
    totalPages: 1,
    totalRows: 1,
  });
  const getUrlPaginated = useMemo(
    () => getUrl({ assigneeId, resolved, spaceId, sortBy, taskId }),
    [assigneeId, resolved, spaceId, sortBy, taskId],
  );

  const { data, ...swr } = useSWRInfinite<CommentResource['id'][]>(
    getUrlPaginated,
    (endpoint: string) =>
      fetcher(endpoint, {
        credentials: 'include',
      }).then(response => {
        const tasksIds = response.data.map((task: CommentResource) => task.id);

        // Save loaded resources into Redux store
        const resourcesGroupedByType = groupBy(
          formatForLocalAction(response),
          'type',
        );
        /**
         * Necessary resource order otherwise the app breaks due to unfulfilled
         * dependencies.
         */
        const resourceOrder = [
          'users',
          'wsProjects',
          'wsProjectImages',
          'wsApprovals',
          'wsCollections',
          'wsCollectionImages',
          'wsComments',
        ];
        resourceOrder.forEach(type => {
          const typeResources = resourcesGroupedByType[type];
          if (typeResources) localBulkCreate(type, typeResources);
        });

        metaPagination.current = response.meta.pagination;

        return tasksIds ?? [];
      }),
    {
      revalidateAll: true,
    },
  );

  return {
    ...swr,
    data: flatten(data),
    meta: metaPagination.current,
  };
}

export default function TasksList({
  assigneeFilter,
  commentSortBy,
  resolvedFilter,
  taskId,
  spaceId,
}: {
  assigneeFilter: string;
  commentSortBy: SortByOptions;
  resolvedFilter: ResolvedFilterOptions;
  taskId?: string;
  spaceId: string;
}) {
  const [loadAll, setLoadAll] = useState(!taskId);
  const {
    data: tasks,
    error,
    meta: { totalPages, page },
    revalidate,
    isValidating,
    setSize,
  } = useTasks({
    assigneeId: assigneeFilter,
    taskId: loadAll ? undefined : taskId,
    spaceId,
    resolved: resolvedFilter,
    sortBy: commentSortBy,
  });
  const loadMoreItems = useCallback(() => {
    if (!isValidating) return setSize(page + 1);
    return null;
  }, [isValidating, page, setSize]);

  const hasNoTasks = !isValidating && tasks.length === 0;

  const Item = useCallback(
    ({ style, ...props }) => (
      <>
        <TaskRenderer {...props} style={style} />
        {!loadAll && (
          <Box
            position="absolute"
            top={`calc(${style.height}px + 16px)`}
            left="calc(50% - 40px)"
          >
            <Button color="white" onClick={() => setLoadAll(true)}>
              <FormattedMessage
                id="TasksPage.labelLoadAll"
                defaultMessage="Load all"
              />
            </Button>
          </Box>
        )}
      </>
    ),
    [loadAll],
  );

  useEffect(() => {
    /**
     * In case there's no param taskId and the page has not yet loaded all tasks.
     * Set `loadAll` to true.
     */
    if (!taskId) {
      setLoadAll(hasLoadedAll => {
        if (!hasLoadedAll) return true;
        return hasLoadedAll;
      });
    }
  }, [taskId]);

  if (error?.status === 403) return <NoPermissionPage />;
  if (error?.status === 404) return <NotFoundPage />;

  return hasNoTasks ? (
    <EmptyTasksList taskId={taskId} spaceId={spaceId} />
  ) : (
    <RevalidateTasksProvider value={revalidate}>
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteDynamicSizeList
            defaultItemSize={DEFAULT_ACTIVITY_SIZE}
            hasMoreItems={page < totalPages}
            height={height}
            items={tasks}
            loadMoreItems={loadMoreItems}
            width={width}
          >
            {Item}
          </InfiniteDynamicSizeList>
        )}
      </AutoSizer>
    </RevalidateTasksProvider>
  );
}

function EmptyTasksList({
  taskId,
  spaceId,
}: {
  taskId?: string;
  spaceId: string;
}) {
  const navigate = useNavigate();
  const { query } = useLocation();
  const taskUrlBuilder = taskId ? librarySpaceTaskUrl : librarySpaceTasksUrl;
  const hasFilterApplied =
    Reflect.has(query, ASSIGNEE_FILTER_QUERY_KEY) ||
    Reflect.has(query, RESOLVED_FILTER_QUERY_KEY);

  return (
    <Grid display="grid" placeItems="center" height="100%">
      <Box alignItems="center" display="flex" flexDirection="column">
        <NoTasksFoundSvg />
        <Spacer sy={7} />
        <Heading fontWeight="light">
          <FormattedMessage
            id="TasksPage.titleNoTasksFound"
            defaultMessage="No task found"
          />
        </Heading>
        <Spacer sy={2} />
        <Body color="grey.600" textAlign="center">
          {hasFilterApplied && (
            <>
              <FormattedMessage
                id="TasksPage.messageNoTasksFoundFilter"
                defaultMessage="Try changing or <reset_link>resetting the filter.</reset_link>"
                values={{
                  reset_link: (...chunks: unknown[]) => (
                    <Anchor
                      onClick={() =>
                        navigate(taskUrlBuilder({ spaceId, taskId }))
                      }
                    >
                      {chunks}
                    </Anchor>
                  ),
                }}
              />
              <br />
            </>
          )}
          <FormattedMessage
            id="TasksPage.messageNoTasksFoundAssignedComments"
            defaultMessage="Assigned comments appear as tasks in this list."
          />
        </Body>
      </Box>
    </Grid>
  );
}
