import { api, makeMemoSelect } from 'src/modules/torii';
import { connect } from 'react-redux';
import { compose, withHandlers, withProps } from 'recompose';

import { get } from 'src/utils/accessors';

import AcceptTermsModal from './components/AcceptTermsModal';

const memoSelect = makeMemoSelect();

export const mapStateToProps = state => {
  const user = get(state, 'user');

  return {
    user:
      memoSelect(state, 'users', {
        id: get(user, 'id'),
      }) || user,
  };
};

export const mapDispatchToProps = {
  update: api.update,
};

export const componentHandlers = {
  handleAcceptTerms: ({ update, user }) => () =>
    update('users', {
      id: get(user, 'id'),
      attributes: {
        acceptTerms: true,
      },
    }),
};

export const mapPropsToProps = ({ user }) => {
  const isUserLoggedIn = !!get(user, 'id');
  const acceptedTermsAtTimestamp =
    get(user, 'acceptedTermsAt') || get(user, 'attributes.acceptedTermsAt');
  const newTermsReleasedTimestamp = new Date(2018, 8, 18).getTime();
  const currentDateTimestamp = Date.now();

  // calculate if it's released and accepted
  const newTermsReleased = currentDateTimestamp > newTermsReleasedTimestamp;
  const acceptedNewTerms =
    newTermsReleased && acceptedTermsAtTimestamp > newTermsReleasedTimestamp;

  return { isUserLoggedIn, acceptedNewTerms, newTermsReleased };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps(mapPropsToProps),
  withHandlers(componentHandlers),
)(AcceptTermsModal);
