import { defineMessages } from 'react-intl';

export default defineMessages({
  titleAppSwitcher: {
    id: 'AppSwitcher.titleAppSwitcher',
    defaultMessage: 'Switch to another app',
  },
  labelPicterWorkspace: {
    id: 'AppSwitcher.labelPicterWorkspace',
    defaultMessage: 'Picter Workspace',
  },
  messagePicterWorkspace: {
    id: 'AppSwitcher.labelPicterWorkspace',
    defaultMessage: 'Photo review has never been this easy.',
  },
  labelPicterContests: {
    id: 'AppSwitcher.labelPicterContests',
    defaultMessage: 'Picter Contests',
  },
  messagePicterContests: {
    id: 'AppSwitcher.labelPicterContests',
    defaultMessage: 'Browse current open calls hosted by Picter.',
  },
});
