import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose, withProps } from 'recompose';
import { TrackerScope, TrackerEvent } from '@picter/tracker';
import { Flex, Box } from '@rebass/grid';
import { Modal, toastInfo } from '@picter/prisma';

import { useLocation, useParams, useHistory } from 'react-router-dom';
import {
  NotInterested as NotInterestedSvg,
  RemoveCircleOutline as RemoveCircleOutlineSvg,
  CheckCircleOutline as CheckCircleOutlineSvg,
} from '@styled-icons/material';
import { Info as InfoSvg } from '@styled-icons/material-outlined';

import { getProjectPublicLinkKeyByViewMode } from 'src/utils/accessors/project';
import useModalStateHandlers from 'src/hooks/use-modal-state-handlers';
import branchMediaQuery from 'src/hocs/branch-media-query';
import {
  projectUrl,
  projectCommentsUrl,
  projectInfoUrl,
} from 'src/routes/urls';
import Navigation from 'src/components/Navigation';
import { ImageProofFilter } from 'src/utils/accessors/project-image';
import { withSidebarControl } from 'src/components/SidebarLayout';
import usePermission from 'src/hooks/use-permission';
import { sortedProjectCollectionsSelector } from 'src/selectors/collection';
import CreateCollectionModalForm from 'src/components/CollectionActionsDropdown/components/CreateCollectionModalForm';
import { useProject } from 'src/hooks/use-resource';
import { returnInCase } from 'src/utils/in-case';
import { Separator } from 'src/modules/prisma';
import { get } from 'src/utils/accessors';
import Avatar from 'src/components/Avatar';
import { useToriiActions } from 'src/modules/torii';
import { addImagesToCollection } from 'src/actions/collection';
import useFormatMessage from 'src/hooks/use-format-message';

import useApprovalFilters from './hooks/use-approval-filters';

import TrashInfoPanel from './components/TrashInfoPanel';
import FilterCheckbox from './components/FilterCheckbox';
import FiltersButton from './components/FiltersButton';
import CollectionNavigationItem from './components/CollectionNavigationItem';
import messages from './messages';

const { ANY, REJECTED, UNDECIDED } = ImageProofFilter;

export const PROJECT_NAVIGATION_ELEMENTS = {
  SHOW_ALL: 'show-all',
  PROOF: 'proof',
  COLLECTIONS: 'collections',
  INFO: 'info',
};

function ProjectNavigation({ onNavigate, projectId, elements }) {
  const formatMessage = useFormatMessage();
  const { bulkCreate } = useToriiActions();
  const { pathname, query } = useLocation();
  const history = useHistory();
  const { filter } = query;
  const { approvedBy: approvedByFilter = [], global: globalFilter } =
    filter || {};
  const parsedApprovedBy = Array.isArray(approvedByFilter)
    ? approvedByFilter
    : [approvedByFilter];
  const filtersCounter = globalFilter ? 1 : parsedApprovedBy.length;
  const params = useParams();
  const project = useProject(
    {
      id: projectId,
      include: [
        'collections.images.image.approvals.user',
        'collections.images.image.approvals.image',
        'images.approvals.user',
        'images.approvals.image',
        'publicLinks',
        'space',
        'folder',
      ],
    },
    { request: false },
  );
  const projectPublicLink = getProjectPublicLinkKeyByViewMode(
    project,
    params.viewMode,
  );

  const collections = sortedProjectCollectionsSelector({ project });

  const projectPublicKey = get(project, 'attributes.publicUrlKey');
  const {
    visibleConfirmCreateModal,
    closeConfirmCreateModal,
    openConfirmCreateModal,
  } = useModalStateHandlers('confirmCreate');

  const buildUrl = useMemo(
    () =>
      returnInCase({
        [pathname.includes('/comments')]: () => projectCommentsUrl,
        default: () => projectUrl,
      }),
    [pathname],
  );

  const { canManageCollections } = usePermission();

  const isProjectInTrash = get(project, 'attributes.deletedAt') !== null;
  const {
    imagesCounter,
    filtersImagesCounter,
    rejectedImagesCounter,
    undecidedImagesCounter,
    approvedImagesCounter,
    approvalsUsers,
  } = useApprovalFilters(project);

  const { showFilters } = history.location.state || {};

  const toggleShowFilters = useCallback(() => {
    return history.push({
      ...history.location,
      state: { showFilters: !showFilters },
    });
  }, [showFilters, history]);

  const addImagesToCollectionHandler = useCallback(
    async (collection, imagesIds) => {
      const collectionId = get(collection, 'id');
      const collectionName = get(collection, 'attributes.name');

      const parsedImagesIds = params.collectionId
        ? imagesIds.map(collectionImageId => {
            const [, imageId] = collectionImageId.split(':');
            return imageId;
          })
        : imagesIds;

      await addImagesToCollection(
        { imagesIds: parsedImagesIds, collectionId, projectPublicKey },
        { bulkCreate },
      );
      toastInfo(
        <FormattedMessage
          {...messages.messageImagesAddedToCollection}
          values={{
            collectionName,
            imagesSize: parsedImagesIds.length,
          }}
        />,
      );
    },
    [bulkCreate, params.collectionId, projectPublicKey],
  );

  return (
    <>
      <TrackerScope name="Nav">
        <Navigation density="compact" onNavigate={onNavigate}>
          {isProjectInTrash && <TrashInfoPanel project={project} />}
          {elements.includes(PROJECT_NAVIGATION_ELEMENTS.PROOF) && (
            <>
              <FiltersButton
                showFilters={showFilters}
                filtersCounter={filtersCounter}
                filtersImagesCounter={filtersImagesCounter}
                toggleShowFilters={toggleShowFilters}
              />
              {showFilters && (
                <>
                  <FilterCheckbox
                    icon={<CheckCircleOutlineSvg />}
                    label={formatMessage(messages.labelFilterAny)}
                    value={ANY}
                    counter={approvedImagesCounter}
                  />
                  <FilterCheckbox
                    icon={<NotInterestedSvg />}
                    label={formatMessage(messages.labelFilterWithoutFlags)}
                    value={UNDECIDED}
                    counter={undecidedImagesCounter}
                  />
                  <FilterCheckbox
                    icon={<RemoveCircleOutlineSvg />}
                    label={formatMessage(messages.labelFilterRejected)}
                    value={REJECTED}
                    counter={rejectedImagesCounter}
                  />
                  {approvalsUsers.map(({ user, counter }) => {
                    return (
                      <FilterCheckbox
                        avatar={
                          <Avatar
                            email={
                              get(user, 'attributes.liteAccountEmail') ||
                              get(user, 'attributes.email')
                            }
                            name={
                              get(user, 'attributes.liteAccountEmail') ||
                              get(user, 'attributes.publicName')
                            }
                            size="small"
                          />
                        }
                        label={formatMessage(messages.labelUserApprovalFilter, {
                          nameOrEmail:
                            get(user, 'attributes.liteAccountEmail') ||
                            get(user, 'attributes.publicName'),
                        })}
                        value={get(user, 'id')}
                        counter={counter}
                      />
                    );
                  })}
                </>
              )}
              <Box ml={7}>
                <Separator />
              </Box>
            </>
          )}
          {elements.includes(PROJECT_NAVIGATION_ELEMENTS.INFO) && (
            <Navigation.Item
              exact
              icon={<InfoSvg />}
              label={<FormattedMessage {...messages.labelInfo} />}
              state={history.location.state}
              to={projectInfoUrl(
                {
                  projectId: projectPublicLink,
                },
                {
                  ...query,
                  filter: undefined,
                },
              )}
              textStyle="body"
            />
          )}

          {elements.includes(PROJECT_NAVIGATION_ELEMENTS.SHOW_ALL) && (
            <Navigation.Item
              active={
                history.location.pathname ===
                buildUrl(
                  {
                    projectId: projectPublicLink,
                  },
                  {
                    ...query,
                    filter: undefined,
                  },
                )
              }
              state={history.location.state}
              icon="apps"
              label={
                <Flex
                  justifyContent="space-between"
                  data-testid="project-filters-all"
                >
                  <FormattedMessage {...messages.labelFilterAll} />
                  <span>{imagesCounter}</span>
                </Flex>
              }
              to={buildUrl(
                {
                  projectId: projectPublicLink,
                },
                {
                  ...query,
                  filter: undefined,
                },
              )}
              textStyle="body"
              data-intercom-target="all-files"
            />
          )}
          {elements.includes(PROJECT_NAVIGATION_ELEMENTS.COLLECTIONS) &&
            collections.map((collection, index) => (
              <CollectionNavigationItem
                collection={collection}
                projectPublicKey={projectPublicKey}
                projectId={projectPublicLink}
                index={index}
                addImagesToCollectionHandler={addImagesToCollectionHandler}
              />
            ))}
          {canManageCollections && (
            <TrackerEvent name="New collection" trackClick>
              <Navigation.Button
                icon="add"
                label={
                  <Flex justifyContent="space-between">
                    <FormattedMessage {...messages.labelNewCollection} />
                  </Flex>
                }
                onClick={openConfirmCreateModal}
                textStyle="body"
                data-intercom-target="new-collection-button"
              />
            </TrackerEvent>
          )}
        </Navigation>
      </TrackerScope>
      <Modal
        open={visibleConfirmCreateModal}
        onClickClose={closeConfirmCreateModal}
        title={<FormattedMessage {...messages.labelNewCollection} />}
      >
        <TrackerEvent
          name="Create collection modal"
          scope="Create collection modal"
          trackView
        >
          <CreateCollectionModalForm
            project={project}
            closeConfirmCreateModal={closeConfirmCreateModal}
          />
        </TrackerEvent>
      </Modal>
    </>
  );
}

ProjectNavigation.propTypes = {
  onNavigate: PropTypes.func,
  projectId: PropTypes.string.isRequired,
  elements: PropTypes.arrayOf(PropTypes.string),
};

ProjectNavigation.defaultProps = {
  onNavigate: null,
  elements: Object.values(PROJECT_NAVIGATION_ELEMENTS),
};

export default compose(
  withSidebarControl,
  branchMediaQuery(
    { maxWidth: 0 },
    withProps(({ sidebarControl }) => ({
      onNavigate: sidebarControl.closeMainSidebar,
    })),
  ),
)(ProjectNavigation);
