import React from 'react';
import { Flex } from '@rebass/grid';
import { MediaQuery } from '@picter/prisma';
import { Close } from '@styled-icons/material';
import { useTheme } from 'styled-components/macro';
import themeGet from '@styled-system/theme-get';

import AccountMenu from 'src/components/AccountMenu';
import AppSwitcher from 'src/components/AppSwitcher';
import { Box, Icon } from 'src/modules/prisma';
import useMedia from 'src/hooks/use-media';
import SidebarLayout, {
  useSidebarControls,
} from 'src/components/SidebarLayout';
import Notifications from 'src/components/Notifications';
import IntercomLauncher from 'src/components/IntercomLauncher';

type Props = {
  children?: React.ReactNode;
};
function LayoutContent({ children }: Props) {
  return <SidebarLayout.Content>{children}</SidebarLayout.Content>;
}

// This component allows to re-use the default navigation header styles
// and replace the AppSwitcher with custom elements
export function NavigationHeader({ children }: Props) {
  const { closeMainSidebar } = useSidebarControls();
  return (
    <Box flex="0 1 80px">
      <Flex alignItems="center" justifyContent="space-between" p={7}>
        <Flex alignItems="flex-end">{children ?? <AppSwitcher />}</Flex>
        <MediaQuery
          query={{ maxWidth: 0 }}
          render={() => (
            <Box mr={-2}>
              <Icon
                backgroundColor="white"
                backgroundOpacity={0.6}
                boxSize="xlarge"
                color="secondary"
                onClick={closeMainSidebar}
                size="medium"
                type={<Close />}
              />
            </Box>
          )}
        />
      </Flex>
    </Box>
  );
}

type LayoutNavigationProps = {
  children: React.ReactNode;
  defaultHeader?: boolean;
};
function LayoutNavigation({
  children,
  defaultHeader = true,
}: LayoutNavigationProps) {
  return (
    <SidebarLayout.MainSidebar>
      <SidebarLayout.MainSidebar.Content>
        {defaultHeader && <NavigationHeader />}
        {children}
      </SidebarLayout.MainSidebar.Content>
      <SidebarLayout.MainSidebar.Bottom>
        <Box position="relative" zIndex="sidebar">
          <Flex p={5} pr={0}>
            <Box>
              <AccountMenu />
            </Box>
            <Box gml={2}>
              <Notifications />
            </Box>
            <Box gml={2}>
              <IntercomLauncher />
            </Box>
          </Flex>
        </Box>
      </SidebarLayout.MainSidebar.Bottom>
    </SidebarLayout.MainSidebar>
  );
}

function Layout({ children }: Props) {
  const theme = useTheme();
  const onMobile = useMedia(
    [`(max-width: ${themeGet('breakpoints.0')({ theme })})`],
    [true],
    false,
  );
  return (
    <SidebarLayout mainSidebarOpened={!onMobile} contextSidebarMode="resize">
      {children}
    </SidebarLayout>
  );
}

Layout.Content = LayoutContent;
Layout.Navigation = LayoutNavigation;

export default Layout;
