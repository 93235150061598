import styled from 'styled-components/macro';
import { Icon } from '@picter/prisma';
import themeGet from '@styled-system/theme-get';

const NavigationItemIcon = styled(Icon).attrs({
  color: 'inherit',
  size: 'small',
})`
  margin-right: ${themeGet('space.2')}px;

  .active & {
    color: ${themeGet('colors.primary')};
  }
`;

export default NavigationItemIcon;
