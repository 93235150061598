import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@picter/prisma';
import { Box, Flex } from '@rebass/grid';

import PicterWorkspaceLogoSVG from 'src/assets/PicterWorkspaceLogoSVG';

const SwitcherToggleButton = ({ active, onClick }) => (
  <Flex alignItems="flex-end" onClick={onClick}>
    <PicterWorkspaceLogoSVG role="button" />
    <Box style={{ marginLeft: 2 }} mb={1}>
      <Icon
        active={active}
        type="arrow_drop_down"
        color="muted"
        size="xsmall"
        interactive
      />
    </Box>
  </Flex>
);

SwitcherToggleButton.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
};
SwitcherToggleButton.defaultProps = {
  active: null,
  onClick: null,
};

export default SwitcherToggleButton;
