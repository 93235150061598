import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import Text from './Text';

const Body = styled(Text).attrs(props => ({
  textStyle: `body.${props.textSize}`,
}))``;

Body.propTypes = {
  tag: PropTypes.oneOf(['p', 'span']),
  textSize: PropTypes.oneOf(['large', 'regular', 'small', 'xsmall']),
};

Body.defaultProps = {
  blacklist: [...Text.defaultProps.blacklist, 'textSize'],
  tag: 'p',
  color: 'black',
  textSize: 'regular',
};

Body.displayName = 'Body';

export default Body;
