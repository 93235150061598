import React from 'react';

import { Text, Body, Box, Icon } from 'src/modules/prisma';

import TaskPanel from '../styles/TaskPanel';
import { taskShape } from '../utils';

function ActiveTask({ task: { index, title, description } }) {
  return (
    <TaskPanel>
      <Icon
        backgroundColor="info"
        backgroundOpacity={0.1}
        border="1px solid"
        borderColor="info"
        boxSize="xxxlarge"
        color="info"
        size="xlarge"
        type={index}
      />
      <Box ml={3}>
        <Text textStyle="action.regular">{title}</Text>
        <Body>{description}</Body>
      </Box>
    </TaskPanel>
  );
}

ActiveTask.propTypes = {
  task: taskShape.isRequired,
};

export default ActiveTask;
