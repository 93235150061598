import styled from 'styled-components/macro';
import { transition } from '@picter/prisma';

import Panel from './Panel';
import { typography, textStyle } from './mixins';

const Button = styled(Panel)`
  ${textStyle};
  ${typography};
  border: none;
  cursor: pointer;
  transition: ${transition({ property: 'filter' })};

  &:hover {
    filter: brightness(0.8);
  }

  &:active {
    filter: brightness(0.6);
  }

  &:disabled {
    cursor: not-allowed;
    filter: opacity(45%);
  }
`;

// order matters for overriding certain props (e.g. font weight)
Button.defaultProps = {
  backgroundColor: 'primary',
  blacklist: [...Panel.defaultProps.blacklist, ...typography.propNames],
  borderRadius: '2px',
  color: 'white',
  display: 'flex',
  fontFamily: 'sansSerif',
  px: 4,
  py: 2,
  tag: 'button',
  textStyle: 'body.regular',
};

Button.displayName = 'Button';

export default Button;
