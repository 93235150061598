import styled from 'styled-components/macro';
import { HintMessage } from '@picter/prisma';

const TextFieldContainer = styled.div`
  display: inline-block;
  margin-bottom: ${props => (props.noMargin ? 0 : `${props.theme.space[4]}px`)};
  position: relative;
  text-align: left;
  width: 100%;

  ${HintMessage /* sc-sel */} {
    margin-top: ${props => props.theme.space[1]}px;
    margin-left: 4px;
  }
`;

export default TextFieldContainer;
