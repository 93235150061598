import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ButtonElement as Button } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';
import { Flex, Box } from '@rebass/grid';
import { withFormik } from 'formik';

import MentionableInputField from 'src/components/MentionableInputField';

import messages from '../messages';

const COMMENT_EDIT_NAME = 'comment-edit-input';

const formikConfig = {
  displayName: 'CommentEditForm',
  handleSubmit: async (values, { props: { onSubmit } }) =>
    onSubmit(values[COMMENT_EDIT_NAME]),
  mapPropsToValues: ({ value }) => ({
    [COMMENT_EDIT_NAME]: value || '',
  }),
  validate: (values, { value }) => {
    const errors = {};

    if (values[COMMENT_EDIT_NAME] === value) {
      errors[COMMENT_EDIT_NAME] = true;
    }

    return errors;
  },
  validateOnMount: true,
};

function CommentEditForm({
  autoFocus,
  glowOnFocus,
  onCancel,
  onKeyDown,

  // formik props
  handleBlur,
  handleChange,
  handleSubmit,
  handleReset,
  isSubmitting,
  isValid,
  submitForm,
  values,
}) {
  const handleKeyDown = useCallback(
    event => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        submitForm();
      }

      if (event.key === 'Escape') {
        event.preventDefault();
        onCancel();
      }

      onKeyDown(event);
    },
    [onCancel, onKeyDown, submitForm],
  );

  return (
    <Box>
      <form onReset={handleReset} onSubmit={handleSubmit}>
        <MentionableInputField
          autoFocus={autoFocus}
          glowOnFocus={glowOnFocus}
          maxRows={6}
          name={COMMENT_EDIT_NAME}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={values[COMMENT_EDIT_NAME]}
        />
        <Flex justifyContent="flex-end">
          <Button
            color="grey.600"
            px={2}
            textStyle="action.regular"
            variant="flat"
            onClick={onCancel}
            type="button"
          >
            <FormattedMessage {...messages.labelCancelCommentEdit} />
          </Button>
          <Button
            color="primary"
            disabled={!isValid || isSubmitting}
            px={0}
            pl={2}
            textStyle="action.regular"
            // For some unknown reason, the type="submit" in combination with
            // the onSubmit handler of a wrapping form tag does not do the trick
            // here. We need to specify the click handler, otherwise the button
            // doesn't trigger the submit.
            onClick={submitForm}
            variant="flat"
          >
            <FormattedMessage {...messages.labelSaveCommentEdit} />
          </Button>
        </Flex>
      </form>
    </Box>
  );
}

CommentEditForm.propTypes = {
  autoFocus: PropTypes.bool,
  glowOnFocus: PropTypes.bool,
  onCancel: PropTypes.func,
  onKeyDown: PropTypes.func,

  // formik props
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.string).isRequired,
};

CommentEditForm.defaultProps = {
  autoFocus: false,
  glowOnFocus: false,
  onCancel: f => f,
  onKeyDown: f => f,
};

export default withFormik(formikConfig)(CommentEditForm);
