import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import paths from 'src/routes/paths';
import lazyWithPreload from 'src/utils/lazy-with-preload';

import CollectionPage from '.';
import Document from './_document';

const { LIBRARY_PROJECT_COLLECTION_IMAGE_PATH } = paths;

const CollectionImagePage = lazyWithPreload(() => import('./i/{imageId}'));

const Routes = () => {
  useEffect(() => {
    CollectionImagePage.preload();
  }, []);

  return (
    <Document>
      <Switch>
        <Route
          path={LIBRARY_PROJECT_COLLECTION_IMAGE_PATH}
          component={CollectionImagePage}
        />
        <Route component={CollectionPage} />
      </Switch>
    </Document>
  );
};

export default Routes;
