import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { TrackerEvent } from '@picter/tracker';
import { Box } from '@rebass/grid';
import {
  Link,
  Redirect,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import {
  A,
  ButtonElement as Button,
  FlexAlignLeft,
  FlexAlignRight,
  Spinner,
} from '@picter/prisma';
import qs from 'qs';

import { ContextualTextField, Heading } from 'src/modules/prisma';
import useDocumentTitle from 'src/hooks/use-document-title';
import SpaceTrialPanel from 'src/components/SpaceTrialPanel';
import { librarySpaceUrl } from 'src/routes/urls';
import withSuspenseLoader from 'src/hocs/with-suspense-loader';
import { getDateDeltaFromNowInSeconds } from 'src/utils/date';
import NoPermissionPage from 'src/components/NoPermissionPage';
import NotFoundPage from 'src/components/NotFoundPage';
import { useRealtimeJSONApi } from 'src/modules/realtime';
import { get } from 'src/utils/accessors';
import {
  hasSpaceScope,
  SPACE_VIEW_QUERY_KEY,
  SPACE_VIEW_SESSIONSTORAGE_KEY,
  SpaceViewTypes,
} from 'src/utils/accessors/space';
import SearchModal from 'src/components/SearchModal';
import EmptyStateBox from 'src/components/EmptyStateBox';
import EmtpyProjectIllustrationSvg from 'src/assets/EmtpyProjectIllustrationSvg';

import Layout from '../../_layout';
import MainNavigation from '../../../../_components/MainNavigation';
import SpaceContentGrid from '../../_components/SpaceContentGrid';
import SpaceContentList from '../../_components/SpaceContentList';
import messages from './_messages';
import useData from './_hooks/use-data';
import useHandlers from './_hooks/use-handlers';

const API_URL = process.env.REACT_APP_API_URL;

function FolderPage() {
  const { spaceId } = useParams();
  const { query, pathname } = useLocation();
  const { replace } = useHistory();
  const { subscribe: subscribeToRealtime } = useRealtimeJSONApi();
  const [signedSearchKey, setSignedSearchKey] = useState(null);
  const { user, space, folder, projects, folderNamePlaceholder } = useData();
  const {
    createProject,
    renameFolder,
    folderName,
    setFolderName,
  } = useHandlers({ folder });
  const isSpaceViewer = hasSpaceScope(space, user, 'viewer');

  const currentView =
    query[SPACE_VIEW_QUERY_KEY] ||
    sessionStorage.getItem(SPACE_VIEW_SESSIONSTORAGE_KEY);
  const spaceUrl = librarySpaceUrl({ spaceId });
  const spaceEndOfTrial = get(space, 'attributes.trialEndsAt');
  const isTrialExpired = useMemo(
    () =>
      spaceEndOfTrial !== null &&
      getDateDeltaFromNowInSeconds(spaceEndOfTrial) < 0,
    [spaceEndOfTrial],
  );

  useEffect(() => {
    if (currentView && !query[SPACE_VIEW_QUERY_KEY]) {
      replace(
        `${pathname}?${qs.stringify({
          ...query,
          [SPACE_VIEW_QUERY_KEY]: currentView,
        })}`,
      );
    }
  }, [currentView, query, pathname, replace]);

  useEffect(() => {
    const subscription = subscribeToRealtime(
      `private-resource=ws-spaces;id=${spaceId}`,
    );

    return subscription;
  }, [subscribeToRealtime, spaceId]);

  useEffect(() => {
    fetch(`${API_URL}/app-workspace/signed-search-keys/${spaceId}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(res => {
        return res.text();
      })
      .then(res => {
        return setSignedSearchKey(res);
      });

    return () => {
      setSignedSearchKey(null);
    };
  }, [spaceId]);

  useDocumentTitle(folderName || folderNamePlaceholder);

  if (isTrialExpired) {
    return <Redirect to={spaceUrl} />;
  }

  return (
    <Layout>
      <Layout.Navigation>
        <MainNavigation />
        <Box mx={5} mt="auto" mb={2}>
          <SpaceTrialPanel space={space} />
        </Box>
      </Layout.Navigation>
      <Layout.Top>
        <FlexAlignLeft flex="1 1 auto" justifyContent="flex-start">
          <Link to={spaceUrl}>
            <A>
              <Heading
                tag="h1"
                fontWeight="light"
                textSize="regular"
                color="primary"
                whiteSpace="nowrap"
              >
                {get(space, 'attributes.name')}
              </Heading>
            </A>
          </Link>
          <Box mx={2}>
            <Heading tag="h1" fontWeight="light" textSize="regular">
              /
            </Heading>
          </Box>
          <Box mr={3} width={1}>
            <ContextualTextField
              maxLength="100"
              maxWidth="100%"
              minWidth="100%"
              textStyle="heading.regular"
              fontWeight="light"
              onBlur={renameFolder}
              onChange={e => setFolderName(e.target.value)}
              placeholder={folderNamePlaceholder}
              value={folderName || undefined}
            />
          </Box>
        </FlexAlignLeft>
        <FlexAlignRight>
          {!isSpaceViewer && (
            <TrackerEvent name="New project" trackClick>
              <Button
                data-testid="new-project-button"
                onClick={createProject}
                borderRadius="100px"
                color="white"
                textStyle="action.regular"
                lineHeight="75%"
              >
                <FormattedMessage {...messages.labelNewProject} />
              </Button>
            </TrackerEvent>
          )}
        </FlexAlignRight>
      </Layout.Top>
      <Layout.Content>
        <Box display="flex" flexDirection="column" height="100%">
          <Box flex="0 1">
            {signedSearchKey && (
              <SearchModal signedSearchKey={signedSearchKey} />
            )}
            {currentView === SpaceViewTypes.LIST ? (
              <SpaceContentList projects={projects} />
            ) : (
              <SpaceContentGrid projects={projects} />
            )}
          </Box>
          {projects.isEmpty() && (
            <EmptyStateBox
              graphic={<EmtpyProjectIllustrationSvg />}
              title={
                <FormattedMessage
                  {...messages.messageEmptyDashboardTitle}
                  values={{ name: get(user, 'attributes.firstName') }}
                />
              }
              message={
                <FormattedMessage {...messages.messageEmptyDashboardText} />
              }
              action={
                !isSpaceViewer && (
                  <Button onClick={createProject} color="white">
                    New project
                  </Button>
                )
              }
            />
          )}
        </Box>
      </Layout.Content>
    </Layout>
  );
}

const FolderLoadingError = ({ error }) => {
  if (error.statusCode === 403) {
    return <NoPermissionPage />;
  }

  return <NotFoundPage />;
};

FolderLoadingError.propTypes = {
  error: PropTypes.shape({
    statusCode: PropTypes.number.isRequired,
  }).isRequired,
};

const FolderPageWithSuspense = withSuspenseLoader({
  LoadingComponent: Spinner,
  ErrorComponent: FolderLoadingError,
})(FolderPage);

export default FolderPageWithSuspense;
