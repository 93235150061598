import { defineMessages } from 'react-intl';

export default defineMessages({
  labelDone: {
    id: 'EntityChooser.labelDone',
    defaultMessage: 'Done',
  },
  labelCancel: {
    id: 'EntityChooser.labelCancel',
    defaultMessage: 'Cancel',
  },
});
