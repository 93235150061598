import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import paths from 'src/routes/paths';
import { $TSFixMe } from 'src/types';
import { EmptyStatePage } from 'src/components/EmptyStateBox';
import ErrorIllustrationSvg from 'src/assets/ErrorIllustrationSvg';
import { Button } from 'src/modules/prisma';

import messages from './messages';

const { HOME_PATH } = paths as $TSFixMe;

function ErrorPage() {
  const history = useHistory();
  const reloadPage = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <EmptyStatePage
      graphic={<ErrorIllustrationSvg />}
      title={<FormattedMessage {...messages.messageSomethingWentWrong} />}
      message={<FormattedMessage {...messages.messageTryReloading} />}
      action={
        <Button
          onClick={reloadPage}
          width="236px"
          justifyContent="center"
          textStyle="action.regular"
        >
          <FormattedMessage {...messages.labelReloadPage} />
        </Button>
      }
      onClickBack={() => {
        history.push(HOME_PATH);
      }}
      onClickHelp={() => {
        window.Intercom?.('show');
      }}
    />
  );
}

export default ErrorPage;
