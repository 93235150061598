import React from 'react';
import PropTypes from 'prop-types';

import Card from '../styles/ImageCardCard';
import Image from '../styles/ImageCardImage';
import ImagePreview from './ImageCardImagePreview';

const style = i => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: i * 100,
  transform: `translate(${i * 6}px, ${i * 6}px)`,
  cursor: 'copy',
});

export const STACK_MAX_SIZE = 5;
export const STACK_MIN_SIZE = 1;

const StackedDragPreview = ({ item }) => {
  const parsedSize =
    item.selectionSize > STACK_MAX_SIZE
      ? STACK_MAX_SIZE
      : item.selectionSize || STACK_MIN_SIZE;
  const array = new Array(parsedSize).fill();

  // Check dnd-handlers > beginDrop method for explanation on where the item props
  // are coming
  return (
    <>
      {array.map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i} style={style(i + 1)}>
          <Card>
            {i === array.length - 1 && (
              <>
                <Card.Extra />
                <Card.Content>
                  {item.uploading ? (
                    <ImagePreview
                      alt={item.title}
                      src={item.src}
                      type={item.fileMimeType}
                    />
                  ) : (
                    <Image alt={item.title} src={item.src} showPlaceholder />
                  )}
                </Card.Content>
                <Card.Extra large />
              </>
            )}
          </Card>
        </div>
      ))}
    </>
  );
};

StackedDragPreview.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    gridWidth: PropTypes.number.isRequired,
    gridPaddingLeft: PropTypes.number.isRequired,
    gridPaddingRight: PropTypes.number.isRequired,
    selectionSize: PropTypes.number.isRequired,
    uploading: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    fileMimeType: PropTypes.string.isRequired,
  }).isRequired,
};

export default StackedDragPreview;
