import React from 'react';

const DownloadSvg = props => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line
      x1="1"
      y1="13.5"
      x2="15"
      y2="13.5"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="M8.0002 11.0454C8.0002 11.0454 8.0002 5.85358 8.0002 2M8.0002 11.0454L11.7006 7.34501M8.0002 11.0454L4.2998 7.34501"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
);

export default DownloadSvg;
